import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { api } from 'api';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { getErrorMessageComponent } from 'utils';
import { Button } from 'components/common/button';
import { useAuthSelector } from 'store/selectors/auth';
import { LoginUserPayload } from 'store/reducers/auth/types';
import { loginUserRequest } from 'store/reducers/auth/actions';
import { MfaMethod } from 'api/services/auth/types';
import { Radio, TextInput } from 'components/common/input';
import { FormAlert } from 'components/alert';

interface MfaConfirmationModalProps {
  open: boolean;
  loginDetails: LoginUserPayload;
  handleClose: () => void;
}

const ConfirmCodeSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  method: Yup.string(),
});
const initialValues = {
  code: '',
  method: null,
};

export function MfaConfirmationModal({
  open,
  loginDetails,
  handleClose,
}: MfaConfirmationModalProps) {
  const dispatch = useDispatch();
  const authState = useAuthSelector();
  const theme = useTheme();
  const [method, setMethod] = useState<MfaMethod>(MfaMethod.EMAIL);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');

  const items = useMemo(
    () =>
      authState.mfaMethods?.map(({ type, value }) => ({
        value: type,
        label: (
          <>
            {type}:{' '}
            <b>{type === MfaMethod.EMAIL ? value : `*******${value}`}</b>
          </>
        ),
      })),
    [authState.mfaMethods],
  );

  const handleSendVerificationCode = async (
    method: MfaMethod,
    onSuccess: () => void,
  ) => {
    setIsSending(true);
    try {
      setMethod(method);
      if (authState.mfaUserId) {
        await api.auth.getMfaCode(method, authState.mfaUserId);
        onSuccess();
      }
    } catch (err: any) {
      setError(err.message);
    }
    setIsSending(false);
  };

  const handleMfaConfirmation = async (values: typeof initialValues) => {
    if (method)
      dispatch(
        loginUserRequest({
          ...loginDetails,
          MFACode: values.code,
          MFAMethod: method,
        }),
      );
  };

  const errorMessage = useMemo(() => {
    if (error) return getErrorMessageComponent(error);
    if (authState.error) return getErrorMessageComponent(authState.error);
    return null;
  }, [error, authState.error]);

  useEffect(() => {
    if (authState.mfaMethods?.length) setMethod(authState.mfaMethods[0].type);
  }, [authState.mfaMethods]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={{
            ...initialValues,
          }}
          onSubmit={handleMfaConfirmation}
          validationSchema={ConfirmCodeSchema}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">
                    What authorization method would you prefer to use?
                  </Typography>
                  <IconButton type="button" onClick={handleClose}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                {!values.method && (
                  <Typography
                    variant="body2"
                    textAlign="center"
                    sx={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                      fontSize: theme.spacing(1.6),
                    }}
                  >
                    In order to verify your identity, we&apos;ll send you a code
                    through the method you select.
                  </Typography>
                )}
                <Grid container spacing={0.5}>
                  {values.method ? (
                    <Grid
                      component="form"
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      onSubmit={handleSubmit}
                      container
                    >
                      <Grid item>
                        <Typography
                          variant="body2"
                          textAlign="center"
                          sx={{
                            marginBottom: theme.spacing(2),
                            fontSize: theme.spacing(1.6),
                          }}
                        >
                          We sent a verification code to you. Please enter the
                          code below
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          fullWidth
                          id="outlined-basic"
                          label="Code"
                          variant="outlined"
                          type="text"
                          name="code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}
                          value={values.code}
                          error={touched.code && Boolean(errors.code)}
                          // A ' ' is a hack to always have space for error message, can crete a better approach later
                          helperText={
                            touched.code && errors.code ? errors.code : ' '
                          }
                          margin="none"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="secondary"
                              disabled={authState.loading}
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        {items && (
                          <Radio
                            items={items}
                            value={method}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setMethod(e.target.value as MfaMethod);
                            }}
                            labelProps={{ sx: { fontSize: '14px' } }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <Grid container justifyContent="center">
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="secondary"
                              loading={isSending}
                              onClick={() => {
                                handleSendVerificationCode(method, () =>
                                  setFieldValue('method', method),
                                );
                              }}
                            >
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
            </>
          )}
        </Formik>
        {errorMessage && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <FormAlert severity="error">{errorMessage}</FormAlert>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </div>
  );
}
