import { Typography, Dialog, Grid, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Button } from '../common/button';
import { TextInput } from '../common/input';
import { useAuthSelector } from '../../store/selectors/auth';

interface HearingTestResultTypeChangingModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

export function HearingTestResultTypeChangingModal(
  props: HearingTestResultTypeChangingModalProps,
) {
  const { user } = useAuthSelector();
  const { opened, onClose, onSubmit } = props;

  const [reason, setReason] = useState('');

  const reasonError = useMemo(
    () => (reason.length > 200 ? 'limited to 200 characters' : ''),
    [reason],
  );

  const theme = useTheme();

  const handleClose = useCallback(() => {
    onClose();
    setReason('');
  }, [onClose]);
  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="xs" fullWidth>
      <Grid p={theme.spacing(3)}>
        <Grid mb={theme.spacing(2)}>
          <Typography sx={{ fontWeight: 700 }}>
            Are you sure you want to update the test type?
          </Typography>
          <Typography sx={{ fontWeight: 700 }}>
            This change may trigger the recalculation of results on newer tests.
          </Typography>
        </Grid>
        <Grid mb={theme.spacing(2)}>
          <Typography sx={{ fontSize: '14px' }}>
            Please provide the reason for changing the test type and click Save.
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <TextInput
              fullWidth
              label="Reason"
              size="small"
              value={reason}
              onChange={(e) => {
                setReason(e.target?.value);
              }}
              helperText={reasonError || ' '}
              error={!!reasonError}
              multiline
              maxRows={4}
            />
          </Grid>
          <Grid mb={theme.spacing(1)}>
            <Typography sx={{ fontSize: '12px' }}>
              {user?.given} {user?.family}{' '}
              {DateTime.now().toFormat('MM/dd/yyyy')}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" gap={theme.spacing(2)}>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={() => {
                  onSubmit(reason);
                  handleClose();
                }}
                disabled={!reason}
                sx={{
                  width: '100%',
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
