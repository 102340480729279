import {
  ILatestVisitTimeSlot,
  ILocation,
  Organization,
  Shift,
} from '../organization/types';
import { IProfessionalSupervisorLicenseState } from '../user/types';
import {
  EHearingTestResultTypes,
  EHearingTestStatuses,
  EHearingTestSubStatuses,
  EHearingThresholdsNames,
  HearingTestResult,
  IHearingTestResultLog,
} from '../hearing-test-results/types';
import { IQuestionnaireResults } from '../questionnaire/types';
import { GetParticipantReportResponse } from '../participant/types';
import { ExternalClinic } from '../external-clinic/types';

export enum UserRoles {
  None = 'None',
  Administrator = 'Administrator',
  SuperAdministrator = 'SuperAdministrator',
  OHC = 'OHC',
  SiteManager = 'SiteManager',
  ProfessionalSupervisor = 'ProfessionalSupervisor',
  Participant = 'Participant',
  HCPSupervisor = 'HCPSupervisor',
  AccountManager = 'AccountManager',
}

export enum UserInvitationStatuses {
  Active = 'active',
  Pending = 'pending',
  Sent = 'sent',
}

export interface InvitationToken {
  invitationToken: string;
}

export interface User {
  id: number;
  given: string;
  family: string;
  middleName: string;
  email: string;
  phone: string;
  role: UserRoles;
  externalId: string;
  gender: string;
  birthDate: string;
  active: boolean;
  locationsCount?: number;
  invitationStatus: UserInvitationStatuses;
  siteManager?: SiteManager;
  ohc?: Ohc;
  participant?: Participant;
  professionalSupervisor?: ProfessionalSupervisor;
  HCPSupervisor?: HCPSupervisor;
  accountManager?: AccountManager;
  phoneVerified?: boolean;
  emailVerified?: boolean;
  ignoreMfa?: boolean;
}

export enum MfaMethod {
  EMAIL = 'email',
  PHONE = 'phone',
}

export type MfaMethodListItem = { type: MfaMethod; value: string | null };

export interface ProfessionalSupervisorUser extends User {
  licenseStatesCount?: number;
  locationsCount?: number;
}

export interface AccountManagerUser extends User {
  organizationsCount?: number;
}

export interface HCPSupervisorUser extends User {
  organizationsCount?: number;
}

export interface ProfessionalSupervisor {
  id: number;
  title: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  licenseStates: IProfessionalSupervisorLicenseState[];
}

export interface HCPSupervisor {
  id: number;
  userId: number;
  organizations: Organization[];
  user: User;
  created_at: string;
  updated_at: string;
}

export interface AccountManager {
  id: number;
  userId: number;
  organizations: Organization[];
  created_at: string;
  updated_at: string;
}

export interface LoginResponse {
  user: User;
  token: string;
  deviceToken: null | string;
}

export interface Participant {
  id: number;
  userId: number;
  locationId: number;
  organizationId: number;
  location: ILocation;
  organization?: Organization;
  department: string;
  employmentDate: string;
  given: string;
  family: string;
  middleName: string;
  email: string;
  phone: string;
  externalId: string;
  gender: string;
  birthDate: string;
  active: boolean;
  status: string;
  shifts: Shift[];
  created_at: string;
  updated_at: string;
  lastTestDate: string;
  lastHearingTestResult?: HearingTestResult;
  deferred?: boolean;
  returnedFromDisabilityLeaveDate?: string;
}

export interface ParticipantUser extends Participant, User {}

export interface VerifiedParticipant extends Participant {
  token: string;
}

export interface ClinicReferralParticipants {
  externalClinic: ExternalClinic;
  externalClinicId: number;
  hearingTestResult?: HearingTestResult;
  hearingTestResultId?: number;
  id: number;
  participantId: number;
  created_at: string;
  updated_at: string;
}

export interface ParticipantWithLastHearingTestResultViewItem {
  id: number;
  userId: number;
  locationId: number;
  organizationId: number;
  location: ILocation;
  organization?: Organization;
  clinicReferralParticipants: ClinicReferralParticipants[];
  department: string;
  employmentDate: string;
  given: string;
  family: string;
  middleName: string;
  email: string;
  phone: string;
  externalId: string;
  gender: string;
  birthDate: string;
  active: boolean;
  shifts: Shift[];
  created_at: string;
  updated_at: string;
  returnedFromDisabilityLeaveDate: string | null;
  lastHearingTestResultId: number;
  threshold_left_250: number;
  threshold_left_500: number;
  threshold_left_1000: number;
  threshold_left_2000: number;
  threshold_left_3000: number;
  threshold_left_4000: number;
  threshold_left_5000: number;
  threshold_left_6000: number;
  threshold_left_7000: number;
  threshold_left_8000: number;
  threshold_right_250: number;
  threshold_right_500: number;
  threshold_right_1000: number;
  threshold_right_2000: number;
  threshold_right_3000: number;
  threshold_right_4000: number;
  threshold_right_5000: number;
  threshold_right_6000: number;
  threshold_right_7000: number;
  threshold_right_8000: number;
  type: EHearingTestResultTypes;
  dateTime: string;
  rightSTSFrequenciesAverage: number | null;
  leftSTSFrequenciesAverage: number | null;
  leftSTS: number | null;
  rightSTS: number | null;
  classifiedHearingLoss: EHearingThresholdsNames;

  leftAgeAdjustedSTSFrequenciesAverage: number | null;
  rightAgeAdjustedSTSFrequenciesAverage: number | null;
  leftAgeAdjustedSTS: number | null;
  rightAgeAdjustedSTS: number | null;

  leftEarSpeechFrequenciesAverage: number | null;
  rightEarSpeechFrequenciesAverage: number | null;
  leftEarHighFrequenciesAverage: number | null;
  rightEarHighFrequenciesAverage: number | null;

  asymmetryAt5001k2kHzFrequenciesAverage: number | null;
  asymmetryAtHighFrequenciesAverage: number | null;
  shiftFromBaselineIn5001k2kHzFrequenciesAverage: number | null;
  shiftFromBaselineInHighFrequenciesAverage: number | null;

  lastSignedReport?: GetParticipantReportResponse;
  reportMessage: string | null;
  referralComment: string | null;
  isReferral: boolean | null;
  status: EHearingTestStatuses | null;
  subStatuses: EHearingTestSubStatuses[] | null;

  latestQuestionnaireResult?: IQuestionnaireResults;
  lastHearingTestResult?: IHearingTestResultLog;
  latestVisitTimeSlot?: ILatestVisitTimeSlot;
}

export interface UnscheduledParticipant extends Participant {
  deferred: boolean;
  deferringReason: string | null;
  deferringReasonNote: string | null;
}

export interface SiteManagerBody {
  given: string;
  family: string;
  email: string;
  middleName: string;
  phone: string;
  externalId: string;
  organizationId?: number;
  locations?: number[];
}

export interface SiteManager {
  id: number;
  userId: number;
  organizationId: number;
  organization: Organization;
  externalId: string;
  locations?: ILocation[];
  created_at: string;
  updated_at: string;
}

export interface Ohc {
  id: number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  accreditation: true;
  locations?: ILocation[];
  created_at: string;
  updated_at: string;
}

export interface OhcBody {
  email: string;
  given: string;
  family: string;
  middleName: string;
  phone: string;
  externalId: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  accreditation: boolean;
}

export interface HCPSupervisorBody {
  email: string;
  given: string;
  family: string;
  middleName?: string;
  phone?: string;
  externalId?: string;
  birthDate?: string;
  gender: string | null;
  active?: boolean;
}

export interface AssignLocationsBody {
  locationsIds: number[];
}

export interface TimeSlot {
  start: string;
  end: string;
}

export interface VerificationCodeResponse {
  success: boolean;
}
