import { Button, ButtonProps } from '@mui/material';
import { UserRoles } from 'api/services/auth/types';
import { useAuthSelector } from 'store/selectors/auth';
import { Tooltip } from '../tooltip';

interface AcesssButtonProps extends ButtonProps {
  roles: UserRoles[];
}

export function AccessButton(props: AcesssButtonProps) {
  const authState = useAuthSelector();
  const { roles, children, ...rest } = props;

  if (!authState.user) return null;

  return roles.includes(authState.user.role) ? (
    <Button {...rest}>{children}</Button>
  ) : (
    <Tooltip title="You don't have permissions to access this resource">
      <span>
        <Button {...rest} disabled>
          {children}
        </Button>
      </span>
    </Tooltip>
  );
}
