import { useEffect, useState } from 'react';

const useProgressiveImage = (src: string) => {
  const [sourcedLoaded, setSourceLoaded] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourcedLoaded;
};

export default useProgressiveImage;
