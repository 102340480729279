import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userIdentityRequest } from 'store/reducers/auth/actions';

export const useIdentity = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userIdentityRequest());
  }, []);
};
