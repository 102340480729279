import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { ExternalClinic } from 'api/services/external-clinic/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { Select, TextInput } from 'components/common/input';
import { IState } from '../../../../../api/services/state/types';
import { getPhoneSchema } from '../../../../../utils/validation/validators';
import { PhoneInput } from '../../../../../components/common/input/PhoneInput';
import { AsyncAutocomplete } from '../../../../../components/common/async-autocomplete';
import { ILocation } from '../../../../../api/services/organization/types';

interface OrganizationFormProps {
  externalClinic?: ExternalClinic | null;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function ExternalClinicForm(props: OrganizationFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [states, setStates] = useState<IState[]>([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const states = await api.state.getStates({ limit: 100 });
        setStates(states.items);
      } catch (e: any) {
        setError(
          e?.response?.data?.error ||
            e?.message ||
            'Something went wrong with fetching the initial data',
        );
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, []);

  const statesDropdownOptions = useMemo(
    () =>
      states?.map((state) => ({ label: state.name, value: state.name })) || [],
    [states],
  );

  const { externalClinic, handleSubmit, handleClose } = props;

  const ExternalClinicSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        line1: Yup.string().required(t('validation.required')),
        line2: Yup.string().optional(),
        city: Yup.string().required(t('validation.required')),
        state: Yup.string().required(t('validation.required')),
        postalCode: Yup.string().required(t('validation.required')),
        email: Yup.string()
          .email('Invalid email')
          .required(t('validation.required')),
        phone: getPhoneSchema().required(t('validation.required')),
        contactPersonName: Yup.string().optional(),
        bookingLink: Yup.string().optional(),
        active: Yup.boolean().required(t('validation.required')),
        locationsIds: Yup.array().min(1, t('validation.required')),
      }),
    [i18n.language],
  );

  const initialValues = externalClinic
    ? {
        name: externalClinic.name,
        line1: externalClinic.line1,
        line2: externalClinic.line2,
        city: externalClinic.city,
        state: externalClinic.state,
        postalCode: externalClinic.postalCode,
        email: externalClinic.email,
        phone: externalClinic.phone,
        contactPersonName: externalClinic.contactPersonName,
        bookingLink: externalClinic.bookingLink,
        locationsIds: externalClinic.locations.map((item) => ({
          value: item.id,
          label: `${item.name} (${item.organization.name})`,
        })),
        active: externalClinic.active,
      }
    : {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        email: '',
        phone: '',
        contactPersonName: '',
        bookingLink: '',
        locationsIds: [],
        active: true,
      };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);
      const newValues = { ...values, locations: [] as number[] };
      newValues.locations = values.locationsIds.map(({ value }) =>
        Number(value),
      );
      if (externalClinic)
        await api.externalClinic.updateExternalClinic(
          externalClinic.id,
          newValues,
        );
      else await api.externalClinic.createExternalClinic(newValues);
      showSnackbar(
        externalClinic
          ? 'External clinic successfully updated'
          : 'External clinic successfully added',
        {
          variant: 'success',
        },
      );
      handleSubmit();
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || t('common.couldNotSaveForm'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ExternalClinicSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        getFieldProps,
        setFieldValue,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            externalClinic
              ? t('common.editOrganization')
              : t('common.createOrganization')
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label={t('common.name')}
                  variant="outlined"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name ? errors.name : ' '}
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label="Address line 1"
                  variant="outlined"
                  type="text"
                  name="line1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line1}
                  error={touched.line1 && Boolean(errors.line1)}
                  helperText={
                    touched.line1 && errors.line1 ? errors.line1 : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label="Address line 2"
                  variant="outlined"
                  type="text"
                  name="line2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.line2}
                  error={touched.line2 && Boolean(errors.line2)}
                  helperText={
                    touched.line2 && errors.line2 ? errors.line2 : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label="City"
                  variant="outlined"
                  type="text"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city ? errors.city : ' '}
                  margin="none"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  fullWidth
                  label="State"
                  options={statesDropdownOptions}
                  placeholder="State"
                  errorMessage={
                    touched.state && errors.state ? errors.state : ''
                  }
                  {...getFieldProps('state')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label="Postal code"
                  variant="outlined"
                  type="text"
                  name="postalCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postalCode}
                  error={touched.postalCode && Boolean(errors.postalCode)}
                  helperText={
                    touched.postalCode && errors.postalCode
                      ? errors.postalCode
                      : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={
                    touched.email && errors.email ? errors.email : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={
                    touched.phone && errors.phone ? errors.phone : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  label="Contact person name"
                  variant="outlined"
                  type="text"
                  name="contactPersonName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contactPersonName}
                  error={
                    touched.contactPersonName &&
                    Boolean(errors.contactPersonName)
                  }
                  helperText={
                    touched.contactPersonName && errors.contactPersonName
                      ? errors.contactPersonName
                      : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <AsyncAutocomplete<ILocation>
                  fullWidth
                  multiple
                  label="Locations"
                  fetchMethod={(params) =>
                    api.organization.getAllLocations(params)
                  }
                  mapOptionMethod={(item: ILocation) => ({
                    value: item.id,
                    label: `${item.name} (${item.organization.name})`,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('locationsIds', newValue);
                  }}
                  value={values.locationsIds}
                  onBlur={handleBlur}
                  getOptionDisabled={(option) =>
                    !!values.locationsIds.find(
                      (item) => item.value === option.value,
                    )
                  }
                  error={touched.locationsIds && Boolean(errors.locationsIds)}
                  helperText={
                    touched.locationsIds && (errors.locationsIds as string)
                      ? (errors.locationsIds as string)
                      : ' '
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Booking link"
                  variant="outlined"
                  type="text"
                  name="bookingLink"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bookingLink}
                  error={touched.bookingLink && Boolean(errors.bookingLink)}
                  helperText={
                    touched.bookingLink && errors.bookingLink
                      ? errors.bookingLink
                      : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="active"
                      onChange={handleChange}
                      checked={values.active}
                    />
                  }
                  label={t('common.Active')}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
