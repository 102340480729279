import { DateTime } from 'luxon';
import i18next from 'i18n/config';
import * as Yup from 'yup';
import { PHONE_REGEXP, ZIP_CODE_REGEXP } from './regexp';

export const birthDate = Yup.date()
  .min(
    DateTime.now().minus({ years: 130 }),
    "impressive, but we think there's been a mistake!",
  )
  .max(DateTime.now(), 'DOB should be earlier then now');

export const getPhoneSchema = () =>
  Yup.string().matches(
    PHONE_REGEXP,
    i18next.t('validation.mustBeAValidPhoneNumber'),
  );

export const postalCode = Yup.string()
  .matches(ZIP_CODE_REGEXP, 'must be a valid zipcode')
  .nullable();
