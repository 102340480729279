import { Grid } from '@mui/material';
import { MainInfoForm } from './main-Info-form';
import { useSiteManagerDashboardSelector } from '../../../store/selectors/dashboard';
import { ShiftsForm } from './shifts-form';

export function SiteManagerSettings() {
  const { selectedLocation } = useSiteManagerDashboardSelector();

  return (
    <Grid
      container
      sx={{
        borderTop: '1px solid rgba(202, 194, 190, 0.30)',
        padding: '24px 0',
      }}
    >
      <Grid xs={12} md={6} sx={{ paddingRight: { xs: '0px', md: '56px' } }}>
        <MainInfoForm location={selectedLocation} />
      </Grid>

      <Grid item xs={12} md={6}>
        <ShiftsForm location={selectedLocation} />
      </Grid>
    </Grid>
  );
}
