import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { ProfessionalSupervisorUser } from 'api/services/auth/types';
import { FormModal } from 'components/modal';
import { TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { getPhoneSchema } from 'utils/validation/validators';
import { showSnackbar } from 'utils';
import {
  convertPhoneToInternationalFormat,
  convertPhoneToTenDigitsFormat,
} from 'utils/phoneNumber';
import { useTranslation } from 'react-i18next';

interface ProfessionalSupervisorFormProps {
  professionalSupervisor?: ProfessionalSupervisorUser | null;
  locationId?: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

const EMPTY_VALUES = {
  given: '',
  family: '',
  email: '',
  phone: '',
  middleName: '',
};

export function ProfessionalSupervisorForm(
  props: ProfessionalSupervisorFormProps,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { professionalSupervisor, locationId, handleSubmit, handleClose } =
    props;
  const { t, i18n } = useTranslation();

  const initialValues = professionalSupervisor
    ? {
        given: professionalSupervisor.given,
        family: professionalSupervisor.family,
        email: professionalSupervisor.email,
        phone: professionalSupervisor.phone
          ? convertPhoneToTenDigitsFormat(professionalSupervisor.phone)
          : '',
        middleName: professionalSupervisor.middleName,
      }
    : EMPTY_VALUES;

  const ProfessionalSupervisorValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        given: Yup.string().required(t('validation.required')),
        family: Yup.string().required(t('validation.required')),
        email: Yup.string()
          .email(t('validation.invalidEmail'))
          .required(t('validation.required')),
        phone: getPhoneSchema(),
      }),
    [i18n.language],
  );

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);
      const body: typeof initialValues & { locationId?: string } = {
        ...values,
      };
      if (locationId) {
        body.locationId = locationId;
      }
      if (professionalSupervisor)
        await api.user.updateProfessionalSupervisor(professionalSupervisor.id, {
          ...body,
          phone: convertPhoneToInternationalFormat(values.phone),
        });
      else await api.user.createProfessionalSupervisor(body);
      showSnackbar(
        professionalSupervisor
          ? t('common.professionalSupervisorSuccessfullyUpdated')
          : t('common.professionalSupervisorSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
      handleSubmit();
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || t('common.couldNotSaveForm'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ProfessionalSupervisorValidationSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            professionalSupervisor
              ? t('common.editProfessionalSupervisor')
              : t('common.createProfessionalSupervisor')
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.firstName')}
                  variant="outlined"
                  type="text"
                  name="given"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.given}
                  error={touched.given && Boolean(errors.given)}
                  helperText={
                    touched.given && errors.given ? errors.given : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.middleName')}
                  variant="outlined"
                  type="text"
                  name="middleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.middleName}
                  error={touched.middleName && Boolean(errors.middleName)}
                  helperText={
                    touched.middleName && errors.middleName
                      ? errors.middleName
                      : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.lastName')}
                  variant="outlined"
                  type="text"
                  name="family"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.family}
                  error={touched.family && Boolean(errors.family)}
                  helperText={
                    touched.family && errors.family ? errors.family : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.email')}
                  variant="outlined"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={
                    touched.email && errors.email ? errors.email : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={
                    touched.phone && errors.phone ? errors.phone : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
