import React, { useCallback, useMemo } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import { HearingTestResult } from '../../../../api/services/hearing-test-results/types';
import { CustomFrequencyTick } from './CustomFrequencyTick';
import { CustomLegend } from './CustomLegend';
import { CustomTooltip } from './CustomTooltip';

interface IParticipantHearingTestResultChartProps {
  hearingTestResult: HearingTestResult;
}

const X_AXIS_TICKS = [500, 1000, 2000, 3000, 4000, 6000, 8000];
const Y_AXIS_TICKS = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110];
const HEARING_CLASSIFICATION_AXIS_TICKS = [12.5, 32.5, 47.5, 62.5, 80, 100];

export function AudiogramChart(props: IParticipantHearingTestResultChartProps) {
  const { hearingTestResult } = props;

  const results = useMemo(() => {
    if (!hearingTestResult) {
      return;
    }
    const result: {
      frequency: number;
      left: number | null;
      right: number | null;
      normal: number[] | null;
      mild: number[] | null;
      moderate: number[] | null;
      moderateSevere: number[] | null;
      severe: number[] | null;
      profound: number[] | null;
    }[] = [];

    X_AXIS_TICKS.forEach((frequency) => {
      result.push({
        frequency,
        left:
          (hearingTestResult[
            `threshold_left_${frequency}` as keyof typeof hearingTestResult
          ] as number | null) || null,
        right:
          (hearingTestResult[
            `threshold_right_${frequency}` as keyof typeof hearingTestResult
          ] as number | null) || null,
        normal: [0, 25],
        mild: [25, 40],
        moderate: [40, 55],
        moderateSevere: [55, 70],
        severe: [70, 90],
        profound: [90, 110],
      });
    });

    return result;
  }, [hearingTestResult]);

  const renderRightEarValue = useCallback(
    ({ cx, cy }: any) =>
      cx >= 0 && cy >= 0 ? (
        <g transform={`translate(${cx - 4},${cy ? cy - 4 : 0})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5.86426C4.82843 5.86426 5.5 5.19269 5.5 4.36426C5.5 3.53583 4.82843 2.86426 4 2.86426C3.17157 2.86426 2.5 3.53583 2.5 4.36426C2.5 5.19269 3.17157 5.86426 4 5.86426ZM4 7.86426C5.933 7.86426 7.5 6.29725 7.5 4.36426C7.5 2.43126 5.933 0.864258 4 0.864258C2.067 0.864258 0.5 2.43126 0.5 4.36426C0.5 6.29725 2.067 7.86426 4 7.86426Z"
            fill="#D9777A"
          />
        </g>
      ) : (
        <span />
      ),
    [],
  );

  const renderLeftEarValue = useCallback(
    ({ cx, cy }: any) =>
      cx >= 0 && cy >= 0 ? (
        <g transform={`translate(${cx - 4},${cy ? cy - 4 : 0})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.78073 0.644987C1.15504 0.270681 1.76191 0.270681 2.13621 0.644987L4 2.50878L5.86379 0.644987C6.23809 0.270681 6.84496 0.270681 7.21927 0.644987C7.59358 1.01929 7.59358 1.62616 7.21927 2.00047L5.35548 3.86426L7.21927 5.72805C7.59358 6.10235 7.59358 6.70922 7.21927 7.08353C6.84496 7.45783 6.23809 7.45783 5.86379 7.08353L4 5.21974L2.13621 7.08353C1.76191 7.45783 1.15504 7.45783 0.78073 7.08353C0.406423 6.70922 0.406423 6.10235 0.78073 5.72805L2.64452 3.86426L0.78073 2.00047C0.406423 1.62616 0.406423 1.01929 0.78073 0.644987Z"
            fill="#4E4A96"
          />
        </g>
      ) : (
        <span />
      ),
    [],
  );

  const hearingLossClassificationTickFormatter = useCallback((tick: number) => {
    const hearingLossMap = {
      12.5: 'Normal',
      32.5: 'Mild',
      47.5: 'Moderate',
      62.5: 'Moderate Severe',
      80: 'Severe',
      100: 'Profound',
    };

    return hearingLossMap[tick as keyof typeof hearingLossMap] === 'Normal'
      ? 'Normal'
      : `${hearingLossMap[tick as keyof typeof hearingLossMap]} hearing loss`;
  }, []);

  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={results}>
        <CartesianGrid
          vertical
          horizontal
          height={isMobileView ? 194 : 221}
          horizontalValues={[
            0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
            85, 90, 95, 100, 105, 110,
          ]}
          verticalValues={[500, 1000, 2000, 3000, 4000, 6000, 8000]}
        />
        <XAxis
          type="category"
          dataKey="frequency"
          unit="Hz"
          ticks={X_AXIS_TICKS}
          interval={0}
          tickLine={false}
          style={{ fontFamily: 'DM Mono', fontSize: '10px' }}
          tick={CustomFrequencyTick}
          height={60}
          padding={{ left: 7, right: 7 }}
        >
          <Label
            value="Frequency (Hz)"
            position="center"
            fontFamily="DM Mono"
            fontSize="8px"
            fill="#000000"
            textAnchor="middle"
            style={{ textTransform: 'uppercase' }}
            dy={30}
          />
        </XAxis>
        <YAxis
          yAxisId="left"
          type="number"
          ticks={Y_AXIS_TICKS}
          domain={['dataMin', 'dataMax']}
          interval={0}
          reversed
          axisLine={false}
          tickLine={false}
          style={{ fontFamily: 'DM Mono', fontSize: '9px', fontWeight: 500 }}
          width={30}
          padding={{ bottom: 9 }}
          tickMargin={0}
        >
          <Label
            value="HEARING LEVEL (dBHL)"
            offset={0}
            dy={50}
            position="insideLeft"
            angle={-90}
            fontFamily="DM Mono"
            fontSize="8px"
            fill="#000000"
            textAnchor="middle"
          />
        </YAxis>
        {!isMobileView && (
          <YAxis
            yAxisId="right"
            type="number"
            ticks={HEARING_CLASSIFICATION_AXIS_TICKS}
            domain={[0, 110]}
            interval={0}
            reversed
            axisLine={false}
            tickLine={false}
            orientation="right"
            style={{
              fontFamily: 'DM Mono',
              fontSize: '8px',
              fontWeight: 400,
              textTransform: 'uppercase',
            }}
            tickMargin={16}
            tickFormatter={hearingLossClassificationTickFormatter}
            width={170}
            padding={{ bottom: 9 }}
          />
        )}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          layout={isMobileView ? 'horizontal' : 'vertical'}
          verticalAlign={isMobileView ? 'bottom' : 'middle'}
          align={isMobileView ? 'center' : 'left'}
          content={CustomLegend}
        />
        <Area
          type="monotone"
          dataKey="profound"
          fillOpacity={0.3}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="none"
          activeDot={false}
        />
        <Area
          type="monotone"
          dataKey="severe"
          fillOpacity={0.25}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="#FF2424"
          strokeDasharray="0 0 3 3"
          strokeWidth={0.5}
          activeDot={false}
        />
        <Area
          type="monotone"
          dataKey="moderateSevere"
          fillOpacity={0.2}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="transparent"
          activeDot={false}
        />
        <Area
          type="monotone"
          dataKey="moderate"
          fillOpacity={0.15}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="#FF2424"
          strokeDasharray="0 0 3 3"
          strokeWidth={0.5}
          activeDot={false}
        />
        <Area
          type="monotone"
          dataKey="mild"
          fillOpacity={0.1}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="none"
          activeDot={false}
        />
        <Area
          type="monotone"
          dataKey="normal"
          fillOpacity={0.03}
          fill="rgb(255, 81, 81)"
          yAxisId="left"
          stroke="#FF2424"
          strokeDasharray="0 0 3 3"
          strokeWidth={0.5}
          activeDot={false}
        />
        <Scatter
          name="Right Ear"
          fill="#D9777A"
          shape={renderRightEarValue}
          yAxisId="left"
          dataKey="right"
        />
        <Scatter
          name="Left Ear"
          fill="#4E4A96"
          shape={renderLeftEarValue}
          yAxisId="left"
          dataKey="left"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
