import { ArrowBack } from '@mui/icons-material';
import { Grid, GridProps, IconButton, Skeleton } from '@mui/material';
import { Heading, HeadingOfEntityPage } from 'components/common/heading';
import { HeadingOfEntityProps } from 'components/common/heading/HeadingOfEntityPage';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type PageHeaderProps = {
  onBackClick?: () => void;
  buttons?: ReactNode;
  fontSize?: string;
  titleLoading?: boolean;
} & GridProps &
  (
    | (HeadingOfEntityProps & { title?: undefined })
    | {
        title: any;
        entityTitle?: undefined;
        entityName?: undefined;
      }
  );

export function PageHeader(props: PageHeaderProps) {
  const {
    title,
    entityName,
    entityTitle,
    onBackClick,
    buttons,
    fontSize,
    titleLoading,
    ...restProps
  } = props;
  const navigate = useNavigate();

  const headerTitle = entityTitle ? (
    <HeadingOfEntityPage entityName={entityName} entityTitle={entityTitle} />
  ) : (
    title
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      pb={2}
      {...restProps}
    >
      <Grid item xs={buttons ? 6 : 12}>
        <Grid
          item
          container
          alignItems="center"
          columnGap={1}
          flexWrap="nowrap"
        >
          <IconButton
            onClick={() => (onBackClick ? onBackClick?.() : navigate(-1))}
          >
            <ArrowBack />
          </IconButton>
          <Heading
            variant="h5"
            fontWeight={700}
            sx={{ fontSize, width: '100%' }}
          >
            {titleLoading ? (
              <Skeleton variant="rounded" width="129px" height="26px" />
            ) : (
              headerTitle
            )}
          </Heading>
        </Grid>
      </Grid>

      {buttons && (
        <Grid item xs={6} flex={1}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            {buttons}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
