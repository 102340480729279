import { Box, Grid, useTheme } from '@mui/material';
import { Editor, EditorState } from 'draft-js';
import { useCallback, useEffect, useRef } from 'react';
import { ToolbarButton } from './components';
import { EToolbarButtonTypes } from './types';
import { getToolbarButtonClickHandler, isToolbarButtonActive } from './utils';

interface ITextEditorProps {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  placeholder?: string;
  focusOnMount?: boolean;
}

export function TextEditor(props: ITextEditorProps) {
  const { editorState, setEditorState, placeholder, focusOnMount } = props;
  const editor = useRef<Editor>(null);
  const focus = useCallback(() => {
    editor.current?.focus();
  }, []);

  useEffect(() => {
    if (focusOnMount) {
      focus();
    }
  }, [focus]);

  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: { xs: theme.spacing(1.5), sm: theme.spacing(3) },
      }}
    >
      <Grid
        sx={{
          padding: theme.spacing(1),
          borderRadius: '16px',
          backgroundColor: '#08161F0A',
        }}
        gap={theme.spacing(1)}
        container
      >
        <ToolbarButton
          toolType={EToolbarButtonTypes.Italic}
          active={isToolbarButtonActive(
            EToolbarButtonTypes.Italic,
            editorState,
          )}
          onMouseDown={getToolbarButtonClickHandler(
            EToolbarButtonTypes.Italic,
            editorState,
            setEditorState,
          )}
        />
        <ToolbarButton
          toolType={EToolbarButtonTypes.Bold}
          active={isToolbarButtonActive(EToolbarButtonTypes.Bold, editorState)}
          onMouseDown={getToolbarButtonClickHandler(
            EToolbarButtonTypes.Bold,
            editorState,
            setEditorState,
          )}
        />
        <ToolbarButton
          toolType={EToolbarButtonTypes.Underlined}
          active={isToolbarButtonActive(
            EToolbarButtonTypes.Underlined,
            editorState,
          )}
          onMouseDown={getToolbarButtonClickHandler(
            EToolbarButtonTypes.Underlined,
            editorState,
            setEditorState,
          )}
        />
        <ToolbarButton
          toolType={EToolbarButtonTypes.UnorderedList}
          active={isToolbarButtonActive(
            EToolbarButtonTypes.UnorderedList,
            editorState,
          )}
          onMouseDown={getToolbarButtonClickHandler(
            EToolbarButtonTypes.UnorderedList,
            editorState,
            setEditorState,
          )}
        />
      </Grid>
      <Box
        onClick={focus}
        sx={{
          minHeight: '110px',
          paddingTop: theme.spacing(2),
          cursor: 'text',
        }}
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          ref={editor}
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
}
