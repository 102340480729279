import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { FullHeightContainer } from 'components/container';
import { ConfirmationModal, FormModal } from 'components/modal';
import { Shift } from 'api/services/organization/types';
import { ShiftCalendar } from 'components/shift-calendar';
import {
  ISimpleTimeErrors,
  ITimeErrors,
  IWeeklySchedule,
} from 'components/shift-calendar/components/types';
import { TextInput } from 'components/common/input/TextInput';
import {
  INITIAL_SCHEDULE_STATE,
  INITIAL_SIMPLE_TIME_ERRORS,
} from 'components/shift-calendar/components/constants';
import {
  checkIsScheduleSimple,
  formatInitialShiftSchedule,
  isSimplePeriodNotValid,
  isWorkingPeriodsValid,
  setTimeScheduleErrors,
  timeParser,
} from 'components/shift-calendar/components/utils';
import { FormAlert } from 'components/alert';
import { TimePeriod } from 'components/shift-calendar/components/TimePeriod';
import { Button } from 'components/common/button';

interface ShiftFormProps {
  shift?: Shift | null;
  locationId: string | number;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function ShiftForm(props: ShiftFormProps) {
  const { shift, handleSubmit, handleClose, locationId } = props;
  const [name, setName] = useState('');
  const [schedule, setSchedule] = useState<IWeeklySchedule>(
    INITIAL_SCHEDULE_STATE,
  );
  const [minimalEmployees, setMinimalEmployees] = useState(0);
  const [timeErrors, setTimeErrors] = useState<ITimeErrors>(
    setTimeScheduleErrors(INITIAL_SCHEDULE_STATE),
  );
  const [simpleTimeErrors, setSimpleTimeErrors] = useState<ISimpleTimeErrors>(
    INITIAL_SIMPLE_TIME_ERRORS,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const [isTouched, setIsTouched] = useState(false);
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [openSwitchViewConfirmation, setOpenSwitchViewConfirmation] =
    useState(false);
  const { t } = useTranslation();

  const assignInitialValues = (shift: Shift) => {
    setName(shift.name);
    setMinimalEmployees(shift.minimalEmployees);
    const initialSchedule = formatInitialShiftSchedule(shift);
    setSchedule(initialSchedule);
    setTimeErrors(setTimeScheduleErrors(initialSchedule));
    const isInitialScheduleSimple = checkIsScheduleSimple(initialSchedule);
    if (!isInitialScheduleSimple) {
      setIsAdvancedView(true);
    } else {
      const commonTimePeriod = Object.values(initialSchedule).find(
        (item) => item.isWorkingDay,
      )?.workingPeriods[0];
      setSchedule((schedule) => {
        const temp = _.cloneDeep(schedule);
        Object.keys(temp).forEach((weekDay) => {
          if (commonTimePeriod?.from) {
            temp[
              weekDay as unknown as keyof IWeeklySchedule
            ].workingPeriods[0].from = commonTimePeriod.from;
          }
          if (commonTimePeriod?.to) {
            temp[
              weekDay as unknown as keyof IWeeklySchedule
            ].workingPeriods[0].to = commonTimePeriod.to;
          }
        });
        return temp;
      });
    }
  };

  const resetScheduleState = () => {
    setSchedule(INITIAL_SCHEDULE_STATE);
    setTimeErrors(setTimeScheduleErrors(INITIAL_SCHEDULE_STATE));
    setSimpleTimeErrors(INITIAL_SIMPLE_TIME_ERRORS);
  };

  useEffect(() => {
    if (shift) {
      assignInitialValues(shift);
    }
  }, [shift]);

  const onSubmit = async () => {
    if (
      !locationId ||
      !isWorkingPeriodsValid(schedule, timeErrors) ||
      isSimplePeriodNotValid(simpleTimeErrors) ||
      !name
    ) {
      return setIsTouched(true);
    }

    const hours = Object.keys(schedule)
      .filter(
        (key) => schedule[key as unknown as keyof IWeeklySchedule].isWorkingDay,
      )
      .map((key) => ({
        day: key,
        hours: _.compact(
          schedule[key as unknown as keyof IWeeklySchedule].workingPeriods
            .sort(
              (a: TimePeriod, b: TimePeriod) =>
                timeParser(a.from) - timeParser(b.from),
            )
            .map((period: TimePeriod) => {
              if (!period.to || !period.from) return;
              return {
                start: period.from
                  ? DateTime.fromJSDate(period.from).toFormat('HH:mm')
                  : '',
                end: period.to
                  ? DateTime.fromJSDate(period.to).toFormat('HH:mm')
                  : '',
              };
            }),
        ),
      }));

    const body = {
      name,
      hours,
      minimalEmployees,
    };

    try {
      setLoading(true);
      if (shift) {
        await api.organization.updateLocationShift(locationId, shift.id, body);
      } else {
        await api.organization.createLocationShift(locationId, body);
      }
      setSchedule(INITIAL_SCHEDULE_STATE);
      handleSubmit();
      showSnackbar(
        shift
          ? t('common.locationShiftSuccessfullyUpdated')
          : t('common.locationShiftSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(getErrorMessage(e, t('common.couldNotSaveForm')));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormModal
      open
      handleClose={() => {
        setSchedule(INITIAL_SCHEDULE_STATE);
        handleClose();
      }}
      handleSubmit={onSubmit}
      title={shift ? t('common.editShift') : t('common.createShift')}
      submitButtonTitle={
        shift ? t('common.updateShift') : t('common.createShift')
      }
      isSubmitting={loading}
    >
      <FullHeightContainer
        maxWidth="sm"
        disableGutters
        sx={{ minHeight: '496px', justifyContent: 'start' }}
      >
        <Grid
          container
          item
          xs={12}
          mb={1}
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Grid item flex={1}>
            <TextInput
              fullWidth
              label={t('common.name')}
              variant="standard"
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              margin="none"
              error={isTouched && !name}
              helperText={isTouched && !name ? 'required' : ' '}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                const isScheduleSimple = checkIsScheduleSimple(schedule);
                if (!isScheduleSimple) {
                  return setOpenSwitchViewConfirmation(true);
                }
                setIsAdvancedView(!isAdvancedView);
              }}
            >
              {isAdvancedView
                ? t('common.simpleView')
                : t('common.advancedView')}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ShiftCalendar
            schedule={schedule}
            setSchedule={setSchedule}
            timeErrors={timeErrors}
            setTimeErrors={setTimeErrors}
            simpleTimeErrors={simpleTimeErrors}
            setSimpleTimeErrors={setSimpleTimeErrors}
            isAdvancedView={isAdvancedView}
          />
        </Grid>

        <Grid>
          <Typography sx={{ margin: '32px 0 16px' }}>
            The parameter below indicates how many employees from the shift
            cannot be tested on the same testing day
          </Typography>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              label="MinimalEmployees"
              variant="standard"
              type="integer"
              name="ohcCount"
              onChange={(e) => setMinimalEmployees(Number(e.target.value))}
              value={minimalEmployees}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {error ? (
                <FormAlert severity="error">
                  {getErrorMessageComponent(error)}
                </FormAlert>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <ConfirmationModal
          open={openSwitchViewConfirmation}
          handleClose={() => {
            setOpenSwitchViewConfirmation(false);
          }}
          handleSubmit={() => {
            resetScheduleState();
            setIsAdvancedView(false);
            setOpenSwitchViewConfirmation(false);
          }}
          submitButtonTitle="Go to Simple View"
          title={
            <>
              You are about to switch to a simple view. The simple view does not
              support settings such as several time periods per day or a shift
              that lasts more than a day.
              <br /> The form will be reset.
            </>
          }
        />
      </FullHeightContainer>
    </FormModal>
  );
}
