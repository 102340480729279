import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import _ from 'lodash';
import { ILocationParticipantsHearingMetric } from '../../api/services/organization/types';
import { HeaderChips } from './components/HeaderChips';
import { MetricsTableCell } from './components/MetricsTableCell';
import { EHearingThresholdsNames } from '../../api/services/hearing-test-results/types';

interface LocationMetricsTableProps {
  metrics: ILocationParticipantsHearingMetric | null;
  loading?: boolean;
}

const HEADER_CELLS: Array<{ component: ReactElement | null }> = [
  {
    component: null,
  },
  {
    component: (
      <HeaderChips value="Males" backgroundColor="rgba(236, 236, 236, 0.60)" />
    ),
  },
  {
    component: (
      <HeaderChips
        value="Females"
        backgroundColor="rgba(236, 236, 236, 0.60)"
      />
    ),
  },
  {
    component: (
      <HeaderChips value="Other" backgroundColor="rgba(236, 236, 236, 0.60)" />
    ),
  },
  {
    component: (
      <HeaderChips value="Total" backgroundColor="rgba(255, 237, 143, 0.60)" />
    ),
  },
  {
    component: (
      <HeaderChips
        value="Percent"
        backgroundColor="rgba(236, 236, 236, 0.60)"
      />
    ),
  },
];

export function LocationMetricsTable(props: LocationMetricsTableProps) {
  const { metrics, loading } = props;
  const totalMalesTested = useMemo(
    () =>
      _.values(metrics?.participantsHearingLossByGender).reduce(
        (acc, item) => acc + item.males,
        0,
      ),
    [metrics],
  );
  const totalFemalesTested = useMemo(
    () =>
      _.values(metrics?.participantsHearingLossByGender).reduce(
        (acc, item) => acc + item.females,
        0,
      ),
    [metrics],
  );
  const totalOtherTested = useMemo(
    () =>
      _.values(metrics?.participantsHearingLossByGender).reduce(
        (acc, item) => acc + item.other,
        0,
      ),
    [metrics],
  );
  const totalParticipantsTested = useMemo(
    () =>
      _.values(metrics?.participantsHearingLossByGender).reduce(
        (acc, item) => acc + item.total,
        0,
      ),
    [metrics],
  );
  const totalParticipantsTestedPercent = useMemo(
    () =>
      (_.values(metrics?.participantsHearingLossByGender).reduce(
        (acc, item) => acc + item.total,
        0,
      ) /
        (metrics?.total || 1)) *
      100,
    [metrics],
  );

  const hearingLossRows = useMemo(
    () =>
      _.values(EHearingThresholdsNames).map((value) => ({
        title:
          value === EHearingThresholdsNames.Normal
            ? 'Normal Hearing'
            : `${value} Loss`,
        male: metrics?.participantsHearingLossByGender[value].males || 0,
        female: metrics?.participantsHearingLossByGender[value].females || 0,
        other: metrics?.participantsHearingLossByGender[value].other || 0,
        total: metrics?.participantsHearingLossByGender[value].total || 0,
        percent: `${(
          ((metrics?.participantsHearingLossByGender[value].total || 0) /
            (metrics?.total || 1)) *
            100 || 0
        ).toFixed(1)}%`,
      })),
    [metrics],
  );

  const hearingShiftRows = useMemo(
    () => [
      {
        title: 'Standard Threshold Shift',
        male: metrics?.participantsWithSTSShiftCountByGender.males || 0,
        female: metrics?.participantsWithSTSShiftCountByGender.females || 0,
        other: metrics?.participantsWithSTSShiftCountByGender.other || 0,
        total: metrics?.participantsWithSTSShiftCountByGender.total || 0,
        percent: `${(
          ((metrics?.participantsWithSTSShiftCountByGender.total || 0) /
            (metrics?.total || 1)) *
            100 || 0
        ).toFixed(1)}%`,
      },
      {
        title: 'OSHA Recordable Shift',
        male: metrics?.participantsWithOSHAShiftCountByGender.males || 0,
        female: metrics?.participantsWithOSHAShiftCountByGender.females || 0,
        other: metrics?.participantsWithOSHAShiftCountByGender.other || 0,
        total: metrics?.participantsWithOSHAShiftCountByGender.total || 0,
        percent: `${(
          ((metrics?.participantsWithOSHAShiftCountByGender.total || 0) /
            (metrics?.total || 1)) *
            100 || 0
        ).toFixed(1)}%`,
      },
    ],
    [metrics],
  );

  return (
    <TableContainer component={Box}>
      <Table
        sx={{
          minWidth: 500,
          width: '100%',
        }}
        aria-label="table"
      >
        <TableHead>
          <TableRow sx={{ borderBottom: 'none' }}>
            {HEADER_CELLS.map((c, index) => (
              <TableCell
                key={index}
                align="center"
                style={{ width: index === 0 ? '25%' : '15%', border: 'none' }}
              >
                {c.component}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                textAlign: 'right',
                border: 'none',
              }}
            >
              <Typography fontSize="14px">
                Number of Employees Tested
              </Typography>
            </TableCell>
            <MetricsTableCell loading={loading} sx={{ border: 'none' }}>
              {totalMalesTested}
            </MetricsTableCell>
            <MetricsTableCell loading={loading} sx={{ border: 'none' }}>
              {totalFemalesTested}
            </MetricsTableCell>
            <MetricsTableCell loading={loading} sx={{ border: 'none' }}>
              {totalOtherTested}
            </MetricsTableCell>
            <MetricsTableCell loading={loading} sx={{ border: 'none' }}>
              {totalParticipantsTested}
            </MetricsTableCell>
            <MetricsTableCell loading={loading} sx={{ border: 'none' }}>
              {totalParticipantsTestedPercent?.toFixed(1)}%
            </MetricsTableCell>
          </TableRow>
          {hearingLossRows.map((item) => (
            <TableRow>
              <MetricsTableCell
                sx={{
                  textAlign: 'left',
                  textTransform: 'none',
                }}
              >
                <Typography fontSize="15px">{item.title}</Typography>
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>{item.male}</MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.female}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.other}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.total}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.percent}
              </MetricsTableCell>
            </TableRow>
          ))}
          <TableRow sx={{ height: '37.5px' }}>
            <MetricsTableCell sx={{ border: 'none' }} />
            <MetricsTableCell sx={{ border: 'none' }} />
            <MetricsTableCell sx={{ border: 'none' }} />
            <MetricsTableCell sx={{ border: 'none' }} />
            <MetricsTableCell sx={{ border: 'none' }} />
            <MetricsTableCell sx={{ border: 'none' }} />
          </TableRow>
          {hearingShiftRows.map((item) => (
            <TableRow>
              <MetricsTableCell
                sx={{
                  textAlign: 'left',
                  textTransform: 'none',
                }}
              >
                <Typography fontSize="15px">{item.title}</Typography>
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>{item.male}</MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.female}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.other}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.total}
              </MetricsTableCell>
              <MetricsTableCell loading={loading}>
                {item.percent}
              </MetricsTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
