import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormikProps } from 'formik';
import { IDailyBiologicalReport } from '../../../../../api/services/organization/types';
import { StyledTableCell } from './components/StyledTableCell';
import { FilledTextInput } from '../FilledTextInput';

interface IBiologicalReportFormTableProps {
  baseline: IDailyBiologicalReport | null;
  formik: FormikProps<any>;
  loading?: boolean;
}

const HEADER_CELLS: Array<string> = [
  'Frequency kHz',
  'Baseline L',
  'L',
  'Baseline R',
  'R',
];

export function BiologicalReportFormTable(
  props: IBiologicalReportFormTableProps,
) {
  const { baseline, formik, loading } = props;

  const hearingLossRows = useMemo(() => {
    const frequencies = [500, 1000, 2000, 3000, 4000, 6000, 8000];
    return frequencies.map(
      (frequency): { [key: string]: ReactElement | string | number } => ({
        title: frequency / 1000,
        left: (
          <FilledTextInput
            {...formik.getFieldProps(`threshold_left_${frequency}`)}
            onChange={(e) => {
              const value = e.currentTarget.value;
              const isValid = /^\d+$/.test(value);
              formik.setFieldValue(
                `threshold_left_${frequency}`,
                isValid ? Number(value) : 0,
              );
            }}
          />
        ),
        right: (
          <FilledTextInput
            {...formik.getFieldProps(`threshold_right_${frequency}`)}
            onChange={(e) => {
              const value = e.currentTarget.value;
              const isValid = /^\d+$/.test(value);
              formik.setFieldValue(
                `threshold_right_${frequency}`,
                isValid ? Number(value) : 0,
              );
            }}
          />
        ),
        baselineLeft:
          (baseline?.[
            `threshold_left_${frequency}` as keyof IDailyBiologicalReport
          ] as number) ?? 'N/A',
        baselineRight:
          (baseline?.[
            `threshold_right_${frequency}` as keyof IDailyBiologicalReport
          ] as number) ?? 'N/A',
      }),
    );
  }, [formik.values]);

  return (
    <TableContainer component={Box}>
      <Table
        sx={{
          minWidth: 500,
          width: '100%',
        }}
        aria-label="table"
      >
        <TableHead>
          <TableRow sx={{ borderBottom: 'none' }}>
            {HEADER_CELLS.map((c) => (
              <TableCell
                key={c}
                align="center"
                style={{
                  width: '20%',
                  border: 'none',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
              >
                {c}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {hearingLossRows.map((item) => (
            <TableRow>
              <StyledTableCell>{item.title}</StyledTableCell>
              <StyledTableCell loading={loading}>
                {item.baselineLeft}
              </StyledTableCell>
              <StyledTableCell loading={loading}>{item.left}</StyledTableCell>
              <StyledTableCell loading={loading}>
                {item.baselineRight}
              </StyledTableCell>
              <StyledTableCell loading={loading}>{item.right}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
