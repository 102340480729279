import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { User } from 'api/services/auth/types';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { FormModal } from 'components/modal';
import { getErrorMessage, showSnackbar } from 'utils';
import { TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { getPhoneSchema } from 'utils/validation/validators';
import {
  convertPhoneToInternationalFormat,
  convertPhoneToTenDigitsFormat,
} from 'utils/phoneNumber';
import { AdminFormSkeleton } from './AdminFormSkeleton';

interface AdminFormProps {
  open: boolean;
  user?: User | null;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function AdminForm(props: AdminFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const { t, i18n } = useTranslation();

  const AdminFormSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('validation.invalidEmail'))
          .required(t('validation.required')),
        phone: getPhoneSchema(),
        given: Yup.string(),
        family: Yup.string(),
      }),
    [i18n.language],
  );

  const { open, user, handleClose, handleSubmit } = props;

  const initialValues = user
    ? {
        email: user.email,
        phone: user.phone ? convertPhoneToTenDigitsFormat(user.phone) : '',
        family: user.family,
        given: user.given,
      }
    : {
        email: '',
        phone: '',
        family: '',
        given: '',
      };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      if (user) await api.admin.updateAdmin(user.id, values);
      else
        await api.admin.createAdmin({
          ...values,
          phone: convertPhoneToInternationalFormat(values.phone),
        });

      handleSubmit();
      showSnackbar(
        user
          ? t('common.adminSuccessfullyUpdated')
          : t('common.adminSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(getErrorMessage(e, 'Could not save form'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={AdminFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={user ? t('common.editAdmin') : t('common.createAdmin')}
          isSubmitting={loading || !open}
        >
          {open ? (
            <FullHeightContainer maxWidth="xs" disableGutters>
              <Grid
                component="form"
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    handleSubmit();
                  }
                }}
                onSubmit={handleSubmit}
                container
                spacing={1}
              >
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.email')}
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.firstName')}
                    variant="outlined"
                    type="text"
                    name="given"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.given}
                    error={touched.given && Boolean(errors.given)}
                    helperText={
                      touched.given && errors.given ? errors.given : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.lastName')}
                    variant="outlined"
                    type="text"
                    name="family"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.family}
                    error={touched.family && Boolean(errors.family)}
                    helperText={
                      touched.family && errors.family ? errors.family : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      {error ? (
                        <FormAlert severity="error">{error}</FormAlert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FullHeightContainer>
          ) : (
            <AdminFormSkeleton />
          )}
        </FormModal>
      )}
    </Formik>
  );
}
