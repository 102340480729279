const isStartFromUSCode = (phoneNumber: string) =>
  phoneNumber.slice(0, 2) === '+1';
const isStartFromUACode = (phoneNumber: string) =>
  phoneNumber.slice(0, 3) === '+38';

export const convertPhoneToInternationalFormat = (phoneNumber: string) => {
  if (isStartFromUSCode(phoneNumber) || isStartFromUACode(phoneNumber))
    return phoneNumber;
  return `+1${phoneNumber}`;
};

export const convertPhoneToTenDigitsFormat = (phoneNumber: string) => {
  if (!isStartFromUSCode(phoneNumber) || isStartFromUACode(phoneNumber))
    return phoneNumber;
  return phoneNumber.substring(2);
};

export const convertPhoneFormFieldToInternationalFormat = (
  phoneNumber?: string | null,
) => {
  if (!phoneNumber) return '';
  return convertPhoneToInternationalFormat(phoneNumber);
};

export const convertPhoneFormFieldToTenDigitFormat = (
  phoneNumber?: string | null,
) => {
  if (!phoneNumber) return '';
  return convertPhoneToTenDigitsFormat(phoneNumber);
};
