import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { ParticipantSignedReports } from 'components/shared-pages/participant/signed-reports';

export function AdminParticipantSignedReportsPage() {
  const { participantId } = useParams();
  return (
    <BasicContainer>
      <ParticipantSignedReports participantId={Number(participantId)} />
    </BasicContainer>
  );
}
