import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import { ITab } from './types';
import { Tab, TabsContainer } from './components';
import { TabPanel } from './components/TabPanel';

interface IDashboardTabsProps {
  initialTabIndex?: number;
  tabs: ITab[];
  title?: string | ReactNode | ((tab: ITab) => string | ReactNode);
}

export function DashboardTabs(props: IDashboardTabsProps) {
  const { initialTabIndex, tabs, title = '' } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsTabIndex = searchParams.get('tabIndex');
  const [tabIndex, setTabIndex] = useState(
    searchParamsTabIndex ? +searchParamsTabIndex : initialTabIndex || 0,
  );
  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setSearchParams({ tabIndex: newValue.toString() });
      setTabIndex(newValue);
    },
    [],
  );

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid container justifyContent="space-between" mb={theme.spacing(2)}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h1">
            {_.isFunction(title) ? title?.(tabs[tabIndex]) : title}
          </Typography>
        </Grid>
        <Grid item maxWidth="100%">
          <TabsContainer
            value={tabIndex}
            onChange={handleChange}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.label}
                label={tab.label}
                icon={tab.icon}
                iconPosition={isMobileView ? 'top' : 'end'}
                disabled={tab.disabled}
              />
            ))}
          </TabsContainer>
        </Grid>
      </Grid>
      {tabs.map((tab, index) => (
        <TabPanel value={tabIndex} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
}
