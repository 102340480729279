import { IVisitTime } from 'api/services/organization/types';
import { DateTimeCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import { ColorFilledCell } from 'components/table/components/ColorFilledCell';
import { Grid } from '@mui/material';
import { InfoIcon } from 'components/common/Info-icon';

export const cells: (tz?: string) => Cell<IVisitTime>[] = (
  tz = 'America/New_York',
) => [
  {
    name: 'Day',
    key: 'startTime',
    render: (item: IVisitTime) => (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        wrap="nowrap"
      >
        <ColorFilledCell
          sx={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '-0.2px',
            minWidth: 180,
          }}
        >
          <DateTimeCell
            date={item.startTime}
            tz={tz}
            format="date"
            token="ccc, LLL dd, y"
          />
        </ColorFilledCell>
        <InfoIcon
          content={item.visitTimeShifts.map((shift) => (
            <div>{shift.shift.name}</div>
          ))}
        />
      </Grid>
    ),
  },
  {
    name: 'Start Time',
    key: 'startTime',
    render: (item: IVisitTime) => (
      <ColorFilledCell sx={{ fontFamily: 'DM Mono, monospace', minWidth: 80 }}>
        <DateTimeCell date={item.startTime} tz={tz} format="time" />
      </ColorFilledCell>
    ),
  },
  {
    name: 'End Time',
    key: 'endTime',
    render: (item: IVisitTime) => (
      <ColorFilledCell sx={{ fontFamily: 'DM Mono, monospace', minWidth: 80 }}>
        <DateTimeCell date={item.endTime} tz={tz} format="time" />
      </ColorFilledCell>
    ),
  },
  {
    name: '#OHCs',
    key: 'ohcCount',
    render: (item: IVisitTime) => (
      <ColorFilledCell sx={{ fontFamily: 'DM Mono, monospace' }}>
        {item.ohcCount}
      </ColorFilledCell>
    ),
  },
  {
    name: 'In Parallel',
    key: 'testsInParallel',
    render: (item: IVisitTime) => (
      <ColorFilledCell sx={{ fontFamily: 'DM Mono, monospace' }}>
        {item.testsInParallel}
      </ColorFilledCell>
    ),
  },
  {
    name: 'Test time',
    key: 'testLength',
    render: (item: IVisitTime) => (
      <ColorFilledCell
        sx={{ fontFamily: 'DM Mono, monospace' }}
      >{`${item.testLength}'`}</ColorFilledCell>
    ),
  },
];
