import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import { Grid, Typography } from '@mui/material';
import { HearingTestResult } from '../../../../api/services/hearing-test-results/types';

export const cells: Cell<HearingTestResult>[] = [
  {
    name: 'Name',
    key: 'name',
    width: '264px',
    renderHeaderCell: (name) => (
      <Grid
        container
        alignItems="center"
        sx={{
          height: '60px',
          padding: '16px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Typography
        sx={{
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        {result.participant?.given && result.participant?.family
          ? `${result.participant?.given} ${result.participant?.family}`
          : '-'}
      </Typography>
    ),
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  // TODO: SSN is not yet available on the platform
  // {
  //   name: 'ssn',
  //   key: 'ssn',
  //   align: 'right',
  //   renderHeaderCell: (name) => (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       sx={{
  //         height: '60px',
  //         padding: '16px',
  //         border: '1px solid rgba(202, 194, 190, 0.3)',
  //         textTransform: 'uppercase',
  //       }}
  //     >
  //       <Typography
  //         variant="mono"
  //         sx={{
  //           fontWeight: 400,
  //           fontSize: '13px',
  //         }}
  //       >
  //         {name}
  //       </Typography>
  //     </Grid>
  //   ),
  //   render: (result: HearingTestResult) => (
  //     <Typography
  //       variant="mono"
  //       sx={{
  //         fontSize: '16px',
  //         fontStyle: 'normal',
  //         fontWeight: 400,
  //         lineHeight: '120%',
  //         textTransform: 'uppercase',
  //       }}
  //     >
  //       {result.participant?.externalId || '-'}
  //     </Typography>
  //   ),
  // },
  // {
  //   name: '',
  //   key: 'divider',
  //   render: () => ' ',
  //   width: '8px',
  // },
  {
    name: 'ID',
    key: 'externalId',
    align: 'right',
    renderHeaderCell: (name) => (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '16px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Typography
        variant="mono"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '120%',
          textTransform: 'uppercase',
        }}
      >
        {result.participant?.externalId || '-'}
      </Typography>
    ),
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  {
    name: 'Status',
    key: 'referralComment',
    align: 'right',
    renderHeaderCell: (name) => (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '16px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Typography
        variant="mono"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '140%',
        }}
      >
        {result.status}
      </Typography>
    ),
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  {
    name: 'Shift',
    key: 'shift',
    align: 'right',
    renderHeaderCell: (name) => (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '16px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    ),
    render: (result: HearingTestResult) =>
      result?.participant?.shifts?.length
        ? result?.participant?.shifts?.map((shift) => (
            <Typography
              variant="mono"
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '140%',
              }}
            >
              {shift.name}{' '}
            </Typography>
          ))
        : '-',
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  {
    name: 'Test Date',
    key: 'testDateTime',
    align: 'right',
    renderHeaderCell: (name) => (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '16px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Typography
        variant="mono"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: '120%',
          textTransform: 'uppercase',
        }}
      >
        {result.dateTime
          ? `${DateTime.fromISO(result.dateTime).toFormat('MM/dd/yyyy')}`
          : '-'}
      </Typography>
    ),
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  {
    name: 'Actual Shift',
    key: 'referralComment',
    align: 'center',
    width: '140px',
    renderHeaderCell: (name) => (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '4px 8px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Typography
            variant="mono"
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              padding: '0 8px',
            }}
          >
            Right
          </Typography>
          <Typography
            variant="mono"
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              padding: '0 8px',
            }}
          >
            Left
          </Typography>
        </Grid>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Grid container justifyContent="space-around" wrap="nowrap">
        <Typography
          variant="mono"
          sx={{
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            padding: '0 8px',
          }}
        >
          {Math.floor(result?.rightSTS || 0)}
        </Typography>
        <Typography
          variant="mono"
          sx={{
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            padding: '0 8px',
          }}
        >
          {Math.floor(result?.leftSTS || 0)}
        </Typography>
      </Grid>
    ),
  },
  {
    name: '',
    key: 'divider',
    render: () => ' ',
    width: '8px',
  },
  {
    name: 'Age Cor',
    key: 'referralComment',
    align: 'center',
    width: '140px',
    renderHeaderCell: (name) => (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '60px',
          padding: '4px 8px',
          border: '1px solid rgba(202, 194, 190, 0.3)',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant="mono"
          sx={{
            fontWeight: 400,
            fontSize: '13px',
          }}
        >
          {name}
        </Typography>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Typography
            variant="mono"
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              padding: '0 8px',
            }}
          >
            Right
          </Typography>
          <Typography
            variant="mono"
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              padding: '0 8px',
            }}
          >
            Left
          </Typography>
        </Grid>
      </Grid>
    ),
    render: (result: HearingTestResult) => (
      <Grid container justifyContent="space-around" wrap="nowrap">
        <Typography
          variant="mono"
          sx={{
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            padding: '0 8px',
          }}
        >
          {Math.floor(result?.rightAgeAdjustedSTS || 0)}
        </Typography>
        <Typography
          variant="mono"
          sx={{
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            padding: '0 8px',
          }}
        >
          {Math.floor(result?.leftAgeAdjustedSTS || 0)}
        </Typography>
      </Grid>
    ),
  },
];
