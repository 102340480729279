import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import { ILocation } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { PageHeader } from 'components/admin-page-layout';
import * as Yup from 'yup';
import { useOrganization } from 'hooks/useOrganization';
import { LocationForm } from 'components/forms/location-form';
import { getCells } from './cells';

export function AccountManagerLocationsList() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [formOpen, setFormOpen] = useState(false);
  const [editedLocation, setEditedLocation] = useState<ILocation | null>(null);
  const { t, i18n } = useTranslation();
  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );

  const fetchLocations = useCallback(
    async (limit: number, offset: number) => {
      if (!organizationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.organization.getLocations(
        organizationId,
        { limit, offset },
      );
      return locationsData;
    },
    [organizationId],
  );

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchLocations,
  });

  const actions = [
    {
      title: t('common.edit'),
      onClick: (location: ILocation) => {
        setEditedLocation(location);
        setFormOpen(true);
      },
    },
    {
      title: t('common.uploadRoster'),
      onClick: (location: ILocation) => {
        navigate(
          `/admin/organizations/${organizationId}/locations/${location.id}/roster-uploads`,
        );
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  const LocationFormSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        externalId: Yup.string().required(t('validation.required')),
        line1: Yup.string().required(t('validation.required')),
        line2: Yup.string().optional(),
        country: Yup.string().required(t('validation.required')),
        state: Yup.string().required(t('validation.required')),
        postalCode: Yup.string().required(t('validation.required')),
        city: Yup.string().required(t('validation.required')),
        amountOfMonthsNewEmployeeShouldBeTested: Yup.number().required(
          t('validation.required'),
        ),
        amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
          Yup.number().required(t('validation.required')),
        questionnaireResourceType: Yup.string().required(
          t('validation.required'),
        ),
      }),
    [i18n.language],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={t('common.locations')}
        entityName={organization?.name}
        titleLoading={isOrganizationLoading}
      />

      <BasicTable<ILocation>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {formOpen && organizationId && (
        <LocationForm
          organizationId={organizationId}
          location={editedLocation}
          validationSchema={LocationFormSchema}
          handleClose={() => setFormOpen(false)}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedLocation(null);
            if (editedLocation) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
    </BasicContainer>
  );
}
