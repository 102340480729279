import { useCallback, useEffect, useState } from 'react';
import { api } from 'api';
import { ILocation } from 'api/services/organization/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { useAuthSelector } from 'store/selectors/auth';

type UseOHCLocationsProps = {
  prefetch?: boolean;
  prefetchLocationsLimit?: number;
};

export const useOHCLocations = (props: UseOHCLocationsProps = {}) => {
  const { prefetch = true, prefetchLocationsLimit = 100 } = props;
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const { user } = useAuthSelector();

  const fetchOhcLocations = useCallback(
    async (limit: number, offset: number) => {
      if (!user) {
        return EMPTY_PAGINATED_DATA;
      }

      setAreLocationsLoading(true);

      const locationsData = await api.user.getOhcLocations(user.id, {
        limit,
        offset,
      });

      setAreLocationsLoading(false);
      setLocations(locationsData.items);

      return locationsData;
    },
    [user],
  );

  useEffect(() => {
    if (prefetch) fetchOhcLocations(prefetchLocationsLimit, 0);
  }, []);

  return { fetchOhcLocations, locations, areLocationsLoading };
};
