import { useEffect, useState } from 'react';
import { api } from 'api';
import { Organization } from 'api/services/organization/types';

export const useOrganization = (organizationId: number | null) => {
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const getOrganization = async () => {
    if (organizationId)
      try {
        setIsOrganizationLoading(true);
        const organization = await api.organization.getOrganizationById(
          organizationId,
        );
        setOrganization(organization);
      } catch (e) {
        console.log(e);
      }

    setIsOrganizationLoading(false);
  };

  useEffect(() => {
    getOrganization();
  }, [organizationId]);

  return { organization, isOrganizationLoading };
};
