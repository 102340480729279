import { Grid, Typography } from '@mui/material';
import { Heading } from 'components/common/heading';
import { BoldText } from './BoldText';
import { Subheading } from './Subheading';

function PrivacyPolicyContent() {
  return (
    <Grid
      item
      container
      sx={{ maxWidth: '842px', overflowWrap: 'anywhere' }}
      direction="column"
      alignItems="center"
    >
      <Typography variant="body1">
        <Heading variant="h5" sx={{ fontWeight: 700 }} align="center">
          Tuned Privacy Policy
        </Heading>

        <p>
          <BoldText>Last Updated:</BoldText> 11/22/2023
        </p>
        <Subheading>Intro/Scope</Subheading>
        <p>
          Tuned Care, Inc. (“Tuned,” “we,” “our,” and/or “us”) value the privacy
          of individuals who use our application, websites, and related services
          (collectively, our “Services”). This privacy policy (the “Privacy
          Policy”) explains how we collect, use, and share information from
          users of our Services (“Users”). In addition to visitors to our
          website, www.tunedcare.com, this Privacy Policy applies to individuals
          who use our Services to obtain audiology services and products
          (“Individual”), audiologists or other hearing specialists who use our
          Services (“Audiologist”), and customers who are part of an employer or
          employer-sponsored hearing benefit (“Qualified Member”). By using our
          Services, you agree to the collection, use, disclosure, and procedures
          this Privacy Policy describes. Beyond the Privacy Policy, your use of
          our Services is also subject to our Terms of Service (
          <a href="https://www.tunedcare.com/general-terms-of-use">
            https://www.tunedcare.com/general-terms-of-use
          </a>
          ).
        </p>
        <Subheading>Information We Collect</Subheading>
        <p>
          We may collect a variety of information from or about you or your
          devices from various sources, as described below.
        </p>
        <p>
          <BoldText>A. Information You Provide to Us.</BoldText>
        </p>
        <p>
          <BoldText>Registration and Profile Information.</BoldText> If you sign
          up for an account, register to use our Services, sign up for
          notifications or updates, or participate in our surveys, we ask you
          for your such as your name, date of birth, gender, full physical
          address, email address, and phone number. Additionally, if you
          register for Services as a Qualified Member, then we collect
          information about authorized users in order to authenticate their use
          of the Services and other business purposes. If you register for
          Services as an audiologist, we collect your name, email, phone,
          address, state in which you are licensed, and other information about
          your practice as an audiologist.
        </p>
        <p>
          <BoldText>Communications.</BoldText> If you contact us directly, we
          may receive additional information about you. For example, when you
          contact our customer support team, we will receive your name, email
          address, phone number, the contents of a message or attachments that
          you may send to us, and other information you choose to provide. If
          you subscribe to our newsletter, then we will collect certain
          information from you, such as your email address. When we send you
          emails, we may track whether you open them to learn how to deliver a
          better customer experience and improve our Services.
        </p>
        <p>
          <BoldText>Careers and Independent Contractors.</BoldText> If you
          decide that you wish to apply for a full-time job with us, you may
          submit your contact information and your resume online. We will
          collect the information you choose to provide on your resume, such as
          your education and employment experience. You may also apply through
          LinkedIn. If you do so, we will collect the information you make
          available to us on LinkedIn. In addition, if you apply to be a 1099
          independent contractor with us, we will collect information in
          connection with execution of our Provider Terms, including your name,
          email, phone, address, state in which you are licensed, and other
          information about your practice as an audiologist.
        </p>
        <p>
          <BoldText>Payment and Fulfillment Information.</BoldText> If you make
          a purchase through our Services, your payment-related information,
          such as credit card, billing, or other financial information, is
          collected by our third-party payment processor on our behalf. Shipping
          information may be shared with third-party vendors that we retain to
          ship any products you order from us.
        </p>
        <p>
          <BoldText>Hearing and other Health-Related Information.</BoldText> Our
          Services include the ability to take hearing screening to assist you
          in identifying products you may want to purchase. Our Services also
          include the ability for you to connect with an audiologist to share
          your assessments and discuss your hearing needs. Audiologists may
          collect additional information about your medical status and upload
          such information to the Services, such as in-clinic hearing screening
          and previous medical history. We will store and maintain such
          information that you share with your audiologist through the Services.
        </p>
        <p>
          <BoldText>
            B. Information We Collect When You Use Our Services.
          </BoldText>
        </p>
        <p>
          <BoldText>Location Information.</BoldText> When you use our Services,
          we can infer your general location information, for example by using
          your internet protocol (IP) address.
        </p>
        <p>
          <BoldText>Device Information.</BoldText> We receive information about
          the device and software you use to access our Services, including IP
          address, web browser type, operating system version, phone carrier and
          manufacturer, application installations, device identifiers, mobile
          advertising identifiers, and push notification tokens.
        </p>
        <p>
          <BoldText>Usage Information.</BoldText> To help us understand how you
          use our Services and to help us improve them, we automatically receive
          information about your interactions with our Services, like the [pages
          or other content you view, the searches you conduct, people you
          follow, purchases you make, your comments, any content you post, and
          the dates and times of your visits].
        </p>
        <p>
          <BoldText>
            Information from Cookies and Similar Technologies.
          </BoldText>{' '}
          We and third-party partners collect information using cookies, pixel
          tags, or similar technologies. Our third-party partners, such as
          analytics and advertising partners, may use these technologies to
          collect information about your online activities over time and across
          different services. Cookies are small text files containing a string
          of alphanumeric characters. We may use both session cookies and
          persistent cookies. A session cookie disappears after you close your
          browser. A persistent cookie remains after you close your browser and
          may be used by your browser on subsequent visits to our Services.
        </p>
        <p>
          Please review your web browser’s “Help” file to learn the proper way
          to modify your cookie settings. Please note that if you delete or
          choose not to accept cookies from the Service, you may not be able to
          utilize the features of the Service to their fullest potential.
        </p>
        <p>
          <BoldText>Call and Text Information.</BoldText> We work with a
          third-party partner to facilitate phone calls and appointment reminder
          text messages between Providers and Qualified Members who have been
          connected. We receive information about these communications, such as
          the date and time of the call or SMS message, the parties’ phone
          numbers, and the content of any SMS messages. For security purposes,
          we may also monitor and/or record the contents of phone calls made on
          our Services, such as those between Tuned support staff, Providers, or
          Qualified Members. You will be given notice that your call may be
          recorded, and by proceeding you agree to allow us to monitor and/or
          record your call.
        </p>
        <p>
          <BoldText>C. Information We Receive from Third Parties.</BoldText>
        </p>
        <p>
          <BoldText>Information from third party services.</BoldText> If you
          choose to link our Services to a third-party account, we may receive
          information about you. If you wish to limit the information available
          to us, you should visit the privacy settings of your third-party
          accounts to learn about your options.
        </p>
        <p>
          <BoldText>Other third parties.</BoldText> We may receive additional
          information about you, such as demographic data, from third parties
          such as data or marketing partners and combine it with other
          information we have about you.
        </p>
        <Subheading>How We Use the Information We Collect</Subheading>
        <p>We use the information we collect:</p>
        <p>&#8226; To provide, maintain, improve, and enhance our Services;</p>
        <p>
          &#8226; To personalize your experience on our Services such as by
          providing tailored content and recommendations;
        </p>
        <p>
          &#8226; To understand and analyze how you use our Services and develop
          new products, services, features, and functionality;
        </p>
        <p>
          &#8226; To communicate with you, provide you with updates and other
          information relating to our Services, provide information that you
          request, respond to comments and questions, and otherwise provide
          customer support;
        </p>
        <p>
          &#8226; To facilitate the connection of third-party services or
          applications, such as hearing screenings;
        </p>
        <p>
          &#8226; To generate anonymized, aggregate data containing only
          de-identified, non-personal information that we may use to publish
          reports;
        </p>
        <p>&#8226; To send you appointment reminder text messages;</p>
        <p>&#8226; To facilitate transactions and payments;</p>
        <p>
          &#8226; To find and prevent fraud, and respond to trust and safety
          issues that may arise;
        </p>
        <p>
          &#8226; For compliance purposes, including enforcing our Terms of
          Service or other legal rights, or as may be required by applicable
          laws and regulations or requested by any judicial process or
          governmental agency; and
        </p>
        <p>
          &#8226; For other purposes for which we provide specific notice at the
          time the information is collected.
        </p>

        <Subheading>How We Share the Information We Collect</Subheading>
        <p>
          <BoldText>Audiologists and other Partners.</BoldText> We share
          information provided by Individual Users with Provider Users in order
          to facilitate the Services. We may also share information with
          third-party partners, for example to fulfill orders you place on the
          website.
        </p>
        <p>
          <BoldText>Vendors and Service Providers.</BoldText> We may share any
          information we receive with vendors and service providers retained in
          connection with the provision of our Services.
        </p>
        <p>
          <BoldText>User Content.</BoldText> Our Services include the ability
          for Provider Users to share questions, information, and other content
          through Tuniversity. If you are a Provider User who chooses to use
          this portion of the Services, your name and other profile information
          will always be viewable by other users. As a Provider User, content
          you post to the Services through Tuniversity will be displayed on the
          Services and viewable by other users by default, and some may be
          displayed publicly. We are not responsible for the other users’ use of
          available information, so you should carefully consider whether and
          what to post or how you identify yourself on the Platform.
        </p>
        <p>
          <BoldText>Sharing Between Users.</BoldText> Individual Users and
          Provider Users share information outlined in this Privacy Policy,
          including personal and medical information, to enable each User’s use
          of the Services.
        </p>
        <p>
          <BoldText>As Required by Law and Similar Disclosures.</BoldText> We
          may access, preserve, and disclose your information if we believe
          doing so is required or appropriate to: (a) comply with law
          enforcement requests and legal process, such as a court order or
          subpoena; (b) respond to your requests; or (c) protect your, our, or
          others’ rights, property, or safety. For the avoidance of doubt, the
          disclosure of your information may occur if you post any objectionable
          content on or through the Platform.
        </p>
        <p>
          <BoldText>Merger, Sale, or Other Asset Transfers.</BoldText> We may
          transfer your information to service providers, advisors, potential
          transactional partners, or other third parties in connection with the
          consideration, negotiation, or completion of a corporate transaction
          in which we are acquired by or merged with another company or we sell,
          liquidate, or transfer all or a portion of our assets. The use of your
          information following any of these events will be governed by the
          provisions of this Privacy Policy in effect at the time the applicable
          information was collected.
        </p>
        <p>
          <BoldText>Consent.</BoldText>Where you have provided consent, we share
          your information, including personal information, as described at the
          time of consent, such as when you authorize a third-party application
          or website to access your TunedCare account or when you participate in
          promotional activities conducted by TunedCare partners or third
          parties. For example, we request your consent to send you email
          communications about products or services available through our Site,
          or a monthly newsletter, using a third party email service provider.
          You can withdraw your consent at any time by contacting us at{' '}
          <a href="mailto:support@tunedcare.com">support@tunedcare.com</a>.
        </p>

        <Subheading>Your Choices</Subheading>
        <p>
          <BoldText>Location Information.</BoldText> You can prevent your device
          from sharing precise location information at any time through your
          device’s operating system settings.
        </p>
        <p>
          <BoldText>Marketing Communications.</BoldText> You can unsubscribe
          from our promotional emails via the link provided in the emails. Even
          if you opt-out of receiving promotional messages from us, you will
          continue to receive administrative messages from us.
        </p>
        <p>
          <BoldText>Do Not Track.</BoldText> There is no accepted standard on
          how to respond to Do Not Track signals, and we do not respond to such
          signals.
          <br />
          <BoldText>
            If you choose not to provide us with information we collect, some
            features of our Services may not work as intended.
          </BoldText>
        </p>
        <Subheading>Third Parties</Subheading>
        <p>
          Our Services may contain links to other websites, products, or
          services that we do not own or operate. We are not responsible for the
          privacy practices of these third parties. Please be aware that this
          Privacy Policy does not apply to your activities on these third-party
          services or any information you disclose to these third parties. We
          encourage you to read their privacy policies before providing any
          information to them.
        </p>
        <Subheading>Security</Subheading>
        <p>
          We make reasonable efforts to protect your information by using
          physical and electronic safeguards designed to improve the security of
          the information we maintain. However, as no electronic transmission or
          storage of information can be entirely secure, we can make no
          guarantees as to the security or privacy of your information.
        </p>
        <Subheading>
          Confidentiality and Security of Health Information
        </Subheading>
        <p>
          Some of our users are subject to laws and regulations governing the
          use and disclosure of health information they create or receive.
          Included among them is the Health Insurance Portability and
          Accountability Act of 1996 (“HIPAA”), the Health Information
          Technology for Economic and Clinical Health of 2009 (“HITECH”), and
          the regulations adopted thereunder. When we store, process or transmit
          certain individually identifiable health information or “Protected
          Health Information” (as such term is defined by HIPAA) on behalf of a
          healthcare provider, we do so as its “business associate” (as also
          defined by HIPAA) under a Business Associate Agreement. Under this
          agreement, we are prohibited from, among other things, using Protected
          Health Information in a manner that the provider itself may not. We
          are also required to, among other things, apply reasonable and
          appropriate measures to safeguard the confidentiality, integrity and
          availability of Protected Health Information we store and process on
          behalf of such providers. We are also subject to laws and regulations
          governing the use and disclosure of certain personal and health
          information, including HIPAA, when we operate as a business associate
          of a healthcare provider.
        </p>
        <Subheading>Children’s Privacy</Subheading>
        <p>
          Children under the age of 13 are not eligible to use the Services and
          must not attempt to submit any personal information to us. Without
          limiting the generality of the foregoing, our Services do allow users
          above the age of 18 years old - such as parents and guardians - to
          submit personal information about dependants, including minors age 13
          and above. Such users assume full responsibility over their
          submission, use and transmission of such information.
        </p>
        <p>
          We do not knowingly collect personal information from any person who
          is under the age of 13 or allow them to register. If it comes to our
          attention that we have collected personal data from a person under the
          age of 13, we will delete this information as quickly as possible.
        </p>
        <Subheading>International Visitors</Subheading>
        <p>
          Our Services are hosted in the United States and intended for visitors
          located within the United States. If you choose to use Platform from
          the European Union or other regions of the world with laws governing
          data collection and use that may differ from U.S. law, then please
          note that you are transferring your personal information outside of
          those regions to the United States for storage and processing. Also,
          we may transfer your data from the U.S. to other countries or regions
          in connection with storage and processing of data, fulfilling your
          requests, and operating the Platform. By providing any information,
          including personal information, on or to the Platform, you consent to
          such transfer, storage, and processing.
        </p>
        <Subheading>Data Retention, Access, and Deletion</Subheading>
        <p>
          Except as may be inherent in the features and functionality of the
          Services, Tuned does not have a mechanism for you to review, edit or
          delete all of your information on or stored in the Services or
          otherwise by us. In the future, Tuned may implement a system to allow
          for a complete review and deletion of all of your information, but we
          have no obligation to provide or create such a mechanism.
        </p>
        <p>
          If you would like us to delete your Personal Information in our
          system, please contact us at{' '}
          <a href="mailto:support@tunedcare.com">support@tunedcare.com</a> and
          we will accommodate your request (but will not do so if we have any
          legal obligation to retain the record). Upon receipt of a Data Subject
          Access Request, we ask individual users to identify themselves and the
          information requested to be accessed, corrected or removed before
          processing such requests. If Tuned is unable to destroy or delete the
          information due to legal obligations or because it is not feasible or
          because it is unduly burdensome, impractical or would jeopardize the
          privacy of others or the integrity of the Services, Tuned shall
          provide a written response stating the reason(s) it cannot fulfill the
          request.
        </p>
        <p>
          Because of the way we maintain certain Services, after you delete your
          information, residual copies may take a period of time before they are
          deleted from our active servers and may remain in our backup systems
          for a limited time. We are not responsible for updating, correcting or
          removing any of your information disclosed to or held by third
          parties.
        </p>
        <p>
          Please be aware that Tuned is under no obligation to maintain and
          store any of your information (including any Personal Information or
          User Content) or otherwise to maintain and operate the Services. Tuned
          reserves the right o cease or modify operation of the Services at any
          time - in such case, your information (including any Personal
          Information and User Content) may no longer be available. If you
          desire to maintain access to any such information, you are encouraged
          to keep a backup of such information in other locations.
        </p>
        <p>
          You can update your account and profile information or close your
          account by contacting{' '}
          <a href="mailto:support@tunedcare.com">support@tunedcare.com</a>. If
          you have questions about your privacy on Platform or this privacy
          policy, please contact us at{' '}
          <a href="mailto:support@tunedcare.com">support@tunedcare.com</a>.
        </p>
        <Subheading>Changes to this Privacy Policy</Subheading>
        <p>
          We will post any adjustments to the Privacy Policy on this page, and
          the revised version will be effective when it is posted. If we
          materially change the ways in which we use or share personal
          information previously collected from you through Platform, we will
          notify you through Platform, by email, or other communication.
        </p>
        <Subheading>Contact Information</Subheading>
        <p>
          If you have any questions, comments, or concerns about our processing
          activities, please email us at{' '}
          <a href="mailto:support@tunedcare.com">support@tunedcare.com</a>.
        </p>
      </Typography>
    </Grid>
  );
}

export default PrivacyPolicyContent;
