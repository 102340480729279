import React, { useCallback, useMemo } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { BasicContainer } from 'components/container';
import { cells } from './cells';
import {
  EReportStatuses,
  ESignedDocumentStatuses,
  IDailyBiologicalReport,
} from '../../../../api/services/organization/types';
import { useLocation } from '../../../../hooks/useLocation';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { PageHeader } from '../../../../components/admin-page-layout';
import { Button } from '../../../../components/common/button';

export function DailyBiologicalReportsList() {
  const { locationId } = useParams();

  const fetchReferralLists = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId) {
        return EMPTY_PAGINATED_DATA;
      }
      return api.organization.getDailyBiologicalReportsForLocation(locationId, {
        limit,
        offset,
      });
    },
    [locationId],
  );

  const tableState = useTableState<IDailyBiologicalReport>({
    fetchDataFunction: fetchReferralLists,
  });

  const { location, isLocationLoading } = useLocation();

  const navigate = useNavigate();

  const actions = useMemo(
    () => [
      {
        title: 'Download',
        onClick: async (report: IDailyBiologicalReport) => {
          try {
            const fileName = `Daily Biological Report - ${
              location?.name || ''
            } (${DateTime.fromISO(report.dateTime as string).toFormat(
              'MM/dd/yyyy h:mm a',
            )}).pdf`;
            const data =
              await api.organization.downloadSignedDailyBiologicalReport(
                report.id,
              );
            FileDownload(data, fileName);
          } catch (e) {
            showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
              variant: 'error',
            });
          }
        },
        disabled: (report: IDailyBiologicalReport) =>
          report.signedDocument?.status !== ESignedDocumentStatuses.Signed,
      },
      {
        title: 'Edit',
        onClick: (report: IDailyBiologicalReport) => {
          navigate(
            `/admin/organizations/${location?.managingOrganization}/locations/${locationId}/daily-biological-reports/${report.id}/update`,
          );
        },
        disabled: (report: IDailyBiologicalReport) =>
          report.status !== EReportStatuses.Draft,
      },
    ],
    [location],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Daily biological reports"
        entityName={location?.name}
        titleLoading={isLocationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(
                `/admin/organizations/${location?.managingOrganization}/locations/${locationId}/daily-biological-reports/create`,
              );
            }}
          >
            Create a new Daily Biological Report
          </Button>
        }
      />
      <BasicTable<IDailyBiologicalReport>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
    </BasicContainer>
  );
}
