import { BoxProps } from '@mui/material';
import { Tooltip } from 'components/common/tooltip';
import { ColorFilledCell } from './ColorFilledCell';

enum ShiftColors {
  '#FFF5C6',
  '#FFC9C6',
  '#C6E0FF',
  '#FFC4F6',
  '#FFE2C1',
  '#ECE4FF',
  '#E3FFC6',
  '#BFFFD5',
  '#8FE0B0',
  '#C3FCFD',
  '#A6FFE4',
  '#DEBDFF',
}

interface IShiftNameCellProps extends BoxProps {
  name: string;
  shiftId: number | string;
}

export function ShiftNameCell(props: IShiftNameCellProps) {
  const { name, shiftId, sx } = props;
  const index = Number(shiftId) % 12;
  const color = ShiftColors[index];
  return (
    <Tooltip title={name}>
      <span>
        <ColorFilledCell
          sx={{
            backgroundColor: color,
            maxWidth: 120,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 90,
            whiteSpace: 'nowrap',
            ...sx,
          }}
        >
          {name}
        </ColorFilledCell>
      </span>
    </Tooltip>
  );
}
