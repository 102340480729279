import { TimePeriod } from './TimePeriod';
import { WeekDay } from './types';

export const DEFAULT_TIME_PERIOD_FROM = { hours: 9, minutes: 0 };
export const DEFAULT_TIME_PERIOD_TO = { hours: 17, minutes: 0 };

export const ERRORS = {
  FROM_ERROR: 'Must be before the "to" time',
  TO_ERROR: 'Must be after the "from" time',
  PERIOD_ERROR: 'Current time is already taken',
};

export const INITIAL_SCHEDULE_STATE = {
  [WeekDay.Mon]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Tue]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Wed]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Thu]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Fri]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Sat]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
  [WeekDay.Sun]: {
    isWorkingDay: false,
    workingPeriods: [
      new TimePeriod(DEFAULT_TIME_PERIOD_FROM, DEFAULT_TIME_PERIOD_TO),
    ],
  },
};

export const INITIAL_SIMPLE_TIME_ERRORS = {
  from: '',
  to: '',
};

export const TIME_DROPDOWN_OPTIONS = {
  time: [
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
  ],
  ampm: ['AM', 'PM'],
};
