import {
  HCPSupervisorBody,
  MfaMethod,
  MfaMethodListItem,
  User,
} from 'api/services/auth/types';
import {
  IAccountManagerBody,
  IProfessionalSupervisorBody,
} from '../../../api/services/user/types';

export interface AuthState {
  user?: User | null;
  error?: string | string[] | null;
  loading: boolean;
  token: string | null;
  mfaRequired: boolean;
  mfaMethods: MfaMethodListItem[];
  mfaUserId: number | null;
  deviceToken: string | null;
}

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_AS_USER_REQUEST = 'LOGIN_AS_USER_REQUEST';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const INVITATION_SIGN_UP_REQUEST = 'INVITATION_SIGN_UP_REQUEST';
export const PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST =
  'PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST';
export const ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST =
  'ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST';
export const HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST =
  'HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST';
export const USER_IDENTITY_REQUEST = 'USER_IDENTITY_REQUEST';
export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_AUTH = 'SET_AUTH';
export const SET_MFA_REQUIRED = 'SET_MFA_REQUIRED';
export const VERIFY_PARTICIPANT_REQUEST = 'VERIFY_PARTICIPANT_REQUEST';

export interface SetLoginUserAction {
  type: typeof SET_LOGIN_USER;
  payload: User | null;
}

export interface SetAuthErrorAction {
  type: typeof SET_AUTH_ERROR;
  payload: string | string[] | null;
}

export interface SetLoginLoadingAction {
  type: typeof SET_AUTH_LOADING;
  payload: boolean;
}

export interface SetAuthAction {
  type: typeof SET_AUTH;
  payload: AuthPayload;
}

export interface SetMfaRequiredAction {
  type: typeof SET_MFA_REQUIRED;
  payload: { mfaRequired: boolean; mfaMethods: MfaMethodListItem[] };
}

export interface LogoutAction {
  type: typeof LOGOUT_USER_REQUEST;
  payload: undefined;
}

export type AuthActions =
  | SetLoginUserAction
  | SetAuthErrorAction
  | SetLoginLoadingAction
  | SetAuthAction
  | SetMfaRequiredAction
  | LogoutAction;

export interface LoginUserPayload {
  email: string;
  password: string;
  rememberDevice: boolean;
  deviceToken?: string;
}

export interface LoginAsUserPayload {
  userId: number;
}

export type LoginWithMfaPayload = LoginUserPayload & {
  MFACode: string;
  MFAMethod: MfaMethod;
};

export interface VerifyParticipantPayload {
  family: string;
  birthDate: string;
  organizationName: string;
}

export interface InvitationSignUpPayload {
  password: string;
  token: string;
  acceptedTermsOfUse: boolean;
}

export interface ProfessionalSupervisorInvitationSignUpPayload
  extends IProfessionalSupervisorBody {
  password: string;
  token: string;
  acceptedTermsOfUse: boolean;
}

export interface accountManagerInvitationSignUpPayload
  extends IAccountManagerBody {
  password: string;
  token: string;
  acceptedTermsOfUse: boolean;
}

export interface HCPSupervisorInvitationSignUpPayload
  extends HCPSupervisorBody {
  password: string;
  token: string;
  acceptedTermsOfUse: boolean;
}

export interface MfaReduxState {
  mfaRequired: boolean;
  mfaMethods: MfaMethodListItem[];
  mfaUserId: number | null;
}

export interface AuthPayload extends Partial<MfaReduxState> {
  user?: User | null;
  token?: string | null;
  deviceToken?: string | null;
  loading?: boolean;
  error?: string | string[] | null;
}
