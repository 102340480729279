import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { User } from 'api/services/auth/types';
import { ConfirmationModal } from 'components/modal';
import { getErrorMessage, showSnackbar } from 'utils';

interface ChangeAdminStatusModalProps {
  open: boolean;
  user: User;
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

export function ChangeAdminStatusModal(props: ChangeAdminStatusModalProps) {
  const { open, user, onClose, onSuccess } = props;
  const { t } = useTranslation();

  const newStatusString = user.active ? 'deactivate' : 'activate';

  const handleChangeAdminStatusSubmit = async () => {
    try {
      await api.admin.changeAdminStatus(user.id, { active: !user.active });

      showSnackbar(
        user.active
          ? t('common.adminSuccessfullyDeactivated')
          : t('common.adminSuccessfullyActivated'),
        { variant: 'success' },
      );

      onSuccess();
      onClose();
    } catch (e: any) {
      showSnackbar(getErrorMessage(e, t('common.couldNotChangeAdminStatus')), {
        variant: 'error',
      });
    }
  };

  return (
    <ConfirmationModal
      open={open}
      handleClose={onClose}
      handleSubmit={handleChangeAdminStatusSubmit}
      submitButtonTitle={t(`common.${newStatusString}`)}
      title={
        <>
          {user.active
            ? t('common.youAreAboutToDeactivateGivenFamily', {
                given: user.given,
                family: user.family,
              })
            : t('common.youAreAboutToActivateGivenFamily', {
                given: user.given,
                family: user.family,
              })}
          <br />
          {t('common.areYouSure')}
        </>
      }
    />
  );
}
