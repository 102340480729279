export enum WEEK_DAYS {
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  Sun,
}

export enum DateFormats {
  FILE_DATE_FORMAT = 'MMddyyyy',
}
