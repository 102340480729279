import { Fade, Grid } from '@mui/material';
import { ReactNode } from 'react';

interface ITabPanelProps {
  value: number;
  index: number;
  children: ReactNode;
}

export function TabPanel(props: ITabPanelProps) {
  const { value, index, children } = props;
  return value === index ? (
    <Fade
      in={value === index}
      timeout={200}
      easing={{ enter: 'ease-in', exit: 'ease-out' }}
    >
      <Grid>{children}</Grid>
    </Fade>
  ) : null;
}
