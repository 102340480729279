import { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ERRORS,
  SimpleShiftCalendarRow,
  TimeSelect,
  timeParser,
} from './components';
import {
  ISimpleTimeErrors,
  IWeeklySchedule,
  WeekDay,
} from './components/types';
import { TimePeriod } from './components/TimePeriod';

interface SimpleShiftCalendarProps {
  schedule: IWeeklySchedule;
  setSchedule: Dispatch<SetStateAction<IWeeklySchedule>>;
  simpleTimeErrors: ISimpleTimeErrors;
  setSimpleTimeErrors: Dispatch<SetStateAction<ISimpleTimeErrors>>;
  readOnly?: boolean;
}

export function SimpleShiftCalendar({
  schedule,
  setSchedule,
  simpleTimeErrors,
  setSimpleTimeErrors,
  readOnly,
}: SimpleShiftCalendarProps) {
  const { t } = useTranslation();
  const handleChangeDayStatus =
    (weekDay: string) => (isWorkingDay: boolean) => {
      setSchedule((schedule) => {
        const temp = _.cloneDeep(schedule);
        temp[weekDay as unknown as keyof IWeeklySchedule].isWorkingDay =
          isWorkingDay;
        return temp;
      });
    };

  const onAllTimePeriodsChange = (updatedTimePeriod: Partial<TimePeriod>) => {
    const isTimePeriodNotValid =
      timeParser(
        updatedTimePeriod?.from || schedule[0].workingPeriods[0].from,
      ) >=
      timeParser(updatedTimePeriod?.to || schedule[0].workingPeriods[0].to);

    if (isTimePeriodNotValid) {
      setSimpleTimeErrors({ from: ERRORS.FROM_ERROR, to: ERRORS.TO_ERROR });
    } else {
      setSimpleTimeErrors({ from: '', to: '' });
    }

    setSchedule((schedule) => {
      const temp = _.cloneDeep(schedule);
      Object.keys(temp).forEach((weekDay) => {
        if (updatedTimePeriod.from) {
          temp[
            weekDay as unknown as keyof IWeeklySchedule
          ].workingPeriods[0].from = updatedTimePeriod.from;
        }
        if (updatedTimePeriod.to) {
          temp[
            weekDay as unknown as keyof IWeeklySchedule
          ].workingPeriods[0].to = updatedTimePeriod.to;
        }
      });
      return temp;
    });
  };

  return (
    <div>
      <Typography sx={{ fontSize: '18px' }} mb={2}>
        {t('common.timeRange')}
      </Typography>
      <Grid container alignItems="center" spacing={2} mb={3}>
        <Grid item>
          <TimeSelect
            value={schedule[0].workingPeriods[0].from}
            onChange={(date) =>
              onAllTimePeriodsChange({
                from: date?.startOf('minute').toJSDate(),
              })
            }
            error={simpleTimeErrors.from}
          />
        </Grid>
        <Grid item>
          <Typography>to</Typography>
        </Grid>
        <Grid item>
          <TimeSelect
            value={schedule[0].workingPeriods[0].to}
            onChange={(date) =>
              onAllTimePeriodsChange({
                to: date?.startOf('minute').toJSDate(),
              })
            }
            error={simpleTimeErrors.to}
          />
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: '18px' }} mb={2}>
        {t('common.applyTo')}
      </Typography>

      <Grid container gap={1}>
        {Object.keys(schedule).map((weekDay) => (
          <SimpleShiftCalendarRow
            key={weekDay}
            dailySchedule={
              schedule[weekDay as unknown as keyof IWeeklySchedule]
            }
            title={WeekDay[Number(weekDay)]}
            onChangeDayStatus={handleChangeDayStatus(weekDay)}
            readOnly={readOnly}
          />
        ))}
      </Grid>
    </div>
  );
}
