import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import React from 'react';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { IAudiologistNote } from '../../../api/services/hearing-test-results/types';
import { useAuthSelector } from '../../../store/selectors/auth';
import { UserRoles } from '../../../api/services/auth/types';

interface IAudiologistNoteProps {
  note: IAudiologistNote | string;
  background?: 'white' | 'transparent';
  useRemoveButton?: boolean;
  onRemove?: () => Promise<void> | void;
}

export function AudiologistNote(props: IAudiologistNoteProps) {
  const {
    note,
    background = 'transparent',
    useRemoveButton = false,
    onRemove,
  } = props;

  const theme = useTheme();
  const authState = useAuthSelector();
  const removeRoleChecked =
    authState.user?.role === UserRoles.SuperAdministrator ||
    authState.user?.role === UserRoles.Administrator ||
    (authState.user?.role === UserRoles.ProfessionalSupervisor &&
      authState.user?.professionalSupervisor?.id ===
        (note as IAudiologistNote).professionalSupervisorId);

  return (
    <Box
      sx={{
        backgroundColor: background,
        padding: { xs: theme.spacing(2), sm: theme.spacing(4) },
        borderRadius: theme.spacing(2),
      }}
      key={_.isString(note) ? note : note.id}
    >
      {useRemoveButton && removeRoleChecked && (
        <IconButton type="button" sx={{ float: 'right' }} onClick={onRemove}>
          <DeleteOutlined />
        </IconButton>
      )}
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '150%',
          letterSpacing: '0.15px',
          '& p': {
            margin: '0 0 16px',
          },
          '& p:last-child': {
            margin: '0',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: _.isString(note)
            ? note
            : draftToHtml({
                // @ts-ignore
                ...note.note,
                entityMap: JSON.parse(note.note.entityMap),
              }),
        }}
      />
    </Box>
  );
}
