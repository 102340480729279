import axios from '../../axios';
import { Answer, IQuestionnaire, IQuestionnaireResults } from './types';

export class QuestionnaireAPI {
  async getQuestionnaires() {
    const { data } = await axios.get(`/questionnaire/`);

    return data as IQuestionnaire[];
  }

  async getQuestionnaire(resourceType: string) {
    const { data } = await axios.get(`/questionnaire/${resourceType}`);

    return data as IQuestionnaire;
  }

  async getQuestionnaireResults(participantId: number, testId: number) {
    const { data } = await axios.get(
      `/participant/${participantId}/hearing-test-results/${testId}/questionnaire-results`,
    );

    return data as IQuestionnaireResults;
  }

  async saveQuestionnaireResults(
    resourceType: string,
    body: { answers: Answer[] },
  ) {
    const { data } = await axios.post(
      `/questionnaire/${resourceType}/results`,
      body,
    );

    return data as IQuestionnaire;
  }
}
