import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import React, { useMemo, useState } from 'react';
import { api } from 'api';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { DatePicker, Select, TextInput } from 'components/common/input';
import { DateTime } from 'luxon';
import _ from 'lodash';
import ct from 'countries-and-timezones';
import { FileUploadButton, SimpleButton } from '../../common/button';
import { EHearingTestResultTypes } from '../../../api/services/hearing-test-results/types';
import { getUserTimezone } from '../../../utils/getUserTimezone';
import { TimePicker } from '../../common/input/TimePicker';

interface IValues {
  participantId: number;
  externalId: string;
  comment: string;
  threshold_left_250: number;
  threshold_left_500: number;
  threshold_left_750: number;
  threshold_left_1000: number;
  threshold_left_1500: number;
  threshold_left_2000: number;
  threshold_left_3000: number;
  threshold_left_4000: number;
  threshold_left_6000: number;
  threshold_left_8000: number;
  threshold_right_250: number;
  threshold_right_500: number;
  threshold_right_750: number;
  threshold_right_1000: number;
  threshold_right_1500: number;
  threshold_right_2000: number;
  threshold_right_3000: number;
  threshold_right_4000: number;
  threshold_right_6000: number;
  threshold_right_8000: number;
  type: string;
  audiometerMake: string;
  audiometerModel: string;
  audiometerSerial: string;
  audiometerCalibrationDate: DateTime | null;
  date: DateTime | null;
  time: DateTime | null;
  testedBy: string | null;
  files: File[];
  timezone: string;
}

interface UploadExternalTestResultFormProps {
  participantId: number;
  handleClose: () => void;
  handleSubmit: () => void;
}

const UploadExternalTestResultFormSchema = Yup.object().shape({
  externalId: Yup.string()
    .required('required')
    .max(255, 'limited to 255 characters'),
  comment: Yup.string().max(255, 'limited to 255 characters'),
  threshold_left_250: Yup.number().required('required'),
  threshold_left_500: Yup.number().required('required'),
  threshold_left_750: Yup.number().required('required'),
  threshold_left_1000: Yup.number().required('required'),
  threshold_left_1500: Yup.number().required('required'),
  threshold_left_2000: Yup.number().required('required'),
  threshold_left_3000: Yup.number().required('required'),
  threshold_left_4000: Yup.number().required('required'),
  threshold_left_6000: Yup.number().required('required'),
  threshold_left_8000: Yup.number().required('required'),
  threshold_right_250: Yup.number().required('required'),
  threshold_right_500: Yup.number().required('required'),
  threshold_right_750: Yup.number().required('required'),
  threshold_right_1000: Yup.number().required('required'),
  threshold_right_1500: Yup.number().required('required'),
  threshold_right_2000: Yup.number().required('required'),
  threshold_right_3000: Yup.number().required('required'),
  threshold_right_4000: Yup.number().required('required'),
  threshold_right_6000: Yup.number().required('required'),
  threshold_right_8000: Yup.number().required('required'),
  type: Yup.string().required('required').max(255, 'limited to 255 characters'),
  audiometerMake: Yup.string()
    .required('required')
    .max(255, 'limited to 255 characters'),
  audiometerModel: Yup.string()
    .required('required')
    .max(255, 'limited to 255 characters'),
  audiometerSerial: Yup.string()
    .required('required')
    .max(255, 'limited to 255 characters'),
  audiometerCalibrationDate: Yup.date()
    .typeError('should be a valid date')
    .nullable('required')
    .required('required'),
  date: Yup.date()
    .typeError('should be a valid date')
    .nullable('required')
    .required('required'),
  time: Yup.date()
    .typeError('should be a valid time')
    .nullable('required')
    .required('required'),
  testedBy: Yup.string().required('required').max(255, ''),
});

export function UploadExternalTestResultForm(
  props: UploadExternalTestResultFormProps,
) {
  const { handleSubmit, handleClose, participantId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initialValues: IValues = useMemo(
    () => ({
      participantId,
      externalId: '',
      comment: '',
      threshold_left_250: 0,
      threshold_left_500: 0,
      threshold_left_750: 0,
      threshold_left_1000: 0,
      threshold_left_1500: 0,
      threshold_left_2000: 0,
      threshold_left_3000: 0,
      threshold_left_4000: 0,
      threshold_left_6000: 0,
      threshold_left_8000: 0,
      threshold_right_250: 0,
      threshold_right_500: 0,
      threshold_right_750: 0,
      threshold_right_1000: 0,
      threshold_right_1500: 0,
      threshold_right_2000: 0,
      threshold_right_3000: 0,
      threshold_right_4000: 0,
      threshold_right_6000: 0,
      threshold_right_8000: 0,
      type: EHearingTestResultTypes.Other,
      audiometerMake: '',
      audiometerModel: '',
      audiometerSerial: '',
      audiometerCalibrationDate: null,
      date: null,
      time: null,
      testedBy: '',
      files: [],
      timezone: getUserTimezone(),
    }),
    [],
  );

  const onSubmit = async (values: IValues) => {
    const { date, time, audiometerCalibrationDate, files, timezone, ...rest } =
      values;

    const body = new FormData();
    _.keys(rest).forEach((key) => {
      const value: string = _.isString(rest[key as keyof typeof rest])
        ? (rest[key as keyof typeof rest] as string)
        : rest[key as keyof typeof rest]?.toString() || '';
      body.append(key, value);
    });
    if (date && time) {
      body.append(
        'dateTime',
        date
          ?.set({
            hour: time?.get('hour'),
            minute: time?.get('minute'),
          })
          ?.setZone(timezone, { keepLocalTime: true })
          .toISO() || '',
      );
    }
    if (audiometerCalibrationDate) {
      body.append(
        'audiometerCalibrationDate',
        audiometerCalibrationDate
          ?.setZone(timezone, { keepLocalTime: true })
          .toISO() || '',
      );
    }

    files?.forEach((file) => {
      body.append('files', file);
    });

    try {
      setLoading(true);
      await api.hearingTestResults.uploadExternalHearingTestResult(body);

      handleSubmit();
      showSnackbar(`Report successfully uploaded`, {
        variant: 'success',
      });
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  const timezonesWithOffset = useMemo(
    () =>
      ct.getTimezonesForCountry('US').map((tz) => {
        const zone = tz.name;
        return {
          value: zone,
          label: `${zone} (${DateTime.local({ zone }).toFormat('ZZZZ, ZZ')})`,
        };
      }),
    [],
  );

  const testTypeOptions = useMemo(
    () =>
      _.keys(EHearingTestResultTypes).map((key) => ({
        label: key,
        value:
          EHearingTestResultTypes[key as keyof typeof EHearingTestResultTypes],
      })),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={UploadExternalTestResultFormSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title="Upload an External Hearing Test Result"
          isSubmitting={loading}
          fullWidth
          PaperProps={{ sx: { width: '100%' } }}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography style={{ marginBottom: '20px' }}>
                  Please choose the timezone in which the test result was
                  recorded.
                </Typography>
                <Select
                  fullWidth
                  options={timezonesWithOffset}
                  value={values.timezone}
                  label="Timezone"
                  displayEmpty
                  onChange={(e) => setFieldValue('timezone', e.target.value)}
                  errorMessage={
                    touched?.timezone && errors?.timezone
                      ? (errors?.timezone as string)
                      : ''
                  }
                />
              </Grid>
              <Grid item container sm={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="External Test ID"
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    options={testTypeOptions}
                    value={values.type}
                    label="Test Type"
                    displayEmpty
                    onChange={(e) => setFieldValue('type', e.target.value)}
                    errorMessage={
                      touched?.type && errors?.type
                        ? (errors?.type as string)
                        : ''
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ marginBottom: '20px' }}>Left</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="250Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_250"
                    onChange={(e) => {
                      if (Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_250',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_250}
                    error={
                      touched.threshold_left_250 &&
                      Boolean(errors.threshold_left_250)
                    }
                    helperText={
                      touched.threshold_left_250 && errors.threshold_left_250
                        ? errors.threshold_left_250
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="500Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_500"
                    onChange={(e) => {
                      if (Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_500',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_500}
                    error={
                      touched.threshold_left_500 &&
                      Boolean(errors.threshold_left_500)
                    }
                    helperText={
                      touched.threshold_left_500 && errors.threshold_left_500
                        ? errors.threshold_left_500
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="750Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_750"
                    onChange={(e) => {
                      if (Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_750',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_750}
                    error={
                      touched.threshold_left_750 &&
                      Boolean(errors.threshold_left_750)
                    }
                    helperText={
                      touched.threshold_left_750 && errors.threshold_left_750
                        ? errors.threshold_left_750
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="1000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_1000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_1000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_1000}
                    error={
                      touched.threshold_left_1000 &&
                      Boolean(errors.threshold_left_1000)
                    }
                    helperText={
                      touched.threshold_left_1000 && errors.threshold_left_1000
                        ? errors.threshold_left_1000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="1500Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_1500"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_1500',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_1500}
                    error={
                      touched.threshold_left_1500 &&
                      Boolean(errors.threshold_left_1500)
                    }
                    helperText={
                      touched.threshold_left_1500 && errors.threshold_left_1500
                        ? errors.threshold_left_1500
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="2000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_2000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_2000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_2000}
                    error={
                      touched.threshold_left_2000 &&
                      Boolean(errors.threshold_left_2000)
                    }
                    helperText={
                      touched.threshold_left_2000 && errors.threshold_left_2000
                        ? errors.threshold_left_2000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="3000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_3000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_3000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_3000}
                    error={
                      touched.threshold_left_3000 &&
                      Boolean(errors.threshold_left_3000)
                    }
                    helperText={
                      touched.threshold_left_3000 && errors.threshold_left_3000
                        ? errors.threshold_left_3000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="4000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_4000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_4000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_4000}
                    error={
                      touched.threshold_left_4000 &&
                      Boolean(errors.threshold_left_4000)
                    }
                    helperText={
                      touched.threshold_left_4000 && errors.threshold_left_4000
                        ? errors.threshold_left_4000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="6000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_6000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_6000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_6000}
                    error={
                      touched.threshold_left_6000 &&
                      Boolean(errors.threshold_left_6000)
                    }
                    helperText={
                      touched.threshold_left_6000 && errors.threshold_left_6000
                        ? errors.threshold_left_6000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="8000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_left_8000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_left_8000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_left_8000}
                    error={
                      touched.threshold_left_8000 &&
                      Boolean(errors.threshold_left_8000)
                    }
                    helperText={
                      touched.threshold_left_8000 && errors.threshold_left_8000
                        ? errors.threshold_left_8000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Audiometer Make"
                    variant="outlined"
                    type="text"
                    name="audiometerMake"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.audiometerMake}
                    error={
                      touched.audiometerMake && Boolean(errors.audiometerMake)
                    }
                    helperText={
                      touched.audiometerMake && errors.audiometerMake
                        ? errors.audiometerMake
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Audiometer Model"
                    variant="outlined"
                    type="text"
                    name="audiometerModel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.audiometerModel}
                    error={
                      touched.audiometerModel && Boolean(errors.audiometerModel)
                    }
                    helperText={
                      touched.audiometerModel && errors.audiometerModel
                        ? errors.audiometerModel
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <DatePicker
                    value={values.date}
                    label="Test Date"
                    onChange={(newDateFrom: DateTime | null) =>
                      setFieldValue('date', newDateFrom)
                    }
                    errorMessage={
                      touched.date && errors.date ? errors.date : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimePicker
                    value={values.time}
                    label="Test Time"
                    onChange={(newTime: DateTime | null) =>
                      setFieldValue('time', newTime)
                    }
                    errorMessage={
                      touched.time && errors.time ? errors.time : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: '20px' }}>
                    Right
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="250Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_250"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_250',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_250}
                    error={
                      touched.threshold_right_250 &&
                      Boolean(errors.threshold_right_250)
                    }
                    helperText={
                      touched.threshold_right_250 && errors.threshold_right_250
                        ? errors.threshold_right_250
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="500Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_500"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_500',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_500}
                    error={
                      touched.threshold_right_500 &&
                      Boolean(errors.threshold_right_500)
                    }
                    helperText={
                      touched.threshold_right_500 && errors.threshold_right_500
                        ? errors.threshold_right_500
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="750Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_750"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_750',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_750}
                    error={
                      touched.threshold_right_750 &&
                      Boolean(errors.threshold_right_750)
                    }
                    helperText={
                      touched.threshold_right_750 && errors.threshold_right_750
                        ? errors.threshold_right_750
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="1000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_1000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_1000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_1000}
                    error={
                      touched.threshold_right_1000 &&
                      Boolean(errors.threshold_right_1000)
                    }
                    helperText={
                      touched.threshold_right_1000 &&
                      errors.threshold_right_1000
                        ? errors.threshold_right_1000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="1500Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_1500"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_1500',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_1500}
                    error={
                      touched.threshold_right_1500 &&
                      Boolean(errors.threshold_right_1500)
                    }
                    helperText={
                      touched.threshold_right_1500 &&
                      errors.threshold_right_1500
                        ? errors.threshold_right_1500
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="2000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_2000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_2000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_2000}
                    error={
                      touched.threshold_right_2000 &&
                      Boolean(errors.threshold_right_2000)
                    }
                    helperText={
                      touched.threshold_right_2000 &&
                      errors.threshold_right_2000
                        ? errors.threshold_right_2000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="3000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_3000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_3000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_3000}
                    error={
                      touched.threshold_right_3000 &&
                      Boolean(errors.threshold_right_3000)
                    }
                    helperText={
                      touched.threshold_right_3000 &&
                      errors.threshold_right_3000
                        ? errors.threshold_right_3000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="4000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_4000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_4000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_4000}
                    error={
                      touched.threshold_right_4000 &&
                      Boolean(errors.threshold_right_4000)
                    }
                    helperText={
                      touched.threshold_right_4000 &&
                      errors.threshold_right_4000
                        ? errors.threshold_right_4000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="6000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_6000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_6000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_6000}
                    error={
                      touched.threshold_right_6000 &&
                      Boolean(errors.threshold_right_6000)
                    }
                    helperText={
                      touched.threshold_right_6000 &&
                      errors.threshold_right_6000
                        ? errors.threshold_right_6000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="8000Hz"
                    variant="outlined"
                    type="text"
                    name="threshold_right_8000"
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)) {
                        setFieldValue(
                          'threshold_right_8000',
                          Number(e.target.value),
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.threshold_right_8000}
                    error={
                      touched.threshold_right_8000 &&
                      Boolean(errors.threshold_right_8000)
                    }
                    helperText={
                      touched.threshold_right_8000 &&
                      errors.threshold_right_8000
                        ? errors.threshold_right_8000
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Audiometer Serial"
                    variant="outlined"
                    type="text"
                    name="audiometerSerial"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.audiometerSerial}
                    error={
                      touched.audiometerSerial &&
                      Boolean(errors.audiometerSerial)
                    }
                    helperText={
                      touched.audiometerSerial && errors.audiometerSerial
                        ? errors.audiometerSerial
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Tested By"
                    variant="outlined"
                    type="text"
                    name="testedBy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.testedBy}
                    error={touched.testedBy && Boolean(errors.testedBy)}
                    helperText={
                      touched.testedBy && errors.testedBy
                        ? errors.testedBy
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  value={values.audiometerCalibrationDate}
                  label="Audiometer Calibration Date"
                  onChange={(newDateFrom: DateTime | null) =>
                    setFieldValue('audiometerCalibrationDate', newDateFrom)
                  }
                  errorMessage={
                    touched.audiometerCalibrationDate &&
                    errors.audiometerCalibrationDate
                      ? errors.audiometerCalibrationDate
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Comment"
                  variant="outlined"
                  type="text"
                  name="comment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment}
                  error={touched.comment && Boolean(errors.comment)}
                  helperText={
                    touched.comment && errors.comment ? errors.comment : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 2,
                  }}
                >
                  <FileUploadButton
                    onFilesChosen={(file) => {
                      setFieldValue('files', [...values.files, file]);
                    }}
                    buttonProps={{
                      variant: 'contained',
                      color: 'secondary',
                      disabled: values.files?.length >= 5,
                    }}
                  >
                    Choose a file
                  </FileUploadButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    paddingX: 2,
                  }}
                >
                  {values.files?.map((file, index) => (
                    <Grid
                      container
                      item
                      flexDirection="row"
                      flexWrap="nowrap"
                      gap={1}
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: '90%',
                        }}
                      >
                        {file.name}
                      </Typography>
                      <SimpleButton
                        actionType="delete"
                        onClick={() => {
                          const files = [...values.files];
                          files.splice(index, 1);
                          setFieldValue('files', files);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
