import { CircularProgress, Grid } from '@mui/material';
import { Chip } from 'components/common/chip';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IStatusCellProps {
  status: string;
  loading?: boolean;
}

export function StatusCell(props: IStatusCellProps) {
  const { status, loading } = props;
  const { t } = useTranslation();

  const chipColors = useMemo(() => {
    switch (status.toLowerCase()) {
      case 'deactivated':
      case 'error':
        return { text: '#701313', dot: '#d2261d', background: '#FDD7D7' };
      case 'unattempted':
      case 'pending':
        return {
          text: '#AA7C1D',
          dot: '#FFE86E',
          background: 'rgba(255, 237, 143, 0.50)',
        };
      case 'success':
      case 'completed':
      case 'active':
        return {
          text: '#287013',
          dot: '#4AD520',
          background: 'rgba(104, 247, 56, 0.20)',
        };
      case 'sent':
      case 'new':
      case 'enqueued':
        return {
          text: '#135970',
          dot: '#20B2D5',
          background: 'rgba(56, 197, 247, 0.20)',
        };

      default:
        return {
          text: '#08161F',
          dot: '#08161F',
          background: 'rgba(202, 194, 190, 0.30)',
        };
    }
  }, [status]);

  const labelComponent = (
    <Grid container gap={1} alignItems="center">
      {_.capitalize(t(`common.${status}`.toLowerCase()))}
      {loading && (
        <CircularProgress
          size={8}
          sx={{
            color: chipColors.text,
          }}
        />
      )}
    </Grid>
  );

  return status ? (
    <Chip
      label={labelComponent}
      backgroundColor={chipColors.background}
      labelColor={chipColors.text}
      isDot
      dotColor={chipColors.dot}
    />
  ) : (
    <>-</>
  );
}
