import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  HearingTestResult,
  IAudiologistNote,
  INoteBody,
} from '../../../api/services/hearing-test-results/types';
import { AudiologistNote } from './AudiologistNote';
import { Button } from '../../common/button';
import { AudiologistNoteEditor } from './audiologist-note-editor';
import { api } from '../../../api';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';
import { ParticipantHearingReportHeading } from './ParticipantHearingReportHeading';
import { ConfirmationModal } from '../../modal';

interface IAudiologistNotesListProps {
  loading: boolean;
  hearingTestResult: HearingTestResult | null;
  editable?: boolean;
  onUpdate?: () => Promise<void> | void;
}

export function AudiologistNotesList(props: IAudiologistNotesListProps) {
  const { hearingTestResult, loading, editable, onUpdate } = props;

  const theme = useTheme();

  const notes = useMemo(
    () =>
      _.compact([
        ...(hearingTestResult?.audiologistNotes || []).sort((a, b) =>
          b.updated_at.localeCompare(a.updated_at),
        ),
      ]),
    [hearingTestResult],
  );

  const [editorOpened, setEditorOpened] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [handledNote, setHandledNote] = useState<IAudiologistNote>();

  const handleAudiologistNoteCreate = useCallback(
    async (note: INoteBody) => {
      if (!hearingTestResult) return;
      try {
        await api.audiologistNotes.createAudiologistNote({
          hearingTestResultId: hearingTestResult.id,
          note,
        });
        setEditorOpened(false);
        await onUpdate?.();
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [hearingTestResult],
  );

  const toggleDeleteAudiologistNote = async () => {
    try {
      if (!hearingTestResult || !handledNote) return;
      await api.audiologistNotes.deleteAudiologistNote(
        handledNote.id,
        hearingTestResult.id,
      );
      showSnackbar('Audiologist Note is successfully deleted', {
        variant: 'success',
      });
      await onUpdate?.();
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not delete Audiologist Note'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setConfirmationOpen(false);
      setHandledNote(undefined);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(243, 238, 236, 0.30)',
          padding: { xs: theme.spacing(1), sm: theme.spacing(4) },
          paddingTop: { xs: 0, sm: 0 },
          marginBottom: theme.spacing(2),
        }}
      >
        <Skeleton variant="rounded" width="100%" height="172px" />
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginBottom: theme.spacing(1),
        }}
      >
        <ParticipantHearingReportHeading>
          Audiologist Note
        </ParticipantHearingReportHeading>
        {editable && (
          <Grid item>
            <Button
              onClick={() => setEditorOpened(true)}
              disabled={editorOpened || !hearingTestResult}
            >
              Add New Note
            </Button>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          backgroundColor: 'rgba(243, 238, 236, 0.30)',
          padding: {
            xs: theme.spacing(1),
            sm: `${theme.spacing(2)} ${theme.spacing(4)}`,
          },
          marginBottom: theme.spacing(2),
        }}
      >
        {editable && editorOpened && !!hearingTestResult && (
          <AudiologistNoteEditor
            onSave={handleAudiologistNoteCreate}
            onCancel={() => setEditorOpened(false)}
          />
        )}
        <div id="note-content">
          {!notes?.length ? (
            <Typography variant="mono" sx={{ color: 'rgb(78,78,78)' }}>
              Participant does not have the audiologist note yet
            </Typography>
          ) : (
            notes.map((item) => (
              <AudiologistNote
                note={item}
                background="white"
                useRemoveButton
                onRemove={() => {
                  setHandledNote(item as IAudiologistNote);
                  setConfirmationOpen(true);
                }}
              />
            ))
          )}
        </div>
      </Box>
      <ConfirmationModal
        open={confirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleSubmit={toggleDeleteAudiologistNote}
        submitButtonTitle="Delete"
        title={
          <>
            You are about to delete a published note. Are you sure you want to
            continue?
          </>
        }
      />
    </Box>
  );
}
