import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import React, {
  ElementType,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DateTime } from 'luxon';
import { MoreVert } from '@mui/icons-material';
import _ from 'lodash';
import { isSTSShiftPresent } from 'utils';
import { Cell, HeaderCell } from '../table/types';
import {
  EHearingTestResultTypes,
  HearingTestResult,
} from '../../api/services/hearing-test-results/types';
import { TestResultTypeCell } from './components';
import {
  ConfirmationModal,
  HearingTestResultTypeChangingModal,
} from '../modal';
import { DropdownButton } from '../common/button';
import { api } from '../../api';
import { useAuthSelector } from '../../store/selectors/auth';
import { UserRoles } from '../../api/services/auth/types';
import { showSnackbar } from '../../utils';

interface ParticipantHearingTestResultsTableProps {
  hearingTestResults: HearingTestResult[];
  onRowClicked?: (value: HearingTestResult) => any | Promise<any>;
  onTypeChange?: (
    hearingTestResultId: number | null,
    type: EHearingTestResultTypes,
    reason: string,
  ) => any | Promise<any>;
  refreshData?: () => void;
  selectedResultId?: number;
  loading?: boolean;
  backgroundColor?: string;
  dividerBackgroundColor?: string;
  highlightHearingFrequencies?: boolean;
  groupedHeaderFontSize?: string | number;
  headerFontSize?: string | number;
  cellFontSize?: string | number;
  isAgeAdjustedShiftShow?: boolean;
}

const getGroupingHeaderCells = (
  isAgeAdjustedShiftShow: boolean,
): Array<HeaderCell & { icon?: ReactElement }> =>
  _.compact([
    {
      name: '',
      colSpan: 3,
      key: 'empty_block',
    },
    {
      name: 'Left Ear',
      colSpan: 9,
      key: 'left_ear',
      icon: (
        <span style={{ verticalAlign: 'middle' }}>
          <img
            src="/images/icons/left-ear-marker.svg"
            alt="left ear marker"
            style={{
              width: '11px',
              height: '11px',
              marginRight: '8px',
            }}
          />
        </span>
      ),
    },
    {
      name: 'Right Ear',
      colSpan: 9,
      key: 'right_ear',
      icon: (
        <span style={{ verticalAlign: 'middle' }}>
          <img
            src="/images/icons/right-ear-marker.svg"
            alt="right ear marker"
            style={{
              width: '11px',
              height: '11px',
              marginRight: '8px',
            }}
          />
        </span>
      ),
    },
    {
      name: 'Shift',
      colSpan: 4,
      key: 'shift',
    },
    isAgeAdjustedShiftShow && {
      name: 'Age Adjusted Shift',
      colSpan: 3,
      key: 'shift',
    },
  ]);

export function ParticipantHearingTestResultsTable(
  props: ParticipantHearingTestResultsTableProps,
) {
  const {
    hearingTestResults,
    selectedResultId,
    onRowClicked,
    backgroundColor = 'transparent',
    dividerBackgroundColor = 'white',
    highlightHearingFrequencies,
    headerFontSize = '11px',
    cellFontSize = '13px',
    groupedHeaderFontSize = '12px',
    onTypeChange,
    refreshData,
    isAgeAdjustedShiftShow = false,
  } = props;

  const [selectedType, setSelectedType] =
    useState<null | EHearingTestResultTypes>(null);
  const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] =
    useState(false);
  const [editTypeConfirmationModalOpened, setEditTypeConfirmationModalOpened] =
    useState(false);
  const [hearingTestResultId, setHearingTestResultId] = useState<number | null>(
    null,
  );
  const { user } = useAuthSelector();

  const getReportOptions = (item: HearingTestResult) => [
    {
      value: item.id.toString(),
      title: 'Calculate test results',
      handleClick: () =>
        api.organization
          .recalculateHearingTestParameters(item.id)
          .then(() =>
            showSnackbar('Test result was successfully recalculated', {
              variant: 'success',
            }),
          )
          .then(() => refreshData?.()),
    },
    {
      value: item.id.toString(),
      title: 'Delete',
      handleClick: () => {
        setHearingTestResultId(item.id);
        setDeleteConfirmationModalOpened(true);
      },
    },
  ];

  const CELLS: Array<Cell<HearingTestResult> & { divider?: boolean }> =
    useMemo(() => {
      const result = [
        {
          name: 'Date',
          key: 'dateTime',
          render: (item: HearingTestResult) =>
            DateTime.fromISO(item.dateTime).toFormat('MM.dd.yy'),
        },
        {
          name: 'Test Type',
          key: 'type',
          render: (item: HearingTestResult) => (
            <TestResultTypeCell
              hearingTestResult={item}
              selectedType={
                item.id === hearingTestResultId ? selectedType : null
              }
              onTypeChange={
                onTypeChange
                  ? (type: EHearingTestResultTypes) => {
                      setHearingTestResultId(item.id);
                      setSelectedType(type);
                      setEditTypeConfirmationModalOpened(true);
                    }
                  : undefined
              }
            />
          ),
          width: '70px',
        },
        {
          name: '',
          key: 'divider1',
          divider: true,
          render: () => '',
        },
        {
          name: '500',
          key: 'threshold_left_500',
        },
        {
          name: '1k',
          key: 'threshold_left_1000',
        },
        {
          name: '2k',
          key: 'threshold_left_2000',
        },
        {
          name: '3k',
          key: 'threshold_left_3000',
        },
        {
          name: '4k',
          key: 'threshold_left_4000',
        },
        {
          name: '6k',
          key: 'threshold_left_6000',
        },
        {
          name: '8k',
          key: 'threshold_left_8000',
        },
        {
          name: '234',
          key: 'leftSTSFrequenciesAverage',
          render: (item: HearingTestResult) =>
            Math.floor(item.leftSTSFrequenciesAverage || 0),
        },
        {
          name: '',
          key: 'divider2',
          divider: true,
          render: () => '',
        },
        {
          name: '500',
          key: 'threshold_right_500',
        },
        {
          name: '1k',
          key: 'threshold_right_1000',
        },
        {
          name: '2k',
          key: 'threshold_right_2000',
        },
        {
          name: '3k',
          key: 'threshold_right_3000',
        },
        {
          name: '4k',
          key: 'threshold_right_4000',
        },
        {
          name: '6k',
          key: 'threshold_right_6000',
        },
        {
          name: '8k',
          key: 'threshold_right_8000',
        },
        {
          name: '234',
          key: 'rightSTSFrequenciesAverage',
          render: (item: HearingTestResult) =>
            Math.floor(item.rightSTSFrequenciesAverage || 0),
        },
        {
          name: '',
          key: 'divider3',
          divider: true,
          render: () => '',
        },
        {
          name: 'LT',
          key: 'leftSTS',
          render: (item: HearingTestResult) => Math.floor(item.leftSTS || 0),
          width: '45px',
        },
        {
          name: 'RT',
          key: 'rightSTS',
          render: (item: HearingTestResult) => Math.floor(item.rightSTS || 0),
          width: '45px',
        },
        {
          name: 'Shift',
          key: 'STS',
          render: (item: HearingTestResult) => (
            <Box>
              {isSTSShiftPresent(item.leftSTS, item.rightSTS) ? 'Y' : '-'}
            </Box>
          ),
          width: '50px',
        },
      ];

      if (isAgeAdjustedShiftShow) {
        result.push(
          ...[
            {
              name: '',
              key: 'divider3',
              divider: true,
              render: () => '',
            },
            {
              name: 'LTAA',
              key: 'leftAASTS',
              render: (item: HearingTestResult) =>
                Math.floor(item.leftAgeAdjustedSTS || 0),
              width: '45px',
            },
            {
              name: 'RTAA',
              key: 'rightAASTS',
              render: (item: HearingTestResult) =>
                Math.floor(item.rightAgeAdjustedSTS || 0),
              width: '45px',
            },
            {
              name: 'Shift',
              key: 'AASTS',
              render: (item: HearingTestResult) => (
                <Box>
                  {isSTSShiftPresent(
                    item.leftAgeAdjustedSTS,
                    item.rightAgeAdjustedSTS,
                  )
                    ? 'Y'
                    : '-'}
                </Box>
              ),
              width: '50px',
            },
          ],
        );
      }

      if (
        user?.role === UserRoles.Administrator ||
        user?.role === UserRoles.SuperAdministrator
      ) {
        result.push({
          name: '',
          key: 'divider4',
          divider: true,
          render: () => '',
        });
        result.push({
          name: '',
          key: 'contextMenu',
          render: (item: HearingTestResult) => (
            <DropdownButton<ElementType, string>
              variant="text"
              color="primary"
              options={getReportOptions(item)}
            >
              <MoreVert />
            </DropdownButton>
          ),
          width: '65px',
        });
      }

      return result;
    }, [hearingTestResults, selectedType, onTypeChange]);

  const getCellBackgroundColor = useCallback(
    (isDivider: boolean, key: string, row: HearingTestResult | null) => {
      const columnsToHighlightYellow = [
        'threshold_right_2000',
        'threshold_right_3000',
        'threshold_right_4000',
        'threshold_left_2000',
        'threshold_left_3000',
        'threshold_left_4000',
      ];
      if (isDivider) return dividerBackgroundColor;
      if (
        selectedResultId === row?.id &&
        columnsToHighlightYellow.includes(key) &&
        highlightHearingFrequencies
      ) {
        return '#F4C871';
      }
      if (
        columnsToHighlightYellow.includes(key) &&
        highlightHearingFrequencies
      ) {
        return '#FFED8F';
      }
      if (
        highlightHearingFrequencies &&
        (((key === 'leftSTS' || key === 'rightSTS' || key === 'STS') &&
          isSTSShiftPresent(row?.leftSTS, row?.rightSTS)) ||
          ((key === 'leftAASTS' || key === 'rightAASTS' || key === 'AASTS') &&
            isSTSShiftPresent(
              row?.leftAgeAdjustedSTS,
              row?.rightAgeAdjustedSTS,
            )))
      ) {
        return '#FA7C7C';
      }
      return backgroundColor;
    },
    [selectedResultId, backgroundColor, dividerBackgroundColor],
  );

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 500 }} aria-label="table">
        <TableHead>
          <TableRow>
            {getGroupingHeaderCells(isAgeAdjustedShiftShow).map((c) => (
              <TableCell
                key={c.key}
                align="center"
                colSpan={c.colSpan}
                sx={{
                  border: 'none',
                }}
              >
                <Typography
                  variant="mono"
                  fontSize={groupedHeaderFontSize}
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  {c.icon}
                  {c.name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {CELLS.map((c) => (
              <TableCell
                key={c.key}
                align="center"
                sx={{
                  border: c.divider ? 'none' : '1px solid #808080',
                  fontSize: headerFontSize,
                  textTransform: 'uppercase',
                  fontWeight: 800,
                  padding: '4px',
                  width: c.width ? c.width : 'inherit',
                  backgroundColor: getCellBackgroundColor(
                    !!c.divider,
                    c.key || '',
                    null,
                  ),
                }}
              >
                {c.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {hearingTestResults.map((row: any, i) => (
            <TableRow
              key={row.id || i}
              sx={{
                ...(onRowClicked
                  ? {
                      '&:hover': {
                        backgroundColor: '#F0EDEC',
                        cursor: 'pointer',
                      },
                    }
                  : {}),
                ...(row.id === selectedResultId
                  ? {
                      backgroundColor: '#F0EDEC',
                    }
                  : {}),
              }}
              onClick={() => onRowClicked?.(row)}
            >
              {CELLS.map(({ name, key, render, divider, width }, index) => {
                const cellKey = key || name;
                return (
                  <TableCell
                    key={cellKey + index}
                    component="th"
                    scope="row"
                    sx={{
                      border: divider ? 'none' : '1px solid #808080',
                      textAlign: 'center',
                      padding: '4px',
                      fontSize: cellFontSize,
                      width: width || 'inherit',
                      backgroundColor: getCellBackgroundColor(
                        !!divider,
                        key || '',
                        row,
                      ),
                    }}
                  >
                    <Typography variant="mono" sx={{ textAlign: 'center' }}>
                      {(render ? render(row) : row[cellKey]) ?? '-'}
                    </Typography>{' '}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <HearingTestResultTypeChangingModal
        opened={!!(editTypeConfirmationModalOpened && hearingTestResultId)}
        onClose={() => {
          setEditTypeConfirmationModalOpened(false);
          setHearingTestResultId(null);
          setSelectedType(null);
        }}
        onSubmit={(reason) => {
          onTypeChange?.(
            hearingTestResultId,
            selectedType as EHearingTestResultTypes,
            reason,
          );
        }}
      />

      <ConfirmationModal
        open={!!(deleteConfirmationModalOpened && hearingTestResultId)}
        handleClose={() => {
          setHearingTestResultId(null);
          setDeleteConfirmationModalOpened(false);
        }}
        handleSubmit={() => {
          api.organization
            .deleteHearingTest(hearingTestResultId as number)
            .then(() =>
              showSnackbar('Test result was successfully deleted', {
                variant: 'success',
              }),
            )
            .then(() => refreshData?.())
            .then(() => {
              setHearingTestResultId(null);
              setDeleteConfirmationModalOpened(false);
            });
        }}
        submitButtonTitle="Yes"
        title={<>Are you sure you want to delete the selected test result?</>}
      />
    </TableContainer>
  );
}
