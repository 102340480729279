import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import { api } from '../../../api';
import {
  EReportStatuses,
  IDailyBiologicalReport,
} from '../../../api/services/organization/types';
import { AudiometerInfoItem } from './components';
import { BiologicalReportFormTable } from './components/biological-report-form-table';
import { Button } from '../../common/button';
import { SignatureButton } from '../../common/button/SignatureButton';
import { VALIDATION_SCHEMA } from './constants';
import { ListeningCheckSection } from './components/listening-check-section';

interface IDailyBiologicalReportValues
  extends Omit<
    IDailyBiologicalReport,
    | 'id'
    | 'dateTime'
    | 'annualCalibration'
    | 'baselineId'
    | 'created_at'
    | 'updated_at'
  > {
  dateTime: DateTime | null;
  annualCalibration: DateTime | null;
  baselineId: number | null | '';
  id?: number;
  signature?: string;
}

interface IDailyBiologicalReportFormProps {
  dailyBiologicalReportId?: number;
  locationId: number;
}

export function DailyBiologicalReportForm({
  dailyBiologicalReportId,
  locationId,
}: IDailyBiologicalReportFormProps) {
  const [loading, setLoading] = useState(!!dailyBiologicalReportId);
  const [initialValues, setInitialValues] =
    useState<IDailyBiologicalReportValues>({
      dateTime: DateTime.now(),
      locationId,
      status: EReportStatuses.Draft,
      baselineId: '',
      examiner: '',
      audiometer: '',
      serialNumber: '',
      booth: 1,
      uniqueId: '',
      annualCalibration: null,
      OHCInitial: '',
      calibrationCheck: false,
      jacksSeated: false,
      cordsOK: false,
      headbandTension: false,
      earphoneCushions: false,
      responseButton: false,
      pureTones: false,
      noStatic: false,
      threshold_left_250: 0,
      threshold_left_500: 0,
      threshold_left_750: 0,
      threshold_left_1000: 0,
      threshold_left_1500: 0,
      threshold_left_2000: 0,
      threshold_left_3000: 0,
      threshold_left_4000: 0,
      threshold_left_6000: 0,
      threshold_left_8000: 0,
      threshold_right_250: 0,
      threshold_right_500: 0,
      threshold_right_750: 0,
      threshold_right_1000: 0,
      threshold_right_1500: 0,
      threshold_right_2000: 0,
      threshold_right_3000: 0,
      threshold_right_4000: 0,
      threshold_right_6000: 0,
      threshold_right_8000: 0,
      signature: '',
      name: `Daily Biological Report (${DateTime.now().toFormat(
        'MM/dd/yyyy',
      )})`,
    });
  const [baselineReports, setBaselineReports] = useState<
    IDailyBiologicalReport[]
  >([]);

  useEffect(() => {
    const getBaselineReports = async () => {
      if (!locationId) return;
      try {
        const { items: reports } =
          await api.organization.getDailyBiologicalReportsForLocation(
            locationId,
            {
              limit: 100,
              offset: 0,
              baseline: true,
            },
          );

        setBaselineReports(reports);
      } catch (e) {
        console.log(e);
      }
    };

    getBaselineReports();
  }, [locationId]);

  useEffect(() => {
    const getEditedReport = async () => {
      if (!dailyBiologicalReportId) return;
      setLoading(true);
      try {
        const report = await api.organization.getDailyBiologicalReportById(
          dailyBiologicalReportId,
        );

        setInitialValues({
          ...report,
          dateTime: report.dateTime ? DateTime.fromISO(report.dateTime) : null,
          annualCalibration: report.annualCalibration
            ? DateTime.fromISO(report.annualCalibration)
            : null,
          baselineId: report.baselineId || '',
        });
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)));
      } finally {
        setLoading(false);
      }
    };

    getEditedReport();
  }, [dailyBiologicalReportId]);

  const documentViewingDateTime = useMemo(() => DateTime.now().toISO(), []);

  const signDocument = useCallback(
    async (values: IDailyBiologicalReportValues) => {
      try {
        let result: IDailyBiologicalReport;
        if (values.id) {
          result = await api.organization.updateDailyBiologicalReport(
            values.id,
            {
              ...values,
              dateTime: values.dateTime?.toISO() || null,
              annualCalibration: values.annualCalibration?.toISO() || null,
              baselineId: values.baselineId || null,
            },
          );
        } else {
          result = await api.organization.createDailyBiologicalReport(
            locationId,
            {
              ...values,
              dateTime: values.dateTime?.toISO() || null,
              annualCalibration: values.annualCalibration?.toISO() || null,
              baselineId: values.baselineId || null,
            },
          );
        }
        setInitialValues({
          ...result,
          dateTime: result.dateTime ? DateTime.fromISO(result.dateTime) : null,
          annualCalibration: result.annualCalibration
            ? DateTime.fromISO(result.annualCalibration)
            : null,
          baseline: result.baselineId
            ? baselineReports.find((report) => report.id === result.baselineId)
            : null,
          baselineId: result.baselineId || '',
        });
        const signedDocument = await api.organization.signDailyBiologicalReport(
          result.id,
          {
            signature: values.signature || '',
            documentViewingDateTime: documentViewingDateTime as string,
          },
        );
        setInitialValues({
          ...result,
          dateTime: result.dateTime ? DateTime.fromISO(result.dateTime) : null,
          annualCalibration: result.annualCalibration
            ? DateTime.fromISO(result.annualCalibration)
            : null,
          baseline: result.baselineId
            ? baselineReports.find((report) => report.id === result.baselineId)
            : null,
          baselineId: result.baselineId || '',
          signedDocument,
        });
        showSnackbar('Daily biological report successfully signed', {
          variant: 'success',
        });
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [locationId, baselineReports, documentViewingDateTime],
  );

  const formik = useFormik<IDailyBiologicalReportValues>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      await signDocument(values);
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    getFieldProps,
  } = formik;

  const theme = useTheme();

  const createDraft = useCallback(async () => {
    try {
      formik.setSubmitting(true);
      let result: IDailyBiologicalReport;
      if (values.id) {
        result = await api.organization.updateDailyBiologicalReport(values.id, {
          ...values,
          dateTime: values.dateTime?.toISO() || null,
          annualCalibration: values.annualCalibration?.toISO() || null,
          baselineId: values.baselineId || null,
        });
      } else {
        result = await api.organization.createDailyBiologicalReport(
          locationId,
          {
            ...values,
            dateTime: values.dateTime?.toISO() || null,
            annualCalibration: values.annualCalibration?.toISO() || null,
            baselineId: values.baselineId || null,
          },
        );
      }
      setInitialValues({
        ...result,
        dateTime: result.dateTime ? DateTime.fromISO(result.dateTime) : null,
        annualCalibration: result.annualCalibration
          ? DateTime.fromISO(result.annualCalibration)
          : null,
        baseline: result.baselineId
          ? baselineReports.find((report) => report.id === result.baselineId)
          : null,
        baselineId: result.baselineId || '',
      });
      showSnackbar('Draft successfully saved', {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
        variant: 'error',
      });
    } finally {
      formik.setSubmitting(false);
    }
  }, [formik.values, locationId, baselineReports]);

  const baselineDropdownOptions = useMemo(
    () => [
      { label: 'None', value: '' },
      ...baselineReports.map((baseline) => ({
        label: `(ID ${baseline.id}) ${
          baseline.dateTime
            ? DateTime.fromISO(baseline.dateTime).toFormat('MM/dd/yyyy h:mm a')
            : '-'
        }`,
        value: baseline.id,
        disabled: baseline.id === initialValues.id,
      })),
    ],
    [baselineReports, initialValues],
  );

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          handleSubmit();
        }
      }}
      container
      sx={{ width: '100%', margin: 0, pb: theme.spacing(4) }}
      spacing={theme.spacing(2)}
      direction="column"
    >
      <Grid
        sx={{
          pb: theme.spacing(2),
          borderBottom: '1px solid rgba(202, 194, 190, 0.80)',
        }}
      >
        <Grid sx={{ maxWidth: '350px', width: '100%', mb: 3 }}>
          <AudiometerInfoItem
            label="Report Name"
            inputProps={{
              ...getFieldProps('name'),
              helperText: touched.name ? errors.name : '',
            }}
          />
        </Grid>
        <Grid sx={{ maxWidth: '350px' }}>
          <AudiometerInfoItem
            label="Baseline"
            selectProps={{
              displayEmpty: true,
              ...getFieldProps('baselineId'),
              onChange: (event: any) => {
                setFieldValue('baselineId', event.target.value || '');
                setFieldValue(
                  'baseline',
                  baselineReports.find(
                    (report) => report.id === Number(event.target.value),
                  ) || null,
                );
              },
              options: baselineDropdownOptions,
            }}
            type="select"
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          py: theme.spacing(4),
          mb: theme.spacing(4),
          borderBottom: '1px solid rgba(202, 194, 190, 0.80)',
        }}
      >
        <Grid
          container
          gap={3}
          flexWrap="wrap"
          sx={{
            maxWidth: '960px',
          }}
        >
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Audiometer"
              inputProps={{
                ...getFieldProps('audiometer'),
                helperText: touched.audiometer ? errors.audiometer : '',
              }}
            />
          </Grid>
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Serial#"
              inputProps={{
                ...getFieldProps('serialNumber'),
                helperText: touched.serialNumber ? errors.serialNumber : '',
              }}
            />
          </Grid>
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Unique ID"
              inputProps={{
                ...getFieldProps('uniqueId'),
                helperText: touched.uniqueId ? errors.uniqueId : '',
              }}
            />
          </Grid>
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Examiner"
              inputProps={{
                ...getFieldProps('examiner'),
                helperText: touched.examiner ? errors.examiner : '',
              }}
            />
          </Grid>
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Booth"
              inputProps={{
                ...getFieldProps('booth'),
                helperText: touched.booth ? errors.booth : '',
              }}
              type="integer"
            />
          </Grid>
          <Grid sx={{ maxWidth: '300px', width: '100%' }}>
            <AudiometerInfoItem
              label="Annual Calibration"
              datePickerProps={{
                value: values.annualCalibration,
                onChange: (value) => {
                  setFieldValue('annualCalibration', value);
                },
                errorMessage: touched.annualCalibration
                  ? errors.annualCalibration
                  : '',
              }}
              type="date"
            />
          </Grid>
        </Grid>
      </Grid>
      <BiologicalReportFormTable
        baseline={values.baseline || null}
        formik={formik}
      />
      <ListeningCheckSection formik={formik} />
      <Grid
        container
        alignItems="center"
        gap={2}
        sx={{
          padding: '24px 0 0',
        }}
      >
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '24px',
          }}
        >
          Prepared by
        </Typography>
        <Typography
          variant="mono"
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '-1%',
          }}
        >
          Tuned Care LLC
        </Typography>
      </Grid>
      <Grid
        sx={{
          py: theme.spacing(3),
        }}
      >
        <Grid sx={{ maxWidth: '320px' }}>
          <AudiometerInfoItem
            label="Test datetime"
            dateTimePickerProps={{
              ...getFieldProps('dateTime'),
              onChange: (value) => {
                setFieldValue('dateTime', value);
              },
              errorMessage: errors.dateTime || '',
            }}
            type="dateTime"
          />
        </Grid>
      </Grid>
      {values.signedDocument && (
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '24px',
          }}
        >
          Audiometers Checked by:
        </Typography>
      )}
      <Grid container gap={2} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
        {!values.signedDocument && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading || formik.isSubmitting}
              onClick={createDraft}
            >
              Save as Draft
            </Button>
          </Grid>
        )}
        <Grid item>
          <SignatureButton
            disabled={loading || formik.isSubmitting}
            buttonTitle="Add signature"
            buttonProps={{ endIcon: <AddIcon fontSize="inherit" /> }}
            onSubmit={async (signature: string) => {
              await setFieldValue('signature', signature);
              handleSubmit();
            }}
            signedDocument={values.signedDocument || null}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
