import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { cells } from './cells';
import { useSiteManagerDashboardSelector } from '../../../store/selectors/dashboard';
import { IVisit } from '../../../api/services/organization/types';
import { VisitFormModal } from '../../admin/location-visits/components/visit-form';

export function SiteManagerLocationVisitsList() {
  const { selectedLocation } = useSiteManagerDashboardSelector();
  const [formOpen, setFormOpen] = useState(false);
  const [editedVisit, setEditedVisit] = useState<IVisit | null>(null);

  const fetchVisits = useCallback(
    async (limit: number, offset: number) => {
      if (!selectedLocation) {
        return EMPTY_PAGINATED_DATA;
      }
      const visitsData = await api.organization.getLocationVisits(
        selectedLocation.id,
        { limit, offset, shared: true },
      );
      return visitsData;
    },
    [selectedLocation],
  );

  const tableState = useTableState<IVisit>({ fetchDataFunction: fetchVisits });

  const actions = useMemo(
    () => [
      {
        title: 'Edit',
        onClick: (visit: IVisit) => {
          setEditedVisit(visit);
          setFormOpen(true);
        },
      },
    ],
    [],
  );

  const navigate = useNavigate();

  return (
    <Grid>
      <BasicTable<IVisit>
        cells={cells}
        tableState={tableState}
        actions={actions}
        onRowClicked={(visit: IVisit) =>
          navigate(
            `/site-manager/locations/${selectedLocation?.id}/visits/${visit.id}`,
          )
        }
      />
      {formOpen && selectedLocation?.id && (
        <VisitFormModal
          handleClose={() => {
            setFormOpen(false);
            setEditedVisit(null);
          }}
          visit={editedVisit}
          locationId={String(selectedLocation?.id)}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedVisit(null);
            if (editedVisit) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
    </Grid>
  );
}
