import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import { CustomCategoryTick } from './CustomCategoryTick';
import { CustomTooltip } from './CustomTooltip';
import { ILocationParticipantsHearingMetric } from '../../api/services/organization/types';
import { EHearingThresholdsNames } from '../../api/services/hearing-test-results/types';

interface IHearingLossChartProps {
  metric: ILocationParticipantsHearingMetric;
}

export function HearingLossChart(props: IHearingLossChartProps) {
  const { metric } = props;

  const results = useMemo(() => {
    if (!metric) {
      return;
    }
    const result: {
      category: string;
      value: number;
    }[] = [];

    _.values(EHearingThresholdsNames).forEach((threshold) => {
      result.push({
        category:
          threshold === EHearingThresholdsNames.Normal
            ? 'Normal Hearing'
            : `${threshold} Loss`,
        value: metric.participantsHearingLossByGender[threshold].total,
      });
    });
    result.push({
      category: 'Number Shifts',
      value: metric.participantsWithSTSShiftCountByGender.total,
    });
    return result;
  }, [metric]);

  const xAxisTicks = useMemo(
    () => [
      ..._.values(EHearingThresholdsNames).map((threshold) =>
        threshold === EHearingThresholdsNames.Normal
          ? 'Normal Hearing'
          : `${threshold} Loss`,
      ),
      'Number Shifts',
    ],
    [],
  );

  const totalParticipantsTested = useMemo(
    () =>
      metric
        ? _.values(metric.participantsHearingLossByGender).reduce(
            (acc, item) => acc + item.total,
            0,
          )
        : 0,
    [metric],
  );

  const yAxisTicks = useMemo(
    () =>
      new Array(12)
        .fill(0)
        .map((item, index) => Math.ceil(index * (metric.total / 12))),
    [metric],
  );

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={results}>
        <CartesianGrid vertical={false} horizontal />
        <XAxis
          type="category"
          dataKey="category"
          interval={0}
          tickLine={false}
          style={{
            fontFamily: 'DM Mono',
            fontSize: '10px',
            textTransform: 'uppercase',
          }}
          tick={CustomCategoryTick}
          ticks={xAxisTicks}
          height={140}
        />
        <YAxis
          type="number"
          dataKey="value"
          tickLine={false}
          style={{ fontFamily: 'DM Mono', fontSize: '11px', fontWeight: 500 }}
          width={30}
          allowDecimals={false}
          padding={{ bottom: 10 }}
          ticks={yAxisTicks}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#6656A9" radius={8} maxBarSize={65} />
        {metric && (
          <ReferenceLine
            y={totalParticipantsTested}
            stroke="#B76060"
            label={{
              position: 'top',
              value: `NUMBER TESTED ${totalParticipantsTested}`,
              fill: '#B76060',
              fontSize: 11,
              fontFamily: 'DM Mono',
            }}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
