import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LoginLink = styled(Link)((props) => ({
  fontWeight: 700,
  textDecoration: 'none',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  display: 'block',
  textAlign: 'center',
  color: '#000',
  paddingTop: '16px',
  fontFamily: 'PlusJakartaSans, sans-serif',
  fontSize: props.theme.spacing(1.7),
}));
