import { Grid, Skeleton, useTheme } from '@mui/material';
import React from 'react';

export function ProfessionalSupervisorLicenseStatesFormSkeleton() {
  const theme = useTheme();
  return (
    <Grid container spacing={theme.spacing(2)}>
      <Grid
        item
        xs={12}
        container
        direction="column"
        spacing={theme.spacing(2)}
      >
        <Grid
          container
          justifyContent="space-between"
          spacing={theme.spacing(2)}
          alignItems="center"
          item
          mb={theme.spacing(4)}
          mt={theme.spacing(4)}
        >
          <Grid
            container
            item
            xs={10}
            justifyContent="space-between"
            spacing={theme.spacing(2)}
          >
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" height={56} />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" height={56} />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" height={56} />
            </Grid>
          </Grid>

          <Grid item width={40}>
            <Skeleton variant="circular" height={40} width={40} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
