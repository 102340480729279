import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button } from 'components/common/button';

export type ResendVerificationCodeProps = { onResendCode: () => void };

export function ResendVerificationCode({
  onResendCode,
}: ResendVerificationCodeProps) {
  const [count, setCount] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count <= 0) {
        clearInterval(timer);
        return;
      }
      setCount((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });
  return (
    <Grid
      item
      xs={12}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Button
        variant="text"
        color="primary"
        onClick={onResendCode}
        disabled={count != 0}
      >
        Resend Code
      </Button>
      <Typography variant="caption">
        {count > 0 && (
          <Typography variant="caption">
            (will be available in{' '}
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, margin: '0 3px' }}
            >
              {count}
            </Typography>{' '}
            seconds)
          </Typography>
        )}
      </Typography>
    </Grid>
  );
}
