import { ChipProps, Grid, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IResultRowProps extends ChipProps {
  title: string;
  note?: ReactNode;
  value: string | number;
  loading?: boolean;
}

export function ResultRow(props: IResultRowProps) {
  const { title, note, value = 0, loading } = props;

  return (
    <Grid container justifyContent="space-between" sx={{ pt: 4, pb: 4 }}>
      <Grid item xs={10} sm={7}>
        <Typography
          sx={{
            fontSize: '32px',
            lineHeight: '120%',
          }}
        >
          {title}
        </Typography>
        {note && (
          <Typography
            sx={{
              pt: 2,
              fontSize: '17px',
              lineHeight: '120%',
            }}
          >
            {note}
          </Typography>
        )}
      </Grid>

      <Grid item>
        {!loading ? (
          <Typography
            variant="mono"
            sx={{
              fontSize: '32px',
              fontWeight: 500,
              lineHeight: '120%',
            }}
          >
            {value}
          </Typography>
        ) : (
          <Skeleton variant="rounded" height="38px" width="40px" />
        )}
      </Grid>
    </Grid>
  );
}
