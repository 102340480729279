import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { getNumberSuffix } from 'utils/getNumberSuffix';

interface DateTextProps {
  date?: string;
  time?: string;
}

export function DateTimeText(props: DateTextProps) {
  const { date, time } = props;

  return (
    <Typography
      variant="mono"
      sx={{
        fontSize: '18px',
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '2px',
      }}
    >
      {date &&
        `${DateTime.fromISO(date).toFormat('LLLL d')}${getNumberSuffix(
          DateTime.fromISO(date).toFormat('d'),
        )}`}
      {time && DateTime.fromISO(time).toFormat('h:mm a')}
    </Typography>
  );
}
