import { ThemeProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { router } from 'routes';
import { store } from 'store';
import { theme } from 'theme';

function StoredApp() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider style={{ fontFamily: theme.typography.fontFamily }} />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export function App() {
  return (
    <Provider store={store}>
      <StoredApp />
    </Provider>
  );
}
