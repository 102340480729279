import { createTheme } from '@mui/material';
import React from 'react';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    transparent: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    mono: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mono?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mono: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#08161f',
    },
    secondary: {
      main: '#ffe86e',
    },
  },
  typography: {
    fontFamily: 'PlusJakartaSans, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '130%',
      letterSpacing: '-0.5px',
    },
    h2: {
      fontSize: '25px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    subtitle1: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '140%',
    },
    mono: {
      fontFamily: 'DM Mono, monospace',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            fontWeight: '700',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          letterSpacing: '0.25px',
          paddingTop: '7px',
          paddingBottom: '7px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          letterSpacing: '0.25px',
          minHeight: 30,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            textTransform: 'none',
            color: 'black',
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '-0.5px',
          },
        },
      ],
    },
  },
});
