import { Box, Grid, useTheme } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Participant } from '../../../api/services/auth/types';
import { ParticipantInfoItem } from './ParticipantInfoItem';
import { HearingTestResult } from '../../../api/services/hearing-test-results/types';

interface IParticipantInfoProps {
  participant: Participant | null;
  selectedTestResult?: HearingTestResult | null;
  loading: boolean;
  title: string | ReactElement;
}

export function ParticipantInfo(props: IParticipantInfoProps) {
  const { participant, loading, selectedTestResult } = props;

  const participantName = useMemo(() => {
    if (!participant) return '';
    return `${participant.given || '-'} ${participant.family || ''}`;
  }, [participant]);

  const participantBirthDate = useMemo(() => {
    if (!participant) return '';
    return DateTime.fromISO(participant.birthDate).toFormat('MM.dd.yy');
  }, [participant]);

  const participantLastTestDate = useMemo(() => {
    if (!participant) return '-';

    const parsedDate = DateTime.fromISO(
      selectedTestResult
        ? selectedTestResult.dateTime
        : participant.lastTestDate,
    );
    if (!parsedDate.isValid) return '-';
    return parsedDate.toFormat('MM.dd.yy');
  }, [participant, selectedTestResult]);

  const participantLocation = useMemo(() => {
    if (!participant) return '';
    return `${participant.organization?.name}, ${participant.location?.name}`;
  }, [participant]);

  const theme = useTheme();

  return (
    <Box
      my={theme.spacing(3)}
      sx={{
        borderBottom: '1px solid rgba(202, 194, 190, 0.30)',
        height: 'auto',
      }}
    >
      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="Name"
              value={participantName}
              loading={loading}
            />
          </Grid>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="Date of Birth"
              value={participantBirthDate}
              loading={loading}
            />
          </Grid>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="Location"
              value={participantLocation}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid item direction="column" xs={12} sm={6}>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="External ID"
              value={participant?.externalId}
              loading={loading}
            />
          </Grid>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="Test Date"
              value={participantLastTestDate}
              loading={loading}
            />
          </Grid>
          <Grid item mb={theme.spacing(2)}>
            <ParticipantInfoItem
              title="Department"
              value={participant?.department}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
