import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { useLocation } from 'hooks/useLocation';
import { Button } from 'components/common/button';
import { useTableState } from 'hooks/useTableState';
import { Shift } from 'api/services/organization/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { ConfirmationModal } from 'components/modal';
import { PageHeader } from 'components/admin-page-layout';
import { useTranslation } from 'react-i18next';
import { getCells } from './cells';
import { ShiftForm } from './components/shift-form';

type LocationShiftsListProps = {
  modifiable?: boolean;
};

export function LocationShiftsList(props: LocationShiftsListProps) {
  const { modifiable } = props;
  const { t, i18n } = useTranslation();

  const cells = useMemo(() => getCells(), [i18n.language]);

  const { locationId } = useParams();
  const [formOpen, setFormOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editedShift, setEditedShift] = useState<Shift | null>(null);

  const { location, isLocationLoading } = useLocation();

  const fetchShifts = useCallback(async (limit: number, offset: number) => {
    if (!locationId) {
      return EMPTY_PAGINATED_DATA;
    }
    const organizationsData = await api.organization.getLocationShifts(
      locationId,
      { limit, offset },
    );
    return organizationsData;
  }, []);

  const tableState = useTableState<Shift>({ fetchDataFunction: fetchShifts });

  const deleteShift = async (shift: Shift) => {
    if (!locationId) {
      return;
    }
    try {
      await api.organization.deleteLocationShift(locationId, shift.id);
      tableState.refreshData();
      showSnackbar(t('common.locationShiftSuccessfullyDeleted'), {
        variant: 'success',
      });
      setConfirmationOpen(false);
      setEditedShift(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, t('common.couldNotDeleteShift')),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  return (
    <>
      {modifiable && formOpen && locationId && (
        <ShiftForm
          handleClose={() => {
            setFormOpen(false);
            setEditedShift(null);
          }}
          shift={editedShift}
          locationId={locationId}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedShift(null);
            if (editedShift) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}

      {modifiable && editedShift && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedShift(null);
          }}
          handleSubmit={() => deleteShift(editedShift)}
          submitButtonTitle={t('common.delete')}
          title={
            <>
              {t('common.youAreAboutToDeleteTheShiftNameShift', {
                shiftName: editedShift.name,
              })}
              <br />
              {t('common.areYouSure')}
            </>
          }
        />
      )}
      <BasicContainer>
        <PageHeader
          entityTitle={t('common.shifts')}
          entityName={location?.name}
          titleLoading={isLocationLoading}
          buttons={
            modifiable ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setEditedShift(null);
                  setFormOpen(true);
                }}
              >
                {t('common.new')}
              </Button>
            ) : null
          }
        />
        <BasicTable<Shift>
          cells={cells}
          tableState={tableState}
          actions={
            modifiable
              ? [
                  {
                    title: t('common.edit'),
                    onClick: (shift: Shift) => {
                      setEditedShift(shift);
                      setFormOpen(true);
                    },
                  },
                  {
                    title: t('common.delete'),
                    onClick: (shift: Shift) => {
                      setEditedShift(shift);
                      setConfirmationOpen(true);
                    },
                  },
                ]
              : undefined
          }
        />
      </BasicContainer>
    </>
  );
}
