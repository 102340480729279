import { FullHeightContainer } from 'components/container';
import { FormModal } from 'components/modal';
import { IVisit } from 'api/services/organization/types';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { showSnackbar } from 'utils';
import ct from 'countries-and-timezones';
import { Grid } from '@mui/material';
import { TextInput } from 'components/common/input/TextInput';
import * as Yup from 'yup';
import { FormAlert } from 'components/alert';
import { Formik } from 'formik';
import { Select } from 'components/common/input';
import { DateTime } from 'luxon';
import { getUserTimezone } from 'utils/getUserTimezone';
import { IOption } from 'common/types/common';

const OrganizationFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  timezone: Yup.string().required('Required'),
  ignoreTestedEmployeesMonths: Yup.number().optional(),
});

interface VisitFormProps {
  visit?: IVisit | null;
  locationId: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function VisitFormModal(props: VisitFormProps) {
  const { visit, handleSubmit, handleClose, locationId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');

  const initialValues = visit
    ? {
        name: visit.name,
        timezone: visit.timezone || getUserTimezone(),
        ignoreTestedEmployeesMonths: visit.ignoreTestedEmployeesMonths,
      }
    : {
        name: '',
        timezone: getUserTimezone(),
        ignoreTestedEmployeesMonths: 0,
      };

  const timeZonesWithOffset = useMemo(
    () =>
      ct.getTimezonesForCountry('US').map((tz) => {
        const zone = tz.name;
        return {
          value: zone,
          label: `${zone} (${DateTime.local({ zone }).toFormat('ZZZZ, ZZ')})`,
        };
      }),
    [],
  );

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      if (visit)
        await api.organization.updateLocationVisit(
          locationId,
          visit.id,
          values,
        );
      else await api.organization.createLocationVisit(locationId, values);
      showSnackbar(`Visit successfully ${visit ? 'updated' : 'added'}`, {
        variant: 'success',
      });
      handleSubmit();
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OrganizationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={visit ? 'Edit Visit' : 'Create Visit'}
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name ? errors.name : ' '}
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  label="Time Zone"
                  name="timezone"
                  options={timeZonesWithOffset}
                  value={values.timezone}
                  displayEmpty
                  renderValue={() =>
                    timeZonesWithOffset.find(
                      (tz: IOption) => tz.value === values.timezone,
                    )?.label
                  }
                  onChange={handleChange}
                  error={touched.timezone && Boolean(errors.timezone)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Ignore (months)"
                  variant="outlined"
                  type="text"
                  name="ignoreTestedEmployeesMonths"
                  onKeyDown={(event) => {
                    if (
                      !/[0-9]/.test(event.key) &&
                      ![
                        'Delete',
                        'Backspace',
                        'Enter',
                        'ArrowLeft',
                        'ArrowRight',
                      ].includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ignoreTestedEmployeesMonths}
                  error={
                    touched.ignoreTestedEmployeesMonths &&
                    Boolean(errors.ignoreTestedEmployeesMonths)
                  }
                  helperText={
                    touched.ignoreTestedEmployeesMonths &&
                    errors.ignoreTestedEmployeesMonths
                      ? errors.ignoreTestedEmployeesMonths
                      : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
