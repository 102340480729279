import { List, ListItemText } from '@mui/material';
import { ReactNode } from 'react';

interface RosterRecordReason {
  field: string;
  value: string;
  message: string;
}

export const formatRosterRecordReason = (reason: string): ReactNode => {
  if (!reason) {
    return '';
  }
  try {
    const result: RosterRecordReason[] = JSON.parse(reason);
    return (
      <List disablePadding>
        {result.map((item) => (
          <ListItemText disableTypography sx={{ fontSize: '14px' }}>
            {`field: "${item.field}", value: "${item.value}", message: "${item.message}"`}
          </ListItemText>
        ))}
      </List>
    );
  } catch (e) {
    return reason;
  }
};
