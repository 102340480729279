import { useMemo } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { ILocation } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { useOHCLocations } from 'hooks/useOHCLocations';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { getCells } from './cells';

export function OhcLocationsList() {
  const { fetchOhcLocations } = useOHCLocations();

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchOhcLocations,
  });

  const cells = useMemo(() => getCells(), []);

  const actions = [
    {
      title: 'Download Participants CSV',
      onClick: async (location: ILocation) => {
        try {
          await api.organization.exportLocationParticipantsData(
            Number(location.id),
          );
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      },
    },
  ];

  return (
    <BasicContainer>
      <BasicTable<ILocation>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
    </BasicContainer>
  );
}
