import PlaceIcon from '@mui/icons-material/Place';
import {
  Box,
  Grid,
  SelectChangeEvent,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IOption } from 'common/types/common';
import { useMemo } from 'react';
import { Select } from '../common/input';

interface ILocationSelectProps {
  value: number | string;
  label: string;
  labelIcon?: 'location';
  options: IOption[];
  loading?: boolean;
  onChange: (e: SelectChangeEvent<unknown>) => void;
}

export function DashboardSelect(props: ILocationSelectProps) {
  const { value, label, labelIcon, options, loading, onChange } = props;
  const theme = useTheme();

  const icon = useMemo(
    () =>
      labelIcon === 'location' ? (
        <PlaceIcon
          sx={{ mr: theme.spacing(1), width: '16px', height: '16px' }}
        />
      ) : null,
    [labelIcon],
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        backgroundColor: 'rgba(202, 194, 190, 0.10)',
        alignItems: 'center',
        px: theme.spacing(1),
        borderRadius: theme.spacing(1),
      }}
    >
      {icon}
      <Typography
        sx={{
          fontSize: '13px',
          letterSpacing: '-0.13px',
          lineHeight: '130%',
          fontWeight: 700,
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      {loading ? (
        <Skeleton variant="rounded" width="100%" height="39px" />
      ) : (
        <Grid item container>
          <Select
            withErrorMessage={false}
            onChange={onChange}
            value={value}
            sx={{
              '& *': {
                border: 'none !important',
              },
              '& .MuiSelect-select': {
                padding: '8px',
                fontSize: '11px',
                letterSpacing: '0.1px',
                lineHeight: '16px',
                fontWeight: 700,
              },
            }}
            options={options}
            IconComponent={KeyboardArrowDownIcon}
            disabled={!options?.length}
          />
        </Grid>
      )}
    </Box>
  );
}
