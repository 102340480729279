import { Typography } from '@mui/material';

interface ISingleChoiceOptionProps {
  text: string;
}

export function Text(props: ISingleChoiceOptionProps) {
  const { text } = props;

  return <Typography variant="caption">{text}</Typography>;
}
