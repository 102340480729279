import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormControlLabel, Grid, Link } from '@mui/material';
import { FormAlert } from 'components/alert';
import { TextInput } from 'components/common/input';
import { birthDate } from 'utils/validation/validators';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyParticipantRequest } from 'store/reducers/auth/actions';
import _ from 'lodash';
import { Heading } from 'components/common/heading';
import { getErrorMessageComponent } from 'utils';
import { useAuthSelector } from 'store/selectors/auth';
import { ArrowForward } from '@mui/icons-material';
import { useCleanupAuthError } from 'hooks/useCleanupAuthError';
import { Checkbox } from 'components/common/input/Checkbox';
import { Button } from 'components/common/button';
import { useState } from 'react';
import { PrivacyPolicyModal } from 'components/privacy-policy-modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';

const initialValues: {
  family: string;
  birthDate: Dayjs | null;
  acceptedTermsOfUse: boolean;
} = {
  family: '',
  birthDate: null,
  acceptedTermsOfUse: false,
};

const VerifySchema = Yup.object().shape({
  family: Yup.string().required('Required'),
  birthDate: birthDate.required('Required'),
  acceptedTermsOfUse: Yup.boolean().required('Required'),
});

export function VerifyForm() {
  const dispatch = useDispatch();
  const authState = useAuthSelector();
  useCleanupAuthError();
  const [privacyPolicyDialogOpened, setPrivacyPolicyDialogOpened] =
    useState(false);
  const { organizationName } = useParams();

  const onSubmit = (values: typeof initialValues) => {
    if (!organizationName) {
      return;
    }
    dispatch(
      verifyParticipantRequest({
        ..._.omit(values, ['acceptedTermsOfUse']),
        birthDate: values.birthDate?.format('YYYY-MM-DD') || '',
        organizationName,
      }),
    );
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={VerifySchema}
    >
      {({
        values,
        touched,
        errors,
        getFieldProps,
        setFieldValue,
        handleSubmit,
      }) => (
        <Grid
          container
          component="form"
          xs={12}
          md={8}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit();
            }
          }}
          onSubmit={handleSubmit}
        >
          <Grid
            container
            flexDirection="column"
            pb={3}
            alignItems={{ xs: 'center', md: 'flex-start' }}
          >
            <img
              src="/images/logos/tuned-simple-logo.png"
              width={64}
              alt="logo"
              style={{ borderRadius: '12px' }}
            />
            <Heading variant="h1" pt={4} pb={2}>
              Welcome to Tuned
            </Heading>
            <Heading variant="subtitle1">
              Fill out your information below.
            </Heading>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              fullWidth
              label="Last Name"
              variant="standard"
              type="text"
              {...getFieldProps('family')}
              error={touched.family && Boolean(errors.family)}
              helperText={touched.family && errors.family ? errors.family : ' '}
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Birth Date"
                value={values.birthDate}
                onChange={(e) => {
                  if (values.birthDate) {
                    setFieldValue('birthDate', null);
                  } else if (e?.isValid() && e?.year() > 1900) {
                    setFieldValue('birthDate', e);
                  }
                }}
                slots={{
                  textField: (textFieldProps) =>
                    (<TextInput fullWidth {...textFieldProps} />) as any,
                }}
                slotProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                  textField: {
                    variant: 'standard',
                    error: touched.birthDate && Boolean(errors.birthDate),
                    helperText:
                      touched.birthDate && errors.birthDate
                        ? errors.birthDate
                        : ' ',
                  },
                }}
                sx={{
                  paddingBottom: '24px',
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('acceptedTermsOfUse')}
                  checked={values.acceptedTermsOfUse}
                />
              }
              slotProps={{
                typography: {
                  fontSize: '13px',
                  fontWeight: 700,
                  lineHeight: '16px',
                },
              }}
              label={
                <span>
                  I accept the{' '}
                  <Link
                    underline="hover"
                    color="#6DBDBF"
                    onClick={(e) => {
                      e.preventDefault();
                      setPrivacyPolicyDialogOpened(true);
                    }}
                  >
                    Terms of Use and Privacy Policy
                  </Link>
                </span>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {authState.error ? (
                  <FormAlert severity="error">
                    {getErrorMessageComponent(authState.error)}
                  </FormAlert>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ padding: '16px 0 16px' }}
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
          >
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="secondary"
              endIcon={<ArrowForward />}
              disabled={!values.acceptedTermsOfUse || authState.loading}
            >
              Continue
            </Button>
          </Grid>

          <PrivacyPolicyModal
            opened={privacyPolicyDialogOpened}
            onClose={() => setPrivacyPolicyDialogOpened(false)}
            onAccept={() => {
              setPrivacyPolicyDialogOpened(false);
              setFieldValue('acceptedTermsOfUse', true);
            }}
            policyTypes={['general', 'privacy-policy']}
          />
        </Grid>
      )}
    </Formik>
  );
}
