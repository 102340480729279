import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilter } from 'components/table-filter';
import { SearchInput } from 'components/common/input/SearchInput';
import { RequestParams } from 'api/types';
import { ILocationParticipantsHearingTestsSummary } from 'api/services/organization/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { HearingTestResultsSummary } from 'components/hearing-test-results-summary';
import { ParticipantForm } from 'components/forms/participant-form';
import { PdfPreviewModal } from 'components/modal';
import { DateTime } from 'luxon';
import { DateFormats } from 'common/constants/date';
import _ from 'lodash';
import { getCells } from './cells';
import { getFilterConfig } from './filter-config';
import { BasicContainer } from '../../../../components/container';
import { PageHeader } from '../../../../components/admin-page-layout';
import { useLocation } from '../../../../hooks/useLocation';
import { DatePickerModal } from '../../../../components/modal/DatePickerModal';

export function HCPSupervisorParticipantsList() {
  const { locationId } = useParams();
  const [editedParticipant, setEditedParticipant] =
    useState<ParticipantWithLastHearingTestResultViewItem | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');

  const [disabilityLeaveModalOpened, setDisabilityLeaveModalOpened] =
    useState(false);

  const { location, isLocationLoading } = useLocation();

  const fetchParticipants = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      if (!locationId) return null;
      try {
        const participantsData =
          await api.organization.getParticipantsByLocationId(
            Number(locationId),
            {
              limit,
              offset,
              orderBy,
              orderDirection,
              search,
              filterParams,
            },
          );
        return participantsData;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [locationId],
  );

  const tableState =
    useTableState<ParticipantWithLastHearingTestResultViewItem>({
      fetchDataFunction: fetchParticipants,
    });

  const setParticipantReturnedFromDisabilityLeave = useCallback(
    async (date: DateTime | null) => {
      try {
        if (!editedParticipant) return;
        await api.participant.updateParticipant(editedParticipant.id, {
          returnedFromDisabilityLeaveDate: date?.toISODate(),
        });
        await tableState.reloadData();
        showSnackbar('Participant Returned From Disability Leave', {
          variant: 'success',
        });
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [editedParticipant, tableState],
  );

  const navigate = useNavigate();

  const actions = useMemo(
    () => [
      {
        title: 'Test Report',
        onClick: (
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          navigate(
            `/hcp-supervisor/locations/${locationId}/participants/${participant.id}/report`,
          );
        },
        disabled: (participant: ParticipantWithLastHearingTestResultViewItem) =>
          !participant.dateTime,
      },
      {
        title: 'Edit',
        onClick: (
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          setFormOpen(true);
          setEditedParticipant(participant);
        },
      },
      {
        title: 'Signed Test Reports',
        onClick: (
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          navigate(
            `/hcp-supervisor/locations/${locationId}/participants/${participant.id}/signed-reports`,
          );
        },
        disabled: (participant: ParticipantWithLastHearingTestResultViewItem) =>
          !participant?.lastSignedReport ||
          _.isEmpty(participant?.lastSignedReport),
      },
      {
        title: 'Returned from disability',
        onClick: (
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          setEditedParticipant(participant);
          setDisabilityLeaveModalOpened(true);
        },
      },
    ],
    [],
  );

  const theme = useTheme();

  const [summary, setSummary] =
    useState<ILocationParticipantsHearingTestsSummary | null>(null);
  const [summaryLoading, setSummaryLoading] = useState(true);

  useEffect(() => {
    const requestSummary = async () => {
      if (!locationId) return;
      try {
        setSummaryLoading(true);
        const data =
          await api.organization.getLocationParticipantsHearingTestsSummary(
            Number(locationId),
            { showInactive: tableState.filterParams?.showInactive as boolean },
          );
        setSummary(data);
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setSummaryLoading(false);
      }
    };
    requestSummary();
  }, [locationId, tableState.filterParams?.showInactive]);

  const handleDownloadReportDocument = async (
    participant: ParticipantWithLastHearingTestResultViewItem,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    if (!participant.lastSignedReport) {
      return;
    }
    try {
      const fileName = `${participant.given}_${
        participant.family
      }_${DateTime.fromISO(participant.lastSignedReport.created_at).toFormat(
        DateFormats.FILE_DATE_FORMAT,
      )}`;
      const data = await api.participant.getParticipantReportPDF(
        participant.id,
        participant.lastSignedReport.id,
      );

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const previewPdfReport = async (
    participant: ParticipantWithLastHearingTestResultViewItem,
  ) => {
    setOpenPdfPreview(true);
    return handleDownloadReportDocument(participant, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const cells = useMemo(() => {
    if (locationId) {
      return getCells(
        locationId,
        location,
        location?.organization || null,
        previewPdfReport,
      );
    }
    return [];
  }, [location, locationId]);

  return (
    <BasicContainer>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
        isDownload
      />

      <PageHeader
        entityTitle="Participants"
        entityName={`${location?.name}${
          location?.externalId ? ` (${location?.externalId})` : ''
        }`}
        titleLoading={isLocationLoading}
      />
      <Grid>
        <HearingTestResultsSummary
          items={[
            {
              name: 'Completed in last 12 months',
              value: summary
                ? `${Math.round(
                    summary.percentageOfParticipantsTestedLastYear,
                  )}%`
                : '',
              backgroundColor: '#ffe86e',
            },
            {
              name: 'of employees with STS',
              value: summary
                ? `${Math.round(summary.percentageOfParticipantsWithSts)}%`
                : '',
              backgroundColor: '#ddf4fd',
            },
            {
              name: 'Tests Needed',
              value:
                (
                  Number(summary?.participantsNotTestedLastYear) +
                  Number(summary?.invalidTestsCount)
                ).toString() || '',
              backgroundColor: '#e5fede',
            },
            {
              name: 'with OSHA Recordability STS',
              value: summary
                ? `${Math.round(
                    summary.percentageOfParticipantsWithRecordableOSHAShift,
                  )}%`
                : '',
              backgroundColor: '#FDD7D7',
            },
          ]}
          loading={summaryLoading}
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <TableFilter
          config={getFilterConfig()}
          useQueryParams
          onChange={(filterParams) => {
            tableState.setFilterParams(filterParams);
          }}
        />
        <Grid
          item
          py={{
            xs: 0,
            sm: theme.spacing(3),
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>
      <BasicTable<ParticipantWithLastHearingTestResultViewItem>
        cells={cells}
        tableState={tableState}
        actions={actions}
        onRowClicked={(
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          if (!participant.dateTime) {
            return showSnackbar('No test results', { variant: 'error' });
          }
          navigate(
            `/hcp-supervisor/locations/${locationId}/participants/${participant.id}/report`,
          );
        }}
      />

      <DatePickerModal
        title="Participant Returned From Disability Leave On:"
        opened={disabilityLeaveModalOpened}
        onClose={() => setDisabilityLeaveModalOpened(false)}
        onSelect={setParticipantReturnedFromDisabilityLeave}
        date={null}
      />

      {!!editedParticipant && location && formOpen && (
        <ParticipantForm
          participant={editedParticipant}
          locationId={Number(location.id)}
          organizationId={Number(location.managingOrganization)}
          handleClose={() => {
            setFormOpen(false);
            setEditedParticipant(null);
          }}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedParticipant(null);
            if (editedParticipant) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
    </BasicContainer>
  );
}
