import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export function ParticipantHearingReportHeading(props: TypographyProps) {
  const { children, sx, ...rest } = props;

  return (
    <Typography
      variant="mono"
      sx={{
        fontSize: '12px',
        fontWeight: 300,
        lineHeight: '120%',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
        ...(sx || {}),
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
