import {
  CLEAR_SITE_MANAGER_DASHBOARD_STATE,
  SET_SITE_MANAGER_DASHBOARD_STATE,
  SET_SITE_MANAGER_LOCATIONS,
  SET_SITE_MANAGER_LOCATIONS_LOADING,
  SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR,
  SET_SITE_MANAGER_SELECTED_LOCATION,
  SiteManagerDashboardActions,
  SiteManagerDashboardState,
} from './types';

const initialState: SiteManagerDashboardState = {
  locations: [],
  error: null,
  loading: true,
  selectedLocation: null,
};

export const siteManagerDashboardReducer = (
  state = initialState,
  action: SiteManagerDashboardActions,
) => {
  switch (action.type) {
    case SET_SITE_MANAGER_LOCATIONS:
      return { ...state, locations: action.payload };
    case SET_SITE_MANAGER_LOCATIONS_LOADING:
      return { ...state, loading: action.payload };
    case SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR:
      return { ...state, error: action.payload };
    case SET_SITE_MANAGER_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case SET_SITE_MANAGER_DASHBOARD_STATE:
      return { ...state, ...action.payload };
    case CLEAR_SITE_MANAGER_DASHBOARD_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
