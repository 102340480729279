import i18next from 'i18n/config';
import { Shift } from 'api/services/organization/types';
import { Cell } from 'components/table/types';
import { ShiftTimingsCell } from 'components/table/components/ShiftTimingsCell';

export const getCells = (): Cell<Shift>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.name'),
    key: 'name',
  },
  {
    name: i18next.t('common.timings'),
    key: 'timing',
    render: (item: Shift) => <ShiftTimingsCell hours={item.hours} />,
  },
  {
    name: i18next.t('common.minimalEmployees'),
    key: 'minimalEmployees',
  },
];
