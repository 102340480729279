import { Grid } from '@mui/material';

interface ITimeRangeLabelProps {
  start: string;
  end: string;
}

export function TimeRangeLabel(props: ITimeRangeLabelProps) {
  const { start, end } = props;

  return (
    <Grid container justifyContent="space-between" sx={{ width: 160 }}>
      <Grid item>{start}</Grid>
      <Grid item>-</Grid>
      <Grid item>{end}</Grid>
    </Grid>
  );
}
