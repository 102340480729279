import axios from '../../axios';
import { IAudiologistNote, INoteBody } from '../hearing-test-results/types';

export class AudiologistNotesAPI {
  async createAudiologistNote(body: {
    hearingTestResultId: number;
    note: INoteBody;
  }) {
    const { data } = await axios.post(`/audiologist-notes`, body);

    return data as IAudiologistNote;
  }

  async deleteAudiologistNote(id: number, hearingTestResultId: number) {
    const { data } = await axios.delete(
      `/audiologist-notes/${id}?hearingTestResultId=${hearingTestResultId}`,
    );

    return data as { success: true };
  }
}
