import { TimePeriod } from './TimePeriod';
import { ERRORS } from './constants';
import { IParseTime } from './types';
import { timeParser } from './utils';

export class TimeErrors {
  static setPeriod = (period: TimePeriod) => ({
    ...period,
    from: timeParser(period.from),
    to: timeParser(period.to),
  });

  static checkDate = (date?: Date) => !Number.isNaN(date?.getTime());

  static checkPeriods = (per_1: IParseTime, per_2: IParseTime) =>
    (per_1.from < per_2.from && per_1.to <= per_2.from) ||
    (per_1.from >= per_2.to && per_1.to > per_2.to);

  static checkIsPeriodCorrect = (timePeriod: TimePeriod) =>
    TimeErrors.checkDate(timePeriod.from) &&
    TimeErrors.checkDate(timePeriod.to);

  static checkIsPeriodValid = (
    workingPeriods: TimePeriod[],
    timePeriod: TimePeriod,
  ) => {
    const updatedWorkingPeriods = workingPeriods.map((period) =>
      TimeErrors.setPeriod(period),
    );
    const updatedTimePeriod = TimeErrors.setPeriod(timePeriod);
    return updatedWorkingPeriods.every((period) =>
      TimeErrors.checkPeriods(updatedTimePeriod, period),
    );
  };

  static checkIsTimeValid = (timePeriod: TimePeriod) => {
    const period = TimeErrors.setPeriod(timePeriod);
    return period.from >= period.to && period.to <= period.from;
  };

  static checkWorkingPeriodsErrors = (workingPeriods: TimePeriod[]) =>
    workingPeriods.reduce((acc, period) => {
      const isPeriodCorrect = TimeErrors.checkIsPeriodCorrect(period);

      if (isPeriodCorrect) {
        const isTimePeriodNotValid = TimeErrors.checkIsTimeValid(period);
        if (isTimePeriodNotValid)
          return {
            ...acc,
            [period.key]: { from: ERRORS.FROM_ERROR, to: ERRORS.TO_ERROR },
          };

        const filteredWorkingPeriods = workingPeriods
          .filter((_period) => _period.key !== period.key)
          .filter((period) => TimeErrors.checkIsPeriodCorrect(period));
        const isWorkingPeriodValid = TimeErrors.checkIsPeriodValid(
          filteredWorkingPeriods,
          period,
        );
        return filteredWorkingPeriods.length && !isWorkingPeriodValid
          ? {
              ...acc,
              [period.key]: {
                from: ERRORS.PERIOD_ERROR,
                to: ERRORS.PERIOD_ERROR,
              },
            }
          : acc;
      }
      return acc;
    }, {});
}
