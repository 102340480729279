import { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import { useAuthSelector } from 'store/selectors/auth';
import { useNavigate } from 'react-router-dom';
import { UserRoles } from 'api/services/auth/types';

export function Main() {
  const authState = useAuthSelector();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authState && !authState.user) navigate('/login');
    else if (authState?.user?.role) {
      if (
        [UserRoles.Administrator, UserRoles.SuperAdministrator].includes(
          authState.user.role,
        )
      )
        navigate('/admin');
      else if ([UserRoles.OHC].includes(authState.user.role)) navigate('/ohc');
      else if ([UserRoles.SiteManager].includes(authState.user.role))
        navigate('/site-manager');
      else if ([UserRoles.ProfessionalSupervisor].includes(authState.user.role))
        navigate('/professional-supervisor');
      else if ([UserRoles.HCPSupervisor].includes(authState.user.role))
        navigate('/hcp-supervisor');
      else if ([UserRoles.AccountManager].includes(authState.user.role))
        navigate('/account-manager');
    }

    setLoading(false);
  }, [authState]);

  return loading ? null : <Container maxWidth="xl" />;
}
