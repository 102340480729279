import { Shift, ShiftHours } from 'api/services/organization/types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { ISimpleTimeErrors, ITimeErrors, IWeeklySchedule } from './types';
import { TimeErrors } from './TimeErrors';
import { TimePeriod } from './TimePeriod';
import { INITIAL_SCHEDULE_STATE } from './constants';

interface ITimeComponents {
  time: string;
  ampm: 'AM' | 'PM';
}

export const dateToTimeComponents = (value: Date): ITimeComponents | null => {
  if (!value) return null;
  const date = DateTime.fromJSDate(value);
  const time = date.toFormat('hh:mm');
  const ampm = date.toFormat('a') as 'AM' | 'PM';
  return { time, ampm };
};

export const timeComponentsToDate = (value: ITimeComponents): DateTime => {
  const { time, ampm } = value;
  const currentDate = DateTime.now().toFormat('MM-dd-yyyy');
  return DateTime.fromFormat(
    `${currentDate} ${time} ${ampm}`,
    'MM-dd-yyyy hh:mm a',
  );
};

interface FormattedInitialShiftHours {
  [key: string]: ShiftHours[];
}

export const timeParser = (date?: Date) => {
  if (!date) {
    return 0;
  }
  return parseFloat(`${date.getHours()}.${date.getMinutes()}`);
};

const formatInitialShiftHours = (shift: Shift): FormattedInitialShiftHours => {
  const formattedShiftHours: FormattedInitialShiftHours = {};
  shift.hours.forEach((element) => {
    if (_.isArray(formattedShiftHours[element.day])) {
      formattedShiftHours[element.day].push(element);
    } else {
      formattedShiftHours[element.day] = [element];
    }
  });
  return formattedShiftHours;
};

export const formatInitialShiftSchedule = (shift: Shift) => {
  const formattedShiftHours = formatInitialShiftHours(shift);
  const temp = _.cloneDeep(INITIAL_SCHEDULE_STATE);
  Object.keys(formattedShiftHours).forEach((day) => {
    if (formattedShiftHours[day].length) {
      temp[day as unknown as keyof IWeeklySchedule].isWorkingDay = true;
      temp[day as unknown as keyof IWeeklySchedule].workingPeriods =
        formattedShiftHours[day].map((timePeriod) => {
          const [fromHours, fromMinutes] = timePeriod.start.split(':');
          const [toHours, toMinutes] = timePeriod.end.split(':');
          return new TimePeriod(
            { hours: Number(fromHours), minutes: Number(fromMinutes) },
            { hours: Number(toHours), minutes: Number(toMinutes) },
          );
        });
    }
  });
  return temp;
};

export const setTimeScheduleErrors = (timeSchedule: IWeeklySchedule) => {
  const timeSheduleKeys = Object.keys(timeSchedule);
  return timeSheduleKeys.reduce((acc, key) => {
    const { workingPeriods } =
      timeSchedule[key as unknown as keyof IWeeklySchedule];
    return {
      ...acc,
      [key]: workingPeriods.reduce(
        (_acc, period) => ({
          ..._acc,
          [period.key]: { from: '', to: '' },
        }),
        {},
      ),
    };
  }, {});
};

export const isWorkingPeriodsValid = (
  timeSchedule: IWeeklySchedule,
  timeErrors: ITimeErrors,
) => {
  const workingDates = Object.keys(timeSchedule).filter(
    (day) => timeSchedule[day as unknown as keyof IWeeklySchedule].isWorkingDay,
  );
  const isPeriodsValid = workingDates.every((day) => {
    const validPeriods = timeSchedule[
      day as unknown as keyof IWeeklySchedule
    ].workingPeriods.filter((period) =>
      TimeErrors.checkIsPeriodCorrect(period),
    );
    return (
      validPeriods.length ===
      timeSchedule[day as unknown as keyof IWeeklySchedule].workingPeriods
        .length
    );
  });

  return (
    isPeriodsValid &&
    workingDates.every((day) => {
      const errorsKeys = Object.keys(timeErrors[day]);
      return errorsKeys.every(
        (key) => !timeErrors[day][key].from && !timeErrors[day][key].to,
      );
    })
  );
};

export const isSimplePeriodNotValid = (simpleTimeErrors: ISimpleTimeErrors) =>
  !!simpleTimeErrors.from || !!simpleTimeErrors.to;

export const checkIsScheduleSimple = (schedule: IWeeklySchedule) => {
  const weekDays = Object.keys(schedule);
  const workingPeriods = weekDays.map(
    (weekDay) =>
      schedule[weekDay as unknown as keyof IWeeklySchedule].workingPeriods,
  );
  const timePeriodsFromWorkingDays = workingPeriods
    .filter(
      (workingPeriods, index) =>
        schedule[index as unknown as keyof IWeeklySchedule].isWorkingDay ===
        true,
    )
    .map((workingPeriod) => workingPeriod[0]);

  const isNotWorkingDays = weekDays
    .map(
      (weekDay) =>
        schedule[weekDay as unknown as keyof IWeeklySchedule].isWorkingDay,
    )
    .every((item) => !item);

  const isNotMultipleWorkingPeriods = workingPeriods.every(
    (item) => item.length === 1,
  );

  const isAllWorkingPeriodsSame = timePeriodsFromWorkingDays.every(
    (item) =>
      item.from?.getTime() === timePeriodsFromWorkingDays[0].from?.getTime() &&
      item.to?.getTime() === timePeriodsFromWorkingDays[0].to?.getTime(),
  );

  return (
    isNotWorkingDays || (isNotMultipleWorkingPeriods && isAllWorkingPeriodsSame)
  );
};
