import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { FormAlert } from 'components/alert';
import { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { Checkbox, Select, TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getPhoneSchema, postalCode } from 'utils/validation/validators';
import { validatePassword } from 'utils/validation/password';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';
import { IState } from '../../../../../api/services/state/types';
import { IProfessionalSupervisorBody } from '../../../../../api/services/user/types';
import { TITLES } from '../../../../../common/constants/professional-supervisor';
import { Button } from '../../../../../components/common/button';
import { ProfessionalSupervisorSignUpFormSkeleton } from './skeleton';
import { useAuthSelector } from '../../../../../store/selectors/auth';
import { professionalSupervisorInvitationSignUpRequest } from '../../../../../store/reducers/auth/actions';
import { PrivacyPolicyModal } from '../../../../../components/privacy-policy-modal';

interface IProfessionalSupervisorSignUpFormProps {
  token: string;
}

const ProfessionalSupervisorValidationSchema = Yup.object().shape({
  given: Yup.string().required('Required'),
  family: Yup.string().required('Required'),
  middleName: Yup.string().nullable(),
  email: Yup.string().email('Invalid email').required('Required'),
  title: Yup.string().oneOf(TITLES, 'must be a valid title'),
  line1: Yup.string().required('Required'),
  line2: Yup.string().optional(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: postalCode.required('Required'),
  phone: getPhoneSchema().required('Required'),
  password: Yup.string()
    .max(128)
    .required('Required')
    .min(12, 'must be at least 12 characters')
    .test(
      'isValidPassword',
      'must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character',
      validatePassword,
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('please, confirm your password'),
  acceptedTermsOfUse: Yup.boolean().required('Required'),
});

const EMPTY_VALUES = {
  given: '',
  family: '',
  middleName: '',
  email: '',
  title: 'None',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postalCode: '',
  phone: '',
  password: '',
  confirmPassword: '',
  acceptedTermsOfUse: false,
};

interface IFormValues extends IProfessionalSupervisorBody {
  password: string;
  confirmPassword: string;
}

export function ProfessionalSupervisorSignUpForm(
  props: IProfessionalSupervisorSignUpFormProps,
) {
  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState<IState[]>([]);
  const [initialValues, setInitialValues] = useState<IFormValues>(EMPTY_VALUES);
  const [error, setError] = useState<string | string[]>('');
  const [privacyPolicyDialogOpened, setPrivacyPolicyDialogOpened] =
    useState(false);
  const { token } = props;

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const user = await api.auth.decodeInvitationSignUpToken(token);
        setInitialValues({
          ...EMPTY_VALUES,
          ..._.pick(user, ['given', 'middleName', 'family', 'email']),
          phone: convertPhoneFormFieldToTenDigitFormat(user.phone),
        });
        const states = await api.state.getStates({ limit: 100 });
        setStates(states.items);
      } catch (e) {
        setError(
          getErrorMessage(
            e,
            'Something went wrong with fetching the initial data',
          ),
        );
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, [token]);

  const statesDropdownOptions = useMemo(
    () =>
      states?.map((state) => ({ label: state.name, value: state.name })) || [],
    [states],
  );

  const titlesDropdownOptions = useMemo(
    () => TITLES.map((title) => ({ label: title, value: title })) || [],
    [],
  );

  const dispatch = useDispatch();
  const authState = useAuthSelector();
  const theme = useTheme();

  const onSubmit = async (values: IFormValues) => {
    dispatch(
      professionalSupervisorInvitationSignUpRequest({
        ..._.omit(values, ['confirmPassword']),
        title: values.title === 'None' ? null : values.title,
        phone: convertPhoneFormFieldToInternationalFormat(values.phone),
        token,
      }),
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={ProfessionalSupervisorValidationSchema}
    >
      {({
        touched,
        errors,
        getFieldProps,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
      }) =>
        loading ? (
          <ProfessionalSupervisorSignUpFormSkeleton />
        ) : (
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !isSubmitting) {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={theme.spacing(1)}
            maxWidth="sm"
          >
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Personal info
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="First Name"
                  helperText={
                    touched.given && errors.given ? errors.given : ' '
                  }
                  {...getFieldProps('given')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Middle Name"
                  helperText={
                    touched.middleName && errors.middleName
                      ? errors.middleName
                      : ' '
                  }
                  {...getFieldProps('middleName')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Last Name"
                  error={!!touched.family && !!errors.family}
                  helperText={
                    touched.family && errors.family ? errors.family : ' '
                  }
                  {...getFieldProps('family')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  fullWidth
                  label="Title"
                  options={titlesDropdownOptions}
                  placeholder="Title"
                  {...getFieldProps('title')}
                  errorMessage={
                    touched.title && errors.title ? errors.title : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Contact info
                </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Street address 1"
                  error={!!touched.line1 && !!errors.line1}
                  helperText={
                    touched.line1 && errors.line1 ? errors.line1 : ' '
                  }
                  {...getFieldProps('line1')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Street address 2"
                  error={!!touched.line2 && !!errors.line2}
                  helperText={
                    touched.line2 && errors.line2 ? errors.line2 : ' '
                  }
                  {...getFieldProps('line2')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="City"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city ? errors.city : ' '}
                  {...getFieldProps('city')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  fullWidth
                  label="State"
                  options={statesDropdownOptions}
                  placeholder="State"
                  errorMessage={
                    touched.state && errors.state ? errors.state : ''
                  }
                  {...getFieldProps('state')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="ZIP Code"
                  error={!!touched.postalCode && !!errors.postalCode}
                  helperText={
                    touched.postalCode && errors.postalCode
                      ? errors.postalCode
                      : ' '
                  }
                  {...getFieldProps('postalCode')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PhoneInput
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={
                    touched.phone && errors.phone ? errors.phone : ' '
                  }
                  {...getFieldProps('phone')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Account info
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Email"
                  error={!!touched.email && !!errors.email}
                  helperText={
                    touched.email && errors.email ? errors.email : ' '
                  }
                  {...getFieldProps('email')}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Password"
                  type="password"
                  error={!!touched.password && !!errors.password}
                  helperText={
                    touched.password && errors.password ? errors.password : ' '
                  }
                  {...getFieldProps('password')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  type="password"
                  label="Confirm password"
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={
                    touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : ' '
                  }
                  {...getFieldProps('confirmPassword')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        {...getFieldProps('acceptedTermsOfUse')}
                        checked={values.acceptedTermsOfUse}
                      />
                      <FormHelperText error>
                        {touched.acceptedTermsOfUse &&
                        Boolean(errors.acceptedTermsOfUse)
                          ? errors.acceptedTermsOfUse
                          : ' '}
                      </FormHelperText>
                    </>
                  }
                  slotProps={{
                    typography: {
                      fontSize: '13px',
                      fontWeight: 700,
                      lineHeight: '16px',
                    },
                  }}
                  label={
                    <span>
                      I accept the{' '}
                      <Link
                        underline="hover"
                        color="#6DBDBF"
                        onClick={(e) => {
                          e.preventDefault();
                          setPrivacyPolicyDialogOpened(true);
                        }}
                      >
                        Terms of Use and Privacy Policy
                      </Link>
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {(error || authState.error) && (
                    <FormAlert severity="error">
                      {getErrorMessageComponent(authState.error || error)}
                    </FormAlert>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button
                size="large"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Sign Up
              </Button>
            </Grid>
            <PrivacyPolicyModal
              opened={privacyPolicyDialogOpened}
              onClose={() => setPrivacyPolicyDialogOpened(false)}
              onAccept={() => {
                setPrivacyPolicyDialogOpened(false);
                setFieldValue('acceptedTermsOfUse', true);
              }}
              policyTypes={['general', 'privacy-policy']}
            />
          </Grid>
        )
      }
    </Formik>
  );
}
