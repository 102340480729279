import { Grid, Skeleton, Typography } from '@mui/material';
import {
  Answer,
  AnswerType,
  IQuestionnaireResults,
  QuestionTypes,
} from 'api/services/questionnaire/types';
import { InfoIcon } from 'components/common/Info-icon';
import { SingleChoiceOption } from './SingleChoiceOption';
import { Text } from './Text';

interface IQuestionnaireResultsProps {
  questionnaireResults: IQuestionnaireResults | null;
  loading: boolean;
}

export function QuestionnaireResults(props: IQuestionnaireResultsProps) {
  const { questionnaireResults, loading } = props;

  const renderAnswers = (answer: Answer) => {
    if (!answer) return null;
    switch (answer.questionType) {
      case QuestionTypes.SINGLE_CHOICE: {
        return (
          <SingleChoiceOption
            label={(answer.answer as AnswerType)?.choiceLabel || ''}
          />
        );
      }
      case QuestionTypes.TEXT: {
        return (
          <Text
            text={answer.answer ? (answer.answer as string) : 'No answered'}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  if (loading) {
    return <Skeleton variant="rounded" width="100%" height="172px" />;
  }

  return (
    <Grid container spacing={2}>
      {questionnaireResults?.answers.length ? (
        questionnaireResults.answers.map((answer, i) => (
          <Grid key={i} xs={12} md={4} item>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                border: '1px solid rgba(202, 194, 190, 0.3)',
                borderRadius: '16px',
                fontFamily: 'PlusJakartaSans',
                padding: '16px',
                height: '100%',
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                flexWrap="nowrap"
                columnGap={1}
                sx={{
                  paddingBottom: '16px',
                  alignItems: { xs: 'flex-start', md: 'center' },
                }}
              >
                <Grid
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textAlign: 'center',
                  }}
                  item
                >
                  {answer.questionId && answer.questionId >= 10
                    ? answer.questionId
                    : `0${answer.questionId}`}
                </Grid>
                <Grid
                  item
                  flex={1}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    overflow: 'hidden',
                    whiteSpace: { md: 'nowrap' },
                    textOverflow: 'ellipsis',
                  }}
                >
                  {
                    questionnaireResults.questionnaire?.questions[i]
                      ?.questionText
                  }
                </Grid>
                <InfoIcon
                  content={
                    questionnaireResults.questionnaire?.questions[i]
                      ?.questionText
                  }
                  sx={{
                    display: { xs: 'none', md: 'block' },
                  }}
                />
              </Grid>
              {renderAnswers(answer)}
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid container item>
          <Typography variant="mono" sx={{ color: 'rgb(78,78,78)' }}>
            Participant does not have the questionnaire results yet
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
