import { useNavigate } from 'react-router-dom';
import { ReportsList } from 'components/shared-pages/location/reports/ReportsList';
import { EReportTypeKeys } from 'api/services/organization/types';
import { useOHCLocations } from 'hooks/useOHCLocations';
import { BasicContainer } from 'components/container';

export function OhcLocationReportsPage() {
  const { locations, areLocationsLoading } = useOHCLocations();

  const navigate = useNavigate();

  return (
    <BasicContainer>
      <ReportsList
        locations={locations}
        areLocationsLoading={areLocationsLoading}
        availableReportTypes={[
          EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
          EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
          EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
          EReportTypeKeys.STS_REPORT,
          EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
          EReportTypeKeys.COVER_LETTERS,
          EReportTypeKeys.SUMMARY_OF_TESTING,
          EReportTypeKeys.OTHER,
        ]}
        navigateToEditPage={(report, locationId) =>
          navigate(
            `/ohc/locations/${locationId}/${report.reportType}/${report.reportId}/update`,
          )
        }
        navigateToCreatePage={(reportType, locationId) =>
          navigate(`/ohc/locations/${locationId}/${reportType}/create`)
        }
      />
    </BasicContainer>
  );
}
