import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { ILocation } from '../api/services/organization/types';

export const useLocation = (paramKey: string = 'locationId') => {
  const params = useParams();
  const [location, setLocation] = useState<ILocation | null>(null);
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  useEffect(() => {
    const getLocation = async () => {
      const locationId = params[paramKey];
      if (!locationId) {
        return;
      }
      setIsLocationLoading(true);
      try {
        const location = await api.organization.getLocationById(locationId);
        setLocation(location);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLocationLoading(false);
      }
    };
    getLocation();
  }, [params]);

  return { location, isLocationLoading };
};
