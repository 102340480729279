import { Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { api } from 'api';
import {
  AnswerType,
  IQuestion,
  IQuestionnaireState,
  QuestionTypes,
} from 'api/services/questionnaire/types';
import { Heading } from 'components/common/heading';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthSelector } from 'store/selectors/auth';
import _ from 'lodash';
import { Checkbox } from 'components/common/input/Checkbox';
import { Button } from 'components/common/button';
import { ArrowForward } from '@mui/icons-material';
import { ParticipantInfoItem } from 'components/participant-hearing-report/components';
import { DateTime } from 'luxon';
import { getErrorMessage } from 'utils';
import { UserRoles } from 'api/services/auth/types';
import { TextInput } from 'components/common/input';
import { Options } from './components/Options';
import { getEmptyAnswer } from './utils';

interface QuestionnaireFormProps {
  setError: (msg: string) => void;
}

export function QuestionnaireForm(props: QuestionnaireFormProps) {
  const { setError } = props;
  const navigate = useNavigate();
  const { user } = useAuthSelector();
  const [searchParams] = useSearchParams();
  const [questionnaireState, setQuestionnaireState] =
    useState<IQuestionnaireState>({
      questionnaire: null,
      answers: [],
    });
  const [certified, setCertified] = useState(false);
  const [loading, setLoading] = useState(false);
  const resourceType = searchParams.get('resourceType');

  const getQuestionnaire = async () => {
    if (!resourceType) {
      return;
    }
    try {
      const data = await api.questionnaire.getQuestionnaire(resourceType);
      setQuestionnaireState({
        questionnaire: data,
        answers: data.questions?.map((q: IQuestion) => getEmptyAnswer(q)),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getQuestionnaire();
  }, [searchParams]);

  const setAnswer = (value: AnswerType | string, index: number) => {
    if (!questionnaireState.answers.length) {
      return;
    }
    const newAnswers = _.cloneDeep(questionnaireState?.answers);
    newAnswers[index].answer = value;
    setQuestionnaireState((prev) => ({ ...prev, answers: newAnswers }));
  };

  const onSubmit = async () => {
    if (!resourceType) {
      return;
    }

    try {
      setLoading(true);
      await api.questionnaire.saveQuestionnaireResults(resourceType, {
        answers: questionnaireState.answers,
      });
      navigate('/questionnaire/success');
    } catch (e: any) {
      setError(getErrorMessage(e, 'Could not save questionnaire') as string);
    } finally {
      setLoading(false);
    }
  };

  const isParticipant = useMemo(
    () => user?.role === UserRoles.Participant,
    [user],
  );

  const isValid = useMemo(() => {
    if (questionnaireState) {
      return questionnaireState?.questionnaire?.questions?.every(
        (question, index) => {
          if (question.mandatory) {
            return !!questionnaireState.answers[index].answer;
          }
          return true;
        },
      );
    }
  }, [questionnaireState]);

  const renderQuestion = (question: IQuestion, questionIndex: number) => {
    switch (question.questionType) {
      case QuestionTypes.SINGLE_CHOICE:
        return (
          <Options
            question={question}
            value={
              questionnaireState?.answers[questionIndex]?.answer as AnswerType
            }
            setValue={(value: AnswerType) => setAnswer(value, questionIndex)}
          />
        );

      case QuestionTypes.TEXT:
        return (
          <Grid sx={{ maxWidth: { xs: '100%', sm: '328px' } }}>
            <TextInput
              fullWidth
              variant="standard"
              type="text"
              onChange={(e) => setAnswer(e.target.value, questionIndex)}
              value={questionnaireState?.answers[questionIndex]?.answer}
              margin="none"
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Grid pt={{ xs: 4, sm: 6 }} pb={2}>
      <Heading variant="h2" pb={{ xs: 4, sm: 7 }}>
        {`Hello, ${user?.given}. We have a few questions for you`}
      </Heading>

      <Grid>
        {questionnaireState?.questionnaire?.questions.map((question, index) => (
          <Grid container gap={3} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              sx={{ width: '64px' }}
            >
              <Typography
                variant="subtitle1"
                pb={{ xs: 0, sm: 6 }}
                sx={{
                  fontSize: '40px',
                  fontWeight: 300,
                  lineHeight: '130%',
                  letterSpacing: '-0.5px',
                }}
              >
                {question.questionId < 10
                  ? `0${question.questionId}`
                  : question.questionId}
              </Typography>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'none', sm: 'block' } }}
            />
            <Grid item pb={{ xs: 3, sm: 6 }} xs={12}>
              <Heading
                variant="subtitle1"
                sx={{ fontSize: '17px', fontWeight: 700, pb: 3 }}
              >
                {question.questionText}
              </Heading>
              {renderQuestion(question, index)}
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid pl={{ xs: 0, sm: 14 }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={certified}
                onChange={(e) => setCertified(e.currentTarget.checked)}
              />
            }
            slotProps={{
              typography: {
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '16px',
              },
            }}
            label="I certify that the information provided is true and accurate."
          />
        </Grid>

        <Grid item xs={12} sx={{ padding: '12px 0' }}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            endIcon={<ArrowForward />}
            disabled={!certified || !isValid || loading || !isParticipant}
          >
            Submit
          </Button>
        </Grid>
        {!isParticipant && (
          <Typography sx={{ marginBottom: 2, fontSize: '10px' }} color="error">
            You are not allowed to complete the questionnaire because you are
            not a Participant
          </Typography>
        )}
        <Grid item xs={12}>
          <ParticipantInfoItem
            title="Date"
            value={DateTime.now().toFormat('dd.MM.yy')}
            loading={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
