import { useMemo } from 'react';
import { Chip } from '@mui/material';

interface IResultsChipProps {
  hearingTestResult: {
    threshold_left_2000: number;
    threshold_left_3000: number;
    threshold_left_4000: number;
    threshold_right_2000: number;
    threshold_right_3000: number;
    threshold_right_4000: number;
  } | null;
  side: 'left' | 'right';
}

export function ResultsChip(props: IResultsChipProps) {
  const { hearingTestResult, side } = props;

  const value = useMemo(() => {
    if (!hearingTestResult) return 0;
    const value2k = hearingTestResult[`threshold_${side}_2000`] || 0;
    const value3k = hearingTestResult[`threshold_${side}_3000`] || 0;
    const value4k = hearingTestResult[`threshold_${side}_4000`] || 0;
    return (value2k + value3k + value4k) / 3;
  }, [hearingTestResult, side]);

  const chipColors = useMemo(() => {
    if (value > 10) {
      return {
        text: '#701313',
        background: '#FDD7D7',
      };
    }
    return {
      text: '#287013',
      background: '#E1FDD7',
    };
  }, [value]);

  return hearingTestResult ? (
    <Chip
      label={`${Math.round(value)}dB`}
      sx={{
        backgroundColor: chipColors.background,
        color: chipColors.text,
        padding: '4px 8px',
        fontSize: '9px',
        lineHeight: '100%',
        fontWeight: 700,
        height: 'auto',
        borderRadius: '8px',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '38px',
        '& .MuiChip-label': {
          padding: 0,
        },
      }}
    />
  ) : (
    <span>-</span>
  );
}
