import {
  OptionsWithExtraProps,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
  enqueueSnackbar,
  closeSnackbar,
} from 'notistack';

const commonOptions: OptionsWithExtraProps<VariantType> = {
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  autoHideDuration: 2000,
};

export const showSnackbar = (
  message: SnackbarMessage,
  options?: OptionsWithExtraProps<VariantType>,
): SnackbarKey => {
  const key: SnackbarKey = enqueueSnackbar(message, {
    ...commonOptions,
    ...{
      ...options,
      SnackbarProps: {
        onClick: () => {
          closeSnackbar(key);
        },
        ...options?.SnackbarProps,
      },
    },
  });
  return key;
};
