import { Divider, Grid, Typography } from '@mui/material';
import { getUserFullNameString } from 'utils/getUserFullNameString';
import { useMemo } from 'react';
import { HCPSupervisor } from 'api/services/auth/types';

interface HCPInfoProps {
  HCPSupervisors: HCPSupervisor[] | null;
}

export function HCPInfo(props: HCPInfoProps) {
  const { HCPSupervisors } = props;

  const HCPSupervisorsNames = useMemo(() => {
    if (!HCPSupervisors?.length) {
      return 'Not assigned';
    }
    return HCPSupervisors.map((item) => getUserFullNameString(item.user)).join(
      ', ',
    );
  }, [HCPSupervisors]);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      gap={1}
      sx={{
        padding: 1,
        borderRadius: 1,
        backgroundColor: '#F1EFE6',
        marginBottom: 1,
        width: 'fit-content',
      }}
    >
      <Typography
        sx={{
          fontSize: '10px',
          letterSpacing: '0.2px',
        }}
      >
        HCP Supervisor
      </Typography>
      <Divider orientation="vertical" />
      <Typography
        sx={{
          fontSize: '11px',
          fontWeight: 700,
        }}
      >
        {HCPSupervisorsNames}
      </Typography>
    </Grid>
  );
}
