import { isSTSShiftPresent } from 'utils';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { YesNoCell } from './YesNoCell';

interface ISTSShiftCellProps {
  testResult: ParticipantWithLastHearingTestResultViewItem;
  isAgeAdjustment: boolean;
}

export function STSShiftCell(props: ISTSShiftCellProps) {
  const { testResult, isAgeAdjustment } = props;

  const leftSTS = isAgeAdjustment
    ? testResult.leftAgeAdjustedSTS
    : testResult.leftSTS;

  const rightSTS = isAgeAdjustment
    ? testResult.rightAgeAdjustedSTS
    : testResult.rightSTS;

  return (
    <YesNoCell value={isSTSShiftPresent(leftSTS, rightSTS) ? 'Yes' : 'No'} />
  );
}
