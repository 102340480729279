import { Typography, TypographyProps } from '@mui/material';

export function TableCellText(props: TypographyProps) {
  const { children, ...rest } = props;
  return (
    <Typography
      sx={{
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.25px',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
