import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Header } from 'components/header';
import { ParticipantHearingReportPage } from '../../../../components/participant-hearing-report';
import { useAuthSelector } from '../../../../store/selectors/auth';
import { UserRoles } from '../../../../api/services/auth/types';

export function ProfessionalSupervisorParticipantReportPage() {
  const { participantId } = useParams();
  const { user } = useAuthSelector();

  const isReportEditable = useMemo(
    () => user?.role === UserRoles.ProfessionalSupervisor,
    [user],
  );
  return (
    <BasicContainer>
      <Header
        isEmpty
        logo={
          <img
            alt="logo"
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            width="88px"
          />
        }
      />
      <ParticipantHearingReportPage
        participantId={Number(participantId)}
        editable={isReportEditable}
      />
    </BasicContainer>
  );
}
