import { Box, Grid, Skeleton, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { PageHeader } from 'components/admin-page-layout';
import { IQuestionnaireResults } from 'api/services/questionnaire/types';
import { Participant } from '../../api/services/auth/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../utils';
import { api } from '../../api';
import {
  ParticipantHearingReport,
  ParticipantHearingReportHeading,
} from './components';
import { useTableState } from '../../hooks/useTableState';
import {
  EHearingTestResultTypes,
  HearingTestResult,
} from '../../api/services/hearing-test-results/types';
import { ParticipantHearingTestResultsTable } from '../participant-hearing-test-result-table/ParticipantHearingTestResultsTable';
import { ReviewStatusSection } from './components/review-status-section';

interface ParticipantHearingReportPageProps {
  participantId: number;
  editable?: boolean;
}

export function ParticipantHearingReportPage({
  participantId,
  editable,
}: ParticipantHearingReportPageProps) {
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [participantLoading, setParticipantLoading] = useState(true);
  const [questionnaireResults, setQuestionnaireResults] =
    useState<IQuestionnaireResults | null>(null);
  const [questionnaireResultsLoading, setQuestionnaireResultsLoading] =
    useState(true);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!participantId) return;
      try {
        setParticipantLoading(true);
        const participantData = await api.participant.getParticipantById(
          participantId,
        );
        setParticipant(participantData);
        setParticipantLoading(false);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    };
    asyncRequest();
  }, [participantId]);

  const [selectedTestResult, setSelectedTestResult] =
    useState<HearingTestResult | null>(null);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!selectedTestResult?.id || !participantId) return;
      try {
        setQuestionnaireResultsLoading(true);
        const questionnaireResultData =
          await api.questionnaire.getQuestionnaireResults(
            participantId,
            selectedTestResult.id,
          );
        setQuestionnaireResults(questionnaireResultData);
      } catch (e) {
        if ((e as any).request?.status !== 404) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      } finally {
        setQuestionnaireResultsLoading(false);
      }
    };
    asyncRequest();
  }, [selectedTestResult]);

  const [baselineTestResult, setBaselineTestResult] =
    useState<HearingTestResult | null>(null);

  const fetchParticipantHearingTestResults = useCallback(
    async (limit: number, offset: number) => {
      if (!participantId) return null;
      const data = await api.participant.getHearingTestResultsByParticipantId(
        participantId,
        { limit, offset },
      );
      setSelectedTestResult(
        (prevState) =>
          (!prevState
            ? _.last(data.items)
            : data.items.find((item) => item.id === prevState.id)) || null,
      );
      return data;
    },
    [participantId],
  );

  const tableState = useTableState<HearingTestResult>({
    fetchDataFunction: fetchParticipantHearingTestResults,
  });

  const { data, setData, refreshData } = tableState;

  const theme = useTheme();

  useEffect(() => {
    if (!data || !selectedTestResult) return;
    const baselineTestResults = data
      .filter((item) =>
        [
          EHearingTestResultTypes.Baseline,
          EHearingTestResultTypes.Revise,
        ].includes(item.type),
      )
      .sort(
        (a, b) =>
          DateTime.fromISO(b.dateTime).toMillis() -
          DateTime.fromISO(a.dateTime).toMillis(),
      );
    setBaselineTestResult(
      baselineTestResults.find(
        (item) =>
          DateTime.fromISO(item.dateTime).toMillis() <=
          DateTime.fromISO(selectedTestResult.dateTime).toMillis(),
      ) || null,
    );
  }, [data, selectedTestResult]);

  const onUpdate = useCallback(async () => {
    if (!selectedTestResult) return;
    try {
      await refreshData();
    } catch (e) {
      showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
        variant: 'error',
      });
    }
  }, [tableState, selectedTestResult]);

  const onTypeChange = useCallback(
    async (
      hearingTestResultId: number | null,
      type: EHearingTestResultTypes,
      reason: string,
    ) => {
      try {
        if (hearingTestResultId) {
          await api.hearingTestResults.updateHearingTestResultType(
            hearingTestResultId,
            {
              type,
              reason,
            },
          );
          await refreshData();
          showSnackbar('Test Result is successfully updated', {
            variant: 'success',
          });
        }
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [data, setData, editable],
  );

  return (
    <Grid container direction="column">
      <PageHeader
        fontSize="20px"
        title={`${participant?.given} ${participant?.family}`}
        titleLoading={participantLoading}
        sx={{ pb: 4 }}
      />

      <ParticipantHearingReportHeading
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        Baseline test thresholds
      </ParticipantHearingReportHeading>
      {participantLoading ? (
        <Skeleton variant="rounded" width="100%" height="160px" />
      ) : (
        <ParticipantHearingTestResultsTable
          hearingTestResults={tableState.data}
          selectedResultId={selectedTestResult?.id}
          onRowClicked={(result) => setSelectedTestResult(result)}
          highlightHearingFrequencies
          onTypeChange={editable ? onTypeChange : undefined}
          refreshData={refreshData}
          isAgeAdjustedShiftShow={
            !!participant?.organization
              ?.isAgeAdjustmentApplicableToHearingTestResults
          }
        />
      )}
      <Box
        sx={{
          margin: '0 auto',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '16px 12px 0 12px',
          borderColor: '#d9d9d9 transparent transparent transparent',
          my: theme.spacing(4),
        }}
      />

      <Box mb={3}>
        <ReviewStatusSection
          hearingTestResult={selectedTestResult}
          editable={editable && !!selectedTestResult}
          onEdit={onUpdate}
        />
      </Box>

      <ParticipantHearingReport
        participant={participant}
        hearingTestResult={selectedTestResult}
        loading={participantLoading}
        baseLineHearingTestResult={
          ![
            EHearingTestResultTypes.Baseline,
            EHearingTestResultTypes.Revise,
          ].includes(selectedTestResult?.type as EHearingTestResultTypes)
            ? baselineTestResult
            : null
        }
        questionnaireResults={questionnaireResults}
        questionnaireResultsLoading={questionnaireResultsLoading}
        editable={editable}
        onUpdate={onUpdate}
      />
    </Grid>
  );
}
