import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { ILocation, Organization } from 'api/services/organization/types';
import {
  DateCell,
  LastTestDateCell,
  ListCell,
} from 'components/table/components';
import { ClinicReferralCell } from 'components/table/components/ClinicReferralCell';
import { OSHAShiftCell } from 'components/table/components/OSHAShiftCell';
import { ReportStatusCell } from 'components/table/components/ReportStatusCell';
import { STSShiftCell } from 'components/table/components/STSShiftCell';
import { Cell } from 'components/table/types';
import _ from 'lodash';
import { getUserFullNameString } from 'utils/getUserFullNameString';

export const getCells = ({
  location,
  organization,
  onPreview,
}: {
  location: ILocation | null;
  organization: Organization | null;
  onPreview: (
    participant: ParticipantWithLastHearingTestResultViewItem,
  ) => void;
}): Cell<ParticipantWithLastHearingTestResultViewItem>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'External ID',
    key: 'externalId',
  },
  {
    name: 'Full name',
    render: (item: ParticipantWithLastHearingTestResultViewItem) =>
      getUserFullNameString(item),
  },
  {
    name: 'Shifts',
    key: 'shifts',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <ListCell items={item.shifts.map((shift) => shift.name)} />
    ),
  },
  {
    name: 'Last test date',
    key: 'dateTime',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => {
      const showClinicReferral =
        item.clinicReferralParticipants.length > 0 &&
        item.clinicReferralParticipants[0].hearingTestResultId === null;
      const hearingTestResultDateTime =
        item.clinicReferralParticipants[0]?.hearingTestResult?.dateTime ?? '';
      const dateTime =
        item.dateTime > hearingTestResultDateTime
          ? item.dateTime
          : hearingTestResultDateTime;

      return showClinicReferral ? (
        <ClinicReferralCell
          date={item.clinicReferralParticipants[0].updated_at}
        />
      ) : (
        <LastTestDateCell
          date={dateTime}
          returnedFromDisabilityLeaveDate={item.returnedFromDisabilityLeaveDate}
          employmentDate={item.employmentDate}
          location={location}
        />
      );
    },
  },
  {
    name: 'Last test Status',
    key: 'status',
    align: 'center',
    render: ({
      status,
      subStatuses,
    }: ParticipantWithLastHearingTestResultViewItem) => (
      <ReportStatusCell status={status} subStatuses={subStatuses} />
    ),
    sortable: true,
    orderByValue: 'status',
  },
  {
    name: 'STS Shift',
    key: 'STSShift',
    align: 'center',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <STSShiftCell
        testResult={item}
        isAgeAdjustment={
          !!organization?.isAgeAdjustmentApplicableToHearingTestResults
        }
      />
    ),
    sortable: true,
    orderByValue: 'STSShift',
  },
  {
    name: 'OSHA Recordable Shift',
    key: 'OSHAShift',
    align: 'center',
    render: ({
      leftAgeAdjustedSTS,
      rightAgeAdjustedSTS,
      leftSTSFrequenciesAverage,
      rightSTSFrequenciesAverage,
    }: ParticipantWithLastHearingTestResultViewItem) => (
      <OSHAShiftCell
        leftAgeAdjustedSTS={leftAgeAdjustedSTS}
        rightAgeAdjustedSTS={rightAgeAdjustedSTS}
        leftSTSFrequenciesAverage={leftSTSFrequenciesAverage}
        rightSTSFrequenciesAverage={rightSTSFrequenciesAverage}
      />
    ),
    sortable: true,
    orderByValue: 'OSHAShift',
  },
  {
    name: 'Last signed report',
    key: 'dateTime',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <DateCell
        date={item.lastSignedReport?.created_at || ''}
        onClick={() => {
          if (!item.lastSignedReport || _.isEmpty(item?.lastSignedReport)) {
            return;
          }
          onPreview(item);
        }}
      />
    ),
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Phone',
    key: 'phone',
  },
];
