import { User } from 'api/services/auth/types';
import { StatusCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import i18next from 'i18n/config';

export const getCells = (): Cell<User>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.email'),
    key: 'email',
  },
  {
    name: i18next.t('common.firstName'),
    key: 'given',
  },
  {
    name: i18next.t('common.lastName'),
    key: 'family',
  },
  {
    name: i18next.t('common.role'),
    key: 'role',
    render: (item: User) => i18next.t(`common.${item.role}`),
  },
  {
    name: i18next.t('common.status'),
    key: 'status',
    render: (item: User) => (
      <StatusCell
        status={!item.active ? 'deactivated' : item.invitationStatus}
      />
    ),
  },
];
