export const validatePassword = (value: string) =>
  /\d+/.test(value) &&
  /[a-z]+/.test(value) &&
  /[A-Z]+/.test(value) &&
  /[$%^&*()_+|~\-=\\`{}[\]:";'<>?,/.!#@]+/.test(value) &&
  value
    .split('')
    .every(
      (letter) =>
        /\d+/.test(letter) ||
        /[a-z]+/.test(letter) ||
        /[A-Z]+/.test(letter) ||
        /[$%^&*()_+|~\-=\\`{}[\]:";'<>?,/.!#@]+/.test(letter),
    );
