import { Grid, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Select, TextInput } from '../common/input';
import { FormModal } from './FormModal';
import { FullHeightContainer } from '../container';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../utils';

interface DeferringParticipantFromTestingModalProps {
  opened: boolean;
  onClose: () => void | Promise<void>;
  onSubmit: (values: {
    deferringReason: string;
    deferringReasonNote?: string | null;
  }) => void | Promise<void>;
}

const REASONS = [
  {
    label: 'Illness (Sinus infection, congestion, etc)',
    value: 'Illness (Sinus infection, congestion, etc)',
  },
  {
    label: 'Disability Leave',
    value: 'Disability Leave',
  },
  {
    label: 'Maternity Leave',
    value: 'Maternity Leave',
  },
  {
    label: 'On vacation',
    value: 'On vacation',
  },
];

export function DeferringParticipantFromTestingModal(
  props: DeferringParticipantFromTestingModalProps,
) {
  const { opened, onClose, onSubmit } = props;

  const initialValues = useMemo(
    () => ({
      deferringReason: '',
      deferringReasonNote: '',
    }),
    [],
  );

  const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        deferringReason: Yup.string()
          .nullable()
          .max(100, 'limited to 100 characters')
          .required('required'),
        deferringReasonNote: Yup.string()
          .nullable()
          .max(200, 'limited to 200 characters'),
      }),
      onSubmit: async (values) => {
        try {
          await onSubmit({
            ...values,
            deferringReasonNote: values.deferringReasonNote || null,
          });
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)));
          console.log(e);
        }
      },
    });

  const theme = useTheme();
  return (
    <FormModal
      open={opened}
      handleClose={onClose}
      handleSubmit={handleSubmit}
      title="You are about to defer this employee’s scheduled test for this visit"
      isSubmitting={isSubmitting}
    >
      <FullHeightContainer maxWidth="sm" disableGutters>
        <Grid
          component="form"
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit();
            }
          }}
          container
          columnSpacing={{ xs: 0, sm: theme.spacing(2) }}
        >
          <Grid item xs={12}>
            <Typography mb={2}>Please add a reason below:</Typography>
            <Select
              fullWidth
              options={REASONS}
              value={values.deferringReason}
              label="Reason"
              onChange={(e) => {
                setFieldValue('deferringReason', e.target.value);
                if (
                  e.target.value !==
                  'Illness (Sinus infection, congestion, etc)'
                ) {
                  setFieldValue('deferringReasonNote', '');
                }
              }}
              errorMessage={
                touched?.deferringReason && errors?.deferringReason
                  ? (errors?.deferringReason as string)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextInput
              disabled={
                values.deferringReason !==
                'Illness (Sinus infection, congestion, etc)'
              }
              fullWidth
              multiline
              maxRows={4}
              rows={4}
              value={values.deferringReasonNote}
              label="Note"
              onChange={(e) =>
                setFieldValue('deferringReasonNote', e.target.value)
              }
              error={
                !!touched?.deferringReasonNote && !!errors?.deferringReasonNote
              }
              helperText={
                touched?.deferringReasonNote && errors?.deferringReasonNote
                  ? (errors?.deferringReasonNote as string)
                  : ''
              }
            />
          </Grid>
        </Grid>
      </FullHeightContainer>
    </FormModal>
  );
}
