import { Chip } from 'components/common/chip';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Grid } from '@mui/material';

interface IClinicReferralCellProps {
  date: string;
}

const chipColors = {
  text: '#6415bd',
  dot: '#ab71ec',
  background: 'rgba(151, 87, 225, 0.15)',
};

export function ClinicReferralCell(props: IClinicReferralCellProps) {
  const { date } = props;

  const formattedDate = useMemo(() => {
    if (!date) {
      return 'N/A';
    }
    const dateTime: DateTime = DateTime.fromISO(date);
    return dateTime.toFormat('MM/dd/yyyy');
  }, [date]);

  return (
    <Grid container alignItems="flex-end" gap={1} wrap="nowrap">
      <Chip
        label={formattedDate}
        backgroundColor={chipColors.background}
        labelColor={chipColors.text}
        isDot
        dotColor={chipColors.dot}
      />
    </Grid>
  );
}
