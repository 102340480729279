import { useEffect } from 'react';

interface IUsePullingConfig {
  isPulling: boolean;
  func: () => void;
  pullingInterval?: number;
}

export const usePulling = (config: IUsePullingConfig) => {
  const { isPulling, pullingInterval = 1000, func } = config;

  useEffect(() => {
    if (isPulling) {
      const interval = setInterval(() => {
        func();
      }, pullingInterval);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isPulling]);
};
