import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import { IVisit } from '../../../api/services/organization/types';

export const cells: Cell<IVisit>[] = [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Timezone',
    key: 'timezone',
    render: (item: IVisit) => {
      const zone = item.timezone;
      return `${zone} (${DateTime.local({ zone }).toFormat('ZZZZ, ZZ')})`;
    },
  },
  {
    name: 'Ignore (months)',
    key: 'ignoreTestedEmployeesMonths',
  },
];
