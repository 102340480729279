import { TypographyProps } from '@mui/material';
import { Heading } from 'components/common/heading';

export function Subheading(props: TypographyProps) {
  const { children, ...otherProps } = props;
  return (
    <Heading
      variant="h5"
      sx={{ fontWeight: 700, fontSize: '18px' }}
      align="center"
      {...otherProps}
    >
      {children}
    </Heading>
  );
}
