import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import FileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { api } from 'api';
import BasicTable, { Action } from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { useSearchParamHandlers } from 'hooks/search-params/useSearchParamHandlers';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { DashboardSelect } from 'components/dashboard-select';
import { useOrganizationLocationsDashboardState } from 'hooks/search-params/useLocationsList';
import { ConfirmationModal, PdfPreviewModal } from 'components/modal';
import { IDateRange } from 'types/dataStructureTypes';
import {
  getReportFileNameByReportTypeKey,
  getReportFileNameBySignedDocument,
} from 'components/shared-pages/location/reports/utils';
import { useAuthSelector } from 'store/selectors/auth';
import { UserRoles } from 'api/services/auth/types';
import { ToggleTableFilter } from 'components/table-filter/ToggleTableFilter';
import { Add, FileUploadOutlined } from '@mui/icons-material';
import { useCells } from './cells';
import {
  EReportTypeKeys,
  ESignedDocumentStatuses,
  ICombinedReport,
  ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS,
  OrganizationReportType,
  OrganizationSelectOptionReportType,
} from '../../../api/services/organization/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';
import { Button } from '../../../components/common/button';
import { DateRangePickerModal } from '../../../components/modal';
import { UploadHistoricalDocumentForm } from '../../../components/forms/upload-historical-document-form';

interface IOrganizationReportsContentProps {
  organizationId: number;
}

export function OrganizationReportsContent(
  props: IOrganizationReportsContentProps,
) {
  const { organizationId } = props;

  const { user } = useAuthSelector();

  const theme = useTheme();
  const navigate = useNavigate();

  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [selectedReportType, setSelectedReportType] = useState<
    Omit<
      OrganizationSelectOptionReportType,
      'audiologist-cover-letters'
    >['value']
  >(EReportTypeKeys.ALL);

  const [reportToDelete, setReportToDelete] =
    useState<ICombinedReport | null>();

  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  const {
    selectedLocation,
    loading,
    locations,
    error,
    setError,
    setSelectedLocation,
  } = useOrganizationLocationsDashboardState<OrganizationReportType>(
    organizationId,
    { setCurrentReportType: setSelectedReportType },
  );

  const { setSearchParam } = useSearchParamHandlers();

  const getReportType = () =>
    selectedReportType === EReportTypeKeys.COVER_LETTERS ||
    selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
      ? EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
      : selectedReportType;

  useEffect(() => {
    if (error)
      showSnackbar(getErrorMessageComponent(error), {
        variant: 'error',
      });
    setError('');
  }, [error]);

  const locationSelectOptions = useMemo(
    () =>
      locations?.map((location) => ({
        label: `${location.organization?.name} - ${location.name}`,
        value: location.id,
      })) || [],
    [locations],
  );

  const onLocationSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedLocation(
        locations.find((location) => location.id === e.target.value) || null,
      );
    },
    [locations],
  );

  const getAdminOrganizationLocationLink = useCallback(
    (path: string) =>
      `/admin/organizations/${organizationId}/locations/${selectedLocation?.id}/${path}`,
    [navigate, organizationId, selectedLocation?.id],
  );

  const [dateRange, setDateRange] = useState<IDateRange>({
    dateFrom: DateTime.now().minus({ month: 12 }),
    dateTo: DateTime.now(),
  });
  const [datePickerOpened, setDatePickerOpened] = useState(false);

  const fetchReports = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
    ) => {
      if (!selectedLocation?.id) return null;
      try {
        const data = await api.organization.getLocationReportsByType(
          Number(selectedLocation?.id),
          getReportType(),
          {
            limit,
            offset,
            orderBy,
            orderDirection,
          },
        );
        return data;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [selectedLocation?.id, selectedReportType],
  );

  const tableState = useTableState<ICombinedReport>({
    fetchDataFunction: fetchReports,
  });

  const downloadReportPDF = async (item: ICombinedReport) => {
    try {
      if (
        selectedReportType === EReportTypeKeys.ALL &&
        item.reportType ===
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING &&
        selectedLocation
      ) {
        await api.organization.downloadCoverLetterAndSummaryOfTestingPDF(
          selectedLocation.id,
          item,
          'audiologist-cover-letter-pdf',
        );
        await api.organization.downloadCoverLetterAndSummaryOfTestingPDF(
          selectedLocation.id,
          item,
          'summary-of-testing-pdf',
        );
        return;
      }
      const attachedDocument = !_.isEmpty(item.signedDocument)
        ? item.signedDocument
        : item.historicalDocument;
      if (!attachedDocument) throw new Error('Invalid document');
      const fileName = getReportFileNameBySignedDocument(attachedDocument);
      const data = await api.organization.getLocationReportPDF(
        item.reportType,
        item.reportId,
      );
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleDownloadReportDocument = async (
    report: ICombinedReport,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    const { name, reportId, reportType, created_at } = report;

    try {
      let fileName = '';
      let data = null;
      if (
        (selectedReportType === EReportTypeKeys.COVER_LETTERS ||
          selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING) &&
        selectedLocation
      ) {
        fileName =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? `cover_letter_${name}.pdf`
            : `summary_of_testing_${name}.pdf`;
        data =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? await api.organization.getAudiologistCoverLetterPDF(
                selectedLocation?.id,
                reportId,
              )
            : await api.organization.getSummaryOfTestingPDF(
                selectedLocation?.id,
                reportId,
              );
      } else {
        fileName = getReportFileNameByReportTypeKey(reportType, created_at);
        data = await api.organization.getLocationReportPDF(
          reportType,
          reportId,
        );
      }

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const previewPdfReport = async (report: ICombinedReport) => {
    setOpenPdfPreview(true);
    setIsPreviewLoading(true);
    return handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
        setIsPreviewLoading(false);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const handleDeleteReport = async () => {
    if (!reportToDelete) return;
    try {
      await api.organization.deleteLocationReport(
        reportToDelete.reportType,
        reportToDelete.reportId,
        selectedLocation?.id,
      );

      showSnackbar('Report successfully deleted', {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not delete a report'),
        ),
        { variant: 'error' },
      );
    } finally {
      setReportToDelete(null);
    }
  };

  const actionButton = useMemo(() => {
    const result = {
      handler: () => setDatePickerOpened(true),
      title: 'Sign Referral List',
    };
    if (selectedReportType === EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS) {
      result.handler = () =>
        navigate(
          getAdminOrganizationLocationLink(
            `${EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS}/create`,
          ),
        );
      result.title = 'New Report';
    }
    if (
      selectedReportType ===
      EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
    ) {
      result.handler = () =>
        navigate(
          getAdminOrganizationLocationLink(
            `${EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS}/create`,
          ),
        );
      result.title = 'New Report';
    }
    if (
      [
        EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
        EReportTypeKeys.STS_REPORT,
      ].includes(selectedReportType)
    ) {
      result.handler = () => setDatePickerOpened(true);
      result.title = 'Generate Report';
    }

    return result;
  }, [selectedReportType, selectedLocation]);

  const actions: Action<ICombinedReport>[] = useMemo(
    () =>
      _.compact([
        {
          title: 'Preview',
          actionType: 'preview',
          onClick: (item: ICombinedReport) => previewPdfReport(item),
          disabled: (item: ICombinedReport) =>
            (_.isEmpty(item.historicalDocument) &&
              item.signedDocument?.status !== ESignedDocumentStatuses.Signed) ||
            // TODO: the AUDIOLOGIST_COVER_LETTERS and SUMMARY_OF_TESTING report display needs refactoring
            (selectedReportType === EReportTypeKeys.ALL &&
              item.reportType ===
                EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING),
        },
        selectedReportType !== EReportTypeKeys.COVER_LETTERS &&
          selectedReportType !== EReportTypeKeys.SUMMARY_OF_TESTING && {
            title: 'Download',
            actionType: 'download',
            onClick: (item: ICombinedReport) => downloadReportPDF(item),
            disabled: (item: ICombinedReport) =>
              _.isEmpty(item.historicalDocument) &&
              item.signedDocument?.status !== ESignedDocumentStatuses.Signed,
          },
        (selectedReportType === EReportTypeKeys.COVER_LETTERS ||
          selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING) && {
          title: 'Download',
          actionType: 'download',
          onClick: (item: ICombinedReport) =>
            selectedLocation &&
            api.organization.downloadCoverLetterAndSummaryOfTestingPDF(
              selectedLocation.id,
              item,
              selectedReportType === EReportTypeKeys.COVER_LETTERS
                ? 'audiologist-cover-letter-pdf'
                : 'summary-of-testing-pdf',
            ),
          disabled: (item: ICombinedReport) =>
            _.isEmpty(item.historicalDocument) &&
            item.signedDocument?.status !== ESignedDocumentStatuses.Signed,
        },
        [
          EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
          EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
          EReportTypeKeys.ALL,
        ].includes(selectedReportType) && {
          title: 'Edit',
          actionType: 'edit',
          onClick: (item: ICombinedReport) => {
            navigate(
              getAdminOrganizationLocationLink(
                `${item.reportType}/${item.reportId}/update`,
              ),
            );
          },
          disabled: (item: ICombinedReport) =>
            (item.reportType !==
              EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS &&
              item.reportType !== EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS) ||
            !_.isEmpty(item.historicalDocument) ||
            item.signedDocument?.status === ESignedDocumentStatuses.Signed,
        },
        user?.role !== UserRoles.Administrator && {
          title: 'Delete',
          actionType: 'delete',
          onClick: (item: ICombinedReport) => setReportToDelete(item),
        },
      ]),
    [selectedLocation, selectedReportType],
  );

  const cells = useMemo(() => useCells({ actions }), [actions]);

  const dateRangePickerTitle = useMemo(() => {
    if (selectedReportType === EReportTypeKeys.MEDICAL_REFERRAL_LISTS) {
      return 'Referral list date range';
    }
    if (selectedReportType === EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT) {
      return 'OSHA Recordable STS date range';
    }
    if (selectedReportType === EReportTypeKeys.STS_REPORT) {
      return 'STS date range';
    }
    return 'Date range';
  }, [selectedReportType]);

  return (
    <Grid>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        isLoading={isPreviewLoading}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <Grid container mb={theme.spacing(3)} gap={2}>
            <Grid item xs={12} sm="auto" mt={theme.spacing(2)}>
              <DashboardSelect
                label="Location"
                labelIcon="location"
                value={selectedLocation?.id || ''}
                options={locationSelectOptions}
                onChange={onLocationSelect}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'inline-flex' }}>
          <Grid item sx={{ marginRight: 1 }}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              endIcon={<FileUploadOutlined fontSize="small" />}
              onClick={() => {
                setUploadModalOpened(true);
              }}
            >
              Upload Report
            </Button>
          </Grid>
          {![
            EReportTypeKeys.COVER_LETTERS,
            EReportTypeKeys.SUMMARY_OF_TESTING,
            EReportTypeKeys.OTHER,
          ].includes(selectedReportType) && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              endIcon={<Add fontSize="small" />}
              onClick={actionButton.handler}
            >
              {actionButton.title}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container mb={theme.spacing(0.5)}>
        <ToggleTableFilter
          options={ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS}
          onChange={(selectedReportType) => {
            setSelectedReportType(selectedReportType);
            setSearchParam('reportType', selectedReportType);
            setSearchParam('page', null);
          }}
          value={selectedReportType}
        />
      </Grid>

      <BasicTable<ICombinedReport> cells={cells} tableState={tableState} />
      <DateRangePickerModal
        title={dateRangePickerTitle}
        opened={datePickerOpened}
        onClose={() => setDatePickerOpened(false)}
        onSelect={({ dateFrom, dateTo }) => {
          setDateRange({
            dateFrom,
            dateTo,
          });
          navigate({
            pathname: `/professional-supervisor/locations/${selectedLocation?.id}/${selectedReportType}/sign`,
            search: createSearchParams({
              dateFrom: dateFrom?.toFormat('yyyy-MM-dd') || '',
              dateTo: dateTo?.toFormat('yyyy-MM-dd') || '',
            }).toString(),
          });
        }}
        dateRange={dateRange}
      />

      <ConfirmationModal
        open={!!reportToDelete}
        handleClose={() => setReportToDelete(null)}
        handleSubmit={handleDeleteReport}
        submitButtonTitle="Delete report"
        title={
          <>
            You are about to delete the report
            <br />
            Are you sure?
          </>
        }
      />
      {uploadModalOpened && (
        <UploadHistoricalDocumentForm
          reportType={
            selectedReportType === EReportTypeKeys.COVER_LETTERS ||
            selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
              ? EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
              : selectedReportType
          }
          locationId={Number(selectedLocation?.id)}
          handleClose={() => setUploadModalOpened(false)}
          handleSubmit={() => {
            tableState.refreshData();
            setUploadModalOpened(false);
          }}
        />
      )}
    </Grid>
  );
}
