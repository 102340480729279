import { AdminAPI } from './admin';
import { AuthAPI } from './auth';
import { OrganizationAPI } from './organization';
import { UserAPI } from './user';
import { StateAPI } from './state';
import { UploadsAPI } from './uploads';
import { HearingTestResultsAPI } from './hearing-test-results';
import { ParticipantAPI } from './participant';
import { QuestionnaireAPI } from './questionnaire';
import { AudiologistNotesAPI } from './audiologist-notes';
import { ExternalClinicAPI } from './external-clinic';

export const api = {
  auth: new AuthAPI(),
  user: new UserAPI(),
  admin: new AdminAPI(),
  organization: new OrganizationAPI(),
  state: new StateAPI(),
  uploads: new UploadsAPI(),
  hearingTestResults: new HearingTestResultsAPI(),
  participant: new ParticipantAPI(),
  questionnaire: new QuestionnaireAPI(),
  audiologistNotes: new AudiologistNotesAPI(),
  externalClinic: new ExternalClinicAPI(),
};
