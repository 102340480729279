import React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import { EToolbarButtonTypes } from './types';

export const getToolbarButtonClickHandler =
  (
    buttonType: EToolbarButtonTypes,
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
  ) =>
  (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (buttonType === EToolbarButtonTypes.UnorderedList) {
      setEditorState(RichUtils.toggleBlockType(editorState, buttonType));
    } else {
      setEditorState(RichUtils.toggleInlineStyle(editorState, buttonType));
    }
  };

export const isToolbarButtonActive = (
  buttonType: EToolbarButtonTypes,
  editorState: EditorState,
) => {
  if (buttonType === EToolbarButtonTypes.UnorderedList) {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return blockType === buttonType;
  }
  return editorState.getCurrentInlineStyle().has(buttonType);
};
