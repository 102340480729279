import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { Organization } from 'api/services/organization/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { TextInput } from 'components/common/input';

interface OrganizationFormProps {
  externalClinic?: Organization | null;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function OrganizationForm(props: OrganizationFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();

  const { externalClinic, handleSubmit, handleClose } = props;

  const OrganizationFormSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        externalId: Yup.string().optional(),
        active: Yup.boolean().optional(),
        requestMFAOnEachLogin: Yup.boolean().optional(),
        isAgeAdjustmentApplicableToHearingTestResults: Yup.boolean().optional(),
      }),
    [i18n.language],
  );

  const initialValues = externalClinic
    ? {
        name: externalClinic.name,
        externalId: externalClinic.externalId,
        active: externalClinic.active,
        requestMFAOnEachLogin: externalClinic.requestMFAOnEachLogin,
        isAgeAdjustmentApplicableToHearingTestResults:
          externalClinic.isAgeAdjustmentApplicableToHearingTestResults,
      }
    : {
        name: '',
        externalId: '',
        active: true,
        requestMFAOnEachLogin: false,
        isAgeAdjustmentApplicableToHearingTestResults: true,
      };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      if (externalClinic)
        await api.organization.updateOrganization(externalClinic.id, values);
      else await api.organization.createOrganization(values);
      showSnackbar(
        externalClinic
          ? t('common.organizationSuccessfullyUpdated')
          : t('common.organizationSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
      handleSubmit();
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || t('common.couldNotSaveForm'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OrganizationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            externalClinic
              ? t('common.editOrganization')
              : t('common.createOrganization')
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.name')}
                  variant="outlined"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name ? errors.name : ' '}
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label={t('common.externalId')}
                  variant="outlined"
                  type="text"
                  name="externalId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.externalId}
                  error={touched.externalId && Boolean(errors.externalId)}
                  helperText={
                    touched.externalId && errors.externalId
                      ? errors.externalId
                      : ' '
                  }
                  margin="none"
                  disabled={!!externalClinic?.externalId}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="active"
                      onChange={handleChange}
                      checked={values.active}
                    />
                  }
                  label={t('common.Active')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="requestMFAOnEachLogin"
                      onChange={handleChange}
                      checked={values.requestMFAOnEachLogin}
                    />
                  }
                  label={t('organizations.requestMFAOnEachLogin')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isAgeAdjustmentApplicableToHearingTestResults"
                      onChange={handleChange}
                      checked={
                        values.isAgeAdjustmentApplicableToHearingTestResults
                      }
                    />
                  }
                  label={t('organizations.ageAdjustmentApplies')}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
