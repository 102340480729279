import { SearchOutlined } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';

export function SearchBar(props: TextFieldProps) {
  return (
    <div>
      <TextField
        variant="standard"
        {...props}
        InputProps={{ endAdornment: <SearchOutlined fontSize="small" /> }}
      />
    </div>
  );
}
