import { FormHelperText, Grid, SxProps } from '@mui/material';
import {
  TimePickerProps,
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

export interface ITimePickerProps<T> extends TimePickerProps<T> {
  errorMessage?: string;
  withErrorMessage?: boolean;
  errorMessageSx?: SxProps;
}
export function TimePicker<T>(props: ITimePickerProps<T>) {
  const {
    errorMessage,
    withErrorMessage = true,
    errorMessageSx = {},
    ...restProps
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid sx={{ position: 'relative' }}>
        <MuiTimePicker
          sx={{ width: '100%' }}
          slotProps={{ textField: { error: !!errorMessage } }}
          {...restProps}
        />
        {withErrorMessage && (
          <FormHelperText
            sx={{ margin: '3px 14px 0', ...errorMessageSx }}
            error={!!errorMessage}
          >
            {errorMessage || ' '}
          </FormHelperText>
        )}
      </Grid>
    </LocalizationProvider>
  );
}
