import { ReportsList } from 'components/shared-pages/location/reports/ReportsList';
import { useHCPSupervisorDashboardState } from 'hooks/useHCPSupervisorDashboardState';
import { EReportTypeKeys } from 'api/services/organization/types';

export function HCPSupervisorReports() {
  const { locations, loading } = useHCPSupervisorDashboardState();

  return (
    <ReportsList
      locations={locations}
      areLocationsLoading={loading}
      availableReportTypes={[
        EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
        EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
        EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
        EReportTypeKeys.STS_REPORT,
        EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
        EReportTypeKeys.COVER_LETTERS,
        EReportTypeKeys.SUMMARY_OF_TESTING,
        EReportTypeKeys.OTHER,
      ]}
    />
  );
}
