import React, { useCallback, useMemo } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { BasicContainer } from 'components/container';
import { DateTime } from 'luxon';
import { cells } from './cells';
import {
  EReportStatuses,
  ESignedDocumentStatuses,
  IEquipmentSpecificationsAndNoiseLevelsReport,
} from '../../../../api/services/organization/types';
import { useLocation } from '../../../../hooks/useLocation';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { PageHeader } from '../../../../components/admin-page-layout';
import { Button } from '../../../../components/common/button';

export function EquipmentSpecificationAndNoiseLevelsReportsList() {
  const { locationId } = useParams();

  const fetchReferralLists = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId) {
        return EMPTY_PAGINATED_DATA;
      }
      return api.organization.getEquipmentSpecificationAndNoiseLevelsReportsForLocation(
        locationId,
        {
          limit,
          offset,
        },
      );
    },
    [locationId],
  );

  const tableState =
    useTableState<IEquipmentSpecificationsAndNoiseLevelsReport>({
      fetchDataFunction: fetchReferralLists,
    });

  const { location, isLocationLoading } = useLocation();

  const navigate = useNavigate();

  const actions = useMemo(
    () => [
      {
        title: 'Download',
        onClick: async (
          report: IEquipmentSpecificationsAndNoiseLevelsReport,
        ) => {
          try {
            const fileName = `equipmentspec_noiselevel_${DateTime.fromISO(
              report.created_at,
            ).toFormat('MMddyyyy')}.pdf`;
            const data =
              await api.organization.downloadSignedEquipmentSpecificationAndNoiseLevelsReport(
                report.id,
              );
            FileDownload(data, fileName);
          } catch (e) {
            showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
              variant: 'error',
            });
          }
        },
        disabled: (report: IEquipmentSpecificationsAndNoiseLevelsReport) =>
          report.signedDocument?.status !== ESignedDocumentStatuses.Signed,
      },
      {
        title: 'Edit',
        onClick: (report: IEquipmentSpecificationsAndNoiseLevelsReport) => {
          navigate(
            `/admin/organizations/${location?.managingOrganization}/locations/${locationId}/equipment-specifications-and-noise-levels-reports/${report.id}/update`,
          );
        },
        disabled: (report: IEquipmentSpecificationsAndNoiseLevelsReport) =>
          report.status !== EReportStatuses.Draft,
      },
    ],
    [location],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Equipment Specification & Noise Levels Reports"
        entityName={location?.name}
        titleLoading={isLocationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(
                `/admin/organizations/${location?.managingOrganization}/locations/${locationId}/equipment-specifications-and-noise-levels-reports/create`,
              );
            }}
          >
            Create
          </Button>
        }
      />
      <BasicTable<IEquipmentSpecificationsAndNoiseLevelsReport>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
    </BasicContainer>
  );
}
