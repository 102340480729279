import { Chip as ChipComponent, ChipProps } from '@mui/material';

interface IChipProps extends ChipProps {
  labelColor?: string;
  backgroundColor?: string;
  isDot?: boolean;
  dotColor?: string;
}

export function Chip(props: IChipProps) {
  const {
    labelColor = '#08161F',
    backgroundColor = 'rgba(202, 194, 190, 0.30)',
    isDot,
    dotColor = '#08161F',
    sx,
    ...restProps
  } = props;

  return (
    <ChipComponent
      sx={{
        backgroundColor,
        color: labelColor,
        padding: '4px 8px',
        fontSize: '9px',
        lineHeight: '100%',
        height: 'auto',
        borderRadius: '16px',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&.MuiChip-root::before': isDot
          ? {
              content: '""',
              height: '6px',
              width: '6px',
              borderRadius: '4px',
              backgroundColor: dotColor,
            }
          : {},
        '& .MuiChip-label': {
          fontWeight: 700,
          paddingLeft: isDot ? '4px' : 0,
          paddingRight: 0,
          lineHeight: '110%',
        },
        ...sx,
      }}
      {...restProps}
    />
  );
}
