import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserRoles } from 'api/services/auth/types';
import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { AccessButton } from 'components/common/button';
import { useAuthSelector } from '../../store/selectors/auth';

export function AdminDashboard() {
  const navigate = useNavigate();
  const { user } = useAuthSelector();
  const { t } = useTranslation();

  useIdentity();

  return (
    <BasicContainer>
      <Box mb={2}>
        <Typography variant="h5" fontWeight={700}>
          {t('common.adminDashboard')}
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="flex-start">
        {user?.role === UserRoles.SuperAdministrator && (
          <Grid item xs={12}>
            <AccessButton
              type="button"
              color="primary"
              variant="outlined"
              roles={[UserRoles.SuperAdministrator]}
              onClick={() => navigate('/admin/admins')}
            >
              {t('common.admins')}
            </AccessButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <AccessButton
            type="button"
            color="primary"
            variant="outlined"
            roles={[UserRoles.SuperAdministrator, UserRoles.Administrator]}
            onClick={() => navigate('/admin/organizations')}
          >
            {t('common.organizations')}
          </AccessButton>
        </Grid>
        <Grid item xs={12}>
          <AccessButton
            type="button"
            color="primary"
            variant="outlined"
            roles={[UserRoles.SuperAdministrator, UserRoles.Administrator]}
            onClick={() => navigate('/admin/external-clinics')}
          >
            External Clinics
          </AccessButton>
        </Grid>
        <Grid item xs={12}>
          <AccessButton
            type="button"
            color="primary"
            variant="outlined"
            roles={[UserRoles.SuperAdministrator, UserRoles.Administrator]}
            onClick={() => navigate('/admin/ohcs')}
          >
            {t('common.OHCs')}
          </AccessButton>
        </Grid>
        <Grid item xs={12}>
          <AccessButton
            type="button"
            color="primary"
            variant="outlined"
            roles={[UserRoles.SuperAdministrator, UserRoles.Administrator]}
            onClick={() => navigate('/admin/professional-supervisors')}
          >
            {t('common.professionalSupervisors')}
          </AccessButton>
        </Grid>
        <Grid item xs={12}>
          <AccessButton
            type="button"
            color="primary"
            variant="outlined"
            roles={[UserRoles.SuperAdministrator, UserRoles.Administrator]}
            onClick={() => navigate('/admin/account-managers')}
          >
            {t('common.accountManagers')}
          </AccessButton>
        </Grid>
      </Grid>
    </BasicContainer>
  );
}
