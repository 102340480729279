import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DailySchedule } from './types';

interface ITimeSchedulingTableRowProps {
  title: string;
  dailySchedule: DailySchedule;
  onChangeDayStatus: (isWorkingDay: boolean) => void;
  readOnly?: boolean;
  size?: 'middle' | 'small';
}

export function SimpleShiftCalendarRow(props: ITimeSchedulingTableRowProps) {
  const {
    title,
    dailySchedule,
    onChangeDayStatus,
    readOnly,
    size = 'middle',
  } = props;
  const { t } = useTranslation();

  const handleSwitchValueChange = () => {
    if (readOnly) return;
    onChangeDayStatus(Boolean(!dailySchedule.isWorkingDay));
  };

  return (
    <Grid
      onClick={handleSwitchValueChange}
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: size === 'middle' ? '32px' : '24px',
        height: size === 'middle' ? '32px' : '24px',
        borderRadius: '50%',
        fontWeight: 700,
        fontFamily: 'PlusJakartaSans',
        cursor: readOnly ? 'default' : 'pointer',
        background: dailySchedule.isWorkingDay
          ? '#8FE0B0'
          : 'rgba(202, 194, 190, 0.3)',
      }}
    >
      {t(`common.${title}`).slice(0, 1)}
    </Grid>
  );
}
