import { Tab as TabComponent, TabProps, useTheme } from '@mui/material';

export function Tab(props: TabProps) {
  const theme = useTheme();
  return (
    <TabComponent
      sx={{
        borderRadius: '8px',
        transition: 'all .2s',
        color: theme.palette.primary.main,
        height: 32,
        minHeight: 'auto',
        fontSize: '14px',
        fontWeight: 700,
        textTransform: 'none',
        margin: '5px',
        '&:hover': {
          transform: 'scale(1.03)',
        },
        '&.Mui-selected': {
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          fontSize: '12px',
          padding: `${theme.spacing(1)}`,
          fontWeight: 500,
          height: 'auto',
          width: 'auto',
        },
      }}
      {...props}
    />
  );
}
