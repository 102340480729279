import { TimeSlot } from 'api/services/auth/types';
import { IAvailableTimeSlot } from 'api/services/organization/types';
import { SelectedDateTime } from './BookingForm';

export const convertSlotDataToStringValue = (
  availableSlot: IAvailableTimeSlot,
  slot: TimeSlot,
) => `${slot.start},${availableSlot.visitTime.id}`;

export const getSlotDataObjectFromStringValue = (
  value: string,
): SelectedDateTime => {
  const values = value.split(',');
  return {
    dateTime: values[0],
    visitTimeId: values[1],
  };
};
