import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import BasicTable from 'components/table/BasicTable';
import { ConfirmationModal } from 'components/modal';
import { useTableState } from 'hooks/useTableState';
import { UnscheduledParticipant } from 'api/services/auth/types';
import { Pagination } from 'components/common/pagination';
import { cells } from './cells';
import { ParticipantsSectionTitle } from '../../components';
import { DeferringParticipantFromTestingModal } from '../../../../../components/modal/DeferringParticipantFromTestingModal';

interface VisitTimesProps {
  tableState: ReturnType<typeof useTableState<UnscheduledParticipant>>;
  toggleDeferred: (
    participantId: number,
    isDeferring: boolean,
    params?: {
      deferringReason: string;
      deferringReasonNote?: string | null;
    },
  ) => Promise<void>;
  setBookingParticipant: (participant: UnscheduledParticipant) => void;
}

export function UnscheduledParticipants({
  tableState,
  toggleDeferred,
  setBookingParticipant,
}: VisitTimesProps) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editedParticipant, setEditedParticipant] =
    useState<UnscheduledParticipant | null>(null);

  const actions = [
    {
      title: (participant: UnscheduledParticipant) =>
        participant.deferred ? 'Undeferred' : 'Deferred',
      onClick: (participant: UnscheduledParticipant) => {
        setEditedParticipant(participant);
        setConfirmationOpen(true);
      },
    },
    {
      title: 'Schedule',
      onClick: (slot: UnscheduledParticipant) => {
        setBookingParticipant(slot);
      },
    },
  ];

  return (
    <Box
      sx={{
        borderRadius: '16px',
        padding: 2,
        border: '1px solid  rgba(202, 194, 190, 0.30)',
      }}
    >
      <Grid container justifyContent="space-between" mb={3}>
        <ParticipantsSectionTitle type="unscheduled">
          Unscheduled
        </ParticipantsSectionTitle>
      </Grid>
      <BasicTable<UnscheduledParticipant>
        cells={cells}
        tableState={tableState}
        actions={actions}
        isPagination={false}
        contextContainerSxProps={{
          verticalAlign: 'top',
          padding: '4px 16px',
        }}
        contextButtonProps={{
          sx: {
            backgroundColor: 'rgba(202, 194, 190, 0.40);',
            width: '32px',
            height: '32px',
            borderRadius: '8px',
          },
        }}
        tableBodyCellSxProps={{
          padding: '4px 8px',
        }}
      />

      <Pagination
        sx={{ mt: 3 }}
        count={Math.ceil(tableState.total / tableState.rowsPerPage)}
        page={tableState.page + 1}
        onChange={(e, page) => tableState.setPage(page - 1)}
      />

      {editedParticipant && editedParticipant.deferred && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedParticipant(null);
          }}
          handleSubmit={async () => {
            await toggleDeferred(editedParticipant.id, false);
            setEditedParticipant(null);
          }}
          submitButtonTitle="Undeferred"
          title={
            <>
              {`You are about to set the ${editedParticipant.given} ${editedParticipant.family} as Not Deferred for the visit`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
      {!!editedParticipant && !editedParticipant.deferred && (
        <DeferringParticipantFromTestingModal
          opened={confirmationOpen}
          onClose={() => {
            setConfirmationOpen(false);
            setEditedParticipant(null);
            setConfirmationOpen(false);
          }}
          onSubmit={async (params: {
            deferringReason: string;
            deferringReasonNote?: string | null;
          }) => {
            await toggleDeferred(editedParticipant.id, true, params);
            setEditedParticipant(null);
            setConfirmationOpen(false);
          }}
        />
      )}
    </Box>
  );
}
