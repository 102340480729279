import { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { ShiftCalendarRow } from './components';
import {
  ISimpleTimeErrors,
  ITimeError,
  ITimeErrors,
  IWeeklySchedule,
  WeekDay,
} from './components/types';
import { TimePeriod } from './components/TimePeriod';
import { TimeErrors } from './components/TimeErrors';
import { SimpleShiftCalendar } from './SimpleShiftCalendar';

interface ShiftCalendarProps {
  schedule: IWeeklySchedule;
  setSchedule: Dispatch<SetStateAction<IWeeklySchedule>>;
  timeErrors: ITimeErrors;
  setTimeErrors: Dispatch<SetStateAction<ITimeErrors>>;
  simpleTimeErrors: ISimpleTimeErrors;
  setSimpleTimeErrors: Dispatch<SetStateAction<ISimpleTimeErrors>>;
  readOnly?: boolean;
  isAdvancedView?: boolean;
}

export function ShiftCalendar({
  schedule,
  setSchedule,
  timeErrors,
  setTimeErrors,
  simpleTimeErrors,
  setSimpleTimeErrors,
  readOnly,
  isAdvancedView,
}: ShiftCalendarProps) {
  const handleSetErrors = (
    weekDay: string,
    errors: { [key: string]: ITimeError },
    key: string | undefined = '',
  ) => {
    setTimeErrors((timeErrors) => {
      const errorsWithoutKey = _.omit(timeErrors[weekDay], key);
      return {
        ...timeErrors,
        [weekDay]: _.keys(errorsWithoutKey).reduce(
          (acc, key) => ({
            ...acc,
            [key]: errors[key] ? errors[key] : { from: '', to: '' },
          }),
          {},
        ),
      };
    });
  };

  const onTimeErrorHandler = (
    updatedTimePeriod: Partial<TimePeriod>,
    key: string,
    weekDay: string,
  ) => {
    const timePeriod = {
      ...schedule[
        weekDay as unknown as keyof IWeeklySchedule
      ].workingPeriods.find((period) => period.key === key),
      ...updatedTimePeriod,
    };
    const workingPeriods = [
      ...schedule[
        weekDay as unknown as keyof IWeeklySchedule
      ].workingPeriods.filter((period) => period.key !== key),
      timePeriod as TimePeriod,
    ];
    const errors = TimeErrors.checkWorkingPeriodsErrors(workingPeriods);
    handleSetErrors(weekDay, errors);
  };

  const handleChangeDayStatus =
    (weekDay: string) => (isWorkingDay: boolean) => {
      setSchedule((schedule) => {
        const temp = _.cloneDeep(schedule);
        temp[weekDay as unknown as keyof IWeeklySchedule].isWorkingDay =
          isWorkingDay;
        return temp;
      });
    };

  const onTimePeriodAdd = (weekDay: string) => {
    const timePeriod = new TimePeriod();
    setTimeErrors((timeErrors) => ({
      ...timeErrors,
      [weekDay]: {
        ...timeErrors[weekDay],
        [timePeriod.key]: { from: '', to: '' },
      },
    }));
    setSchedule((schedule) => {
      const temp = _.cloneDeep(schedule);
      temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods.push(
        timePeriod,
      );
      return temp;
    });
  };

  const onTimePeriodDelete = (weekDay: string) => (key: string) => {
    const errors = TimeErrors.checkWorkingPeriodsErrors(
      schedule[
        weekDay as unknown as keyof IWeeklySchedule
      ].workingPeriods.filter((period) => period.key !== key),
    );
    handleSetErrors(weekDay, errors, key);

    setSchedule((schedule) => {
      const temp = _.cloneDeep(schedule);
      const indexOfTheItemToDelete = temp[
        weekDay as unknown as keyof IWeeklySchedule
      ].workingPeriods.findIndex((period) => period.key === key);
      if (indexOfTheItemToDelete !== -1) {
        temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods.splice(
          indexOfTheItemToDelete,
          1,
        );
      }
      return temp;
    });
  };

  const onTimePeriodChange =
    (weekDay: string) =>
    (updatedTimePeriod: Partial<TimePeriod>, key: string) => {
      onTimeErrorHandler(updatedTimePeriod, key, weekDay);
      setSchedule((schedule) => {
        const temp = _.cloneDeep(schedule);
        const indexOfTheItemToUpdate = temp[
          weekDay as unknown as keyof IWeeklySchedule
        ].workingPeriods.findIndex((period) => period.key === key);

        if (indexOfTheItemToUpdate !== -1) {
          temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods[
            indexOfTheItemToUpdate
          ].from =
            updatedTimePeriod.from ||
            temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods[
              indexOfTheItemToUpdate
            ].from;

          temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods[
            indexOfTheItemToUpdate
          ].to =
            updatedTimePeriod.to ||
            temp[weekDay as unknown as keyof IWeeklySchedule].workingPeriods[
              indexOfTheItemToUpdate
            ].to;
        }
        return temp;
      });
    };

  return isAdvancedView ? (
    <div>
      {Object.keys(schedule).map((weekDay) => (
        <ShiftCalendarRow
          key={weekDay}
          dailySchedule={schedule[weekDay as unknown as keyof IWeeklySchedule]}
          timeErrors={timeErrors[weekDay]}
          title={WeekDay[Number(weekDay)]}
          onChangeDayStatus={handleChangeDayStatus(weekDay)}
          onTimePeriodAdd={() => onTimePeriodAdd(weekDay)}
          onTimePeriodDelete={onTimePeriodDelete(weekDay)}
          onTimePeriodChange={onTimePeriodChange(weekDay)}
          readOnly={readOnly}
        />
      ))}
    </div>
  ) : (
    <SimpleShiftCalendar
      schedule={schedule}
      setSchedule={setSchedule}
      simpleTimeErrors={simpleTimeErrors}
      setSimpleTimeErrors={setSimpleTimeErrors}
      readOnly={readOnly}
    />
  );
}
