import { Skeleton, TableCellProps, TableCell } from '@mui/material';

export function StyledTableCell(props: TableCellProps & { loading?: boolean }) {
  const { sx = {}, loading, children, ...otherProps } = props;
  return (
    <TableCell
      component="th"
      scope="row"
      {...otherProps}
      sx={{
        fontFamily: 'DM Mono',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '130%',
        letterSpacing: '-0.16px',
        textAlign: 'center',
        border: '1px solid #EAEAEA',
        ...sx,
      }}
    >
      {loading ? <Skeleton width="100%" /> : children}
    </TableCell>
  );
}
