import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { Button } from 'components/common/button';
import { useTableState } from 'hooks/useTableState';
import { User, UserInvitationStatuses } from 'api/services/auth/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  handleInvitationCopy,
  isEqualStatuses,
  showSnackbar,
} from 'utils';
import { PageHeader } from 'components/admin-page-layout';
import { ConfirmationModal } from 'components/modal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginAsUserRequest } from 'store/reducers/auth/actions';
import { getCells } from './cells';
import { OhcForm } from './components/ohc-form';

export function OhcsList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [formOpen, setFormOpen] = useState({
    open: false,
    isReactivation: false,
  });
  const [editedOhc, setEditedOhc] = useState<User | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const fetchOhcs = useCallback(async (limit: number, offset: number) => {
    const organizationsData = await api.user.getOhcs(limit, offset);
    return organizationsData;
  }, []);
  const tableState = useTableState<User>({ fetchDataFunction: fetchOhcs });

  const toggleActivationOhc = async () => {
    if (!editedOhc) {
      return;
    }
    try {
      await api.user.toggleActive(editedOhc.id, { active: !editedOhc.active });
      tableState.reloadData();
      showSnackbar(
        editedOhc.active
          ? t('common.OHCSuccessfullyDeactivated')
          : t('common.OHCSuccessfullyActivated'),
        {
          variant: 'success',
        },
      );
      setConfirmationOpen(false);
      setEditedOhc(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not delete OHC')),
        {
          variant: 'error',
        },
      );
    }
  };

  const actions = [
    {
      title: t('common.edit'),
      onClick: (ohc: User) => {
        setEditedOhc(ohc);
        setFormOpen({ open: true, isReactivation: false });
      },
    },
    {
      title: t('common.copyInvitationLink'),
      onClick: (ohc: User) => {
        handleInvitationCopy(ohc);
      },
      disabled: (user: User) =>
        isEqualStatuses(user.invitationStatus, UserInvitationStatuses.Active),
    },
    {
      title: t('common.sendInvitation'),
      onClick: () => {},
      disabled: true,
      // disabled: (user: User) =>
      //   isEqualStatuses(user.invitationStatus, UserInvitationStatuses.Active),
    },
    {
      title: (user: User) =>
        user.active ? t('common.deactivate') : t('common.activate'),
      onClick: (ohc: User) => {
        setEditedOhc(ohc);
        if (ohc.active) {
          return setConfirmationOpen(true);
        }
        setFormOpen({ open: true, isReactivation: true });
      },
    },
    {
      title: 'Login',
      onClick: (user: User) => {
        dispatch(loginAsUserRequest({ userId: user.id }));
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      <PageHeader
        title={t('common.OHCs')}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedOhc(null);
              setFormOpen({ open: true, isReactivation: false });
            }}
          >
            {t('common.new')}
          </Button>
        }
      />

      <BasicTable<User>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
      {formOpen.open && (
        <OhcForm
          handleClose={() =>
            setFormOpen({ open: false, isReactivation: false })
          }
          ohc={editedOhc}
          isReactivation={formOpen.isReactivation}
          handleSubmit={() => {
            if (formOpen.isReactivation) {
              toggleActivationOhc();
            }
            setFormOpen({ open: false, isReactivation: false });
            setEditedOhc(null);
            if (editedOhc) {
              console.log('>>>>>>> reloadData <<<<<<<');
              tableState.reloadData();
            } else {
              console.log('>>>>>>> refreshData <<<<<<<');
              tableState.refreshData();
            }
          }}
        />
      )}
      {editedOhc && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedOhc(null);
          }}
          handleSubmit={toggleActivationOhc}
          submitButtonTitle={editedOhc.active ? 'deactivate' : 'activate'}
          title={
            <>
              {editedOhc.active
                ? t('common.youAreAboutToDeactivateGivenFamily', {
                    given: editedOhc.given,
                    family: editedOhc.family,
                  })
                : t('common.youAreAboutToActivateGivenFamily', {
                    given: editedOhc.given,
                    family: editedOhc.family,
                  })}
              <br />
              {t('common.areYouSure')}
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
