import { Box, BoxProps } from '@mui/material';

export function ColorFilledCell(props: BoxProps) {
  const { sx, ...restProps } = props;
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(202, 194, 190, 0.40)',
        padding: '4px 8px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '130%',
        letterSpacing: '-0.16px',
        textAlign: 'center',
        fontFamily: 'PlusJakartaSans, sans-serif',
        whiteSpace: 'break-spaces',
        ...sx,
      }}
      {...restProps}
    />
  );
}
