import { useCallback, useMemo } from 'react';
import { Dialog, Grid, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SimpleButton } from 'components/common/button';
import FileDownload from 'js-file-download';
import { Loader } from 'components/common/loader';

interface PdfPreviewModalProps {
  file?: Blob | null;
  fileName: string | null;
  isLoading?: boolean;
  open: boolean;
  onClose: () => void;
  isDownload?: boolean;
}

export function PdfPreviewModal(props: PdfPreviewModalProps) {
  const { file, fileName, isLoading, open, onClose, isDownload } = props;

  const theme = useTheme();
  const fileURL = useMemo(
    () =>
      file
        ? URL.createObjectURL(new Blob([file], { type: 'application/pdf' }))
        : '',
    [file],
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const downloadFile = () => {
    if (!file || !fileName) {
      return;
    }
    FileDownload(file, fileName);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { padding: 0, maxWidth: 'none', width: 'calc(100% - 64px)' },
      }}
    >
      <Grid p={theme.spacing(3)}>
        <Grid
          item
          sx={{
            marginLeft: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" fontWeight={700}>
            {!isLoading && fileName}
          </Typography>
          <Grid>
            {isDownload && (
              <SimpleButton
                actionType="download"
                onClick={downloadFile}
                tooltipText="Download"
              />
            )}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {isLoading || !file ? (
          <Loader sx={{ height: '80vh', width: 300, margin: 'auto' }} />
        ) : (
          <embed
            style={{ width: '100%', height: '80vh', verticalAlign: 'top' }}
            src={fileURL}
          />
        )}
      </Grid>
    </Dialog>
  );
}
