import React, { useEffect, useState } from 'react';
import uuid from 'uuidv4';
import _ from 'lodash';
import * as Yup from 'yup';
import { Divider, Grid, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { Button } from 'components/common/button';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { PageHeader } from 'components/admin-page-layout';
import { api } from '../../../api';
import { IProfessionalSupervisorLicenseStateBody } from '../../../api/services/user/types';
import { IState } from '../../../api/services/state/types';
import { ProfessionalSupervisorLicenseStatesRow } from './ProfessionalSupervisorLicenseStatesRow';
import { ProfessionalSupervisorLicenseStatesFormSkeleton } from './skeleton';

interface IProfessionalSupervisorLicenseStatesFormProps {
  userId: number;
}

interface ILicenseStates {
  [key: string]: IProfessionalSupervisorLicenseStateBody & { key: string };
}

const NEW_STATE = {
  stateId: 0,
  licenseNumber: '',
  licenseExpirationDate: '',
};

export function ProfessionalSupervisorLicenseStatesForm({
  userId,
}: IProfessionalSupervisorLicenseStatesFormProps) {
  const [loading, setLoading] = useState(true);

  const [professionalSupervisorName, setProfessionalSupervisorName] =
    useState('');
  const [
    isProfessionalSupervisorNameLoading,
    setIsProfessionalSupervisorNameLoading,
  ] = useState(true);
  const [initialValues, setInitialValues] = useState<ILicenseStates>({});
  const [states, setStates] = useState<IState[]>([]);
  const [newStateKey, setNewStateKey] = useState('');
  const [validationSchema, setValidationSchema] = useState<Yup.Schema | null>(
    null,
  );

  useEffect(() => {
    const getLicenseStates = async () => {
      setIsProfessionalSupervisorNameLoading(true);
      try {
        const { items: states } = await api.state.getStates({ limit: 60 });

        setStates(states);

        const res = await api.user.getProfessionalSupervisorById(
          Number(userId),
        );
        const { given, family } = res;
        const { licenseStates } = res.professionalSupervisor || {};
        const result: ILicenseStates = {};
        if (licenseStates?.length) {
          licenseStates.forEach((state) => {
            const key = uuid();
            result[key] = {
              stateId: state.stateId,
              licenseNumber: state.licenseNumber,
              licenseExpirationDate: state.licenseExpirationDate,
              key,
            };
          });
        } else {
          const key = uuid();
          result[key] = {
            ..._.cloneDeep(NEW_STATE),
            key,
          };
        }
        setProfessionalSupervisorName(`${`${given} `}${family}`);
        setInitialValues(result);
        setIsProfessionalSupervisorNameLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getLicenseStates();
  }, []);

  const handleFormSubmit = async (values: ILicenseStates) => {
    const valuesArray = Object.values(values);
    const data = valuesArray.map((item) =>
      _.pick(item, ['licenseNumber', 'licenseExpirationDate', 'stateId']),
    );
    try {
      const res = await api.user.updateProfessionalSupervisorLicenses(userId, {
        licenseStates: data,
      });

      const result: ILicenseStates = {};
      res.professionalSupervisor?.licenseStates?.forEach((state) => {
        const key = uuid();
        result[key] = {
          stateId: state.stateId,
          licenseNumber: state.licenseNumber,
          licenseExpirationDate: state.licenseExpirationDate,
          key,
        };
      });
      setInitialValues(result);
      showSnackbar('Licenses successfully updated!', {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not update licenses'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const formik = useFormik<ILicenseStates>({
    initialValues,
    validationSchema: validationSchema || null,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        await handleFormSubmit(values);
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleAddState = () => {
    const key = uuid();
    formik.setFieldValue(key, {
      ..._.cloneDeep(NEW_STATE),
      key,
    });
    setNewStateKey(key);
  };

  useEffect(() => {
    if (!_.values(formik.values).length) {
      handleAddState();
    }
  }, [formik.values]);

  useEffect(() => {
    const schema: { [key: string]: Yup.Schema } = {};
    Object.keys(formik.values).forEach((key) => {
      schema[key] = Yup.object().shape({
        stateId: Yup.number()
          .required('* State required')
          .test(
            'unique-test',
            'States should be unique',
            (value) =>
              value > 0 &&
              !Object.keys(_.omit(formik.values, key))
                .map((id) => formik.values[id].stateId)
                .includes(value),
          ),
        licenseNumber: Yup.string()
          .required('required')
          .nullable()
          .max(50, 'limited to 50 characters'),
        licenseExpirationDate: Yup.date()
          .nullable()
          .required('required')
          .typeError('should be a valid date')
          .min(new Date(), 'your license seems expired'),
      });
    });
    setValidationSchema(Yup.object().shape(schema));
  }, [formik.values]);

  const compareStates = (
    a: IProfessionalSupervisorLicenseStateBody & { key: string },
    b: IProfessionalSupervisorLicenseStateBody & { key: string },
  ) => {
    if (a.key === newStateKey) {
      return -1;
    }
    if (b.key === newStateKey) {
      return 1;
    }
    if (!a.stateId) {
      return -1;
    }
    if (!b.stateId) {
      return 1;
    }
    if (a.stateId < b.stateId) {
      return -1;
    }
    if (a.stateId > b.stateId) {
      return 1;
    }
    return 0;
  };

  const theme = useTheme();

  return (
    <>
      <PageHeader
        entityTitle="License States"
        entityName={professionalSupervisorName}
        titleLoading={isProfessionalSupervisorNameLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddState}
          >
            New
          </Button>
        }
      />

      <Grid
        component="form"
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            formik.handleSubmit();
          }
        }}
        container
        spacing={theme.spacing(2)}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={theme.spacing(2)}
        >
          {!loading &&
            _.values(formik.values)
              .sort(compareStates)
              .map((item, index, array) => (
                <>
                  <ProfessionalSupervisorLicenseStatesRow
                    id={item.key}
                    licenseNumber={item.licenseNumber}
                    licenseExpirationDate={item.licenseExpirationDate}
                    stateId={item.stateId}
                    states={states}
                    onChangeLicenseExpirationDate={(date) =>
                      formik.setFieldValue(
                        `${item.key}.licenseExpirationDate`,
                        date,
                      )
                    }
                    onChangeLicenseNumber={(licenseNumber) =>
                      formik.setFieldValue(
                        `${item.key}.licenseNumber`,
                        licenseNumber,
                      )
                    }
                    onChangeState={(stateId) =>
                      formik.setFieldValue(`${item.key}.stateId`, stateId)
                    }
                    onDeleteRow={
                      item.key === newStateKey && array.length === 1
                        ? null
                        : () => {
                            formik.setFieldValue(item.key, undefined);
                          }
                    }
                    errors={formik.errors?.[item.key]}
                    touched={formik.touched?.[item.key]}
                  />
                  <Divider />
                </>
              ))}
          {loading && <ProfessionalSupervisorLicenseStatesFormSkeleton />}
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={theme.spacing(2)}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={formik.handleReset}
              disabled={!formik.dirty}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => formik.handleSubmit()}
              disabled={!Object.values(formik.values).length}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
