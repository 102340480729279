import { MoreVert } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Alert,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  Box,
  IconButtonProps,
  SxProps,
} from '@mui/material';
import { IRowsSorting, useTableState } from 'hooks/useTableState';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import i18next from 'i18n/config';
import { SimpleButtonProps } from 'components/common/button';
import { Cell, HeaderCell } from './types';

function LabelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return <>{i18next.t('common.fromToPagination', { from, to, count })}</>;
}

export interface Action<T> {
  title: string | ((value: T) => string);
  actionType?: SimpleButtonProps['actionType'];
  disabled?: boolean | ((item: T) => boolean);
  onClick?: (item: T) => void;
}

interface BasicTableProps<T> {
  cells: Cell<T>[];
  headerGroupingCells?: HeaderCell[];
  tableState: ReturnType<typeof useTableState<T>>;
  actions?: Action<T>[];
  onRowClicked?: (value: T) => any | Promise<any>;
  isPagination?: boolean;
  backgroundColor?: string;
  tableHeaderCellSxProps?: SxProps;
  tableBodyCellSxProps?: SxProps;
  contextButtonProps?: IconButtonProps;
  contextContainerSxProps?: SxProps;
  isHeader?: boolean;
  isRowDividers?: boolean;
  useQueryParams?: boolean;
}

interface ContextMenuProps<T> {
  actions: Action<T>[];
  item: T;
  buttonProps?: IconButtonProps;
  containerSxProps?: SxProps;
}

export function ContextMenu<T>(props: ContextMenuProps<T>) {
  const { actions, item, buttonProps, containerSxProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActionTitle = (action: Action<T>, item: T) =>
    _.isFunction(action.title) ? action.title(item) : action.title;

  return (
    <TableCell
      component="th"
      scope="row"
      width="16px"
      sx={{
        padding: '8px 16px',
        border: 'none',
        ...containerSxProps,
      }}
    >
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
        {...buttonProps}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={(e: any) => {
          e.stopPropagation();
          handleClose();
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={getActionTitle(action, item)}
            onClick={(e) => {
              e.stopPropagation();
              if (action.onClick) action.onClick(item);
              handleClose();
            }}
            disabled={
              typeof action.disabled === 'boolean'
                ? action.disabled
                : action.disabled?.(item)
            }
          >
            {getActionTitle(action, item)}
          </MenuItem>
        ))}
      </Menu>
    </TableCell>
  );
}

export default function BasicTable<T>(props: BasicTableProps<T>) {
  const {
    cells,
    tableState,
    actions,
    headerGroupingCells,
    onRowClicked,
    isPagination = true,
    backgroundColor = '#FFFFFF',
    contextButtonProps,
    tableHeaderCellSxProps,
    tableBodyCellSxProps,
    isHeader = true,
    isRowDividers,
    contextContainerSxProps,
    useQueryParams = true,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const tableKeys = cells.map((c) => ({
    key: c.key || c.name,
    render: c.render,
    align: c.align,
    width: c.width,
    maxWidth: c.maxWidth,
    verticalAlign: c.verticalAlign,
  }));

  const {
    rowsPerPage,
    setRowsPerPage,
    page,
    handlePageChange,
    total,
    data,
    loading,
    error,
    sorting,
    setSorting,
  } = tableState;

  const handleSortingClick = (c: Cell<T>) => {
    if (!c.sortable || !c.orderByValue) return;
    const newSorting: IRowsSorting = {
      orderBy: c.orderByValue,
      orderDirection: 'ASC',
    };
    if (sorting?.orderBy === c.orderByValue) {
      newSorting.orderDirection =
        sorting?.orderDirection === 'ASC' ? 'DESC' : 'ASC';
    }

    if (useQueryParams) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('orderBy', newSorting.orderBy);
      updatedSearchParams.set('orderDirection', newSorting.orderDirection);
      setSearchParams(updatedSearchParams.toString(), {
        replace: true,
      });
    } else {
      setSorting(newSorting);
    }
  };

  return (
    <Box sx={{ backgroundColor }}>
      <TableContainer component={Box} sx={{ backgroundColor }}>
        <Table sx={{ minWidth: 500 }} aria-label="table">
          {isHeader && (
            <TableHead>
              {!!headerGroupingCells?.length && (
                <TableRow>
                  {headerGroupingCells.map((c) => (
                    <TableCell
                      key={c.key}
                      align="center"
                      colSpan={c.colSpan}
                      sx={{ border: 'none' }}
                    >
                      {c.name}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              <TableRow>
                {cells.map((c) => (
                  <TableCell
                    key={c.key}
                    align={c.align || 'left'}
                    width={c.width || 'inherit'}
                    sx={{
                      border: 'none',
                      maxWidth: c.maxWidth || 'inherit',
                      ...tableHeaderCellSxProps,
                    }}
                  >
                    <TableSortLabel
                      sx={{
                        display: c.renderHeaderCell ? 'block' : 'inline-block',
                      }}
                      hideSortIcon={!c.sortable}
                      active={sorting?.orderBy === c.orderByValue}
                      direction={
                        sorting?.orderBy === c.orderByValue
                          ? (sorting?.orderDirection.toLowerCase() as
                              | 'asc'
                              | 'desc')
                          : 'asc'
                      }
                      onClick={() => handleSortingClick(c)}
                    >
                      {c.renderHeaderCell ? c.renderHeaderCell(c.name) : c.name}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {actions?.length ? (
                  <TableCell width="16px" sx={{ border: 'none' }} />
                ) : null}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, i) => (
              <TableRow
                key={row.id || i}
                sx={{
                  borderBottom: isRowDividers
                    ? '1px solid rgba(202, 194, 190, 0.30)'
                    : 'none',
                  '&:last-child, &:last-child td, &:last-child th': {
                    border: 'none',
                  },
                  ...(onRowClicked
                    ? {
                        '&:hover': {
                          backgroundColor: 'rgba(8, 22, 31, .03)',
                          cursor: 'pointer',
                        },
                      }
                    : {}),
                }}
                onClick={() => onRowClicked?.(row)}
              >
                {tableKeys.map(
                  (
                    { key, verticalAlign, align, width, maxWidth, render },
                    index,
                  ) => (
                    <TableCell
                      key={key + index}
                      align={align || 'left'}
                      width={width || 'inherit'}
                      component="th"
                      scope="row"
                      sx={{
                        border: 'none',
                        verticalAlign: verticalAlign || 'inherit',
                        maxWidth: maxWidth || 'inherit',
                        ...tableBodyCellSxProps,
                      }}
                    >
                      {(render ? render(row) : row[key]) || '-'}
                    </TableCell>
                  ),
                )}
                {actions?.length ? (
                  <ContextMenu<T>
                    actions={actions}
                    item={row}
                    buttonProps={contextButtonProps}
                    containerSxProps={contextContainerSxProps}
                  />
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          labelDisplayedRows={LabelDisplayedRows}
          labelRowsPerPage={`${t('common.rowsPerPage')}:`}
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => handlePageChange(page)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            handlePageChange(0);
          }}
          SelectProps={{ disabled: loading }}
          sx={{
            marginTop: 3,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: { xs: 8, sm: 0 },
          }}
        />
      )}
      {error ? <Alert severity="error">{error}</Alert> : null}
    </Box>
  );
}
