import React from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';

import { CoverLetterAndSummaryOfTestingForm } from 'components/forms/cover-letter-and-summary-of-testing-form';
import { PageHeader } from '../../../../components/admin-page-layout';

export function PSCreateCoverLetterAndSummaryOfTestingPage() {
  const { locationId } = useParams();
  return (
    <BasicContainer>
      <PageHeader title="New Cover Letter & Summary Of Testing" />
      <CoverLetterAndSummaryOfTestingForm locationId={Number(locationId)} />
    </BasicContainer>
  );
}
