import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { Link, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Button, FileUploadButton } from 'components/common/button';
import { Upload } from 'api/services/uploads/types';
import _ from 'lodash';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import FileDownload from 'js-file-download';
import { PageHeader } from 'components/admin-page-layout';
import axios from 'api/axios';
import { getCells } from './cells';
import { ILocation } from '../../../api/services/organization/types';

export function SiteManagerLocationUploads() {
  const { locationId } = useParams();

  const [location, setLocation] = useState<ILocation | null>(null);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const getLocationName = async () => {
      if (!locationId) {
        return;
      }
      try {
        const location = await api.organization.getLocationById(locationId);
        setLocation(location);
      } catch (e) {
        console.log(e);
      }
    };
    getLocationName();
  }, [locationId]);

  const fetchUploads = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const params = {
        limit,
        offset,
        locationId,
      };
      return api.uploads.getRosterUploads(params);
    },
    [locationId],
  );

  const downloadTemplateHandler = useCallback(async () => {
    try {
      const fileName = 'STAR_Roster_Template.csv';
      const { data } = await axios.get(`${window.location.origin}/${fileName}`);
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a template'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }, []);

  const downloadRosterHandler = useCallback(async (upload: Upload) => {
    try {
      const fileName = upload.file.name;
      const data = await api.uploads.downloadFile(upload.id);
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a roster'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }, []);

  const tableState = useTableState<Upload>({
    fetchDataFunction: fetchUploads,
  });

  const upload = async () => {
    if (!file || !locationId || !location) {
      return;
    }
    try {
      tableState.setData((prev) => {
        const data = _.cloneDeep(prev) as any;
        data.unshift({ file: { name: file.name }, status: 'Uploading' });
        return data;
      });
      tableState.handlePageChange(0);
      const body = new FormData();
      body.append('roster', file);
      body.append('locationId', locationId as string);
      body.append('organizationId', location.managingOrganization.toString());

      await api.uploads.uploadRoster(body);

      setFile(null);
      showSnackbar(`File successfully uploaded`, {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not update licenses'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      tableState.refreshData();
    }
  };

  const processUpload = async (uploadId: number) => {
    if (!uploadId) {
      return;
    }
    try {
      await api.uploads.processUpload(uploadId);
      showSnackbar(`Upload process successfully started`, {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not started upload process'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  useEffect(() => {
    if (file) {
      upload();
    }
  }, [file]);

  const actions = [
    {
      title: 'Process Upload',
      onClick: (upload: Upload) => processUpload(upload.id),
      disabled: (upload: Upload) => upload.status !== 'Unattempted',
    },
    {
      title: 'Download',
      onClick: (upload: Upload) => downloadRosterHandler(upload),
    },
  ];

  const cells = useMemo(() => getCells(), []);

  return (
    <BasicContainer>
      <PageHeader
        title={`
        Rosters Uploads
        ${!!location && ` ${location.name}`}`}
        buttons={
          <>
            <Grid item>
              <Link
                to="https://docs.google.com/spreadsheets/d/1zp4jaNJ8S0zge133eJFm62XKNrK_Cg2E/edit?usp=sharing&ouid=100540254876419919804&rtpof=true&sd=true"
                target="_blank"
              >
                <Typography>Columns Definition</Typography>
              </Link>
            </Grid>

            <Grid item>
              <Button onClick={downloadTemplateHandler}>
                Download Template
              </Button>
            </Grid>
            <Grid item>
              <FileUploadButton
                onFilesChosen={(files) => {
                  setFile(files);
                }}
                buttonProps={{
                  variant: 'contained',
                  color: 'secondary',
                }}
                inputProps={{
                  accept: '.csv',
                }}
              >
                Upload new csv
              </FileUploadButton>
            </Grid>
          </>
        }
      />

      <BasicTable<Upload>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
    </BasicContainer>
  );
}
