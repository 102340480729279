import { api } from 'api';
import {
  LoginResponse,
  User,
  UserInvitationStatuses,
  UserRoles,
  VerifiedParticipant,
} from 'api/services/auth/types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  invitationSignUpRequest,
  loginUserRequest,
  setAuth,
  setAuthLoading,
  setAuthError,
  professionalSupervisorInvitationSignUpRequest,
  hcpSupervisorInvitationSignUpRequest,
  verifyParticipantRequest,
  setMfaRequired,
  loginAsUserRequest,
  accountManagerInvitationSignUpRequest,
} from 'store/reducers/auth/actions';

import {
  AuthState,
  HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  INVITATION_SIGN_UP_REQUEST,
  LOGIN_USER_REQUEST,
  LOGIN_AS_USER_REQUEST,
  LOGOUT_USER_REQUEST,
  PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  USER_IDENTITY_REQUEST,
  VERIFY_PARTICIPANT_REQUEST,
  ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST,
} from 'store/reducers/auth/types';
import { State } from 'store/types';
import { getErrorMessage } from '../../../utils';
import { clearSiteManagerDashboardState } from '../../reducers/dashboard/site-manager/actions';

export function* loginUser(action: ReturnType<typeof loginUserRequest>) {
  try {
    yield put(setAuthLoading(true));

    const data: LoginResponse = yield call(api.auth.login, action.payload);

    yield put(
      setAuth({ ...data, mfaMethods: [], mfaRequired: false, mfaUserId: null }),
    );
  } catch (eResp: any) {
    if (eResp.response.data.errorCode === 'MFA_REQUIRED')
      yield put(
        setMfaRequired({
          mfaRequired: true,
          mfaMethods: eResp.response.data.methods,
          mfaUserId: eResp.response.data.userId,
        }),
      );
    else yield put(setAuthError(getErrorMessage(eResp)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* loginAsUser(action: ReturnType<typeof loginAsUserRequest>) {
  try {
    yield put(setAuthLoading(true));

    const data: LoginResponse = yield call(
      api.auth.loginAsUser,
      action.payload.userId,
    );

    yield put(
      setAuth({ ...data, mfaMethods: [], mfaRequired: false, mfaUserId: null }),
    );
    window.location.replace(window.location.origin);
  } catch (eResp: any) {
    if (eResp.response.data.errorCode === 'MFA_REQUIRED')
      yield put(
        setMfaRequired({
          mfaRequired: true,
          mfaMethods: eResp.response.data.methods,
          mfaUserId: eResp.response.data.userId,
        }),
      );
    else yield put(setAuthError(getErrorMessage(eResp)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* logoutUser() {
  try {
    yield put(setAuthLoading(true));

    yield put(clearSiteManagerDashboardState());
  } catch (e: any) {
    yield put(setAuthError(e?.message || 'Could not logout user'));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* invitationSignUp(
  action: ReturnType<typeof invitationSignUpRequest>,
) {
  try {
    yield put(setAuthLoading(true));

    const { token, password, acceptedTermsOfUse } = action.payload;
    const data: LoginResponse = yield call(
      api.auth.invitationSignUp,
      password,
      token,
      acceptedTermsOfUse,
    );

    yield put(setAuth(data));
  } catch (e: any) {
    yield put(
      setAuthError(
        e?.response?.data?.error || e?.message || 'Could not login user',
      ),
    );
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* professionalSupervisorInvitationSignUp(
  action: ReturnType<typeof professionalSupervisorInvitationSignUpRequest>,
) {
  try {
    yield put(setAuthLoading(true));
    const data: LoginResponse = yield call(
      api.auth.professionalSupervisorInvitationSignUp,
      action.payload,
    );

    yield put(setAuth(data));
  } catch (e: any) {
    yield put(setAuthError(getErrorMessage(e)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* accountManagerInvitationSignUp(
  action: ReturnType<typeof accountManagerInvitationSignUpRequest>,
) {
  try {
    yield put(setAuthLoading(true));
    const data: LoginResponse = yield call(
      api.auth.accountManagerInvitationSignUp,
      action.payload,
    );

    yield put(setAuth(data));
  } catch (e: any) {
    yield put(setAuthError(getErrorMessage(e)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* hcpSupervisorInvitationSignUp(
  action: ReturnType<typeof hcpSupervisorInvitationSignUpRequest>,
) {
  try {
    yield put(setAuthLoading(true));
    const data: LoginResponse = yield call(
      api.auth.hcpSupervisorInvitationSignUp,
      action.payload,
    );

    yield put(setAuth(data));
  } catch (e: any) {
    yield put(setAuthError(getErrorMessage(e)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* fetchIdentity() {
  try {
    yield put(setAuthLoading(true));
    const authState: AuthState = yield select((state: State) => state.auth);

    if (authState.user) {
      const data: User = yield call(api.user.identity);

      yield put(setAuth({ user: data }));
    }
  } catch (e: any) {
    yield put(
      setAuthError(
        e?.response?.data?.error ||
          e?.message ||
          'Could not fetch user identity',
      ),
    );
  } finally {
    yield put(setAuthLoading(false));
  }
}

export function* verifyParticipant(
  action: ReturnType<typeof verifyParticipantRequest>,
) {
  try {
    yield put(setAuthLoading(true));

    const { family, birthDate, organizationName } = action.payload;
    const data: VerifiedParticipant = yield call(
      api.auth.verifyParticipant,
      family,
      birthDate,
      organizationName,
    );
    yield put(
      setAuth({
        user: {
          ...data,
          participant: data,
          role: UserRoles.Participant,
          invitationStatus: UserInvitationStatuses.Active,
        },
        token: data.token,
      }),
    );
  } catch (e: any) {
    yield put(setAuthError(getErrorMessage(e)));
  } finally {
    yield put(setAuthLoading(false));
  }
}

export const authSagas = [
  takeLatest(LOGIN_USER_REQUEST, loginUser),
  takeLatest(LOGIN_AS_USER_REQUEST, loginAsUser),
  takeLatest(LOGOUT_USER_REQUEST, logoutUser),
  takeLatest(INVITATION_SIGN_UP_REQUEST, invitationSignUp),
  takeLatest(
    PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
    professionalSupervisorInvitationSignUp,
  ),
  takeLatest(
    ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST,
    accountManagerInvitationSignUp,
  ),
  takeLatest(
    HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
    hcpSupervisorInvitationSignUp,
  ),
  takeLatest(USER_IDENTITY_REQUEST, fetchIdentity),
  takeLatest(VERIFY_PARTICIPANT_REQUEST, verifyParticipant),
];
