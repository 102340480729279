import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useAuthSelector } from 'store/selectors/auth';
import { Header } from 'components/header';
import { FormWithBgContainer } from 'components/common/form-with-bg-container';
import { VerifyForm } from './components/verify-form';

const BGImage = '/images/backgrounds/verify/verify-bg.jpeg';
const BGImageMobile = '/images/backgrounds/verify/verify-bg-mobile.jpeg';

export function Verify() {
  const [queryParams] = useSearchParams();
  const authState = useAuthSelector();
  const navigate = useNavigate();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (authState.user) {
      const isReport = queryParams.get('report');
      const visitId = queryParams.get('visitId');

      if (isReport) {
        return navigate(`/hearing-test-results`);
      }
      if (visitId) {
        return navigate(`/booking?visitId=${visitId}`);
      }
      navigate('/questionnaire?resourceType=ParticipantsQuestionnaire');
    }
  }, [authState.user]);

  const imageUrl = useMemo(
    () => (isBelowMd ? BGImageMobile : BGImage),
    [isBelowMd],
  );

  return (
    <>
      <Header
        isEmpty
        logo={
          <img
            alt="logo"
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            width="88px"
          />
        }
      />
      <FormWithBgContainer imageUrl={imageUrl}>
        <Grid container alignItems="center">
          <VerifyForm />
        </Grid>
      </FormWithBgContainer>
    </>
  );
}
