import axios from 'axios';

import { store } from 'store';
import { logoutUserRequest } from 'store/reducers/auth/actions';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL || ''}/api/v1`,
});

instance.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  config.headers.Authorization = token ? `Bearer ${token}` : null;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      return store.dispatch(logoutUserRequest());
    }
    throw error;
  },
);

export default instance;
