import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { BasicContainer } from '../../../../components/container';
import { PageHeader } from '../../../../components/admin-page-layout';
import { useLocation } from '../../../../hooks/useLocation';
import { MetaInfoItem, Signature } from './components';
import { cells } from './cells';
import BasicTable from '../../../../components/table/BasicTable';
import { HearingTestResult } from '../../../../api/services/hearing-test-results/types';
import { EMPTY_PAGINATED_DATA } from '../../../../common/types';
import { api } from '../../../../api';
import { useTableState } from '../../../../hooks/useTableState';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { ISignedDocument } from '../../../../api/services/organization/types';
import { TextInput } from '../../../../components/common/input';

export function ProfessionalSupervisorSignReferralPage() {
  const { locationId } = useParams();
  const [queryParams] = useSearchParams();
  const { location } = useLocation();
  const dateFrom = useMemo(() => queryParams.get('dateFrom'), [queryParams]);
  const dateTo = useMemo(() => queryParams.get('dateTo'), [queryParams]);

  const fetchReferralLists = useCallback(async () => {
    if (
      !locationId ||
      !(
        dateFrom &&
        dateTo &&
        DateTime.fromISO(dateFrom).isValid &&
        DateTime.fromISO(dateTo).isValid
      )
    ) {
      return EMPTY_PAGINATED_DATA;
    }
    return api.organization.getReferralListPreviewParams(locationId, {
      dateFrom: DateTime.fromISO(dateFrom).toISO() || '',
      dateTo: DateTime.fromISO(dateTo).endOf('day').toISO() || '',
    });
  }, [locationId]);

  const tableState = useTableState<HearingTestResult>({
    fetchDataFunction: fetchReferralLists,
  });

  const [signedDocument, setSignedDocument] = useState<ISignedDocument | null>(
    null,
  );

  const documentViewingDateTime = useMemo(
    () => DateTime.now().toISO() as string,
    [],
  );

  const [reportName, setReportName] = useState('');

  useEffect(() => {
    if (dateFrom && dateTo) {
      setReportName(
        `Referral List (${DateTime.fromISO(dateFrom).toFormat(
          'MM/dd/yyyy',
        )} - ${DateTime.fromISO(dateTo).toFormat('MM/dd/yyyy')})`,
      );
    }
  }, [dateFrom, dateTo]);

  const reportNameError = useMemo(() => {
    if (!reportName) return 'required';
    if (reportName.length > 200) return 'limited to 200 characters';
    return '';
  }, [reportName]);

  const handleSign = useCallback(
    async (signature: string) => {
      if (reportNameError) return;
      try {
        const document = await api.organization.signReferralList(
          Number(locationId),
          {
            dateFrom: dateFrom as string,
            dateTo: dateTo as string,
            signature,
            documentViewingDateTime,
            name: reportName,
          },
        );
        setSignedDocument(document);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [
      locationId,
      dateFrom,
      dateTo,
      documentViewingDateTime,
      reportName,
      reportNameError,
    ],
  );

  return (
    <BasicContainer sx={{ paddingBottom: '32px' }}>
      <PageHeader
        title={`Referral list ${location && `of ${location?.name}`} ${
          dateFrom &&
          dateTo &&
          DateTime.fromISO(dateFrom).isValid &&
          DateTime.fromISO(dateTo).isValid
            ? `(${DateTime.fromISO(dateFrom).toFormat(
                'MM/dd/yyyy',
              )} - ${DateTime.fromISO(dateTo).toFormat('MM/dd/yyyy')})`
            : ''
        }`}
      />
      <TextInput
        sx={{ width: '100%', maxWidth: '350px', marginLeft: 2 }}
        size="small"
        type="text"
        label="Report Name"
        value={reportName}
        onChange={(e) => setReportName(e.target.value)}
        error={!reportName}
        helperText={reportNameError || ' '}
        disabled={!!signedDocument}
      />
      <Grid
        sx={{
          margin: { xs: '16px 4px', sm: '8px 16px' },
          padding: { xs: '8px', sm: '24px' },
          borderRadius: '8px',
          border: '1px solid black',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          gap={2}
          alignItems="center"
          sx={{
            mb: '40px',
          }}
        >
          <img
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            alt="logo"
            width="171px"
            height="64px"
          />
          <Typography
            variant="mono"
            sx={{
              fontSize: '16px',
              fontWeight: 300,
              textTransform: 'uppercase',
            }}
          >
            Summary of Testing Results
          </Typography>
        </Grid>
        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '110%',
            letterSpacing: '-1px',
            marginBottom: '32px',
          }}
        >
          Please find below
        </Typography>
        <Grid
          sx={{
            mb: '32px',
            '& > p': {
              my: '24px',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '140%',
            },
          }}
        >
          <Typography>
            Professional Review of employees who have met the following criteria
            and the coding for:
          </Typography>
          <Typography>
            Recordable Shifts (RS), Standard Threshold Shifts (STS), Non-Work
            Related Shifts (NWRS), with the effected ear defined at (L) Left,
            (R) Right, or (B) Both.
          </Typography>
          <Typography>
            It should be noted that all the above coding also indicates a
            Medical Referral recommendation to the employee. You will also see
            indications of speech frequency average, history, asymmetry, etc.
            which may not have met the above coding criteria but still results
            in Medical Referral recommendations
          </Typography>
          <Typography>
            All of the employees listed below have been individually reviewed by
            one of our Doctors of Audiology.
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid
            container
            item
            direction="column"
            sx={{ width: { xs: '100%', sm: '50%' } }}
          >
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Period"
                loading={
                  !(
                    dateFrom &&
                    dateTo &&
                    DateTime.fromISO(dateFrom).isValid &&
                    DateTime.fromISO(dateTo).isValid
                  )
                }
                value={`${DateTime.fromISO(dateFrom || '').toFormat(
                  'MM/dd/yyyy',
                )} to ${DateTime.fromISO(dateTo || '').toFormat('MM/dd/yyyy')}`}
              />
            </Grid>
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Company"
                loading={!location}
                value={location?.organization?.name || '-'}
              />
            </Grid>
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Location"
                loading={!location}
                value={location?.name || '-'}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            sx={{ width: { xs: '100%', sm: '50%' } }}
          >
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Number Listed"
                loading={!tableState.data}
                value={tableState.data?.length?.toString(10)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '110%',
            letterSpacing: '-1px',
            marginBottom: '16px',
            marginTop: '28px',
          }}
        >
          Active Employees Only
        </Typography>
        <BasicTable<HearingTestResult>
          cells={cells}
          tableState={tableState}
          isPagination={false}
        />
        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '110%',
            letterSpacing: '-1px',
            marginBottom: '16px',
            marginTop: '28px',
          }}
        >
          Reviewing Audiologist
        </Typography>
        <Signature
          onSubmit={handleSign}
          signedDocument={signedDocument}
          disabled={!tableState.data?.length || !!reportNameError}
        />
      </Grid>
    </BasicContainer>
  );
}
