import { Box, Grid } from '@mui/material';
import { VisitShift } from 'api/services/organization/types';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { cells } from './cells';
import { VisitSectionTitle } from '../../components';

interface VisitShiftsProps {
  tableState: ReturnType<typeof useTableState<VisitShift>>;
}

export function VisitShifts({ tableState }: VisitShiftsProps) {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container justifyContent="space-between" mb={3}>
        <VisitSectionTitle variant="h6">
          Location&apos;s shifts
        </VisitSectionTitle>
      </Grid>

      <BasicTable<VisitShift>
        cells={cells}
        tableState={tableState}
        isPagination={false}
        isHeader={false}
        isRowDividers
        tableBodyCellSxProps={{ padding: '16px 4px' }}
      />
    </Box>
  );
}
