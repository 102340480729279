import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import {
  Box,
  Grid,
  SelectChangeEvent,
  Skeleton,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocationMetricsTable } from 'components/location-metrics-table';
import { HearingTestResultsSummary } from 'components/hearing-test-results-summary';
import { Button } from 'components/common/button';
import { DateRangePickerModal } from 'components/modal/DateRangePickerModal';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { ILocationParticipantsHearingMetric } from 'api/services/organization/types';
import { HearingLossChart } from 'components/hearing-loss-chart';
import { useHCPSupervisorDashboardState } from '../../../../hooks/useHCPSupervisorDashboardState';
import { DashboardSelect } from '../../../../components/dashboard-select';

export function HCPSupervisorLocationMetrics() {
  const { error, setError, locations, loading, setLoading } =
    useHCPSupervisorDashboardState();

  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (error)
      showSnackbar(getErrorMessageComponent(error), {
        variant: 'error',
      });
    setError('');
  }, [error]);

  const onLocationSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const locationId = e.target.value;
      setSelectedLocationId(typeof locationId === 'number' ? locationId : null);
    },
    [locations],
  );

  useEffect(() => {
    if (!locations || loading) return;
    setSelectedLocationId(locations[0].id ? 0 : null);
  }, [locations]);

  const locationSelectOptions = useMemo(
    () =>
      Array.isArray(locations) && locations.length > 0
        ? [
            {
              label: `${locations[0].organization?.name} - aggregated metrics`,
              value: 0,
            },
          ].concat(
            locations?.map((location) => ({
              label: `${location.organization?.name} - ${location.name}`,
              value: location.id,
            })),
          )
        : [],
    [locations],
  );
  const [metrics, setMetrics] =
    useState<ILocationParticipantsHearingMetric | null>(null);
  const [dateRange, setDateRange] = useState<{
    dateFrom: DateTime;
    dateTo: DateTime;
  }>({
    dateFrom: DateTime.now().minus({ month: 12 }),
    dateTo: DateTime.now(),
  });

  const [datePickerOpened, setDatePickerOpened] = useState(false);
  useEffect(() => {
    const asyncRequest = async () => {
      if (selectedLocationId === null) {
        return EMPTY_PAGINATED_DATA;
      }
      try {
        setLoading(true);
        const metricsData =
          await api.organization.getLocationParticipantsHearingMetricsByLocationIdsList(
            selectedLocationId === 0
              ? locations.map(({ id }) => id)
              : [selectedLocationId],
            dateRange.dateFrom.toFormat('yyyy-MM-dd'),
            dateRange.dateTo.toFormat('yyyy-MM-dd'),
          );
        setMetrics(metricsData);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    asyncRequest();
  }, [selectedLocationId, dateRange]);

  const totalParticipantsTested = useMemo(
    () =>
      metrics
        ? _.values(metrics.participantsHearingLossByGender).reduce(
            (acc, item) => acc + item.total,
            0,
          )
        : 0,
    [metrics],
  );

  const theme = useTheme();

  const summaryBlocksData = useMemo(
    () => [
      {
        name: 'Employees Tested',
        value: `${totalParticipantsTested}`,
        backgroundColor: '#FFE86E',
      },
      {
        name: 'Standard Threshold Shift',
        value: `${metrics?.participantsWithSTSShiftCountByGender.total || 0}`,
        backgroundColor: '#FFF1A6',
      },
      {
        name: 'OSHA Recordable Shift',
        value: `${metrics?.participantsWithOSHAShiftCountByGender.total || 0}`,
        backgroundColor: '#D9777A',
      },
    ],
    [metrics],
  );
  return (
    <Grid>
      <Grid mb={theme.spacing(2)}>
        <DashboardSelect
          label="Location"
          labelIcon="location"
          value={selectedLocationId !== null ? selectedLocationId : ''}
          options={locationSelectOptions}
          onChange={onLocationSelect}
          loading={loading}
        />
      </Grid>
      <Grid mb={theme.spacing(2)}>
        <HearingTestResultsSummary
          items={summaryBlocksData}
          loading={loading}
          itemsWidth="auto"
          itemsHeight="auto"
          containerSx={{
            justifyContent: 'space-between',
            gap: theme.spacing(1),
          }}
          itemSx={{
            flex: 1,
            padding: theme.spacing(2),
            marginRight: theme.spacing(0.5),
            minHeight: '87px',
            '& .MuiGrid-root:nth-child(1)': {
              marginLeft: theme.spacing(1),
            },
            '& .MuiGrid-root:nth-child(2) p': {
              fontSize: '18px',
              fontWeight: 600,
            },
          }}
        />
      </Grid>
      <Grid mb={theme.spacing(3)}>
        <Button
          onClick={() => setDatePickerOpened(true)}
          endIcon={<DateRangeIcon fontSize="inherit" />}
          variant="transparent"
        >
          {dateRange.dateFrom.toFormat('MM/dd/yyyy')} -{' '}
          {dateRange.dateTo.toFormat('MM/dd/yyyy')}
        </Button>
      </Grid>
      <Grid mb={theme.spacing(7)}>
        <LocationMetricsTable metrics={metrics} />
      </Grid>
      <Grid
        pb={theme.spacing(2)}
        container
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Box
          sx={{
            background:
              'url("/images/backgrounds/audiogram-texture.png"), linear-gradient(rgba(243, 238, 236, 0.30), rgba(243, 238, 236, 0.30))',
            backgroundSize: 'cover, cover',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'top left, top left',
            px: theme.spacing(2),
            pt: theme.spacing(8),
            pb: theme.spacing(5),
            borderRadius: theme.spacing(2),
            width: { xs: '100%', md: '80%' },
            boxSizing: 'border-box',
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ maxWidth: '100% !important' }}
          >
            <Grid item xs={12} md={6} sx={{ maxWidth: '100% !important' }}>
              {metrics && !loading ? (
                <HearingLossChart metric={metrics} />
              ) : (
                <Skeleton variant="rounded" width="100%" height="300px" />
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <DateRangePickerModal
        opened={datePickerOpened}
        onClose={() => setDatePickerOpened(false)}
        onSelect={({ dateFrom, dateTo }) =>
          setDateRange({
            dateFrom: dateFrom as DateTime,
            dateTo: dateTo as DateTime,
          })
        }
        dateRange={dateRange}
      />
    </Grid>
  );
}
