import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Button } from 'components/common/button';
import { Heading } from 'components/common/heading';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Tab, TabsContainer } from 'components/tabs/dashboard-tabs/components';
import { TabPanel } from 'components/tabs/dashboard-tabs/components/TabPanel';
import { CONTENT } from './constants';

export interface IPrivacyPolicyModalProps {
  opened: boolean;
  onClose: () => void | Promise<void>;
  onAccept: () => void | Promise<void>;
  policyTypes: Array<'general' | 'privacy-policy'>;
}

export function PrivacyPolicyModal(props: IPrivacyPolicyModalProps) {
  const { opened, onClose, onAccept, policyTypes } = props;
  const [activeTab, setActiveTab] = useState(0);
  const paperRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
      paperRef.current?.scrollTo(0, 0);
    },
    [],
  );

  const tabs = useMemo(() => {
    if (!policyTypes) return null;
    if (policyTypes?.length > 1) {
      return (
        <TabsContainer
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
        >
          {policyTypes.map((type) => (
            <Tab key={type} label={CONTENT[type].title} />
          ))}
        </TabsContainer>
      );
    }
    return CONTENT[policyTypes[0]].content;
  }, [policyTypes, activeTab]);

  const buttonTitle = useMemo(() => {
    if (!policyTypes) return '';
    if (policyTypes.length > 1 && activeTab < policyTypes.length - 1) {
      return 'Continue';
    }
    return 'Accept';
  }, [activeTab, policyTypes]);

  const buttonClickHandler = useMemo(() => {
    if (!policyTypes) return null;
    if (policyTypes.length > 1 && activeTab < policyTypes.length - 1) {
      return () => {
        setActiveTab((activeTab) => activeTab + 1);
        paperRef.current?.scrollTo(0, 0);
      };
    }
    return onAccept;
  }, [activeTab, policyTypes, paperRef]);

  useEffect(() => {
    if (opened) setActiveTab(0);
  }, [opened]);

  return (
    <Dialog maxWidth="lg" open={opened} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <IconButton type="button" onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        ref={paperRef}
        sx={{
          padding: '56px',
          '@media(max-width:600px)': {
            padding: '16px',
          },
        }}
      >
        <Heading
          variant="h1"
          align="center"
          sx={{ margin: '0 auto 56px', fontWeight: 700 }}
        >
          Tuned Care Privacy Policy and Terms of Use
        </Heading>
        <Box pb={4}>{tabs}</Box>
        {policyTypes.map((type, index) => (
          <TabPanel value={activeTab} index={index}>
            {CONTENT[type].content}
          </TabPanel>
        ))}

        <Grid container justifyContent="center" pt={3}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => buttonClickHandler?.()}
          >
            {buttonTitle}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
