import { Outlet, createBrowserRouter } from 'react-router-dom';
import { Layout } from 'components/layout';
import { EReportTypeKeys } from 'api/services/organization/types';
import { Main } from './main';
import { Login } from './auth/login';
import { InvitationSignUp } from './sign-up';
import { AdminDashboard } from './admin';
import { AdminList } from './admin/admins';
import { OrganizationsList } from './admin/organizations';
import { OhcsList } from './admin/ohcs';
import { ProfessionalSupervisorsList } from './admin/professional-supervisors';
import { LicenseStates } from './admin/professional-supervisors/license-states';
import { OrganizationLocationsList } from './admin/organization-locations';
import { AdminParticipantsList } from './admin/participants';
import { ProfessionalSupervisorInvitationSignUp } from './sign-up/professional-supervisor';
import { AdminLocationShiftsList } from './admin/location-shifts';
import { OrganizationHCPSupervisors } from './admin/organization-hcp-supervisors';
import { HCPSupervisorInvitationSignUp } from './sign-up/hcp-supervisor';
import { Uploads } from './admin/uploads';
import { Records } from './admin/records';
import { OhcDashboard } from './ohc';
import { AdminOhcLocationsList } from './admin/ohc-locations';
import { OhcParticipantsList } from './ohc/ohc-participants';
import { HearingTestResultsList } from './admin/hearing-test-results';
import { SiteManagerDashboard } from './site-manager';
import { LocationVisitsList } from './admin/location-visits';
import { Visit } from './admin/visit';
import { ParticipantReportPage } from './site-manager/participant-report-page';
import { AdminParticipantReportPage } from './admin/participant-report-page';
import { Verify } from './verify';
import { Questionnaire, QuestionnaireSuccessScreen } from './questionnaire';
import { AdminProfessionalSupervisorLocationsList } from './admin/professional-supervisor-locations';
import { ProfessionalSupervisorDashboard } from './professional-supervisor';
import { ProfessionalSupervisorParticipantReportPage } from './professional-supervisor/components/participant-report-page';
import { ProfessionalSupervisorSignReferralPage } from './professional-supervisor/components/professional-supervisor-sign-referral-page';
import {
  DailyBiologicalReportsList,
  CreateDailyBiologicalReportPage,
} from './admin/daily-biological-report';
import { UpdateDailyBiologicalReportPage } from './admin/daily-biological-report/update-daily-biological-report-page';
import { ParticipantOwnHearingTestResultsPage } from './hearing-test-results/participant-report-page';
import { HCPSupervisorDashboard } from './hcp-supervisor';
import { HCPSupervisorParticipantsList } from './hcp-supervisor/components/hcp-supervisor-participants';
import { HCPSupervisorLocationVisitsList } from './hcp-supervisor/components/hcp-supervisor-location-visits';
import { HCPSupervisorParticipantReportPage } from './hcp-supervisor/components/hcp-supervisor-participant-report-page';
import {
  EquipmentSpecificationAndNoiseLevelsReportsList,
  CreateEquipmentSpecificationAndNoiseLevelsReportPage,
  UpdateEquipmentSpecificationAndNoiseLevelsReportPage,
} from './admin/equipment-specifications-and-noise-levels-report';
import { PSCreateEquipmentSpecificationAndNoiseLevelsReportPage } from './professional-supervisor/components/create-equipment-specifications-and-noise-levels-report-page';
import { PSUpdateEquipmentSpecificationAndNoiseLevelsReportPage } from './professional-supervisor/components/update-equipment-specifications-and-noise-levels-report-page';
import { ForgotPassword } from './auth/forgot-password';
import { SiteManagerLocationUploads } from './site-manager/uploads';
import { SiteManagerLocationUploadRecords } from './site-manager/upload-records';
import { PSCreateCoverLetterAndSummaryOfTestingPage } from './professional-supervisor/components/create-cover-letter-and-summary-of-testing';
import { PSUpdateCoverLetterAndSummaryOfTestingPage } from './professional-supervisor/components/update-cover-letter-and-summary-of-testing copy';
import { OrganizationReportPage } from './admin/organization-report';
import { MfaVerificationPage } from './auth/mfa-verification';
import { OhcLocationLocationVisitsList } from './ohc/ohc-location-visits';
import { OhcLocationShiftsList } from './ohc/ohc-location-shifts';
import { HCPSupervisorParticipantSignedReportsPage } from './hcp-supervisor/components/hcp-supervisor-participant-signed-reports-page';
import { SiteManagerParticipantSignedReportsPage } from './site-manager/participant-signed-reports-page';
import { AdminParticipantSignedReportsPage } from './admin/participant-signed-reports-page';
import { OhcCreateEquipmentSpecificationAndNoiseLevelsReportPage } from './ohc/ohc-location-reports';
import { OhcCreateDailyBiologicalReportPage } from './ohc/ohc-location-reports/OhcCreateDailyBiologicalReportPage';
import { OhcUpdateDailyBiologicalReportPage } from './ohc/ohc-location-reports/OhcUpdateDailyBiologicalReportPage';
import { OhcUpdateEquipmentSpecificationAndNoiseLevelsReportPage } from './ohc/ohc-location-reports/OhcUpdateEquipmentSpecificationAndNoiseLevelsReportPage';
import { LocationSiteManagersPage } from './admin/site-managers';
import { AccountManagersList } from './admin/account-managers';
import { AccountManagerOrganizationsList } from './admin/account-manager-organizations';
import { AccountManagerInvitationSignUp } from './sign-up/account-manager';
import { AccountManagerDashboard } from './account-manager';
import { AccountManagerLocationsList } from './account-manager/components/account-manager-organization-locations';
import { AccountManagerParticipantsList } from './account-manager/components/account-manager-participants';
import { ExternalClinicsList } from './admin/external-clinics';
import { AdminExternalClinicLocationsList } from './admin/external-clinic-locations';
import { Booking } from './booking';
import { ProfessionalSupervisorSignOSHAPage } from './professional-supervisor/components/professional-supervisor-sign-osha-page';
import { ProfessionalSupervisorSignSTSPage } from './professional-supervisor/components/professional-supervisor-sign-sts-page';

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        Component: Main,
      },
      {
        path: '/admin',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: AdminDashboard,
          },
          {
            path: 'admins',
            Component: AdminList,
          },
          {
            path: 'participants',
            Component: Outlet,
            children: [
              {
                path: '',
                Component: AdminParticipantsList,
              },
              {
                path: ':participantId/report',
                Component: AdminParticipantReportPage,
              },
              {
                path: ':participantId/signed-reports',
                Component: AdminParticipantSignedReportsPage,
              },
            ],
          },
          {
            path: 'organizations',
            Component: Outlet,
            children: [
              {
                path: '',
                Component: OrganizationsList,
              },
              {
                path: ':organizationId/locations',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: OrganizationLocationsList,
                  },
                  {
                    path: ':locationId',
                    Component: Outlet,
                    children: [
                      {
                        path: 'shifts',
                        Component: AdminLocationShiftsList,
                      },
                      {
                        path: 'participants',
                        Component: AdminParticipantsList,
                      },
                      {
                        path: 'professional-supervisors',
                        Component: ProfessionalSupervisorsList,
                      },
                      {
                        path: 'site-managers',
                        Component: LocationSiteManagersPage,
                      },
                      {
                        path: 'visits',
                        Component: Outlet,
                        children: [
                          {
                            path: '',
                            Component: LocationVisitsList,
                          },
                          {
                            path: ':visitId/visit',
                            Component: Visit,
                          },
                        ],
                      },
                      {
                        path: 'roster-uploads',
                        Component: Outlet,
                        children: [
                          {
                            path: '',
                            Component: Uploads,
                          },
                          {
                            path: ':uploadId/records',
                            Component: Records,
                          },
                        ],
                      },
                      {
                        path: `${EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS}`,
                        Component: Outlet,
                        children: [
                          {
                            path: '',
                            Component: DailyBiologicalReportsList,
                          },
                          {
                            path: 'create',
                            Component: CreateDailyBiologicalReportPage,
                          },
                          {
                            path: ':reportId/update',
                            Component: UpdateDailyBiologicalReportPage,
                          },
                        ],
                      },
                      {
                        path: `${EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS}`,
                        Component: Outlet,
                        children: [
                          {
                            path: '',
                            Component:
                              EquipmentSpecificationAndNoiseLevelsReportsList,
                          },
                          {
                            path: 'create',
                            Component:
                              CreateEquipmentSpecificationAndNoiseLevelsReportPage,
                          },
                          {
                            path: ':reportId/update',
                            Component:
                              UpdateEquipmentSpecificationAndNoiseLevelsReportPage,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: ':organizationId/hcp-supervisors',
                Component: OrganizationHCPSupervisors,
              },
              {
                path: ':organizationId/roster-uploads',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: Uploads,
                  },
                  {
                    path: ':uploadId/records',
                    Component: Records,
                  },
                ],
              },
              {
                path: ':organizationId/test-result-uploads',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: Uploads,
                  },
                  {
                    path: ':uploadId/records',
                    Component: Records,
                  },
                ],
              },
              {
                path: ':organizationId/hearing-test-results',
                Component: HearingTestResultsList,
              },
              {
                path: ':organizationId/reports',
                Component: OrganizationReportPage,
              },
            ],
          },
          {
            path: 'external-clinics',
            Component: Outlet,
            children: [
              {
                path: '',
                Component: ExternalClinicsList,
              },
              {
                path: ':externalClinicId/locations',
                Component: AdminExternalClinicLocationsList,
              },
            ],
          },
          {
            path: 'ohcs',
            Component: Outlet,
            children: [
              {
                path: '',
                Component: OhcsList,
              },
              {
                path: ':ohcId/locations',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: AdminOhcLocationsList,
                  },
                  {
                    path: ':locationId/participants',
                    Component: AdminParticipantsList,
                  },
                ],
              },
            ],
          },
          {
            path: 'professional-supervisors/*',
            children: [
              {
                path: '',
                Component: ProfessionalSupervisorsList,
              },
              {
                path: 'license-states',
                Component: LicenseStates,
              },
              {
                path: ':professionalSupervisorId/locations',
                Component: AdminProfessionalSupervisorLocationsList,
              },
            ],
          },
          {
            path: 'account-managers/*',
            children: [
              {
                path: '',
                Component: AccountManagersList,
              },
              {
                path: ':accountManagerId/organizations',
                Component: AccountManagerOrganizationsList,
              },
            ],
          },
        ],
      },
      {
        path: '/ohc',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: OhcDashboard,
          },
          {
            path: 'locations',
            Component: Outlet,
            children: [
              {
                path: ':locationId',
                Component: Outlet,
                children: [
                  {
                    path: 'participants',
                    Component: OhcParticipantsList,
                  },
                  {
                    path: 'visits',
                    Component: Outlet,
                    children: [
                      {
                        path: '',
                        Component: OhcLocationLocationVisitsList,
                      },
                      {
                        path: ':visitId',
                        Component: Visit,
                      },
                    ],
                  },
                  {
                    path: 'shifts',
                    Component: OhcLocationShiftsList,
                  },
                  {
                    path: `${EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS}`,
                    Component: Outlet,
                    children: [
                      {
                        path: 'create',
                        Component:
                          OhcCreateEquipmentSpecificationAndNoiseLevelsReportPage,
                      },
                      {
                        path: ':reportId/update',
                        Component:
                          OhcUpdateEquipmentSpecificationAndNoiseLevelsReportPage,
                      },
                    ],
                  },
                  {
                    path: `${EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS}`,
                    Component: Outlet,
                    children: [
                      {
                        path: 'create',
                        Component: OhcCreateDailyBiologicalReportPage,
                      },
                      {
                        path: ':reportId/update',
                        Component: OhcUpdateDailyBiologicalReportPage,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/site-manager',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: SiteManagerDashboard,
          },
          {
            path: 'participants/:participantId',
            Component: Outlet,
            children: [
              { path: '', Component: ParticipantReportPage },
              {
                path: 'signed-reports',
                Component: SiteManagerParticipantSignedReportsPage,
              },
            ],
          },
          {
            path: 'locations/:locationId/visits/:visitId',
            Component: Visit,
          },
          {
            path: 'locations/:locationId/roster-uploads',
            Component: SiteManagerLocationUploads,
          },
          {
            path: 'locations/:locationId/roster-uploads/:uploadId/records',
            Component: SiteManagerLocationUploadRecords,
          },
        ],
      },
      {
        path: '/professional-supervisor',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: ProfessionalSupervisorDashboard,
          },
          {
            path: 'participants/:participantId/report',
            Component: ProfessionalSupervisorParticipantReportPage,
          },
          {
            path: `locations/:locationId/${EReportTypeKeys.MEDICAL_REFERRAL_LISTS}/sign`,
            Component: ProfessionalSupervisorSignReferralPage,
          },
          {
            path: `locations/:locationId/${EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT}/sign`,
            Component: ProfessionalSupervisorSignOSHAPage,
          },
          {
            path: `locations/:locationId/${EReportTypeKeys.STS_REPORT}/sign`,
            Component: ProfessionalSupervisorSignSTSPage,
          },
          {
            path: `locations/:locationId/${EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS}`,
            Component: Outlet,
            children: [
              {
                path: 'create',
                Component:
                  PSCreateEquipmentSpecificationAndNoiseLevelsReportPage,
              },
              {
                path: ':reportId/update',
                Component:
                  PSUpdateEquipmentSpecificationAndNoiseLevelsReportPage,
              },
            ],
          },
          {
            path: 'locations/:locationId/cover-letter-and-summary-of-testing',
            Component: Outlet,
            children: [
              {
                path: 'create',
                Component: PSCreateCoverLetterAndSummaryOfTestingPage,
              },
              {
                path: ':coverLetterId/update',
                Component: PSUpdateCoverLetterAndSummaryOfTestingPage,
              },
            ],
          },
        ],
      },
      {
        path: '/hcp-supervisor',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: HCPSupervisorDashboard,
          },
          {
            path: ':organizationId/locations',
            Component: Outlet,
            children: [
              {
                path: ':locationId',
                Component: Outlet,
                children: [
                  {
                    path: 'site-managers',
                    Component: LocationSiteManagersPage,
                  },
                ],
              },
            ],
          },
          {
            path: 'locations/:locationId/participants',
            Component: HCPSupervisorParticipantsList,
          },
          {
            path: 'locations/:locationId/participants/:participantId',
            Component: Outlet,
            children: [
              {
                path: 'report',
                Component: HCPSupervisorParticipantReportPage,
              },
              {
                path: 'signed-reports',
                Component: HCPSupervisorParticipantSignedReportsPage,
              },
            ],
          },
          {
            path: 'locations/:locationId/visits',
            Component: HCPSupervisorLocationVisitsList,
          },
          {
            path: 'locations/:locationId/visits/:visitId',
            Component: Visit,
          },
        ],
      },
      {
        path: '/account-manager',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: AccountManagerDashboard,
          },
          {
            path: 'organizations/',
            Component: Outlet,
            children: [
              {
                path: ':organizationId/roster-uploads',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: Uploads,
                  },
                  {
                    path: ':uploadId/records',
                    Component: Records,
                  },
                ],
              },
              {
                path: ':organizationId/reports',
                Component: OrganizationReportPage,
              },
              {
                path: ':organizationId/locations',
                Component: Outlet,
                children: [
                  {
                    path: '',
                    Component: AccountManagerLocationsList,
                  },
                  {
                    path: ':locationId/participants',
                    Component: AccountManagerParticipantsList,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/login',
        Component: Login,
      },
      {
        path: '/mfa-verification',
        Component: MfaVerificationPage,
      },
      {
        path: '/forgot-password',
        Component: ForgotPassword,
      },
      {
        path: '/sign-up',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: InvitationSignUp,
          },
          {
            path: 'professional-supervisor',
            Component: ProfessionalSupervisorInvitationSignUp,
          },
          {
            path: 'hcp-supervisor',
            Component: HCPSupervisorInvitationSignUp,
          },
          {
            path: 'account-manager',
            Component: AccountManagerInvitationSignUp,
          },
        ],
      },
      {
        path: '/verify/:organizationName',
        Component: Verify,
      },
      {
        path: '/questionnaire',
        Component: Outlet,
        children: [
          {
            path: '',
            Component: Questionnaire,
          },
          {
            path: '/questionnaire/success',
            Component: QuestionnaireSuccessScreen,
          },
        ],
      },
      {
        path: '/booking',
        Component: Booking,
      },
      {
        path: '/hearing-test-results',
        Component: ParticipantOwnHearingTestResultsPage,
      },
    ],
  },
]);
