import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useTableState } from 'hooks/useTableState';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { SearchBar } from 'components/table/components';
import { TableFilter } from 'components/table-filter';
import { Button } from 'components/common/button';
import { Organization } from 'api/services/organization/types';
import { ParticipantForm } from 'components/forms/participant-form';
import { PageHeader } from 'components/admin-page-layout';
import { getCells } from './cells';
import { getFilterConfig } from './filter.config';
import { useLocation } from '../../../hooks/useLocation';

export function OhcParticipantsList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { locationId } = useParams();
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleLoading, setPageTitleLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [formOpen, setFormOpen] = useState(false);

  const getLocation = async () => {
    if (!locationId) {
      return;
    }
    setPageTitleLoading(true);
    try {
      const { organization, name } = await api.organization.getLocationById(
        locationId,
      );
      setPageTitle(`${name} of ${organization?.name || ''}`);
      setOrganization(organization);
      setPageTitleLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLocation();
  }, [locationId]);

  const fetchParticipants = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy?: string,
      orderDirection?: 'ASC' | 'DESC',
      search?: string,
      filterParams?: any,
    ) => {
      if (!locationId) return EMPTY_PAGINATED_DATA;
      const participantsData =
        await api.organization.getParticipantsByLocationId(locationId, {
          limit,
          offset,
          orderBy: orderBy || null,
          orderDirection: orderDirection || null,
          search: search || null,
          filterParams,
        });

      return participantsData;
    },
    [locationId],
  );

  const tableState =
    useTableState<ParticipantWithLastHearingTestResultViewItem>({
      fetchDataFunction: fetchParticipants,
    });

  const filterConfig = useMemo(() => {
    if (locationId) {
      return getFilterConfig({ locationId });
    }
    return [];
  }, [locationId]);

  const { location } = useLocation();

  const cells = useMemo(() => getCells(location), [location]);

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Participants"
        entityName={pageTitle}
        titleLoading={pageTitleLoading}
        buttons={
          <>
            <Button
              sx={{ mr: theme.spacing(1.5) }}
              onClick={() => navigate(`/ohc/locations/${locationId}/visits`)}
            >
              Visits
            </Button>
            <Button
              sx={{ mr: theme.spacing(1.5) }}
              onClick={() => setFormOpen(true)}
            >
              Add Participant
            </Button>
            <SearchBar
              value={tableState.search}
              onChange={tableState.handleSearchTyping}
              inputProps={{
                placeholder: 'Search...',
              }}
            />
          </>
        }
      />

      <TableFilter withoutContainerPadding config={filterConfig} />
      <BasicTable<ParticipantWithLastHearingTestResultViewItem>
        cells={cells}
        tableState={tableState}
      />
      {formOpen && locationId && organization && (
        <ParticipantForm
          locationId={Number(locationId)}
          organizationId={organization.id}
          handleClose={() => {
            setFormOpen(false);
          }}
          handleSubmit={() => {
            setFormOpen(false);
            tableState.refreshData();
          }}
        />
      )}
    </BasicContainer>
  );
}
