import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { ILocation, Organization } from 'api/services/organization/types';
import _ from 'lodash';
import { User } from 'api/services/auth/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';
import { getPhoneSchema } from 'utils/validation/validators';

interface SiteManagerFormProps {
  defaultValues?: { locations?: ILocation[]; organization?: Organization };
  siteManager?: User | null;
  handleClose: () => void;
  handleSubmit: () => void;
}

const EMPTY_VALUES = {
  given: '',
  family: '',
  email: '',
  middleName: '',
  phone: '',
  externalId: '',
  organization: null,
  locations: [],
};

export function SiteManagerForm(props: SiteManagerFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { siteManager, defaultValues, handleSubmit, handleClose } = props;
  const { t, i18n } = useTranslation();

  const OrganizationFormSchema = useMemo(
    () =>
      Yup.object().shape({
        given: Yup.string().required(t('validation.required')),
        family: Yup.string().required(t('validation.required')),
        email: Yup.string()
          .email(t('validation.invalidEmail'))
          .required(t('validation.required')),
        phone: getPhoneSchema().optional(),
        externalId: Yup.string().optional(),
        organization: Yup.object().required(t('validation.required')),
        locations: Yup.array().min(1, t('validation.required')),
      }),
    [i18n.language],
  );

  const initialValues = useMemo(() => {
    if (siteManager)
      return {
        given: siteManager.given,
        family: siteManager.family,
        email: siteManager.email,
        middleName: siteManager.middleName,
        phone: convertPhoneFormFieldToTenDigitFormat(siteManager.phone),
        externalId: siteManager.externalId,
        organization: siteManager.siteManager?.organization
          ? {
              value: siteManager.siteManager?.organization.id,
              label: siteManager.siteManager?.organization.name,
            }
          : null,
        locations:
          siteManager.siteManager?.locations?.map((item) => ({
            value: item.id,
            label: item.name,
          })) || [],
      };

    if (defaultValues)
      return {
        ...EMPTY_VALUES,
        organization: defaultValues?.organization
          ? {
              value: defaultValues.organization.id,
              label: defaultValues.organization.name,
            }
          : null,
        locations:
          defaultValues?.locations?.map((item) => ({
            value: item.id,
            label: item.name,
          })) || [],
      };

    return EMPTY_VALUES;
  }, [siteManager, defaultValues]);

  const onSubmit = async (values: typeof initialValues) => {
    const body = {
      ..._.omit(values, ['organization', 'locations']),
      phone: convertPhoneFormFieldToInternationalFormat(values.phone),
      organizationId: values.organization?.value,
      locations: values.locations.map((item) => item.value),
    };
    try {
      setLoading(true);
      if (siteManager) await api.user.updateSiteManager(siteManager.id, body);
      else await api.user.createSiteManager(body);
      handleSubmit();
      showSnackbar(
        siteManager
          ? t('common.siteManagerSuccessfullyUpdated')
          : t('common.siteManagerSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || t('common.couldNotSaveForm'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OrganizationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            siteManager
              ? t('common.editSiteManager')
              : t('common.createSiteManager')
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.firstName')}
                    variant="outlined"
                    type="text"
                    name="given"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.given}
                    error={touched.given && Boolean(errors.given)}
                    helperText={
                      touched.given && errors.given ? errors.given : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.middleName')}
                    variant="outlined"
                    type="text"
                    name="middleName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={
                      touched.middleName && errors.middleName
                        ? errors.middleName
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.lastName')}
                    variant="outlined"
                    type="text"
                    name="family"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.family}
                    error={touched.family && Boolean(errors.family)}
                    helperText={
                      touched.family && errors.family ? errors.family : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.email')}
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.externalId')}
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutocomplete<Organization>
                    fullWidth
                    label={t('common.organization')}
                    fetchMethod={(params) =>
                      api.organization.getOrganizations(params)
                    }
                    mapOptionMethod={(item: Organization) => ({
                      value: item.id,
                      label: item.name,
                    })}
                    onChange={(event: any, newValue: any) => {
                      setFieldValue('organization', newValue);
                      setFieldValue('locations', []);
                    }}
                    value={values.organization}
                    onBlur={handleBlur}
                    error={touched.organization && Boolean(errors.organization)}
                    helperText={
                      touched.organization && errors.organization
                        ? errors.organization
                        : ' '
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutocomplete<ILocation>
                    fullWidth
                    multiple
                    label={t('common.locations')}
                    fetchMethod={(params) =>
                      api.organization.getLocations(
                        values.organization?.value || '',
                        params,
                      )
                    }
                    mapOptionMethod={(item: ILocation) => ({
                      value: item.id,
                      label: item.name,
                    })}
                    onChange={(event: any, newValue: any) => {
                      setFieldValue('locations', newValue);
                    }}
                    value={values.locations}
                    onBlur={handleBlur}
                    getOptionDisabled={(option) =>
                      !!values.locations.find(
                        (item) => item.value === option.value,
                      )
                    }
                    disabled={
                      !siteManager?.siteManager?.locations &&
                      !!defaultValues?.locations
                    }
                    error={touched.locations && Boolean(errors.locations)}
                    helperText={
                      !!touched.locations && !!errors.locations
                        ? (errors.locations as string)
                        : ' '
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {error ? (
                    <FormAlert severity="error">{error}</FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
