import { ButtonProps, IconButton, Tooltip, TooltipProps } from '@mui/material';
import { useMemo } from 'react';
import {
  Add as AddIcon,
  ArrowBack,
  FilterList,
  ArrowForward,
  HelpOutline,
} from '@mui/icons-material';
import EditIcon from 'custom-icons/EditIcon';
import DownloadIcon from 'custom-icons/DownloadIcon';
import TrashIcon from 'custom-icons/TrashIcon';
import EyeIcon from 'custom-icons/EyeIcon';

export interface SimpleButtonProps extends ButtonProps {
  actionType:
    | 'add'
    | 'delete'
    | 'back'
    | 'forward'
    | 'filter'
    | 'edit'
    | 'download'
    | 'help'
    | 'preview';
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
}
export function SimpleButton(props: SimpleButtonProps) {
  const {
    actionType,
    fontSize = 'inherit',
    tooltipText,
    tooltipPlacement,
    ...restProps
  } = props;

  const getFontSize = (fontSize: 'inherit' | 'large' | 'medium' | 'small') => {
    if (fontSize === 'small') {
      return '16';
    }
    return '24';
  };

  const iconComponent = useMemo(() => {
    if (actionType === 'add') return <AddIcon fontSize={fontSize} />;
    if (actionType === 'delete')
      return <TrashIcon fontSize={getFontSize(fontSize)} />;
    if (actionType === 'back') return <ArrowBack fontSize={fontSize} />;
    if (actionType === 'forward') return <ArrowForward fontSize={fontSize} />;
    if (actionType === 'filter') return <FilterList fontSize={fontSize} />;
    if (actionType === 'edit')
      return <EditIcon fontSize={getFontSize(fontSize)} />;
    if (actionType === 'download')
      return <DownloadIcon fontSize={getFontSize(fontSize)} />;
    if (actionType === 'help') return <HelpOutline fontSize={fontSize} />;
    if (actionType === 'preview')
      return <EyeIcon fontSize={getFontSize(fontSize)} />;
    return null;
  }, [actionType]);

  const color = useMemo(() => {
    if (actionType === 'delete') return 'error';
    return 'primary';
  }, [actionType]);
  return tooltipText ? (
    <Tooltip title={tooltipText} placement={tooltipPlacement ?? 'left'}>
      <IconButton color={color} {...restProps}>
        {iconComponent}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton color={color} {...restProps}>
      {iconComponent}
    </IconButton>
  );
}
