import { ILocation } from 'api/services/organization/types';
import { LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';

export const getCells = (ohcId: string): Cell<ILocation>[] => [
  {
    name: 'ID',
    key: 'id',
    sortable: true,
    orderByValue: 'id',
  },
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    orderByValue: 'name',
  },
  {
    name: 'External Id',
    key: 'externalId',
  },
  {
    name: 'Organization',
    key: 'organization',
    render: (item: ILocation) => item.organization?.name || '-',
    sortable: true,
    orderByValue: 'organization',
  },
  {
    name: 'Address',
    key: 'line1',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'State',
    key: 'state',
  },
  {
    name: 'Participants',
    key: 'participantsCount',
    render: (item: ILocation) => (
      <LinkCell
        to={`/admin/ohcs/${ohcId}/locations/${item.id}/participants`}
        label={item.participantsCount || '0'}
      />
    ),
  },
];
