import { Box, Grid, Typography } from '@mui/material';
import { Heading } from 'components/common/heading';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../button';

interface ErrorScreenProps {
  title?: string;
  errorMessage?: ReactNode;
  buttons?: ReactNode;
  action?: () => void;
  buttonTitle?: string;
}

const DEFAULT_TITLE = 'Oops! An error occurred while processing your request.';

export function ErrorScreen(props: ErrorScreenProps) {
  const {
    title = DEFAULT_TITLE,
    errorMessage,
    buttons,
    buttonTitle = 'Refresh',
    action,
  } = props;
  const navigate = useNavigate();
  const refreshPage = () => navigate(0);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      gap={1}
      sx={{
        height: '80vh',
      }}
    >
      <Heading variant="subtitle2" align="center">
        {title}
      </Heading>

      {errorMessage && (
        <Box>
          <Heading variant="subtitle2" component="span">
            Reason:{' '}
          </Heading>

          {typeof errorMessage === 'string' ? (
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ fontStyle: 'italic' }}
            >
              {errorMessage}
            </Typography>
          ) : (
            <Box>{errorMessage}</Box>
          )}
        </Box>
      )}

      <Grid pt={2}>
        {buttons ? (
          <Grid container justifyContent="center" alignItems="center">
            {buttons}
          </Grid>
        ) : (
          <Button
            onClick={() => (action ? action() : refreshPage())}
            variant="contained"
            color="secondary"
          >
            {buttonTitle}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
