import {
  EHistoricalDocumentTypes,
  EReportTypeKeys,
  ESignedDocumentTypes,
  IHistoricalDocument,
  ISignedDocument,
} from 'api/services/organization/types';
import { DateFormats } from 'common/constants/date';

import { DateTime } from 'luxon';

export const getReportFileNameBySignedDocument = (
  document: ISignedDocument | IHistoricalDocument,
) => {
  const date = DateTime.fromISO(document.created_at).toFormat(
    DateFormats.FILE_DATE_FORMAT,
  );
  let reportName = '';
  if (document.type === ESignedDocumentTypes.ReferralList) {
    reportName = 'referralslist';
  }
  if (document.type === ESignedDocumentTypes.DailyBiological) {
    reportName = 'dailybiological';
  }
  if (document.type === ESignedDocumentTypes.OSHARecordableShiftReport) {
    reportName = 'OSHA Recordable STS';
  }
  if (document.type === ESignedDocumentTypes.STSReport) {
    reportName = 'STS';
  }
  if (document.type === ESignedDocumentTypes.EquipmentAndNoiseReport) {
    reportName = 'equipmentspec_noiselevel';
  }
  if (document.type === EHistoricalDocumentTypes.Other) {
    reportName = 'other';
  }
  return `${reportName}_${date}.pdf`;
};

export const getReportFileNameByReportTypeKey = (
  type: Omit<
    EReportTypeKeys,
    EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
  >,
  documentDate: string,
) => {
  const date = DateTime.fromISO(documentDate).toFormat(
    DateFormats.FILE_DATE_FORMAT,
  );

  let reportName = '';
  if (type === EReportTypeKeys.MEDICAL_REFERRAL_LISTS) {
    reportName = 'referralslist';
  }
  if (type === EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS) {
    reportName = 'dailybiological';
  }
  if (type === EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT) {
    reportName = 'OSHA Recordable STS';
  }
  if (type === EReportTypeKeys.STS_REPORT) {
    reportName = 'STS';
  }
  if (
    type === EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
  ) {
    reportName = 'equipmentspec_noiselevel';
  }

  return `${reportName}_${date}.pdf`;
};

export const getReportId = (signedDocument: ISignedDocument) => {
  let reportId;
  if (signedDocument.type === ESignedDocumentTypes.ReferralList) {
    reportId = signedDocument.referralListId;
  }
  if (signedDocument.type === ESignedDocumentTypes.DailyBiological) {
    reportId = signedDocument.dailyBiologicalReportId;
  }
  if (signedDocument.type === ESignedDocumentTypes.EquipmentAndNoiseReport) {
    reportId = signedDocument.equipmentSpecificationsAndNoiseLevelsReportId;
  }
  return reportId;
};
