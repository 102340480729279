import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { Checkbox as CheckboxComponent, CheckboxProps } from '@mui/material';

interface ICheckboxProps extends CheckboxProps {
  fontSize?: 'small' | 'medium' | 'inherit' | 'large';
}

export function Checkbox(props: ICheckboxProps) {
  const { fontSize = 'small', ...restProps } = props;
  return (
    <CheckboxComponent
      checkedIcon={<CheckCircle htmlColor="#6DBDBF" fontSize={fontSize} />}
      icon={<RadioButtonUnchecked htmlColor="#08161F" fontSize={fontSize} />}
      {...restProps}
    />
  );
}
