import { PaginatedData } from 'common/types';
import { PaginatedRequestParams } from 'api/types';
import axios from '../../axios';
import { User } from '../auth/types';
import { AdminBody, ChangeAdminStatusBody } from './types';

export class AdminAPI {
  async getAdmins({
    limit = 10,
    offset = 0,
    orderBy,
    orderDirection,
    search,
    filterParams,
  }: PaginatedRequestParams) {
    const { data } = await axios.get(`/admin`, {
      params: {
        limit,
        offset,
        orderBy,
        orderDirection,
        search,
        ...filterParams,
      },
    });

    return data as PaginatedData<User>;
  }

  async createAdmin(body: AdminBody) {
    const { data } = await axios.post('/admin', body);

    return data as User;
  }

  async updateAdmin(id: number, body: AdminBody) {
    const { data } = await axios.put(`/admin/${id}`, body);

    return data as User;
  }

  async changeAdminStatus(id: number, body: ChangeAdminStatusBody) {
    const { data } = await axios.patch<User>(`/admin/${id}/status`, body);

    return data;
  }
}
