import { ListCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import { Typography } from '@mui/material';
import { ShiftNameCell } from 'components/table/components/ShiftNameCell';
import { UnscheduledParticipant } from 'api/services/auth/types';
import { Close } from '@mui/icons-material';
import { Tooltip } from 'components/common/tooltip';
import { getUserFullNameString } from 'utils/getUserFullNameString';

export const cells: Cell<UnscheduledParticipant>[] = [
  {
    name: 'Employee',
    key: 'participant',
    verticalAlign: 'top',
    render: (item: UnscheduledParticipant) => (
      <Tooltip title={getUserFullNameString(item)}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '130%',
            letterSpacing: '-0.16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 200,
            maxWidth: 200,
            whiteSpace: 'nowrap',
          }}
        >
          {getUserFullNameString(item)}
        </Typography>
      </Tooltip>
    ),
  },
  {
    name: 'Shift',
    key: 'shift',
    verticalAlign: 'top',
    render: (item: UnscheduledParticipant) => (
      <ListCell
        items={item.shifts.map((shift) => (
          <ShiftNameCell name={shift.name} shiftId={shift.id} />
        ))}
      />
    ),
  },
  {
    name: '',
    key: 'excluded',
    verticalAlign: 'top',
    render: (item: UnscheduledParticipant) =>
      item.deferred ? (
        <Tooltip
          title={
            <p>
              <p>Deferred</p>
              <p>{`Reason: ${item.deferringReason}`}</p>
              {item.deferringReasonNote ? (
                <p>{`Note: ${item.deferringReasonNote}`}</p>
              ) : (
                ''
              )}
            </p>
          }
        >
          <Close fontSize="small" htmlColor="#B76060" />
        </Tooltip>
      ) : (
        ' '
      ),
    width: '30px',
  },
];
