import { api } from 'api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { State } from 'store/types';
import { getErrorMessage } from '../../../../utils';
import {
  requestSiteManagerLocationsAction,
  setSiteManagerLocations,
  setSiteManagerLocationsError,
  setSiteManagerLocationsLoading,
  setSiteManagerSelectedLocation,
} from '../../../reducers/dashboard/site-manager/actions';
import { ILocation } from '../../../../api/services/organization/types';
import { PaginatedData } from '../../../../common/types';
import { REQUEST_SITE_MANAGER_LOCATIONS } from '../../../reducers/dashboard/site-manager/types';

export function* requestSiteManagerLocations(
  action: ReturnType<typeof requestSiteManagerLocationsAction>,
) {
  try {
    yield put(setSiteManagerLocationsLoading(true));

    const userId = action.payload;
    const data: PaginatedData<ILocation> = yield call(
      api.user.getSiteManagerLocations,
      userId,
      { limit: 100, offset: 0 },
    );
    yield put(setSiteManagerLocations(data.items));
    const siteManagerSelectedLocation: ILocation = yield select(
      (state: State) => state.dashboard.siteManager.selectedLocation,
    );
    if (!siteManagerSelectedLocation) {
      yield put(setSiteManagerSelectedLocation(data.items[0]));
    }
  } catch (e: any) {
    yield put(setSiteManagerLocationsError(getErrorMessage(e)));
  } finally {
    yield put(setSiteManagerLocationsLoading(false));
  }
}

export const siteManagerDashboardSagas = [
  takeLatest(REQUEST_SITE_MANAGER_LOCATIONS, requestSiteManagerLocations),
];
