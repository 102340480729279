import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { User } from 'api/services/auth/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { FormModal } from 'components/modal';
import { TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';

interface OhcsFormProps {
  ohc?: User | null;
  isReactivation: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const OhcsFormSchema = Yup.object().shape({
  given: Yup.string().required('Required'),
  family: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const EMPTY_VALUES = {
  given: '',
  family: '',
  email: '',
  middleName: '',
  phone: '',
  externalId: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  accreditation: false,
};

export function OhcForm(props: OhcsFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const { ohc, isReactivation, handleSubmit, handleClose } = props;

  const title = useMemo(() => {
    if (ohc) {
      return isReactivation ? 'Reactivate OHC' : 'Edit OHC';
    }
    return 'Create OHC';
  }, [ohc, isReactivation]);

  const initialValues = ohc
    ? {
        given: ohc.given,
        family: ohc.family,
        email: isReactivation ? '' : ohc.email,
        middleName: ohc.middleName,
        phone: convertPhoneFormFieldToTenDigitFormat(ohc.phone),
        externalId: ohc.externalId,
        line1: ohc.ohc?.line1 || '',
        line2: ohc.ohc?.line2 || '',
        city: ohc.ohc?.city || '',
        state: ohc.ohc?.state || '',
        postalCode: ohc.ohc?.postalCode || '',
        country: ohc.ohc?.country || '',
        accreditation: isReactivation ? false : !!ohc.ohc?.accreditation,
      }
    : EMPTY_VALUES;

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);
      if (ohc)
        await api.user.updateOhc(ohc.id, {
          ...values,
          phone: convertPhoneFormFieldToInternationalFormat(values.phone),
        });
      else await api.user.createOhc(values);
      handleSubmit();
      showSnackbar(`OHC successfully ${ohc ? 'updated' : 'added'}`, {
        variant: 'success',
      });
    } catch (e: any) {
      setError(getErrorMessage(e, 'Could not save form'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OhcsFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={title}
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    type="text"
                    name="given"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.given}
                    error={touched.given && Boolean(errors.given)}
                    helperText={
                      touched.given && errors.given ? errors.given : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Middle Name"
                    variant="outlined"
                    type="text"
                    name="middleName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={
                      touched.middleName && errors.middleName
                        ? errors.middleName
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    name="family"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.family}
                    error={touched.family && Boolean(errors.family)}
                    helperText={
                      touched.family && errors.family ? errors.family : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="External Id"
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Address Line 1"
                    variant="outlined"
                    type="text"
                    name="line1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.line1}
                    error={touched.line1 && Boolean(errors.line1)}
                    helperText={
                      touched.line1 && errors.line1 ? errors.line1 : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Address Line 2"
                    variant="outlined"
                    type="text"
                    name="line2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.line2}
                    error={touched.line2 && Boolean(errors.line2)}
                    helperText={
                      touched.line2 && errors.line2 ? errors.line2 : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="City"
                    variant="outlined"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city ? errors.city : ' '}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="State"
                    variant="outlined"
                    type="text"
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    error={touched.state && Boolean(errors.state)}
                    helperText={
                      touched.state && errors.state ? errors.state : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Country"
                    variant="outlined"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={touched.country && Boolean(errors.country)}
                    helperText={
                      touched.country && errors.country ? errors.country : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Postal Code"
                    variant="outlined"
                    type="text"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    error={touched.postalCode && Boolean(errors.postalCode)}
                    helperText={
                      touched.postalCode && errors.postalCode
                        ? errors.postalCode
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="accreditation"
                      onChange={handleChange}
                      checked={values.accreditation}
                    />
                  }
                  label="Accreditation"
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
