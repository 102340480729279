import { Grid, Typography, useTheme } from '@mui/material';

export function VerificationHeaderText() {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Typography
        textAlign="center"
        sx={{
          marginBottom: theme.spacing(2),
          fontSize: theme.spacing(3),
        }}
      >
        Let&apos;s setup verification methods
      </Typography>
    </Grid>
  );
}
