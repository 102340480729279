import * as React from 'react';
import { AutocompleteProps, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedData } from 'common/types';
import { IOption } from 'common/types/common';
import { TextInput } from '../input';

interface AsyncSelectProps<T> {
  fetchMethod: () => Promise<PaginatedData<T>>;
  mapOptionMethod: (item: any) => IOption;
  label: string;
  error?: boolean;
  withHelperText?: boolean;
  helperText?: string;
  value: string | string[] | null;
}

export function AsyncSelect<T>({
  fetchMethod,
  mapOptionMethod,
  label,
  error,
  withHelperText = true,
  helperText = ' ',
  value,
  ...props
}: AsyncSelectProps<T> &
  Partial<
    AutocompleteProps<
      IOption,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >
  >) {
  const [open, setOpen] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);

  const fetchOptions = async () => {
    setAutocompleteLoading(true);
    try {
      const organizationsData = await fetchMethod();
      setOptions(organizationsData.items.map(mapOptionMethod));
    } catch (e) {
      console.log(e);
    } finally {
      setAutocompleteLoading(false);
    }
  };

  useEffect(() => {
    if (!options.length && (open || !!value?.length)) {
      fetchOptions();
    }
  }, [open, value]);

  return (
    <Autocomplete
      {...props}
      value={options.filter((option) => {
        if (!option.value) {
          return false;
        }
        return value?.includes(String(option.value));
      })}
      loading={autocompleteLoading}
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          variant="outlined"
          type="text"
          error={error}
          helperText={withHelperText ? helperText : ''}
        />
      )}
      inputValue=""
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
    />
  );
}
