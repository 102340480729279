import { PaginationProps, styled, Grid, Box } from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Button, SimpleButton } from '../button';

const StyledButton = styled(Button)(() => ({
  width: 28,
  minWidth: 'unset',
  height: 28,
  borderRadius: '50%',
  fontSize: '13px',
  fontWeight: 700,
  padding: '6px 0',
  margin: '0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export function Pagination(props: PaginationProps) {
  const { count, onChange, page, sx } = props;
  const { items } = usePagination({
    count,
    onChange,
    page,
  });

  return (
    <Grid container justifyContent="center" alignItems="center" sx={sx}>
      <SimpleButton
        actionType="back"
        fontSize="small"
        disabled={items[0].disabled}
        onClick={items[0].onClick}
      />
      <Box
        sx={{
          backgroundColor: 'rgba(202, 194, 190, 0.30)',
          borderRadius: 16,
        }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          {items.map(({ page, type, selected, ...item }, index) => {
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              return (
                <Box
                  sx={{
                    height: 10,
                    fontSize: '13px',
                    fontWeight: 700,
                  }}
                >
                  …
                </Box>
              );
            }
            if (type === 'page') {
              return (
                <StyledButton
                  key={index}
                  sx={{
                    backgroundColor: selected
                      ? '#FFFFFF'
                      : 'rgba(202, 194, 190, 0.30)',
                  }}
                  {...item}
                >
                  {page}
                </StyledButton>
              );
            }
            return null;
          })}
        </Grid>
      </Box>
      <SimpleButton
        actionType="forward"
        fontSize="small"
        disabled={items[items.length - 1].disabled}
        onClick={items[items.length - 1].onClick}
      />
    </Grid>
  );
}
