import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import { ReportStatusCell } from 'components/table/components/ReportStatusCell';
import { STSShiftCell } from 'components/table/components/STSShiftCell';
import { YesNoCell } from 'components/table/components/YesNoCell';
import { getUserFullNameString } from 'utils/getUserFullNameString';
import { OSHAShiftCell } from 'components/table/components/OSHAShiftCell';
import { Organization } from 'api/services/organization/types';
import { TableCellText } from './components/TableCellText';
import { ResultsChip } from './components/ResultsChip';

export const getCells = ({
  organization,
}: {
  organization: Organization | null;
}): Cell<ParticipantWithLastHearingTestResultViewItem>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Full name',
    key: 'name',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <TableCellText>{getUserFullNameString(item) || '-'}</TableCellText>
    ),
    sortable: true,
    orderByValue: 'name',
  },
  {
    name: 'Test Date',
    key: 'dateTime',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => {
      const testDateObject = DateTime.fromISO(item.dateTime);
      return (
        <TableCellText>
          {testDateObject.isValid ? testDateObject.toFormat('MM.dd.yyyy') : '-'}
        </TableCellText>
      );
    },
    sortable: true,
    orderByValue: 'dateTime',
  },
  {
    name: 'Result Left',
    key: 'resultLeft',
    align: 'center',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <ResultsChip hearingTestResult={item || null} side="left" />
    ),
  },
  {
    name: 'Result Right',
    key: 'resultRight',
    align: 'center',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <ResultsChip hearingTestResult={item || null} side="right" />
    ),
  },
  {
    name: 'STS Shift',
    key: 'STSShift',
    align: 'center',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <STSShiftCell
        testResult={item}
        isAgeAdjustment={
          !!organization?.isAgeAdjustmentApplicableToHearingTestResults
        }
      />
    ),
    sortable: true,
    orderByValue: 'STSShift',
  },
  {
    name: 'OSHA Recordable Shift',
    key: 'OSHAShift',
    align: 'center',
    render: ({
      leftAgeAdjustedSTS,
      rightAgeAdjustedSTS,
      leftSTSFrequenciesAverage,
      rightSTSFrequenciesAverage,
    }: ParticipantWithLastHearingTestResultViewItem) => (
      <OSHAShiftCell
        leftAgeAdjustedSTS={leftAgeAdjustedSTS}
        rightAgeAdjustedSTS={rightAgeAdjustedSTS}
        leftSTSFrequenciesAverage={leftSTSFrequenciesAverage}
        rightSTSFrequenciesAverage={rightSTSFrequenciesAverage}
      />
    ),
    sortable: true,
    orderByValue: 'OSHAShift',
  },
  {
    name: 'Referral',
    key: 'referral',
    align: 'center',
    render: ({ isReferral }: ParticipantWithLastHearingTestResultViewItem) => (
      <YesNoCell value={isReferral ? 'Yes' : 'No'} />
    ),
  },
  {
    name: 'Status',
    key: 'status',
    align: 'center',
    render: ({
      status,
      subStatuses,
    }: ParticipantWithLastHearingTestResultViewItem) => (
      <ReportStatusCell status={status} subStatuses={subStatuses} />
    ),
    sortable: true,
    orderByValue: 'status',
  },
];
