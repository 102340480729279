import { ProfessionalSupervisorUser } from 'api/services/auth/types';
import { StatusCell, LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import i18next from 'i18n/config';

export const getCells = (): Cell<ProfessionalSupervisorUser>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.firstName'),
    key: 'given',
  },
  {
    name: i18next.t('common.middleName'),
    key: 'middleName',
  },
  {
    name: i18next.t('common.lastName'),
    key: 'family',
  },
  {
    name: i18next.t('common.email'),
    key: 'email',
  },
  {
    name: i18next.t('common.licensedStates'),
    key: 'licensedStatesCount',
    render: (item: ProfessionalSupervisorUser) => (
      <LinkCell
        to={`/admin/professional-supervisors/license-states?userId=${item.id}`}
        label={item.licenseStatesCount || '0'}
      />
    ),
  },
  {
    name: i18next.t('common.locations'),
    key: 'locationsCount',
    render: (item: ProfessionalSupervisorUser) => (
      <LinkCell
        to={`/admin/professional-supervisors/${item.id}/locations`}
        label={item.locationsCount || '0'}
      />
    ),
  },
  {
    name: i18next.t('common.status'),
    key: 'status',
    render: (item: ProfessionalSupervisorUser) => (
      <StatusCell status={item.invitationStatus} />
    ),
  },
];
