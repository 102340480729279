import { RawDraftContentBlock } from 'draft-js';
import { Participant, User } from '../auth/types';
import { UploadFile } from '../uploads/types';

export interface HearingTestResult {
  id: number;
  participantId: number;
  externalId: string;
  comment: string;
  OHCId: number;
  threshold_left_250: number;
  threshold_left_500: number;
  threshold_left_750?: number;
  threshold_left_1000: number;
  threshold_left_1500?: number;
  threshold_left_2000: number;
  threshold_left_3000: number;
  threshold_left_4000: number;
  threshold_left_5000: number;
  threshold_left_6000: number;
  threshold_left_7000: number;
  threshold_left_8000: number;
  threshold_right_250: number;
  threshold_right_500: number;
  threshold_right_750?: number;
  threshold_right_1000: number;
  threshold_right_1500?: number;
  threshold_right_2000: number;
  threshold_right_3000: number;
  threshold_right_4000: number;
  threshold_right_5000: number;
  threshold_right_6000: number;
  threshold_right_7000: number;
  threshold_right_8000: number;
  type: EHearingTestResultTypes;
  testedBy?: string;
  audiometerMake: string;
  audiometerModel: string;
  audiometerSerial: string;
  audiometerCalibrationDate: string;
  dateTime: string;
  rightSTSFrequenciesAverage: number | null;
  leftSTSFrequenciesAverage: number | null;
  rightAgeAdjustedSTSFrequenciesAverage: number | null;
  leftAgeAdjustedSTSFrequenciesAverage: number | null;
  leftSTS: number | null;
  rightSTS: number | null;
  leftAgeAdjustedSTS: number | null;
  rightAgeAdjustedSTS: number | null;
  participant: Participant;
  audiologistNotes?: IAudiologistNote[];
  classifiedHearingLoss: EHearingThresholdsNames;

  leftEarSpeechFrequenciesAverage: number | null;
  rightEarSpeechFrequenciesAverage: number | null;
  leftEarHighFrequenciesAverage: number | null;
  rightEarHighFrequenciesAverage: number | null;

  asymmetryAt5001k2kHzFrequenciesAverage: number | null;
  asymmetryAtHighFrequenciesAverage: number | null;
  shiftFromBaselineIn5001k2kHzFrequenciesAverage: number | null;
  shiftFromBaselineInHighFrequenciesAverage: number | null;

  reportMessage: string | null;
  referralComment: string | null;
  isReferral: boolean | null;
  status: EHearingTestStatuses;
  subStatuses: EHearingTestSubStatuses[] | null;
  typeChangeLogs?: IHearingTestResultLog[];
  attachments?: HearingTestResultAttachment[];
}

export interface HearingTestResultAttachment {
  id: number;
  testResultId: number;
  fileId: number;
  file: UploadFile;
  created_at: string;
  updated_at: string;
}

export enum EHearingThresholdsNames {
  Normal = 'Normal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  ModeratelySevere = 'Moderately-Severe',
  Severe = 'Severe',
  Profound = 'Profound',
}

export interface INoteBody {
  entityMap: string;
  blocks: RawDraftContentBlock[];
}

export interface IAudiologistNote {
  id: number;
  professionalSupervisorId: number | null;
  hearingTestResultId: number;
  note: INoteBody;
  created_at: string;
  updated_at: string;
}

export enum EHearingTestResultTypes {
  Baseline = 'B',
  Retest = 'T',
  Annual = 'A',
  Revise = 'R',
  Followup = 'F',
  Invalid = 'I',
  Other = 'O',
}

export enum EHearingTestSubStatuses {
  RetestRequestedWithin30Days = 'Re-test requested within 30 days',
  PSRequestsWorkerCompleteAdditionalQuestionnaire = 'PS requests worker complete additional questionnaire',
  PSRequestsContactWithOHC = 'PS requests contact with OHC',
  PSRequestsContactWithWorkerOrSupervisor = 'PS requests contact with Worker/Supervisor',
  NoPSRecommendation = 'No PS recommendation',
  PSRecommendsHearingCareSpecialist = 'PS recommends a hearing care specialist',
}

export enum EHearingTestStatuses {
  NoReviewNeeded = 'No Review Needed',
  NeedsReview = 'Needs Review',
  PendingPSRequest = 'Pending PS Request',
  PSSigned = 'PS Signed',
  Reviewed = 'Reviewed',
  Historical = 'Historical',
  Invalid = 'Invalid',
}

export const HEARING_TEST_SUB_STATUSES_GROUPING_MAP = {
  [EHearingTestStatuses.NoReviewNeeded]: [] as EHearingTestSubStatuses[],
  [EHearingTestStatuses.NeedsReview]: [] as EHearingTestSubStatuses[],
  [EHearingTestStatuses.Historical]: [] as EHearingTestSubStatuses[],
  [EHearingTestStatuses.PendingPSRequest]: [
    EHearingTestSubStatuses.RetestRequestedWithin30Days,
    EHearingTestSubStatuses.PSRequestsWorkerCompleteAdditionalQuestionnaire,
    EHearingTestSubStatuses.PSRequestsContactWithOHC,
    EHearingTestSubStatuses.PSRequestsContactWithWorkerOrSupervisor,
  ],
  [EHearingTestStatuses.PSSigned]: [
    EHearingTestSubStatuses.NoPSRecommendation,
    EHearingTestSubStatuses.PSRecommendsHearingCareSpecialist,
  ],
  [EHearingTestStatuses.Reviewed]: [
    EHearingTestSubStatuses.NoPSRecommendation,
    EHearingTestSubStatuses.PSRecommendsHearingCareSpecialist,
  ],
  [EHearingTestStatuses.Invalid]: [] as EHearingTestSubStatuses[],
};

export interface IHearingTestResultTypeChangeLogMetadata {
  oldType: EHearingTestResultTypes;
  newType: EHearingTestResultTypes;
  reason: string;
}

export enum EHearingTestResultLogsActions {
  TypeChange = 'TYPE_CHANGE',
}

export interface IHearingTestResultLog {
  id: number;
  hearingTestResultId: number;
  userId: number;
  action: EHearingTestResultLogsActions;
  metadata: IHearingTestResultTypeChangeLogMetadata;
  user?: User;
  created_at: string;
  updated_at: string;
}
