import { PaginatedData } from 'common/types';
import { PaginatedRequestParams } from 'api/types';
import axios from '../../axios';
import { ExternalClinic, ExternalClinicBody } from './types';
import { ILocation } from '../organization/types';

export class ExternalClinicAPI {
  async getExternalClinics({
    limit = 10,
    offset = 0,
    search,
  }: PaginatedRequestParams) {
    const { data } = await axios.get(`/external-clinic`, {
      params: {
        limit,
        offset,
        search,
      },
    });

    return data as PaginatedData<ExternalClinic>;
  }

  async getExternalClinicLocations(
    externalClinicId: string | number,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/external-clinic/${externalClinicId}/location`,
      {
        params: {
          limit,
          offset,
          search,
        },
      },
    );

    return data as PaginatedData<ILocation>;
  }

  async getExternalClinicById(id: number | string) {
    const { data } = await axios.get<ExternalClinic>(`/external-clinic/${id}`);

    return data;
  }

  async createExternalClinic(body: ExternalClinicBody) {
    const { data } = await axios.post('/external-clinic', body);

    return data as ExternalClinic;
  }

  async updateExternalClinic(id: number, body: ExternalClinicBody) {
    const { data } = await axios.put(`/external-clinic/${id}`, body);

    return data as ExternalClinic;
  }
}
