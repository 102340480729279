import { Header } from 'components/header';
import { useEffect, useMemo, useState } from 'react';
import { ErrorScreen } from 'components/common/error-screen';
import { InfoModal } from 'components/modal/InfoModal';
import { api } from 'api';
import { useAuthSelector } from 'store/selectors/auth';
import { DateTime } from 'luxon';
import { VisitTimeSlot } from 'api/services/organization/types';
import { Grid, useMediaQuery } from '@mui/material';
import { theme } from 'theme';
import { FormWithBgContainer } from 'components/common/form-with-bg-container';
import { ParticipantInfo } from 'components/participant-info';
import { BookingForm } from './components/booking-form';

const BGImage = '/images/backgrounds/booking/booking-bg.jpg';
const BGImageMobile = '/images/backgrounds/booking/booking-bg-mobile.jpg';

export function Booking() {
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuthSelector();
  const [error, setError] = useState('');
  const [lastScheduledVisit, setLastScheduledVisit] =
    useState<VisitTimeSlot | null>(null);

  const imageUrl = useMemo(
    () => (isBelowMd ? BGImageMobile : BGImage),
    [isBelowMd],
  );

  const getLastScheduledVisit = async () => {
    if (!user?.id) {
      return;
    }
    try {
      const data = await api.participant.getLastScheduledVisit(user.id);
      setLastScheduledVisit(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLastScheduledVisit();
  }, []);

  return (
    <>
      <Header
        isEmpty
        logo={
          <img
            alt="logo"
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            width="88px"
          />
        }
      />

      <FormWithBgContainer imageUrl={imageUrl}>
        {error ? (
          <ErrorScreen errorMessage={error} />
        ) : (
          <Grid sx={{ maxWidth: { sm: '586px', lg: 'unset' } }}>
            <ParticipantInfo />
            <BookingForm setError={setError} />
          </Grid>
        )}
      </FormWithBgContainer>

      {!!lastScheduledVisit && (
        <InfoModal
          open={!!lastScheduledVisit}
          handleClose={() => {
            setLastScheduledVisit(null);
          }}
          handleSubmit={() => setLastScheduledVisit(null)}
          submitButtonTitle="Ok"
          title={
            <>
              {`You have been scheduled for an upcoming hearing test. Please attend on ${DateTime.fromISO(
                lastScheduledVisit.dateTime,
              ).toFormat('ccc, LLL dd, y')} at ${DateTime.fromISO(
                lastScheduledVisit.dateTime,
              ).toFormat('h:mm a')} (${DateTime.fromISO(
                lastScheduledVisit.dateTime,
              ).toFormat('ZZZZ')}).`}
              <br />
              To reschedule, please contact your Site Manager.
            </>
          }
        />
      )}
    </>
  );
}
