import {
  EHearingTestStatuses,
  EHearingTestSubStatuses,
} from 'api/services/hearing-test-results/types';
import { Chip } from 'components/common/chip';
import { Tooltip } from 'components/common/tooltip';

interface IReportStatusCellProps {
  status: EHearingTestStatuses | null;
  subStatuses: EHearingTestSubStatuses[] | null;
}

export const STATUS_CHIP_COLORS_MAP = {
  [EHearingTestStatuses.NoReviewNeeded]: {
    labelColor: '#276942',
    dotColor: '#276942',
    backgroundColor: '#8FE0B066',
  },
  [EHearingTestStatuses.NeedsReview]: {
    labelColor: '#C45558',
    dotColor: '#C45558',
    backgroundColor: '#FDD7D7',
  },
  [EHearingTestStatuses.PendingPSRequest]: {
    labelColor: '#8F6715',
    dotColor: '#8F6715',
    backgroundColor: '#FFC88C80',
  },
  [EHearingTestStatuses.PSSigned]: {
    labelColor: '#276942',
    dotColor: '#276942',
    backgroundColor: '#8FE0B066',
  },
  [EHearingTestStatuses.Reviewed]: {
    labelColor: '#114e83',
    dotColor: '#114e83',
    backgroundColor: '#a9e6f8',
  },
  [EHearingTestStatuses.Historical]: {
    labelColor: '#656565',
    dotColor: '#656565',
    backgroundColor: '#dcdcdc',
  },
  [EHearingTestStatuses.Invalid]: {
    labelColor: '#C45558',
    dotColor: '#C45558',
    backgroundColor: '#FDD7D7',
  },
};

export function ReportStatusCell(props: IReportStatusCellProps) {
  const { status, subStatuses } = props;

  const formattedSubStatuses = subStatuses?.length ? (
    <ul>
      {subStatuses.map((subStatus) => (
        <li>{subStatus}</li>
      ))}
    </ul>
  ) : (
    ''
  );

  return status ? (
    <Tooltip
      title={formattedSubStatuses}
      enterTouchDelay={0}
      sx={{
        '& .MuiTooltip-tooltip': { marginRight: '26px' },
        '& ul': { paddingLeft: '16px' },
      }}
    >
      <div>
        <Chip
          label={status}
          isDot
          labelColor={STATUS_CHIP_COLORS_MAP[status].labelColor}
          dotColor={STATUS_CHIP_COLORS_MAP[status].dotColor}
          backgroundColor={STATUS_CHIP_COLORS_MAP[status].backgroundColor}
          sx={{
            marginRight: '26px',
          }}
        />
      </div>
    </Tooltip>
  ) : (
    <>-</>
  );
}
