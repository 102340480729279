import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { api } from 'api';
import { ILocation, Organization } from 'api/services/organization/types';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { getErrorMessageComponent } from 'utils';
import { FormModal } from 'components/modal';
import { IOption } from 'common/types/common';

interface AssignLocationFormProps {
  ohcId: string;
  onSubmit: (locationIds: number[], action: 'assign') => void;
  handleClose: () => void;
  loading: boolean;
  error: string | string[];
}

interface Values {
  organization: IOption | null;
  locationsIds: IOption[];
}

const LocationFormSchema = Yup.object().shape({
  organization: Yup.object().required('Required'),
  locationsIds: Yup.array().min(1, 'Required'),
});

export function AssignLocationForm(props: AssignLocationFormProps) {
  const { loading, error, onSubmit, handleClose } = props;

  const initialValues: Values = {
    organization: null,
    locationsIds: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        onSubmit(
          values.locationsIds.map((item) => Number(item.value)),
          'assign',
        )
      }
      validationSchema={LocationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={loading}
          title="Assign Locations to OHC"
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <AsyncAutocomplete<Organization>
                  fullWidth
                  label="Organization"
                  fetchMethod={(params) =>
                    api.organization.getOrganizations(params)
                  }
                  mapOptionMethod={(item: Organization) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('organization', newValue);
                  }}
                  value={values.organization}
                  onBlur={handleBlur}
                  error={touched.organization && Boolean(errors.organization)}
                  helperText={
                    touched.organization && errors.organization
                      ? errors.organization
                      : ' '
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <AsyncAutocomplete<ILocation>
                  fullWidth
                  multiple
                  label="Locations"
                  fetchMethod={(params) =>
                    api.organization.getLocations(
                      values.organization?.value || '',
                      params,
                    )
                  }
                  mapOptionMethod={(item: ILocation) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('locationsIds', newValue);
                  }}
                  value={values.locationsIds}
                  onBlur={handleBlur}
                  getOptionDisabled={(option) =>
                    !!values.locationsIds.find(
                      (item) => item.value === option.value,
                    )
                  }
                  error={touched.locationsIds && Boolean(errors.locationsIds)}
                  helperText={
                    touched.locationsIds && (errors.locationsIds as string)
                      ? (errors.locationsIds as string)
                      : ' '
                  }
                  disabled={!values.organization?.value}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
