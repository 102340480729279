import { AccountManagerUser } from 'api/services/auth/types';
import { StatusCell, LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import i18next from 'i18n/config';
import { getUserFullNameString } from 'utils/getUserFullNameString';

export const getCells = (): Cell<AccountManagerUser>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.fullName'),
    render: (item: AccountManagerUser) => getUserFullNameString(item, true),
  },
  {
    name: i18next.t('common.email'),
    key: 'email',
  },
  {
    name: i18next.t('common.organizations'),
    key: 'organizationsCount',
    render: (item: AccountManagerUser) => (
      <LinkCell
        to={`/admin/account-managers/${item.id}/organizations`}
        label={item.organizationsCount || '0'}
      />
    ),
  },
  {
    name: i18next.t('common.status'),
    key: 'status',
    render: (item: AccountManagerUser) => (
      <StatusCell
        status={!item.active ? 'deactivated' : item.invitationStatus}
      />
    ),
  },
];
