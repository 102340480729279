import { useMemo } from 'react';
import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { Grid } from '@mui/material';
import { ITab } from 'components/tabs/dashboard-tabs/types';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { DashboardTabs } from 'components/tabs';
import PeopleIcon from '@mui/icons-material/People';
import { ProfessionalSupervisorParticipantsList } from './components/professional-supervisor-participants';
import { ProfessionalSupervisorReportsList } from './components/professional-supervisor-reports-lists';

export function ProfessionalSupervisorDashboard() {
  useIdentity();

  const tabs = useMemo<ITab[]>(
    () => [
      {
        label: 'Employees',
        component: <ProfessionalSupervisorParticipantsList />,
        icon: <PeopleIcon fontSize="small" />,
      },
      {
        label: 'Reports',
        component: <ProfessionalSupervisorReportsList />,
        icon: <InsertDriveFileOutlined fontSize="small" />,
      },
    ],
    [],
  );

  const renderTitle = (tab: ITab) => <Grid container>{tab.label}</Grid>;

  return (
    <BasicContainer>
      <DashboardTabs tabs={tabs} title={renderTitle} />
    </BasicContainer>
  );
}
