import { FullHeightContainer } from 'components/container';
import { FormModal } from 'components/modal';
import { IVisitTime, VisitShift } from 'api/services/organization/types';
import { useState } from 'react';
import { api } from 'api';
import { showSnackbar } from 'utils';
import { Grid } from '@mui/material';
import { TextInput } from 'components/common/input/TextInput';
import * as Yup from 'yup';
import { FormAlert } from 'components/alert';
import { Formik } from 'formik';
import { DatePicker, Select } from 'components/common/input';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { IOption } from 'common/types/common';
import { DateTime } from 'luxon';
import { TimePicker } from 'components/common/input/TimePicker';

interface Values {
  date: DateTime | null;
  startTime: DateTime | null;
  endTime: DateTime | null;
  testLength: number | null;
  testsInParallel: number | null;
  ohcCount: number | null;
  shifts: IOption[];
}

const VisitTimeFormSchema = Yup.object().shape({
  date: Yup.object<DateTime>()
    .required('Required')
    .test('isValid', 'Should be valid Date', (value) => {
      try {
        return (value as DateTime).isValid;
      } catch {
        return true;
      }
    })
    .test('isFuture', 'Must be a future Date', (value) => {
      try {
        return (
          (value as DateTime).startOf('day') >= DateTime.now().startOf('day')
        );
      } catch {
        return true;
      }
    }),
  startTime: Yup.object()
    .required('Required')
    .test('isValid', 'Should be valid Time', (value) => {
      try {
        return (value as DateTime).isValid;
      } catch {
        return true;
      }
    })
    .test('isBeforeTime', 'Should be before End Time', (value, testContext) => {
      const { endTime } = testContext.parent;
      try {
        return (
          (value as DateTime).toMillis() < (endTime as DateTime).toMillis()
        );
      } catch {
        return true;
      }
    }),
  endTime: Yup.object<DateTime>()
    .required('Required')
    .test('isValid', 'Should be valid Time', (value) => {
      try {
        return (value as DateTime).isValid;
      } catch {
        return true;
      }
    })
    .test('isAfterTime', 'Should be after Start Time', (value, testContext) => {
      const { startTime } = testContext.parent;
      try {
        return (
          (value as DateTime).toMillis() > (startTime as DateTime).toMillis()
        );
      } catch {
        return true;
      }
    }),
  testLength: Yup.string().required('Required'),
  testsInParallel: Yup.string().required('Required'),
  ohcCount: Yup.string().required('Required'),
  shifts: Yup.array().min(1, 'Required'),
});

interface VisitFormProps {
  visitTime?: IVisitTime | null;
  locationId: string;
  visitId: string;
  timezone: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function VisitTimeForm(props: VisitFormProps) {
  const {
    visitTime,
    handleSubmit,
    handleClose,
    locationId,
    visitId,
    timezone = 'America/New_York',
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const testLengthOptions = ['10', '15', '20'].map((gender) => ({
    label: gender,
    value: gender,
  }));

  const initialValues: Values = visitTime
    ? {
        date: DateTime.fromISO(visitTime.startTime).setZone(timezone),
        startTime: DateTime.fromISO(visitTime.startTime).setZone(timezone),
        endTime: DateTime.fromISO(visitTime.endTime).setZone(timezone),
        testLength: visitTime.testLength,
        testsInParallel: visitTime.testsInParallel,
        ohcCount: visitTime.ohcCount,
        shifts: visitTime.visitTimeShifts.map((item) => ({
          value: item.shift.id,
          label: item.shift.name,
        })),
      }
    : {
        date: null,
        startTime: null,
        endTime: null,
        testLength: null,
        testsInParallel: null,
        ohcCount: null,
        shifts: [],
      };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      const requestBody = {
        ohcCount: Number(values.ohcCount),
        testLength: Number(values.testLength),
        testsInParallel: Number(values.testsInParallel),
        startTime:
          values.date
            ?.set({
              hour: values.startTime?.get('hour'),
              minute: values.startTime?.get('minute'),
            })
            .setZone(timezone)
            .toString() || '',
        endTime:
          values.date
            ?.set({
              hour: values.endTime?.get('hour'),
              minute: values.endTime?.get('minute'),
            })
            .setZone(timezone)
            .toString() || '',
        shifts: values.shifts.map((item) => Number(item.value)),
      };

      if (visitTime)
        await api.organization.updateVisitTime(
          locationId,
          visitId,
          visitTime.id,
          requestBody,
        );
      else
        await api.organization.createVisitTime(
          locationId,
          visitId,
          requestBody,
        );
      showSnackbar(
        `Visit Time successfully ${visitTime ? 'updated' : 'added'}`,
        {
          variant: 'success',
        },
      );
      handleSubmit();
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={VisitTimeFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={visitTime ? 'Edit Visit' : 'Create Visit'}
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <DatePicker
                  sx={{ width: '100%' }}
                  value={values.date}
                  onChange={(value) => {
                    if (values.shifts) {
                      setFieldValue('shifts', []);
                    }
                    setFieldValue('date', value);
                  }}
                  timezone={timezone}
                  label="Date"
                  disablePast
                  errorMessage={
                    touched.date && errors.date ? (errors.date as string) : ''
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  direction={{ xs: 'column', sm: 'row' }}
                  gap={{ xs: 1, sm: 4 }}
                  wrap="nowrap"
                >
                  <TimePicker
                    label="Start time"
                    value={values.startTime}
                    onChange={(value) => {
                      setFieldValue('startTime', value);
                    }}
                    errorMessage={
                      touched.startTime && errors.startTime
                        ? (errors.startTime as string)
                        : ''
                    }
                    disabled={!values.date}
                  />

                  <TimePicker
                    label="End time"
                    value={values.endTime}
                    onChange={(value) => {
                      setFieldValue('endTime', value);
                    }}
                    errorMessage={
                      touched.endTime && errors.endTime
                        ? (errors.endTime as string)
                        : ''
                    }
                    disabled={!values.date}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="#OHCs"
                  variant="outlined"
                  type="integer"
                  name="ohcCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ohcCount}
                  error={touched.ohcCount && Boolean(errors.ohcCount)}
                  helperText={
                    touched.ohcCount && errors.ohcCount ? errors.ohcCount : ' '
                  }
                  margin="none"
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="#of Testing In Parallel"
                  variant="outlined"
                  type="integer"
                  name="testsInParallel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.testsInParallel}
                  error={
                    touched.testsInParallel && Boolean(errors.testsInParallel)
                  }
                  helperText={
                    touched.testsInParallel && errors.testsInParallel
                      ? errors.testsInParallel
                      : ' '
                  }
                  margin="none"
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  fullWidth
                  label="Test Length (minutes)"
                  options={testLengthOptions}
                  value={values.testLength}
                  displayEmpty
                  onChange={(e) =>
                    setFieldValue('testLength', Number(e.target.value))
                  }
                  errorMessage={
                    touched.testLength && errors.testLength
                      ? errors.testLength
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AsyncAutocomplete<VisitShift>
                  fullWidth
                  multiple
                  label="Shifts"
                  fetchMethod={() =>
                    api.organization.getAvailableVisitShifts(
                      locationId,
                      visitId,
                      {
                        startTime:
                          values.date
                            ?.set({
                              hour: values.startTime?.get('hour'),
                              minute: values.startTime?.get('minute'),
                            })
                            .setZone(timezone)
                            .toString() || '',
                      },
                    )
                  }
                  mapOptionMethod={(item: VisitShift) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('shifts', newValue);
                  }}
                  value={values.shifts}
                  onBlur={handleBlur}
                  getOptionDisabled={(option) =>
                    !!values.shifts.find((item) => item.value === option.value)
                  }
                  error={touched.shifts && Boolean(errors.shifts)}
                  helperText={
                    touched.shifts && errors.shifts
                      ? (errors.shifts as string)
                      : ' '
                  }
                  disabled={!values.startTime || !values.endTime}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">{error}</FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
