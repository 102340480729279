import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  EHearingTestStatuses,
  EHearingTestSubStatuses,
  HearingTestResult,
} from '../../../../api/services/hearing-test-results/types';
import { SimpleButton } from '../../../common/button';
import { StatusSelectionModal } from './StatusSelectionModal';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { api } from '../../../../api';

interface IReviewStatusSectionProps {
  hearingTestResult: HearingTestResult | null;
  editable?: boolean;
  onEdit?: () => Promise<void> | void;
}

export function ReviewStatusSection(props: IReviewStatusSectionProps) {
  const { hearingTestResult, editable, onEdit = () => {} } = props;

  const subStatuses = useMemo(() => {
    if (!hearingTestResult) return [];
    return _.isArray(hearingTestResult.subStatuses)
      ? hearingTestResult.subStatuses
      : [];
  }, [hearingTestResult]);

  const status = useMemo(() => {
    if (!hearingTestResult) return null;
    return hearingTestResult.status;
  }, [hearingTestResult]);

  const [modalOpened, setModalOpened] = useState(false);

  const theme = useTheme();

  const handleStatusChange = useCallback(
    async (body: {
      status: EHearingTestStatuses;
      subStatuses: EHearingTestSubStatuses[];
    }) => {
      if (!hearingTestResult) return;
      try {
        await api.hearingTestResults.updateHearingTestResult(
          hearingTestResult.id,
          body,
        );
        await onEdit?.();
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [hearingTestResult, onEdit],
  );

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ display: 'inline-flex', width: 'auto' }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 800,
            lineWeight: '120%' /* 16.8px */,
            letterSpacing: '0.15px',
            textTransform: 'uppercase',
          }}
        >
          Review Status
        </Typography>
        {editable && (
          <SimpleButton
            actionType="edit"
            sx={{ fontSize: '14px', marginLeft: theme.spacing(1) }}
            onClick={() => setModalOpened(true)}
          />
        )}
      </Grid>
      <Grid container direction="column">
        {hearingTestResult ? (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 800,
              lineWeight: '120%',
              letterSpacing: '0.2px',
            }}
          >
            {status || '-'}
          </Typography>
        ) : (
          <Skeleton width="200px" />
        )}
        {subStatuses?.length ? (
          <ul
            style={{
              margin: '8px 0',
              paddingLeft: theme.spacing(2),
            }}
          >
            {subStatuses.map((subStatus) => (
              <li>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineWeight: '120%',
                    letterSpacing: '0.2px',
                  }}
                >
                  {subStatus}
                </Typography>
              </li>
            ))}
          </ul>
        ) : null}
      </Grid>
      {
        <StatusSelectionModal
          open={modalOpened}
          handleClose={() => setModalOpened(false)}
          onSubmit={handleStatusChange}
          status={status || null}
          subStatuses={subStatuses || []}
        />
      }{' '}
    </Box>
  );
}
