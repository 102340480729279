import { Skeleton, TableCellProps, TableCell } from '@mui/material';

export function MetricsTableCell(
  props: TableCellProps & { loading?: boolean },
) {
  const { sx = {}, loading, children, ...otherProps } = props;
  return (
    <TableCell
      component="th"
      scope="row"
      {...otherProps}
      sx={{
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'DM Mono',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
        textAlign: 'center',
        border: '1px solid #EAEAEA',
        ...sx,
      }}
    >
      {loading ? <Skeleton width="100%" /> : children}
    </TableCell>
  );
}
