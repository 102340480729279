import { useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { api } from 'api';
import { FullHeightContainer } from 'components/container';
import { MfaMethod } from 'api/services/auth/types';
import { Button } from 'components/common/button';
import { FormAlert } from 'components/alert';
import { getErrorMessageComponent } from 'utils';
import { Loader } from 'components/common/loader';
import { VerificationHeaderText } from '../components/Shared';

export type SendEmailVerificationStepProps = {
  email: string;
  onGoToNextStep: () => void;
};

export function SendEmailVerificationStep({
  email,
  onGoToNextStep,
}: SendEmailVerificationStepProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await api.user.getMfaVerificationCode(MfaMethod.EMAIL, email);
      onGoToNextStep();
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message);
    }
    setIsLoading(false);
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <FullHeightContainer maxWidth="xs" disableGutters>
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={0.5}
          >
            <VerificationHeaderText />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    sx={{
                      marginBottom: theme.spacing(4),
                      fontSize: theme.spacing(1.6),
                    }}
                  >
                    We will send a verification code to {email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      {error ? (
                        <FormAlert severity="error">
                          {getErrorMessageComponent(error)}
                        </FormAlert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </FullHeightContainer>
      )}
    </Formik>
  );
}
