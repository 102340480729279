import { Typography, TypographyProps } from '@mui/material';

export function CoverLetterParagraph(props: TypographyProps) {
  const { children, ...restProps } = props;
  return (
    <Typography
      sx={{
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        marginTop: 1,
        marginBottom: 1,
      }}
      {...restProps}
    >
      {children}
    </Typography>
  );
}
