import { Grid } from '@mui/material';
import { Tooltip } from 'components/common/tooltip';

interface ILinkCellProps {
  text: string;
}

export function EllipsisCell(props: ILinkCellProps) {
  const { text } = props;

  return (
    <Tooltip placement="bottom-start" title={text}>
      <Grid
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Grid>
    </Tooltip>
  );
}
