import {
  Tooltip as TooltipComponent,
  styled,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipComponent {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.90)',
  },
}));
