import { Chip } from 'components/common/chip';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { CHIP_STYLES } from '../constants';

interface ILastTestDateCellProps {
  date: string;
  onClick?: () => void;
}

export function DateCell(props: ILastTestDateCellProps) {
  const { date, onClick } = props;

  const formattedDate = useMemo(() => {
    if (!date) {
      return 'N/A';
    }
    const dateTime: DateTime = DateTime.fromISO(date);
    return dateTime.toFormat('MM/dd/yyyy');
  }, [date]);

  const chipStyle = useMemo(
    () =>
      date ? CHIP_STYLES.testingIsInMoreThanTwoMonths : CHIP_STYLES.disabled,
    [date],
  );

  return (
    <Chip
      label={formattedDate}
      backgroundColor={chipStyle.background}
      labelColor={chipStyle.text}
      isDot
      dotColor={chipStyle.dot}
      sx={onClick ? { cursor: 'pointer' } : {}}
      clickable={!!onClick && !!date}
      onClick={(e) => {
        if (!onClick || !date) {
          return;
        }
        e.stopPropagation();
        onClick();
      }}
    />
  );
}
