import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate } from 'react-router-dom';
import { Organization } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { Grid } from '@mui/material';
import { SearchInput } from 'components/common/input/SearchInput';
import { RequestParams } from 'api/types';
import { useAuthSelector } from 'store/selectors/auth';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { getCells } from './cells';
import { OrganizationForm } from './components/organization-form';

export function AccountManagerOrganizations() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = useAuthSelector();

  const [open, setOpen] = useState(false);
  const [editedOrganization, setEditedOrganization] =
    useState<Organization | null>(null);

  const fetchOrganizations = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      if (!user) {
        return EMPTY_PAGINATED_DATA;
      }
      const organizationsData = await api.user.getAccountManagerOrganizations(
        user.id,
        {
          limit,
          offset,
          orderBy,
          orderDirection,
          search,
          filterParams,
        },
      );
      return organizationsData;
    },
    [],
  );
  const tableState = useTableState<Organization>({
    fetchDataFunction: fetchOrganizations,
  });

  const actions = [
    {
      title: t('common.edit'),
      onClick: (organization: Organization) => {
        setEditedOrganization(organization);
        setOpen(true);
      },
    },
    {
      title: t('common.uploadRoster'),
      onClick: (organization: Organization) => {
        navigate(
          `/account-manager/organizations/${organization.id}/roster-uploads`,
        );
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Grid />
        <Grid
          item
          py={{
            xs: 0,
            sm: 3,
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>

      <BasicTable<Organization>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && (
        <OrganizationForm
          organization={editedOrganization}
          handleSubmit={() => {
            setOpen(false);
            setEditedOrganization(null);
            if (editedOrganization) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
          handleClose={() => setOpen(false)}
        />
      )}
    </BasicContainer>
  );
}
