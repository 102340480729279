import React, { SyntheticEvent, useMemo } from 'react';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { IState } from '../../../api/services/state/types';
import { Select, TextInput } from '../../common/input';
import { SimpleButton } from '../../common/button';

interface LicenseStateRowProps {
  id: string;
  licenseNumber: string;
  licenseExpirationDate: string;
  stateId: number | null;
  states: IState[];
  onDeleteRow: (() => void) | null;
  onChangeState: (stateId: number) => void;
  onChangeLicenseExpirationDate: (date: string) => void;
  onChangeLicenseNumber: (number: string) => void;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
}

export function ProfessionalSupervisorLicenseStatesRow(
  props: LicenseStateRowProps,
) {
  const {
    id,
    licenseNumber,
    licenseExpirationDate,
    stateId,
    onDeleteRow,
    states,
    onChangeState,
    onChangeLicenseExpirationDate,
    onChangeLicenseNumber,
    errors,
    touched,
  } = props;
  const stateChangeHandler = (e: SelectChangeEvent<any>) => {
    onChangeState(Number(e.target.value));
  };
  const licenseNumberChangeHandler = (e: any) => {
    onChangeLicenseNumber(e.target.value);
  };
  const licenseExpirationDateChangeHandler = (e: any) => {
    onChangeLicenseExpirationDate(e.target.value);
  };

  const deleteStateHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (onDeleteRow) {
      onDeleteRow();
    }
  };
  const theme = useTheme();

  const statesOptions = useMemo(
    () => states.map((state) => ({ label: state.name, value: state.id })),
    [states],
  );

  const selectValue = useMemo(
    () =>
      stateId
        ? states.find((state) => state.id === stateId)?.name
        : 'Select licensed state',
    [stateId, states],
  );

  return (
    <Grid
      key={id}
      container
      justifyContent="space-between"
      spacing={theme.spacing(2)}
      alignItems="center"
      item
      mb={theme.spacing(4)}
      mt={theme.spacing(2)}
    >
      <Grid
        container
        item
        xs={10}
        justifyContent="space-between"
        spacing={theme.spacing(2)}
      >
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Select
            fullWidth
            options={statesOptions}
            value={stateId || ''}
            displayEmpty
            renderValue={() => selectValue}
            onChange={stateChangeHandler}
            errorMessage={touched?.stateId ? (errors?.stateId as string) : ''}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextInput
            value={licenseNumber}
            onChange={licenseNumberChangeHandler}
            label="License #"
            fullWidth
            error={touched?.licenseNumber && !!errors?.licenseNumber}
            helperText={
              touched?.licenseNumber ? (errors?.licenseNumber as string) : ' '
            }
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextInput
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={licenseExpirationDate}
            onChange={licenseExpirationDateChangeHandler}
            label="License Expiration Date"
            fullWidth
            error={
              touched?.licenseExpirationDate && !!errors?.licenseExpirationDate
            }
            helperText={
              touched?.licenseExpirationDate
                ? (errors?.licenseExpirationDate as string)
                : ' '
            }
          />
        </Grid>
      </Grid>

      <Grid item width={40}>
        <SimpleButton
          actionType="delete"
          onClick={deleteStateHandler}
          disabled={!onDeleteRow}
        />
      </Grid>
    </Grid>
  );
}
