export const CHIP_STYLES = {
  testingIsInMoreThanTwoMonths: {
    text: '#287013',
    dot: '#4AD520',
    background: 'rgba(104, 247, 56, 0.20)',
  },
  testingIsInLessThanTwoMonths: {
    text: '#815800',
    dot: '#815800',
    background: 'rgba(255, 237, 143, 0.5)',
  },
  testingIsOverdue: {
    text: '#936929',
    dot: '#e19218',
    background: 'rgba(216, 147, 37, 0.35)',
  },
  disabled: {
    text: '#C45558',
    dot: '#C45558',
    background: 'rgba(255,119,119,0.35)',
  },
};
