import GeneralTermsOfUseContent from './GeneralTermsOfUseContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';

export const CONTENT = {
  general: {
    title: 'General Terms Of Use',
    content: <GeneralTermsOfUseContent />,
  },
  provider: {
    title: 'Provider Terms Of Use',
    content: '',
  },
  'privacy-policy': {
    title: 'Privacy Policy',
    content: <PrivacyPolicyContent />,
  },
};
