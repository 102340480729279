import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import {
  Radio as RadioComponent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  TypographyProps,
  FormControlProps,
  styled,
} from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';

const StyledTypography = styled(Typography)({});

interface IRadioItem {
  value: string;
  label: ReactNode;
  disabled?: boolean;
}

interface IRadioProps extends FormControlProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  labelProps?: TypographyProps;
  items: IRadioItem[];
}

export function Radio(props: IRadioProps) {
  const { items, value, onChange, labelProps, ...restProps } = props;
  return (
    <FormControl {...restProps}>
      <RadioGroup name="radio-buttons-group" value={value} onChange={onChange}>
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={
              <RadioComponent
                icon={
                  <RadioButtonUnchecked
                    htmlColor="#08161F"
                    sx={{ opacity: item.disabled ? 0.7 : 1 }}
                  />
                }
                checkedIcon={<RadioButtonChecked htmlColor="#6DBDBF" />}
              />
            }
            label={
              <StyledTypography {...labelProps}>{item.label}</StyledTypography>
            }
            disabled={item.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
