import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export function CustomTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '8px',
        }}
      >
        <Typography variant="mono" sx={{ fontSize: '10px' }}>
          {label}
        </Typography>
        {payload.map((item: any) => (
          <Grid container direction="column" key={item.name}>
            <Typography
              variant="mono"
              sx={{ fontSize: '9px', color: item.color }}
            >{`Participants: ${item.value}`}</Typography>
          </Grid>
        ))}
      </Box>
    );
  }

  return null;
}
