import { PaginatedData } from 'common/types';
import axios from '../../axios';
import { Participant } from '../auth/types';
import { HearingTestResult } from '../hearing-test-results/types';
import {
  GetParticipantReportResponse,
  IParticipantSignedReport,
  ParticipantBody,
  SignParticipantHearingTestReportBody,
  SignParticipantHearingTestReportResponse,
} from './types';
import {
  SendParticipantReportsBody,
  VisitTimeSlot,
} from '../organization/types';

export class ParticipantAPI {
  async getParticipants(limit = 10, offset = 0) {
    const { data } = await axios.get(
      `/participant?limit=${limit}&offset=${offset}`,
    );
    return data as PaginatedData<Participant>;
  }

  async getParticipantById(id: number) {
    const { data } = await axios.get(`/participant/${id}`);
    return data as Participant;
  }

  async getHearingTestResultsByParticipantId(id: number, params: any) {
    const { data } = await axios.get(
      `/participant/${id}/hearing-test-results`,
      params,
    );
    return data as PaginatedData<HearingTestResult>;
  }

  async createParticipant(body: ParticipantBody) {
    const { data } = await axios.post(`/participant`, body);

    return data as Participant;
  }

  async updateParticipant(id: number, body: ParticipantBody) {
    const { data } = await axios.put(`/participant/${id}`, body);

    return data as Participant;
  }

  async deleteParticipant(id: number) {
    const { data } = await axios.delete(`/participant/${id}`);

    return data as { success: true };
  }

  async sendHearingTestResultsReport(
    id: number,
    body: SendParticipantReportsBody,
  ) {
    const { data } = await axios.post(
      `/participant/${id}/create-hearing-test-results-report`,
      body,
    );

    return data as { success: true };
  }

  async signParticipantHearingTestReport(
    id: number,
    body: SignParticipantHearingTestReportBody,
  ) {
    const { data } = await axios.post(`/participant/${id}/sign-report`, body);

    return data as SignParticipantHearingTestReportResponse;
  }

  async getParticipantReport(id: number) {
    const { data } = await axios.get(`/participant/${id}/report`);

    return data as GetParticipantReportResponse;
  }

  async getOwnParticipantReportPDF(id: number) {
    const { data } = await axios.get(`/participant/${id}/report-pdf`, {
      responseType: 'blob',
    });

    return data as any;
  }

  async getParticipantReportPDF(participantId: number, reportId: number) {
    const { data } = await axios.get(
      `/participant/${participantId}/reports/${reportId}/download`,
      {
        responseType: 'blob',
      },
    );

    return data as any;
  }

  async getParticipantSignedReports(id: number, params: any) {
    const { data } = await axios.get(
      `/participant/${id}/signed-reports`,
      params,
    );
    return data as PaginatedData<IParticipantSignedReport>;
  }

  async getLastScheduledVisit(id: number) {
    const { data } = await axios.get(`/participant/${id}/last-scheduled-visit`);
    return data as VisitTimeSlot;
  }
}
