import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n/config';

import { App } from 'App';

// mui fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// overriding basic styles when necessary
import './styles/main.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
