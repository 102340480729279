import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import { useSearchParams } from 'react-router-dom';
import { useAuthSelector } from 'store/selectors/auth';
import * as Yup from 'yup';
import { api } from '../../../api';
import {
  ICoverLetterAndSummaryOfTesting,
  ILocationParticipantsHearingMetric,
  ISignedDocument,
} from '../../../api/services/organization/types';
import { CoverLetterParagraph, ReferralsNote, ResultRow } from './components';
import { Button } from '../../common/button';
import { SignatureButton } from '../../common/button/SignatureButton';
import { FilledTextInput } from '../../common/input';

interface ICoverLetterAndSummaryOfTestingValues {
  signature: string;
  signedDocument?: ISignedDocument | null;
  name: string;
}

interface ICoverLetterAndSummaryOfTestingFormProps {
  audiologistCoverLetterId?: number;
  locationId: number;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
});

export function CoverLetterAndSummaryOfTestingForm({
  audiologistCoverLetterId,
  locationId,
}: ICoverLetterAndSummaryOfTestingFormProps) {
  const theme = useTheme();
  const [queryParams] = useSearchParams();
  const { user } = useAuthSelector();
  const [editedCoverLetter, setEditedCoverLetter] =
    useState<ICoverLetterAndSummaryOfTesting | null>(null);
  const dateFrom = useMemo(() => {
    if (editedCoverLetter) {
      return DateTime.fromISO(editedCoverLetter.from).toFormat('yyyy-MM-dd');
    }
    return queryParams.get('dateFrom');
  }, [queryParams, editedCoverLetter]);
  const dateTo = useMemo(() => {
    if (editedCoverLetter) {
      return DateTime.fromISO(editedCoverLetter.to).toFormat('yyyy-MM-dd');
    }
    return queryParams.get('dateTo');
  }, [queryParams, editedCoverLetter]);
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] =
    useState<ILocationParticipantsHearingMetric | null>(null);
  const [initialValues, setInitialValues] =
    useState<ICoverLetterAndSummaryOfTestingValues>({
      signature: '',
      name: '',
    });

  useEffect(() => {
    const asyncRequest = async () => {
      if (!locationId || !dateFrom || !dateTo) {
        return;
      }
      try {
        setLoading(true);
        const metricsData =
          await api.organization.getLocationParticipantsHearingMetricsByLocationId(
            locationId,
            dateFrom,
            dateTo,
          );
        setMetrics(metricsData);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    asyncRequest();
  }, [locationId, dateFrom, dateTo]);

  useEffect(() => {
    const getEditedCoverLetter = async () => {
      if (!audiologistCoverLetterId) return;

      try {
        const editedCoverLetter =
          await api.organization.getAudiologistCoverLetterById(
            locationId,
            audiologistCoverLetterId,
          );
        setEditedCoverLetter(editedCoverLetter);
        setInitialValues((values) => ({
          ...values,
          name: editedCoverLetter.name,
        }));
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)));
      }
    };

    getEditedCoverLetter();
  }, [audiologistCoverLetterId]);

  async function handleSaveCoverLetter(
    values: ICoverLetterAndSummaryOfTestingValues,
  ) {
    if (!dateFrom || !dateTo) {
      return;
    }
    try {
      const requestBody = {
        signature: values.signature,
        from: dateFrom,
        to: dateTo,
        isFinal: !!values.signature,
        name: values.name,
      };
      const result = editedCoverLetter
        ? await api.organization.updateAudiologistCoverLetter(
            locationId,
            editedCoverLetter.id,
            requestBody,
          )
        : await api.organization.createAudiologistCoverLetter(
            locationId,
            requestBody,
          );
      setInitialValues({
        name: values.name,
        signature: '',
        signedDocument: result.signedDocument,
      });
      setEditedCoverLetter(result);
      showSnackbar('Cover letter successfully saved', {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
        variant: 'error',
      });
    }
  }

  const formik = useFormik<ICoverLetterAndSummaryOfTestingValues>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit: async (values) => handleSaveCoverLetter(values),
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
  } = formik;

  const nameInitializedRef = useRef(false);

  useEffect(() => {
    if (
      dateFrom &&
      dateTo &&
      !touched.name &&
      !audiologistCoverLetterId &&
      !nameInitializedRef.current
    ) {
      setFieldValue(
        'name',
        `Audiologist Cover Letter & Summary Of Testing (${DateTime.fromISO(
          dateFrom,
        ).toFormat('MM/dd/yyyy')} - ${DateTime.fromISO(dateFrom).toFormat(
          'MM/dd/yyyy',
        )})`,
      );
      nameInitializedRef.current = true;
    }
  }, [dateFrom, dateTo, touched, values]);

  return (
    <Grid
      container
      sx={{ width: '100%', margin: 0, pb: theme.spacing(4) }}
      spacing={theme.spacing(2)}
      direction="column"
    >
      <Typography
        sx={{
          fontSize: '25px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '130%',
          marginBottom: theme.spacing(1),
        }}
      >
        Cover Letter
      </Typography>
      <Divider />
      <Grid
        container
        direction="column"
        gap={theme.spacing(2.5)}
        sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      >
        {' '}
        <Grid container gap={2} alignItems="center" flexWrap="nowrap">
          <Grid
            item
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '24px',
                whiteSpace: 'nowrap',
              }}
            >
              Report Name
            </Typography>
          </Grid>
          <Grid item sx={{ maxWidth: '355px', width: '100%' }}>
            <FilledTextInput
              fullWidth
              {...getFieldProps('name')}
              helperText={touched.name ? errors.name : ''}
            />
          </Grid>
        </Grid>
        <Grid container gap={2} alignItems="center">
          <Grid
            item
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '24px',
              }}
            >
              Test Date(s)
            </Typography>
          </Grid>

          {!loading &&
          dateFrom &&
          dateTo &&
          DateTime.fromISO(dateFrom).isValid &&
          DateTime.fromISO(dateTo).isValid ? (
            <Grid
              item
              sx={{
                backgroundColor: 'rgba(202, 194, 190, 0.40)',
                padding: '4px 8px',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '130%',
                letterSpacing: '-0.16px',
                textAlign: 'center',
                fontFamily: 'DM Mono',
                whiteSpace: 'break-spaces',
              }}
            >
              {`${DateTime.fromISO(dateFrom).toFormat(
                'LLLL dd, yyyy',
              )} - ${DateTime.fromISO(dateTo).toFormat('LLLL dd, yyyy')}`}
            </Grid>
          ) : (
            <Grid item>
              <Skeleton variant="rounded" height="28px" width="347px" />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mb: theme.spacing(1) }} />
      <CoverLetterParagraph>Dear Health & Safety Manager,</CoverLetterParagraph>
      <CoverLetterParagraph>
        Thank you for giving us the opportunity to be a part of your Hearing
        Conservation Program and conducting the on-site portion of your annual
        employee industrial hearing testing. Summary reports and individual
        employee hearing evaluation letters in this document are provided for
        employees who received their annual hearing tests{' '}
        {dateFrom &&
        dateTo &&
        DateTime.fromISO(dateFrom).isValid &&
        DateTime.fromISO(dateTo).isValid
          ? `from ${DateTime.fromISO(dateFrom).toFormat(
              'LLLL dd, yyyy',
            )} to ${DateTime.fromISO(dateTo).toFormat('LLLL dd, yyyy')}`
          : ''}
        .
      </CoverLetterParagraph>
      <CoverLetterParagraph>
        If you have any questions, need any further information or need
        assistance with noise surveys or employee hearing conservation training,
        please do not hesitate to contact us.
      </CoverLetterParagraph>
      <CoverLetterParagraph>
        I look forward to working with you again next year.
      </CoverLetterParagraph>
      <CoverLetterParagraph>Sincerely,</CoverLetterParagraph>

      <SignatureButton
        disabled={loading || isSubmitting}
        buttonTitle="Add signature"
        buttonProps={{ endIcon: <AddIcon fontSize="inherit" /> }}
        onSubmit={async (signature: string) => {
          await setFieldValue('signature', signature);
          handleSubmit();
        }}
        onCancel={() => {
          setFieldValue('signature', '');
        }}
        signedDocument={values.signedDocument || null}
      />

      <CoverLetterParagraph>{`${user?.given} ${user?.family}`}</CoverLetterParagraph>

      <ResultRow
        title="Employees Tested"
        value={metrics?.participantsTestedTotal || 0}
        loading={loading}
      />
      <Divider sx={{ mb: 1 }} />
      <ResultRow
        title="Employees indicating STS"
        note={
          <div>
            Should be notified in writing within 21 days of receipt of this
            report and scheduled for retest within 30 days, unless shift is
            determined to be
            <br />
            <br />
            revisable or medical as per OSHA 29CFR 1910.95 STS = Standard
            Threshold Shift
          </div>
        }
        value={metrics?.participantsWithSTSShiftCountByGender.total || 0}
        loading={loading}
      />
      <Divider sx={{ mb: 1 }} />
      <ResultRow
        title="Employees with an OSHA Recordable Shift"
        note="It is the responsibility of the employer to record these recordable shifts on Form 300 within 7 days of a re- test or 37 days of test, if not previously recorded for this shift in hearing (see OSHA 1904.10 for details)."
        value={metrics?.participantsWithOSHAShiftCountByGender.total || 0}
        loading={loading}
      />
      <Divider sx={{ mb: 1 }} />
      <ResultRow
        title="Medical Referrals"
        note={
          <div>
            <ReferralsNote
              number="1"
              title="Hearing Test Result"
              subtitle="(I.e. Speech Freq. Avg. etc.)"
            />
            <ReferralsNote
              number="2"
              title="Medical History Form"
              subtitle="(i.e. Sudden Hearing loss/Otoscopic examination reveals totally occluded with wax or Possible infection)"
            />
          </div>
        }
        value={metrics?.medicalReferralsTotal || 0}
        loading={loading}
      />
      <Grid container gap={2} pt={3} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
        {!values.signedDocument && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading || isSubmitting}
              onClick={() => handleSaveCoverLetter(formik.values)}
            >
              Save as draft
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
