import { Grid, Typography } from '@mui/material';
import { AnswerType, QuestionChoice } from 'api/services/questionnaire/types';

interface OptionProps {
  index: number;
  choice: QuestionChoice;
  value?: AnswerType | null;
  handleChange: (answerValue: AnswerType) => void;
}

export function Option(props: OptionProps) {
  const { index, choice, value, handleChange } = props;
  const isSelected = value?.choiceId === choice.id;

  return (
    <Grid
      container
      gap={1}
      sx={{
        padding: '8px 16px',
        borderRadius: '8px',
        background: isSelected ? '#FFE86E' : 'transparent',
        border: '1px solid rgba(202, 194, 190, 0.30)',
        width: 'fit-content',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.13)',
        },
        '&:active, &:focus': {
          background: '#FFE86E',
        },
      }}
      onClick={() =>
        handleChange({
          choiceId: choice.id,
          choiceLabel: choice.label,
        })
      }
    >
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: '0 7px',
          background: isSelected ? '#FFFFFF' : 'rgba(255, 237, 143, 0.50)',
          border: isSelected
            ? '1px solid #FFE86E'
            : '1px solid rgba(202, 194, 190, 0.30)',
          width: 'fit-content',
          borderRadius: '2px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 700,
            lineHeight: '130%',
          }}
        >
          {index}
        </Typography>
      </Grid>

      <Typography
        variant="subtitle1"
        sx={{
          fontSize: '15px',
          lineHeight: '140%',
        }}
      >
        {choice.label}
      </Typography>
    </Grid>
  );
}
