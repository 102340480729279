import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React from 'react';

export function AccountManagerSignUpFormSkeleton() {
  const theme = useTheme();
  return (
    <Grid container spacing={theme.spacing(1)} maxWidth="sm">
      <Grid
        container
        item
        spacing={theme.spacing(1)}
        direction="row"
        justifyContent="space-between"
        width="100%"
      >
        <Grid item sm={12}>
          <Typography variant="body1" fontWeight="700" pb={theme.spacing(1)}>
            Personal info
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={theme.spacing(1)}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item sm={12}>
          <Typography variant="body1" fontWeight="700" pb={theme.spacing(1)}>
            Contact info
          </Typography>
        </Grid>

        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={theme.spacing(1)}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item sm={12}>
          <Typography variant="body1" fontWeight="700" pb={theme.spacing(1)}>
            Account info
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
        <Grid item sm={6} xs={12} mb={3}>
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <Skeleton variant="rounded" height={56} width={150} />
      </Grid>
    </Grid>
  );
}
