import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextFieldProps, TextField, styled, Grid } from '@mui/material';
import { useState } from 'react';

const StyledTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid rgba(202, 194, 190, 0.30)',
  },
});

const StyledPasswordTextField = styled(StyledTextField)({
  '& input': {
    paddingRight: '48px',
  },
});

export function TextInput(props: TextFieldProps) {
  const { type, ...restProps } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  if (type === 'password')
    return (
      <Grid sx={{ position: 'relative' }}>
        <StyledPasswordTextField
          type={isPasswordVisible ? 'text' : 'password'}
          InputProps={{
            fullWidth: true,
            sx: {
              paddingRight: 0,
            },
            endAdornment: (
              <Grid
                sx={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -42%)',
                  right: '16px',
                  cursor: 'pointer',
                  opacity: 0.6,
                }}
                onClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </Grid>
            ),
          }}
          {...restProps}
        />
      </Grid>
    );

  return (
    <StyledTextField
      onKeyDown={(event) => {
        if (
          type === 'integer' &&
          !/[0-9]/.test(event.key) &&
          !['Delete', 'Backspace', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(
            event.key,
          )
        ) {
          event.preventDefault();
        }
      }}
      type={type}
      {...restProps}
    />
  );
}
