import ct from 'countries-and-timezones';
import { DateTime } from 'luxon';

export const getUserTimezone = () => {
  const usTimeZones = ct.getTimezonesForCountry('US').map((tz) => tz.name);
  const userTimeZone = DateTime.now().toFormat('z');
  if (usTimeZones.includes(userTimeZone)) {
    return userTimeZone;
  }
  return 'America/New_York';
};
