import { IconButton, IconButtonProps } from '@mui/material';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useMemo } from 'react';
import { EToolbarButtonTypes } from '../types';
import { Tooltip } from '../../common/tooltip';

interface IToolbarButtonProps extends IconButtonProps {
  toolType: EToolbarButtonTypes;
  active?: boolean;
  tooltipTitle?: string;
}

export function ToolbarButton(props: IToolbarButtonProps) {
  const { toolType, active, tooltipTitle = '', ...rest } = props;
  const icon = useMemo(() => {
    if (toolType === EToolbarButtonTypes.Bold)
      return <FormatBoldIcon fontSize="inherit" />;
    if (toolType === EToolbarButtonTypes.Italic)
      return <FormatItalicIcon fontSize="inherit" />;
    if (toolType === EToolbarButtonTypes.Underlined)
      return <FormatUnderlinedIcon fontSize="inherit" />;
    if (toolType === EToolbarButtonTypes.UnorderedList)
      return <FormatListBulletedIcon fontSize="inherit" />;

    return null;
  }, [toolType]);
  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        sx={{
          backgroundColor: !active ? 'transparent' : 'rgba(255, 226, 193, 0.3)',
          width: '24px',
          height: '24px',
          fontSize: '20px',
          cursor: 'pointer',
          borderRadius: '4px',
          transition: '0.3s',
          ' &:hover': {
            backgroundColor: 'rgba(255, 226, 193, 0.3)',
          },
        }}
        {...rest}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
