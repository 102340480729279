import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { Select } from 'components/common/input';
import { IState } from '../../../../../api/services/state/types';

interface ParticipantFormProps {
  participant: ParticipantWithLastHearingTestResultViewItem;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ParticipantFormSchema = Yup.object().shape({
  externalClinic: Yup.string().required('Required'),
});

export function ExternalClinicDropdown(props: ParticipantFormProps) {
  const { participant, handleSubmit, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [externalClinics, setExternalClinics] = useState<IState[]>([]);

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const externalClinics =
          await api.organization.getExternalClinicsByLocationId(
            participant?.locationId as number,
            { limit: 100 },
          );
        setExternalClinics(externalClinics.items);
      } catch (e: any) {
        setError(
          e?.response?.data?.error ||
            e?.message ||
            'Something went wrong with fetching the initial data',
        );
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, []);

  const externalClinicsDropdownOptions = useMemo(
    () =>
      externalClinics?.map((state) => ({
        label: state.name,
        value: state.id,
      })) || [],
    [externalClinics],
  );

  const initialValues = {
    externalClinic:
      participant.clinicReferralParticipants[0]?.externalClinicId || '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);
      const shouldUpdate = participant.clinicReferralParticipants.length > 0;
      if (shouldUpdate) {
        await api.organization.updateClinicReferral(
          participant.locationId,
          participant.clinicReferralParticipants[0].id,
          participant.id,
          values.externalClinic,
        );
      } else
        await api.organization.createClinicReferral(
          participant.locationId,
          participant.id,
          values.externalClinic,
        );
      handleSubmit();
      showSnackbar(
        `Clinic referral successfully ${shouldUpdate ? 'updated' : 'added'}!`,
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ParticipantFormSchema}
      enableReinitialize
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title="Send to a clinic"
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid item xs={12}>
                <Select
                  fullWidth
                  options={externalClinicsDropdownOptions}
                  value={values.externalClinic}
                  label="External Clinic"
                  displayEmpty
                  onChange={(e) =>
                    setFieldValue('externalClinic', e.target.value)
                  }
                  errorMessage={
                    touched?.externalClinic && errors?.externalClinic
                      ? (errors?.externalClinic as string)
                      : ''
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {error ? (
                    <FormAlert severity="error">{error}</FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
