import { ChipProps, Grid, Typography } from '@mui/material';

interface IReferralsNoteProps extends ChipProps {
  number: string | number;
  title: string;
  subtitle?: string;
}

export function ReferralsNote(props: IReferralsNoteProps) {
  const { number, title, subtitle } = props;

  return (
    <Grid container gap={3} wrap="nowrap" pb={2}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#08161F',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          fontSize: '16px',
          fontWeight: 800,
          lineHeight: '120%',
          color: '#FFFFFF',
        }}
      >
        {number}
      </Grid>
      <Grid>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '120%',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '17px',
            fontWeight: 400,
            lineHeight: '140%',
          }}
        >
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
}
