import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import React, { useState } from 'react';
import { api } from 'api';
import {
  EReportTypeKeys,
  IHistoricalDocumentParams,
  ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS_WITHOUT_ALL,
} from 'api/services/organization/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { DatePicker, Select, TextInput } from 'components/common/input';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { FileUploadButton, SimpleButton } from '../../common/button';

interface UploadHistoricalDocumentFormProps {
  reportType?: Omit<
    EReportTypeKeys,
    EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
  >;
  locationId: number;
  handleClose: () => void;
  handleSubmit: () => void;
}

const UploadHistoricalDocumentFormSchema = Yup.object().shape({
  selectedReportType: Yup.string()
    .oneOf(_.values(EReportTypeKeys))
    .required('required'),
  name: Yup.string().required('required').max(200, ''),
  signatoryName: Yup.string().max(200, ''),
  isSigned: Yup.boolean(),
  signingDate: Yup.date().nullable().typeError('should be a valid date'),
  file: Yup.mixed().nullable().required('required'),
});

export function UploadHistoricalDocumentForm(
  props: UploadHistoricalDocumentFormProps,
) {
  const { handleSubmit, handleClose, reportType, locationId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initialValues: Omit<IHistoricalDocumentParams, 'signingDate'> & {
    selectedReportType:
      | Omit<
          EReportTypeKeys,
          EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
        >
      | '';
    signingDate: DateTime | null;
  } = {
    selectedReportType:
      reportType === EReportTypeKeys.ALL || !reportType
        ? EReportTypeKeys.MEDICAL_REFERRAL_LISTS
        : reportType,
    name: '',
    signatoryName: '',
    signingDate: null,
    file: null,
    isSigned: false,
  };

  const onSubmit = async (
    values: Omit<IHistoricalDocumentParams, 'signingDate'> & {
      selectedReportType:
        | Omit<
            EReportTypeKeys,
            EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
          >
        | '';
      signingDate: DateTime | null;
    },
  ) => {
    const {
      selectedReportType,
      signatoryName,
      signingDate,
      isSigned,
      name,
      file,
    } = values;

    const type = selectedReportType;

    if (!type) {
      return;
    }

    const body = new FormData();
    body.append('name', name);
    body.append('signatoryName', signatoryName);
    body.append('isSigned', String(isSigned));
    body.append('file', file as File);
    body.append(
      'signingDate',
      signingDate?.isValid ? String(signingDate.toISO()) : '',
    );
    try {
      setLoading(true);
      await api.organization.uploadHistoricalDocument(type, locationId, body);

      handleSubmit();
      showSnackbar(`Report successfully uploaded`, {
        variant: 'success',
      });
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={UploadHistoricalDocumentFormSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title="Upload a Report"
          isSubmitting={loading}
          fullWidth={false}
          PaperProps={{ sx: { width: '100%', maxWidth: '420px' } }}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    options={
                      ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS_WITHOUT_ALL
                    }
                    value={values.selectedReportType}
                    label="Report Type"
                    displayEmpty
                    onChange={(e) =>
                      setFieldValue('selectedReportType', e.target.value)
                    }
                    errorMessage={
                      touched?.selectedReportType && errors?.selectedReportType
                        ? (errors?.selectedReportType as string)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Report Name"
                    variant="outlined"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name ? errors.name : ' '}
                    margin="none"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isSigned"
                        onChange={(e) => {
                          const checked = e.currentTarget.checked;
                          setFieldValue('isSigned', checked);
                          if (!checked) {
                            setFieldValue('signatoryName', '');
                            setFieldValue('signingDate', null);
                          }
                        }}
                        checked={values.isSigned}
                      />
                    }
                    label="Signed"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Signatory Name"
                    variant="outlined"
                    type="text"
                    name="signatoryName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.signatoryName}
                    error={
                      touched.signatoryName && Boolean(errors.signatoryName)
                    }
                    helperText={
                      touched.signatoryName && errors.signatoryName
                        ? errors.signatoryName
                        : ' '
                    }
                    margin="none"
                    disabled={!values.isSigned}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={values.signingDate}
                    label="Signing Date"
                    onChange={(newDateFrom: DateTime | null) =>
                      setFieldValue('signingDate', newDateFrom)
                    }
                    errorMessage={
                      touched.signingDate && errors.signingDate
                        ? errors.signingDate
                        : ''
                    }
                    disabled={!values.isSigned}
                  />
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <FileUploadButton
                      onFilesChosen={(files) => {
                        setFieldValue('file', files);
                      }}
                      buttonProps={{
                        variant: 'contained',
                        color: 'secondary',
                      }}
                      inputProps={{
                        accept: '.pdf',
                      }}
                      withErrorMessage
                      errorMessage={errors.file ? errors.file : ''}
                    >
                      Choose a file
                    </FileUploadButton>
                  </Grid>
                  {values.file && (
                    <Grid
                      container
                      item
                      flexDirection="row"
                      flexWrap="nowrap"
                      gap={1}
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: '90%',
                        }}
                      >
                        {values.file.name}
                      </Typography>
                      <SimpleButton
                        actionType="delete"
                        onClick={() => setFieldValue('file', null)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      {error ? (
                        <FormAlert severity="error">{error}</FormAlert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
