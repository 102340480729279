import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React from 'react';

interface IMetaInfoItemProps {
  title: string;
  value?: string;
  loading: boolean;
}

export function MetaInfoItem(props: IMetaInfoItemProps) {
  const { title, value = '', loading } = props;

  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Typography
        sx={{
          fontSize: '13px',
          fontWeight: 300,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          textTransform: 'uppercase',
          marginRight: theme.spacing(2),
          minWidth: '200px',
        }}
        variant="mono"
      >
        {title}
      </Typography>
      {loading ? (
        <Skeleton variant="rounded" height="26px" width="100px" />
      ) : (
        <Typography
          sx={{
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '120%',
          }}
        >
          {value}
        </Typography>
      )}
    </Grid>
  );
}
