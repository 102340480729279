import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  PaperProps,
  Typography,
  styled,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/button';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleCancel?: () => void;
  handleSubmit: () => void;
  cancelButtonTitle?: string;
  cancelButtonIcon?: React.ReactNode;
  submitButtonTitle?: string;
  submitButtonIcon?: React.ReactNode;
  children?: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
  isSubmitting?: boolean;
  disableBackdropClick?: boolean;
  PaperProps?: PaperProps;
}

// DialogContent top padding is hard to override, so separate element is used
const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export function FormModal(props: ModalProps) {
  const { t } = useTranslation();
  const {
    open,
    handleClose,
    handleCancel,
    handleSubmit,
    cancelButtonTitle = t('common.cancel'),
    cancelButtonIcon,
    submitButtonTitle = t('common.submit'),
    submitButtonIcon,
    children,
    title = 'Modal',
    fullWidth = true,
    cancelDisabled,
    submitDisabled,
    isSubmitting,
    disableBackdropClick = true,
    PaperProps,
  } = props;
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={disableBackdropClick ? undefined : handleClose}
        PaperProps={PaperProps || {}}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton type="button" onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Content>{children}</Content>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Button
            type="submit"
            variant="text"
            color="primary"
            onClick={handleCancel || handleClose}
            disabled={isSubmitting || cancelDisabled}
            endIcon={cancelButtonIcon}
          >
            {cancelButtonTitle}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={isSubmitting || submitDisabled}
            endIcon={submitButtonIcon}
          >
            {submitButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
