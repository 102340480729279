import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import {
  ProfessionalSupervisorUser,
  User,
  UserInvitationStatuses,
} from 'api/services/auth/types';
import { PageHeader } from 'components/admin-page-layout';
import { useLocation } from 'hooks/useLocation';
import { useTranslation } from 'react-i18next';
import { loginAsUserRequest } from 'store/reducers/auth/actions';
import { useDispatch } from 'react-redux';
import { ProfessionalSupervisorForm } from './components/professional-supervisor-form';
import {
  getErrorMessage,
  getErrorMessageComponent,
  handleInvitationCopy,
  isEqualStatuses,
  showSnackbar,
} from '../../../utils';
import { Button } from '../../../components/common/button';
import { getCells } from './cells';

export function ProfessionalSupervisorsList() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [open, setOpen] = useState(false);
  const [editedProfessionalSupervisor, setEditedProfessionalSupervisor] =
    useState<ProfessionalSupervisorUser | null>(null);
  const { t, i18n } = useTranslation();

  const { location, isLocationLoading } = useLocation();

  const fetchProfessionalSupervisors = useCallback(
    async (limit: number, offset: number) =>
      api.user.getProfessionalSupervisors({
        limit,
        offset,
        locationId: locationId ? Number(locationId) : undefined,
      }),
    [],
  );
  const tableState = useTableState<ProfessionalSupervisorUser>({
    fetchDataFunction: fetchProfessionalSupervisors,
  });

  const handleSendInvitation = async (user: User) => {
    try {
      await api.user.sendProfessionalSupervisorInvitation(user.id);
      showSnackbar(t('common.invitationSuccessfullySent'), {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, t('common.couldNotSendInvitation')),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const actions = [
    {
      title: t('common.edit'),
      onClick: (professionalSupervisor: ProfessionalSupervisorUser) => {
        setEditedProfessionalSupervisor(professionalSupervisor);
        setOpen(true);
      },
    },
    {
      title: t('common.copyInvitationLink'),
      onClick: (professionalSupervisor: ProfessionalSupervisorUser) => {
        handleInvitationCopy(
          professionalSupervisor,
          'sign-up/professional-supervisor',
        );
      },
      disabled: (professionalSupervisor: ProfessionalSupervisorUser) =>
        isEqualStatuses(
          professionalSupervisor.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: t('common.sendInvitation'),
      onClick: (professionalSupervisor: ProfessionalSupervisorUser) => {
        handleSendInvitation(professionalSupervisor);
      },
      disabled: (professionalSupervisor: ProfessionalSupervisorUser) =>
        isEqualStatuses(
          professionalSupervisor.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: 'Login',
      onClick: (professionalSupervisor: ProfessionalSupervisorUser) => {
        dispatch(loginAsUserRequest({ userId: professionalSupervisor.id }));
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      {locationId ? (
        <PageHeader
          entityTitle={t('common.professionalSupervisors')}
          entityName={location?.name}
          titleLoading={isLocationLoading}
          buttons={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setEditedProfessionalSupervisor(null);
                setOpen(true);
              }}
            >
              {t('common.new')}
            </Button>
          }
        />
      ) : (
        <PageHeader
          title={t('common.professionalSupervisors')}
          buttons={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setEditedProfessionalSupervisor(null);
                setOpen(true);
              }}
            >
              {t('common.new')}
            </Button>
          }
        />
      )}
      <BasicTable<ProfessionalSupervisorUser>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && (
        <ProfessionalSupervisorForm
          professionalSupervisor={editedProfessionalSupervisor}
          locationId={locationId}
          handleClose={() => setOpen(false)}
          handleSubmit={() => {
            setOpen(false);
            setEditedProfessionalSupervisor(null);
            if (editedProfessionalSupervisor) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
    </BasicContainer>
  );
}
