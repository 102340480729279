import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { api } from 'api';
import { ILocation, Organization } from 'api/services/organization/types';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { FormModal } from 'components/modal';
import { useCallback, useState } from 'react';
import { IOption } from 'common/types/common';

interface AssignLocationFormProps {
  professionalSupervisorId: string;
  onSubmit: (locationId: number) => void;
  handleClose: () => void;
}

interface Values {
  organization: IOption | null;
  location: IOption | null;
}

const LocationFormSchema = Yup.object().shape({
  organization: Yup.object().required('Required'),
  location: Yup.object().required('Required'),
});

export function AssignLocationForm(props: AssignLocationFormProps) {
  const { professionalSupervisorId, onSubmit, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');

  const initialValues: Values = {
    organization: null,
    location: null,
  };

  const assignLocationToProfessionalSupervisor = useCallback(
    async (values: Values) => {
      if (!professionalSupervisorId) {
        return;
      }
      const locationId = Number(values.location?.value);
      try {
        setLoading(true);
        await api.organization.assignProfessionalSupervisorToLocation(
          locationId,
          professionalSupervisorId,
        );
        await onSubmit(locationId);
      } catch (e: any) {
        setError(getErrorMessage(e, 'Could not save form'));
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    },
    [professionalSupervisorId, onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={assignLocationToProfessionalSupervisor}
      validationSchema={LocationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={loading}
          title="Assign Locations to OHC"
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <AsyncAutocomplete<Organization>
                  fullWidth
                  label="Organization"
                  fetchMethod={(params) =>
                    api.organization.getOrganizations(params)
                  }
                  mapOptionMethod={(item: Organization) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('organization', newValue);
                    setFieldValue('location', null);
                  }}
                  value={values.organization}
                  onBlur={handleBlur}
                  error={touched.organization && Boolean(errors.organization)}
                  helperText={
                    touched.organization && errors.organization
                      ? errors.organization
                      : ' '
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <AsyncAutocomplete<ILocation>
                  fullWidth
                  label="Locations"
                  fetchMethod={(params) =>
                    api.organization.getLocations(
                      values.organization?.value || '',
                      params,
                    )
                  }
                  mapOptionMethod={(item: ILocation) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('location', newValue);
                  }}
                  value={values.location}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={
                    touched.location && (errors.location as string)
                      ? (errors.location as string)
                      : ' '
                  }
                  disabled={!values.organization?.value}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
