import { ButtonProps, Grid, Typography } from '@mui/material';
import React, { ReactNode, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'components/common/button';
import { TextInput } from 'components/common/input';

interface ISignatureButtonProps {
  disabled?: boolean;
  onSubmit: (signature: string) => void;
  onCancel?: () => void;
  signature: string;
  buttonProps?: ButtonProps;
  buttonTitle: ReactNode;
}

export function Signature(props: ISignatureButtonProps) {
  const {
    disabled,
    onSubmit,
    signature,
    buttonProps = {},
    buttonTitle,
    onCancel,
  } = props;

  const [inputTriggered, setInputTriggered] = useState(false);

  const submitHandler = useCallback((values: { signature: string }) => {
    onSubmit(values.signature);
  }, []);

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: { signature },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      signature: Yup.string()
        .required('Please, add your signature here')
        .max(200, 'limited to 200 characters'),
    }),
    onSubmit: submitHandler,
  });

  return (
    <Grid container>
      {!inputTriggered ? (
        <Button
          onClick={() => {
            if (disabled) {
              return;
            }
            setInputTriggered(true);
          }}
          {...buttonProps}
        >
          {signature ? (
            <Typography
              sx={{
                position: 'relative',
                display: 'inline-block',
                fontFamily: "'Square Peg', cursive",
                fontSize: '32px',
                fontWeight: 300,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {signature}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontFamily: "'Playball', cursive",
                fontSize: '18px',
                letterSpacing: '0.15px',
                opacity: 0.3,
              }}
            >
              {buttonTitle}
            </Typography>
          )}
        </Button>
      ) : (
        <Grid
          container
          gap={1}
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            background: '#ffffff',
            boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.13)',
            padding: 1,
            zIndex: 1,
          }}
        >
          <TextInput
            sx={{ width: '205px' }}
            size="small"
            type="text"
            {...getFieldProps('signature')}
            error={touched.signature && Boolean(errors.signature)}
            helperText={
              touched.signature && errors.signature ? errors.signature : ' '
            }
            disabled={isSubmitting}
          />
          <Button
            onClick={() => {
              setInputTriggered(false);
              handleSubmit();
            }}
            disabled={isSubmitting}
            sx={{ height: '40px', minWidth: '40px', paddingX: '6px' }}
          >
            <CheckIcon fontSize="small" />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setInputTriggered(false);
              resetForm();
              onCancel?.();
            }}
            disabled={isSubmitting}
            sx={{ height: '40px', minWidth: '40px', paddingX: '6px' }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
