import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAuthError } from 'store/reducers/auth/actions';

export const useCleanupAuthError = () => {
  const dispatch = useDispatch();
  // cleans up auth error before rendering component
  useEffect(() => {
    dispatch(setAuthError(null));
  }, []);
};
