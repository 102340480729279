import { useCallback, useEffect, useMemo, useState } from 'react';
import FileDownload from 'js-file-download';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid } from '@mui/material';
import { PdfPreviewModal } from 'components/modal';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { PageHeader } from 'components/admin-page-layout';
import { Participant } from 'api/services/auth/types';
import { IParticipantSignedReport } from 'api/services/participant/types';
import { getCells } from './cells';
import { getReportFileNameBySignedReport } from './utils';

type ReportsListProps = {
  participantId: number;
};

export function ParticipantSignedReports(props: ReportsListProps) {
  const { participantId } = props;
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [participantLoading, setParticipantLoading] = useState(true);
  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [openPdfPreview, setOpenPdfPreview] = useState(false);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!participantId) return;
      setParticipantLoading(true);
      try {
        const participantData = await api.participant.getParticipantById(
          participantId,
        );
        setParticipant(participantData);
        setParticipantLoading(false);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    };
    asyncRequest();
  }, [participantId]);

  const fetchReports = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
    ) => {
      if (!participantId) return null;

      try {
        const data = await api.participant.getParticipantSignedReports(
          Number(participantId),
          {
            limit,
            offset,
            orderBy,
            orderDirection,
          },
        );
        return data;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [participantId],
  );

  const tableState = useTableState<any>({
    fetchDataFunction: fetchReports,
  });

  const handleDownloadReportDocument = async (
    report: IParticipantSignedReport,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    if (!participant) {
      return;
    }

    try {
      const fileName = getReportFileNameBySignedReport(participant, report);
      const data = await api.participant.getParticipantReportPDF(
        participant.id,
        report.id,
      );

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const downloadReportPDF = async (report: IParticipantSignedReport) =>
    handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        FileDownload(file, fileName);
      },
    });

  const previewPdfReport = async (report: IParticipantSignedReport) => {
    setOpenPdfPreview(true);
    return handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const cells = useMemo(() => {
    if (!participant) {
      return [];
    }
    return getCells({
      participant,
      onDownload: downloadReportPDF,
      onPreview: previewPdfReport,
    });
  }, [participant]);

  return (
    <Grid>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />

      <PageHeader
        entityTitle="Signed reports"
        entityName={
          participant ? `${participant?.given} ${participant?.family}` : ''
        }
        titleLoading={participantLoading}
      />

      <BasicTable<IParticipantSignedReport>
        cells={cells}
        tableState={tableState}
        useQueryParams={false}
      />
    </Grid>
  );
}
