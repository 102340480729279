import { Checkbox } from '@mui/material';
import { User } from 'api/services/auth/types';
import { StatusCell, LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import i18next from 'i18next';

export const getCells = (): Cell<User>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.firstName'),
    key: 'given',
  },
  {
    name: i18next.t('common.lastName'),
    key: 'family',
  },
  {
    name: i18next.t('common.email'),
    key: 'email',
  },
  {
    name: i18next.t('common.state'),
    key: 'state',
    render: (item: User) => item.ohc?.state,
  },
  {
    name: i18next.t('ohcs.accreditationVerified'),
    key: 'accreditation',
    render: (item: User) => (
      <Checkbox
        size="small"
        sx={{ padding: 0 }}
        checked={item.ohc?.accreditation}
      />
    ),
  },
  {
    name: i18next.t('ohcs.backgroundCheck'),
    key: 'accreditation',
    render: () => <Checkbox size="small" sx={{ padding: 0 }} checked={false} />,
  },
  {
    name: i18next.t('common.activeSites'),
    key: 'locationsCount',
    render: (item: User) => (
      <LinkCell
        to={`/admin/ohcs/${item.id}/locations`}
        label={item.locationsCount || '0'}
      />
    ),
  },
  {
    name: i18next.t('common.status'),
    key: 'status',
    render: (item: User) => (
      <StatusCell
        status={!item.active ? 'deactivated' : item.invitationStatus}
      />
    ),
  },
];
