import { BasicContainer } from 'components/container';
import { useSearchParams } from 'react-router-dom';
import { ProfessionalSupervisorLicenseStatesForm } from '../../../../components/forms';

export function LicenseStates() {
  const [searchParams] = useSearchParams();
  return (
    <BasicContainer maxWidth="lg">
      <ProfessionalSupervisorLicenseStatesForm
        userId={Number(searchParams.get('userId'))}
      />
    </BasicContainer>
  );
}
