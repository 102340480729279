import { Typography, Dialog, Grid, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { IDateRange } from 'types/dataStructureTypes';
import { Button } from '../common/button';
import { DatePicker } from '../common/input';

interface DateRangePickerModalProps {
  opened: boolean;
  onClose: () => void;
  onSelect: (dateRange: IDateRange) => void;
  dateRange: IDateRange;
  title?: string;
}

export function DateRangePickerModal(props: DateRangePickerModalProps) {
  const {
    opened,
    onClose,
    onSelect,
    dateRange,
    title = 'Metrics Date Range',
  } = props;
  const [{ dateFrom, dateTo }, setInternalDateRange] = useState({
    ...dateRange,
  });
  const [dateFromError, setDateFromError] = useState('');
  const [dateToError, setDateToError] = useState('');

  const dateFromTimeReseted = dateFrom
    ? DateTime.fromJSDate(dateFrom.toJSDate()).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : null;
  const today = useMemo(
    () =>
      DateTime.now().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      }),
    [],
  );

  useEffect(() => {
    if (dateFromTimeReseted && dateTo && dateFromTimeReseted >= dateTo)
      return setDateFromError('Date From should not be later than Date To');
    if (dateFrom && dateFrom > today)
      return setDateFromError('Date From should not be in the future');
    return setDateFromError('');
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (dateFromTimeReseted && dateTo && dateFromTimeReseted >= dateTo)
      return setDateToError('Date To should not be earlier than Date From');
    if (dateTo && dateTo > today)
      return setDateToError('Date To should not be in the future');
    return setDateToError('');
  }, [dateTo, dateFrom]);

  useEffect(() => {
    setInternalDateRange({
      dateFrom:
        dateRange.dateFrom?.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }) || null,
      dateTo:
        dateRange.dateTo?.set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }) || null,
    });
  }, [dateRange, opened]);

  const theme = useTheme();
  return (
    <Dialog open={opened} onClose={onClose} maxWidth="xs" fullWidth>
      <Grid p={theme.spacing(3)}>
        <Grid mb={theme.spacing(2)}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid container direction="column">
          <Grid item mb={theme.spacing(1)}>
            <DatePicker
              value={dateFrom}
              label="From"
              onChange={(newDateFrom: DateTime | null) =>
                setInternalDateRange((prevState) => ({
                  ...prevState,
                  dateFrom:
                    newDateFrom?.set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    }) || dateRange.dateFrom,
                }))
              }
              disableFuture
              errorMessage={dateFromError}
            />
          </Grid>
          <Grid item mb={theme.spacing(1)}>
            <DatePicker
              value={dateTo}
              label="To"
              onChange={(newDateTo: DateTime | null) =>
                setInternalDateRange((prevState) => ({
                  ...prevState,
                  dateTo:
                    newDateTo?.set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    }) || dateRange.dateTo,
                }))
              }
              disableFuture
              errorMessage={dateToError}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" gap={theme.spacing(2)}>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={() => {
                  onSelect({ dateFrom, dateTo });
                  onClose();
                }}
                disabled={!!(dateFromError || dateToError)}
                sx={{
                  width: '100%',
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
