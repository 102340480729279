import { ReactNode, useRef, useState } from 'react';
import {
  Grid,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  toggleButtonGroupClasses,
  useTheme,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from 'components/common/button';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: 'rgba(202, 194, 190, 0.1)',
  borderRadius: '0 8px 8px 0',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    textTransform: 'none',
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.87) !important',
    padding: '2px 6px',
    borderRadius: '10px !important',
    margin: `${theme.spacing(0.4)} ${theme.spacing(1)}`,
    border: 0,
    '&:hover': {
      backgroundColor: 'rgba(202, 194, 190, 0.2)',
      cursor: 'pointer',
    },
  },
}));

type ToggleTableFilterProps<ValueT = string> = {
  options: { label: ReactNode; value: ValueT }[];
  value: ValueT;
  onChange: (value: ValueT) => void;
};

export function ToggleTableFilter<ValueT extends string>(
  props: ToggleTableFilterProps<ValueT>,
) {
  const { options, value, onChange } = props;
  const [expanded, setExpanded] = useState(true);

  const animationContainerRef = useRef(null);

  const theme = useTheme();

  return (
    <Grid container item flexDirection="row" alignItems="center">
      <Grid
        item
        sx={{
          py: theme.spacing(1.5),
        }}
      >
        <Button
          startIcon={<FilterListIcon sx={{ fontSize: '13px' }} />}
          sx={{
            borderRadius: expanded ? '8px 0 0 8px' : '8px',
            fontSize: '13px',
            textTransform: 'none',
            padding: '4px 8px',
            backgroundColor: 'rgba(202, 194, 190, 0.1)',
            '&:hover': {
              backgroundColor: 'rgba(202, 194, 190, 0.1)',
            },
          }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          Filter
        </Button>
      </Grid>
      <Grid item ref={animationContainerRef} sx={{ overflowX: 'hidden' }}>
        <Slide
          direction="right"
          in={expanded}
          container={animationContainerRef.current}
        >
          <StyledToggleButtonGroup
            value={value}
            exclusive
            onChange={(e, newValue) => newValue !== null && onChange(newValue)}
          >
            {options.map(({ label, value }) => (
              <ToggleButton value={value}>{label}</ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Slide>
      </Grid>
    </Grid>
  );
}
