import { useEffect, useMemo } from 'react';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { LazyBgGrid } from 'components/common/lazy-bg-grid';
import { Header } from 'components/header';
import { Heading } from 'components/common/heading';
import { useDispatch } from 'react-redux';
import { logoutUserRequest } from 'store/reducers/auth/actions';

const BGImage = '/images/backgrounds/verify/verify-bg.jpeg';
const BGImageMobile = '/images/backgrounds/verify/verify-bg-mobile.jpeg';

export function QuestionnaireSuccessScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));

  const imageUrl = useMemo(
    () => (isBelowSm ? BGImageMobile : BGImage),
    [isBelowSm],
  );

  useEffect(() => {
    dispatch(logoutUserRequest());
  }, []);

  return (
    <>
      <Header
        isEmpty
        logo={
          <img
            alt="logo"
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            width="88px"
          />
        }
      />
      <Grid
        container
        justifyContent="center"
        alignItems="self-start"
        wrap="nowrap"
        sx={{
          height: {
            xs: 'unset',
            sm: 'calc(100vh - 64px)',
          },
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
        }}
      >
        <Grid
          container
          xs={12}
          sm={6}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            height: '100%',
            padding: 2,
            paddingRight: {
              sx: 2,
              sm: 2,
              md: '70px',
            },
          }}
        >
          <Grid xs={12} md={8}>
            <Heading variant="h1" sx={{ fontSize: '40px' }}>
              Thank you for taking time to fill out this questionnaire!
            </Heading>
          </Grid>
        </Grid>

        <LazyBgGrid
          src={imageUrl}
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
            },
            height: {
              xs: '20vh',
              sm: '100%',
            },
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
          }}
        />
      </Grid>
    </>
  );
}
