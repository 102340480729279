import * as React from 'react';
import { AutocompleteProps, Autocomplete } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { PaginatedRequestParams } from 'api/types';
import { PaginatedData } from 'common/types';
import { IOption } from 'common/types/common';
import { TextInput } from '../input';

interface AsyncAutocompleteProps<T> {
  fetchMethod: (params: PaginatedRequestParams) => Promise<PaginatedData<T>>;
  mapOptionMethod: (item: any) => IOption;
  label: string;
  error?: boolean;
  helperText?: string;
  withHelperText?: boolean;
}

export function AsyncAutocomplete<T>({
  fetchMethod,
  mapOptionMethod,
  label,
  error,
  helperText = ' ',
  withHelperText = true,
  ...props
}: AsyncAutocompleteProps<T> &
  Partial<
    AutocompleteProps<
      IOption,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >
  >) {
  const [open, setOpen] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);

  const fetchOptions = async (search?: string) => {
    setAutocompleteLoading(true);
    try {
      const organizationsData = await fetchMethod(search ? { search } : {});
      setOptions(organizationsData.items.map(mapOptionMethod));
    } catch (e) {
      console.log(e);
    } finally {
      setAutocompleteLoading(false);
    }
  };

  const debounceSearch = useMemo(
    () =>
      _.debounce(
        (search?: string) => {
          fetchOptions(search);
        },
        500,
        { leading: false, trailing: true },
      ),
    [],
  );

  useEffect(() => {
    if (open) {
      fetchOptions();
    }
  }, [open]);

  return (
    <Autocomplete
      {...props}
      loading={autocompleteLoading}
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          variant="outlined"
          type="text"
          onChange={(event) => {
            if (!autocompleteLoading) setAutocompleteLoading(true);
            setOptions([]);
            debounceSearch(event.target?.value);
          }}
          error={error}
          helperText={withHelperText ? helperText : ''}
        />
      )}
      ListboxProps={{
        style: {
          maxHeight: '30vh',
        },
      }}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
    />
  );
}
