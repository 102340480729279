import { IQuestion, QuestionTypes } from 'api/services/questionnaire/types';

export const getEmptyAnswer = (question: IQuestion) => {
  switch (question.questionType) {
    case QuestionTypes.SINGLE_CHOICE:
      return {
        questionId: question.questionId,
        questionType: question.questionType,
        answer: null,
      };

    case QuestionTypes.TEXT:
      return {
        questionId: question.questionId,
        questionType: question.questionType,
        answer: null,
      };

    default:
      return {
        questionId: null,
        questionType: null,
        answer: null,
      };
  }
};
