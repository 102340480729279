import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { ParticipantHearingReportPage } from '../../../components/participant-hearing-report';

export function ParticipantReportPage() {
  const { participantId } = useParams();
  return (
    <BasicContainer>
      <ParticipantHearingReportPage participantId={Number(participantId)} />
    </BasicContainer>
  );
}
