import { Box, List, ListItemText, ListProps, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface IListCellProps extends ListProps {
  items: ReactNode[];
  ListItemSxProps?: SxProps;
}

export function ListCell(props: IListCellProps) {
  const { items, ListItemSxProps, ...restProps } = props;

  return (
    <List disablePadding {...restProps}>
      {items.map((item, index) => {
        if (typeof item === 'string' || typeof item === 'number') {
          return (
            <ListItemText
              disableTypography
              sx={{
                fontSize: 'inherit',
                margin: '0',
                ...ListItemSxProps,
              }}
              key={index}
            >
              {item}
            </ListItemText>
          );
        }
        return (
          <Box
            sx={{
              marginBottom: items.length > 1 ? '2px' : 0,
              ...ListItemSxProps,
            }}
            key={index}
          >
            {item}
          </Box>
        );
      })}
    </List>
  );
}
