import { BasicContainer } from 'components/container';
import { useNavigate, useParams } from 'react-router-dom';
import { useIdentity } from 'hooks/useIdentity';
import { PageHeader } from 'components/admin-page-layout';
import { useOrganization } from 'hooks/useOrganization';
import { OrganizationReportsContent } from './OrganizationReportContent';

export function OrganizationReportPage() {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );

  useIdentity();
  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Reports"
        entityName={organization?.name}
        titleLoading={isOrganizationLoading}
        onBackClick={() => navigate('/admin/organizations')}
      />
      <OrganizationReportsContent organizationId={Number(organizationId)} />
    </BasicContainer>
  );
}
