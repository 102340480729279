import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { Grid, Typography } from '@mui/material';
import { SimpleButton } from 'components/common/button';
import { useTranslation } from 'react-i18next';
import { ITimeError } from './types';
import { TimeSelect } from './TimeSelect';
import { TimePeriod } from './TimePeriod';

interface ITimeBoundariesRowProps {
  timePeriod: TimePeriod;
  timeErrors?: ITimeError;
  workingPeriods: TimePeriod[];
  disabled?: boolean;
  onTimePeriodDelete?: (key: string) => void | null;
  onTimePeriodAdd?: (keyOfPrevSibling: string) => void;
  onTimePeriodChange?: (
    newTimePeriod: Partial<TimePeriod>,
    key: string,
  ) => void;
  isFirstRow: boolean;
  readOnly?: boolean;
}

function TimeBoundariesRow({
  timePeriod,
  disabled,
  timeErrors,
  onTimePeriodAdd,
  onTimePeriodDelete,
  onTimePeriodChange,
  isFirstRow,
  readOnly,
}: ITimeBoundariesRowProps) {
  const { t } = useTranslation();
  const handleDelete = useCallback(() => {
    if (readOnly) return;
    onTimePeriodDelete?.(timePeriod.key);
  }, [onTimePeriodDelete, timePeriod]);

  const handleAdd = useCallback(() => {
    if (readOnly) return;
    onTimePeriodAdd?.(timePeriod.key);
  }, [onTimePeriodAdd, timePeriod, readOnly]);

  const handleChangeDate = (period: 'from' | 'to') => (date: DateTime) => {
    if (
      period === 'from' &&
      timePeriod?.to &&
      date.minute !== timePeriod?.to?.getMinutes()
    ) {
      const updatedToJsonDate = DateTime.fromJSDate(timePeriod?.to)
        ?.set({ minute: date.minute })
        ?.startOf('minute')
        .toJSDate();
      onTimePeriodChange?.({ to: updatedToJsonDate }, timePeriod.key);
    }
    let jsonDate = date?.startOf('minute').toJSDate();
    if (period === 'to') {
      if (date?.toFormat('HH:mm') === '00:00') {
        jsonDate = date
          ?.set({ hour: 23, minute: 59 })
          .startOf('minute')
          .toJSDate();
      }
    }

    onTimePeriodChange?.({ [period]: jsonDate }, timePeriod.key);
  };

  const getMinutesIncrement = () => {
    const minutes = timePeriod?.from?.getMinutes();
    if (!minutes) return '00';
    return `${minutes}`;
  };

  return (
    <Grid container alignItems="center" spacing={2} mb={1}>
      <Grid item>
        <TimeSelect
          value={timePeriod?.from}
          onChange={handleChangeDate('from')}
          error={timeErrors?.from}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item>
        <Typography>{t('common.to')}</Typography>
      </Grid>
      <Grid item>
        <TimeSelect
          value={
            timePeriod?.to &&
            DateTime.fromJSDate(timePeriod?.to).toFormat('HH:mm') === '23:59'
              ? DateTime.fromJSDate(timePeriod?.to)
                  .set({ hour: 0, minute: 0 })
                  .toJSDate()
              : timePeriod?.to
          }
          onChange={handleChangeDate('to')}
          minutesIncrement={getMinutesIncrement()}
          error={timeErrors?.to}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Grid>
      {isFirstRow && (
        <Grid item>
          <SimpleButton
            actionType="add"
            disabled={disabled}
            onClick={handleAdd}
          />
        </Grid>
      )}
      {!isFirstRow && (
        <Grid item>
          <SimpleButton
            actionType="delete"
            disabled={!onTimePeriodDelete || disabled}
            onClick={handleDelete}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default TimeBoundariesRow;
