import { ILocation } from 'api/services/organization/types';
import { Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Select, TextInput } from 'components/common/input';
import { useEffect, useMemo, useState } from 'react';
import { IState } from 'api/services/state/types';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { Button } from 'components/common/button';
import { useAuthSelector } from 'store/selectors/auth';
import { useDispatch } from 'react-redux';
import {
  requestSiteManagerLocationsAction,
  setSiteManagerSelectedLocation,
} from 'store/reducers/dashboard/site-manager/actions';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { getPhoneSchema } from 'utils/validation/validators';
import { convertPhoneFormFieldToTenDigitFormat } from 'utils/phoneNumber';
import {
  AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE_OPTIONS,
  AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED_OPTIONS,
  DEFAULT_AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE,
  DEFAULT_AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED,
} from '../../../../common/constants/organization-location';
import { HCPInfo } from './components';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  line1: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  phone: getPhoneSchema().optional(),
  amountOfMonthsNewEmployeeShouldBeTested: Yup.number().required('Required'),
  amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
    Yup.number().required('Required'),
});

interface Values {
  name: string;
  line1: string;
  state: string;
  postalCode: string;
  phone: string;
  amountOfMonthsNewEmployeeShouldBeTested: number;
  amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave: number;
}

interface MainInfoFormProps {
  location: ILocation | null;
}

export function MainInfoForm(props: MainInfoFormProps) {
  const { location } = props;
  const { user } = useAuthSelector();
  const dispatch = useDispatch();
  const [states, setStates] = useState<IState[]>([]);

  const initialValues = {
    name: location?.name || '',
    line1: location?.line1 || '',
    state: location?.state || '',
    postalCode: location?.postalCode || '',
    // TODO: phone in development
    phone: convertPhoneFormFieldToTenDigitFormat(location?.phone),
    amountOfMonthsNewEmployeeShouldBeTested:
      location?.amountOfMonthsNewEmployeeShouldBeTested ||
      DEFAULT_AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED,
    amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
      location?.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave ||
      DEFAULT_AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE,
  };

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const states = await api.state.getStates({ limit: 100 });
        setStates(states.items);
      } catch (e) {
        showSnackbar(
          getErrorMessageComponent(
            getErrorMessage(
              e,
              'Something went wrong with fetching the initial data',
            ),
          ),
          {
            variant: 'error',
          },
        );
      }
    };
    asyncRequest();
  }, []);

  const statesDropdownOptions = useMemo(
    () =>
      states?.map((state) => ({ label: state.name, value: state.name })) || [],
    [states],
  );

  const updateSiteManagerLocations = (location: ILocation) => {
    if (user?.id) dispatch(requestSiteManagerLocationsAction(user.id));
    dispatch(setSiteManagerSelectedLocation(location));
  };

  const handleSubmit = async (values: Values) => {
    if (!location?.id) {
      return;
    }

    try {
      const updatedLocation = await api.organization.updateLocation(
        location.id,
        values,
      );
      showSnackbar('Location successfully updated', {
        variant: 'success',
      });
      updateSiteManagerLocations(updatedLocation);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not save form')),
        {
          variant: 'error',
        },
      );
    }
  };

  const {
    getFieldProps,
    setFieldValue,
    submitForm,
    resetForm,
    dirty,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik<Values>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container xs={12} pb={1}>
        <HCPInfo
          HCPSupervisors={location?.organization.HCPSupervisors || null}
        />
      </Grid>
      <Grid container xs={12} pb={1}>
        <TextInput
          variant="standard"
          fullWidth
          label="Location name"
          type="text"
          {...getFieldProps('name')}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name ? errors.name : ' '}
          margin="none"
        />
      </Grid>
      <Grid item xs={12} pb={1}>
        <TextInput
          variant="standard"
          fullWidth
          label="Facility address"
          type="text"
          {...getFieldProps('line1')}
          error={touched.line1 && Boolean(errors.line1)}
          helperText={touched.line1 && errors.line1 ? errors.line1 : ' '}
          margin="none"
        />
      </Grid>

      <Grid item xs={12} container justifyContent="space-between" pb={1}>
        <Grid item xs={12} md={4}>
          <Select
            variant="standard"
            fullWidth
            label="State"
            options={statesDropdownOptions}
            displayEmpty
            {...getFieldProps('state')}
            onChange={(e) => setFieldValue('state', e.target.value)}
            errorMessage={touched.state && errors.state ? errors.state : ''}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <TextInput
            variant="standard"
            fullWidth
            label="Zip Code"
            type="text"
            {...getFieldProps('postalCode')}
            error={touched.postalCode && Boolean(errors.postalCode)}
            helperText={
              touched.postalCode && errors.postalCode ? errors.postalCode : ' '
            }
            margin="none"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} pb={1}>
        <PhoneInput
          label="Phone Number"
          {...getFieldProps('phone')}
          error={touched.phone && Boolean(errors.phone)}
          helperText={touched.phone && errors.phone ? errors.phone : ' '}
          margin="none"
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography>Program Settings</Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="space-between" pb={1}>
        <Grid item xs={12} md={5.5}>
          <Select
            variant="standard"
            fullWidth
            label="Test new employees within:"
            options={AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED_OPTIONS}
            displayEmpty
            {...getFieldProps('amountOfMonthsNewEmployeeShouldBeTested')}
            onChange={(e) =>
              setFieldValue(
                'amountOfMonthsNewEmployeeShouldBeTested',
                e.target.value,
              )
            }
            errorMessage={
              touched.amountOfMonthsNewEmployeeShouldBeTested &&
              errors.amountOfMonthsNewEmployeeShouldBeTested
                ? errors.amountOfMonthsNewEmployeeShouldBeTested
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Select
            variant="standard"
            fullWidth
            label="Test employees returning from disability leave within:"
            options={
              AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE_OPTIONS
            }
            displayEmpty
            {...getFieldProps(
              'amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave',
            )}
            onChange={(e) =>
              setFieldValue(
                'amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave',
                e.target.value,
              )
            }
            errorMessage={
              touched.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave &&
              errors.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave
                ? errors.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave
                : ''
            }
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} pb={1} gap={2}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={submitForm}
          disabled={!dirty || !isValid || isSubmitting}
        >
          Save
        </Button>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={() => resetForm()}
          disabled={!dirty || isSubmitting}
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
}
