import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DailySchedule, ITimeError } from './types';
import TimeBoundariesRow from './TimeBoundariesRow';
import { TimePeriod } from './TimePeriod';

interface ITimeSchedulingTableRowProps {
  title: string;
  dailySchedule: DailySchedule;
  timeErrors: { [key: string]: ITimeError };
  onTimePeriodAdd: () => void;
  onTimePeriodDelete: (key: string) => void;
  onTimePeriodChange: (
    updatedTimePeriod: Partial<TimePeriod>,
    key: string,
  ) => void;
  onChangeDayStatus: (isWorkingDay: boolean) => void;
  readOnly?: boolean;
}

export function ShiftCalendarRow(props: ITimeSchedulingTableRowProps) {
  const {
    title,
    dailySchedule,
    timeErrors,
    onTimePeriodAdd,
    onTimePeriodDelete,
    onTimePeriodChange,
    onChangeDayStatus,
    readOnly,
  } = props;
  const handleSwitchValueChange = (event: { target: { checked: any } }) => {
    if (readOnly) return;
    onChangeDayStatus(Boolean(event.target?.checked));
  };
  const { t } = useTranslation();

  return (
    <Grid
      container
      mb={1}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Typography fontSize={24} sx={{ width: '50px' }}>
        {t(`common.${title}`)}
      </Typography>
      <div>
        <Switch
          checked={dailySchedule.isWorkingDay}
          onChange={handleSwitchValueChange}
        />
      </div>
      <div>
        {dailySchedule.workingPeriods.map((timePeriod, index) => (
          <TimeBoundariesRow
            disabled={!dailySchedule.isWorkingDay}
            timeErrors={timeErrors[timePeriod.key]}
            timePeriod={timePeriod}
            onTimePeriodDelete={index === 0 ? undefined : onTimePeriodDelete}
            onTimePeriodAdd={onTimePeriodAdd}
            onTimePeriodChange={onTimePeriodChange}
            workingPeriods={dailySchedule.workingPeriods}
            isFirstRow={index === 0}
            readOnly={readOnly}
          />
        ))}
      </div>
    </Grid>
  );
}
