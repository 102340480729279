import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMemo, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
  EHearingTestResultTypes,
  HearingTestResult,
} from '../../../api/services/hearing-test-results/types';
import { Select } from '../../common/input';
import { Tooltip } from '../../common/tooltip';

interface ITestResultTypeCellProps {
  hearingTestResult: HearingTestResult;
  selectedType: null | EHearingTestResultTypes;
  onTypeChange?: (type: EHearingTestResultTypes) => any;
}

export function TestResultTypeCell(props: ITestResultTypeCellProps) {
  const { hearingTestResult, selectedType, onTypeChange } = props;

  const [selectOpened, setSelectOpened] = useState(false);

  const options = useMemo(
    () =>
      _.keys(EHearingTestResultTypes).map((key) => ({
        label: key,
        value:
          EHearingTestResultTypes[key as keyof typeof EHearingTestResultTypes],
      })),
    [],
  );

  const tooltipMessage = useMemo(() => {
    const mostRecentTypeChange = _.first(hearingTestResult.typeChangeLogs);
    if (mostRecentTypeChange && !selectOpened) {
      return (
        <Typography sx={{ fontSize: '9px', whiteSpace: 'break-spaces' }}>
          The type was changed by {mostRecentTypeChange.user?.given}{' '}
          {mostRecentTypeChange.user?.family} on{' '}
          {DateTime.fromISO(mostRecentTypeChange.created_at).toFormat(
            'MM/dd/yyyy',
          )}{' '}
          at{' '}
          {DateTime.fromISO(mostRecentTypeChange.created_at).toFormat('h:mm a')}
          <br />
          Reason: {mostRecentTypeChange.metadata.reason}
        </Typography>
      );
    }
    return '';
  }, [hearingTestResult, selectOpened]);

  return (
    <Tooltip title={tooltipMessage}>
      <Grid
        container
        alignItems="center"
        gap={1}
        justifyContent="center"
        flexWrap="nowrap"
      >
        {onTypeChange ? (
          <Grid item>
            <Select
              onOpen={() => setSelectOpened(true)}
              onClose={() => setSelectOpened(false)}
              withErrorMessage={false}
              onChange={(e: SelectChangeEvent<unknown>) => {
                onTypeChange(e.target.value as EHearingTestResultTypes);
              }}
              value={selectedType || hearingTestResult.type}
              renderValue={(value) => value as string}
              sx={{
                marginLeft: '28px',
                '& *': {
                  border: 'none !important',
                },
                '& input': {
                  height: 'auto',
                },
                '& .MuiSvgIcon-root': {
                  width: '16px',
                  height: '16px',
                  right: '-2px',
                  top: 'calc(50% - 8px)',
                },
                '& .MuiSelect-select': {
                  fontFamily: 'DM Mono',
                  padding: '0px',
                  paddingRight: '14px !important',
                  fontSize: '13px',
                  letterSpacing: '0.1px',
                  fontWeight: 300,
                },
              }}
              options={options}
              IconComponent={KeyboardArrowDownIcon}
            />
          </Grid>
        ) : (
          <Grid item sx={{ marginLeft: '14px' }}>
            {hearingTestResult.type}
          </Grid>
        )}
        <Grid item>
          <Box
            sx={{
              width: '6px',
              height: '6px',
              borderRadius: '3px',
              backgroundColor: hearingTestResult.typeChangeLogs?.length
                ? '#F4C871'
                : 'transparent',
            }}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
}
