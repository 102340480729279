import * as Yup from 'yup';

export const VALIDATION_SCHEMA = Yup.object().shape({
  audiometer: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  examiners: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('required')
        .min(1, 'required')
        .max(200, 'limited to 200 characters'),
      license: Yup.string()
        .required('required')
        .min(1, 'required')
        .max(200, 'limited to 200 characters'),
    }),
  ),
  name: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  serialNumber: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  annualCalibration: Yup.date()
    .typeError('invalid date')
    .nullable('required')
    .required('required'),
});
