import { Cell, HeaderCell } from 'components/table/types';
import { DateTime } from 'luxon';
import { HearingTestResult } from '../../../api/services/hearing-test-results/types';

export const cells: Cell<HearingTestResult>[] = [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Date',
    key: 'dateTime',
    render: (item) => (
      <span>
        <span>{DateTime.fromISO(item.dateTime).toFormat('MM/dd/yyyy')}</span>{' '}
        <br />{' '}
        <span>{DateTime.fromISO(item.dateTime).toFormat('h:mm a')} </span>
      </span>
    ),
  },
  {
    name: 'Participant',
    key: 'participant',
    render: (item) => (
      <span>
        <span>{item.participant.given}</span> <br />{' '}
        <span>{item.participant.family} </span>
      </span>
    ),
  },
  {
    name: 'Type',
    key: 'type',
  },
  {
    name: '500',
    key: 'threshold_left_500',
  },
  {
    name: '1k',
    key: 'threshold_left_1000',
  },
  {
    name: '2k',
    key: 'threshold_left_2000',
  },
  {
    name: '3k',
    key: 'threshold_left_3000',
  },
  {
    name: '4k',
    key: 'threshold_left_4000',
  },
  {
    name: '5k',
    key: 'threshold_left_5000',
  },
  {
    name: '6k',
    key: 'threshold_left_6000',
  },
  {
    name: '7k',
    key: 'threshold_left_7000',
  },
  {
    name: '8k',
    key: 'threshold_left_8000',
  },
  {
    name: '234',
    key: 'leftSTSFrequenciesAverage',
    render: (item) => Math.round(item.leftSTSFrequenciesAverage || 0),
  },
  {
    name: '500',
    key: 'threshold_right_500',
  },
  {
    name: '1k',
    key: 'threshold_right_1000',
  },
  {
    name: '2k',
    key: 'threshold_right_2000',
  },
  {
    name: '3k',
    key: 'threshold_right_3000',
  },
  {
    name: '4k',
    key: 'threshold_right_4000',
  },
  {
    name: '5k',
    key: 'threshold_right_5000',
  },
  {
    name: '6k',
    key: 'threshold_right_6000',
  },
  {
    name: '7k',
    key: 'threshold_right_7000',
  },
  {
    name: '8k',
    key: 'threshold_right_8000',
  },
  {
    name: '234',
    key: 'rightSTSFrequenciesAverage',
    render: (item) => Math.round(item.rightSTSFrequenciesAverage || 0),
  },
  {
    name: 'LT',
    key: 'leftSTS',
    render: (item) => Math.floor(item.leftSTS || 0),
  },
  {
    name: 'RT',
    key: 'rightSTS',
    render: (item) => Math.floor(item.rightSTS || 0),
  },
];

export const headerGroupingCells: HeaderCell[] = [
  {
    name: '',
    colSpan: 4,
    key: 'generic_test_data',
  },
  {
    name: 'Left Ear',
    colSpan: 10,
    key: 'left_ear',
  },
  {
    name: 'Right Ear',
    colSpan: 10,
    key: 'right_ear',
  },
  {
    name: 'Shift',
    colSpan: 2,
    key: 'shift',
  },
];
