import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { BasicContainer } from '../../../../components/container';
import { PageHeader } from '../../../../components/admin-page-layout';
import { useLocation } from '../../../../hooks/useLocation';
import { MetaInfoItem, Signature } from './components';
import { cells } from './cells';
import BasicTable from '../../../../components/table/BasicTable';
import { HearingTestResult } from '../../../../api/services/hearing-test-results/types';
import { EMPTY_PAGINATED_DATA } from '../../../../common/types';
import { api } from '../../../../api';
import { useTableState } from '../../../../hooks/useTableState';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { ISignedDocument } from '../../../../api/services/organization/types';

export function ProfessionalSupervisorSignOSHAPage() {
  const { locationId } = useParams();
  const [queryParams] = useSearchParams();
  const { location } = useLocation();
  const dateFrom = useMemo(() => queryParams.get('dateFrom'), [queryParams]);
  const dateTo = useMemo(() => queryParams.get('dateTo'), [queryParams]);

  const fetchOSHARecordableShiftReports = useCallback(async () => {
    if (
      !locationId ||
      !(
        dateFrom &&
        dateTo &&
        DateTime.fromISO(dateFrom).isValid &&
        DateTime.fromISO(dateTo).isValid
      )
    ) {
      return EMPTY_PAGINATED_DATA;
    }
    return api.organization.getOSHARecordableShiftReportsPreviewParams(
      locationId,
      {
        dateFrom: DateTime.fromISO(dateFrom).toISO() || '',
        dateTo: DateTime.fromISO(dateTo).endOf('day').toISO() || '',
      },
    );
  }, [locationId]);

  const tableState = useTableState<HearingTestResult>({
    fetchDataFunction: fetchOSHARecordableShiftReports,
  });

  const [signedDocument, setSignedDocument] = useState<ISignedDocument | null>(
    null,
  );

  const documentViewingDateTime = useMemo(
    () => DateTime.now().toISO() as string,
    [],
  );

  const [reportName, setReportName] = useState('');

  useEffect(() => {
    if (dateFrom && dateTo) {
      setReportName(
        `OSHA Recordable STS (${DateTime.fromISO(dateFrom).toFormat(
          'MM/dd/yyyy',
        )} - ${DateTime.fromISO(dateTo).toFormat('MM/dd/yyyy')})`,
      );
    }
  }, [dateFrom, dateTo]);

  const reportNameError = useMemo(() => {
    if (!reportName) return 'required';
    if (reportName.length > 200) return 'limited to 200 characters';
    return '';
  }, [reportName]);

  const handleSign = useCallback(
    async (signature: string) => {
      if (reportNameError) return;
      try {
        const document = await api.organization.signOSHARecordableSTS(
          Number(locationId),
          {
            dateFrom: dateFrom as string,
            dateTo: dateTo as string,
            signature,
            documentViewingDateTime,
            name: reportName,
          },
        );
        setSignedDocument(document);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [
      locationId,
      dateFrom,
      dateTo,
      documentViewingDateTime,
      reportName,
      reportNameError,
    ],
  );

  return (
    <BasicContainer sx={{ paddingBottom: '32px' }}>
      <PageHeader
        title={`OSHA Recordable STS ${location && `of ${location?.name}`} ${
          dateFrom &&
          dateTo &&
          DateTime.fromISO(dateFrom).isValid &&
          DateTime.fromISO(dateTo).isValid
            ? `(${DateTime.fromISO(dateFrom).toFormat(
                'MM/dd/yyyy',
              )} - ${DateTime.fromISO(dateTo).toFormat('MM/dd/yyyy')})`
            : ''
        }`}
      />

      <Grid
        sx={{
          margin: { xs: '16px 4px', sm: '8px 16px' },
          padding: { xs: '8px', sm: '24px' },

          border: '1px solid black',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          gap={2}
          alignItems="center"
          sx={{
            mb: '40px',
          }}
        >
          <img
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            alt="logo"
            width="171px"
            height="64px"
          />
          <Typography
            variant="mono"
            sx={{
              fontSize: '16px',
              fontWeight: 300,
              textTransform: 'uppercase',
            }}
          >
            {`OSHA Recordable STS ${
              dateFrom &&
              dateTo &&
              DateTime.fromISO(dateFrom).isValid &&
              DateTime.fromISO(dateTo).isValid
                ? `(${DateTime.fromISO(dateFrom).toFormat(
                    'MM/dd/yyyy',
                  )} - ${DateTime.fromISO(dateTo).toFormat('MM/dd/yyyy')})`
                : ''
            }`}
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between">
          <Grid
            container
            item
            direction="column"
            sx={{ width: { xs: '100%', sm: '50%' } }}
          >
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Period"
                loading={
                  !(
                    dateFrom &&
                    dateTo &&
                    DateTime.fromISO(dateFrom).isValid &&
                    DateTime.fromISO(dateTo).isValid
                  )
                }
                value={`${DateTime.fromISO(dateFrom || '').toFormat(
                  'MM/dd/yyyy',
                )} to ${DateTime.fromISO(dateTo || '').toFormat('MM/dd/yyyy')}`}
              />
            </Grid>
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Company"
                loading={!location}
                value={location?.organization?.name || '-'}
              />
            </Grid>
            <Grid item sx={{ mb: '8px' }}>
              <MetaInfoItem
                title="Location"
                loading={!location}
                value={location?.name || '-'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '110%',
            margin: '32px 0',
          }}
        >
          Employees With Recordable Shift
        </Typography>

        <BasicTable<HearingTestResult>
          cells={cells}
          tableState={tableState}
          isPagination={false}
          tableHeaderCellSxProps={{
            padding: 0,
          }}
          tableBodyCellSxProps={{
            padding: '8px',
          }}
        />

        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '110%',
            letterSpacing: '-1px',
            marginBottom: '16px',
            margin: '32px 0',
          }}
        >
          Please be advised
        </Typography>

        <Typography
          sx={{
            marginBottom: '16px',
          }}
        >
          Please be advised that the individual(s) above show a recordable shift
          in hearing.
        </Typography>
        <Typography
          sx={{
            marginBottom: '16px',
          }}
        >
          In accordance with OSHA&apos;s new ruling on occupational hearing loss
          (rule 1904.10) the employer is required to report on form 300 the
          above Standard Threshold Shift (STS) within 7 days of a re-test or 37
          days of test, if a retest is not conducted. A re-test must be
          conducted within 30 days of the original test. As a reminder it is
          required that you re-fit & retrain these individuals in the use of
          their appropriate hearing protection.
        </Typography>
        <Typography
          sx={{
            marginBottom: '16px',
          }}
        >
          If you have any questions, or need any further information, please do
          not hesitate to contact Tuned.
        </Typography>

        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '110%',
            margin: '32px 0',
          }}
        >
          Reviewing Audiologist
        </Typography>
        <Signature
          onSubmit={handleSign}
          signedDocument={signedDocument}
          disabled={!tableState.data?.length || !!reportNameError}
        />
      </Grid>
    </BasicContainer>
  );
}
