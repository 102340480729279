import i18next from 'i18n/config';
import { ILocation } from 'api/services/organization/types';
import { LinkCell } from 'components/table/components/LinkCell';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<ILocation>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.name'),
    key: 'name',
  },
  {
    name: i18next.t('common.externalId'),
    key: 'externalId',
  },
  {
    name: i18next.t('common.state'),
    key: 'state',
  },
  {
    name: i18next.t('common.participants'),
    key: 'participantsCount',
    render: (item: ILocation) => (
      <LinkCell
        to={`/account-manager/organizations/${item.managingOrganization}/locations/${item.id}/participants`}
        label={item.participantsCount || '0'}
      />
    ),
  },
  {
    name: 'Completed in the last 12 months %',
    key: 'participantsTestedLastYearCount',
    align: 'center',
    render: (item: ILocation) =>
      `${
        Math.round(
          (item.participantsTestedLastYearCount / item.participantsCount) * 100,
        ) || 0
      }%`,
  },
  {
    name: 'Tests Needed',
    key: 'participantsWithSTSCount',
    align: 'center',
    render: (item: ILocation) =>
      item.participantsCount - item.participantsTestedLastYearCount || '0',
  },
  {
    name: 'Employees with STS',
    key: 'participantsWithSTSCount',
    align: 'center',
    render: (item: ILocation) =>
      `${
        Math.round(
          (item.participantsWithSTSCount / item.participantsCount) * 100,
        ) || 0
      }%`,
  },
  {
    name: 'OSHA Recordable Shift',
    key: 'participantsWithRecordableOSHAShiftCount',
    align: 'center',
    render: (item: ILocation) =>
      `${
        Math.round(
          (item.participantsWithRecordableOSHAShiftCount /
            item.participantsCount) *
            100,
        ) || 0
      }%`,
  },
  {
    name: i18next.t('common.professionalSupervisors'),
    key: 'professionalSupervisor',
    render: (item: ILocation) => (
      <div>{item.professionalSupervisorsCount || '0'}</div>
      // TODO: feature in development
      // <LinkCell
      //   to={`/admin/organizations/${item.managingOrganization}/locations/${item.id}/professional-supervisors`}
      //   label={item.professionalSupervisorsCount || '0'}
      // />
    ),
  },
  {
    name: i18next.t('common.siteManagers'),
    key: 'siteManager',
    render: (item: ILocation) => (
      <div>{item.siteManagersCount || '0'}</div>
      // TODO: feature in development
      // <LinkCell
      //   to={`/admin/organizations/${item.managingOrganization}/locations/${item.id}/site-managers`}
      //   label={item.siteManagersCount || '0'}
      // />
    ),
  },
  {
    name: i18next.t('common.shifts'),
    key: 'shifts',
    render: (item: ILocation) => (
      <div>{item.shiftsCount || '0'}</div>
      // TODO: feature in development
      // <LinkCell
      //   to={`/admin/organizations/${item.managingOrganization}/locations/${item.id}/shifts`}
      //   label={item.shiftsCount || '0'}
      // />
    ),
  },
  {
    name: i18next.t('common.visits'),
    key: 'visitsCount',
    render: (item: ILocation) => (
      <div>{item.visitsCount || '0'}</div>
      // TODO: feature in development
      // <LinkCell
      //   to={`/admin/organizations/${item.managingOrganization}/locations/${item.id}/visits`}
      //   label={item.visitsCount || '0'}
      // />
    ),
  },
];
