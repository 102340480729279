import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { useMemo } from 'react';
import { Grid, useTheme } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { HCPSupervisorLocationsList } from './components/hcp-supervisor-locations';
import { ITab } from '../../components/tabs/dashboard-tabs/types';
import { DashboardTabs } from '../../components/tabs';
import { useAuthSelector } from '../../store/selectors/auth';
import { HCPSupervisorLocationMetrics } from './components/hcp-supervisor-location-metrics';
import { HCPSupervisorReports } from './components/hcp-supervisor-reports';

export function HCPSupervisorDashboard() {
  useIdentity();
  const { user } = useAuthSelector();
  const tabs = useMemo<ITab[]>(
    () => [
      {
        label: 'Locations',
        component: <HCPSupervisorLocationsList />,
        icon: <LocationOnIcon fontSize="small" />,
      },
      {
        label: 'Reports',
        component: <HCPSupervisorReports />,
        icon: <InsertDriveFileOutlined fontSize="small" />,
      },
      {
        label: 'Metrics',
        component: <HCPSupervisorLocationMetrics />,
        icon: <EqualizerIcon fontSize="small" />,
      },
    ],
    [],
  );

  const theme = useTheme();
  return (
    <BasicContainer>
      <DashboardTabs
        tabs={tabs}
        title={
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sx={{
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                margin: { xs: '0 auto', sm: '0' },
              }}
            >
              {user?.HCPSupervisor?.organizations?.[0] ? (
                <>
                  <Grid item>
                    {user?.HCPSupervisor?.organizations?.[0]?.name}
                  </Grid>{' '}
                  <Grid item mx={theme.spacing(1)}>
                    <img
                      alt="x"
                      src="/images/icons/x_icon_24.svg"
                      width="20px"
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item mt="10px">
                <img
                  alt="logo"
                  src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
                  width="100px"
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </BasicContainer>
  );
}
