import { ElementType, useRef, useState } from 'react';
import {
  ButtonProps,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { Button } from './Button';

type DropdownButtonProps<C extends ElementType, ValueT> = {
  options: {
    value: ValueT;
    title: string;
    handleClick: (value: ValueT) => void;
  }[];
} & Omit<ButtonProps<C, { component?: C }>, 'children' | 'component'>;

export function DropdownButton<C extends ElementType, ValueT extends string>(
  props: DropdownButtonProps<C, ValueT>,
) {
  const { options, ...rest } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid ref={anchorRef}>
        <Button onClick={handleToggle} {...rest} />
      </Grid>

      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.title}
                      onClick={() => {
                        option.handleClick(option.value);
                        setOpen(false);
                      }}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
