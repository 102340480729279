import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { DashboardTabs } from 'components/tabs';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMemo } from 'react';
import { ITab } from 'components/tabs/dashboard-tabs/types';
import { OhcLocationsList } from './ohc-locations';
import { OhcLocationReportsPage } from './ohc-location-reports';
import { useAuthSelector } from '../../store/selectors/auth';

export function OhcDashboard() {
  const authState = useAuthSelector();
  const [queryParams] = useSearchParams();
  useIdentity();

  const tabs = useMemo<ITab[]>(
    () => [
      {
        label: 'Locations',
        component: <OhcLocationsList />,
        icon: <LocationOnIcon fontSize="small" />,
      },
    ],
    [],
  );

  if (
    authState.user?.ohc?.locations?.length &&
    !tabs.filter((tab) => tab.label === 'Reports').length
  ) {
    tabs.push({
      label: 'Reports',
      component: <OhcLocationReportsPage />,
      icon: <InsertDriveFileOutlined fontSize="small" />,
    });
  }

  const pageTitle = useMemo(() => {
    const tabIndex = queryParams.get('tabIndex') || '0';
    return tabs[Number(tabIndex)].label;
  }, [queryParams]);

  return (
    <BasicContainer>
      <DashboardTabs
        tabs={tabs}
        title={
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sx={{
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                margin: { xs: '0 auto', sm: '0' },
              }}
            >
              {pageTitle}
            </Grid>
          </Grid>
        }
      />
    </BasicContainer>
  );
}
