import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { ILocation } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { PageHeader } from 'components/admin-page-layout';
import { getCells } from './cells';

export function AdminExternalClinicLocationsList() {
  const { externalClinicId } = useParams();
  const [externalClinicName, setExternalClinicName] = useState('');
  const [externalClinicNameLoading, setExternalClinicNameLoading] =
    useState(true);

  const getExternalClinicName = async () => {
    if (!externalClinicId) {
      return;
    }
    setExternalClinicNameLoading(true);
    try {
      const { name } = await api.externalClinic.getExternalClinicById(
        externalClinicId,
      );
      setExternalClinicName(name);
      setExternalClinicNameLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getExternalClinicName();
  }, [externalClinicId]);

  const fetchExternalClinicLocations = useCallback(
    async (limit: number, offset: number) => {
      if (!externalClinicId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.externalClinic.getExternalClinicLocations(
        externalClinicId,
        {
          limit,
          offset,
        },
      );
      return locationsData;
    },
    [externalClinicId],
  );

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchExternalClinicLocations,
  });

  const cells = useMemo(() => getCells(), [externalClinicId]);

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Locations"
        entityName={externalClinicName}
        titleLoading={externalClinicNameLoading}
      />

      <BasicTable<ILocation> cells={cells} tableState={tableState} />
    </BasicContainer>
  );
}
