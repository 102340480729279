import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { ILocation } from 'api/services/organization/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { Select, TextInput } from 'components/common/input';
import { IState } from 'api/services/state/types';
import { IQuestionnaire } from 'api/services/questionnaire/types';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'components/common/input/PhoneInput';
import {
  AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE_OPTIONS,
  AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED_OPTIONS,
  DEFAULT_AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE,
  DEFAULT_AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED,
} from '../../../common/constants/organization-location';

interface LocationFormProps {
  organizationId?: string | number;
  location?: ILocation | null;
  validationSchema?: Yup.Schema;
  disabledFields?: string[];
  handleClose: () => void;
  handleSubmit: () => void;
}

export function LocationForm(props: LocationFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [states, setStates] = useState<IState[]>([]);
  const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>([]);
  const { t } = useTranslation();

  const {
    organizationId,
    location,
    handleSubmit,
    handleClose,
    validationSchema,
    disabledFields = [],
  } = props;

  const statesDropdownOptions = useMemo(
    () =>
      states?.map((state) => ({ label: state.name, value: state.name })) || [],
    [states],
  );
  const questionnairesDropdownOptions = useMemo(
    () =>
      questionnaires?.map((questionnaire) => ({
        label: questionnaire.resourceType,
        value: questionnaire.resourceType,
      })) || [],
    [questionnaires],
  );

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const states = await api.state.getStates({ limit: 100 });
        setStates(states.items);
        const questionnaires = await api.questionnaire.getQuestionnaires();
        setQuestionnaires(questionnaires);
      } catch (e) {
        console.log(e);
      }
    };
    asyncRequest();
  }, []);

  const initialValues = location
    ? {
        name: location.name,
        externalId: location.externalId,
        line1: location.line1,
        line2: location.line2,
        country: location.country,
        state: location.state,
        postalCode: location.postalCode,
        city: location.city,
        amountOfMonthsNewEmployeeShouldBeTested:
          location.amountOfMonthsNewEmployeeShouldBeTested,
        amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
          location.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave,
        questionnaireResourceType:
          location.questionnaireResourceType || 'ParticipantsQuestionnaire',
        phone: location.phone,
      }
    : {
        name: '',
        externalId: '',
        line1: '',
        line2: '',
        country: '',
        state: '',
        postalCode: '',
        city: '',
        amountOfMonthsNewEmployeeShouldBeTested:
          DEFAULT_AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED,
        amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
          DEFAULT_AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE,
        questionnaireResourceType: '',
        phone: '',
      };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      if (location) {
        await api.organization.updateLocation(location.id, values);
      } else
        await api.organization.createLocation(Number(organizationId), values);

      handleSubmit();
      showSnackbar(
        location
          ? t('common.locationSuccessfullyUpdated')
          : t('common.locationSuccessfullyAdded'),
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(
        e?.response?.data?.error || e?.message || t('common.couldNotSaveForm'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        getFieldProps,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            location ? t('common.editLocation') : t('common.createLocation')
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.name')}
                    variant="outlined"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name ? errors.name : ' '}
                    margin="none"
                    disabled={disabledFields.includes('name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.externalId')}
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                    disabled={disabledFields.includes('externalId')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.addressLine1')}
                    variant="outlined"
                    type="text"
                    name="line1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.line1}
                    error={touched.line1 && Boolean(errors.line1)}
                    helperText={
                      touched.line1 && errors.line1 ? errors.line1 : ' '
                    }
                    margin="none"
                    disabled={disabledFields.includes('line1')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.addressLine2')}
                    variant="outlined"
                    type="text"
                    name="line2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.line2}
                    error={touched.line2 && Boolean(errors.line2)}
                    helperText={
                      touched.line2 && errors.line2 ? errors.line2 : ' '
                    }
                    margin="none"
                    disabled={disabledFields.includes('line2')}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.city')}
                    variant="outlined"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city ? errors.city : ' '}
                    margin="none"
                    disabled={disabledFields.includes('city')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label={t('common.state')}
                    options={statesDropdownOptions}
                    placeholder={t('common.state')}
                    onChange={(e) => setFieldValue('state', e.target.value)}
                    onBlur={handleBlur}
                    value={values.state}
                    errorMessage={
                      touched.state && errors.state ? errors.state : ''
                    }
                    disabled={disabledFields.includes('state')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.country')}
                    variant="outlined"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={touched.country && Boolean(errors.country)}
                    helperText={
                      touched.country && errors.country ? errors.country : ' '
                    }
                    margin="none"
                    disabled={disabledFields.includes('country')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label={t('common.postalCode')}
                    variant="outlined"
                    type="text"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    error={touched.postalCode && Boolean(errors.postalCode)}
                    helperText={
                      touched.postalCode && errors.postalCode
                        ? errors.postalCode
                        : ' '
                    }
                    margin="none"
                    disabled={disabledFields.includes('postalCode')}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label={t('common.questionnaireResourceType')}
                    options={questionnairesDropdownOptions}
                    placeholder={t('common.questionnaireResourceType')}
                    onChange={(e) =>
                      setFieldValue('questionnaireResourceType', e.target.value)
                    }
                    onBlur={handleBlur}
                    value={values.questionnaireResourceType}
                    errorMessage={
                      touched.questionnaireResourceType &&
                      errors.questionnaireResourceType
                        ? errors.questionnaireResourceType
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>
              <Grid px={{ xs: 0, sm: 2 }} mb={2} width="100%">
                <Typography>Program Settings</Typography>
              </Grid>
              <Grid container item xs={12} sm={6} spacing={1}>
                {' '}
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label="Test new employees within:"
                    options={
                      AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED_OPTIONS
                    }
                    displayEmpty
                    {...getFieldProps(
                      'amountOfMonthsNewEmployeeShouldBeTested',
                    )}
                    onChange={(e) =>
                      setFieldValue(
                        'amountOfMonthsNewEmployeeShouldBeTested',
                        e.target.value,
                      )
                    }
                    errorMessage={
                      touched.amountOfMonthsNewEmployeeShouldBeTested &&
                      errors.amountOfMonthsNewEmployeeShouldBeTested
                        ? errors.amountOfMonthsNewEmployeeShouldBeTested
                        : ''
                    }
                    disabled={disabledFields.includes(
                      'amountOfMonthsNewEmployeeShouldBeTested',
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label="Test employees returning from disability leave within:"
                    options={
                      AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE_OPTIONS
                    }
                    displayEmpty
                    {...getFieldProps(
                      'amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave',
                    )}
                    onChange={(e) =>
                      setFieldValue(
                        'amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave',
                        e.target.value,
                      )
                    }
                    errorMessage={
                      touched.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave &&
                      errors.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave
                        ? errors.amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave
                        : ''
                    }
                    disabled={disabledFields.includes(
                      'amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave',
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {error ? (
                    <FormAlert severity="error">{error}</FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
