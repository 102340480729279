import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { User, UserInvitationStatuses } from 'api/services/auth/types';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { Button } from 'components/common/button';
import { useTableState } from 'hooks/useTableState';
import { PageHeader } from 'components/admin-page-layout';
import { TableFilter } from 'components/table-filter';
import { AdminForm } from './components/admin-form';
import { handleInvitationCopy, isEqualStatuses } from '../../../utils';
import { getCells } from './cells';
import { ChangeAdminStatusModal } from './components/change-status';
import { getFilterConfig } from './filter.config';

export function AdminList() {
  const [open, setOpen] = useState(false);
  const [openConfirmChangeStatusModal, setOpenConfirmChangeStatusModal] =
    useState(false);
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const fetchAdmins = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy?: string,
      orderDirection?: 'ASC' | 'DESC',
      search?: string,
      filterParams?: any,
    ) => {
      const adminsData = await api.admin.getAdmins({
        limit,
        offset,
        orderBy,
        orderDirection,
        search,
        filterParams,
      });

      return adminsData;
    },
    [],
  );
  const tableState = useTableState<User>({ fetchDataFunction: fetchAdmins });
  const { t, i18n } = useTranslation();

  const actions = [
    {
      title: t('common.edit'),
      onClick: (user: User) => {
        setEditedUser(user);
        setOpen(true);
      },
    },
    {
      title: (user: User) =>
        user.active ? t('common.deactivate') : t('common.activate'),
      onClick: (user: User) => {
        setEditedUser(user);
        setOpenConfirmChangeStatusModal(true);
      },
      disabled: (user: User) => !user.active,
    },
    {
      title: t('common.copyInvitationLink'),
      onClick: (user: User) => {
        handleInvitationCopy(user);
      },
      disabled: (user: User) =>
        isEqualStatuses(user.invitationStatus, UserInvitationStatuses.Active),
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      <PageHeader
        title={t('common.admins')}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedUser(null);
              setOpen(true);
            }}
          >
            {t('common.new')}
          </Button>
        }
      />

      <TableFilter
        config={getFilterConfig()}
        useQueryParams
        onChange={(filterParams) => {
          tableState.setFilterParams(filterParams);
        }}
        withoutContainerPadding
      />

      <BasicTable<User>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
      {open && (
        <AdminForm
          open={open}
          handleClose={() => setOpen(false)}
          user={editedUser}
          handleSubmit={() => {
            setOpen(false);
            setEditedUser(null);
            if (editedUser) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
      {editedUser && (
        <ChangeAdminStatusModal
          user={editedUser}
          open={openConfirmChangeStatusModal}
          onClose={() => setOpenConfirmChangeStatusModal(false)}
          onSuccess={async () => tableState.reloadData()}
        />
      )}
    </BasicContainer>
  );
}
