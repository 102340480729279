import { useNavigate, useParams } from 'react-router-dom';
import { LocationVisitsList } from 'components/shared-pages/location/visits-list';

export function HCPSupervisorLocationVisitsList() {
  const { locationId } = useParams();

  const navigate = useNavigate();

  return (
    <LocationVisitsList
      locationId={Number(locationId)}
      onRowClicked={(visit) =>
        navigate(`/hcp-supervisor/locations/${locationId}/visits/${visit.id}`)
      }
    />
  );
}
