import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import { ProfessionalSupervisorSignUpForm } from './components/professional-supervisor-sign-up-form';
import { useAuthSelector } from '../../../store/selectors/auth';
import { Heading } from '../../../components/common/heading';

export function ProfessionalSupervisorInvitationSignUp() {
  const [searchParams] = useSearchParams();
  const authState = useAuthSelector();
  const navigate = useNavigate();
  const theme = useTheme();
  useEffect(() => {
    if (authState.user) navigate('/');
  }, [authState.user]);

  useEffect(() => {
    if (!searchParams.get('token')) navigate('/login');
  }, [searchParams]);
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      p={theme.spacing(2)}
      sx={{
        overflowY: 'auto',
      }}
    >
      <Grid item pb={theme.spacing(5)}>
        <Heading variant="h1">Professional Supervisor Sign Up</Heading>
      </Grid>
      <Grid item>
        <ProfessionalSupervisorSignUpForm
          token={searchParams.get('token') as string}
        />
      </Grid>
    </Grid>
  );
}
