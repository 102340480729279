import { Grid, GridProps } from '@mui/material';
import { useEffect } from 'react';
import useProgressiveImage from './useProgressiveImage';

interface LazyBgGridProps extends GridProps {
  src: string;
  gradient?: string;
  onLoadImg?: () => void;
}

const addGradient = (gradient: string | null, loaded: string | null) => {
  if (!loaded) return null;
  if (gradient && !loaded) return null;
  if (loaded && !gradient) {
    return `url(${loaded})`;
  }
  if (loaded && gradient) {
    return `${gradient} , url(${loaded})`;
  }
  return null;
};

export function LazyBgGrid(props: LazyBgGridProps) {
  const { src, children, gradient, onLoadImg, sx, ...restProps } = props;
  const loaded = useProgressiveImage(src);

  useEffect(() => {
    if (onLoadImg && src && loaded) {
      onLoadImg();
    }
  }, [loaded, src]);

  const gradientStyle = loaded && gradient ? gradient : null;

  return (
    <Grid
      {...restProps}
      sx={{
        ...sx,
        backgroundImage: addGradient(gradientStyle, loaded),
      }}
    >
      {children}
    </Grid>
  );
}
