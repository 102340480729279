import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { Button } from 'components/common/button';
import { useTableState } from 'hooks/useTableState';
import { useLocation } from 'hooks/useLocation';
import { User, UserInvitationStatuses } from 'api/services/auth/types';
import { PageHeader } from 'components/admin-page-layout';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginAsUserRequest } from 'store/reducers/auth/actions';
import { SiteManagerForm } from './components/site-manager-form';
import {
  getErrorMessage,
  getErrorMessageComponent,
  handleInvitationCopy,
  isEqualStatuses,
  showSnackbar,
} from '../../../utils';
import { getCells } from './cells';
import { AssignSiteManagerForm } from './components/assign-site-manager-form';
import { ConfirmationModal } from '../../../components/modal';

export function LocationSiteManagersPage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAddSiteManager, setOpenAddSiteManager] = useState(false);
  const [openConfirmRemoveSiteManager, setOpenConfirmRemoveAddSiteManager] =
    useState(false);
  const [openConfirmDeleteSiteManager, setOpenConfirmDeleteAddSiteManager] =
    useState(false);
  const [editedSiteManager, setEditedSiteManager] = useState<User | null>(null);
  const { t, i18n } = useTranslation();

  const { locationId } = useParams();
  const { isLocationLoading, location } = useLocation();

  const fetchSiteManagers = useCallback(
    async (limit: number, offset: number) => {
      const siteManagersList = await api.user.getSiteManagers(limit, offset, {
        locationId: locationId ? Number(locationId) : undefined,
      });
      return siteManagersList;
    },
    [],
  );
  const tableState = useTableState<User>({
    fetchDataFunction: fetchSiteManagers,
  });

  const handleSendInvitation = async (user: User) => {
    try {
      await api.user.sendSiteManagerInvitation(user.id);
      showSnackbar(t('common.invitationSuccessfullySent'), {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, t('common.couldNotSendInvitation')),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleRemoveFromThisLocation = async (user: User) => {
    try {
      await api.user.updateSiteManager(user.id, {
        given: user.given,
        family: user.family,
        email: user.email,
        middleName: user.middleName,
        phone: user.phone,
        externalId: user.externalId,
        organizationId: location?.organization.id,
        locations: user.siteManager?.locations
          ?.map(({ id }) => id)
          .filter((id) => id !== location?.id),
      });
      showSnackbar('Site Manager is successfully removed', {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(
            e,
            'Could not remove this site manager from the site',
          ),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setOpenConfirmRemoveAddSiteManager(false);
      setEditedSiteManager(null);
    }
  };

  const handleDeleteFromThisOrganization = async (user: User) => {
    if (!location) return;
    try {
      await api.organization.deleteSiteManager(
        location.organization.id,
        user.id,
      );
      showSnackbar('Site Manager is successfully deleted', {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(
            e,
            'Could not remove this site manager from the site',
          ),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setOpenConfirmDeleteAddSiteManager(false);
      setEditedSiteManager(null);
    }
  };

  const cells = useMemo(() => getCells(), [i18n.language]);

  const actions = [
    {
      title: t('common.edit'),
      onClick: (SiteManager: User) => {
        setEditedSiteManager(SiteManager);
        setOpen(true);
      },
    },
    {
      title: 'Remove from this site',
      onClick: (SiteManager: User) => {
        setEditedSiteManager(SiteManager);
        setOpenConfirmRemoveAddSiteManager(true);
      },
    },
    {
      title: 'Delete from the organization',
      onClick: (SiteManager: User) => {
        setEditedSiteManager(SiteManager);
        setOpenConfirmDeleteAddSiteManager(true);
      },
    },
    {
      title: t('common.copyInvitationLink'),
      onClick: (SiteManager: User) => {
        handleInvitationCopy(SiteManager);
      },
      disabled: (user: User) =>
        isEqualStatuses(user.invitationStatus, UserInvitationStatuses.Active),
    },
    {
      title: t('common.sendInvitation'),
      onClick: (SiteManager: User) => {
        handleSendInvitation(SiteManager);
      },
      disabled: (user: User) =>
        isEqualStatuses(user.invitationStatus, UserInvitationStatuses.Active),
    },
    {
      title: 'Login',
      onClick: (SiteManager: User) => {
        dispatch(loginAsUserRequest({ userId: SiteManager.id }));
      },
    },
  ];

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={t('common.siteManagers')}
        entityName={location?.name}
        titleLoading={isLocationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedSiteManager(null);
              setOpenAddSiteManager(true);
            }}
          >
            {t('common.add')}/{t('common.new')}
          </Button>
        }
      />

      <BasicTable<User>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && (
        <SiteManagerForm
          siteManager={editedSiteManager}
          defaultValues={
            location
              ? {
                  locations: [location],
                  organization: location.organization,
                }
              : undefined
          }
          handleClose={() => setOpen(false)}
          handleSubmit={() => {
            setOpen(false);
            setEditedSiteManager(null);
            if (editedSiteManager) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
      {openAddSiteManager && location && (
        <AssignSiteManagerForm
          locationId={location.id}
          organizationId={location.organization.id}
          handleClose={() => setOpenAddSiteManager(false)}
          addNewSiteManager={() => {
            setOpenAddSiteManager(false);
            setOpen(true);
          }}
          onSubmit={() => {
            tableState.reloadData();
            setOpenAddSiteManager(false);
          }}
        />
      )}
      {editedSiteManager && (
        <ConfirmationModal
          open={openConfirmRemoveSiteManager}
          handleClose={() => {
            setOpenConfirmRemoveAddSiteManager(false);
            setEditedSiteManager(null);
          }}
          handleSubmit={() => handleRemoveFromThisLocation(editedSiteManager)}
          submitButtonTitle="Remove"
          title={
            <>
              {`You are about to remove the ${editedSiteManager.given} ${editedSiteManager.family} site manager from the site.`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
      {editedSiteManager && (
        <ConfirmationModal
          open={openConfirmDeleteSiteManager}
          handleClose={() => {
            setOpenConfirmDeleteAddSiteManager(false);
            setEditedSiteManager(null);
          }}
          handleSubmit={() =>
            handleDeleteFromThisOrganization(editedSiteManager)
          }
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete the ${editedSiteManager.given} ${editedSiteManager.family} site manager from the organization.`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
