import { ChangeEvent, useState } from 'react';
import { Dialog, Grid, Typography } from '@mui/material';
import { Button } from 'components/common/button';

import { Radio } from 'components/common/input';

interface SendBookingInvitationsFormProps {
  opened: boolean;
  handleClose: () => void;
  handleSubmit: (invitationType: 'phone' | 'email' | 'both') => void;
}

export function SendBookingInvitationsForm(
  props: SendBookingInvitationsFormProps,
) {
  const { opened, handleSubmit, handleClose } = props;
  const [invitationType, setInvitationType] = useState<
    'phone' | 'email' | 'both' | null
  >(null);

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvitationType((event.target as any).value);
  };

  const items = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'phone',
      label: 'Text Message',
    },
    {
      value: 'both',
      label: 'Both',
    },
  ];

  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="xs" fullWidth>
      <Grid p={3}>
        <Grid mb={2}>
          <Typography>Send Booking Invitation</Typography>
        </Grid>
        <Grid container direction="column">
          <Radio
            items={items}
            value={invitationType || ''}
            onChange={handleTypeChange}
            sx={{ paddingLeft: 1, paddingBottom: 1 }}
            labelProps={{ sx: { fontSize: '14px' } }}
          />

          <Grid item container justifyContent="flex-end" gap={2}>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={() => {
                  if (invitationType) {
                    handleSubmit(invitationType);
                    handleClose();
                  }
                }}
                sx={{
                  width: '100%',
                }}
                disabled={!invitationType}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
