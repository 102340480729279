import { IFilterConfig } from 'components/table-filter/types';

export const getFilterConfig = (): IFilterConfig[] => [
  {
    name: 'showInactive',
    label: 'Show Inactive',
    type: 'checkbox',
    checkboxValues: {
      true: 'YES',
      false: 'NO',
    },
  },
];
