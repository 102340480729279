import { PaginatedData } from 'common/types';
import { PaginatedRequestParams, RequestParams } from 'api/types';
import axios from '../../axios';
import { Record, Upload } from './types';

export class UploadsAPI {
  async getRosterUploads(params: PaginatedRequestParams | RequestParams) {
    const { data } = await axios.get(`/uploads/roster`, {
      params,
    });

    return data as PaginatedData<Upload>;
  }

  async getRosterUploadById(id: number | string) {
    const { data } = await axios.get(`/uploads/${id}`);

    return data as Upload;
  }

  async uploadRoster(body: FormData) {
    const { data } = await axios.post('/uploads/roster', body);

    return data as Upload;
  }

  async processUpload(uploadId: number) {
    const { data } = await axios.post(`/uploads/${uploadId}/process`);

    return data as Upload;
  }

  async getRosterRecords(
    uploadId: number | string,
    params: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(`/uploads/${uploadId}/roster/records`, {
      params,
    });

    return data as PaginatedData<Record>;
  }

  async getTestResultUploads(params: PaginatedRequestParams | RequestParams) {
    const { data } = await axios.get(`/uploads/hearing-test-result`, {
      params,
    });

    return data as PaginatedData<Upload>;
  }

  async uploadTestResults(body: FormData) {
    const { data } = await axios.post('/uploads/hearing-test-result', body);

    return data as Upload;
  }

  async getTestResultRecords(
    uploadId: number | string,
    params: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/uploads/${uploadId}/hearing-test-result/records`,
      {
        params,
      },
    );

    return data as PaginatedData<Record>;
  }

  async downloadFile(uploadId: number | string) {
    const { data } = await axios.get(`/uploads/${uploadId}/download`, {
      responseType: 'blob',
    });

    return data as any;
  }
}
