import { AccessTime } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

interface SlotButtonProps {
  checked: boolean;
  label: string;
}

export function SlotButton(props: SlotButtonProps) {
  const { checked, label } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      gap={1}
      sx={{
        padding: '2px 6px',
        borderRadius: '8px',
        background: checked ? '#FFED8F' : 'transparent',
        border: `1px solid ${checked ? '#DFCB61' : '#08161F'}`,
        maxWidth: { xs: '100%', sm: '219px' },
        width: { xs: '100%', sm: '172px', lg: '219px' },
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.13)',
        },
        '&:active, &:focus': {
          background: '#FFE86E',
        },
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: '11px',
          lineHeight: '16px',
          fontWeight: 700,
        }}
      >
        {label}
      </Typography>

      <AccessTime fontSize="small" />
    </Grid>
  );
}
