import { Typography, TypographyProps } from '@mui/material';

export function SubHeader({ children }: TypographyProps) {
  return (
    <Typography
      sx={{
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '130%',
        letterSpacing: '0.18px',
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      {children}
    </Typography>
  );
}
