import { useMemo, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import {
  IVisit,
  IVisitTime,
  VisitTimeSlot,
} from 'api/services/organization/types';
import BasicTable from 'components/table/BasicTable';
import { ConfirmationModal } from 'components/modal';
import { useTableState } from 'hooks/useTableState';
import { Participant } from 'api/services/auth/types';
import { Button } from 'components/common/button';
import { Download } from '@mui/icons-material';
import { getCells } from './cells';
import { ParticipantsSectionTitle } from '../../components';
import { SendQuestionnaireForm } from './components';
import { DeferringParticipantFromTestingModal } from '../../../../../components/modal/DeferringParticipantFromTestingModal';

interface VisitTimesProps {
  tableState: ReturnType<typeof useTableState<IVisitTime>>;
  toggleDeferred: (
    participantId: number,
    isDeferring: boolean,
    params?: {
      deferringReason: string;
      deferringReasonNote?: string | null;
    },
  ) => Promise<void>;
  unscheduleParticipant: (participantId: number) => void;
  setBookingParticipant: (participant: Participant) => void;
  sendIndividualQuestionnaireInvitation: (
    participantId: number,
    invitationType:
      | 'phone-questionnaire-invitation'
      | 'email-questionnaire-invitation'
      | 'questionnaire-invitation',
  ) => void;
  sendQuestionnaireInvitations: () => void;
  sendQuestionnaireLoading: boolean;
  getSchedulePDF: () => void;
  getSchedulePDFLoading: boolean;
  visit?: IVisit;
}

export function ScheduledParticipants({
  tableState,
  toggleDeferred,
  unscheduleParticipant,
  setBookingParticipant,
  sendQuestionnaireInvitations,
  sendQuestionnaireLoading,
  sendIndividualQuestionnaireInvitation,
  getSchedulePDF,
  getSchedulePDFLoading,
  visit,
}: VisitTimesProps) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [sendQuestionnaireOpen, setSendQuestionnaireOpen] = useState(false);
  const [editedSlot, setEditedSlot] = useState<VisitTimeSlot | null>(null);
  const [actionType, setActionType] = useState<'defer' | 'unschedule' | ''>('');

  const actions = [
    {
      title: 'Defer',
      onClick: (slot: VisitTimeSlot) => {
        setEditedSlot(slot);
        setActionType('defer');
        setConfirmationOpen(true);
      },
    },
    {
      title: 'Schedule',
      onClick: (slot: VisitTimeSlot) => {
        if (slot.participant) {
          setBookingParticipant(slot.participant);
        }
      },
    },
    {
      title: 'Unschedule',
      onClick: (slot: VisitTimeSlot) => {
        setEditedSlot(slot);
        setActionType('unschedule');
        setConfirmationOpen(true);
      },
    },
    {
      title: 'Send Invitation',
      onClick: (slot: VisitTimeSlot) => {
        setSendQuestionnaireOpen(true);
        setEditedSlot(slot);
      },
    },
  ];

  const cells = useMemo(() => getCells(actions, visit), [visit]);

  return (
    <Box
      sx={{
        borderRadius: '16px',
        padding: 2,
        border: '1px solid  rgba(202, 194, 190, 0.30)',
      }}
    >
      <Grid container justifyContent="space-between" mb={3} gap={1}>
        <ParticipantsSectionTitle type="scheduled">
          Scheduled
        </ParticipantsSectionTitle>
        <Grid item gap={1} sx={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={getSchedulePDF}
            endIcon={
              getSchedulePDFLoading ? (
                <CircularProgress size="14px" sx={{ color: '#FFFFFF' }} />
              ) : (
                <Download sx={{ width: '14px' }} />
              )
            }
            disabled={getSchedulePDFLoading || !tableState.data.length}
          >
            Print Schedule
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={sendQuestionnaireInvitations}
            disabled={sendQuestionnaireLoading}
          >
            Send Invitation
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ maxHeight: 800, overflow: 'auto' }}>
        <BasicTable<IVisitTime>
          cells={cells}
          tableState={tableState}
          isPagination={false}
          isRowDividers
          contextButtonProps={{
            sx: {
              backgroundColor: 'rgba(202, 194, 190, 0.40);',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
            },
          }}
          tableBodyCellSxProps={{
            padding: '4px 12px',
          }}
        />
      </Box>

      {!!(sendQuestionnaireOpen && editedSlot) && (
        <SendQuestionnaireForm
          opened={!!(sendQuestionnaireOpen && editedSlot)}
          handleClose={() => {
            setSendQuestionnaireOpen(false);
            setEditedSlot(null);
          }}
          handleSubmit={sendIndividualQuestionnaireInvitation}
          participant={editedSlot?.participant}
          title={`Send Invitation to ${editedSlot?.participant?.given} ${editedSlot?.participant?.family}`}
        />
      )}

      {editedSlot && actionType !== 'defer' && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedSlot(null);
            setActionType('');
          }}
          handleSubmit={() => {
            unscheduleParticipant(editedSlot.participantId);
            setEditedSlot(null);
            setActionType('');
          }}
          submitButtonTitle={actionType}
          title={
            <>
              {`You are about to ${actionType} ${editedSlot.participant?.given} ${editedSlot.participant?.family} from visit`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
      {!!editedSlot && actionType === 'defer' && (
        <DeferringParticipantFromTestingModal
          opened={confirmationOpen}
          onClose={() => {
            setConfirmationOpen(false);
            setEditedSlot(null);
            setActionType('');
          }}
          onSubmit={async (params: {
            deferringReason: string;
            deferringReasonNote?: string | null;
          }) => {
            await toggleDeferred(editedSlot?.participantId, true, params);
            setEditedSlot(null);
            setActionType('');
            setConfirmationOpen(false);
          }}
        />
      )}
    </Box>
  );
}
