import uuid from 'uuidv4';
import { ITimePeriodBoundary } from './types';

export class TimePeriod {
  key: string;

  from?: Date;

  to?: Date;

  constructor(_from?: ITimePeriodBoundary, _to?: ITimePeriodBoundary) {
    this.key = uuid();
    if (_from) {
      this.from = new Date();
      this.from.setHours(_from.hours, _from.minutes || 0, 0, 0);
    }
    if (_to) {
      this.to = new Date();
      this.to.setHours(_to.hours, _to.minutes || 0, 0, 0);
    }
  }

  changeTimePeriod = (updatedPeriod: { from?: Date; to?: Date }) => {
    this.from = updatedPeriod.from || this.from;
    this.to = updatedPeriod.to || this.to;
  };
}
