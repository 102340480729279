import React from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';

import { CoverLetterAndSummaryOfTestingForm } from 'components/forms/cover-letter-and-summary-of-testing-form';
import { PageHeader } from '../../../../components/admin-page-layout';

export function PSUpdateCoverLetterAndSummaryOfTestingPage() {
  const { locationId, coverLetterId } = useParams();
  return (
    <BasicContainer>
      <PageHeader title="Update Cover Letter & Summary Of Testing" />
      <CoverLetterAndSummaryOfTestingForm
        locationId={Number(locationId)}
        audiologistCoverLetterId={Number(coverLetterId)}
      />
    </BasicContainer>
  );
}
