import {
  FormHelperText,
  Grid,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { FilledTextInput } from './FilledTextInput';
import {
  DatePicker,
  IDatePickerProps,
  ISelectProps,
  Select,
} from '../../../common/input';

interface IAudiometerInfoItemProps {
  label: string;
  inputProps?: TextFieldProps;
  datePickerProps?: IDatePickerProps<any>;
  dateTimePickerProps?: DateTimePickerProps<any> & { errorMessage?: string };
  selectProps?: ISelectProps;
  type?: 'text' | 'date' | 'select' | 'dateTime';
}

export function AudiometerInfoItem(props: IAudiometerInfoItemProps) {
  const {
    label,
    inputProps,
    datePickerProps,
    dateTimePickerProps,
    selectProps,
    type = 'text',
  } = props;

  const renderInput = useMemo(() => {
    if (type === 'text') {
      return (
        <FilledTextInput fullWidth {...inputProps} sx={{ width: '100%' }} />
      );
    }
    if (type === 'date') {
      return (
        <DatePicker
          slots={{ textField: FilledTextInput }}
          disableOpenPicker
          errorMessageSx={{
            position: 'absolute',
            bottom: '-20px',
            width: '100%',
          }}
          {...datePickerProps}
        />
      );
    }
    if (type === 'dateTime') {
      return (
        <Grid sx={{ position: 'relative' }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              slots={{ textField: FilledTextInput }}
              disableOpenPicker
              {...dateTimePickerProps}
            />
            <FormHelperText
              sx={{ position: 'absolute', top: '100%', left: '8px' }}
              error={!!dateTimePickerProps?.errorMessage}
            >
              {dateTimePickerProps?.errorMessage || ''}
            </FormHelperText>
          </LocalizationProvider>
        </Grid>
      );
    }
    return (
      <Select
        sx={{
          minHeight: 'auto',
          maxWidth: '100%',
          '& *': {
            border: 'none !important',
          },
          '& .MuiSelect-select': {
            borderRadius: '8px',
            padding: '4px 8px',
            backgroundColor: 'rgba(202, 194, 190, 0.40)',
            fontFamily: 'DM Mono',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '130%',
            letterSpacing: '-0.14px',
            textAlign: 'center',
          },
        }}
        IconComponent={KeyboardArrowDownIcon}
        withErrorMessage={false}
        {...(selectProps as ISelectProps)}
      />
    );
  }, [type, inputProps, datePickerProps, selectProps, dateTimePickerProps]);
  return (
    <Grid
      container
      alignItems="center"
      item
      gap={{ xs: 1, sm: 3 }}
      flexWrap="nowrap"
      justifyContent="space-between"
    >
      <Typography
        sx={{
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      <Grid item sx={{ display: 'flex', flex: 1 }}>
        {renderInput}
      </Grid>
    </Grid>
  );
}
