import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuthSelector } from 'store/selectors/auth';
import { setUser } from 'store/reducers/auth/actions';
import { Header } from 'components/header';
import { User } from 'api/services/auth/types';
import { SendEmailVerificationStep } from './steps/SendEmailVerificationStep';
import { ConfirmEmailVerificationStep } from './steps/ConfirmEmailVerificationStep';
import { ConfirmPhoneVerificationStep } from './steps/ConfirmPhoneVerificationStep';
import { SendPhoneVerificationStep } from './steps/SendPhoneVerificationStep';

enum VerificationPageStep {
  EMAIL_SEND_VERIFICATION_CODE = 'email_send_verification_code',
  EMAIL_CONFIRM_VERIFICATION_CODE = 'email_confirm_verification_code',
  PHONE_SEND_VERIFICATION_CODE = 'phone_send_verification_code',
  PHONE_CONFIRM_VERIFICATION_CODE = 'phone_confirm_verification_code',
}

export function MfaVerificationPage() {
  const authState = useAuthSelector();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentVerificationStep, setCurrentVerificationStep] = useState(
    !authState.user?.emailVerified
      ? VerificationPageStep.EMAIL_SEND_VERIFICATION_CODE
      : VerificationPageStep.PHONE_SEND_VERIFICATION_CODE,
  );

  const setUserData = (user: User | null) => dispatch(setUser(user));

  const goToLoginAndResetUser = () => {
    navigate('/login');
    dispatch(setUserData(null));
  };

  const onPhoneVerificationFinish = () => {
    if (authState.user) {
      setUserData({ ...authState.user, phoneVerified: true });
      navigate('/');
    }
  };

  return (
    <>
      <Header onLogoClick={goToLoginAndResetUser} />
      {currentVerificationStep ===
        VerificationPageStep.EMAIL_SEND_VERIFICATION_CODE &&
        authState.user && (
          <SendEmailVerificationStep
            email={authState.user.email}
            onGoToNextStep={() => {
              setCurrentVerificationStep(
                VerificationPageStep.EMAIL_CONFIRM_VERIFICATION_CODE,
              );
            }}
          />
        )}
      {currentVerificationStep ===
        VerificationPageStep.EMAIL_CONFIRM_VERIFICATION_CODE &&
        authState.user && (
          <ConfirmEmailVerificationStep
            email={authState.user.email}
            onGoToNextStep={() => {
              if (authState.user)
                setUserData({ ...authState.user, emailVerified: true });

              setCurrentVerificationStep(
                VerificationPageStep.PHONE_SEND_VERIFICATION_CODE,
              );
            }}
            onResendCode={() => {
              setCurrentVerificationStep(
                VerificationPageStep.EMAIL_SEND_VERIFICATION_CODE,
              );
            }}
          />
        )}
      {currentVerificationStep ===
        VerificationPageStep.PHONE_SEND_VERIFICATION_CODE && (
        <SendPhoneVerificationStep
          phone={authState.user?.phone}
          onGoToNextStep={(newPhone: string) => {
            if (authState.user && newPhone !== authState.user.phone)
              setUserData({ ...authState.user, phone: newPhone });
            setCurrentVerificationStep(
              VerificationPageStep.PHONE_CONFIRM_VERIFICATION_CODE,
            );
          }}
        />
      )}
      {currentVerificationStep ===
        VerificationPageStep.PHONE_CONFIRM_VERIFICATION_CODE && (
        <ConfirmPhoneVerificationStep
          phone={authState.user?.phone}
          onFinish={onPhoneVerificationFinish}
          onResendCode={() => {
            setCurrentVerificationStep(
              VerificationPageStep.PHONE_SEND_VERIFICATION_CODE,
            );
          }}
        />
      )}
    </>
  );
}
