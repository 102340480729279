import React, { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Box, Grid, Skeleton, useTheme } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useSiteManagerDashboardSelector } from '../../../store/selectors/dashboard';
import { ILocationParticipantsHearingMetric } from '../../../api/services/organization/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';
import { HearingTestResultsSummary } from '../../../components/hearing-test-results-summary';
import { LocationMetricsTable } from '../../../components/location-metrics-table';
import { HearingLossChart } from '../../../components/hearing-loss-chart';
import { Button } from '../../../components/common/button';
import { DateRangePickerModal } from '../../../components/modal/DateRangePickerModal';

export function SiteManagerLocationMetrics() {
  const { selectedLocation } = useSiteManagerDashboardSelector();

  const [metrics, setMetrics] =
    useState<ILocationParticipantsHearingMetric | null>(null);
  const [dateRange, setDateRange] = useState<{
    dateFrom: DateTime;
    dateTo: DateTime;
  }>({
    dateFrom: DateTime.now().minus({ month: 12 }),
    dateTo: DateTime.now(),
  });

  const [loading, setLoading] = useState(true);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  useEffect(() => {
    const asyncRequest = async () => {
      if (!selectedLocation) {
        return EMPTY_PAGINATED_DATA;
      }
      try {
        setLoading(true);
        const metricsData =
          await api.organization.getLocationParticipantsHearingMetricsByLocationId(
            selectedLocation.id,
            dateRange.dateFrom.toFormat('yyyy-MM-dd'),
            dateRange.dateTo.toFormat('yyyy-MM-dd'),
          );
        setMetrics(metricsData);
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    asyncRequest();
  }, [selectedLocation, dateRange]);

  const totalParticipantsTested = useMemo(
    () =>
      metrics
        ? _.values(metrics.participantsHearingLossByGender).reduce(
            (acc, item) => acc + item.total,
            0,
          )
        : 0,
    [metrics],
  );

  const theme = useTheme();

  const summaryBlocksData = useMemo(
    () => [
      {
        name: 'Employees Tested',
        value: `${totalParticipantsTested}`,
        backgroundColor: '#FFE86E',
      },
      {
        name: 'Standard Threshold Shift',
        value: `${metrics?.participantsWithSTSShiftCountByGender.total || 0}`,
        backgroundColor: '#FFF1A6',
      },
      {
        name: 'OSHA Recordable Shift',
        value: `${metrics?.participantsWithOSHAShiftCountByGender.total || 0}`,
        backgroundColor: '#D9777A',
      },
    ],
    [metrics],
  );
  return (
    <Grid>
      <Grid mb={theme.spacing(2)}>
        <HearingTestResultsSummary
          items={summaryBlocksData}
          loading={loading}
          itemsWidth="auto"
          itemsHeight="auto"
          containerSx={{
            justifyContent: 'space-between',
            gap: theme.spacing(1),
          }}
          itemSx={{
            flex: 1,
            padding: theme.spacing(2),
            marginRight: theme.spacing(0.5),
            minHeight: '87px',
            '& .MuiGrid-root:nth-child(1)': {
              marginLeft: theme.spacing(1),
            },
            '& .MuiGrid-root:nth-child(2) p': {
              fontSize: '18px',
              fontWeight: 600,
            },
          }}
        />
      </Grid>
      <Grid mb={theme.spacing(3)}>
        <Button
          onClick={() => setDatePickerOpened(true)}
          endIcon={<DateRangeIcon fontSize="inherit" />}
          variant="transparent"
        >
          {dateRange.dateFrom.toFormat('MM/dd/yyyy')} -{' '}
          {dateRange.dateTo.toFormat('MM/dd/yyyy')}
        </Button>
      </Grid>
      <Grid mb={theme.spacing(7)}>
        <LocationMetricsTable metrics={metrics} />
      </Grid>
      <Grid
        pb={theme.spacing(2)}
        container
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Box
          sx={{
            background:
              'url("/images/backgrounds/audiogram-texture.png"), linear-gradient(rgba(243, 238, 236, 0.30), rgba(243, 238, 236, 0.30))',
            backgroundSize: 'cover, cover',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'top left, top left',
            px: theme.spacing(2),
            pt: theme.spacing(8),
            pb: theme.spacing(5),
            borderRadius: theme.spacing(2),
            width: { xs: '100%', md: '80%' },
            boxSizing: 'border-box',
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ maxWidth: '100% !important' }}
          >
            <Grid item xs={12} md={6} sx={{ maxWidth: '100% !important' }}>
              {metrics && !loading ? (
                <HearingLossChart metric={metrics} />
              ) : (
                <Skeleton variant="rounded" width="100%" height="300px" />
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <DateRangePickerModal
        opened={datePickerOpened}
        onClose={() => setDatePickerOpened(false)}
        onSelect={({ dateFrom, dateTo }) =>
          setDateRange({
            dateFrom: dateFrom as DateTime,
            dateTo: dateTo as DateTime,
          })
        }
        dateRange={dateRange}
      />
    </Grid>
  );
}
