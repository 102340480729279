import { useCallback, useEffect, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Record } from 'api/services/uploads/types';
import { PageHeader } from 'components/admin-page-layout';
import { cells } from './cells';

export function SiteManagerLocationUploadRecords() {
  const { locationId, uploadId } = useParams();
  const [locationName, setLocationName] = useState('');
  const [uploadFileName, setUploadFileName] = useState('');

  useEffect(() => {
    const getLocationName = async () => {
      if (!locationId) {
        return;
      }
      try {
        const { name } = await api.organization.getLocationById(locationId);
        setLocationName(name);
      } catch (e) {
        console.log(e);
      }
    };

    const getUploadFileName = async () => {
      if (!uploadId) {
        return;
      }
      try {
        const { file } = await api.uploads.getRosterUploadById(uploadId);
        setUploadFileName(file.name);
      } catch (e) {
        console.log(e);
      }
    };

    getUploadFileName();
    getLocationName();
  }, [locationId]);

  const fetchRecords = useCallback(
    async (limit: number, offset: number) => {
      if (!uploadId) {
        return EMPTY_PAGINATED_DATA;
      }

      return api.uploads.getRosterRecords(uploadId, {
        limit,
        offset,
      });
    },
    [uploadId],
  );

  const tableState = useTableState<Record>({
    fetchDataFunction: fetchRecords,
  });

  return (
    <BasicContainer>
      <PageHeader title={`Rosters Records ${locationName || ''}`} />

      <Box mb={2}>
        <Typography variant="h6" fontWeight={400}>
          File:{uploadFileName && ` ${uploadFileName}`}
        </Typography>
      </Box>
      <BasicTable<Record> cells={cells} tableState={tableState} />
    </BasicContainer>
  );
}
