import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { LoginUserPayload } from 'store/reducers/auth/types';
import { Button } from 'components/common/button';
import { useAuthSelector } from 'store/selectors/auth';
import { FullHeightContainer } from 'components/container';
import {
  loginUserRequest,
  logoutUserRequest,
} from 'store/reducers/auth/actions';
import { FormAlert } from 'components/alert';
import { TextInput } from 'components/common/input';
import { MfaConfirmationModal } from 'components/modal/MfaConfirmationModal';
import { getErrorMessageComponent } from '../../../utils';
import { LoginLink } from './components/LoginLink';

export const loginFormInitialValues: LoginUserPayload = {
  email: '',
  password: '',
  rememberDevice: true,
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

export function Login() {
  const dispatch = useDispatch();
  const authState = useAuthSelector();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showMfaModal, setShowMfaModal] = useState(false);

  const onSubmit = (values: typeof loginFormInitialValues) => {
    const loginRequestData = { ...values };
    if (authState.deviceToken)
      Object.assign(loginRequestData, { deviceToken: authState.deviceToken });

    dispatch(loginUserRequest(loginRequestData));
  };

  useEffect(() => {
    if (authState.user) {
      if (
        !authState.user.ignoreMfa &&
        (!authState.user?.phoneVerified || !authState.user?.emailVerified)
      )
        navigate('/mfa-verification');
      else navigate('/');
    }
  }, [authState.user]);

  useEffect(() => {
    if (authState.mfaRequired) setShowMfaModal(true);
  }, [authState.mfaRequired]);
  return (
    <Formik
      initialValues={{ ...loginFormInitialValues, ...authState.user }}
      onSubmit={onSubmit}
      validationSchema={LoginSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <>
          <MfaConfirmationModal
            open={showMfaModal}
            handleClose={() => {
              setShowMfaModal(false);
              dispatch(logoutUserRequest());
            }}
            loginDetails={values}
          />
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={0.5}
            >
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  id="outlined-basic"
                  label={t('common.email')}
                  variant="outlined"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  // A ' ' is a hack to always have space for error message, can crete a better approach later
                  helperText={
                    touched.email && errors.email ? errors.email : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  id="outlined-basic"
                  label={t('common.password')}
                  variant="outlined"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    touched.password && errors.password ? errors.password : ' '
                  }
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {authState.error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(authState.error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={authState.loading}
                    >
                      {t('common.login')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={4}>
                    <LoginLink to="/forgot-password">
                      {t('common.forgotPassword')}
                    </LoginLink>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </>
      )}
    </Formik>
  );
}
