import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Box, Chip, Grid, useTheme } from '@mui/material';
import { showSnackbar } from 'utils';
import {
  EHearingTestStatuses,
  EHearingTestSubStatuses,
  HEARING_TEST_SUB_STATUSES_GROUPING_MAP,
} from '../../../../api/services/hearing-test-results/types';
import { FormModal } from '../../../modal';
import { Select } from '../../../common/input';

interface IStatusSelectionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (body: {
    status: EHearingTestStatuses;
    subStatuses: EHearingTestSubStatuses[];
  }) => Promise<void> | void;
  status: EHearingTestStatuses | null;
  subStatuses: EHearingTestSubStatuses[];
}

const VALIDATION_SCHEMA = Yup.object().shape({
  status: Yup.string()
    .oneOf(_.values(EHearingTestStatuses))
    .required('status required'),
  subStatuses: Yup.array()
    .required('sub-status required')
    .test(
      'areSubStatusesSelected',
      'You should select at least one sub-status',
      (value, testContext) => {
        const { status } = testContext.parent;
        if (status) {
          if (
            HEARING_TEST_SUB_STATUSES_GROUPING_MAP[
              status as EHearingTestStatuses
            ].length
          ) {
            return (
              !!value.length &&
              value.every((subStatus) =>
                HEARING_TEST_SUB_STATUSES_GROUPING_MAP[
                  status as EHearingTestStatuses
                ].includes(subStatus as EHearingTestSubStatuses),
              )
            );
          }
          return !value.length;
        }
        return true;
      },
    ),
});

export function StatusSelectionModal(props: IStatusSelectionModalProps) {
  const { open, handleClose, onSubmit, status, subStatuses } = props;

  const submitHandler = useCallback(
    async (values: {
      status: EHearingTestStatuses | '';
      subStatuses: EHearingTestSubStatuses[];
    }) => {
      const { status, subStatuses } = values;
      try {
        await onSubmit({
          status: status as EHearingTestStatuses,
          subStatuses,
        });
        handleClose();
        showSnackbar('Status successfully updated!', {
          variant: 'success',
        });
      } catch (e) {
        console.log(e);
      }
    },
    [onSubmit],
  );

  const initialValues = useMemo(
    () => ({
      status: (status || '') as EHearingTestStatuses | '',
      subStatuses: subStatuses || [],
    }),
    [status, subStatuses],
  );

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
    values,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const theme = useTheme();

  const statusOptions = useMemo(
    () =>
      _.values(EHearingTestStatuses)
        .filter((item) => item !== EHearingTestStatuses.PSSigned)
        .map((item) => ({
          label: item,
          value: item,
        })),
    [],
  );

  const subStatusesOptions = useMemo(
    () =>
      (values.status
        ? HEARING_TEST_SUB_STATUSES_GROUPING_MAP[values.status] || []
        : []
      ).map((item) => ({
        label: item,
        value: item,
        disabled:
          item !== EHearingTestSubStatuses.NoPSRecommendation &&
          values.subStatuses.includes(
            EHearingTestSubStatuses.NoPSRecommendation,
          ),
      })),
    [values.status, values.subStatuses],
  );

  return (
    <FormModal
      open={open}
      handleClose={() => {
        handleClose();
        resetForm();
      }}
      handleSubmit={handleSubmit}
      title="Edit Review Status"
      disableBackdropClick
      isSubmitting={isSubmitting}
    >
      <Grid container direction="column">
        <Grid item mb={theme.spacing(1)}>
          <Select
            {...getFieldProps('status')}
            options={statusOptions}
            label="Status"
            onChange={(e) => {
              setFieldValue('status', e.target.value);
              setFieldValue('subStatuses', []);
            }}
            errorMessage={touched?.status ? (errors?.status as string) : ''}
          />
        </Grid>
        <Grid item mb={theme.spacing(1)}>
          <Select
            sx={{
              maxWidth: '100%',
            }}
            {...getFieldProps('subStatuses')}
            options={subStatusesOptions}
            label="Sub-statuses"
            onChange={(e) => {
              if (
                (e.target.value as EHearingTestSubStatuses[]).includes(
                  EHearingTestSubStatuses.NoPSRecommendation,
                )
              ) {
                return setFieldValue('subStatuses', [
                  EHearingTestSubStatuses.NoPSRecommendation,
                ]);
              }
              setFieldValue('subStatuses', e.target.value);
            }}
            multiline
            errorMessage={
              touched?.subStatuses ? (errors?.subStatuses as string) : ''
            }
            multiple
            disabled={!subStatusesOptions.length}
            MenuProps={{
              sx: {
                '& .MuiMenuItem-root': {
                  maxWidth: '100%',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  textOverflow: 'clip',
                },
              },
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: theme.spacing(1),
                }}
              >
                {(selected as string[]).map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{
                      maxWidth: '100%',
                      margin: 0,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      '& .MuiChip-label': {
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                        textOverflow: 'clip',
                      },
                    }}
                  />
                ))}
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}
