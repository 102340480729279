import { Grid, Typography, styled } from '@mui/material';
import { useAuthSelector } from 'store/selectors/auth';
import { Skeleton } from './Skeleton';

const Label = styled(Typography)(() => ({
  fontSize: '10px',
  fontWeight: 800,
  lineHeight: '120%',
  letterSpacing: '0.15px',
  textTransform: 'uppercase',
  marginRight: '16px',
  minWidth: '80px',
}));

const Text = styled('span')(() => ({
  fontFamily: 'DM Mono',
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '120%',
  letterSpacing: '0.15px',
}));

export function ParticipantInfo() {
  const { user } = useAuthSelector();

  return (
    <Grid
      container
      gap={1}
      sx={{
        padding: '16px 24px',
        borderRadius: '8px',
        backgroundColor: '#F4F4F4',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
      }}
    >
      <Grid container direction="column" item xs={12} sm={6} gap={1}>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Name</Label>
          {user ? (
            <Text>{`${user?.given} ${user?.family}`}</Text>
          ) : (
            <Skeleton />
          )}
        </Grid>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Date of Birth</Label>
          {user ? <Text>{user?.birthDate}</Text> : <Skeleton />}
        </Grid>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Company</Label>
          {user ? (
            <Text>{user?.participant?.organization?.name}</Text>
          ) : (
            <Skeleton />
          )}
        </Grid>
      </Grid>

      <Grid container direction="column" item xs={12} sm={6} gap={1}>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Gender</Label>
          {user ? <Text>{user?.gender}</Text> : <Skeleton />}
        </Grid>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Department</Label>
          {user ? <Text>{user?.participant?.department}</Text> : <Skeleton />}
        </Grid>
        <Grid container alignItems="baseline" wrap="nowrap">
          <Label>Shift/s</Label>
          {user ? (
            <Text>
              {user?.participant?.shifts?.map((shift) => shift.name).join(', ')}
            </Text>
          ) : (
            <Skeleton />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
