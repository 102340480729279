import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { renderReportStatus } from 'utils/render/tableRender';
import {
  ECombinedReportStatus,
  ESignedDocumentStatuses,
  ICombinedReport,
} from 'api/services/organization/types';
import { Action } from 'components/table';
import { IconActionsCell } from 'components/table/components/IconActionsCell';
import { DateCell } from 'components/table/components';

export const useCells = ({
  actions,
}: {
  actions: Action<ICombinedReport>[];
}): Cell<ICombinedReport>[] => [
  {
    name: 'File Name',
    key: 'name',
    width: '375px',
  },
  {
    name: 'Date',
    key: 'created_at',
    render: (item: ICombinedReport) => {
      if (item.historicalDocument?.signingDate) {
        return `${DateTime.fromISO(
          item.historicalDocument?.signingDate,
        ).toFormat('MM/dd/yyyy')}`;
      }
      if (item.signedDocument?.signingDateTime) {
        return `${DateTime.fromISO(
          item.signedDocument.signingDateTime,
        ).toFormat('MM/dd/yyyy')}`;
      }
      return '-';
    },
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: ICombinedReport) => {
      let status: ECombinedReportStatus = ECombinedReportStatus.Draft;

      if (item.signedDocument?.status) {
        const signedDocumentStatus = item.signedDocument.status;

        if (signedDocumentStatus === ESignedDocumentStatuses.Signed)
          status = ECombinedReportStatus.Signed;
        if (signedDocumentStatus === ESignedDocumentStatuses.Error)
          status = ECombinedReportStatus.Error;
        if (signedDocumentStatus === ESignedDocumentStatuses.Processing)
          status = ECombinedReportStatus.Processing;
      } else if (!_.isEmpty(item.historicalDocument)) {
        status = ECombinedReportStatus.Historical;
      }

      return renderReportStatus(status);
    },
  },
  {
    name: 'Signed By',
    key: 'signatory',
    render: (item: ICombinedReport) => {
      if (!_.isEmpty(item.historicalDocument))
        return item.historicalDocument.signatoryName;
      if (!_.isEmpty(item.signedDocument?.signatory))
        return `${item.signedDocument?.signatory?.given || ''} ${
          item.signedDocument?.signatory?.family || ''
        }`;
      return '-';
    },
  },
  {
    name: 'Signed Date',
    key: 'signingDateTime',
    render: (item: ICombinedReport) => {
      if (item.historicalDocument?.signingDate) {
        return <DateCell date={item.historicalDocument?.signingDate} />;
      }
      if (item.signedDocument?.signingDateTime) {
        return <DateCell date={item.signedDocument.signingDateTime} />;
      }
      return '-';
    },
  },
  {
    name: '',
    render: (list: ICombinedReport) => (
      <IconActionsCell<ICombinedReport> actions={actions} item={list} />
    ),
    align: 'right',
  },
];
