import { useSearchParams } from 'react-router-dom';

export const useSearchParamHandlers = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = (key: string, value: string | null) => {
    if (key) {
      if (!value) {
        searchParams.delete(key);
        setSearchParams(searchParams);
      } else {
        searchParams.set(key, value);
        setSearchParams(searchParams);
      }
    }
  };

  return {
    searchParams,
    setSearchParam,
  };
};
