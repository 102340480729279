import { Container, styled } from '@mui/material';

export const FullHeightContainer = styled(Container)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const BasicContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));
