import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Button } from 'components/common/button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleCancel?: () => void;
  handleSubmit: () => void;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  title?: ReactNode;
}

export function ConfirmationModal(props: ModalProps) {
  const { t } = useTranslation();
  const {
    open,
    handleClose,
    handleCancel,
    handleSubmit,
    cancelButtonTitle = t('common.cancel'),
    submitButtonTitle = t('common.submit'),
    title = t('common.areYouSure'),
  } = props;
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <IconButton type="button" onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">{title}</Typography>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Button
            type="submit"
            variant="text"
            color="primary"
            onClick={handleCancel || handleClose}
          >
            {cancelButtonTitle.charAt(0).toUpperCase() +
              cancelButtonTitle.slice(1)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            {submitButtonTitle.charAt(0).toUpperCase() +
              submitButtonTitle.slice(1)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
