import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/common/button';
import { Organization } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { PageHeader } from 'components/admin-page-layout';
import { Grid } from '@mui/material';
import { SearchInput } from 'components/common/input/SearchInput';
import { RequestParams } from 'api/types';
import { OrganizationForm } from './components/organization-form';
import { getCells } from './cells';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';

export function OrganizationsList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [editedOrganization, setEditedOrganization] =
    useState<Organization | null>(null);

  const fetchOrganizations = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      const organizationsData = await api.organization.getOrganizations({
        limit,
        offset,
        orderBy,
        orderDirection,
        search,
        filterParams,
      });
      return organizationsData;
    },
    [],
  );
  const tableState = useTableState<Organization>({
    fetchDataFunction: fetchOrganizations,
  });

  const actions = [
    {
      title: t('common.edit'),
      onClick: (organization: Organization) => {
        setEditedOrganization(organization);
        setOpen(true);
      },
    },
    {
      title: t('common.uploadRoster'),
      onClick: (organization: Organization) => {
        navigate(`/admin/organizations/${organization.id}/roster-uploads`);
      },
    },
    {
      title: t('common.uploadTestResults'),
      onClick: (organization: Organization) => {
        navigate(`/admin/organizations/${organization.id}/test-result-uploads`);
      },
    },
    {
      title: t('common.seeHearingTestResults'),
      onClick: (organization: Organization) => {
        navigate(
          `/admin/organizations/${organization.id}/hearing-test-results`,
        );
      },
    },
    {
      title: t('common.exportEMRData'),
      onClick: async (organization: Organization) => {
        try {
          await api.organization.exportEMRRResults(Number(organization.id));
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      },
    },
    {
      title: t('common.recalculateHearingTestsParameters'),
      onClick: async (organization: Organization) => {
        try {
          const { totalResultsCount, successfullyRecalculatedResultsCount } =
            await api.organization.recalculateHearingTestParametersInOrganization(
              Number(organization.id),
            );
          let message = '';
          let snackbarVariant: 'success' | 'error' | 'warning' = 'success';
          if (successfullyRecalculatedResultsCount === 0) {
            message = t('organizations.couldNotRecalculateTestResults');
            snackbarVariant = 'error';
          } else if (successfullyRecalculatedResultsCount < totalResultsCount) {
            message = t(
              'organizations.successfullyRecalculatedNumberOfTestResults',
              { successfullyRecalculatedResultsCount, totalResultsCount },
            );
            snackbarVariant = 'warning';
          } else {
            message = t('organizations.successfullyRecalculatedAllTestResults');
            snackbarVariant = 'success';
          }
          showSnackbar(message, {
            variant: snackbarVariant,
          });
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      },
    },
    {
      title: t('common.viewReports'),
      onClick: async (organization: Organization) => {
        navigate(`/admin/organizations/${organization.id}/reports`);
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      <PageHeader
        title={t('common.organizations')}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedOrganization(null);
              setOpen(true);
            }}
          >
            {t('common.new')}
          </Button>
        }
        onBackClick={() => navigate('/')}
      />

      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Grid />
        <Grid
          item
          py={{
            xs: 0,
            sm: 3,
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>

      <BasicTable<Organization>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && (
        <OrganizationForm
          externalClinic={editedOrganization}
          handleSubmit={() => {
            setOpen(false);
            setEditedOrganization(null);
            if (editedOrganization) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
          handleClose={() => setOpen(false)}
        />
      )}
    </BasicContainer>
  );
}
