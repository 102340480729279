import { styled } from '@mui/material';
import { ShiftHours } from 'api/services/organization/types';
import {
  SimpleShiftCalendarRow,
  WeekDay,
} from 'components/shift-calendar/components';
import { useMemo } from 'react';
import { convertMilitaryTime } from 'utils';

export const HourHolder = styled('div')<any>(({ isCompactView }) => ({
  display: 'flex',
  flexDirection: isCompactView ? 'column' : 'row',
  alignItems: isCompactView ? 'flex-start' : 'center',
  gap: '10px',
  margin: '5px 0',
}));

export const WeekDaysHolder = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
}));

export const HoursRangeHolder = styled('div')<any>(({ isCompactView }) => ({
  whiteSpace: 'nowrap',
  minWidth: '130px',
  fontFamily: isCompactView ? 'DM Mono, monospace' : 'inherit',
  fontSize: isCompactView ? '14px' : 'inherit',
  fontWeight: isCompactView ? 500 : 'inherit',
  lineHeight: isCompactView ? '21px' : 'inherit',
  letterSpacing: isCompactView ? '-0.14px' : 'inherit',
}));

interface IShiftTimingsCellProps {
  hours: ShiftHours[];
  isCompactView?: boolean;
}

export function ShiftTimingsCell(props: IShiftTimingsCellProps) {
  const { hours, isCompactView } = props;

  const timings = useMemo(() => {
    const resultHours: { [key: string]: number[] } = {};
    [...hours]
      .sort((a, b) => (a.start > b.start ? 1 : -1))
      .forEach((hour) => {
        const key = `${convertMilitaryTime(hour.start)} - ${convertMilitaryTime(
          hour.end,
        )}`;

        if (resultHours[key] === undefined) {
          resultHours[key] = [];
        }

        resultHours[key].push(Number(hour.day));
      });
    return resultHours;
  }, [hours]);

  return (
    <div>
      {Object.keys(timings).map((hourKey) => (
        <HourHolder isCompactView={isCompactView}>
          <HoursRangeHolder isCompactView={isCompactView}>
            {hourKey}
          </HoursRangeHolder>
          <WeekDaysHolder>
            {new Array(7).fill(0).map((_, day) => (
              <SimpleShiftCalendarRow
                key={day}
                dailySchedule={{
                  isWorkingDay: timings[hourKey].includes(day),
                  workingPeriods: [],
                }}
                title={WeekDay[day]}
                onChangeDayStatus={() => null}
                readOnly
                size={isCompactView ? 'small' : 'middle'}
              />
            ))}
          </WeekDaysHolder>
        </HourHolder>
      ))}
    </div>
  );
}
