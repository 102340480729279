import {
  AuthActions,
  AuthState,
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_LOGIN_USER,
  SET_AUTH_ERROR,
  SET_MFA_REQUIRED,
  LOGOUT_USER_REQUEST,
} from './types';

const initialState: AuthState = {
  user: null,
  error: null,
  loading: false,
  token: null,
  deviceToken: null,
  mfaRequired: false,
  mfaMethods: [],
  mfaUserId: null,
};

export const authReducer = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case SET_LOGIN_USER:
      return { ...state, user: action.payload };
    case SET_AUTH_ERROR:
      return { ...state, error: action.payload };
    case SET_AUTH_LOADING:
      return { ...state, loading: action.payload };
    case SET_AUTH:
      return { ...state, ...action.payload };
    case SET_MFA_REQUIRED:
      return { ...state, ...action.payload };
    case LOGOUT_USER_REQUEST:
      return { ...state, ...initialState, deviceToken: state.deviceToken };
    default:
      return state;
  }
};
