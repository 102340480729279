import { BasicContainer } from 'components/container';
import { Header } from 'components/header';
import { ParticipantOwnHearingReportPage } from 'components/participant-hearing-report/ParticipantOwnHearingReportPage';

export function ParticipantOwnHearingTestResultsPage() {
  return (
    <BasicContainer>
      <Header
        isEmpty
        logo={
          <img
            alt="logo"
            src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
            width="88px"
          />
        }
      />
      <ParticipantOwnHearingReportPage />
    </BasicContainer>
  );
}
