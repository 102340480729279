import { Cell } from 'components/table/types';
import { TableCellText } from './components/TableCellText';
import { IHCPSupervisorLocation } from '../../../../api/services/organization/types';
import { LinkCell } from '../../../../components/table/components';

export const cells: Cell<IHCPSupervisorLocation>[] = [
  {
    name: 'Name',
    key: 'name',
    render: (item: IHCPSupervisorLocation) => (
      <TableCellText>{item.name}</TableCellText>
    ),
    sortable: true,
    orderByValue: 'name',
  },
  {
    name: 'External ID',
    key: 'externalId',
    align: 'center',
  },
  {
    name: 'Number of active participants',
    key: 'participantsCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) => (
      <LinkCell
        to={`/hcp-supervisor/locations/${item.id}/participants`}
        label={item.participantsCount || '0'}
      />
    ),
  },
  {
    name: 'Site Manager(s)',
    key: 'siteManagers',
    align: 'left',
    render: (item: IHCPSupervisorLocation) =>
      item.siteManagers.length ? (
        <LinkCell
          to={`/hcp-supervisor/${item.managingOrganization}/locations/${item.id}/site-managers`}
          label={
            <>
              {item.siteManagers.map(({ given, family }, index) => (
                <TableCellText>
                  {[given, family].join(' ')}
                  {index !== item.siteManagers.length - 1 ? ',' : ''}
                </TableCellText>
              ))}
            </>
          }
        />
      ) : (
        '-'
      ),
  },
  {
    name: 'Visits',
    key: 'visitsCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) => (
      <LinkCell
        to={`/hcp-supervisor/locations/${item.id}/visits`}
        label={item.visitsCount || '0'}
      />
    ),
  },
  {
    name: 'Completed in the last 12 months %',
    key: 'participantsTestedLastYearCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) =>
      `${
        Math.round(
          (item.participantsTestedLastYearCount / item.participantsCount) * 100,
        ) || 0
      }%`,
  },
  {
    name: 'Employees with STS %',
    key: 'participantsWithSTSCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) =>
      `${
        Math.round(
          (item.participantsWithSTSCount / item.participantsCount) * 100,
        ) || 0
      }%`,
  },
  {
    name: 'OSHA Recordable Shift',
    key: 'participantsWithRecordableOSHAShiftCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) =>
      `${
        Math.round(
          (item.participantsWithRecordableOSHAShiftCount /
            item.participantsCount) *
            100,
        ) || 0
      }%`,
  },
  {
    name: 'Tests needed #',
    key: 'participantsWithSTSCount',
    align: 'center',
    render: (item: IHCPSupervisorLocation) =>
      item.participantsCount - item.participantsTestedLastYearCount || '0',
  },
];
