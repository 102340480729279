import { InputAdornment, TextFieldProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { TextInput } from './TextInput';

type NumberFormatInputProps = {
  name: string;
  onChange: (arg: { target: { name: string; value: string } }) => void;
};

const NumberTextInput = styled(NumericFormat)({
  height: '56px',
  width: '100%',
  fontSize: '1rem',
  fontFamily: 'PlusJakartaSans, sans-serif',
  border: 'none',
  ':focus': {
    outline: 'none',
  },
});

const NumberFormatInput = forwardRef(
  (
    { onChange, name, value }: NumericFormatProps & NumberFormatInputProps,
    ref,
  ) => (
    <NumberTextInput
      onValueChange={({ value }) => {
        onChange({
          target: { name, value },
        });
      }}
      value={value}
      valueIsNumericString
      getInputRef={ref}
    />
  ),
);

const InputPrefix = styled(InputAdornment)({
  '& .MuiTypography-root': {
    lineHeight: '1.4375em',
  },
});

export function PhoneInput(
  props: TextFieldProps &
    Omit<NumberFormatInputProps, 'name'> & { name?: string },
) {
  const { t } = useTranslation();
  const {
    type = 'phone',
    name = 'phone',
    label = t('common.phone'),
    variant = 'outlined',
    id = 'outlined-basic',
    fullWidth = true,
    ...restProps
  } = props;

  return (
    <TextInput
      type={type}
      name={name}
      label={label}
      variant={variant}
      id={id}
      fullWidth={fullWidth}
      InputProps={{
        inputComponent: NumberFormatInput as any,
        startAdornment: (
          <InputPrefix sx={{ lineHeight: '1.4375em' }} position="start">
            +1
          </InputPrefix>
        ),
      }}
      {...restProps}
    />
  );
}
