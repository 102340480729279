import { ButtonProps, Button as ButtonComponent, styled } from '@mui/material';
import { ElementType } from 'react';

const StyledButton = styled(ButtonComponent)({
  boxShadow: 'none',
  borderRadius: '8px',
  textTransform: 'unset',
  fontWeight: '700',

  '&.MuiButton-containedSizeSmall': {
    height: '24px',
    fontSize: '11px',
    lineHeight: '16px',
  },
  '&.MuiButton-containedSizeMedium': {},
  '&.MuiButton-containedSizeLarge': {
    height: '48px',
    padding: '8px 16px',
    fontSize: '15px',
    lineHeight: '24px',
  },
});

export function Button<C extends ElementType>(
  props: ButtonProps<C, { component?: C }>,
) {
  const { children, ...restProps } = props;

  return (
    <StyledButton variant="contained" color="secondary" {...restProps}>
      {children}
    </StyledButton>
  );
}
