export const AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED_OPTIONS = [
  { value: 3, label: '3 months' },
  { value: 6, label: '6 months' },
  { value: 9, label: '9 months' },
  { value: 12, label: '12 months' },
];
export const DEFAULT_AMOUNT_OF_MONTHS_NEW_EMPLOYEE_SHOULD_BE_TESTED = 12;

export const AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE_OPTIONS =
  [
    { value: 1, label: '1 month' },
    { value: 3, label: '3 months' },
    { value: 6, label: '6 months' },
  ];
export const DEFAULT_AMOUNT_OF_MONTHS_EMPLOYEE_SHOULD_BE_TESTED_AFTER_DISABILITY_LEAVE = 1;
