import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Dialog, Grid, Typography } from '@mui/material';
import { Button } from 'components/common/button';
import { Participant } from 'api/services/auth/types';
import { Radio } from 'components/common/input';

interface SendQuestionnaireFormProps {
  opened: boolean;
  title: string;
  participant?: Participant;
  handleClose: () => void;
  handleSubmit: (
    participantId: number,
    invitationType:
      | 'phone-questionnaire-invitation'
      | 'email-questionnaire-invitation'
      | 'questionnaire-invitation',
  ) => void;
}

export function SendQuestionnaireForm(props: SendQuestionnaireFormProps) {
  const { opened, title, participant, handleSubmit, handleClose } = props;
  const [invitationType, setInvitationType] = useState<
    | 'phone-questionnaire-invitation'
    | 'email-questionnaire-invitation'
    | 'questionnaire-invitation'
    | null
  >(null);

  const handleRelationshipChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvitationType((event.target as any).value);
  };

  useEffect(() => {
    if (participant?.email || participant?.phone) {
      if (!participant?.email) {
        setInvitationType('phone-questionnaire-invitation');
      }
      if (!participant?.phone) {
        setInvitationType('email-questionnaire-invitation');
      }
    }
  }, [participant]);

  const items = useMemo(
    () => [
      {
        value: 'email-questionnaire-invitation',
        label: 'Email',
        disabled: !participant?.email,
      },
      {
        value: 'phone-questionnaire-invitation',
        label: 'Text Message',
        disabled: !participant?.phone,
      },
      {
        value: 'questionnaire-invitation',
        label: 'Both',
        disabled: !participant?.email || !participant?.phone,
      },
    ],
    [participant],
  );

  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="xs" fullWidth>
      <Grid p={3}>
        <Grid mb={2}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid container direction="column">
          <Radio
            items={items}
            value={invitationType || ''}
            onChange={handleRelationshipChange}
            sx={{ paddingLeft: 1, paddingBottom: 1 }}
            labelProps={{ sx: { fontSize: '14px' } }}
          />

          <Grid item container justifyContent="flex-end" gap={2}>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={() => {
                  if (participant?.id && invitationType) {
                    handleSubmit(participant.id, invitationType);
                    handleClose();
                  }
                }}
                sx={{
                  width: '100%',
                }}
                disabled={!invitationType}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
