import { useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from 'api';
import { FullHeightContainer } from 'components/container';
import { MfaMethod } from 'api/services/auth/types';
import { Button } from 'components/common/button';
import { FormAlert } from 'components/alert';
import { TextInput } from 'components/common/input';
import { getErrorMessageComponent } from 'utils';
import { convertPhoneToInternationalFormat } from 'utils/phoneNumber';
import { Loader } from 'components/common/loader';
import { VerificationHeaderText } from '../components/Shared';
import {
  ResendVerificationCode,
  ResendVerificationCodeProps,
} from '../components/ResendVerificationCode';

export type ConfirmPhoneVerificationStepProps = {
  phone?: string;
  isPhoneVerified?: boolean;
  onFinish: () => void;
} & Pick<ResendVerificationCodeProps, 'onResendCode'>;

const initialValues = {
  code: '',
};

const ConfirmCodeSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
});

export function ConfirmPhoneVerificationStep({
  phone,
  onFinish,
  onResendCode,
}: ConfirmPhoneVerificationStepProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    try {
      await api.user.verifyMfaVerificationCode(MfaMethod.PHONE, values.code);
      onFinish();
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message);
    }
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={ConfirmCodeSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FullHeightContainer maxWidth="xs" disableGutters>
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={0.5}
          >
            <VerificationHeaderText />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {phone && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      sx={{
                        marginBottom: theme.spacing(4),
                        fontSize: theme.spacing(1.6),
                      }}
                    >
                      We&apos;ve sent a verification code to{' '}
                      {convertPhoneToInternationalFormat(phone)}. Please enter
                      code below
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    id="outlined-basic"
                    label="Code"
                    variant="outlined"
                    type="text"
                    name="code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{ shrink: true }}
                    value={values.code}
                    error={touched.code && Boolean(errors.code)}
                    // A ' ' is a hack to always have space for error message, can crete a better approach later
                    helperText={touched.code && errors.code ? errors.code : ' '}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      {error ? (
                        <FormAlert severity="error">
                          {getErrorMessageComponent(error)}
                        </FormAlert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                      >
                        Verify
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <ResendVerificationCode onResendCode={onResendCode} />
              </>
            )}
          </Grid>
        </FullHeightContainer>
      )}
    </Formik>
  );
}
