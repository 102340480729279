import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { useEffect, useMemo, useState } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { DashboardSelect } from 'components/dashboard-select';
import { useLocationIdSearchParam } from 'hooks/search-params/useLocationIdSearchParam';
import { DashboardTabs } from '../../components/tabs';
import { ITab } from '../../components/tabs/dashboard-tabs/types';
import { SiteManagerParticipantsList } from './site-manager-participants';
import { useAuthSelector } from '../../store/selectors/auth';
import { useSiteManagerDashboardSelector } from '../../store/selectors/dashboard';
import {
  requestSiteManagerLocationsAction,
  setSiteManagerLocationsError,
  setSiteManagerSelectedLocation,
} from '../../store/reducers/dashboard/site-manager/actions';
import { getErrorMessageComponent, showSnackbar } from '../../utils';
import { SiteManagerLocationVisitsList } from './site-manager-location-visits';
import { SiteManagerSettings } from './site-manager-settings';
import { api } from '../../api';
import { SiteManagerLocationMetrics } from './site-manager-location-metrics';

export function SiteManagerDashboard() {
  useIdentity();

  const { user } = useAuthSelector();

  const { selectedLocation, loading, locations, error } =
    useSiteManagerDashboardSelector();

  const dispatch = useDispatch();

  useLocationIdSearchParam({
    selectedLocation,
    locations,
    onChange: (location) => dispatch(setSiteManagerSelectedLocation(location)),
  });

  useEffect(() => {
    if (user?.id) dispatch(requestSiteManagerLocationsAction(user.id));
  }, [user]);

  useEffect(() => {
    if (error)
      showSnackbar(getErrorMessageComponent(error), {
        variant: 'error',
      });
    dispatch(setSiteManagerLocationsError(null));
  }, [error]);
  const [isScheduleTabDisabled, setScheduleTabDisabled] = useState(true);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!selectedLocation) return;
      try {
        const data = await api.organization.getLocationVisits(
          selectedLocation.id,
          { limit: 1, offset: 0, shared: true },
        );
        if (data.items.length) {
          setScheduleTabDisabled(false);
        } else {
          setScheduleTabDisabled(true);
        }
      } catch (e) {
        console.log(e);
      }
    };
    asyncRequest();
  }, [selectedLocation]);

  const tabs = useMemo<ITab[]>(
    () => [
      {
        label: 'Employees',
        component: <SiteManagerParticipantsList />,
        icon: <PeopleIcon fontSize="small" />,
      },
      {
        label: 'Schedule',
        component: <SiteManagerLocationVisitsList />,
        icon: <DateRangeIcon fontSize="small" />,
        disabled: isScheduleTabDisabled,
      },
      {
        label: 'Metrics',
        component: <SiteManagerLocationMetrics />,
        icon: <EqualizerIcon fontSize="small" />,
      },
      {
        label: 'Settings',
        component: <SiteManagerSettings />,
        icon: <SettingsIcon fontSize="small" />,
      },
    ],
    [isScheduleTabDisabled],
  );

  const theme = useTheme();
  return (
    <BasicContainer>
      <DashboardTabs
        tabs={tabs}
        title={
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sx={{
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                margin: { xs: '0 auto', sm: '0' },
              }}
            >
              {user?.siteManager?.organization?.name ? (
                <>
                  <Grid item>{user.siteManager?.organization?.name}</Grid>{' '}
                  <Grid item mx={theme.spacing(1)}>
                    <img
                      alt="x"
                      src="/images/icons/x_icon_24.svg"
                      width="20px"
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item mt="10px">
                <img
                  alt="logo"
                  src="/images/logos/Tuned_Logo_SVG_128_dark.svg"
                  width="100px"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm="auto" mt={theme.spacing(2)}>
              <DashboardSelect
                label="Location"
                labelIcon="location"
                value={selectedLocation?.id || ''}
                options={locations.map((location) => ({
                  label: `${location?.name}${
                    location?.externalId ? ` (${location?.externalId})` : ''
                  }`,
                  value: location.id,
                }))}
                onChange={(event: any) => {
                  dispatch(
                    setSiteManagerSelectedLocation(
                      locations.find(
                        (location) => location.id === event.target.value,
                      ) || null,
                    ),
                  );
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        }
      />
    </BasicContainer>
  );
}
