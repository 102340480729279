export interface PaginatedData<T> {
  items: T[];
  total: number;
  limit: number;
  offset: number;
}

export const EMPTY_PAGINATED_DATA: PaginatedData<never> = {
  items: [],
  total: 0,
  limit: 0,
  offset: 0,
};
