import { Grid, Typography } from '@mui/material';
import { Heading } from 'components/common/heading';
import { BoldText } from './BoldText';
import { BoldUnderlinedText } from './BoldUnderlinedText';

function GeneralTermsOfUseContent() {
  return (
    <Grid
      item
      container
      sx={{ maxWidth: '842px', overflowWrap: 'anywhere' }}
      direction="column"
      alignItems="center"
    >
      <Typography variant="body1">
        <Heading variant="h5" sx={{ fontWeight: 700 }} align="center">
          Tuned Terms of Service
        </Heading>
        <p>
          <BoldText>Last Updated:</BoldText> 02/22/2023
        </p>
        <p>
          Welcome, and thank you for your interest in Tuned Care, Inc. (“
          <BoldUnderlinedText>Tuned,</BoldUnderlinedText>” “
          <BoldUnderlinedText>we,</BoldUnderlinedText>” “
          <BoldUnderlinedText>us,</BoldUnderlinedText>” or “
          <BoldUnderlinedText>our</BoldUnderlinedText>”). These Terms of Service
          are a legally binding contract between you and Tuned regarding your
          use of the Service. As used in these Terms of Service, the term “
          <BoldUnderlinedText>Service</BoldUnderlinedText>” means our
          applications and website at{' '}
          <a href="https://www.tunedcare.com/">https://www.tunedcare.com/</a>{' '}
          (or any successor domains), along with our related websites, networks,
          and other technology platform-related services provided directly by
          us. For clarity, “Service” excludes any material, services, products,
          or information provided to you by any party other than us (e.g., any
          Provider, as defined in Section 1). If you access and use the Service
          as a Provider, you also must agree to and be bound by the
          Provider-specific terms that we will make available to you during the
          Provider account creation process.
        </p>
        <BoldText>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</BoldText>
        <p>
          <BoldText>
            BY CLICKING “I ACCEPT,” OR OTHERWISE ACCESSING OR USING THE SERVICE
          </BoldText>{' '}
          YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO
          YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS
          AND CONDITIONS (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE OR
          DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE
          THE SERVICE. YOUR USE OF THE SERVICE, AND TUNED’S PROVISION OF THE
          SERVICE TO YOU, CONSTITUTE AN AGREEMENT BY TUNED AND BY YOU TO BE
          BOUND BY THESE TERMS.
        </p>
        <p>
          <BoldText>ARBITRATION NOTICE.</BoldText> Except for certain kinds of
          disputes described in Section 19, you agree that disputes arising
          under these Terms will be resolved by binding individual arbitration,
          and BY ACCEPTING THESE TERMS, YOU AND TUNED ARE EACH WAIVING THE RIGHT
          TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
          REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO
          COURT to assert or defend your rights under this contract (except for
          matters that may be taken to small claims court). Your rights will be
          determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See
          Section 19.)
        </p>
        <ol type="1">
          <li>
            <BoldText>Tuned Service Overview.</BoldText>
            The Service is designed to help connect individuals with licensed
            audiologists who are independent contractors and who may offer
            screenings and consultations through our technology platform (“
            <BoldUnderlinedText>Providers</BoldUnderlinedText>”). The Service
            also may provide you with information or access to certain products
            supplied by third parties, as well as information about or products
            and services.
          </li>
          <li>
            <BoldText>Eligibility.</BoldText>
            By agreeing to these Terms, you represent, warrant, and covenant to
            us that: (a) either you are at least 18 years of age or have been
            authorized to use the Service by your parent or legal guardian who
            is at least 18 years of age and who agrees to these Terms and will
            supervise your use of the Service; (b) you have not previously been
            suspended or removed from the Service; and (c) your use of the
            Service is in compliance with any and all applicable laws and
            regulations.
          </li>
          <li>
            <BoldText>Provider Independence.</BoldText>
            WITHOUT LIMITING ANY OTHER TERMS IN THESE TERMS:
            <p>
              THE SERVICE, AND ALL MATERIALS AND CONTENT PROVIDED BY TUNED ON OR
              THROUGH THE SERVICE, DO NOT CONSTITUTE MEDICAL OR CLINICAL
              PROFESSIONAL ADVICE, DIAGNOSIS, OR TREATMENT OF ANY MEDICAL
              CONDITION OR HEALTH PROBLEM.
            </p>
            <p>
              YOUR INTERACTIONS WITH ANY PROVIDER (INCLUDING DURING SCREENINGS,
              CONSULTATIONS, MESSAGING, OR ANY PROVISION OF CARE IN CONNECTION
              WITH THE SERVICE (EACH, A “
              <BoldUnderlinedText>Consultation</BoldUnderlinedText>
              ”)), AND ANY OPINIONS, RECOMMENDATIONS, MATERIALS, INFORMATION, OR
              OTHER TERMS, CONDITIONS, WARRANTIES OR REPRESENTATIONS MADE
              AVAILABLE TO YOU BY ANY PROVIDER (INCLUDING IN CONNECTION WITH ANY
              CONSULTATIONS), ARE SOLELY BETWEEN YOU AND THE PROVIDER.
            </p>
            <p>
              YOU ARE NOT REQUIRED TO USE ANY PROVIDER OR ANY PRODUCT MADE
              AVAILABLE VIA THE SERVICE OR THROUGH ANY INTERACTION YOU HAVE WITH
              A PROVIDER. YOU MUST EXERCISE DUE DILIGENCE AND CARE WHEN DECIDING
              WHETHER TO ENGAGE WITH A PROVIDER.
            </p>
            <p>
              TUNED DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES, OR OTHER
              COMMITMENTS OR GUARANTEES IN CONNECTION WITH ANY PROVIDER, OTHER
              USERS YOU MAY ENCOUNTER, OR ANY INFORMATION, SERVICE, OR PRODUCTS
              PROVIDED BY A THIRD PARTY ON THE SERVICE. WITHOUT LIMITING THE
              FOREGOING, TUNED DOES NOT ENDORSE ANY PARTICULAR PROVIDER,
              PRODUCT, OR TREATMENT METHOD OR TECHNIQUE, AND SHALL IN NO EVENT
              BE LIABLE TO YOU OR TO ANYONE FOR ANY DECISION MADE OR ACTION
              TAKEN OR NOT TAKEN BY ANY PARTY IN RELIANCE ON INFORMATION ARISING
              FROM ANY USE OF THE SERVICE (INCLUDING ANY INTERACTIONS OR
              CONSULTATIONS WITH PROVIDERS).
            </p>
            <p>
              YOU AGREE THAT ANY PROVIDER YOU CONSULT WITH VIA THE SERVICE WILL
              BE SOLELY AND EXCLUSIVELY RESPONSIBLE AND LIABLE FOR ALL TREATMENT
              DECISIONS AND PROFESSIONAL OR AUDIOLOGICAL SERVICES AND
              INFORMATION (INCLUDING ANY DECISION TO OFFER SUCH SERVICES OR
              INFORMATION TO ANY PARTICULAR USER), AND TUNED WILL NEITHER HAVE
              NOR EXERCISE ANY CONTROL OR DISCRETION OVER THE METHODS BY WHICH
              ANY PROVIDER PRACTICES MEDICINE OR AUDIOLOGY.
            </p>
            <p>
              NOTHING IN THESE TERMS WILL BE CONSTRUED TO ALTER OR OTHERWISE
              AFFECT THE LEGAL, ETHICAL OR PROFESSIONAL RELATIONSHIPS BETWEEN
              AND AMONG YOU AND PROVIDERS, NOR DOES ANYTHING IN THESE TERMS
              ABROGATE ANY RIGHT, PRIVILEGE, OR OBLIGATION ARISING FROM OR
              RELATED TO THE PHYSICIAN-PATIENT RELATIONSHIP.
            </p>
          </li>

          <li>
            <BoldText>No Endorsement; Third-Party Obligations.</BoldText>
            Tuned may make certain products available for purchase through the
            Service, and it may receive compensation as the result of sales
            through the Service of certain products. The Service’s listing or
            identification of a product is for informational purposes only and
            in no way constitutes Tuned’s recommendation or guarantee of the
            fitness of a product for any particular purpose, nor does it
            constitute an endorsement, sponsorship, approval, or affiliation by
            Tuned of any product or any affiliation between Tuned and the
            product provider. All products ordered through the Service are
            delivered to you by a third-party, and Tuned is not the manufacturer
            or supplier of any such products. YOU ARE NOT OBLIGATED TO BUY OR
            USE ANY PRODUCTS, AND YOU MAY PURCHASE THROUGH ANOTHER STORE OR
            VENDOR. YOUR DECISION TO NOT BUY ANYTHING WILL NOT IMPACT OTHER
            SERVICES PROVIDED THROUGH THE PLATFORM. IF THERE IS ANY PROBLEM WITH
            THE DELIVERY, FUNCTIONALITY, OR OTHERWISE WITH RESPECT TO ANY
            PRODUCT THAT YOU PURCHASE THROUGH THE SERVICE, YOU MUST CONTACT THE
            SUPPLIER OF THE PRODUCT DIRECTLY, AND TUNED HAS NO OBLIGATION OR
            LIABILITY TO YOU WHATSOEVER WITH RESPECT TO ANY SUCH ISSUES.
          </li>
          <li>
            <BoldText>Accounts and Registration.</BoldText>
            To access certain features of the Service, you will need to register
            for an account. When you register for an account, you may be
            required to provide us with some information about yourself. You
            agree that the information you provide to us is accurate and that
            you will keep it accurate and up-to-date at all times. You are
            solely responsible for maintaining the confidentiality of your
            account, and you accept responsibility for all activities that occur
            under your account. If you believe that your account is no longer
            secure, then you must immediately notify us at
            support@TunedCare.com.
          </li>
          <li>
            <BoldText>Payment and Fees.</BoldText>
            You may be required to pay fees for your access to certain products,
            experiences, and functionalities on or available through the
            Service. All fees and accepted payment methods will be identified in
            the applicable portion of the Service. We may change the fees and
            accepted payment methods at any time with prior notice to you. If
            your employer offers Tuned access and benefits as part of a benefit
            plan, fees and payment may be subject in whole or part to that plan,
            and you are solely responsible for coordinating with your employer
            to understand who is responsible for what fees. If you are a
            Provider, please see the Provider Terms of Service (available
            through your Provider account) for additional terms applicable to
            fees. You must pay all fees due without set-off, deduction, or
            reduction of any kind or for any reason.
          </li>
          <li>
            <BoldText>Licenses</BoldText>
            <p>
              <BoldText>7.1 Limited License.</BoldText>
              Subject to your complete and ongoing compliance with these Terms,
              Tuned grants you, as a user in any capacity other than as a
              Provider, a limited, non-exclusive, non-transferable,
              non-sublicensable, revocable license to: (a) access and use the
              Service; and (b) access and use any content we make available to
              you on the Service, in the case of clause (a) and (b) solely for
              your personal, non-commercial use.
            </p>
            <p>
              <BoldText>7.2 License Restrictions.</BoldText>
              Except and solely to the extent such a restriction is
              impermissible under applicable law, you may not: (a) reproduce,
              distribute, publicly display, or publicly perform the Service; (b)
              make modifications to the Service; or (c) interfere with or
              circumvent any feature of the Service, including any security or
              access control mechanism. If you are prohibited under applicable
              law from using the Service, you may not use it.
            </p>
          </li>
          <li>
            <BoldText>Feedback.</BoldText>
            If you provide any ideas, input, suggestions, or feedback to us
            regarding the Service, Tuned, or our business (including any
            regarding your experience using or impressions regarding, or
            proposed modifications or improvements to, the Service)
            (collectively, “<BoldUnderlinedText>Feedback</BoldUnderlinedText>”),
            then you hereby grant Tuned a perpetual, irrevocable, non-exclusive,
            transferable, fully-paid, royalty-free, and worldwide right, with
            the right to grant and authorize sublicenses, to use, modify,
            disclose, and otherwise exploit the Feedback in any manner and for
            any purpose, including to improve the Service and create other
            products and services, without limitation, restriction, or any fees
            or credit due to you.
          </li>
          <li>
            <BoldText>Ownership; Proprietary Rights.</BoldText>
            The Service is owned and operated by Tuned. The visual interfaces,
            graphics, design, compilation, information, data, computer code
            (including source code or object code), products, software,
            services, content, and all other elements of the Service (“
            <BoldUnderlinedText>Materials</BoldUnderlinedText>”) provided by
            Tuned are protected by intellectual property and other laws. All
            Materials included in the Service are the property of Tuned or its
            third-party licensors. Except as expressly authorized by Tuned, you
            may not otherwise make use of the Materials. Tuned reserves all
            rights to the Materials not granted expressly in these Terms.
          </li>
          <li>
            <BoldText>Third-Party Terms</BoldText>
            <p>
              <BoldText>
                10.1 Third-Party Services and Linked Websites.
              </BoldText>
              Tuned may provide tools through the Service that enable you to
              transmit information to or through third-party services. By using
              one of these tools, you agree that Tuned may transfer that
              information to or through the applicable third-party service.
              Third-party services are not under Tuned’s control, and, to the
              fullest extent permitted by law, Tuned is not responsible for any
              third-party service’s use of your exported information. The
              Service may also contain links to third-party websites. Linked
              websites are not under Tuned’s control, and Tuned is not
              responsible for their content.
            </p>
            <p>
              <BoldText>10.2 Third-Party Software.</BoldText>
              The Service may include or incorporate third-party software
              components that are generally available free of charge under
              licenses granting recipients broad rights to copy, modify, and
              distribute those components (“
              <BoldUnderlinedText>Third-Party Components</BoldUnderlinedText>
              ”). Although the Service is provided to you subject to these
              Terms, nothing in these Terms prevents, restricts, or is intended
              to prevent or restrict you from obtaining Third-Party Components
              under the applicable third-party licenses or to limit your use of
              Third-Party Components under those third-party licenses.
            </p>
          </li>
          <li>
            <BoldText>User Content</BoldText>
            <p>
              <BoldText>11.1 User Content Generally.</BoldText>
              Certain features of the Service may permit users to transmit
              content to the Service, including responses to survey questions
              regarding your experience and other materials depicted therein,
              Consultations, private messages with other users (including
              between users and Providers), educational content uploaded to
              Tuniversity, and other content (collectively, “
              <BoldUnderlinedText>User Content</BoldUnderlinedText>
              ”). You own the User Content that you transmit to the Service.
            </p>
            <p>
              <BoldText>11.2 Limited License Grant to Tuned.</BoldText>y
              transmitting User Content to or via the Service or responding to
              survey questions regarding your experience with the service and
              any other user, you grant Tuned a non-exclusive, perpetual,
              irrevocable, transferable, sublicensable (through various tiers),
              royalty-free, and worldwide right and license, to the fullest
              extent permitted by law, to use, host, store, reproduce, create
              derivative works of, transfer, display, perform, modify, and
              distribute your User Content to provide the Service and as further
              described in our Privacy Policy.
            </p>
            <p>
              <BoldText>
                11.3 User Content Representations and Warranties.
              </BoldText>
              Tuned disclaims any and all liability in connection with User
              Content. You are solely responsible for your User Content and the
              consequences of providing User Content via the Service. By
              providing User Content via the Service, you affirm, represent, and
              warrant that:
              <ol type="a">
                <li>
                  you have the necessary licenses, rights, consents, and
                  permissions to authorize Tuned to use and distribute your User
                  Content as necessary to exercise the licenses granted by you
                  in this Section, in the manner contemplated by Tuned, the
                  Service, and these Terms; and
                </li>
                <li>
                  your User Content, and the use of your User Content as
                  contemplated by these Terms, does not and will not: (i)
                  infringe, violate, or misappropriate any third-party right,
                  including any copyright, trademark, patent, trade secret,
                  moral right, privacy right, right of publicity, or any other
                  intellectual property or proprietary right; (ii) slander,
                  defame, libel, or invade the right of privacy, publicity or
                  other property rights of any other person; or (iii) cause
                  Tuned to violate any law or regulation; and
                </li>
                <li>
                  your User Content could not be deemed by a reasonable person
                  to be objectionable, profane, indecent, pornographic,
                  harassing, threatening, embarrassing, hateful, or otherwise
                  inappropriate.
                </li>
              </ol>
            </p>
            <p>
              <BoldText>11.4 User Content Disclaimer.</BoldText>
              We are under no obligation to edit or control User Content
              transmitted to the Service, and will not be in any way responsible
              or liable for User Content. You agree to waive, and do waive, any
              legal or equitable right or remedy you have or may have against
              Tuned with respect to User Content.
            </p>
            <p>
              <BoldText>11.5 Monitoring Content.</BoldText>
              You acknowledge and agree that Tuned reserves the right to, and
              may from time to time, monitor any and all information transmitted
              or received through the Service for operational and other
              purposes, but Tuned has no obligation to do so. If at any time
              Tuned chooses to monitor the content, Tuned still assumes no
              responsibility or liability for content or any loss or damage
              incurred as a result of the use of content. During monitoring,
              information may be examined, recorded, copied, and used in
              accordance with our Privacy Policy.
            </p>
          </li>
          <li>
            <BoldText>Digital Millennium Copyright Act</BoldText>
            <p>
              <BoldText>12.1 DMCA Notification.</BoldText>
              We comply with the provisions of the Digital Millennium Copyright
              Act applicable to Internet service providers (17 U.S.C. §512, as
              amended). If you have an intellectual property rights-related
              complaint about material posted on the Service, you may contact
              our Designated Agent at the following address:
              <br />
              <br />
              Tuned Care, Inc.
              <br />
              320 7th Ave #291
              <br />
              Brooklyn, NY 11215
              <br />
              <br />
              Any notice alleging that materials hosted by or distributed
              through the Service infringe intellectual property rights must
              include the following information:
              <ol type="a">
                <li>
                  an electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright or other right
                  being infringed;
                </li>
                <li>
                  a description of the copyrighted work or other intellectual
                  property that you claim has been infringed;
                </li>
                <li>
                  a description of the material that you claim is infringing and
                  where it is located on the Service;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that the above information in your notice
                  is accurate and that, under penalty of perjury, you are the
                  copyright or intellectual property owner or authorized to act
                  on the copyright or intellectual property owner’s behalf.
                </li>
                <li>
                  a statement by you that the above information in your notice
                  is accurate and that, under penalty of perjury, you are the
                  intellectual property owner or authorized to act on the
                  intellectual property owner’s behalf.
                </li>
              </ol>
            </p>
            <p>
              <BoldText>12.2 Repeat Infringers.</BoldText>
              Tuned will promptly terminate the accounts of users that are
              determined by Tuned to be repeat infringers.
            </p>
          </li>
          <li>
            <BoldText>Prohibited Conduct.</BoldText>
            BY USING THE SERVICE YOU AGREE NOT TO:
            <p>
              <ol type="a">
                <li>
                  use the Service for any illegal purpose or in violation of any
                  local, state, national, or international law;
                </li>
                <li>
                  harass, threaten, demean, embarrass, or otherwise harm any
                  other user of the Service;
                </li>
                <li>
                  violate, or encourage others to violate, any right of a
                  third-party, including by infringing or misappropriating any
                  third-party intellectual property right;
                </li>
                <li>
                  interfere with security-related features of the Service,
                  including by: (i) disabling or circumventing features that
                  prevent or limit use or copying of any content; or (ii)
                  reverse engineering or otherwise attempting to discover the
                  source code of any portion of the Service except to the extent
                  that the activity is expressly permitted by applicable law;
                </li>
                <li>
                  interfere with the operation of the Service or any user’s
                  enjoyment of the Service, including by: (i) uploading or
                  otherwise disseminating any virus, adware, spyware, worm, or
                  other malicious code; (ii) making any unsolicited offer or
                  advertisement to another user of the Service; (iii) collecting
                  personal information about another user or third-party without
                  consent; or (iv) interfering with or disrupting any network,
                  equipment, or server connected to or used to provide the
                  Service;
                </li>
                <li>
                  perform any fraudulent activity including impersonating any
                  person or entity, claiming a false affiliation, accessing any
                  other Service account without permission, or falsifying your
                  age or date of birth;
                </li>
                <li>
                  sell or otherwise transfer the access granted under these
                  Terms or any Materials or any right or ability to view,
                  access, or use any Materials; or
                </li>
                <li>
                  attempt to do any of the acts described in this Section 13 or
                  assist or permit any person in engaging in any of the acts
                  described in this Section 13.
                </li>
              </ol>
            </p>
          </li>
          <li>
            <BoldText>Modification of these Terms.</BoldText>
            We reserve the right to change these Terms on a going-forward basis
            at any time upon at least 7 days advance notice. Please check these
            Terms periodically for changes. If a change to these Terms
            materially modifies your rights or obligations, we may require that
            you accept the modified Terms in order to continue to use the
            Service. Material modifications are effective upon your acceptance
            of the modified Terms. Immaterial modifications are effective upon
            publication. Except as expressly permitted in this Section 14, these
            Terms may be amended only by a written agreement signed by
            authorized representatives of the parties to these Terms. Disputes
            arising under these Terms will be resolved in accordance with the
            version of these Terms that was in effect at the time the dispute
            arose.
          </li>
          <li>
            <BoldText>
              Term, Termination and Modification of the Service
            </BoldText>
            <p>
              <BoldText>15.1 Term.</BoldText>
              These Terms are effective beginning when you first access or use
              the Service, and ending when terminated as described in Section
              15.2.
            </p>
            <p>
              <BoldText>15.2 Termination.</BoldText>
              If you violate any provision of these Terms, your authorization to
              access the Service and these Terms automatically terminate. In
              addition, Tuned may, at its sole discretion, terminate these Terms
              or your account on the Service, or suspend or terminate your
              access to the Service, at any time for any reason or no reason,
              with or without notice. You may terminate your account and these
              Terms at any time by closing your account through your account
              settings.
            </p>
            <p>
              <BoldText>15.3 Effect of Termination.</BoldText>
              Upon termination of these Terms: (a) your license rights will
              terminate and you must immediately cease all use of the Service;
              (b) you will no longer be authorized to access your account or the
              Service (c) you must pay Tuned any unpaid amount that was due
              prior to termination; and (d) the following Sections, and all
              defined terms used in those Sections for purposes of interpreting
              those Sections, will survive: 2, 3, 4, 5, 6, 7.2, 8, 9, 10, 11.2,
              13, 14, 15.3, 16, and 17-20.
            </p>
            <p>
              <BoldText>15.4 Modification of the Service.</BoldText>
              Tuned reserves the right to modify or discontinue the Service at
              any time (including by limiting or discontinuing certain features
              of the Service), temporarily or permanently, without notice to
              you, to the fullest extent permitted by applicable law. Tuned will
              have no liability for any change to the Service or any suspension
              or termination of your access to or use of the Service.
            </p>
          </li>
          <li>
            <BoldText>Indemnity.</BoldText>
            To the fullest extent permitted by law, you are responsible for your
            use of the Service, and you will defend and indemnify Tuned and its
            officers, directors, employees, consultants, affiliates,
            subsidiaries, and agents (together, the “
            <BoldUnderlinedText>Tuned Entities</BoldUnderlinedText>
            ”) from and against every claim brought by a third-party, and any
            related liability, damage, loss, and expense, including reasonable
            attorneys’ fees and costs, arising out of or connected with: (a)
            your unauthorized use of, or misuse of, the Service; (b) your
            violation of any portion of these Terms, any representation,
            warranty, or agreement referenced in these Terms, or any applicable
            law or regulation; (c) your violation of any third-party right,
            including any intellectual property right or publicity,
            confidentiality, other property, or privacy right; or (d) any
            dispute or issue between you and any third-party. We reserve the
            right, at our own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you
            (without limiting your indemnification obligations with respect to
            that matter), and in that case, you agree to cooperate with our
            defense of those claims.
          </li>
          <li>
            <BoldText>Disclaimers; No Warranties.</BoldText>
            THE FOLLOWING TERMS APPLY TO THE FULLEST EXTENT BY LAW:
            <p>
              THE SERVICE AND ALL ITEMS, MATERIALS, OR CONTENT AVAILABLE ON OR
              THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
              BASIS. TUNED DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
              OR IMPLIED, RELATING TO THE SERVICE AND ALL ITEMS, MATERIALS, OR
              CONTENT AVAILABLE ON OR THROUGH THE SERVICE, INCLUDING: (A) ANY
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY
              WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. TUNED
              DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE,
              OR ANY ITEMS, MATERIALS, OR CONTENT AVAILABLE ON OR THROUGH THE
              SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS,
              VIRUSES, OR OTHER HARMFUL COMPONENTS, AND TUNED DOES NOT WARRANT
              THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
            </p>
            <p>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM THE SERVICE OR TUNED ENTITIES OR ANY ITEMS, MATERIALS, OR
              CONTENT AVAILABLE ON OR THROUGH THE SERVICE WILL CREATE ANY
              WARRANTY.
            </p>
            <p>
              WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
              SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER OR ANY ITEMS,
              MATERIALS, OR CONTENT AVAILABLE ON OR THROUGH THE SERVICE. YOU
              UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT
              YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR
              ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
              MOBILE PRODUCT USED IN CONNECTION WITH THE SERVICE), ANY LOSS,
              USE, OR DISCLOSURE OF DATA, INCLUDING USER CONTENT.
            </p>
            <p>WITHOUT LIMITING ANY OTHER TERMS SET FORTH IN THESE TERMS:</p>
            <ul>
              <li>
                TUNED IS NOT A HEALTH INSURANCE COMPANY AND DOES NOT PROVIDE YOU
                WITH HEALTH INSURANCE BENEFITS. YOU ARE SOLELY RESPONSIBLE FOR
                IDENTIFYING AND UNDERSTANDING ANY THIRD PARTY PLANS OR BENEFITS
                YOU MAY HAVE THAT PERTAIN TO HEALTHCARE.
              </li>
              <li>
                TUNED HAS NO RESPONSIBILITY TO COORDINATE WITH YOUR EMPLOYER OR
                ANY THIRD PARTY IN CONNECTION WITH ANY OTHER BENEFITS OR PLANS
                YOU MAY HAVE THAT PERTAIN TO HEALTHCARE. YOU ARE SOLELY
                RESPONSIBLE FOR ALL SUCH COORDINATION.
              </li>
              <li>
                YOU UNDERSTAND THAT FEES YOU MAY BE OBLIGATED TO PAY IN
                CONNECTION WITH THESE TERMS DO NOT INCLUDE AND DO NOT COVER FEES
                THAT YOU MAY OWE TO ANY TREATMENT PROFESSIONAL OTHER THAN ANY
                PROVIDER.
              </li>
            </ul>
          </li>
          <li>
            <BoldText>Limitation of Liability</BoldText>
            <p>TO THE FULLEST EXTENT PERMITTED BY LAW:</p>
            <p>
              IN NO EVENT WILL THE TUNED ENTITIES BE LIABLE TO YOU FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
              (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER
              INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR
              USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY
              ITEMS, MATERIALS, OR CONTENT AVAILABLE ON OR THROUGH THE SERVICE,
              WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
              STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY TUNED
              ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
            </p>
            <p>
              EXCEPT AS PROVIDED IN SECTION 19.5 AND TO THE FULLEST EXTENT
              PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE TUNED ENTITIES TO
              YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
              INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER
              THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED
              TO $50.
            </p>
            <p>
              N PROVIDING THE SERVICE, TUNED PROVIDES A DIGITAL COMMUNICATION
              PLATFORM WHICH IS DESIGNED TO FACILITATE YOUR ACCESS TO HEARING
              HEALTH INFORMATION AND YOUR COMMUNICATION WITH OTHER USERS. TUNED
              DOES NOT ENGAGE IN THE PRACTICE OF MEDICINE OR PROVIDE ANY HEALTH
              SERVICES, BUT YOU CAN USE THE TUNED PLATFORM AND ITS COMMUNICATION
              TOOLS TO INTERACT WITH PROVIDERS, WHO CAN PROVIDE CONSULTATION AND
              SCREENING SERVICES TO YOU (OR, FOR PROVIDERS, TO INTERACT WITH
              PATIENTS). TUNED DOES NOT MONITOR OR OTHERWISE INTERFERE WITH THE
              PROFESSIONAL SERVICES PROVIDED BY PROVIDERS. PROVIDERS ARE SOLELY
              RESPONSIBLE FOR THE SERVICES THEY PROVIDE VIA THE SERVICE,
              INCLUDING ANY DETERMINATION OF WHETHER SUCH SERVICES MAY BE
              PROVIDED TO A USER IN ANY SPECIFIC LOCATION OR JURISDICTION, AND
              ANY CONTENT OR INFORMATION COMMUNICATED BY SUCH PROVIDERS.
              PROVIDERS MAY ACCEPT OR DECLINE TO PROVIDE THEIR PROFESSIONAL
              SERVICES TO ANY USER IN THEIR SOLE DISCRETION. TUNED DOES NOT
              GUARANTEE, MONITOR, OR VERIFY THE ACCURACY, RELIABILITY, OR
              COMPLETENESS OF ANY INFORMATION, CONTENT, COMMUNICATION, OR
              PROFESSIONAL SERVICES PROVIDED BY ANY USER (INCLUDING ANY
              RECOMMENDATIONS BY A PROVIDER TO PURCHASE OR OBTAIN ANY PRODUCT)
              AND YOU EXPRESSLY WAIVE AND RELEASE TUNED FROM ANY AND ALL
              LIABILITY, CLAIMS, OR DAMAGES ARISING FROM OR IN ANY WAY RELATED
              TO YOUR CONSULTATIONS AND INTERACTIONS WITH PROVIDERS OR ANY OTHER
              USERS OF THE SERVICE.
            </p>
            <p>
              TUNED WILL NOT BE A PARTY TO DISPUTES OR NEGOTIATIONS OF DISPUTES,
              INCLUDING CLAIMS OF PROFESSIONAL MALPRACTICE, BETWEEN PROVIDERS
              AND USERS. RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING
              SERVICES OFFERED VIA THE SERVICE (WITH ALL THEIR IMPLICATIONS)
              RESTS SOLELY WITH YOU.
            </p>
            <p>
              THE QUALITY OF CONSULTATIONS SCHEDULED THROUGH THE USE OF THE
              SERVICE IS ENTIRELY THE RESPONSIBILITY OF THE PROVIDER WHO
              ULTIMATELY PROVIDES THE CONSULTATION. YOU UNDERSTAND THAT BY USING
              THE SERVICE, YOU MAY BE EXPOSED TO SITUATIONS THAT ARE POTENTIALLY
              OFFENSIVE OR OTHERWISE OBJECTIONABLE, AND THAT YOU USE THE SERVICE
              AT YOUR OWN RISK.
            </p>
            <p>
              EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
              LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
              INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
              THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS
              OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
              SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS.
              THE LIMITATIONS IN THIS SECTION 18 WILL APPLY EVEN IF ANY LIMITED
              REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>
          </li>
          <li>
            <BoldText>Dispute Resolution and Arbitration</BoldText>
            <p>
              <BoldText>19.1 Generally.</BoldText>
              In the interest of resolving disputes between you and Tuned in the
              most expedient and cost effective manner, and except as described
              in Sections 19.2 and 19.3, you and Tuned agree that every dispute
              arising in connection with these Terms will be resolved by binding
              arbitration. Arbitration is less formal than a lawsuit in court.
              Arbitration uses a neutral arbitrator instead of a judge or jury,
              may allow for more limited discovery than in court, and can be
              subject to very limited review by courts. Arbitrators can award
              the same damages and relief that a court can award. This agreement
              to arbitrate disputes includes all claims arising out of or
              relating to any aspect of these Terms, whether based in contract,
              tort, statute, fraud, misrepresentation, or any other legal
              theory, and regardless of whether a claim arises during or after
              the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY
              ENTERING INTO THESE TERMS, YOU AND TUNED ARE EACH WAIVING THE
              RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
            </p>
            <p>
              <BoldText>19.2 Exceptions.</BoldText>
              Despite the provisions of Section 19.1, nothing in these Terms
              will be deemed to waive, preclude, or otherwise limit the right of
              either party to: (a) bring an individual action in small claims
              court; (b) pursue an enforcement action through the applicable
              federal, state, or local agency if that action is available; (c)
              seek injunctive relief in a court of law in aid of arbitration; or
              (d) to file suit in a court of law to address an intellectual
              property infringement claim.
            </p>
            <p>
              <BoldText>19.3 Opt-Out.</BoldText>If you do not wish to resolve
              disputes by binding arbitration, you may opt out of the provisions
              of this Section 19 within 30 days after the date that you agree to
              these Terms by sending a letter to Tuned Care, Inc., Attention:
              Legal Department – Arbitration Opt-Out, 320 7<sup>th</sup> Ave
              #291, Brooklyn, NY 11215, that specifies: your full legal name,
              the email address associated with your account on the Service, and
              a statement that you wish to opt out of arbitration (“
              <BoldText>Opt-Out Notice</BoldText>
              ”). Once Tuned receives your Opt-Out Notice, this Section 19 will
              be void and any action arising out of these Terms will be resolved
              as set forth in Section 21.2. The remaining provisions of these
              Terms will not be affected by your Opt-Out Notice.
            </p>
            <p>
              <BoldText>19.4 Arbitrator</BoldText>Any arbitration between you
              and Tuned will be settled under the Federal Arbitration Act and
              administered by the American Arbitration Association (“
              <BoldUnderlinedText>AAA</BoldUnderlinedText>”) under its Consumer
              Arbitration Rules (collectively, “
              <BoldUnderlinedText>AAA Rules</BoldUnderlinedText>”) as modified
              by these Terms. The AAA Rules and filing forms are available
              online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
              contacting Tuned. The arbitrator has exclusive authority to
              resolve any dispute relating to the interpretation, applicability,
              or enforceability of this binding arbitration agreement.
            </p>
            <p>
              <BoldText>19.5 Notice of Arbitration; Process.</BoldText>A party
              who intends to seek arbitration must first send a written notice
              of the dispute to the other party by certified U.S. Mail or by
              Federal Express (signature required) or, only if that other party
              has not provided a current physical address, then by electronic
              mail (“
              <BoldUnderlinedText>Notice of Arbitration</BoldUnderlinedText>
              ”). Tuned’s address for Notice of Arbitration is: Tuned Care,
              Inc., 320 7th Ave #291, Brooklyn, NY 11215. The Notice of
              Arbitration must: (a) describe the nature and basis of the claim
              or dispute; and (b) set forth the specific relief sought (“
              <BoldUnderlinedText>Demand</BoldUnderlinedText>”). The parties
              will make good faith efforts to resolve the claim directly, but if
              the parties do not reach an agreement to do so within 30 days
              after the Notice of Arbitration is received, you or Tuned may
              commence an arbitration proceeding. All arbitration proceedings
              between the parties will be confidential unless otherwise agreed
              by the parties in writing. During the arbitration, the amount of
              any settlement offer made by you or Tuned must not be disclosed to
              the arbitrator until after the arbitrator makes a final decision
              and award, if any. If the arbitrator awards you an amount higher
              than the last written settlement amount offered by Tuned in
              settlement of the dispute prior to the award, Tuned will pay to
              you the higher of: (i) the amount awarded by the arbitrator; or
              (ii) $10,000.
            </p>
            <p>
              <BoldText>19.6 Fees.</BoldText>If you commence arbitration in
              accordance with these Terms, Tuned will reimburse you for your
              payment of the filing fee, unless your claim is for more than
              $10,000, in which case the payment of any fees will be decided by
              the AAA Rules. Any arbitration hearing will take place at a
              location to be agreed upon in Alameda County, California, but if
              the claim is for $10,000 or less, you may choose whether the
              arbitration will be conducted: (a) solely on the basis of
              documents submitted to the arbitrator; (b) through a
              non-appearance based telephone hearing; or (c) by an in-person
              hearing as established by the AAA Rules in the county (or parish)
              of your billing address. If the arbitrator finds that either the
              substance of your claim or the relief sought in the Demand is
              frivolous or brought for an improper purpose (as measured by the
              standards set forth in Federal Rule of Civil Procedure 11(b)),
              then the payment of all fees will be governed by the AAA Rules. In
              that case, you agree to reimburse Tuned for all monies previously
              disbursed by it that are otherwise your obligation to pay under
              the AAA Rules. Regardless of the manner in which the arbitration
              is conducted, the arbitrator must issue a reasoned written
              decision sufficient to explain the essential findings and
              conclusions on which the decision and award, if any, are based.
              The arbitrator may make rulings and resolve disputes as to the
              payment and reimbursement of fees or expenses at any time during
              the proceeding and upon request from either party made within 14
              days of the arbitrator’s ruling on the merits.
            </p>
            <p>
              <BoldText>19.7 No Class Actions.</BoldText>
              YOU AND TUNED AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
              ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
              Further, unless both you and Tuned agree otherwise, the arbitrator
              may not consolidate more than one person’s claims, and may not
              otherwise preside over any form of a representative or class
              proceeding.
            </p>
            <p>
              <BoldText>
                19.8 Modifications to this Arbitration Provision.
              </BoldText>
              If Tuned makes any future change to this arbitration provision,
              other than a change to Tuned’s address for Notice of Arbitration,
              you may reject the change by sending us written notice within 30
              days of the change to Tuned’s address for Notice of Arbitration,
              in which case your account with Tuned will be immediately
              terminated and this arbitration provision, as in effect
              immediately prior to the changes you rejected will survive.
            </p>
            <p>
              <BoldText>19.9 Enforceability.</BoldText>
              If Section 19.7 or the entirety of this Section 19 is found to be
              unenforceable, or if Tuned receives an Opt-Out Notice from you,
              then the entirety of this Section 19 will be null and void and, in
              that case, exclusive jurisdiction and venue described in Section
              21.2 will govern any action arising out of or related to these
              Terms.
            </p>
          </li>
          <li>
            <BoldText>Privacy and Information Security</BoldText>
            <p>
              <BoldText>20.1 Privacy Policy.</BoldText>
              You acknowledge and agree that except as described in these Terms,
              any User Content, data, and information you enter into the Service
              or that we collect in connection with the Service (“
              <BoldUnderlinedText>Data</BoldUnderlinedText>”) will be collected,
              used, disclosed, and protected as described in the Tuned Privacy
              Policy available at www.TunedCare.com/privacy-policy. Please read
              the Privacy Policy carefully.
            </p>
            <p>
              <BoldText>20.2 Data.</BoldText>
              As between you and Tuned, Data is and will remain owned by you.
              You hereby grant Tuned the right to collect, process, transmit,
              store, use, and disclose Data to provide the Service and as
              otherwise set forth in this Agreement and the Tuned Privacy
              Policy.
            </p>
            <p>
              <BoldText>20.3 Use of Aggregated Data.</BoldText>
              You acknowledge and agree that Tuned may collect, create, process,
              transmit, store, use, and disclose aggregated and/or de-identified
              data derived from Data or use of the Services (“
              <BoldUnderlinedText>Aggregated Data</BoldUnderlinedText>
              ”) for its business purposes, including for industry analysis,
              benchmarking, and analytics. All Aggregated Data will be in an
              aggregated and/or de-identified form only and will not identify
              you.
            </p>
            <p>
              <BoldText>20.4 Compliance.</BoldText>
              You are solely responsible (a) for Data as entered, supplied,
              accessed, or used by you and (b) for complying with any privacy
              and data protection laws and regulations applicable to Data or
              your use of the Services. You represent and warrant that you have
              obtained and will maintain all rights, consents, and
              authorizations required to grant Tuned the rights and licenses set
              forth in this Section 20 and to enable Tuned to exercise its
              rights under the same without violation or infringement of the
              rights of any third-party.
            </p>
          </li>
          <li>
            <BoldText>Miscellaneous</BoldText>
            <p>
              <BoldText>21.1 General Terms.</BoldText>
              These Terms, together with the Privacy Policy and any other
              agreements expressly incorporated by reference into these Terms
              (including, for Providers, the Provider Terms of Service available
              through your Provider account), are the entire and exclusive
              understanding and agreement between you and Tuned regarding your
              use of the Service. You may not assign or transfer these Terms or
              your rights under these Terms, in whole or in part, by operation
              of law or otherwise, without our prior written consent. We may
              assign these Terms at any time without notice or consent. The
              failure to require performance of any provision will not affect
              our right to require performance at any other time after that, nor
              will a waiver by us of any breach or default of these Terms, or
              any provision of these Terms, be a waiver of any subsequent breach
              or default or a waiver of the provision itself. Use of section
              headers in these Terms is for convenience only and will not have
              any impact on the interpretation of any provision. Throughout
              these Terms the use of the word “including” means “including but
              not limited to.” If any part of these Terms is held to be invalid
              or unenforceable, the unenforceable part will be given effect to
              the greatest extent possible, and the remaining parts will remain
              in full force and effect.
            </p>
            <p>
              <BoldText>21.2 Governing Law.</BoldText>
              These Terms are governed by the laws of the State of New York
              without regard to conflict of law principles. You and Tuned submit
              to the personal and exclusive jurisdiction of the state courts and
              federal courts located within New York County, New York, for
              resolution of any lawsuit or court proceeding permitted under
              these Terms.
            </p>
            <p>
              <BoldText>21.3 Additional Terms.</BoldText>
              Your use of the Service is subject to all additional terms,
              policies, rules, or guidelines that we may post on the Service
              (the “<BoldUnderlinedText>Additional Terms</BoldUnderlinedText>
              ”). All Additional Terms are incorporated by this reference into,
              and made a part of, these Terms. If there is any conflict or
              inconsistency between these Terms and any Additional Terms, these
              Terms will apply to the extent of the conflict or inconsistency
              unless the Additional Terms expressly state that they control to
              the extent of that conflict or inconsistency.
            </p>
            <p>
              <BoldText>21.4 Consent to Electronic Communications.</BoldText>
              By using the Service, you consent to receiving certain electronic
              communications from us as further described in our Privacy Policy.
              Please read our Privacy Policy to learn more about our electronic
              communications practices. You agree that any notices, agreements,
              disclosures, or other communications that we send to you
              electronically will satisfy any legal communication requirements,
              including that those communications be in writing.
            </p>
            <p>
              <BoldText>21.5 Contact Information.</BoldText>
              You may contact us by sending correspondence to 320 7<sup>
                th
              </sup>{' '}
              Ave #291, Brooklyn, NY 11215 or by emailing us at
              support@tunedcare.com.
            </p>
            <p>
              <BoldText>21.6 Notice to California Residents.</BoldText>
              If you are a California resident, under California Civil Code
              Section 1789.3, you may contact the Complaint Assistance Unit of
              the Division of Consumer Services of the California Department of
              Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202,
              Sacramento, California 95834, or by telephone at (800) 952-5210 in
              order to resolve a complaint regarding the Service or to receive
              further information regarding use of the Service.
            </p>
            <p>
              <BoldText>21.7 No Support.</BoldText>
              We are under no obligation to provide support for the Service. In
              instances where we may offer support, the support will be subject
              to published policies.
            </p>
            <p>
              <BoldText>21.8 International Use.</BoldText>
              The Service is intended for visitors located within the United
              States. We make no representation that the Service is appropriate
              or available for use outside of the United States. Access to the
              Service from countries or territories or by individuals where such
              access is illegal is prohibited.
            </p>
          </li>
        </ol>
      </Typography>
    </Grid>
  );
}

export default GeneralTermsOfUseContent;
