import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormControlLabel, Grid } from '@mui/material';
import { Checkbox } from 'components/common/input/Checkbox';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { Shift, VisitShift } from 'api/services/organization/types';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { FormModal } from 'components/modal';
import { showSnackbar } from 'utils';
import { Select, TextInput } from 'components/common/input';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { GENDERS } from 'common/constants/user';
import { ParticipantBody } from 'api/services/participant/types';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';
import { getPhoneSchema } from 'utils/validation/validators';

interface ParticipantFormProps {
  participant: ParticipantWithLastHearingTestResultViewItem | null;
  organizationName: string;
  locationName: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ParticipantFormSchema = Yup.object().shape({
  given: Yup.string().required('Required'),
  family: Yup.string().required('Required'),
  middleName: Yup.string().optional(),
  email: Yup.string().email('Invalid email').optional(),
  phone: getPhoneSchema().optional(),
  externalId: Yup.string().required('Required'),
  birthDate: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  department: Yup.string().optional(),
  employmentDate: Yup.string().optional(),
});

export function ParticipantForm(props: ParticipantFormProps) {
  const {
    participant,
    organizationName,
    locationName,
    handleSubmit,
    handleClose,
  } = props;
  const { organizationId, locationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const genderDropdownOptions = useMemo(
    () => GENDERS.map((gender) => ({ label: gender, value: gender })) || [],
    [],
  );

  const initialValues = participant
    ? {
        given: participant.given || '',
        family: participant.family || '',
        email: participant.email || '',
        middleName: participant.middleName || '',
        phone: convertPhoneFormFieldToTenDigitFormat(participant.phone),
        externalId: participant.externalId || '',
        birthDate: participant.birthDate || '',
        gender: participant.gender || '',
        department: participant.department || '',
        employmentDate: participant.employmentDate || '',
        active: participant.active,
        shifts: participant.shifts.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      }
    : {
        given: '',
        family: '',
        email: '',
        middleName: '',
        phone: '',
        externalId: '',
        birthDate: '',
        gender: '',
        department: '',
        employmentDate: '',
        active: true,
        shifts: [],
      };

  const onSubmit = async (values: typeof initialValues) => {
    if (!organizationId || !locationId) {
      return;
    }

    _.omitBy(
      values,
      (value) => (_.isString(value) && value.length === 0) || value === null,
    );

    const body = {
      ...values,
      phone: convertPhoneFormFieldToInternationalFormat(values.phone),
      organizationId: Number(organizationId),
      locationId: Number(locationId),
      email: values.email || null,
      employmentDate: values.employmentDate || null,
      shifts: values.shifts.map((item) => Number(item.value)),
    } as ParticipantBody;
    try {
      setLoading(true);
      if (participant) {
        await api.participant.updateParticipant(participant.id, body);
      } else await api.participant.createParticipant(body);
      handleSubmit();
      showSnackbar(
        `Participant successfully ${participant ? 'updated' : 'added'}!`,
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || 'Could not save form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ParticipantFormSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={participant ? 'Edit Participant' : 'Add Participant'}
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    type="text"
                    name="given"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.given}
                    error={touched.given && Boolean(errors.given)}
                    helperText={
                      touched.given && errors.given ? errors.given : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Middle Name"
                    variant="outlined"
                    type="text"
                    name="middleName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={
                      touched.middleName && errors.middleName
                        ? errors.middleName
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    name="family"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.family}
                    error={touched.family && Boolean(errors.family)}
                    helperText={
                      touched.family && errors.family ? errors.family : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Birth Date"
                    variant="outlined"
                    type="date"
                    name="birthDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.birthDate}
                    error={touched.birthDate && Boolean(errors.birthDate)}
                    helperText={
                      touched.birthDate && errors.birthDate
                        ? errors.birthDate
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    options={genderDropdownOptions}
                    value={values.gender}
                    label="Gender"
                    displayEmpty
                    onChange={(e) => setFieldValue('gender', e.target.value)}
                    errorMessage={
                      touched?.gender && errors?.gender
                        ? (errors?.gender as string)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Department"
                    variant="outlined"
                    type="text"
                    name="department"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.department}
                    error={touched.department && Boolean(errors.department)}
                    helperText={
                      touched.department && errors.department
                        ? errors.department
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutocomplete<Shift>
                    fullWidth
                    multiple
                    label="Shifts"
                    fetchMethod={(params) =>
                      api.organization.getLocationShifts(
                        Number(locationId),
                        params,
                      )
                    }
                    mapOptionMethod={(item: VisitShift) => ({
                      value: item.id,
                      label: item.name,
                    })}
                    onChange={(event: any, newValue: any) => {
                      setFieldValue('shifts', newValue);
                    }}
                    value={values.shifts}
                    onBlur={handleBlur}
                    getOptionDisabled={(option) =>
                      values.shifts.length >= 3 ||
                      !!values.shifts.find(
                        (item) => item.value === option.value,
                      )
                    }
                    error={touched.shifts && Boolean(errors.shifts)}
                    helperText={
                      touched.shifts && errors.shifts
                        ? (errors.shifts as string)
                        : ' '
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="External Id"
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Organization"
                    variant="outlined"
                    type="text"
                    value={organizationName}
                    margin="none"
                    helperText={' '}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Location"
                    variant="outlined"
                    type="text"
                    value={locationName}
                    margin="none"
                    helperText={' '}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Employment Date"
                    variant="outlined"
                    type="date"
                    name="employmentDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employmentDate}
                    error={
                      touched.employmentDate && Boolean(errors.employmentDate)
                    }
                    helperText={
                      touched.employmentDate && errors.employmentDate
                        ? errors.employmentDate
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.active}
                        onChange={(e) =>
                          setFieldValue('active', e.currentTarget.checked)
                        }
                      />
                    }
                    slotProps={{
                      typography: {
                        fontSize: '16px',
                        fontWeight: 700,
                        textAlign: 'center',
                        lineHeight: '56px',
                      },
                    }}
                    label="Is active"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {error ? (
                    <FormAlert severity="error">{error}</FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
