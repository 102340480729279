import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { Button } from 'components/common/button';
import { Organization } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { PageHeader } from 'components/admin-page-layout';
import { Grid } from '@mui/material';
import { SearchInput } from 'components/common/input/SearchInput';
import { RequestParams } from 'api/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { useAccountManager } from 'hooks/useAccountManager';
import { getUserFullNameString } from 'utils/getUserFullNameString';
import { ConfirmationModal } from 'components/modal';
import { AssignOrganizationForm } from './components/assign-organization-form';
import { getCells } from './cells';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';

export function AccountManagerOrganizationsList() {
  const { accountManagerId } = useParams();
  const { t, i18n } = useTranslation();
  const { accountManager, isAccountManagerLoading } = useAccountManager(
    accountManagerId ? Number(accountManagerId) : null,
  );
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [actionedOrganization, setActionedOrganization] =
    useState<Organization | null>(null);

  const fetchOrganizations = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      if (!accountManagerId) {
        return EMPTY_PAGINATED_DATA;
      }
      const organizationsData = await api.user.getAccountManagerOrganizations(
        accountManagerId,
        {
          limit,
          offset,
          orderBy,
          orderDirection,
          search,
          filterParams,
        },
      );
      return organizationsData;
    },
    [],
  );
  const tableState = useTableState<Organization>({
    fetchDataFunction: fetchOrganizations,
  });

  const actions = [
    {
      title: 'Remove',
      onClick: (organization: Organization) => {
        setActionedOrganization(organization);
        setConfirmationOpen(true);
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  const handleFormClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onFormSubmit = useCallback(() => {
    tableState.refreshData();
    showSnackbar('Organization successfully assigned', { variant: 'success' });
    handleFormClose();
    setActionedOrganization(null);
  }, []);

  const unassignAccountManagerToOrganization = useCallback(
    async (userId: number | string) => {
      if (!userId || !actionedOrganization?.id) {
        return;
      }
      try {
        await api.organization.assignAccountManagerToOrganization(
          actionedOrganization?.id,
          userId,
          'unassign',
        );
        setActionedOrganization(null);
        tableState.refreshData();
        showSnackbar('Organization successfully unassigned', {
          variant: 'success',
        });
      } catch (e: any) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [actionedOrganization],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={t('common.organizations')}
        entityName={getUserFullNameString(accountManager)}
        titleLoading={isAccountManagerLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Assign
          </Button>
        }
      />

      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Grid />
        <Grid
          item
          py={{
            xs: 0,
            sm: 3,
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>

      <BasicTable<Organization>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && accountManagerId && (
        <AssignOrganizationForm
          accountManagerId={accountManagerId}
          onSubmit={onFormSubmit}
          handleClose={handleFormClose}
        />
      )}

      {actionedOrganization && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setActionedOrganization(null);
          }}
          handleSubmit={async () =>
            accountManagerId &&
            unassignAccountManagerToOrganization(accountManagerId)
          }
          submitButtonTitle="Remove"
          title={
            <>
              {`You are about to remove the Account Manager from the ${actionedOrganization.name} oranization`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
