import { Person } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { VisitShift } from 'api/services/organization/types';
import { Tooltip } from 'components/common/tooltip';
import { ShiftNameCell } from 'components/table/components/ShiftNameCell';
import { Cell } from 'components/table/types';
import { ShiftTimingsCell } from 'components/table/components/ShiftTimingsCell';

export const cells: Cell<VisitShift>[] = [
  {
    name: '',
    key: 'name',
    render: (item: VisitShift) => (
      <ShiftNameCell name={item.name} shiftId={item.id} />
    ),
  },
  {
    name: '',
    render: (item: VisitShift) => (
      <Box sx={{ paddingLeft: 2 }}>
        <ShiftTimingsCell hours={item.hours} isCompactView />
      </Box>
    ),
  },
  {
    name: '',
    key: 'minimalEmployees',
    render: (item: VisitShift) => (
      <Tooltip
        title="How many employees from the shift cannot be tested on the same testing day"
        arrow
      >
        <Chip
          sx={{
            height: 'unset',
            fontSize: '14px',
            fontWeight: 800,
            borderRadius: '8px',
            color: '#C45558',
            backgroundColor: '#FDD7D7',
          }}
          icon={<Person sx={{ width: '16px' }} />}
          label={item.minimalEmployees}
        />
      </Tooltip>
    ),
    align: 'right',
  },
  {
    name: '',
    key: 'scheduledParticipantsCount',
    render: (item: VisitShift) => (
      <Tooltip
        title="Scheduled patients on
      this shift"
        arrow
      >
        <Chip
          sx={{
            height: 'unset',
            fontSize: '14px',
            fontWeight: 800,
            borderRadius: '8px',
            color: '#277750',
            backgroundColor: '#8FE0B0',
          }}
          icon={<Person sx={{ width: '16px' }} />}
          label={item.scheduledParticipantsCount}
        />
      </Tooltip>
    ),
    align: 'right',
  },
  {
    name: '',
    key: 'unscheduledParticipantsCount',
    render: (item: VisitShift) => (
      <Tooltip
        title="Unscheduled patients on
    this shift"
      >
        <Chip
          sx={{
            height: 'unset',
            fontSize: '14px',
            fontWeight: 800,
            borderRadius: '8px',
            color: '#967126',
            backgroundColor: '#FFED8F',
          }}
          icon={<Person sx={{ width: '16px' }} />}
          label={item.unscheduledParticipantsCount}
        />
      </Tooltip>
    ),
    align: 'right',
  },
];
