import React from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../../../components/admin-page-layout';
import { EquipmentSpecificationAndNoiseLevelsReportForm } from '../../../../components/forms/equipment-specification-and-noise-levels-report-form';

export function PSUpdateEquipmentSpecificationAndNoiseLevelsReportPage() {
  const { locationId, reportId } = useParams();
  return (
    <BasicContainer>
      <PageHeader title="Update Equipment Specification & Noise Levels Report" />
      <EquipmentSpecificationAndNoiseLevelsReportForm
        locationId={Number(locationId)}
        equipmentSpecificationReportId={Number(reportId)}
        allowedSignature
      />
    </BasicContainer>
  );
}
