import { FormHelperText, Grid, TextFieldProps } from '@mui/material';
import { TextInput } from '../../../common/input';

export function FilledTextInput(props: TextFieldProps) {
  const { sx = {}, helperText, ...restProps } = props;
  return (
    <Grid sx={{ position: 'relative', width: '100%' }}>
      <TextInput
        sx={{
          overflow: 'hidden',
          borderRadius: '8px',
          '& fieldset': { border: 'none' },
          '& .MuiInputBase-input': {
            borderRadius: '8px',
            padding: '4px 8px',
            backgroundColor: 'rgba(202, 194, 190, 0.40)',
            fontFamily: 'DM Mono',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '130%',
            letterSpacing: '-0.14px',
            textAlign: 'center',
          },
          ...sx,
        }}
        {...restProps}
      />
      <FormHelperText
        sx={{ margin: '3px 14px 0', position: 'absolute', bottom: '-20px' }}
        error={!!helperText}
      >
        {helperText || ' '}
      </FormHelperText>
    </Grid>
  );
}
