import { Record } from 'api/services/uploads/types';
import { StatusCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import { formatRosterRecordReason } from 'utils';

export const cells: Cell<Record>[] = [
  {
    name: 'Id',
    key: 'id',
  },
  {
    name: 'Row',
    key: 'lineNumber',
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: Record) => <StatusCell status={item.status} />,
  },
  {
    name: 'Reason',
    key: 'reason',
    render: (item: Record) => formatRosterRecordReason(item.reason),
  },
];
