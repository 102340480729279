import {
  CLEAR_SITE_MANAGER_DASHBOARD_STATE,
  REQUEST_SITE_MANAGER_LOCATIONS,
  SET_SITE_MANAGER_DASHBOARD_STATE,
  SET_SITE_MANAGER_LOCATIONS,
  SET_SITE_MANAGER_LOCATIONS_LOADING,
  SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR,
  SET_SITE_MANAGER_SELECTED_LOCATION,
  SiteManagerDashboardState,
} from './types';
import { ILocation } from '../../../../api/services/organization/types';

export const setSiteManagerSelectedLocation = (payload: ILocation | null) => ({
  type: SET_SITE_MANAGER_SELECTED_LOCATION,
  payload,
});

export const setSiteManagerLocations = (payload: ILocation[] | []) => ({
  type: SET_SITE_MANAGER_LOCATIONS,
  payload,
});

export const setSiteManagerLocationsError = (
  payload: string | string[] | null,
) => ({
  type: SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR,
  payload,
});

export const setSiteManagerLocationsLoading = (payload: boolean) => ({
  type: SET_SITE_MANAGER_LOCATIONS_LOADING,
  payload,
});

export const setSiteManagerDashboardState = (
  payload: SiteManagerDashboardState,
) => ({
  type: SET_SITE_MANAGER_DASHBOARD_STATE,
  payload,
});

export const requestSiteManagerLocationsAction = (payload: number) => ({
  type: REQUEST_SITE_MANAGER_LOCATIONS,
  payload,
});

export const clearSiteManagerDashboardState = () => ({
  type: CLEAR_SITE_MANAGER_DASHBOARD_STATE,
});
