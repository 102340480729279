import { Checkbox } from '@mui/material';
import i18next from 'i18n/config';
import { ExternalClinic } from 'api/services/external-clinic/types';
import { EllipsisCell, LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<ExternalClinic>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.name'),
    key: 'name',
    render: (item: ExternalClinic) => <EllipsisCell text={item.name} />,
  },
  {
    name: 'Address line 1',
    key: 'line1',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'State',
    key: 'state',
  },
  {
    name: 'Postal code',
    key: 'postalCode',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Phone',
    key: 'phone',
  },
  {
    name: 'Contact person name',
    key: 'contactPersonName',
  },
  {
    name: 'Booking link',
    key: 'bookingLink',
  },
  {
    name: 'Locations',
    key: 'locations',
    render: (item: ExternalClinic) => (
      <LinkCell
        to={`/admin/external-clinics/${item.id}/locations/`}
        label={item.locations.length}
      />
    ),
  },
  {
    name: i18next.t('common.Active'),
    key: 'active',
    render: (item: ExternalClinic) => (
      <Checkbox size="small" sx={{ padding: 0 }} checked={item.active} />
    ),
  },
];
