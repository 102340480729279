import { DateTime } from 'luxon';
import { ReactNode } from 'react';

interface IDateTimeCellProps {
  date: string;
  format: 'date' | 'time' | 'date_time';
  token?: string;
  tz?: string;
}

export function DateTimeCell(props: IDateTimeCellProps) {
  const { date, format, token, tz } = props;

  if (!date) {
    return null;
  }

  let dateTime: DateTime = DateTime.fromISO(date);

  if (tz) {
    dateTime = dateTime.setZone(tz);
  }

  let formattedDate: ReactNode = null;

  if (token) {
    return <>{dateTime.toFormat(token)}</>;
  }

  if (format === 'date') {
    formattedDate = <>{dateTime.toFormat('MM/dd/yyyy')}</>;
  }
  if (format === 'time') {
    formattedDate = <>{dateTime.toFormat('h:mm a')}</>;
  }
  if (format === 'date_time') {
    formattedDate = (
      <>
        {dateTime.toFormat('MM/dd/yyyy')}
        <br />
        {dateTime.toFormat('h:mm a')}
      </>
    );
  }

  return formattedDate;
}
