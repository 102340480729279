import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  styled,
  Box,
} from '@mui/material';
import { api } from 'api';
import { Heading } from 'components/common/heading';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthSelector } from 'store/selectors/auth';
import { Button } from 'components/common/button';
import {
  AccessTime,
  Add,
  ArrowBack,
  ArrowForward,
  CalendarMonth,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { getErrorMessage } from 'utils';
import { UserRoles } from 'api/services/auth/types';
import { IAvailableTimeSlot } from 'api/services/organization/types';
import { SlotButton } from './components';
import {
  convertSlotDataToStringValue,
  getSlotDataObjectFromStringValue,
} from './utils';
import { DateTimeText } from './components/DateText';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Add sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
}));

export type SelectedDateTime = {
  dateTime: string;
  visitTimeId: string;
};

interface QuestionnaireFormProps {
  setError: (msg: string) => void;
}

export function BookingForm(props: QuestionnaireFormProps) {
  const { setError } = props;
  const navigate = useNavigate();
  const { user } = useAuthSelector();
  const [searchParams] = useSearchParams();
  const [availableSlots, setAvailableSlots] = useState<IAvailableTimeSlot[]>(
    [],
  );
  const [selectedDateTime, setSelectedDateTime] =
    useState<SelectedDateTime | null>(null);
  const [step, setStep] = useState<'selectStep' | 'confirmStep'>('selectStep');
  const [loading, setLoading] = useState(false);
  const visitId = searchParams.get('visitId');

  const getAvailableSlots = async () => {
    if (!user?.participant?.locationId || !user?.id || !visitId) {
      return;
    }
    try {
      const slots = await api.organization.getAvailableVisitTimeSlots(
        user?.participant?.locationId,
        user?.id,
        visitId,
      );
      setAvailableSlots(slots);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAvailableSlots();
  }, [searchParams]);

  const handleDateTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDateTime(
      getSlotDataObjectFromStringValue((event.target as any).value),
    );
  };

  const onSubmit = async () => {
    const shiftId = availableSlots.find(
      (availableSlot) =>
        String(availableSlot.visitTime.id) === selectedDateTime?.visitTimeId,
    )?.visitTime.visitTimeShifts[0].id;

    if (
      !user?.participant?.locationId ||
      !user?.id ||
      !visitId ||
      !selectedDateTime ||
      !shiftId
    ) {
      return;
    }

    try {
      setLoading(true);
      await api.organization.bookTimeSlotForVisitTime(
        user?.participant?.locationId,
        visitId,
        selectedDateTime.visitTimeId,
        shiftId,
        user?.id,
        {
          startTime: selectedDateTime?.dateTime,
        },
      );

      navigate('/questionnaire?resourceType=ParticipantsQuestionnaire');
    } catch (e: any) {
      setError(getErrorMessage(e, 'Could not book') as string);
    } finally {
      setLoading(false);
    }
  };

  const isParticipant = useMemo(
    () => user?.role === UserRoles.Participant,
    [user],
  );

  return (
    <Grid container pt={{ xs: 4, sm: 5 }} pb={2}>
      <Heading variant="h2" pb={{ xs: 4 }}>
        {step === 'selectStep' &&
          'Select a date and time for your hearing test'}
        {step === 'confirmStep' && 'Confirm your hearing test appointment'}
      </Heading>

      <Grid
        container
        sx={{
          padding: { xs: 3, sm: 5 },
          borderRadius: '16px',
          border: `1px solid rgba(202, 194, 190, 0.8)`,
        }}
      >
        {step === 'selectStep' && (
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              name="radio-buttons-group"
              value={selectedDateTime}
              onChange={handleDateTimeChange}
            >
              {availableSlots.map((availableSlot) => {
                if (!availableSlot.slots.length) {
                  return null;
                }
                return (
                  <Accordion
                    sx={{
                      boxShadow: 'none',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Add />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <DateTimeText
                        date={availableSlot.visitTime.startTime || ''}
                      />
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container justifyContent="center">
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(2, 1fr)',
                            },
                            width: { xs: '100%', sm: 'unset' },
                            gap: 1,
                          }}
                        >
                          {availableSlot.slots.map((item) => (
                            <FormControlLabel
                              key={item.start}
                              value={convertSlotDataToStringValue(
                                availableSlot,
                                item,
                              )}
                              control={<Radio sx={{ display: 'none' }} />}
                              label={
                                <SlotButton
                                  checked={
                                    `${selectedDateTime?.dateTime}, ${selectedDateTime?.visitTimeId}` ===
                                    `${item.start}, ${availableSlot.visitTime.id}`
                                  }
                                  label={DateTime.fromISO(item.start).toFormat(
                                    'h:mm a',
                                  )}
                                />
                              }
                              sx={{
                                margin: 0,
                                display: 'block',
                              }}
                            />
                          ))}
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </RadioGroup>
          </FormControl>
        )}

        {step === 'confirmStep' && (
          <Grid container gap={3}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 700,
              }}
            >
              Your hearing test appointment is on
            </Typography>
            <Grid container gap={2}>
              <CalendarMonth htmlColor="#08161F" />
              <DateTimeText date={selectedDateTime?.dateTime || ''} />
            </Grid>
            <Grid container gap={2}>
              <AccessTime htmlColor="#08161F" />
              <DateTimeText time={selectedDateTime?.dateTime || ''} />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          justifyContent={step === 'confirmStep' ? 'flex-start' : 'center'}
          xs={12}
          sx={{ padding: '24px 0 0' }}
          gap={2}
        >
          {step === 'confirmStep' && (
            <Button
              onClick={() => setStep('selectStep')}
              variant="outlined"
              color="primary"
              startIcon={<ArrowBack />}
            >
              Back
            </Button>
          )}
          <Button
            onClick={() =>
              step === 'selectStep' ? setStep('confirmStep') : onSubmit()
            }
            variant="contained"
            color="secondary"
            endIcon={<ArrowForward />}
            disabled={loading || !isParticipant || !selectedDateTime}
          >
            Continue
          </Button>
        </Grid>

        {!isParticipant && (
          <Grid container justifyContent="center">
            <Typography sx={{ marginTop: 2, fontSize: '10px' }} color="error">
              You are not allowed to select date and time because you are not a
              Participant
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
