import { IRenderedOptionEntity } from 'types/dataStructureTypes';
import { HCPSupervisor, Participant, TimeSlot, User } from '../auth/types';
import {
  EHearingThresholdsNames,
  HearingTestResult,
} from '../hearing-test-results/types';

export interface Organization {
  id: number;
  name: string;
  externalId: string;
  active: boolean;
  requestMFAOnEachLogin: boolean;
  isAgeAdjustmentApplicableToHearingTestResults: boolean;
  HCPSupervisorsCount: number;
  locationsCount: number;
  uploads: Upload[];
  participantsCount: number;
  participantsTestedLastYearCount: number;
  HCPSupervisors: HCPSupervisor[];
  created_at: string;
  updated_at: string;
}

export interface Upload {
  id: number;
  fileId: number;
  rowsCount: number;
  status: string;
  reason: string | null;
  type: string;
  organizationId: number;
  locationId: number | null;
  created_at: string;
  updated_at: string;
  timezone: string | null;
  isHistorical: false;
}

export interface OrganizationBody {
  name: string;
  externalId: string;
  active: boolean;
}

export interface ILocation {
  id: number;
  name: string;
  externalId: string;
  line1: string;
  line2: string;
  country: string;
  state: string;
  postalCode: string;
  city: string;
  phone?: string;
  managingOrganization: number;
  shiftsCount: number;
  participantsCount: number;
  visitsCount: number;
  professionalSupervisorsCount?: number;
  siteManagersCount?: number;
  organization: Organization;
  participantsTestedLastYearCount: number;
  participantsWithSTSCount: number;
  participantsWithRecordableOSHAShiftCount: number;
  siteManagers: User[];
  questionnaireResourceType: string;
  amountOfMonthsNewEmployeeShouldBeTested: number;
  amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave: number;
  created_at: string;
  updated_at: string;
}

export interface LocationBody {
  name: string;
  externalId?: string;
  line1: string;
  line2?: string;
  country?: string;
  state: string;
  postalCode: string;
  city?: string;
}

export interface ShiftHours {
  id: number;
  day: number;
  start: string;
  end: string;
  timezone: string;
}

export interface Shift {
  id: number;
  name: string;
  hours: ShiftHours[];
  minimalEmployees: number;
  created_at: string;
  updated_at: string;
}

export interface VisitShift extends Shift {
  unscheduledParticipantsCount: number;
  scheduledParticipantsCount: number;
}

export interface AssignHCPSupervisorBody {
  hcpSupervisorId: number;
}

export interface ILocationParticipantsHearingTestsSummary {
  participantsNotTestedLastYear: number;
  percentageOfParticipantsTestedLastYear: number;
  percentageOfParticipantsWithSts: number;
  percentageOfParticipantsWithRecordableOSHAShift: number;
  invalidTestsCount: number;
}
export interface ILocationParticipantsHearingMetric {
  participantsHearingLossByGender: {
    [key in EHearingThresholdsNames]: {
      males: number;
      females: number;
      other: number;
      total: number;
    };
  };
  participantsWithSTSShiftCountByGender: {
    males: number;
    females: number;
    other: number;
    total: number;
  };
  participantsWithOSHAShiftCountByGender: {
    males: number;
    females: number;
    other: number;
    total: number;
  };
  medicalReferralsTotal: number;
  participantsTestedTotal: number;
  total: number;
}

export interface VisitTimeShift {
  id: number;
  visitTimeId: number;
  shiftId: number;
  shift: Shift;
  visitTimeSlots: VisitTimeSlot[];
}

export interface VisitTimeSlot {
  id: number;
  visitTimeId: number;
  visitTimeShiftId: number;
  participantId: number;
  participant?: Participant;
  dateTime: string;
  created_at: string;
  updated_at: string;
}

export interface IVisitTime {
  id: number;
  visitId: number;
  startTime: string;
  endTime: string;
  testLength: number;
  testsInParallel: number;
  ohcCount: number;
  visitTimeShifts: VisitTimeShift[];
  visitTimeSlots: VisitTimeSlot[];
}

export interface IAvailableTimeSlot {
  slots: TimeSlot[];
  visitTime: IVisitTime;
}

export interface ILatestVisitTime extends VisitTimeSlot {
  visit: IVisit;
}

export interface ILatestVisitTimeSlot extends VisitTimeSlot {
  visitTime: ILatestVisitTime;
}

export interface IVisit {
  id: number;
  locationId: number;
  name: string;
  timezone: string;
  ignoreTestedEmployeesMonths: number;
  shared: boolean;
  location: ILocation;
  schedulePDFDocumentFileId: number;
  schedulePDFDocumentStatus: string;
  visitTimes: IVisitTime[];
  created_at: string;
  updated_at: string;
}

export interface VisitBody {
  name: string;
  timezone: string;
  ignoreTestedEmployeesMonths?: number;
  shared?: boolean;
}

export interface VisitTimeBody {
  startTime: string;
  endTime: string;
  testLength: number | null;
  testsInParallel: number | null;
  ohcCount: number | null;
}

export enum ESignedDocumentTypes {
  ReferralList = 'REFERRAL_LIST',
  OSHARecordableShiftReport = 'OSHA_RECORDABLE_SHIFT_REPORT',
  STSReport = 'STS_REPORT',
  DailyBiological = 'DAILY_BIOLOGICAL_REPORT',
  EquipmentAndNoiseReport = 'EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORT',
}

export enum ESignedDocumentStatuses {
  Processing = 'Processing',
  Signed = 'Signed',
  Error = 'Error',
}

export interface ISignedDocument {
  id: number;
  signatoryId: number;
  signatory: User;
  signature: string;
  signatoryIPAddress: string;
  type: ESignedDocumentTypes;
  referralListId?: number;
  dailyBiologicalReportId?: number;
  equipmentSpecificationsAndNoiseLevelsReportId?: number;
  audiologistCoverLetterAndTestingSummaryId?: number;
  signingDateTime: string;
  documentViewingDateTime: string;
  status: ESignedDocumentStatuses;
  created_at: string;
  updated_at: string;
}
export enum EHistoricalDocumentTypes {
  ReferralList = 'REFERRAL_LIST',
  DailyBiologicalReport = 'DAILY_BIOLOGICAL_REPORT',
  EquipmentSpecificationsAndNoiseLevelsReport = 'EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORT',
  AudiologistCoverLetterAndTestingSummary = 'AUDIOLOGIST_COVER_LETTER_AND_TESTING_SUMMARY',
  Other = 'OTHER',
}

export interface IHistoricalDocument {
  id: number;
  isSigned: boolean;
  signatoryName: string;
  signingDate: string;
  type: EHistoricalDocumentTypes;
  fileId: number;
  referralListId?: number;
  dailyBiologicalReportId?: number;
  equipmentSpecificationsAndNoiseLevelsReportId?: number;
  audiologistCoverLetterAndTestingSummaryId?: number;
  otherReportId?: number;
  created_at: string;
  updated_at: string;
}

export interface IReferralListItem {
  id: number;
  locationId: number;
  location: ILocation;
  dateFrom: string;
  dateTo: string;
  data: HearingTestResult[];
  signedDocument: ISignedDocument;
  historicalDocument?: IHistoricalDocument;
  reportType: EReportTypeKeys;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IDailyBiologicalReport {
  id: number;
  dateTime: string | null;
  locationId: number;
  status: EReportStatuses;
  baselineId: number | null;
  examiner: string;
  audiometer: string;
  serialNumber: string;
  booth: number;
  uniqueId: string;
  annualCalibration: string | null;
  OHCInitial: string;
  calibrationCheck: boolean;
  jacksSeated: boolean;
  cordsOK: boolean;
  headbandTension: boolean;
  earphoneCushions: boolean;
  responseButton: boolean;
  pureTones: boolean;
  noStatic: boolean;
  threshold_left_250: number;
  threshold_left_500: number;
  threshold_left_750: number;
  threshold_left_1000: number;
  threshold_left_1500: number;
  threshold_left_2000: number;
  threshold_left_3000: number;
  threshold_left_4000: number;
  threshold_left_6000: number;
  threshold_left_8000: number;
  threshold_right_250: number;
  threshold_right_500: number;
  threshold_right_750: number;
  threshold_right_1000: number;
  threshold_right_1500: number;
  threshold_right_2000: number;
  threshold_right_3000: number;
  threshold_right_4000: number;
  threshold_right_6000: number;
  threshold_right_8000: number;
  signedDocument?: ISignedDocument | null;
  baseline?: IDailyBiologicalReport | null;
  name: string;
  historicalDocument?: IHistoricalDocument;
  created_at: string;
  updated_at: string;
}

export interface IEquipmentSpecificationsAndNoiseLevelsReport {
  id: number;
  locationId: number;
  status: EReportStatuses;
  examiners: { name: string; license: string }[];
  audiometer: string;
  serialNumber: string;
  annualCalibration: string | null;
  decibels_500: number;
  decibels_1000: number;
  decibels_2000: number;
  decibels_4000: number;
  decibels_8000: number;
  level_500: number;
  level_1000: number;
  level_2000: number;
  level_4000: number;
  level_8000: number;
  name: string;
  historicalDocument?: IHistoricalDocument;
  created_at: string;
  updated_at: string;
  signedDocument?: ISignedDocument;
}

export interface ICoverLetterAndSummaryOfTesting {
  id: number;
  locationId: number;
  from: string;
  to: string;
  professionalSupervisorUserId: number;
  signature: string;
  created_at: string;
  name: string;
  updated_at: string;
  signedDocument?: ISignedDocument;
  historicalDocument?: IHistoricalDocument;
  summaryOfTestingData: number | null;
  summaryOfTestingFileId: number | null;
}

export type GetLocationReportTypes =
  | EReportTypeKeys.ALL
  | EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS
  | EReportTypeKeys.MEDICAL_REFERRAL_LISTS
  | EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT
  | EReportTypeKeys.STS_REPORT
  | EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
  | EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
  | EReportTypeKeys.COVER_LETTERS
  | EReportTypeKeys.SUMMARY_OF_TESTING
  | EReportTypeKeys.OTHER;

export interface ICombinedReport {
  reportId: number;
  locationId: number;
  signedDocumentId: number | null;
  historicalDocumentId: number | null;
  signedDocument: ISignedDocument;
  historicalDocument?: IHistoricalDocument;
  name: string;
  status: ECombinedReportStatus;
  reportType: Omit<
    EReportTypeKeys,
    EReportTypeKeys.COVER_LETTERS | EReportTypeKeys.SUMMARY_OF_TESTING
  >;
  created_at: string;
}

export enum ECombinedReportStatus {
  Signed = 'Signed',
  Draft = 'Draft',
  Processing = 'Processing',
  Error = 'Error',
  Historical = 'Historical',
}

export enum EReportStatuses {
  Signed = 'Signed',
  Draft = 'Draft',
  Processing = 'Processing',
  Error = 'Error',
}

export enum EReportTypeKeys {
  MEDICAL_REFERRAL_LISTS = 'referral-lists',
  OSHA_RECORDABLE_SHIFT_REPORT = 'OSHA-recordable-shift-report',
  STS_REPORT = 'STS-reports',
  DAILY_BIOLOGICAL_REPORTS = 'daily-biological-reports',
  EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS = 'equipment-specifications-and-noise-levels-reports',
  AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING = 'audiologist-cover-letters',
  COVER_LETTERS = 'cover-letters',
  SUMMARY_OF_TESTING = 'summary-of-testing',
  OTHER = 'other-reports',
  ALL = 'all-reports',
}

export type OrganizationReportType =
  | EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS
  | EReportTypeKeys.MEDICAL_REFERRAL_LISTS
  | EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT
  | EReportTypeKeys.STS_REPORT
  | EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
  | EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
  | EReportTypeKeys.COVER_LETTERS
  | EReportTypeKeys.SUMMARY_OF_TESTING
  | EReportTypeKeys.OTHER
  | EReportTypeKeys.ALL;

export type OrganizationSelectOptionReportType =
  IRenderedOptionEntity<OrganizationReportType>;

export const ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS: OrganizationSelectOptionReportType[] =
  [
    {
      label: 'All',
      value: EReportTypeKeys.ALL,
    },
    {
      label: 'Medical Referrals List',
      value: EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
    },
    {
      label: 'STS',
      value: EReportTypeKeys.STS_REPORT,
    },
    {
      label: 'OSHA Recordable STS',
      value: EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
    },
    {
      label: 'Daily Biological Report',
      value: EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
    },
    {
      label: 'Equipment Specifications & Noise Levels Report',
      value: EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
    },
    {
      label: 'Cover Letter',
      value: EReportTypeKeys.COVER_LETTERS,
    },
    {
      label: 'Summary of Testing',
      value: EReportTypeKeys.SUMMARY_OF_TESTING,
    },
    {
      label: 'Other',
      value: EReportTypeKeys.OTHER,
    },
  ];
export const ORGANIZATION_REPORT_TYPE_SELECT_OPTIONS_WITHOUT_ALL: OrganizationSelectOptionReportType[] =
  [
    {
      label: 'Medical Referrals List',
      value: EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
    },
    {
      label: 'STS',
      value: EReportTypeKeys.STS_REPORT,
    },
    {
      label: 'OSHA Recordable STS',
      value: EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
    },
    {
      label: 'Daily Biological Report',
      value: EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
    },
    {
      label: 'Equipment Specifications & Noise Levels Report',
      value: EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
    },
    {
      label: 'Audiologist Cover Letters & Summary of Testing',
      value: EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING,
    },
    {
      label: 'Other',
      value: EReportTypeKeys.OTHER,
    },
  ];

export interface SendParticipantReportsBody {
  from: string;
  to: string;
  sendEmail: boolean;
  sendSms: boolean;
}

export interface IHCPSupervisorLocation extends ILocation {
  participantsCount: number;
  participantsTestedLastYearCount: number;
  participantsWithSTSCount: number;
  visitsCount: number;
}

export interface ICreateAudiologistCoverLetterBody {
  signature: string;
  from: string;
  to: string;
  isFinal: boolean;
}

export interface IHistoricalDocumentParams {
  name: string;
  signingDate: string | null;
  signatoryName: string;
  isSigned: boolean;
  file: File | null;
}
