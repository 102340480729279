import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Header } from 'components/header';
import { useAuthSelector } from 'store/selectors/auth';
import { useEffect, useMemo } from 'react';
import { UserRoles } from 'api/services/auth/types';
import { isAdminLoggedInAsUser } from 'utils/isAdminLoggedInAsUser';

const authorizedPaths = [
  { path: '/', exact: true, startWith: false },
  { path: '/admin', exact: false, startWith: true },
  { path: '/ohc', exact: false, startWith: true },
  { path: '/site-manager', exact: false, startWith: true },
  { path: '/professional-supervisor', exact: false, startWith: true },
  { path: '/hcp-supervisor', exact: false, startWith: true },
  { path: '/account-manager', exact: false, startWith: true },
  { path: '/questionnaire', exact: true, startWith: false },
  { path: '/hearing-test-results', exact: true, startWith: true },
];

const NO_HEADER_NAVBAR_PATHS = [
  '/verify',
  '/mfa-verification',
  '/questionnaire',
  '/hearing-test-results',
];

export function Layout() {
  const authState = useAuthSelector();
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  useEffect(() => {
    if (
      authorizedPaths.find((p) =>
        p.exact ? pathname === p.path : pathname.startsWith(p.path),
      ) &&
      authState &&
      authState?.user?.role !== UserRoles.Participant &&
      !isAdminLoggedInAsUser(authState.token) &&
      (!authState?.user ||
        (!authState.user.ignoreMfa &&
          (!authState.user.emailVerified || !authState.user.phoneVerified)))
    )
      navigate('/login');
  }, [authState, pathname]);

  const isHeader = useMemo(
    () => !NO_HEADER_NAVBAR_PATHS.includes(pathname),
    [pathname],
  );

  return (
    <>
      {isHeader && <Header />}
      <Outlet />
    </>
  );
}
