import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import BasicTable, { Action } from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import FileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { DashboardSelect } from 'components/dashboard-select';
import { useProfessionalSupervisorDashboardState } from 'hooks/search-params/useProfessionalSupervisorDashboardState';
import {
  getReportFileNameByReportTypeKey,
  getReportFileNameBySignedDocument,
} from 'components/shared-pages/location/reports/utils';
import _ from 'lodash';
import { ConfirmationModal, PdfPreviewModal } from 'components/modal';
import { IRenderedOptionEntity } from 'types/dataStructureTypes';
import { ToggleTableFilter } from 'components/table-filter/ToggleTableFilter';
import { UploadHistoricalDocumentForm } from 'components/forms/upload-historical-document-form';
import { getCells } from './cells';
import {
  EReportTypeKeys,
  ESignedDocumentStatuses,
  ICombinedReport,
} from '../../../../api/services/organization/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { Button } from '../../../../components/common/button';
import { DateRangePickerModal } from '../../../../components/modal/DateRangePickerModal';

export type ProfessionalSupervisorReportType = IRenderedOptionEntity<
  | EReportTypeKeys.ALL
  | EReportTypeKeys.MEDICAL_REFERRAL_LISTS
  | EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT
  | EReportTypeKeys.STS_REPORT
  | EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS
  | EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
  | EReportTypeKeys.COVER_LETTERS
  | EReportTypeKeys.SUMMARY_OF_TESTING
  | EReportTypeKeys.OTHER
>;

const REPORT_TYPE_SELECT_OPTIONS: ProfessionalSupervisorReportType[] = [
  {
    label: 'All',
    value: EReportTypeKeys.ALL,
  },
  {
    label: 'Medical Referrals List',
    value: EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
  },
  {
    label: 'STS',
    value: EReportTypeKeys.STS_REPORT,
  },
  {
    label: 'OSHA Recordable STS',
    value: EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
  },
  {
    label: 'Equipment Specifications & Noise Levels Report',
    value: EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
  },
  {
    label: 'Cover Letter',
    value: EReportTypeKeys.COVER_LETTERS,
  },
  {
    label: 'Summary of Testing',
    value: EReportTypeKeys.SUMMARY_OF_TESTING,
  },
  {
    label: 'Other',
    value: EReportTypeKeys.OTHER,
  },
];

export function ProfessionalSupervisorReportsList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedReportType, setSelectedReportType] = useState<
    ProfessionalSupervisorReportType['value']
  >(EReportTypeKeys.ALL);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deletedCoverLetter, setDeletedCoverLetter] =
    useState<ICombinedReport | null>(null);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  const {
    selectedLocation,
    loading,
    locations,
    error,
    setError,
    setSelectedLocation,
  } = useProfessionalSupervisorDashboardState<
    ProfessionalSupervisorReportType['value']
  >({ setCurrentReportType: setSelectedReportType });

  useEffect(() => {
    if (error)
      showSnackbar(getErrorMessageComponent(error), {
        variant: 'error',
      });
    setError('');
  }, [error]);

  const locationSelectOptions = useMemo(
    () =>
      locations?.map((location) => ({
        label: `${location.organization?.name} - ${location.name}`,
        value: location.id,
      })) || [],
    [locations],
  );

  const onLocationSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedLocation(
        locations.find((location) => location.id === e.target.value) || null,
      );
    },
    [locations],
  );

  const [dateRange, setDateRange] = useState<{
    dateFrom: DateTime;
    dateTo: DateTime;
  }>({
    dateFrom: DateTime.now().minus({ month: 12 }),
    dateTo: DateTime.now(),
  });
  const [datePickerOpened, setDatePickerOpened] = useState(false);

  const fetchReports = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
    ) => {
      if (!selectedLocation?.id) return null;
      let reportType: any =
        selectedReportType === EReportTypeKeys.COVER_LETTERS ||
        selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
          ? EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
          : selectedReportType;
      if (selectedReportType === EReportTypeKeys.ALL) {
        reportType = [
          EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
          EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
          EReportTypeKeys.STS_REPORT,
          EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING,
          EReportTypeKeys.OTHER,
        ];
      }
      try {
        const data = await api.organization.getLocationReportsByType(
          Number(selectedLocation?.id),
          reportType,
          {
            limit,
            offset,
            orderBy,
            orderDirection,
          },
        );
        return data;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [selectedLocation?.id, selectedReportType],
  );

  const tableState = useTableState<ICombinedReport>({
    fetchDataFunction: fetchReports,
  });

  const downloadCoverLetterAndSummaryOfTestingPDF = async (
    listItem: ICombinedReport,
    documentType: 'audiologist-cover-letter-pdf' | 'summary-of-testing-pdf',
  ) => {
    if (!selectedLocation?.id) return null;
    try {
      let fileName = '';
      if (!_.isEmpty(listItem.historicalDocument)) {
        fileName = documentType;
      } else if (documentType === 'audiologist-cover-letter-pdf') {
        fileName = `cover_letter_${listItem.name}.pdf`;
      } else {
        fileName = `summary_of_testing_${listItem.name}.pdf`;
      }
      const data =
        documentType === 'audiologist-cover-letter-pdf'
          ? await api.organization.getAudiologistCoverLetterPDF(
              selectedLocation?.id,
              listItem.reportId,
            )
          : await api.organization.getSummaryOfTestingPDF(
              selectedLocation?.id,
              listItem.reportId,
            );

      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const downloadReportPDF = async (item: ICombinedReport) => {
    try {
      if (
        selectedReportType === EReportTypeKeys.ALL &&
        item.reportType ===
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
      ) {
        await downloadCoverLetterAndSummaryOfTestingPDF(
          item,
          'audiologist-cover-letter-pdf',
        );
        await downloadCoverLetterAndSummaryOfTestingPDF(
          item,
          'summary-of-testing-pdf',
        );
        return;
      }
      const attachedDocument = !_.isEmpty(item.signedDocument)
        ? item.signedDocument
        : item.historicalDocument;
      if (!attachedDocument) throw new Error('Invalid document');
      const fileName = getReportFileNameBySignedDocument(attachedDocument);
      const data = await api.organization.getLocationReportPDF(
        item.reportType,
        item.reportId,
      );
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const deleteCoverLetter = async (coverLetter: ICombinedReport) => {
    if (!selectedLocation?.id || !coverLetter.reportId) {
      return;
    }
    try {
      await api.organization.deleteAudiologistCoverLetter(
        selectedLocation?.id,
        coverLetter.reportId,
      );
      tableState.refreshData();
      showSnackbar('Cover Letter successfully deleted', {
        variant: 'success',
      });
      setConfirmationOpen(false);
      setDeletedCoverLetter(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not delete Cover Letter'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleDownloadReportDocument = async (
    report: ICombinedReport,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    const { name, reportId, reportType, created_at } = report;

    try {
      let fileName = '';
      let data = null;
      if (
        (selectedReportType === EReportTypeKeys.COVER_LETTERS ||
          selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING) &&
        selectedLocation
      ) {
        fileName =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? `cover_letter_${name}.pdf`
            : `summary_of_testing_${name}.pdf`;
        data =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? await api.organization.getAudiologistCoverLetterPDF(
                selectedLocation?.id,
                reportId,
              )
            : await api.organization.getSummaryOfTestingPDF(
                selectedLocation?.id,
                reportId,
              );
      } else {
        fileName = getReportFileNameByReportTypeKey(reportType, created_at);
        data = await api.organization.getLocationReportPDF(
          reportType,
          reportId,
        );
      }

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const previewPdfReport = async (report: ICombinedReport) => {
    setOpenPdfPreview(true);
    setIsPreviewLoading(true);
    return handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
        setIsPreviewLoading(false);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const actions = useMemo(() => {
    const actions: Action<ICombinedReport>[] = [
      {
        title: 'Preview',
        actionType: 'preview',
        onClick: (list: ICombinedReport) => previewPdfReport(list),
        disabled: (item: ICombinedReport) =>
          (_.isEmpty(item.historicalDocument) &&
            item.signedDocument?.status !== ESignedDocumentStatuses.Signed) ||
          // TODO: the AUDIOLOGIST_COVER_LETTERS and SUMMARY_OF_TESTING report display needs refactoring
          (selectedReportType === EReportTypeKeys.ALL &&
            item.reportType ===
              EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING),
      },
    ];
    if (
      selectedReportType !== EReportTypeKeys.COVER_LETTERS &&
      selectedReportType !== EReportTypeKeys.SUMMARY_OF_TESTING
    ) {
      actions.push({
        title: 'Download',
        actionType: 'download',
        onClick: (list: ICombinedReport) => downloadReportPDF(list),
        disabled: (list: ICombinedReport) =>
          _.isEmpty(list.historicalDocument) &&
          list.signedDocument?.status !== ESignedDocumentStatuses.Signed,
      });
    }
    if (
      selectedReportType === 'equipment-specifications-and-noise-levels-reports'
    ) {
      actions.push({
        title: 'Edit',
        actionType: 'edit',
        onClick: (list: ICombinedReport) => {
          navigate(
            `/professional-supervisor/locations/${selectedLocation?.id}/equipment-specifications-and-noise-levels-reports/${list.reportId}/update`,
          );
        },
        disabled: (list: ICombinedReport) =>
          !_.isEmpty(list.historicalDocument) ||
          list.signedDocument?.status === ESignedDocumentStatuses.Signed,
      });
    }
    if (
      selectedReportType === EReportTypeKeys.COVER_LETTERS ||
      selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
    ) {
      actions.push(
        {
          title: 'Edit',
          actionType: 'edit',
          onClick: (list: ICombinedReport) => {
            navigate(
              `/professional-supervisor/locations/${selectedLocation?.id}/cover-letter-and-summary-of-testing/${list.reportId}/update`,
            );
          },
          disabled: (list: ICombinedReport) =>
            !_.isEmpty(list.historicalDocument) ||
            list.signedDocument?.status === ESignedDocumentStatuses.Signed,
        },
        {
          title: 'Download',
          actionType: 'download',
          onClick: (list: ICombinedReport) =>
            downloadCoverLetterAndSummaryOfTestingPDF(
              list,
              selectedReportType === EReportTypeKeys.COVER_LETTERS
                ? 'audiologist-cover-letter-pdf'
                : 'summary-of-testing-pdf',
            ),
          disabled: (item: ICombinedReport) =>
            _.isEmpty(item.historicalDocument) &&
            item.signedDocument?.status !== ESignedDocumentStatuses.Signed,
        },
        {
          title: 'Delete',
          actionType: 'delete',
          onClick: (list: ICombinedReport) => {
            setDeletedCoverLetter(list);
            setConfirmationOpen(true);
          },
          disabled: (list: ICombinedReport) => !!list.signedDocument?.status,
        },
      );
    }
    return actions;
  }, [selectedLocation, selectedReportType]);

  const actionButton = useMemo(() => {
    const result = {
      handler: () => setDatePickerOpened(true),
      title: 'Sign Referral List',
    };
    if (
      selectedReportType === 'equipment-specifications-and-noise-levels-reports'
    ) {
      result.handler = () =>
        navigate(
          `/professional-supervisor/locations/${selectedLocation?.id}/equipment-specifications-and-noise-levels-reports/create`,
        );
      result.title = 'New Report';
    }
    if (
      selectedReportType === EReportTypeKeys.COVER_LETTERS ||
      selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
    ) {
      result.handler = () => setDatePickerOpened(true);
      result.title = 'New Letter & Summary';
    }
    if (
      [
        EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
        EReportTypeKeys.STS_REPORT,
      ].includes(selectedReportType)
    ) {
      result.handler = () => setDatePickerOpened(true);
      result.title = 'Generate Report';
    }
    if (selectedReportType === EReportTypeKeys.OTHER) {
      result.handler = () => setUploadModalOpened(true);
      result.title = 'Upload Report';
    }
    return result;
  }, [selectedReportType, selectedLocation]);

  const dateRangePickerParams = useMemo(() => {
    const result = {
      title: 'Referral list date range',
      pathname: `/professional-supervisor/locations/${selectedLocation?.id}/referral-lists/sign`,
    };
    if (
      selectedReportType === EReportTypeKeys.COVER_LETTERS ||
      selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
    ) {
      result.title = 'Cover letter date range';
      result.pathname = `/professional-supervisor/locations/${selectedLocation?.id}/cover-letter-and-summary-of-testing/create`;
    }
    if (selectedReportType === EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT) {
      result.title = 'OSHA Recordable STS date range';
      result.pathname = `/professional-supervisor/locations/${selectedLocation?.id}/${EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT}/sign`;
    }
    if (selectedReportType === EReportTypeKeys.STS_REPORT) {
      result.title = 'STS date range';
      result.pathname = `/professional-supervisor/locations/${selectedLocation?.id}/${EReportTypeKeys.STS_REPORT}/sign`;
    }
    return result;
  }, [selectedReportType, selectedLocation]);

  const cells = useMemo(() => getCells({ actions }), [actions]);

  return (
    <Grid>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        isLoading={isPreviewLoading}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid>
          <Grid container mb={theme.spacing(3)} gap={2}>
            <Grid item xs={12} sm="auto" mt={theme.spacing(2)}>
              <DashboardSelect
                label="Location"
                labelIcon="location"
                value={selectedLocation?.id || ''}
                options={locationSelectOptions}
                onChange={onLocationSelect}
                loading={loading}
              />
            </Grid>

            <Grid container mb={theme.spacing(0.5)}>
              <ToggleTableFilter
                options={REPORT_TYPE_SELECT_OPTIONS}
                onChange={(selectedReportType) => {
                  setSelectedReportType(selectedReportType);
                  searchParams.set('reportType', selectedReportType);
                  searchParams.delete('page');
                  setSearchParams(searchParams);
                }}
                value={selectedReportType}
              />
            </Grid>
          </Grid>
        </Grid>

        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={actionButton.handler}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {actionButton.title}
        </Button>
      </Grid>

      <BasicTable<ICombinedReport> cells={cells} tableState={tableState} />

      <DateRangePickerModal
        title={dateRangePickerParams.title}
        opened={datePickerOpened}
        onClose={() => setDatePickerOpened(false)}
        onSelect={({ dateFrom, dateTo }) => {
          setDateRange({
            dateFrom: dateFrom as DateTime,
            dateTo: dateTo as DateTime,
          });
          navigate({
            pathname: dateRangePickerParams.pathname,
            search: createSearchParams({
              dateFrom: dateFrom?.toISO({ includeOffset: true }) || '',
              dateTo: dateTo?.toISO({ includeOffset: true }) || '',
            }).toString(),
          });
        }}
        dateRange={dateRange}
      />

      {deletedCoverLetter && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setDeletedCoverLetter(null);
          }}
          handleSubmit={() => deleteCoverLetter(deletedCoverLetter)}
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete the Cover Letter & Summary of Testing "${deletedCoverLetter.name}"`}
              <br />
              Are you sure?
            </>
          }
        />
      )}

      {uploadModalOpened && (
        <UploadHistoricalDocumentForm
          reportType={
            selectedReportType === EReportTypeKeys.COVER_LETTERS ||
            selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
              ? EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING
              : selectedReportType
          }
          locationId={Number(selectedLocation?.id)}
          handleClose={() => setUploadModalOpened(false)}
          handleSubmit={() => {
            tableState.refreshData();
            setUploadModalOpened(false);
          }}
        />
      )}
    </Grid>
  );
}
