import { Chip } from 'components/common/chip';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

interface ILastRosterUploadDateCellProps {
  date: string;
  onClick?: () => void;
}

export function LastRosterUploadDateCell(
  props: ILastRosterUploadDateCellProps,
) {
  const { date, onClick } = props;

  const chipColors = useMemo(() => {
    const dateTime: DateTime = DateTime.fromISO(date);
    const diffFromNow = DateTime.now().diff(dateTime, ['months']).months;
    if (diffFromNow < 3) {
      return {
        text: '#287013',
        dot: '#4AD520',
        background: 'rgba(104, 247, 56, 0.20)',
      };
    }
    if (diffFromNow < 6) {
      return {
        text: '#815800',
        dot: '#815800',
        background: 'rgba(255, 237, 143, 0.5)',
      };
    }
    return {
      text: '#C45558',
      dot: '#C45558',
      background: 'rgba(255,119,119,0.35)',
    };
  }, [date]);

  const formattedDate = useMemo(() => {
    if (!date) {
      return 'N/A';
    }
    const dateTime: DateTime = DateTime.fromISO(date);
    return dateTime.toFormat('MM/dd/yyyy');
  }, [date]);

  return (
    <Chip
      label={formattedDate}
      backgroundColor={chipColors.background}
      labelColor={chipColors.text}
      isDot
      dotColor={chipColors.dot}
      sx={onClick ? { cursor: 'pointer' } : {}}
      clickable={!!onClick && !!date}
      onClick={(e) => {
        if (!onClick || !date) {
          return;
        }
        e.stopPropagation();
        onClick();
      }}
    />
  );
}
