import { Tabs, TabsProps } from '@mui/material';

export function TabsContainer(props: TabsProps) {
  return (
    <Tabs
      sx={{
        '&.MuiTabs-root': {
          width: '100%',
        },
        '&>.MuiTabs-scroller>.MuiTabs-indicator': {
          display: 'none',
        },
      }}
      {...props}
    />
  );
}
