import { Cell } from 'components/table/types';
import { DateCell } from 'components/table/components';
import { SimpleButton } from 'components/common/button';
import {
  ECombinedReportStatus,
  EReportTypeKeys,
  GetLocationReportTypes,
  ICombinedReport,
} from 'api/services/organization/types';
import { renderReportStatus } from 'utils/render/tableRender';
import { DateTime } from 'luxon';
import _ from 'lodash';

export const getCells = ({
  onPreview,
  onDownload,
  onEdit,
  selectedReportType,
}: {
  onPreview: (report: ICombinedReport) => void;
  onDownload: (report: ICombinedReport) => void;
  onEdit?: (report: ICombinedReport) => void;
  editable?: boolean;
  selectedReportType: GetLocationReportTypes;
}): Cell<ICombinedReport>[] => {
  const isShowPreview = (item: ICombinedReport) => {
    if (
      item.status === ECombinedReportStatus.Signed ||
      item.status === ECombinedReportStatus.Historical
    ) {
      // TODO: the AUDIOLOGIST_COVER_LETTERS and SUMMARY_OF_TESTING report display needs refactoring
      if (
        item.reportType !==
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING ||
        (item.reportType ===
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING &&
          selectedReportType !== EReportTypeKeys.ALL)
      ) {
        return true;
      }
      return false;
    }
  };

  const isShowDownload = (item: ICombinedReport) => {
    if (
      item.status === ECombinedReportStatus.Signed ||
      item.status === ECombinedReportStatus.Historical
    ) {
      return true;
    }
    return false;
  };

  return [
    {
      name: 'File name',
      key: 'name',
      width: '40%',
    },
    {
      name: 'Date',
      render: (item: ICombinedReport) =>
        `${DateTime.fromISO(item.created_at).toFormat('MM/dd/yyyy')}`,
    },
    {
      name: 'Status',
      key: 'status',
      render: (item: ICombinedReport) => renderReportStatus(item.status),
    },
    {
      name: 'Signed By',
      key: 'signatory',
      render: (item: ICombinedReport) => {
        if (!_.isEmpty(item.historicalDocument))
          return item.historicalDocument.signatoryName;
        if (!_.isEmpty(item.signedDocument?.signatory))
          return `${item.signedDocument?.signatory?.given || ''} ${
            item.signedDocument?.signatory?.family || ''
          }`;
        return '-';
      },
    },
    {
      name: 'Signed Date',
      key: 'signingDateTime',
      render: (item: ICombinedReport) => {
        if (item.historicalDocument?.signingDate) {
          return <DateCell date={item.historicalDocument?.signingDate} />;
        }
        if (item.signedDocument?.signingDateTime) {
          return <DateCell date={item.signedDocument.signingDateTime} />;
        }
        return '-';
      },
    },
    {
      name: '',
      render: (item: ICombinedReport) => (
        <>
          {item.status === ECombinedReportStatus.Draft && (
            <SimpleButton
              actionType="edit"
              onClick={() => onEdit?.(item)}
              tooltipText="Edit"
            />
          )}
          {isShowPreview(item) && (
            <SimpleButton
              actionType="preview"
              onClick={() => onPreview(item)}
              tooltipText="Preview"
            />
          )}
          {isShowDownload(item) && (
            <SimpleButton
              actionType="download"
              onClick={() => onDownload(item)}
              tooltipText="Download"
            />
          )}
        </>
      ),
      align: 'right',
    },
  ].filter((column) => !!column) as Cell<ICombinedReport>[];
};
