import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { IQuestionnaireResults } from 'api/services/questionnaire/types';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import FileDownload from 'js-file-download';
import { Participant, UserRoles } from '../../../api/services/auth/types';
import { ParticipantHearingReportHeading, ParticipantInfo } from './index';
import { HearingTestResult } from '../../../api/services/hearing-test-results/types';
import { AudiogramChart } from './audiogram-chart';
import { ParticipantHearingTestResultsTable } from '../../participant-hearing-test-result-table';
import { AudiologistNotesList } from './AudiologistNotesList';
import { QuestionnaireResults } from './questionnaire-results';
import { NotesList } from './NotesList';
import { SignatureButton } from '../../common/button/SignatureButton';
import {
  ESignedDocumentStatuses,
  ISignedDocument,
} from '../../../api/services/organization/types';
import { api } from '../../../api';
import { Button } from '../../common/button';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';
import { useAuthSelector } from '../../../store/selectors/auth';

interface ParticipantHearingReportProps {
  participant: Participant | null;
  loading: boolean;
  hearingTestResult: HearingTestResult | null;
  baseLineHearingTestResult?: HearingTestResult | null;
  questionnaireResults: IQuestionnaireResults | null;
  questionnaireResultsLoading: boolean;
  editable?: boolean;
  onUpdate?: () => Promise<void> | void;
}

export function ParticipantHearingReport({
  participant,
  loading,
  hearingTestResult,
  baseLineHearingTestResult,
  questionnaireResults,
  questionnaireResultsLoading,
  editable,
  onUpdate,
}: ParticipantHearingReportProps) {
  const theme = useTheme();
  const [signedDocument, setSignedDocument] = useState<null | ISignedDocument>(
    null,
  );
  const [signatureInProgress, setSignatureInProgress] = useState(false);
  const [hideSignButton, setHideSignButton] = useState(true);

  const authState = useAuthSelector();
  const getSignedDocument = async (): Promise<ISignedDocument | null> => {
    if (participant === null) return null;

    const { signedDocument = null } = await api.participant
      .getParticipantReport(participant.id)
      .then((data) => {
        setHideSignButton(false);
        return data;
      })
      .catch(() => {
        setHideSignButton(true);
        return { signedDocument: null };
      });
    return signedDocument?.status === ESignedDocumentStatuses.Processing
      ? new Promise((resolve) => {
          setTimeout(() => resolve(getSignedDocument()), 3000);
        })
      : signedDocument;
  };

  useEffect(() => {
    setSignatureInProgress(true);
    getSignedDocument().then((signedDocument) => {
      setSignedDocument(signedDocument);
      setSignatureInProgress(false);
    });
  }, [participant]);

  const sign = async (str: string) => {
    setSignatureInProgress(true);
    await api.participant.signParticipantHearingTestReport(
      participant?.id ?? 0,
      {
        signature: str,
        graphSvg: document.querySelector('.recharts-surface')?.outerHTML ?? '',
        audiologistNoteContent:
          document.querySelector('#note-content')?.innerHTML,
      },
    );
    showSnackbar(
      'Report signed successfully, please wait a minute until it will be available for download.',
      {
        variant: 'success',
        autoHideDuration: 7000,
      },
    );
    const signedDocument = await getSignedDocument();

    setSignedDocument(signedDocument);
    setSignatureInProgress(false);
  };

  const download = async () => {
    const fileName = `${participant?.given ?? ''} ${
      participant?.family ?? ''
    } - Hearing test results.pdf`;
    const data = await api.participant.getOwnParticipantReportPDF(
      participant?.id ?? 0,
    );
    FileDownload(data, fileName);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        border: '1px solid #D0D3D5',
        padding: { xs: theme.spacing(1), sm: theme.spacing(3) },
        marginBottom: theme.spacing(1),
      }}
    >
      <ParticipantInfo
        participant={participant || null}
        loading={loading}
        title="Audiometric Evaluation Report"
        selectedTestResult={hearingTestResult || null}
      />
      <ParticipantHearingReportHeading
        sx={{
          marginBottom: theme.spacing(1),
        }}
      >
        Note
      </ParticipantHearingReportHeading>
      <NotesList hearingTestResult={hearingTestResult} loading={loading} />

      {(editable || !!hearingTestResult?.audiologistNotes?.length) && (
        <AudiologistNotesList
          hearingTestResult={hearingTestResult}
          loading={loading}
          editable={editable}
          onUpdate={onUpdate}
        />
      )}

      <ParticipantHearingReportHeading
        sx={{
          marginBottom: theme.spacing(1),
        }}
      >
        Baseline test thresholds
      </ParticipantHearingReportHeading>
      <Grid
        sx={{
          backgroundColor: 'rgba(236, 243, 236, 0.30)',
          px: { xs: theme.spacing(1), sm: theme.spacing(4) },
          py: { xs: theme.spacing(1), sm: theme.spacing(4) },
          marginBottom: theme.spacing(2),
          maxWidth: '100%',
        }}
      >
        {hearingTestResult && !loading ? (
          <ParticipantHearingTestResultsTable
            hearingTestResults={_.compact([
              baseLineHearingTestResult,
              hearingTestResult,
            ])}
            backgroundColor="white"
            dividerBackgroundColor="rgba(236, 243, 236, 0.30)"
            groupedHeaderFontSize="9px"
            headerFontSize="8px"
            cellFontSize="8px"
          />
        ) : (
          <Skeleton variant="rounded" width="100%" height="180px" />
        )}
      </Grid>
      <ParticipantHearingReportHeading
        sx={{
          marginBottom: theme.spacing(1),
        }}
      >
        Audiogram
      </ParticipantHearingReportHeading>
      <Box
        sx={{
          background:
            'url("/images/backgrounds/jagged-border-up.png"), url("/images/backgrounds/jagged-border-down.png"), url("/images/backgrounds/audiogram-texture.png"), linear-gradient(#F8FFFE, #F8FFFE)',
          backgroundSize: '38px 10px, 38px 10px, cover, cover',
          backgroundRepeat: 'repeat-x, repeat-x, no-repeat, no-repeat',
          backgroundPosition: 'top left, bottom left, top left, top left',
          px: theme.spacing(3),
          py: theme.spacing(4),
          marginBottom: theme.spacing(2),
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: '100% !important' }}
        >
          <Grid item xs={12} md={6} sx={{ maxWidth: '100% !important' }}>
            {hearingTestResult && !loading ? (
              <AudiogramChart hearingTestResult={hearingTestResult} />
            ) : (
              <Skeleton variant="rounded" width="100%" height="300px" />
            )}
          </Grid>
        </Grid>
      </Box>
      {!!hearingTestResult?.attachments?.length &&
        authState?.user?.role !== UserRoles.Participant && (
          <>
            <ParticipantHearingReportHeading
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
              }}
            >
              Attached files
            </ParticipantHearingReportHeading>
            <Grid
              container
              direction="column"
              sx={{
                marginBottom: theme.spacing(2),
              }}
              spacing={theme.spacing(1)}
            >
              {hearingTestResult?.attachments.map((attachment) => (
                <Grid item>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: 'DodgerBlue',
                      textDecoration: 'underline',
                      fontSize: '12px',
                    }}
                    component="span"
                    onClick={async () => {
                      try {
                        await api.hearingTestResults.getHearingTestResultAttachment(
                          hearingTestResult?.id,
                          attachment.id,
                          attachment.file.name,
                        );
                      } catch (e) {
                        showSnackbar(
                          getErrorMessageComponent(getErrorMessage(e)),
                          {
                            variant: 'error',
                          },
                        );
                      }
                    }}
                  >
                    {attachment.file.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      <Box
        sx={{
          padding: { xs: theme.spacing(2), sm: theme.spacing(4) },
          borderRadius: theme.spacing(2),
          border: '1px solid rgba(202, 194, 190, 0.30)',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          fontSize={20}
          variant="h1"
          sx={{ marginBottom: theme.spacing(3) }}
        >
          Questionnaire Results{' '}
          {questionnaireResults?.created_at && (
            <Typography
              component="span"
              variant="mono"
              sx={{ fontSize: '14px', fontWeight: 400 }}
            >
              (date:{' '}
              {DateTime.fromISO(questionnaireResults?.created_at).toFormat(
                'MM.dd.yy',
              )}
              )
            </Typography>
          )}
        </Typography>
        <QuestionnaireResults
          questionnaireResults={questionnaireResults}
          loading={questionnaireResultsLoading}
        />
      </Box>
      {!hideSignButton && (
        <>
          <SignatureButton
            disabled={signatureInProgress}
            buttonTitle="Add signature"
            buttonProps={{ endIcon: <AddIcon fontSize="inherit" /> }}
            onSubmit={async (str: string) => {
              await sign(str);
            }}
            onCancel={() => {}}
            signedDocument={signedDocument}
          />
          <div
            style={{
              paddingTop: '15px',
            }}
          >
            {signatureInProgress ? (
              <Skeleton height={40} variant="rounded" />
            ) : (
              <Button
                endIcon={<FileDownloadOutlined fontSize="inherit" />}
                onClick={download}
                variant="contained"
                color="secondary"
                disabled={
                  signedDocument?.status !== ESignedDocumentStatuses.Signed
                }
              >
                Download report
              </Button>
            )}
          </div>
        </>
      )}
    </Grid>
  );
}
