import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getPersistConfig } from 'redux-deep-persist';

import { PersistPartial } from 'redux-persist/es/persistReducer';
import { sagas } from './sagas';
import { authReducer } from './reducers/auth';
import { Actions, State } from './types';
import { dashboardReducer } from './reducers/dashboard';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers<State, any>({
  auth: authReducer,
  dashboard: dashboardReducer,
});

const persistConfig = getPersistConfig<State>({
  key: 'root',
  storage,
  rootReducer,
  blacklist: ['auth.error', 'auth.loading'],
});

const persistedReducer = persistReducer<State, Actions>(
  persistConfig,
  rootReducer,
);

export const store = configureStore<State & PersistPartial, Actions>({
  reducer: persistedReducer,
  middleware: () => [sagaMiddleware],
});
export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
