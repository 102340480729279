import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { Button } from 'components/common/button';
import { ILocation } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { ConfirmationModal } from 'components/modal';
import { PageHeader } from 'components/admin-page-layout';
import { AssignLocationForm } from './components/assign-location-form';
import { getCells } from './cells';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';

export function AdminProfessionalSupervisorLocationsList() {
  const { professionalSupervisorId } = useParams();
  const [professionalSupervisorName, setProfessionalSupervisorName] =
    useState('');
  const [
    professionalSupervisorNameLoading,
    setProfessionalSupervisorNameLoading,
  ] = useState(true);
  const [actionedLocation, setActionedLocation] = useState<ILocation | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const getProfessionalSupervisorName = async () => {
    if (!professionalSupervisorId) {
      return;
    }
    setProfessionalSupervisorNameLoading(true);
    try {
      const { given, family } = await api.user.getProfessionalSupervisorById(
        professionalSupervisorId,
      );
      setProfessionalSupervisorName(`${given} ${family}`);
      setProfessionalSupervisorNameLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProfessionalSupervisorName();
  }, [professionalSupervisorId]);

  const fetchProfessionalSupervisorLocations = useCallback(
    async (limit: number, offset: number) => {
      if (!professionalSupervisorId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.user.getProfessionalSupervisorLocations(
        professionalSupervisorId,
        {
          limit,
          offset,
        },
      );
      return locationsData;
    },
    [professionalSupervisorId],
  );

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchProfessionalSupervisorLocations,
  });

  const actions = [
    {
      title: 'Remove',
      onClick: (item: ILocation) => {
        setActionedLocation(item);
        setConfirmationOpen(true);
      },
    },
  ];

  const cells = useMemo(() => {
    if (professionalSupervisorId) {
      return getCells(professionalSupervisorId);
    }
    return [];
  }, [professionalSupervisorId]);

  const handleFormClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onFormSubmit = useCallback(() => {
    tableState.refreshData();
    showSnackbar('Location successfully assigned', { variant: 'success' });
    handleFormClose();
    setActionedLocation(null);
  }, []);

  const unassignLocationToProfessionalSupervisor = useCallback(
    async (userId: number | string) => {
      if (!actionedLocation) return;
      try {
        await api.organization.unassignProfessionalSupervisorFromLocation(
          actionedLocation.id,
          userId,
        );
        setActionedLocation(null);
        tableState.refreshData();
        showSnackbar('Location successfully unassigned', {
          variant: 'success',
        });
      } catch (e: any) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [actionedLocation],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Locations"
        entityName={professionalSupervisorName}
        titleLoading={professionalSupervisorNameLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Assign
          </Button>
        }
      />

      <BasicTable<ILocation>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && professionalSupervisorId && (
        <AssignLocationForm
          professionalSupervisorId={professionalSupervisorId}
          onSubmit={onFormSubmit}
          handleClose={handleFormClose}
        />
      )}

      {actionedLocation && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setActionedLocation(null);
          }}
          handleSubmit={async () =>
            professionalSupervisorId &&
            unassignLocationToProfessionalSupervisor(professionalSupervisorId)
          }
          submitButtonTitle="Remove"
          title={
            <>
              {`You are about to remove the professional supervisor from the ${actionedLocation.name} location`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
