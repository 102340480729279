import i18next from 'i18n/config';
import { User } from 'api/services/auth/types';
import { StatusCell } from 'components/table/components';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<User>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
  },
  {
    name: i18next.t('common.firstName'),
    key: 'given',
  },
  {
    name: i18next.t('common.middleName'),
    key: 'middleName',
  },
  {
    name: i18next.t('common.lastName'),
    key: 'family',
  },
  {
    name: i18next.t('common.email'),
    key: 'email',
  },
  {
    name: i18next.t('common.phone'),
    key: 'phone',
  },
  {
    name: i18next.t('common.organization'),
    key: 'organization',
    render: (item: User) => item.siteManager?.organization.name,
  },
  {
    name: i18next.t('common.externalId'),
    key: 'externalId',
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: User) => <StatusCell status={item.invitationStatus} />,
  },
];
