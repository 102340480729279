import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/common/button';
import {
  ILocation,
  ILocationParticipantsHearingTestsSummary,
} from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { PageHeader } from 'components/admin-page-layout';
import * as Yup from 'yup';
import { useOrganization } from 'hooks/useOrganization';
import { getCells } from './cells';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../utils';
import { AssignProfessionalSupervisorForm } from './components/assign-professional-supervisor-form';
import { HearingTestResultsSummary } from '../../../components/hearing-test-results-summary';
import { LocationForm } from '../../../components/forms/location-form';

export function OrganizationLocationsList() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [formOpen, setFormOpen] = useState(false);
  const [
    assignProfessionalSupervisorFormOpen,
    setAssignProfessionalSupervisorFormOpen,
  ] = useState(false);
  const [editedLocation, setEditedLocation] = useState<ILocation | null>(null);
  const { t, i18n } = useTranslation();
  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );

  const fetchLocations = useCallback(
    async (limit: number, offset: number) => {
      if (!organizationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.organization.getLocations(
        organizationId,
        { limit, offset },
      );
      return locationsData;
    },
    [organizationId],
  );

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchLocations,
  });

  const actions = [
    {
      title: t('common.edit'),
      onClick: (location: ILocation) => {
        setEditedLocation(location);
        setFormOpen(true);
      },
    },
    {
      title: t('common.uploadRoster'),
      onClick: (location: ILocation) => {
        navigate(
          `/admin/organizations/${organizationId}/locations/${location.id}/roster-uploads`,
        );
      },
    },
    {
      title: t('common.downloadParticipantsCSV'),
      onClick: async (location: ILocation) => {
        try {
          await api.organization.exportLocationParticipantsData(
            Number(location.id),
          );
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      },
    },
    {
      title: t('common.recalculateHearingTestsParameters'),
      onClick: async (location: ILocation) => {
        try {
          const { totalResultsCount, successfullyRecalculatedResultsCount } =
            await api.organization.recalculateHearingTestParametersInLocation(
              Number(location.id),
            );
          let message = '';
          let snackbarVariant: 'success' | 'error' | 'warning' = 'success';
          if (successfullyRecalculatedResultsCount === 0) {
            message = t('organizations.couldNotRecalculateTestResults');
            snackbarVariant = 'error';
          } else if (successfullyRecalculatedResultsCount < totalResultsCount) {
            message = t(
              'organizations.successfullyRecalculatedNumberOfTestResults',
              { successfullyRecalculatedResultsCount, totalResultsCount },
            );
            snackbarVariant = 'warning';
          } else {
            message = t('organizations.successfullyRecalculatedAllTestResults');
            snackbarVariant = 'success';
          }
          showSnackbar(message, {
            variant: snackbarVariant,
            autoHideDuration: 5000,
          });
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
            variant: 'error',
          });
        }
      },
    },
    {
      title: () => t('common.assignPS'),
      onClick: async (location: ILocation) => {
        setEditedLocation(location);
        setAssignProfessionalSupervisorFormOpen(true);
      },
    },
    {
      title: t('common.dailyBiologicalReport'),
      onClick: (location: ILocation) => {
        navigate(
          `/admin/organizations/${organizationId}/locations/${location.id}/daily-biological-reports`,
        );
      },
    },
    {
      title: t('common.equipmentSpecificationAndNoiseLevelsReport'),
      onClick: (location: ILocation) => {
        navigate(
          `/admin/organizations/${organizationId}/locations/${location.id}/equipment-specifications-and-noise-levels-reports`,
        );
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  const [summary, setSummary] =
    useState<ILocationParticipantsHearingTestsSummary | null>(null);
  const [summaryLoading, setSummaryLoading] = useState(true);

  useEffect(() => {
    if (!organizationId) return;
    const requestSummary = async () => {
      try {
        setSummaryLoading(true);
        const data =
          await api.organization.getOrganizationParticipantsHearingTestsSummary(
            organizationId,
            { showInactive: tableState.filterParams?.showInactive as boolean },
          );
        setSummary(data);
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setSummaryLoading(false);
      }
    };
    requestSummary();
  }, [organizationId]);

  const LocationFormSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        externalId: Yup.string().required(t('validation.required')),
        line1: Yup.string().required(t('validation.required')),
        line2: Yup.string().optional(),
        country: Yup.string().required(t('validation.required')),
        state: Yup.string().required(t('validation.required')),
        postalCode: Yup.string().required(t('validation.required')),
        city: Yup.string().required(t('validation.required')),
        amountOfMonthsNewEmployeeShouldBeTested: Yup.number().required(
          t('validation.required'),
        ),
        amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
          Yup.number().required(t('validation.required')),
        questionnaireResourceType: Yup.string().required(
          t('validation.required'),
        ),
      }),
    [i18n.language],
  );

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={t('common.locations')}
        entityName={organization?.name}
        titleLoading={isOrganizationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedLocation(null);
              setFormOpen(true);
            }}
          >
            {t('common.new')}
          </Button>
        }
      />

      <HearingTestResultsSummary
        items={[
          {
            name: 'Completed in last 12 months',
            value: summary
              ? `${Math.round(summary.percentageOfParticipantsTestedLastYear)}%`
              : '',
            backgroundColor: '#ffe86e',
          },
          {
            name: 'of employees with STS',
            value: summary
              ? `${Math.round(summary.percentageOfParticipantsWithSts)}%`
              : '',
            backgroundColor: '#ddf4fd',
          },
          {
            name: 'Tests Needed',
            value: summary?.participantsNotTestedLastYear?.toString() || '',
            backgroundColor: '#e5fede',
          },
          {
            name: 'with OSHA Recordability STS',
            value: summary
              ? `${Math.round(
                  summary.percentageOfParticipantsWithRecordableOSHAShift,
                )}%`
              : '',
            backgroundColor: '#FDD7D7',
          },
        ]}
        loading={summaryLoading}
      />

      <BasicTable<ILocation>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {formOpen && organizationId && (
        <LocationForm
          organizationId={organizationId}
          location={editedLocation}
          validationSchema={LocationFormSchema}
          handleClose={() => setFormOpen(false)}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedLocation(null);
            if (editedLocation) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
      {assignProfessionalSupervisorFormOpen && !!editedLocation && (
        <AssignProfessionalSupervisorForm
          handleClose={() => setAssignProfessionalSupervisorFormOpen(false)}
          onSubmit={() => {
            setAssignProfessionalSupervisorFormOpen(false);
            setEditedLocation(null);
            tableState.refreshData();
            showSnackbar('Professional Supervisor successfully assigned', {
              variant: 'success',
            });
          }}
          locationId={editedLocation.id}
        />
      )}
    </BasicContainer>
  );
}
