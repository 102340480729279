import { Upload } from 'api/services/uploads/types';
import {
  StatusCell,
  DateTimeCell,
  LinkCell,
} from 'components/table/components';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<Upload>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'File Name',
    key: 'fileName',
    render: (item: Upload) => {
      const href = `/site-manager/locations/${item.locationId}/roster-uploads/${item.id}/records`;
      return <LinkCell to={href} label={item.file.name} />;
    },
  },
  {
    name: 'Date',
    key: 'created_at',
    render: (item: Upload) => (
      <DateTimeCell date={item.created_at} format="date_time" />
    ),
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: Upload) => (
      <StatusCell status={item.status} loading={!item.id} />
    ),
  },
  {
    name: 'Reason',
    key: 'reason',
  },
];
