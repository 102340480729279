import { ILocation } from 'api/services/organization/types';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<ILocation>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'External Id',
    key: 'externalId',
  },
  {
    name: 'Address',
    key: 'line1',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'State',
    key: 'state',
  },
  {
    name: 'Organization',
    key: 'organization',
    render: (item: ILocation) => item.organization?.name || '-',
  },
];
