import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { api } from 'api';
import { Organization } from 'api/services/organization/types';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { FormModal } from 'components/modal';
import { useCallback, useState } from 'react';
import { IOption } from 'common/types/common';

interface AssignOrganizationFormProps {
  accountManagerId: string;
  onSubmit: () => void;
  handleClose: () => void;
}

interface Values {
  organization: IOption | null;
}

const OrganizationFormSchema = Yup.object().shape({
  organization: Yup.object().required('Required'),
});

export function AssignOrganizationForm(props: AssignOrganizationFormProps) {
  const { accountManagerId, onSubmit, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');

  const initialValues: Values = {
    organization: null,
  };

  const assignAccountManagerToOrganization = useCallback(
    async (values: Values) => {
      if (!accountManagerId || !values.organization?.value) {
        return;
      }
      try {
        setLoading(true);
        await api.organization.assignAccountManagerToOrganization(
          values.organization.value,
          accountManagerId,
          'assign',
        );
        onSubmit();
      } catch (e: any) {
        setError(getErrorMessage(e, 'Could not save form'));
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    },
    [accountManagerId, onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={assignAccountManagerToOrganization}
      validationSchema={OrganizationFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={loading}
          title="Assign Organization to Account Manager"
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <AsyncAutocomplete<Organization>
                  fullWidth
                  label="Organization"
                  fetchMethod={(params) =>
                    api.organization.getOrganizations(params)
                  }
                  mapOptionMethod={(item: Organization) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('organization', newValue);
                  }}
                  value={values.organization}
                  onBlur={handleBlur}
                  error={touched.organization && Boolean(errors.organization)}
                  helperText={
                    touched.organization && errors.organization
                      ? errors.organization
                      : ' '
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
