import { ILocation } from 'api/services/organization/types';
import { LinkCell } from 'components/table/components';
import { Cell } from 'components/table/types';

export const getCells = (): Cell<ILocation>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Organization Name',
    key: 'organization',
    render: (item: ILocation) => item.organization.name,
  },
  {
    name: 'Location Name',
    key: 'name',
  },
  {
    name: 'Shifts',
    key: 'shiftsCount',
    render: (item: ILocation) => (
      <LinkCell
        to={`/ohc/locations/${item.id}/shifts`}
        label={item.shiftsCount || '0'}
      />
    ),
  },
  {
    name: 'Participants',
    key: 'participantsCount',
    render: (item: ILocation) => (
      <LinkCell
        to={`/ohc/locations/${item.id}/participants`}
        label={item.participantsCount || '0'}
      />
    ),
  },
  {
    name: 'Visits',
    key: 'visitsCount',
    render: (item: ILocation) => (
      <LinkCell
        to={`/ohc/locations/${item.id}/visits`}
        label={item.visitsCount || '0'}
      />
    ),
  },
];
