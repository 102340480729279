import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { FormAlert } from 'components/alert';
import { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { Select, TextInput } from 'components/common/input';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { GENDERS } from 'common/constants/user';
import { HCPSupervisorBody } from 'api/services/auth/types';
import { birthDate, getPhoneSchema } from 'utils/validation/validators';
import { Checkbox } from 'components/common/input/Checkbox';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { validatePassword } from 'utils/validation/password';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';
import { Button } from '../../../../../components/common/button';
import { HCPSupervisorSignUpFormSkeleton } from './skeleton';
import { useAuthSelector } from '../../../../../store/selectors/auth';
import { hcpSupervisorInvitationSignUpRequest } from '../../../../../store/reducers/auth/actions';
import { PrivacyPolicyModal } from '../../../../../components/privacy-policy-modal';

interface IHcpSupervisorSignUpFormProps {
  token: string;
}

const HCPSupervisorValidationSchema = Yup.object().shape({
  given: Yup.string().required('Required'),
  family: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: getPhoneSchema().required('Required'),
  gender: Yup.string().oneOf(GENDERS, 'must be a valid gender'),
  password: Yup.string()
    .max(128)
    .required('Required')
    .min(12, 'must be at least 12 characters')
    .test(
      'isValidPassword',
      'must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character',
      validatePassword,
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('please, confirm your password'),
  acceptedTermsOfUse: Yup.boolean().required('Required'),
  birthDate,
});

const EMPTY_VALUES = {
  given: '',
  family: '',
  middleName: '',
  email: '',
  externalId: '',
  gender: 'None',
  birthDate: '',
  phone: '',
  password: '',
  confirmPassword: '',
  acceptedTermsOfUse: false,
};

interface IFormValues extends HCPSupervisorBody {
  password: string;
  confirmPassword: string;
  acceptedTermsOfUse: boolean;
}

export function HCPSupervisorSignUpForm(props: IHcpSupervisorSignUpFormProps) {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<IFormValues>(EMPTY_VALUES);
  const [error, setError] = useState<string | string[]>('');
  const [privacyPolicyDialogOpened, setPrivacyPolicyDialogOpened] =
    useState(false);
  const { token } = props;

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        const user = await api.auth.decodeInvitationSignUpToken(token);
        setInitialValues({
          ...EMPTY_VALUES,
          ..._.pick(user, [
            'given',
            'middleName',
            'family',
            'email',
            'externalId',
            'birthDate',
          ]),
          phone: convertPhoneFormFieldToTenDigitFormat(user.phone),
          gender: user.gender || 'None',
        });
      } catch (e) {
        setError(
          getErrorMessage(
            e,
            'Something went wrong with fetching the initial data',
          ),
        );
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, [token]);

  const genderDropdownOptions = useMemo(
    () => GENDERS.map((gender) => ({ label: gender, value: gender })) || [],
    [],
  );

  const dispatch = useDispatch();
  const authState = useAuthSelector();
  const theme = useTheme();

  const onSubmit = async (values: IFormValues) => {
    const val = _.omitBy(values, (value) => _.isNull(value));
    const requestBody = {
      ..._.omit(val, ['confirmPassword']),
      phone: convertPhoneFormFieldToInternationalFormat(values.phone),
      password: values.password,
      email: values.email,
      given: values.given,
      family: values.family,
      gender: values.gender === 'None' ? null : values.gender,
      acceptedTermsOfUse: values.acceptedTermsOfUse,
      token,
    };

    dispatch(hcpSupervisorInvitationSignUpRequest(requestBody));
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={HCPSupervisorValidationSchema}
    >
      {({
        touched,
        errors,
        getFieldProps,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
      }) =>
        loading ? (
          <HCPSupervisorSignUpFormSkeleton />
        ) : (
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !isSubmitting) {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={theme.spacing(1)}
            maxWidth="sm"
          >
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Personal info
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="First Name"
                  helperText={
                    touched.given && errors.given ? errors.given : ' '
                  }
                  {...getFieldProps('given')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Middle Name"
                  error={!!touched.middleName && !!errors.middleName}
                  helperText={
                    touched.middleName && errors.middleName
                      ? errors.middleName
                      : ' '
                  }
                  {...getFieldProps('middleName')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Last Name"
                  error={!!touched.family && !!errors.family}
                  helperText={
                    touched.family && errors.family ? errors.family : ' '
                  }
                  {...getFieldProps('family')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="External Id"
                  error={touched.externalId && Boolean(errors.externalId)}
                  helperText={
                    touched.externalId && errors.externalId
                      ? errors.externalId
                      : ' '
                  }
                  {...getFieldProps('externalId')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  fullWidth
                  options={genderDropdownOptions}
                  label="Gender"
                  {...getFieldProps('gender')}
                  errorMessage={
                    touched?.gender && errors?.gender
                      ? (errors?.gender as string)
                      : ''
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Date of Birth"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={touched.birthDate && Boolean(errors.birthDate)}
                  helperText={
                    touched.birthDate && errors.birthDate
                      ? errors.birthDate
                      : ' '
                  }
                  {...getFieldProps('birthDate')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Contact info
                </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <PhoneInput
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={
                    touched.phone && errors.phone ? errors.phone : ' '
                  }
                  {...getFieldProps('phone')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={theme.spacing(1)}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={12}>
                <Typography
                  variant="body1"
                  fontWeight="700"
                  pb={theme.spacing(1)}
                >
                  Account info
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Email"
                  error={!!touched.email && !!errors.email}
                  helperText={
                    touched.email && errors.email ? errors.email : ' '
                  }
                  {...getFieldProps('email')}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  label="Password"
                  type="password"
                  error={!!touched.password && !!errors.password}
                  helperText={
                    touched.password && errors.password ? errors.password : ' '
                  }
                  {...getFieldProps('password')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  fullWidth
                  type="password"
                  label="Confirm password"
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={
                    touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : ' '
                  }
                  {...getFieldProps('confirmPassword')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <>
                    <Checkbox
                      {...getFieldProps('acceptedTermsOfUse')}
                      checked={values.acceptedTermsOfUse}
                    />
                    <FormHelperText error>
                      {touched.acceptedTermsOfUse &&
                      Boolean(errors.acceptedTermsOfUse)
                        ? errors.acceptedTermsOfUse
                        : ' '}
                    </FormHelperText>
                  </>
                }
                slotProps={{
                  typography: {
                    fontSize: '13px',
                    fontWeight: 700,
                    lineHeight: '16px',
                  },
                }}
                label={
                  <span>
                    I accept the{' '}
                    <Link
                      underline="hover"
                      color="#6DBDBF"
                      onClick={(e) => {
                        e.preventDefault();
                        setPrivacyPolicyDialogOpened(true);
                      }}
                    >
                      Terms of Use and Privacy Policy
                    </Link>
                  </span>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {(error || authState.error) && (
                    <FormAlert severity="error">
                      {getErrorMessageComponent(authState.error || error)}
                    </FormAlert>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button
                size="large"
                disabled={isSubmitting || !values.acceptedTermsOfUse}
                onClick={() => handleSubmit()}
              >
                Sign Up
              </Button>
            </Grid>
            <PrivacyPolicyModal
              opened={privacyPolicyDialogOpened}
              onClose={() => setPrivacyPolicyDialogOpened(false)}
              onAccept={() => {
                setPrivacyPolicyDialogOpened(false);
                setFieldValue('acceptedTermsOfUse', true);
              }}
              policyTypes={['general', 'privacy-policy']}
            />
          </Grid>
        )
      }
    </Formik>
  );
}
