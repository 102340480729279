import { ButtonProps, FormHelperText, SxProps, styled } from '@mui/material';
import {
  InputHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useRef,
} from 'react';
import { Button } from './Button';

interface FileUploadButtonProps extends ButtonProps {
  onFilesChosen: (files: File) => void;
  children: ReactNode;
  buttonProps?: ButtonProps;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  errorMessage?: string;
  withErrorMessage?: boolean;
  errorMessageSx?: SxProps;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function FileUploadButton(props: FileUploadButtonProps) {
  const {
    children,
    onFilesChosen,
    buttonProps,
    inputProps,
    errorMessage,
    withErrorMessage = false,
    errorMessageSx = {},
  } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleChooseFile = useCallback(
    (event: SyntheticEvent) => {
      if (hiddenFileInput.current?.value) {
        // @ts-ignore
        onFilesChosen([...(event.currentTarget as HTMLInputElement).files][0]);
        hiddenFileInput.current.value = '';
      }
    },
    [onFilesChosen],
  );

  return (
    <>
      <Button component="label" {...buttonProps}>
        {children}
        <VisuallyHiddenInput
          ref={hiddenFileInput}
          type="file"
          onChange={handleChooseFile}
          {...inputProps}
        />
      </Button>
      {withErrorMessage && (
        <FormHelperText
          sx={{ margin: '3px 14px 0', ...errorMessageSx }}
          error={!!errorMessage}
        >
          {errorMessage || ' '}
        </FormHelperText>
      )}
    </>
  );
}
