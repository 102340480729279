import { Dispatch, SetStateAction } from 'react';
import { Checkbox } from '@mui/material';
import { IVisit } from 'api/services/organization/types';
import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';

export const cells: (
  setEditedVisit: Dispatch<SetStateAction<IVisit | null>>,
  setConfirmationState: Dispatch<
    SetStateAction<{
      open: boolean;
      type: 'delete' | 'share' | 'cancel_sharing' | null;
    }>
  >,
) => Cell<IVisit>[] = (setEditedVisit, setConfirmationState) => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Timezone',
    key: 'timezone',
    render: (item: IVisit) => {
      const zone = item.timezone;
      return `${zone} (${DateTime.local({ zone }).toFormat('ZZZZ, ZZ')})`;
    },
  },
  {
    name: 'Ignore (months)',
    key: 'ignoreTestedEmployeesMonths',
  },
  {
    name: 'Shared',
    key: 'shared',
    render: (item: IVisit) => (
      <Checkbox
        size="small"
        sx={{ padding: 0 }}
        checked={item.shared}
        onClick={(e) => e.stopPropagation()}
        onChange={() => {
          setEditedVisit(item);
          setConfirmationState({
            open: true,
            type: item.shared ? 'cancel_sharing' : 'share',
          });
        }}
      />
    ),
  },
];
