import { Box, BoxProps, CircularProgress } from '@mui/material';

interface ILoaderProps extends BoxProps {
  size?: number;
}

export function Loader(props: ILoaderProps) {
  const { size = 40, ...restProps } = props;
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      width="100%"
      {...restProps}
    >
      <CircularProgress size={size} sx={{ color: '#000000' }} />
    </Box>
  );
}
