import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { api } from 'api';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { FormModal } from 'components/modal';
import { useCallback, useMemo, useState } from 'react';
import { IOption } from 'common/types/common';
import { useTranslation } from 'react-i18next';
import { getUserFullNameString } from 'utils/getUserFullNameString';
import { ProfessionalSupervisorUser } from '../../../../../api/services/auth/types';

interface AssignProfessionalSupervisorFormProps {
  onSubmit: (professionalSupervisorUserId: number) => void;
  handleClose: () => void;
  locationId: number;
}

interface Values {
  professionalSupervisor: IOption | null;
}

export function AssignProfessionalSupervisorForm(
  props: AssignProfessionalSupervisorFormProps,
) {
  const { onSubmit, handleClose, locationId } = props;
  const { t, i18n } = useTranslation();

  const ProfessionalSupervisorFormSchema = useMemo(
    () =>
      Yup.object().shape({
        professionalSupervisor: Yup.object().required(t('validation.required')),
      }),
    [i18n.language],
  );

  const initialValues: Values = {
    professionalSupervisor: null,
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const handleSubmit = useCallback(async (values: Values) => {
    try {
      setLoading(true);
      await api.organization.assignProfessionalSupervisorToLocation(
        locationId,
        Number(values.professionalSupervisor?.value),
      );
      onSubmit(Number(values.professionalSupervisor?.value));
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ProfessionalSupervisorFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={loading}
          title={t('common.assignProfessionalSupervisorToLocation')}
        >
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
            >
              <Grid item xs={12}>
                <AsyncAutocomplete<ProfessionalSupervisorUser>
                  fullWidth
                  label={t('common.professionalSupervisor')}
                  fetchMethod={(params) =>
                    api.user.getProfessionalSupervisors(params)
                  }
                  mapOptionMethod={(item: ProfessionalSupervisorUser) => ({
                    value: item.id,
                    label: `${item.id} - ${getUserFullNameString(item)}`,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('professionalSupervisor', newValue);
                  }}
                  value={values.professionalSupervisor}
                  onBlur={handleBlur}
                  error={
                    touched.professionalSupervisor &&
                    Boolean(errors.professionalSupervisor)
                  }
                  helperText={
                    touched.professionalSupervisor &&
                    (errors.professionalSupervisor as string)
                      ? (errors.professionalSupervisor as string)
                      : ' '
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
