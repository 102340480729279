import { IFilterConfig } from 'components/table-filter/types';
import _ from 'lodash';
import { EHearingTestStatuses } from '../../../../api/services/hearing-test-results/types';

export const getFilterConfig = (): IFilterConfig[] => [
  {
    name: 'lastTestDate',
    label: 'Test Date',
    type: 'dateRange',
    dateFrom: {
      name: 'testDateFrom',
      label: 'Test Date From',
      value: null,
    },
    dateTo: {
      name: 'testDateTo',
      label: 'Test Date To',
      value: null,
    },
    width: 250,
  },
  {
    name: 'STSShift',
    label: 'STS Shift',
    type: 'select',
    options: [
      {
        label: 'Not Selected',
        value: '',
      },
      {
        label: 'Yes',
        value: 'YES',
      },
      {
        label: 'No',
        value: 'NO',
      },
    ],
    width: 250,
  },
  {
    name: 'recordableOSHAShift',
    label: 'OSHA Recordable Shift',
    type: 'select',
    options: [
      {
        label: 'Not Selected',
        value: '',
      },
      {
        label: 'Yes',
        value: 'YES',
      },
      {
        label: 'No',
        value: 'NO',
      },
    ],
    width: 250,
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        label: 'Not Selected',
        value: '',
      },
      ..._.values(EHearingTestStatuses).map((item) => ({
        label: item,
        value: item,
      })),
    ],
    width: 250,
  },
  {
    name: 'showInactive',
    label: 'Show Inactive',
    type: 'checkbox',
    checkboxValues: {
      true: 'YES',
      false: 'NO',
    },
  },
];
