import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { api } from 'api';
import { User } from 'api/services/auth/types';
import { AsyncAutocomplete } from 'components/common/async-autocomplete';
import { getErrorMessage, getErrorMessageComponent } from 'utils';
import { FormModal } from 'components/modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IOption } from 'common/types/common';
import { useTranslation } from 'react-i18next';
import { getUserFullNameString } from 'utils/getUserFullNameString';
import { PaginatedData } from '../../../../../common/types';
import { Button } from '../../../../../components/common/button';

interface AssignSiteManagerFormProps {
  onSubmit: (siteManagerUserId: number) => void;
  handleClose: () => void;
  locationId: number;
  organizationId: number;
  addNewSiteManager: () => void;
}

interface Values {
  siteManager: IOption | null;
}

export function AssignSiteManagerForm(props: AssignSiteManagerFormProps) {
  const {
    onSubmit,
    handleClose,
    locationId,
    organizationId,
    addNewSiteManager,
  } = props;
  const { t, i18n } = useTranslation();
  const [siteManagers, setSiteManagers] = useState<PaginatedData<User>>({
    items: [],
    offset: 0,
    limit: 1000,
    total: 0,
  });

  useEffect(() => {
    api.organization.getSiteManagers(organizationId).then((siteManagers) => {
      siteManagers.items = siteManagers.items.filter(
        (item) =>
          !item.siteManager?.locations?.some(
            (location) => location.id === locationId,
          ),
      );

      setSiteManagers(siteManagers);
    });
  }, []);

  const ProfessionalSupervisorFormSchema = useMemo(
    () =>
      Yup.object().shape({
        siteManager: Yup.object().required(t('validation.required')),
      }),
    [i18n.language],
  );

  const initialValues: Values = {
    siteManager: null,
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const handleSubmit = useCallback(
    async (values: Values) => {
      const siteManager = siteManagers.items.find(
        (siteManager) => siteManager.id === values.siteManager?.value,
      );
      if (!siteManager) {
        return;
      }
      const locations =
        siteManager.siteManager?.locations?.map((location) => location.id) ||
        [];
      locations.push(locationId);
      try {
        setLoading(true);
        await api.user.updateSiteManager(siteManager.id, {
          given: siteManager.given,
          family: siteManager.family,
          email: siteManager.email,
          middleName: siteManager.middleName,
          phone: siteManager.phone,
          externalId: siteManager.externalId,
          organizationId,
          locations,
        });
        onSubmit(Number(values.siteManager?.value));
      } catch (e) {
        setError(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    },
    [siteManagers],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ProfessionalSupervisorFormSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={loading}
          title={t('common.addAnExistingSiteManager')}
        >
          <div style={{ margin: '0 53px 20px', textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                addNewSiteManager();
              }}
            >
              Add a new site manager
            </Button>
          </div>
          <FullHeightContainer maxWidth="xs" disableGutters>
            <Grid
              component="form"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              onSubmit={handleSubmit}
              container
            >
              <Grid item xs={12}>
                <AsyncAutocomplete<User>
                  fullWidth
                  label={t('common.siteManager')}
                  fetchMethod={async () => siteManagers}
                  mapOptionMethod={(item: User) => ({
                    value: item.id,
                    label: `${item.id} - ${getUserFullNameString(item)}`,
                  })}
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('siteManager', newValue);
                  }}
                  value={values.siteManager}
                  onBlur={handleBlur}
                  error={touched.siteManager && Boolean(errors.siteManager)}
                  helperText={
                    touched.siteManager && (errors.siteManager as string)
                      ? (errors.siteManager as string)
                      : ' '
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
