import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Button } from 'components/common/button';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useAuthSelector } from 'store/selectors/auth';
import { useCleanupAuthError } from 'hooks/useCleanupAuthError';
import { TextInput } from 'components/common/input';
import { api } from 'api';
import { FormState } from '../types';

const initialValues = {
  email: '',
};

const EmailFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

interface EmailFormProps {
  setFormState: (state: FormState) => void;
  setEmail: (email: string) => void;
}

export function EmailForm({ setFormState, setEmail }: EmailFormProps) {
  const authState = useAuthSelector();
  const navigate = useNavigate();

  useCleanupAuthError();

  useEffect(() => {
    if (authState.user) navigate('/');
  }, [authState.user]);

  const onSubmit = (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>,
  ) => {
    formikHelpers.setSubmitting(true);
    api.auth
      .generateResetPasswordVerificationCode(values.email)
      // eslint-disable-next-line
      .catch((err) => console.log(err))
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setEmail(values.email);
        setFormState(FormState.VerificationForm);
      });
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={EmailFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Grid
          component="form"
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit();
            }
          }}
          onSubmit={handleSubmit}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <TextInput
              fullWidth
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="text"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              // A ' ' is a hack to always have space for error message, can crete a better approach later
              helperText={touched.email && errors.email ? errors.email : ' '}
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
