import i18next from 'i18n/config';
import { User } from '../api/services/auth/types';
import { api } from '../api';
import { showSnackbar } from './showSnackbar';

export const handleInvitationCopy = async (
  user: User,
  path?: string,
  onSuccess?: () => void | Promise<void>,
  onError?: (e: any) => void | Promise<void>,
) => {
  try {
    const siteUrl = new URL(window.location.href);
    const { invitationToken } = await api.user.getInvitationToken(user.id);
    await window.navigator.clipboard.writeText(
      `${siteUrl.origin}/${path || 'sign-up'}?token=${invitationToken}`,
    );
    showSnackbar(i18next.t('handleInvitationCopy.success'), {
      variant: 'success',
    });
    onSuccess?.();
  } catch (e) {
    console.log(e);
    showSnackbar(i18next.t('handleInvitationCopy.error'), {
      variant: 'error',
    });
    onError?.(e);
  }
};
