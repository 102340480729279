import { ILocation } from '../../../../api/services/organization/types';

export interface SiteManagerDashboardState {
  locations: ILocation[] | [];
  error: string | string[] | null;
  loading: boolean;
  selectedLocation: ILocation | null;
}

export const SET_SITE_MANAGER_LOCATIONS = 'SET_SITE_MANAGER_LOCATIONS';
export const REQUEST_SITE_MANAGER_LOCATIONS = 'REQUEST_SITE_MANAGER_LOCATIONS';
export const SET_SITE_MANAGER_SELECTED_LOCATION =
  'SET_SITE_MANAGER_SELECTED_LOCATION';
export const SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR =
  'SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR';
export const SET_SITE_MANAGER_LOCATIONS_LOADING =
  'SET_SITE_MANAGER_LOCATIONS_LOADING';

export const SET_SITE_MANAGER_DASHBOARD_STATE =
  'SET_SITE_MANAGER_DASHBOARD_STATE';
export const CLEAR_SITE_MANAGER_DASHBOARD_STATE =
  'CLEAR_SITE_MANAGER_DASHBOARD_STATE';

export interface SetSiteManagerLocationsAction {
  type: typeof SET_SITE_MANAGER_LOCATIONS;
  payload: ILocation[] | [];
}

export interface SetSiteManagerDashboardState {
  type: typeof SET_SITE_MANAGER_DASHBOARD_STATE;
  payload: SiteManagerDashboardState;
}

export interface SetSiteManagerLocationsRequestErrorAction {
  type: typeof SET_SITE_MANAGER_LOCATIONS_REQUEST_ERROR;
  payload: string | string[] | null;
}

export interface SetSiteManagerLocationsLoadingAction {
  type: typeof SET_SITE_MANAGER_LOCATIONS_LOADING;
  payload: boolean;
}

export interface SetSiteManagerSelectedLocationAction {
  type: typeof SET_SITE_MANAGER_SELECTED_LOCATION;
  payload: ILocation | null;
}
export interface RequestSiteManagerLocationsAction {
  type: typeof REQUEST_SITE_MANAGER_LOCATIONS;
  payload: number;
}
export interface ClearSiteManagerDashboardStateAction {
  type: typeof CLEAR_SITE_MANAGER_DASHBOARD_STATE;
}

export type SiteManagerDashboardActions =
  | SetSiteManagerDashboardState
  | SetSiteManagerLocationsAction
  | SetSiteManagerLocationsRequestErrorAction
  | SetSiteManagerLocationsLoadingAction
  | SetSiteManagerSelectedLocationAction
  | RequestSiteManagerLocationsAction
  | ClearSiteManagerDashboardStateAction;
