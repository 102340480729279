import {
  MenuItem,
  SelectProps,
  Select as SelectComponent,
  FormHelperText,
  InputLabel,
  FormControl,
  InputLabelProps,
  styled,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface ISelectProps extends SelectProps {
  options: { label: ReactNode; value: string | number; disabled?: boolean }[];
  errorMessage?: string;
  withErrorMessage?: boolean;
  inputLabelProps?: InputLabelProps;
  formControlSx?: SxProps;
}

const StyledSelect = styled(SelectComponent)({
  '&.MuiInput-underline:before': {
    borderBottom: '1px solid rgba(202, 194, 190, 0.30)',
  },
});

export function Select(props: ISelectProps) {
  const {
    options,
    errorMessage,
    label,
    withErrorMessage = true,
    inputLabelProps,
    variant,
    formControlSx = {},
    ...restProps
  } = props;
  return (
    <FormControl fullWidth variant={variant} sx={formControlSx}>
      <InputLabel {...inputLabelProps} htmlFor="select" id="select-label">
        {label}
      </InputLabel>
      <StyledSelect
        {...restProps}
        label={label}
        id="select"
        error={!!errorMessage}
      >
        {options.map((item) => (
          <MenuItem
            value={item.value}
            key={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {withErrorMessage ? (
        <FormHelperText error={!!errorMessage}>
          {errorMessage || ' '}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
