import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { TIME_DROPDOWN_OPTIONS } from './constants';
import { dateToTimeComponents, timeComponentsToDate } from './utils';

interface ITimeSelectProps {
  disabled?: boolean;
  value?: Date;
  onChange: (date: DateTime) => void;
  error?: string;
  readOnly?: boolean;
  minutesIncrement?: string;
}

export function TimeSelect(props: ITimeSelectProps) {
  const { value, onChange, minutesIncrement, disabled, error, readOnly } =
    props;
  const [selectedTime, setSelectedTime] = useState(
    value ? dateToTimeComponents(value)?.time : '',
  );
  const [selectedAmPm, setSelectedAmPm] = useState<'AM' | 'PM'>(
    (value ? dateToTimeComponents(value)?.ampm : 'AM') || 'AM',
  );

  useEffect(() => {
    setSelectedAmPm((value ? dateToTimeComponents(value)?.ampm : 'AM') || 'AM');
    setSelectedTime(value ? dateToTimeComponents(value)?.time : '');
  }, [value]);

  const dropdownOptions = useMemo(() => {
    if (!minutesIncrement) return TIME_DROPDOWN_OPTIONS.time;
    return TIME_DROPDOWN_OPTIONS.time.filter((item) =>
      item.includes(`:${minutesIncrement}`),
    );
  }, [minutesIncrement]);

  return (
    <div>
      <Grid container>
        <FormControl variant="standard" size="small">
          <Select
            disabled={disabled}
            value={selectedTime}
            placeholder="Select time"
            onChange={(e) => {
              const { value } = e.target;
              setSelectedTime(value as string);
              onChange(
                timeComponentsToDate({
                  time: value as string,
                  ampm: selectedAmPm,
                }),
              );
            }}
            sx={{ width: 80, fontSize: 15, marginRight: '10px' }}
            error={!!error}
            readOnly={readOnly}
          >
            {dropdownOptions?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" size="small">
          <Select
            disabled={disabled || !selectedTime}
            value={selectedAmPm}
            placeholder="AM/PM"
            onChange={(e) => {
              const { value } = e.target;
              setSelectedAmPm(value as 'AM' | 'PM');
              onChange(
                timeComponentsToDate({
                  time: selectedTime || '',
                  ampm: value as 'AM' | 'PM',
                }),
              );
            }}
            sx={{ width: 60, fontSize: 15 }}
            error={!!error}
            readOnly={readOnly}
          >
            {TIME_DROPDOWN_OPTIONS.ampm.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid sx={{ minHeight: '16px' }}>
        {error && (
          <Typography color="error" fontSize={10}>
            {error}
          </Typography>
        )}
      </Grid>
    </div>
  );
}
