import { Participant } from 'api/services/auth/types';
import { IParticipantSignedReport } from 'api/services/participant/types';
import { DateFormats } from 'common/constants/date';

import { DateTime } from 'luxon';

export const getReportFileNameBySignedReport = (
  participant: Participant,
  report: IParticipantSignedReport,
) =>
  `${participant?.given}_${participant?.family}_${DateTime.fromISO(
    report.created_at,
  ).toFormat(DateFormats.FILE_DATE_FORMAT)}`;
