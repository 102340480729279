import { useEffect, useState } from 'react';
import { api } from 'api';
import { User } from 'api/services/auth/types';

export const useAccountManager = (accountManagerId: number | null) => {
  const [isAccountManagerLoading, setIsAccountManagerLoading] = useState(false);
  const [accountManager, setAccountManager] = useState<User | null>(null);

  const getAccountManager = async () => {
    if (accountManagerId)
      try {
        setIsAccountManagerLoading(true);
        const accountManager = await api.user.getAccountManagerById(
          accountManagerId,
        );
        setAccountManager(accountManager);
      } catch (e) {
        console.log(e);
      }

    setIsAccountManagerLoading(false);
  };

  useEffect(() => {
    getAccountManager();
  }, [accountManagerId]);

  return { accountManager, isAccountManagerLoading };
};
