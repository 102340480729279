import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ILinkCellProps {
  to: string;
  label: string | number | ReactNode;
}

export function LinkCell(props: ILinkCellProps) {
  const { to, label } = props;

  return (
    <Link onClick={(e) => e.stopPropagation()} to={to}>
      {label}
    </Link>
  );
}
