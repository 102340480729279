import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import {
  EReportStatuses,
  IDailyBiologicalReport,
} from '../../../../api/services/organization/types';
import { Chip } from '../../../../components/common/chip';

export const cells: Cell<IDailyBiologicalReport>[] = [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'Date Time',
    key: 'dateTime',
    render: (report: IDailyBiologicalReport) =>
      report.dateTime
        ? DateTime.fromISO(report.dateTime).toFormat('MM/dd/yyyy h:mm a')
        : 'N/A',
  },
  {
    name: 'Status',
    key: 'status',
    render: (report: IDailyBiologicalReport) => {
      const COLORS = {
        [EReportStatuses.Signed]: {
          labelColor: '#276942',
          dotColor: '#276942',
          backgroundColor: '#8FE0B066',
        },
        [EReportStatuses.Error]: {
          labelColor: '#C45558',
          dotColor: '#C45558',
          backgroundColor: '#FDD7D7',
        },
        [EReportStatuses.Processing]: {
          labelColor: '#8F6715',
          dotColor: '#8F6715',
          backgroundColor: '#FFC88C80',
        },
        [EReportStatuses.Draft]: {
          labelColor: '#06368d',
          dotColor: '#06368d',
          backgroundColor: 'rgba(103,146,255,0.5)',
        },
      };
      return (
        <Chip
          label={report.status || 'N/A'}
          backgroundColor={COLORS[report.status]?.backgroundColor}
          labelColor={COLORS[report.status]?.labelColor}
          dotColor={COLORS[report.status]?.backgroundColor}
        />
      );
    },
  },
  {
    name: 'Signatory',
    key: 'signatory',
    render: (list: IDailyBiologicalReport) =>
      list.signedDocument?.signatory
        ? `${list.signedDocument?.signatory?.given || ''} ${
            list.signedDocument?.signatory?.family || ''
          }`
        : 'N/A',
  },
  {
    name: 'Signed At',
    key: 'signingDateTime',
    render: (list: IDailyBiologicalReport) =>
      list.signedDocument?.signingDateTime
        ? `${DateTime.fromISO(list.signedDocument?.signingDateTime).toFormat(
            'MM/dd/yyyy',
          )}`
        : '-',
  },
];
