import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Checkbox } from 'components/common/input/Checkbox';
import { SubHeader } from '../SubHeader';
import { Signature } from './components/Signature';
import { StyledTableCell } from '../biological-report-form-table/components/StyledTableCell';

interface IBiologicalReportFormTableProps {
  formik: FormikProps<any>;
  readOnly?: boolean;
}

const HEADER_CELLS: Array<string> = [
  '',
  'Jacks Seated',
  'Cords O.K.',
  'Headband Tension',
  'Earphone Cushions',
  'Response Button',
  'Pure Tones',
  'No Static',
];

const CHECKBOX_FIELD_NAMES: Array<string> = [
  'jacksSeated',
  'cordsOK',
  'headbandTension',
  'earphoneCushions',
  'responseButton',
  'pureTones',
  'noStatic',
];

export function ListeningCheckSection(props: IBiologicalReportFormTableProps) {
  const { formik, readOnly } = props;

  return (
    <>
      <Grid
        sx={{
          borderBottom: ' 1px solid rgba(202, 194, 190, 0.80)',
          padding: '16px 0 24px',
        }}
      >
        <SubHeader>Calibration check</SubHeader>
        <Grid
          container
          direction="column"
          sx={{ width: '340px', padding: '16px 8px' }}
        >
          <Typography
            variant="mono"
            align="center"
            sx={{
              fontSize: '9px',
              letterSpacing: '-0.5px',
              textTransform: 'uppercase',
            }}
          >
            Initial to Pass/Fail
          </Typography>
          <Divider
            sx={{
              margin: '8px 0 16px',
            }}
          />
          <Grid container gap={1} justifyContent="space-between" wrap="nowrap">
            <Grid item xs={6}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{
                  height: '100%',
                }}
              >
                <Grid item>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      letterSpacing: '0.15px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Pass
                  </Typography>

                  <Typography
                    variant="mono"
                    component="p"
                    align="center"
                    sx={{
                      fontSize: '9px',
                      letterSpacing: '-0.5px',
                      margin: '8px 0 16px',
                      textTransform: 'uppercase',
                    }}
                  >
                    +/- 5 dB of Baseline
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    position: 'relative',
                    height: '45px',
                  }}
                >
                  <Grid sx={{ position: 'absolute', bottom: 0, left: 0 }}>
                    <Signature
                      buttonTitle="Initial"
                      buttonProps={{
                        sx: {
                          width: '158px',
                          height: '45px',
                          backgroundColor: 'transparent',
                          border: '1px solid rgba(202, 194, 190, 0.3)',
                        },
                      }}
                      onSubmit={(signature: string) => {
                        formik.setFieldValue('OHCInitial', signature);
                        formik.setFieldValue('calibrationCheck', true);
                      }}
                      signature={
                        formik.values.calibrationCheck
                          ? formik.values.OHCInitial
                          : ''
                      }
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{
                  height: '100%',
                }}
                xs={12}
              >
                <Grid item>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      letterSpacing: '0.15px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Fail
                  </Typography>
                  <Typography
                    variant="mono"
                    align="center"
                    component="p"
                    sx={{
                      fontSize: '9px',
                      letterSpacing: '-0.5px',
                      margin: '8px 0 16px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Greater than +/- 5 dB of Baseline
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    position: 'relative',
                    height: '45px',
                  }}
                >
                  <Grid sx={{ position: 'absolute', bottom: 0, left: 0 }}>
                    <Signature
                      buttonTitle="Initial"
                      buttonProps={{
                        sx: {
                          width: '158px',
                          height: '45px',
                          backgroundColor: 'transparent',
                          border: '1px solid rgba(202, 194, 190, 0.3)',
                        },
                      }}
                      onSubmit={(signature: string) => {
                        formik.setFieldValue('OHCInitial', signature);
                        formik.setFieldValue('calibrationCheck', false);
                      }}
                      signature={
                        !formik.values.calibrationCheck
                          ? formik.values.OHCInitial
                          : ''
                      }
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          borderBottom: ' 1px solid rgba(202, 194, 190, 0.80)',
          padding: '16px 0 24px',
        }}
      >
        <SubHeader>Listening check</SubHeader>
        <TableContainer component={Box}>
          <Table
            sx={{
              minWidth: 500,
              width: '100%',
            }}
            aria-label="table"
          >
            <TableHead>
              <TableRow sx={{}}>
                {HEADER_CELLS.map((c) => (
                  <TableCell
                    key={c}
                    align="center"
                    sx={{
                      width: '10%',
                      border: '1px solid #EAEAEA',
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '24px',
                      '&:first-of-type': {
                        width: '20%',
                      },
                    }}
                  >
                    {c}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <StyledTableCell>{`${formik.values.serialNumber}/${formik.values.uniqueId}`}</StyledTableCell>
                {CHECKBOX_FIELD_NAMES.map((fieldName) => (
                  <StyledTableCell key={fieldName}>
                    <Checkbox
                      name={fieldName}
                      onChange={() => {
                        formik.setFieldValue(
                          fieldName,
                          !formik.values[fieldName],
                        );
                      }}
                      checked={formik.values[fieldName]}
                      disabled={readOnly}
                    />
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
