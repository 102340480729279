import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Heading } from './Heading';

function Text({
  children,
  fontWeight,
}: {
  children: ReactNode;
  fontWeight?: number;
}) {
  return (
    <Heading variant="h5" sx={{ width: '100%', fontWeight: fontWeight ?? 700 }}>
      {children}
    </Heading>
  );
}

export type HeadingOfEntityProps = {
  entityTitle: string;
  entityName: string | undefined;
};

export function HeadingOfEntityPage(props: HeadingOfEntityProps) {
  const { entityTitle, entityName } = props;
  const { t } = useTranslation();

  return (
    <Grid container gap={1}>
      <Grid item>
        <Text>{entityTitle}</Text>
      </Grid>
      <Grid item alignSelf="center">
        <Text fontWeight={300}>{t('common.of')}</Text>
      </Grid>
      <Grid item>
        <Text>{entityName}</Text>
      </Grid>
    </Grid>
  );
}
