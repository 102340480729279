import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Chip, FormControl, InputLabel } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IDateRangeField } from '../types';
import { Select } from '../../common/input';
import { DateRangePickerModal } from '../../modal/DateRangePickerModal';

interface IFilterDateRangeInputProps {
  label: string;
  dateFromField: IDateRangeField;
  dateToField: IDateRangeField;
  onChange: (dateFrom: DateTime | null, dateTo: DateTime | null) => void;
}

export function FilterDateRangeInput(props: IFilterDateRangeInputProps) {
  const { dateFromField, dateToField, onChange, label } = props;
  const [dateFrom, setDateFrom] = useState<DateTime | null>(
    dateFromField.value,
  );
  const [dateTo, setDateTo] = useState<DateTime | null>(dateToField.value);
  const [showDateRange, setShowDateRange] = useState(false);

  useEffect(() => {
    setDateFrom(dateFromField.value);
  }, [dateFromField]);

  useEffect(() => {
    setDateTo(dateToField.value);
  }, [dateToField]);

  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        focused={false}
        onClick={() => {
          setShowDateRange(true);
        }}
        sx={{
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
        }}
      >
        <InputLabel
          id="filter-datepicker-label"
          shrink={!!(dateFrom && dateTo)}
          sx={{
            transform:
              dateFrom && dateTo
                ? 'translate(17px, -9px) scale(0.6)'
                : 'translate(14px, 10px) scale(0.8)',
          }}
        >
          {label}
        </InputLabel>
        <Select
          sx={{
            '& .MuiSelect-select': {
              py: '7px',
              height: '26px',
            },
          }}
          notched
          labelId="filter-datepicker-label"
          inputLabelProps={{
            shrink: !!(dateFrom && dateTo),
            sx: {
              transform:
                dateFrom && dateTo
                  ? 'translate(17px, -9px) scale(0.6)'
                  : 'translate(14px, 10px) scale(0.8)',
            },
          }}
          label={dateFrom && dateTo ? label : ''}
          fullWidth
          readOnly
          options={[]}
          IconComponent={DateRangeIcon}
          value={
            dateFrom && dateTo
              ? `${dateFrom.toFormat('MM/dd/yyyy')} - ${dateTo.toFormat(
                  'MM/dd/yyyy',
                )}`
              : ''
          }
          renderValue={(value) => (
            <Chip
              sx={{
                '& .MuiChip-label': {
                  fontSize: '10px',
                },
                '& .MuiChip-deleteIcon': {
                  marginRight: '3px',
                  fontSize: '20px',
                },
                height: 'auto',
              }}
              label={value as string}
              onDelete={() => {
                onChange(null, null);
                setDateFrom(null);
                setDateTo(null);
              }}
            />
          )}
        />
      </FormControl>

      <DateRangePickerModal
        opened={showDateRange}
        onClose={() => {
          setShowDateRange(false);
        }}
        onSelect={({ dateFrom, dateTo }) => {
          onChange(dateFrom, dateTo);
        }}
        dateRange={{ dateFrom, dateTo }}
      />
    </>
  );
}
