import { isOSHAShiftPresent } from 'utils';
import { YesNoCell } from './YesNoCell';

interface IOSHAShiftCellProps {
  leftAgeAdjustedSTS: number | null;
  rightAgeAdjustedSTS: number | null;
  leftSTSFrequenciesAverage: number | null;
  rightSTSFrequenciesAverage: number | null;
}

export function OSHAShiftCell(props: IOSHAShiftCellProps) {
  const {
    leftAgeAdjustedSTS,
    rightAgeAdjustedSTS,
    leftSTSFrequenciesAverage,
    rightSTSFrequenciesAverage,
  } = props;

  return (
    <YesNoCell
      value={
        isOSHAShiftPresent(
          leftAgeAdjustedSTS,
          rightAgeAdjustedSTS,
          leftSTSFrequenciesAverage,
          rightSTSFrequenciesAverage,
        )
          ? 'Yes'
          : 'No'
      }
    />
  );
}
