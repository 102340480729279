import { Grid, Typography, useTheme } from '@mui/material';
import { Button } from 'components/common/button';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { TextInput } from 'components/common/input';
import { validatePassword } from 'utils/validation/password';
import { api } from 'api';
import { getErrorMessage, showSnackbar } from 'utils';
import { useNavigate } from 'react-router-dom';

interface VerificationFormProps {
  email: string;
}

const initialValues = {
  verificationCode: '',
  password: '',
  confirmPassword: '',
};

const EmailFormSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Required'),
  password: Yup.string()
    .max(128)
    .required('Required')
    .min(12, 'must be at least 12 characters')
    .test(
      'isValidPassword',
      'must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character',
      validatePassword,
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('Required'),
});

export function VerificationForm({ email }: VerificationFormProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit = (
    values: typeof initialValues,
    helpers: FormikHelpers<typeof initialValues>,
  ) => {
    helpers.setSubmitting(true);

    api.auth
      .resetPassword(email, values.verificationCode, values.password)
      .then(() => {
        showSnackbar('Password changed', {
          variant: 'success',
        });
        navigate('/login');
      })
      // eslint-disable-next-line
      .catch((err) => {
        showSnackbar(
          getErrorMessage(
            err,
            'Could not reset password, please try again later',
          ),
          { variant: 'error' },
        );
      })
      .finally(() => helpers.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={EmailFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Grid
          component="form"
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit();
            }
          }}
          onSubmit={handleSubmit}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                marginBottom: theme.spacing(4),
                fontSize: theme.spacing(1.4),
              }}
            >
              We sent a verification code to
              <br />
              {email}.
              <br />
              Please enter code below
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              id="outlined-basic"
              label="Verification Code"
              variant="outlined"
              type="text"
              name="verificationCode"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.verificationCode}
              error={
                touched.verificationCode && Boolean(errors.verificationCode)
              }
              // A ' ' is a hack to always have space for error message, can crete a better approach later
              helperText={
                touched.verificationCode && errors.verificationCode
                  ? errors.verificationCode
                  : ' '
              }
              margin="none"
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              fullWidth
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.password}
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password && errors.password ? errors.password : ' '
              }
              margin="none"
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              fullWidth
              id="outlined-basic"
              label="Confirm Password"
              variant="outlined"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.confirmPassword}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ' '
              }
              margin="none"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
