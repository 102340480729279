import { Typography, TypographyProps, styled } from '@mui/material';

const StyledTypography = styled(Typography)({
  fontWeight: '700',
});

export function Heading(props: TypographyProps) {
  const { children, ...otherProps } = props;
  return <StyledTypography {...otherProps}>{children}</StyledTypography>;
}
