import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatValueToFilterString } from 'components/table-filter/utils';
import { ILocation } from '../../api/services/organization/types';

interface IUseLocationIdSearchParam {
  selectedLocation: ILocation | null;
  locations: ILocation[];
  setInitialLocationIdIfEmpty?: boolean;
  onChange: (location: ILocation | null) => void;
}

export const useLocationIdSearchParam = (params: IUseLocationIdSearchParam) => {
  const { selectedLocation, locations, setInitialLocationIdIfEmpty, onChange } =
    params;

  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (locationId: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(
      'locationId',
      formatValueToFilterString(locationId),
    );
    updatedSearchParams.delete('page');
    setSearchParams(updatedSearchParams.toString(), {
      replace: true,
    });
  };

  useEffect(() => {
    if (locations.length > 0) {
      if (searchParams.get('locationId')) {
        onChange(
          locations.find(
            (location) =>
              String(location.id) === searchParams.get('locationId'),
          ) || null,
        );
      } else if (setInitialLocationIdIfEmpty)
        updateSearchParams(locations[0].id.toString());
    }
  }, [searchParams, locations]);

  useEffect(() => {
    if (selectedLocation) {
      updateSearchParams(String(selectedLocation.id));
    }
  }, [selectedLocation]);
};
