import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EReportTypeKeys,
  ILocation,
} from '../../api/services/organization/types';
import { useAuthSelector } from '../../store/selectors/auth';
import { getErrorMessage } from '../../utils';
import { api } from '../../api';
import { useLocationIdSearchParam } from './useLocationIdSearchParam';
import { useUrlParam } from './useUrlParams';

export type UseReportPageDashboardStateOpts<ReportT> = {
  setCurrentReportType?: (reportType: ReportT) => void;
};

export const useProfessionalSupervisorDashboardState = <
  ReportT extends EReportTypeKeys,
>(
  props?: UseReportPageDashboardStateOpts<ReportT>,
) => {
  const { setCurrentReportType } = props || {};

  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAuthSelector();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [error, setError] = useState<string | string[]>('');
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(
    null,
  );
  const useEffectTriggered = useRef(false);

  useLocationIdSearchParam({
    selectedLocation,
    locations,
    onChange: (location) => setSelectedLocation(location),
  });

  // Handle reportType url state
  useUrlParam({
    paramName: 'reportType',
    setNewValue: setCurrentReportType,
    validationList: Object.values(EReportTypeKeys),
  });

  const setCurrentReportTypeSearchParam = (
    newReportType: EReportTypeKeys | null,
  ) => {
    if (newReportType === null) {
      searchParams.delete('reportType');
      setSearchParams(searchParams);
    } else {
      searchParams.set('reportType', newReportType);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const asyncRequest = async () => {
      if (!user) return;
      try {
        setLoading(true);
        const locations = await api.user.getProfessionalSupervisorLocations(
          user.id,
          { limit: 100, offset: 0 },
        );
        setLocations(locations.items);
        useEffectTriggered.current = true;

        if (!searchParams.get('locationId')) {
          setSelectedLocation(locations.items[0] || null);
        }
      } catch (e) {
        setError(getErrorMessage(e, 'Failed to load the locations list'));
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, [user]);

  return {
    error,
    setError,
    loading,
    setLoading,
    selectedLocation,
    setSelectedLocation,
    locations,
    setLocations,
    setCurrentReportTypeSearchParam,
  };
};
