import { Cell } from 'components/table/types';
import { DateCell } from 'components/table/components';
import { SimpleButton } from 'components/common/button';
import { IParticipantSignedReport } from 'api/services/participant/types';
import { Participant } from 'api/services/auth/types';
import { getReportFileNameBySignedReport } from './utils';

export const getCells = ({
  participant,
  onPreview,
  onDownload,
}: {
  participant: Participant;
  onPreview: (signedDocument: IParticipantSignedReport) => void;
  onDownload: (signedDocument: IParticipantSignedReport) => void;
}): Cell<IParticipantSignedReport>[] => [
  {
    name: 'File name',
    render: (item: IParticipantSignedReport) =>
      getReportFileNameBySignedReport(participant, item),
    width: '375px',
  },
  {
    name: 'Date',
    render: (item: IParticipantSignedReport) => (
      <DateCell date={item.created_at} />
    ),
  },
  {
    name: '',
    render: (item: IParticipantSignedReport) => (
      <>
        <SimpleButton
          actionType="preview"
          onClick={() => onPreview(item)}
          tooltipText="Preview"
        />
        <SimpleButton
          actionType="download"
          onClick={() => onDownload(item)}
          tooltipText="Download"
        />
      </>
    ),
    align: 'right',
  },
];
