import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Record } from 'api/services/uploads/types';
import { PageHeader } from 'components/admin-page-layout';
import { useOrganization } from 'hooks/useOrganization';
import { useLocation as useOrganizationLocation } from 'hooks/useLocation';
import { cells } from './cells';
import { UploadType } from '../uploads';

export function Records() {
  const location = useLocation();
  const { organizationId, uploadId } = useParams();
  const [uploadFileName, setUploadFileName] = useState('');
  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );
  const { location: organizationLocation, isLocationLoading } =
    useOrganizationLocation();

  const uploadType: UploadType = useMemo(() => {
    if (location.pathname.includes('roster-uploads')) {
      return UploadType.Roster;
    }
    return UploadType.TestResult;
  }, [location.pathname]);

  const getUploadFileName = async () => {
    if (!uploadId) {
      return;
    }
    try {
      const { file } = await api.uploads.getRosterUploadById(uploadId);
      setUploadFileName(file.name);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUploadFileName();
  }, [uploadId]);

  const fetchRecords = useCallback(
    async (limit: number, offset: number) => {
      if (!uploadId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData =
        uploadType === UploadType.Roster
          ? await api.uploads.getRosterRecords(uploadId, {
              limit,
              offset,
            })
          : await api.uploads.getTestResultRecords(uploadId, {
              limit,
              offset,
            });
      return locationsData;
    },
    [uploadId],
  );

  const tableState = useTableState<Record>({
    fetchDataFunction: fetchRecords,
  });

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={`${
          uploadType === UploadType.Roster
            ? 'Rosters '
            : 'Hearing Test Results '
        }
                Records`}
        entityName={`${organization?.name ? `${organization.name}` : ''}
        ${organizationLocation?.name ? ` ${organizationLocation.name}` : ''}`}
        titleLoading={isOrganizationLoading || isLocationLoading}
      />

      <Box mb={2}>
        <Typography variant="h6" fontWeight={400}>
          File:{uploadFileName && ` ${uploadFileName}`}
        </Typography>
      </Box>
      <BasicTable<Record> cells={cells} tableState={tableState} />
    </BasicContainer>
  );
}
