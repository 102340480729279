import { Shift } from 'api/services/organization/types';
import { ShiftNameCell } from 'components/table/components/ShiftNameCell';
import { ShiftTimingsCell } from 'components/table/components/ShiftTimingsCell';
import { Cell } from 'components/table/types';

export const cells: Cell<Shift>[] = [
  {
    name: '',
    key: 'name',
    render: (item: Shift) => (
      <ShiftNameCell name={item.name} shiftId={item.id} />
    ),
  },
  {
    name: '',
    render: (item: Shift) => (
      <ShiftTimingsCell hours={item.hours} isCompactView />
    ),
  },
];
