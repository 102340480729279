const STSLimit = 10;

export const isSTSGreaterThanLimit = (STS?: number | null): boolean => {
  const value = STS ? Number(STS) : 0;
  return value >= STSLimit;
};

export const isSTSShiftPresent = (
  leftSTS?: number | null,
  rightSTS?: number | null,
): boolean => isSTSGreaterThanLimit(leftSTS) || isSTSGreaterThanLimit(rightSTS);
