import { CheckCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

interface ISingleChoiceOptionProps {
  label: string;
}

export function SingleChoiceOption(props: ISingleChoiceOptionProps) {
  const { label } = props;

  return (
    <Grid
      sx={{
        display: 'flex',
        border: '1px solid #6DBDBF',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 12px',
        borderRadius: '16px',
        gap: '10px',
      }}
    >
      <CheckCircle htmlColor="#6DBDBF" />
      <Typography variant="caption">{label}</Typography>
    </Grid>
  );
}
