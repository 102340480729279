import { Grid, Typography, useTheme } from '@mui/material';

import { FullHeightContainer } from 'components/container';
import { useState } from 'react';
import { FormState } from './types';
import { EmailForm } from './components/EmailForm';
import { VerificationForm } from './components/VerificationForm';

export function ForgotPassword() {
  const theme = useTheme();

  const [formState, setFormState] = useState<FormState>(FormState.EmailForm);
  const [email, setEmail] = useState('');

  return (
    <FullHeightContainer maxWidth="xs" disableGutters>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ marginBottom: theme.spacing(4) }}
          >
            Reset Password
          </Typography>
        </Grid>
        {formState === FormState.EmailForm ? (
          <Grid item xs={12}>
            <EmailForm setFormState={setFormState} setEmail={setEmail} />
          </Grid>
        ) : null}
        {formState === FormState.VerificationForm ? (
          <Grid item xs={12}>
            <VerificationForm email={email} />
          </Grid>
        ) : null}
      </Grid>
    </FullHeightContainer>
  );
}
