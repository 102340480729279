import { combineReducers } from '@reduxjs/toolkit';
import { siteManagerDashboardReducer } from './site-manager';
import { DashboardActions, DashboardState } from './types';

export const dashboardReducer = combineReducers<
  DashboardState,
  DashboardActions
>({
  siteManager: siteManagerDashboardReducer,
});
