import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useTableState } from 'hooks/useTableState';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { PageHeader } from 'components/admin-page-layout';
import { PdfPreviewModal } from 'components/modal';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { RequestParams } from 'api/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { TableFilter } from 'components/table-filter';
import { Grid, useTheme } from '@mui/material';
import { SearchInput } from 'components/common/input/SearchInput';
import { Button } from 'components/common/button';
import { DateTime } from 'luxon';
import { DateFormats } from 'common/constants/date';
import _ from 'lodash';
import { useOrganization } from 'hooks/useOrganization';
import { useLocation } from 'hooks/useLocation';
import { DatePickerModal } from 'components/modal/DatePickerModal';
import { ParticipantForm } from './participant-form';
import { getCells } from './cells';
import { getFilterConfig } from './filter-config';
import { ExternalClinicDropdown } from './external-clinic-dropdown';
import { UploadExternalTestResultForm } from '../../../../components/forms/upload-external-test-result-form';

export function AccountManagerParticipantsList() {
  const navigate = useNavigate();
  const { organizationId, locationId } = useParams();
  const theme = useTheme();
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');

  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );
  const { location, isLocationLoading } = useLocation();

  const [editedParticipant, setEditedParticipant] =
    useState<ParticipantWithLastHearingTestResultViewItem | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [externalClinicsDropdownOpen, setExternalClinicsDropdownOpen] =
    useState(false);

  const fetchParticipants = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      if (!locationId) {
        return null;
      }
      try {
        const participantsData =
          await api.organization.getParticipantsByLocationId(locationId, {
            limit,
            offset,
            orderBy,
            orderDirection,
            search,
            filterParams,
          });

        return participantsData;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [locationId],
  );

  const tableState =
    useTableState<ParticipantWithLastHearingTestResultViewItem>({
      fetchDataFunction: fetchParticipants,
    });

  const [disabilityLeaveModalOpened, setDisabilityLeaveModalOpened] =
    useState(false);

  const [
    uploadExternalTestResultModalOpened,
    setUploadExternalTestResultModalOpened,
  ] = useState(false);

  const setParticipantReturnedFromDisabilityLeave = useCallback(
    async (date: DateTime | null) => {
      try {
        if (!editedParticipant) return;
        await api.participant.updateParticipant(editedParticipant.id, {
          returnedFromDisabilityLeaveDate: date?.toISODate(),
        });
        await tableState.reloadData();
        showSnackbar('Participant Returned From Disability Leave', {
          variant: 'success',
        });
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [editedParticipant, tableState],
  );

  const actions = [
    {
      title: 'Test Report',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        navigate(`/admin/participants/${participant.id}/report`);
      },
      disabled: (participant: ParticipantWithLastHearingTestResultViewItem) =>
        !participant.dateTime,
    },
    {
      title: (participant: ParticipantWithLastHearingTestResultViewItem) =>
        participant.active ? 'Mark as Inactive' : 'Mark as Active',
      onClick: async (
        participant: ParticipantWithLastHearingTestResultViewItem,
      ) => {
        await api.participant.updateParticipant(participant.id, {
          active: !participant.active,
        });
        await tableState.reloadData();
      },
    },
    {
      title: 'Send to a clinic',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        if (!participant.email || !participant.phone) {
          showSnackbar('Please fill the email and phone number fields first.', {
            variant: 'warning',
          });
          setFormOpen(true);
        } else {
          setExternalClinicsDropdownOpen(true);
        }
        setEditedParticipant(participant);
      },
    },
    {
      title: 'Edit',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        setFormOpen(true);
        setEditedParticipant(participant);
      },
    },
    {
      title: 'Signed Test Reports',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        navigate(`/admin/participants/${participant.id}/signed-reports`);
      },
      disabled: (participant: ParticipantWithLastHearingTestResultViewItem) =>
        !participant?.lastSignedReport ||
        _.isEmpty(participant?.lastSignedReport),
    },
    {
      title: 'Returned from disability',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        setEditedParticipant(participant);
        setDisabilityLeaveModalOpened(true);
      },
    },
    {
      title: 'Upload external results',
      onClick: (participant: ParticipantWithLastHearingTestResultViewItem) => {
        setEditedParticipant(participant);
        setUploadExternalTestResultModalOpened(true);
      },
    },
  ];

  const handleDownloadReportDocument = async (
    participant: ParticipantWithLastHearingTestResultViewItem,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    if (!participant.lastSignedReport) {
      return;
    }
    try {
      const fileName = `${participant.given}_${
        participant.family
      }_${DateTime.fromISO(participant.lastSignedReport.created_at).toFormat(
        DateFormats.FILE_DATE_FORMAT,
      )}`;
      const data = await api.participant.getParticipantReportPDF(
        participant.id,
        participant.lastSignedReport.id,
      );

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const previewPdfReport = async (
    participant: ParticipantWithLastHearingTestResultViewItem,
  ) => {
    setOpenPdfPreview(true);
    return handleDownloadReportDocument(participant, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const cells = useMemo(
    () => getCells({ location, organization, onPreview: previewPdfReport }),
    [location],
  );

  return (
    <BasicContainer>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
        isDownload
      />

      <PageHeader
        entityTitle="Participants"
        entityName={`${organization ? `${organization.name}, ` : ''}${
          location ? `${location.name}` : ''
        }`}
        titleLoading={isOrganizationLoading || isLocationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedParticipant(null);
              setFormOpen(true);
            }}
          >
            Add Participant
          </Button>
        }
      />

      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <TableFilter
          config={getFilterConfig()}
          useQueryParams
          onChange={(filterParams) => {
            tableState.setFilterParams(filterParams);
          }}
        />
        <Grid
          item
          py={{
            xs: 0,
            sm: theme.spacing(3),
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>

      <BasicTable<ParticipantWithLastHearingTestResultViewItem>
        cells={cells}
        tableState={tableState}
        actions={actions}
        onRowClicked={(
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          if (!participant.dateTime) {
            return showSnackbar('No test results', { variant: 'error' });
          }
          navigate(`/admin/participants/${participant.id}/report`);
        }}
      />

      {formOpen && (
        <ParticipantForm
          participant={editedParticipant}
          organizationName={organization?.name || ''}
          locationName={location?.name || ''}
          handleClose={() => {
            setFormOpen(false);
            setEditedParticipant(null);
          }}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedParticipant(null);
            if (editedParticipant) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}

      {externalClinicsDropdownOpen && editedParticipant && (
        <ExternalClinicDropdown
          participant={editedParticipant}
          handleClose={() => {
            setExternalClinicsDropdownOpen(false);
            setEditedParticipant(null);
          }}
          handleSubmit={() => {
            setExternalClinicsDropdownOpen(false);
            setEditedParticipant(null);
            tableState.reloadData();
          }}
        />
      )}
      {!!editedParticipant && uploadExternalTestResultModalOpened && (
        <UploadExternalTestResultForm
          handleClose={() => {
            setUploadExternalTestResultModalOpened(false);
            setEditedParticipant(null);
          }}
          handleSubmit={() => {
            setUploadExternalTestResultModalOpened(false);
            setEditedParticipant(null);
            tableState.refreshData();
          }}
          participantId={editedParticipant.id}
        />
      )}
      <DatePickerModal
        title="Participant Returned From Disability Leave On:"
        opened={disabilityLeaveModalOpened}
        onClose={() => setDisabilityLeaveModalOpened(false)}
        onSelect={setParticipantReturnedFromDisabilityLeave}
        date={null}
      />
    </BasicContainer>
  );
}
