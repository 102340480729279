import { Grid, Skeleton } from '@mui/material';

import { FullHeightContainer } from 'components/container';

export function LoadingSkeleton() {
  return (
    <FullHeightContainer maxWidth="xs" disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={56} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={56} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <Skeleton variant="rounded" height={36} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullHeightContainer>
  );
}
