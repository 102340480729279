import { PaginatedData } from 'common/types';
import { PaginatedRequestParams, RequestParams } from 'api/types';
import FileDownload from 'js-file-download';
import axios from '../../axios';
import {
  EHearingTestResultTypes,
  EHearingTestStatuses,
  EHearingTestSubStatuses,
  HearingTestResult,
} from './types';

export class HearingTestResultsAPI {
  async getHearingTestResults(params: PaginatedRequestParams | RequestParams) {
    const { data } = await axios.get(`/hearing-test-results`, {
      params,
    });

    return data as PaginatedData<HearingTestResult>;
  }

  async updateHearingTestResult(
    id: number,
    body: {
      status: EHearingTestStatuses;
      subStatuses: EHearingTestSubStatuses[];
    },
  ) {
    const { data } = await axios.put(`/hearing-test-results/${id}`, body);

    return data as HearingTestResult;
  }

  async updateHearingTestResultType(
    id: number,
    body: {
      type: EHearingTestResultTypes;
      reason: string;
    },
  ) {
    const { data } = await axios.put(`/hearing-test-results/${id}/type`, body);

    return data as HearingTestResult;
  }

  async uploadExternalHearingTestResult(body: FormData) {
    const { data } = await axios.post(`/hearing-test-results/`, body);

    return data as HearingTestResult;
  }

  async getHearingTestResultAttachment(
    id: number,
    attachmentId: number,
    fileName: string,
  ) {
    const { data } = await axios.get(
      `/hearing-test-results/${id}/attachments/${attachmentId}`,
      {
        responseType: 'blob',
      },
    );

    return FileDownload(data, fileName);
  }
}
