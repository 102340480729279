import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormikProps } from 'formik';
import { StyledTableCell } from './components/StyledTableCell';
import { FilledTextInput } from '../FilledTextInput';

interface IEquipmentSpecificationsAndNoiseLevelsReportFormTableProps {
  formik: FormikProps<any>;
  loading?: boolean;
}

const FREQUENCIES = [500, 1000, 2000, 4000, 8000];

export function EquipmentSpecificationsAndNoiseLevelsReportFormTable(
  props: IEquipmentSpecificationsAndNoiseLevelsReportFormTableProps,
) {
  const { formik, loading } = props;

  const rows = useMemo(
    () =>
      [
        ['Hertz', ...FREQUENCIES],
        [
          'Decibels',
          ...FREQUENCIES.map((frequency) => (
            <FilledTextInput
              {...formik.getFieldProps(`decibels_${frequency}`)}
              onChange={(e) => {
                const value = e.currentTarget.value;
                const isValid = /^\d+$/.test(value);
                formik.setFieldValue(
                  `decibels_${frequency}`,
                  isValid ? Number(value) : 0,
                );
              }}
              disabled={loading}
            />
          )),
        ],
        [
          'Levels',
          ...FREQUENCIES.map((frequency) => (
            <FilledTextInput
              {...formik.getFieldProps(`level_${frequency}`)}
              onChange={(e) => {
                const value = e.currentTarget.value.replace(',', '.');
                const isValid = /^\d*(\.\d{0,2})?$/.test(value);
                if (isValid && Number(value) >= 0) {
                  formik.setFieldValue(`level_${frequency}`, value);
                }
              }}
              disabled={loading}
            />
          )),
        ],
      ] as Array<Array<string | number | ReactElement>>,
    [formik.values, loading],
  );

  return (
    <TableContainer component={Box}>
      <Table
        sx={{
          minWidth: 500,
          width: '100%',
        }}
        aria-label="table"
      >
        <TableHead>
          <TableRow>
            {rows[0].map((c, index) => (
              <TableCell
                key={c as string}
                align="center"
                style={{
                  fontFamily:
                    index === 0 ? 'PlusJakartaSans' : 'DM Mono, monospace',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  border: '1px solid #EAEAEA',
                }}
              >
                {c}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.slice(1).map((item) => (
            <TableRow>
              {item.map((value, index) => (
                <StyledTableCell
                  sx={{
                    fontFamily:
                      index === 0 ? 'PlusJakartaSans' : 'DM Mono, monospace',
                  }}
                >
                  {value}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
