import { TimePeriod } from './TimePeriod';

export enum WeekDay {
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  Sun,
}

export interface ITimePeriodBoundary {
  hours: number;
  minutes: number;
}

export interface ITimeError {
  from: string;
  to: string;
}

export interface ITimeErrors {
  [key: string]: {
    [key: string]: ITimeError;
  };
}

export interface ISimpleTimeErrors {
  from: string;
  to: string;
}

export interface IParseTime {
  key: string;
  from: number;
  to: number;
}

export type DailySchedule = {
  isWorkingDay: boolean;
  workingPeriods: TimePeriod[];
};

export type IWeeklySchedule = {
  [key in WeekDay]: DailySchedule;
};
