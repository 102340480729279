import { api } from 'api';
import { Shift } from 'api/services/organization/types';
import { IFilterConfig } from 'components/table-filter/types';

export const getFilterConfig = ({
  locationId,
}: {
  locationId: string | number;
}): IFilterConfig[] => [
  {
    name: 'department',
    label: 'Department',
    type: 'asyncSelect',
    fetchMethod: () => api.organization.getLocationDepartments(locationId, {}),
    mapOptionMethod: (item: string) => ({
      value: item,
      label: item,
    }),
    width: 320,
    isMultiple: true,
  },
  {
    name: 'shifts',
    label: 'Working shifts',
    type: 'asyncSelect',
    fetchMethod: () => api.organization.getLocationShifts(locationId, {}),
    mapOptionMethod: (item: Shift) => ({
      value: String(item.id),
      label: item.name,
    }),
    width: 300,
    isMultiple: true,
  },
  {
    name: 'showInactive',
    label: 'Show Inactive',
    type: 'checkbox',
    checkboxValues: {
      true: 'YES',
      false: 'NO',
    },
  },
];
