import { useCallback } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';

import { useTableState } from 'hooks/useTableState';
import { PageHeader } from 'components/admin-page-layout';
import { cells, headerGroupingCells } from './cells';
import { HearingTestResult } from '../../../api/services/hearing-test-results/types';

export function HearingTestResultsList() {
  const { organizationId } = useParams();
  const fetchHearingTestResults = useCallback(
    async (limit: number, offset: number) => {
      const hearingTestResultsData =
        await api.hearingTestResults.getHearingTestResults({
          limit,
          offset,
          organizationId,
        });
      return hearingTestResultsData;
    },
    [],
  );
  const tableState = useTableState<HearingTestResult>({
    fetchDataFunction: fetchHearingTestResults,
  });

  return (
    <BasicContainer>
      <PageHeader title="Hearing Test Results" />

      <BasicTable<HearingTestResult>
        cells={cells}
        tableState={tableState}
        headerGroupingCells={headerGroupingCells}
      />
    </BasicContainer>
  );
}
