/* eslint-disable @typescript-eslint/no-unused-vars */
import { ILocation, Shift } from 'api/services/organization/types';
import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { Button } from 'components/common/button';
import _ from 'lodash';
import BasicTable from 'components/table/BasicTable';
import { Add } from '@mui/icons-material';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { ConfirmationModal } from 'components/modal';
import { Heading } from 'components/common/heading';
import { ShiftForm } from 'components/shared-pages/location/shifts/components/shift-form';
import { cells } from './cells';

interface ShiftsFormProps {
  location: ILocation | null;
}

export function ShiftsForm(props: ShiftsFormProps) {
  const { location } = props;
  const [formOpen, setFormOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editedShift, setEditedShift] = useState<Shift | null>(null);

  const fetchVisitShifts = useCallback(
    async (limit: number, offset: number) => {
      if (!location?.id) {
        return EMPTY_PAGINATED_DATA;
      }
      const shifts = await api.organization.getLocationShifts(location.id, {
        limit,
        offset,
      });
      return shifts;
    },
    [],
  );

  const tableState = useTableState<Shift>({
    fetchDataFunction: fetchVisitShifts,
  });

  const deleteShift = async (shift: Shift) => {
    if (!location?.id) {
      return;
    }
    try {
      await api.organization.deleteLocationShift(location.id, shift.id);
      tableState.refreshData();
      showSnackbar('Location Shift successfully deleted', {
        variant: 'success',
      });
      setConfirmationOpen(false);
      setEditedShift(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not delete Shift')),
        {
          variant: 'error',
        },
      );
    }
  };

  const actions = [
    {
      title: 'Edit',
      onClick: (shift: Shift) => {
        setEditedShift(shift);
        setFormOpen(true);
      },
    },
    {
      title: 'Delete',
      onClick: (shift: Shift) => {
        setEditedShift(shift);
        setConfirmationOpen(true);
      },
    },
  ];

  return (
    <>
      <Grid
        container
        item
        xs={12}
        pb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading sx={{ fontWeight: 700, fontSize: '15px' }} pl={2}>
          Shifts
        </Heading>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          endIcon={<Add fontSize="small" />}
          onClick={() => {
            setEditedShift(null);
            setFormOpen(true);
          }}
        >
          Add Shift
        </Button>
      </Grid>

      <BasicTable<Shift>
        cells={cells}
        tableState={tableState}
        actions={actions}
        isPagination={false}
        isHeader={false}
        contextButtonProps={{
          sx: {
            backgroundColor: 'rgba(202, 194, 190, 0.40);',
            width: '32px',
            height: '32px',
            borderRadius: '8px',
          },
        }}
      />

      {formOpen && location?.id && (
        <ShiftForm
          handleClose={() => {
            setFormOpen(false);
            setEditedShift(null);
          }}
          shift={editedShift}
          locationId={location.id}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedShift(null);
            if (editedShift) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}

      {editedShift && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedShift(null);
          }}
          handleSubmit={() => deleteShift(editedShift)}
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete the ${editedShift.name} shift`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </>
  );
}
