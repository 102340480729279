import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormControlLabel, FormHelperText, Grid, Link } from '@mui/material';
import { Button } from 'components/common/button';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuthSelector } from 'store/selectors/auth';
import { FullHeightContainer } from 'components/container';
import { api } from 'api';
import { invitationSignUpRequest } from 'store/reducers/auth/actions';
import { useCleanupAuthError } from 'hooks/useCleanupAuthError';
import { FormAlert } from 'components/alert';
import { Checkbox, TextInput } from 'components/common/input';
import { PrivacyPolicyModal } from 'components/privacy-policy-modal';
import { validatePassword } from 'utils/validation/password';
import { LoadingSkeleton } from './components/loading-skeleton';

const InvitationSignUpSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string()
    .max(128)
    .required('Required')
    .min(12, 'must be at least 12 characters')
    .test(
      'isValidPassword',
      'must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 special character',
      validatePassword,
    ),
  acceptedTermsOfUse: Yup.boolean().required('Required'),
});

export function InvitationSignUp() {
  const dispatch = useDispatch();
  const authState = useAuthSelector();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    acceptedTermsOfUse: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [privacyPolicyDialogOpened, setPrivacyPolicyDialogOpened] =
    useState(false);

  useCleanupAuthError();

  useEffect(() => {
    if (authState.user) navigate('/');
  }, [authState.user]);

  const decodeToken = useCallback(
    async (token: string) => {
      try {
        const decodedUser = await api.auth.decodeInvitationSignUpToken(token);

        setInitialValues((values) => ({ ...values, email: decodedUser.email }));
      } catch (e: any) {
        setError(
          e?.response?.data?.error ||
            e?.message ||
            'Could not decode invitation token',
        );
      } finally {
        setLoading(false);
      }
    },
    [searchParams],
  );

  useEffect(() => {
    if (!searchParams.get('token')) navigate('/login');

    decodeToken(searchParams.get('token') as string);
  }, [searchParams]);

  const onSubmit = (values: typeof initialValues) => {
    dispatch(
      invitationSignUpRequest({
        ...values,
        token: searchParams.get('token') as string,
      }),
    );
  };

  if (loading) return <LoadingSkeleton />;

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={InvitationSignUpSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        getFieldProps,
      }) => (
        <FullHeightContainer maxWidth="xs" disableGutters>
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={0}
          >
            <Grid item xs={12}>
              <TextInput
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="text"
                name="email"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                // A ' ' is a hack to always have space for error message, can crete a better approach later
                helperText={touched.email && errors.email ? errors.email : ' '}
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
                helperText={
                  touched.password && errors.password ? errors.password : ' '
                }
                margin="none"
                disabled={
                  authState.loading || Boolean(authState.error || error)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <>
                    <Checkbox
                      {...getFieldProps('acceptedTermsOfUse')}
                      checked={values.acceptedTermsOfUse}
                    />
                    <FormHelperText error>
                      {touched.acceptedTermsOfUse &&
                      Boolean(errors.acceptedTermsOfUse)
                        ? errors.acceptedTermsOfUse
                        : ' '}
                    </FormHelperText>
                  </>
                }
                slotProps={{
                  typography: {
                    fontSize: '13px',
                    fontWeight: 700,
                    lineHeight: '16px',
                  },
                }}
                label={
                  <span>
                    I accept the{' '}
                    <Link
                      underline="hover"
                      color="#6DBDBF"
                      onClick={(e) => {
                        e.preventDefault();
                        setPrivacyPolicyDialogOpened(true);
                      }}
                    >
                      Terms of Use and Privacy Policy
                    </Link>
                  </span>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  {authState.error || error ? (
                    <FormAlert severity="error">
                      {authState.error || error}
                    </FormAlert>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={
                      !values.acceptedTermsOfUse ||
                      authState.loading ||
                      Boolean(authState.error || error)
                    }
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <PrivacyPolicyModal
            opened={privacyPolicyDialogOpened}
            onClose={() => setPrivacyPolicyDialogOpened(false)}
            onAccept={() => {
              setPrivacyPolicyDialogOpened(false);
              setFieldValue('acceptedTermsOfUse', true);
            }}
            policyTypes={['general', 'privacy-policy']}
          />
        </FullHeightContainer>
      )}
    </Formik>
  );
}
