import React from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { DailyBiologicalReportForm } from '../../../../components/forms/daily-biological-report-form';
import { PageHeader } from '../../../../components/admin-page-layout';

export function UpdateDailyBiologicalReportPage() {
  const { locationId, reportId } = useParams();
  return (
    <BasicContainer>
      <PageHeader title="Update Biological Report" />
      <DailyBiologicalReportForm
        locationId={Number(locationId)}
        dailyBiologicalReportId={Number(reportId)}
      />
    </BasicContainer>
  );
}
