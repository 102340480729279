const ageAdjustedSTSLimit = 10;
const STSFrequenciesAverageLimit = 25;

export const isAgeAdjustedSTSGreaterThanLimit = (
  ageAdjustedSTS?: number | null,
): boolean => {
  const value = ageAdjustedSTS ? Number(ageAdjustedSTS) : 0;
  return value >= ageAdjustedSTSLimit;
};

export const isSTSFrequenciesAverageGreaterThanLimit = (
  STSFrequenciesAverage?: number | null,
): boolean => {
  const value = STSFrequenciesAverage ? Number(STSFrequenciesAverage) : 0;
  return value >= STSFrequenciesAverageLimit;
};

export const isOSHAShiftPresent = (
  leftAgeAdjustedSTS?: number | null,
  rightAgeAdjustedSTS?: number | null,
  leftSTSFrequenciesAverage?: number | null,
  rightSTSFrequenciesAverage?: number | null,
): boolean =>
  (isAgeAdjustedSTSGreaterThanLimit(leftAgeAdjustedSTS) ||
    isAgeAdjustedSTSGreaterThanLimit(rightAgeAdjustedSTS)) &&
  (isSTSFrequenciesAverageGreaterThanLimit(leftSTSFrequenciesAverage) ||
    isSTSFrequenciesAverageGreaterThanLimit(rightSTSFrequenciesAverage));
