import { useCallback, useEffect, useMemo } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DashboardSelect } from 'components/dashboard-select';
import { useProfessionalSupervisorDashboardState } from 'hooks/search-params/useProfessionalSupervisorDashboardState';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import { getFilterConfig } from './filter-config';
import { TableFilter } from '../../../../components/table-filter';
import { RequestParams } from '../../../../api/types';
import { SearchInput } from '../../../../components/common/input/SearchInput';
import { getCells } from './cells';

export function ProfessionalSupervisorParticipantsList() {
  const {
    selectedLocation,
    loading,
    locations,
    error,
    setError,
    setSelectedLocation,
  } = useProfessionalSupervisorDashboardState();

  useEffect(() => {
    if (error)
      showSnackbar(getErrorMessageComponent(error), {
        variant: 'error',
      });
    setError('');
  }, [error]);

  const fetchParticipants = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      if (!selectedLocation?.id) return null;
      try {
        const participantsData =
          await api.organization.getParticipantsByLocationId(
            selectedLocation?.id,
            {
              limit,
              offset,
              orderBy,
              orderDirection,
              search,
              filterParams,
            },
          );
        return participantsData;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [selectedLocation?.id],
  );

  const tableState =
    useTableState<ParticipantWithLastHearingTestResultViewItem>({
      fetchDataFunction: fetchParticipants,
    });

  useEffect(() => {
    tableState.refreshData();
  }, [selectedLocation?.id]);

  const theme = useTheme();

  const navigate = useNavigate();

  const actions = useMemo(
    () => [
      {
        title: 'Participant Reports',
        onClick: (
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          navigate(
            `/professional-supervisor/participants/${participant.id}/report`,
          );
        },
      },
    ],
    [],
  );

  const locationSelectOptions = useMemo(
    () =>
      locations?.map((location) => ({
        label: `${location.organization?.name} - ${location.name}`,
        value: location.id,
      })) || [],
    [locations],
  );

  const onLocationSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSelectedLocation(
        locations.find((location) => location.id === e.target.value) || null,
      );
    },
    [locations],
  );

  const cells = useMemo(
    () => getCells({ organization: selectedLocation?.organization || null }),
    [selectedLocation],
  );

  return (
    <Grid>
      <Grid container justifyContent="space-between">
        <DashboardSelect
          label="Location"
          labelIcon="location"
          value={selectedLocation?.id || ''}
          options={locationSelectOptions}
          onChange={onLocationSelect}
          loading={loading}
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <TableFilter
          config={getFilterConfig()}
          useQueryParams
          onChange={(filterParams) => {
            tableState.setFilterParams(filterParams);
          }}
        />
        <Grid
          item
          py={{
            xs: 0,
            sm: theme.spacing(3),
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>
      <BasicTable<ParticipantWithLastHearingTestResultViewItem>
        cells={cells}
        tableState={tableState}
        useQueryParams={false}
        actions={actions}
        onRowClicked={(
          participant: ParticipantWithLastHearingTestResultViewItem,
        ) => {
          navigate(
            `/professional-supervisor/participants/${participant.id}/report`,
          );
        }}
      />
    </Grid>
  );
}
