import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { Cell } from 'components/table/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { IVisit } from 'api/services/organization/types';
import { defaultCells } from './cells';
import { VisitFormModal } from '../../../../routes/admin/location-visits/components/visit-form';
import { BasicContainer } from '../../../container';
import { PageHeader } from '../../../admin-page-layout';
import { useLocation } from '../../../../hooks/useLocation';

type LocationVisitsListProps = {
  cells?: Cell<IVisit>[];
  locationId: number;
  onRowClicked: (visit: IVisit) => void;
};

export function LocationVisitsList(props: LocationVisitsListProps) {
  const { cells = defaultCells, locationId, onRowClicked } = props;

  const [formOpen, setFormOpen] = useState(false);
  const [editedVisit, setEditedVisit] = useState<IVisit | null>(null);

  const fetchVisits = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const visitsData = await api.organization.getLocationVisits(
        Number(locationId),
        { limit, offset, shared: true },
      );
      return visitsData;
    },
    [locationId],
  );

  const tableState = useTableState<IVisit>({ fetchDataFunction: fetchVisits });

  const actions = useMemo(
    () => [
      {
        title: 'Edit',
        onClick: (visit: IVisit) => {
          setEditedVisit(visit);
          setFormOpen(true);
        },
      },
    ],
    [],
  );

  const { location } = useLocation();

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Visits"
        entityName={location?.name}
        titleLoading={!location}
      />
      <BasicTable<IVisit>
        cells={cells}
        tableState={tableState}
        actions={actions}
        onRowClicked={onRowClicked}
      />
      {formOpen && locationId && (
        <VisitFormModal
          handleClose={() => {
            setFormOpen(false);
            setEditedVisit(null);
          }}
          visit={editedVisit}
          locationId={String(locationId)}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedVisit(null);
            if (editedVisit) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
    </BasicContainer>
  );
}
