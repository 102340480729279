import _ from 'lodash';
import { ReactElement } from 'react';

interface IErrorItem {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
}

export const getErrorMessageComponent = (
  error: string | string[],
): ReactElement => {
  if (Array.isArray(error)) {
    return (
      <div>
        <span>
          During the processing of your request, we encountered following
          errors:{' '}
        </span>
        <ul>
          {(error as string[]).map((e: string) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
    );
  }
  return <span>{error}</span>;
};

export const getErrorMessage = (
  e: any,
  defaultMessage?: string,
): string[] | string => {
  if (e.response?.data?.error) {
    return e.response?.data?.error;
  }
  if (e.response?.data?.errors?.length) {
    const errors = e.response?.data?.errors.map((item: IErrorItem) => {
      const fieldName = item.path.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      return `${_.capitalize(fieldName)} - ${item.msg} (${item.value})`;
    });
    if (errors.length === 1) {
      return errors[0];
    }
    return errors;
  }
  return e.message || defaultMessage || e.toString();
};
