import { Grid, Skeleton, SxProps, Typography, useTheme } from '@mui/material';

interface IHearingTestResultsSummaryProps {
  items: {
    name: string;
    value: string;
    backgroundColor: string;
  }[];
  loading: boolean;
  itemsHeight?: string;
  itemsWidth?: string;
  containerSx?: SxProps;
  itemSx?: SxProps;
}
export function HearingTestResultsSummary({
  items,
  loading,
  itemsWidth = '260px',
  itemsHeight = '87px',
  containerSx = {},
  itemSx = {},
}: IHearingTestResultsSummaryProps) {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        ...containerSx,
      }}
    >
      {items.map((item, index) => (
        <Grid
          container
          item
          width={itemsWidth}
          height={itemsHeight}
          borderRadius="16px"
          sx={{
            position: 'relative',
            transition: '0.2s',
            '&:hover': { transform: 'scale(1.05)' },

            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
            ...itemSx,
          }}
          justifyContent="flex-start"
          alignItems="center"
          mr={{ xs: 0, sm: index === items.length - 1 ? 0 : theme.spacing(2) }}
          mb={theme.spacing(1)}
          bgcolor={item.backgroundColor}
          p={theme.spacing(2)}
          flexWrap="nowrap"
        >
          <Grid item mr="24px">
            <Typography fontSize="39px" fontWeight="700">
              {item.value}
            </Typography>
          </Grid>
          <Grid item mr="24px">
            <Typography fontSize="13px">{item.name}</Typography>
          </Grid>
          {loading && (
            <Grid
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              <Skeleton
                variant="rounded"
                width="100%"
                height={itemsHeight}
                sx={{ borderRadius: '16px' }}
              />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
