import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/common/button';
import { ExternalClinic } from 'api/services/external-clinic/types';
import { useTableState } from 'hooks/useTableState';
import { PageHeader } from 'components/admin-page-layout';
import { Grid } from '@mui/material';
import { SearchInput } from 'components/common/input/SearchInput';
import { RequestParams } from 'api/types';
import { ExternalClinicForm } from './components/external-clinic-form';
import { getCells } from './cells';

export function ExternalClinicsList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [editedExternalClinic, setEditedExternalClinic] =
    useState<ExternalClinic | null>(null);

  const fetchExternalClinics = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search: string | undefined,
      filterParams: RequestParams | undefined,
    ) => {
      const externalClinicsData = await api.externalClinic.getExternalClinics({
        limit,
        offset,
        orderBy,
        orderDirection,
        search,
        filterParams,
      });
      return externalClinicsData;
    },
    [],
  );
  const tableState = useTableState<ExternalClinic>({
    fetchDataFunction: fetchExternalClinics,
  });

  const actions = [
    {
      title: t('common.edit'),
      onClick: (externalClinic: ExternalClinic) => {
        setEditedExternalClinic(externalClinic);
        setOpen(true);
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      <PageHeader
        title="External Clinics"
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedExternalClinic(null);
              setOpen(true);
            }}
          >
            {t('common.new')}
          </Button>
        }
        onBackClick={() => navigate('/')}
      />

      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Grid />
        <Grid
          item
          py={{
            xs: 0,
            sm: 3,
          }}
        >
          <SearchInput
            value={tableState.search || ''}
            onChange={tableState.handleSearchTyping}
          />
        </Grid>
      </Grid>

      <BasicTable<ExternalClinic>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && (
        <ExternalClinicForm
          externalClinic={editedExternalClinic}
          handleSubmit={() => {
            setOpen(false);
            setEditedExternalClinic(null);
            if (editedExternalClinic) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
          handleClose={() => setOpen(false)}
        />
      )}
    </BasicContainer>
  );
}
