import { useEffect, useState } from 'react';
import { api } from 'api';
import { User } from 'api/services/auth/types';

export const useOHC = (ohcId: number | null) => {
  const [isOHCLoading, setIsOHCLoading] = useState(false);
  const [OHC, setOHC] = useState<User | null>(null);

  const getOHC = async () => {
    if (ohcId)
      try {
        setIsOHCLoading(true);
        const OHC = await api.user.getOhcById(ohcId);
        setOHC(OHC);
      } catch (e) {
        console.log(e);
      }

    setIsOHCLoading(false);
  };

  useEffect(() => {
    getOHC();
  }, [ohcId]);

  return { OHC, isOHCLoading };
};
