import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { HearingTestResult } from '../../../../api/services/hearing-test-results/types';

export const cells: Cell<HearingTestResult>[] = [
  {
    name: 'Name',
    key: 'name',
    render: (result: HearingTestResult) => (
      <Typography
        sx={{
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        {result.participant?.given && result.participant?.family
          ? `${result.participant?.given} ${result.participant?.family}`
          : '-'}
      </Typography>
    ),
  },
  {
    name: 'External ID',
    key: 'externalId',
    render: (result: HearingTestResult) => (
      <Typography
        variant="mono"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: '120%',
          textTransform: 'uppercase',
        }}
      >
        {result.participant?.externalId || '-'}
      </Typography>
    ),
  },
  {
    name: 'Test Date',
    key: 'testDateTime',
    render: (result: HearingTestResult) => (
      <Typography
        variant="mono"
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: '120%',
          textTransform: 'uppercase',
        }}
      >
        {result.dateTime
          ? `${DateTime.fromISO(result.dateTime).toFormat('MM/dd/yyyy')}`
          : '-'}
      </Typography>
    ),
  },
  {
    name: 'Reason',
    key: 'referralComment',
    render: (result: HearingTestResult) => (
      <Typography
        sx={{
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        {result.referralComment}
      </Typography>
    ),
  },
];
