import { MfaMethodListItem, User } from 'api/services/auth/types';
import {
  AuthPayload,
  INVITATION_SIGN_UP_REQUEST,
  InvitationSignUpPayload,
  LOGIN_USER_REQUEST,
  LOGOUT_USER_REQUEST,
  LoginUserPayload,
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_LOGIN_USER,
  SET_AUTH_ERROR,
  USER_IDENTITY_REQUEST,
  ProfessionalSupervisorInvitationSignUpPayload,
  PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  HCPSupervisorInvitationSignUpPayload,
  HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  VerifyParticipantPayload,
  VERIFY_PARTICIPANT_REQUEST,
  SET_MFA_REQUIRED,
  LoginWithMfaPayload,
  LOGIN_AS_USER_REQUEST,
  LoginAsUserPayload,
  accountManagerInvitationSignUpPayload,
  ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST,
} from './types';

export const setAuth = (payload: AuthPayload) => ({
  type: SET_AUTH,
  payload,
});

export const setUser = (payload: User | null) => ({
  type: SET_LOGIN_USER,
  payload,
});

export const setAuthError = (payload: string | string[] | null) => ({
  type: SET_AUTH_ERROR,
  payload,
});

export const setAuthLoading = (payload: boolean) => ({
  type: SET_AUTH_LOADING,
  payload,
});

export const setMfaRequired = (payload: {
  mfaRequired: boolean;
  mfaMethods: MfaMethodListItem[];
  mfaUserId: string;
}) => ({
  type: SET_MFA_REQUIRED,
  payload,
});

export const loginUserRequest = (
  payload: LoginUserPayload | LoginWithMfaPayload,
) => ({
  type: LOGIN_USER_REQUEST,
  payload,
});

export const loginAsUserRequest = (payload: LoginAsUserPayload) => ({
  type: LOGIN_AS_USER_REQUEST,
  payload,
});

export const logoutUserRequest = () => ({
  type: LOGOUT_USER_REQUEST,
});

export const invitationSignUpRequest = (payload: InvitationSignUpPayload) => ({
  type: INVITATION_SIGN_UP_REQUEST,
  payload,
});

export const professionalSupervisorInvitationSignUpRequest = (
  payload: ProfessionalSupervisorInvitationSignUpPayload,
) => ({
  type: PROFESSIONAL_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  payload,
});

export const accountManagerInvitationSignUpRequest = (
  payload: accountManagerInvitationSignUpPayload,
) => ({
  type: ACCOUNT_MANAGER_INVITATION_SIGN_UP_REQUEST,
  payload,
});

export const hcpSupervisorInvitationSignUpRequest = (
  payload: HCPSupervisorInvitationSignUpPayload,
) => ({
  type: HCP_SUPERVISOR_INVITATION_SIGN_UP_REQUEST,
  payload,
});

export const userIdentityRequest = () => ({ type: USER_IDENTITY_REQUEST });

export const verifyParticipantRequest = (
  payload: VerifyParticipantPayload,
) => ({
  type: VERIFY_PARTICIPANT_REQUEST,
  payload,
});
