import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EReportTypeKeys,
  ILocation,
} from '../../api/services/organization/types';
import { useAuthSelector } from '../../store/selectors/auth';
import { getErrorMessage } from '../../utils';
import { api } from '../../api';
import { useLocationIdSearchParam } from './useLocationIdSearchParam';
import { useUrlParam } from './useUrlParams';
import { UseReportPageDashboardStateOpts } from './useProfessionalSupervisorDashboardState';

export const useOrganizationLocationsDashboardState = <
  ReportT extends EReportTypeKeys,
>(
  organizationId: number,
  opts?: UseReportPageDashboardStateOpts<ReportT>,
) => {
  const { setCurrentReportType } = opts || {};

  const [searchParams] = useSearchParams();
  const { user } = useAuthSelector();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [error, setError] = useState<string | string[]>('');
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(
    null,
  );
  const useEffectTriggered = useRef(false);

  useLocationIdSearchParam({
    selectedLocation,
    locations,
    onChange: (location) => setSelectedLocation(location),
  });

  useUrlParam({
    paramName: 'reportType',
    setNewValue: setCurrentReportType,
    validationList: Object.values(EReportTypeKeys),
  });

  useEffect(() => {
    const asyncRequest = async () => {
      try {
        setLoading(true);
        const locations = await api.organization.getLocations(organizationId, {
          limit: 100,
          offset: 0,
        });
        setLocations(locations.items);
        useEffectTriggered.current = true;
        if (!searchParams.get('locationId')) {
          setSelectedLocation(locations.items[0] || null);
        }
      } catch (e) {
        setError(getErrorMessage(e, 'Failed to load the locations list'));
      } finally {
        setLoading(false);
      }
    };

    asyncRequest();
  }, [user]);

  return {
    selectedLocation,
    locations,
    error,
    loading,
    setLoading,
    setError,
    setSelectedLocation,
    setLocations,
  };
};
