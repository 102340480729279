export function CustomCategoryTick(props: any) {
  const { x, y, payload, style } = props;
  return (
    <g transform={`translate(${x - 12},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        transform="rotate(-90)"
        style={style}
      >
        {payload.value}
      </text>
    </g>
  );
}
