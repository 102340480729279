import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ct from 'countries-and-timezones';
import { FileUploadButton, SimpleButton } from '../common/button';
import { Select } from '../common/input';
import { FormModal } from './FormModal';
import { FullHeightContainer } from '../container';
import { api } from '../../api';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../utils';
import { getUserTimezone } from '../../utils/getUserTimezone';
import { Checkbox } from '../common/input/Checkbox';

interface HearingTestResultsUploadModalProps {
  organizationId: number;
  opened: boolean;
  onClose: () => void | Promise<void>;
  onSubmit: () => void | Promise<void>;
}

export function HearingTestResultsUploadModal(
  props: HearingTestResultsUploadModalProps,
) {
  const { opened, onClose, onSubmit, organizationId } = props;
  const [initialValues, setInitialValues] = useState({
    file: null,
    timezone: '',
    isHistorical: false,
  });

  const timezonesWithOffset = useMemo(
    () =>
      ct.getTimezonesForCountry('US').map((tz) => {
        const zone = tz.name;
        return {
          value: zone,
          label: `${zone} (${DateTime.local({ zone }).toFormat('ZZZZ, ZZ')})`,
        };
      }),
    [],
  );

  useEffect(() => {
    setInitialValues((initialValues) => ({
      ...initialValues,
      timezone: getUserTimezone(),
    }));
  }, []);

  const { handleSubmit, values, setFieldValue, touched, errors, isSubmitting } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        file: Yup.mixed().nullable().required('required'),
        timezone: Yup.string().nullable().required('required'),
      }),
      onSubmit: async ({ file, timezone, isHistorical }) => {
        try {
          const formData = new FormData();
          if (file) {
            formData.append('hearingTestResult', file);
          }
          formData.append('timezone', timezone);
          formData.append('organizationId', organizationId.toString());
          formData.append('isHistorical', isHistorical.toString());

          await api.uploads.uploadTestResults(formData);
          showSnackbar(`File successfully uploaded`, {
            variant: 'success',
          });
          await onSubmit();
        } catch (e) {
          showSnackbar(getErrorMessageComponent(getErrorMessage(e)));
          console.log(e);
        }
      },
    });

  const theme = useTheme();
  return (
    <FormModal
      open={opened}
      handleClose={onClose}
      handleSubmit={handleSubmit}
      title="Upload hearing test results"
      isSubmitting={isSubmitting}
    >
      <FullHeightContainer maxWidth="sm" disableGutters>
        <Grid
          component="form"
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleSubmit();
            }
          }}
          container
          columnSpacing={{ xs: 0, sm: theme.spacing(2) }}
        >
          <Grid item xs={12}>
            <Typography style={{ marginBottom: '20px' }}>
              Please choose the timezone in which the test results were
              recorded.
            </Typography>
            <Select
              fullWidth
              options={timezonesWithOffset}
              value={values.timezone}
              label="Timezone"
              displayEmpty
              onChange={(e) => setFieldValue('timezone', e.target.value)}
              errorMessage={
                touched?.timezone && errors?.timezone
                  ? (errors?.timezone as string)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '13px',
                },
              }}
              control={
                <Checkbox
                  name="isHistorical"
                  onChange={(event: any) => {
                    setFieldValue(
                      'isHistorical',
                      !!event.currentTarget.checked,
                    );
                  }}
                  checked={values.isHistorical}
                />
              }
              label="Historical"
            />
          </Grid>
          <Grid item xs={12}>
            <FileUploadButton
              onFilesChosen={(files) => {
                setFieldValue('file', files);
              }}
              inputProps={{
                accept: '.csv',
              }}
              buttonProps={{
                variant: 'contained',
                color: 'secondary',
              }}
            >
              Upload Test Results
            </FileUploadButton>
            <FormHelperText error>
              {touched?.file && errors?.file ? errors.file : ' '}
            </FormHelperText>
          </Grid>
          {values.file && (
            <Grid
              container
              item
              flexDirection="row"
              flexWrap="nowrap"
              gap={1}
              alignItems="center"
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {(values.file as File).name}
              </Typography>
              <SimpleButton
                actionType="delete"
                onClick={() => setFieldValue('file', null)}
              />
            </Grid>
          )}
        </Grid>
      </FullHeightContainer>
    </FormModal>
  );
}
