import { ElementType, useCallback, useEffect, useMemo, useState } from 'react';
import FileDownload from 'js-file-download';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { DashboardSelect } from 'components/dashboard-select';
import { PdfPreviewModal } from 'components/modal';
import { ToggleTableFilter } from 'components/table-filter/ToggleTableFilter';
import { Button, DropdownButton } from 'components/common/button';
import { useLocationIdSearchParam } from 'hooks/search-params/useLocationIdSearchParam';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { IRenderedOptionEntity } from 'types/dataStructureTypes';
import {
  ECombinedReportStatus,
  EReportTypeKeys,
  GetLocationReportTypes,
  ICombinedReport,
  ILocation,
} from 'api/services/organization/types';
import { UploadHistoricalDocumentForm } from 'components/forms/upload-historical-document-form';
import { useSearchParamHandlers } from 'hooks/search-params/useSearchParamHandlers';
import { Add, FileUploadOutlined } from '@mui/icons-material';
import { getCells } from './cells';
import { getReportFileNameByReportTypeKey } from './utils';

type ReportTypeValue = GetLocationReportTypes;

type ReportsListProps = {
  availableReportTypes: GetLocationReportTypes[];
  locations: ILocation[];
  areLocationsLoading: boolean;
  navigateToEditPage?: (report: ICombinedReport, locationId?: number) => void;
  navigateToCreatePage?: (
    reportType: EReportTypeKeys,
    locationId?: number,
  ) => void;
};

export function ReportsList(props: ReportsListProps) {
  const {
    availableReportTypes,
    locations,
    areLocationsLoading,
    navigateToEditPage,
    navigateToCreatePage,
  } = props;

  const editable = !!navigateToEditPage;

  const [fileData, setFileData] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(
    null,
  );
  const [selectedReportType, setSelectedReportType] = useState<ReportTypeValue>(
    EReportTypeKeys.ALL,
  );
  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  useLocationIdSearchParam({
    locations,
    selectedLocation,
    onChange: setSelectedLocation,
    setInitialLocationIdIfEmpty: true,
  });

  const reportTypeSelectOptions: IRenderedOptionEntity<GetLocationReportTypes>[] =
    [EReportTypeKeys.ALL].concat(availableReportTypes).map((value) => {
      switch (value) {
        case EReportTypeKeys.MEDICAL_REFERRAL_LISTS:
          return {
            label: 'Medical Referrals List',
            value: EReportTypeKeys.MEDICAL_REFERRAL_LISTS,
          };
        case EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT:
          return {
            label: 'OSHA Recordable STS',
            value: EReportTypeKeys.OSHA_RECORDABLE_SHIFT_REPORT,
          };
        case EReportTypeKeys.STS_REPORT:
          return {
            label: 'STS',
            value: EReportTypeKeys.STS_REPORT,
          };
        case EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS:
          return {
            label: 'Daily Biological Report',
            value: EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
          };
        case EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS:
          return {
            label: 'Equipment Specifications & Noise Levels Report',
            value:
              EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
          };
        case EReportTypeKeys.COVER_LETTERS:
          return {
            label: 'Cover Letter',
            value: EReportTypeKeys.COVER_LETTERS,
          };
        case EReportTypeKeys.SUMMARY_OF_TESTING:
          return {
            label: 'Summary of Testing',
            value: EReportTypeKeys.SUMMARY_OF_TESTING,
          };
        case EReportTypeKeys.OTHER:
          return {
            label: 'Other',
            value: EReportTypeKeys.OTHER,
          };
        default:
          return {
            label: 'All',
            value: EReportTypeKeys.ALL,
          };
      }
    });

  const { setSearchParam } = useSearchParamHandlers();
  useEffect(() => {
    setSearchParam('page', null);
  }, [selectedReportType]);

  const handleSelectLocation = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const locationId = e.target.value as number;

      setSelectedLocation(
        locations.find((location) => location.id === locationId) || null,
      );
    },
    [locations],
  );

  const locationSelectOptions = useMemo(
    () =>
      locations?.map((location) => ({
        label: `${location.organization?.name} - ${location.name}`,
        value: location.id,
      })) || [],
    [locations],
  );

  const fetchReports = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
    ) => {
      if (!selectedLocation?.id) return null;
      let reportTypesList: GetLocationReportTypes[] = [];
      if (selectedReportType === EReportTypeKeys.ALL) {
        reportTypesList = reportTypeSelectOptions
          .filter(
            ({ value }) =>
              value !== EReportTypeKeys.ALL &&
              value !== EReportTypeKeys.COVER_LETTERS &&
              value !== EReportTypeKeys.SUMMARY_OF_TESTING,
          )
          .map(({ value }) => value)
          .concat([
            EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING,
          ]) as GetLocationReportTypes[];
      } else {
        reportTypesList =
          selectedReportType === EReportTypeKeys.COVER_LETTERS ||
          selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING
            ? [EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING]
            : [selectedReportType];
      }

      try {
        const data = await api.organization.getLocationReports(
          Number(selectedLocation?.id),
          reportTypesList,
          {
            limit,
            offset,
            orderBy,
            orderDirection,
            filterParams: editable
              ? undefined
              : {
                  statuses: [
                    ECombinedReportStatus.Historical,
                    ECombinedReportStatus.Signed,
                  ],
                },
          },
        );
        return data;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
      return EMPTY_PAGINATED_DATA;
    },
    [selectedLocation?.id, selectedReportType],
  );

  const tableState = useTableState<any>({
    fetchDataFunction: fetchReports,
  });

  const handleDownloadReportDocument = async (
    report: ICombinedReport,
    {
      onSuccess,
      onReject,
    }: {
      onSuccess: (file: File, fileName: string) => Promise<void>;
      onReject?: (e: unknown) => void;
    },
  ) => {
    const { name, reportId, reportType, created_at } = report;

    try {
      if (
        selectedReportType === EReportTypeKeys.ALL &&
        reportType ===
          EReportTypeKeys.AUDIOLOGIST_COVER_LETTERS_AND_SUMMARY_OF_TESTING &&
        selectedLocation
      ) {
        const coverLetterData =
          await api.organization.getAudiologistCoverLetterPDF(
            selectedLocation?.id,
            reportId,
          );
        await onSuccess(coverLetterData, `cover_letter_${name}.pdf`);
        const coverSummaryOfTestingData =
          await api.organization.getSummaryOfTestingPDF(
            selectedLocation?.id,
            reportId,
          );
        await onSuccess(
          coverSummaryOfTestingData,
          `summary_of_testing_${name}.pdf`,
        );
        return;
      }

      let fileName = '';
      let data = null;

      if (
        (selectedReportType === EReportTypeKeys.COVER_LETTERS ||
          selectedReportType === EReportTypeKeys.SUMMARY_OF_TESTING) &&
        selectedLocation
      ) {
        fileName =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? `cover_letter_${name}.pdf`
            : `summary_of_testing_${name}.pdf`;
        data =
          selectedReportType === EReportTypeKeys.COVER_LETTERS
            ? await api.organization.getAudiologistCoverLetterPDF(
                selectedLocation?.id,
                reportId,
              )
            : await api.organization.getSummaryOfTestingPDF(
                selectedLocation?.id,
                reportId,
              );
      } else {
        fileName = getReportFileNameByReportTypeKey(reportType, created_at);
        data = await api.organization.getLocationReportPDF(
          reportType,
          reportId,
        );
      }

      await onSuccess(data, fileName);
    } catch (e) {
      onReject?.(e);
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a PDF'),
        ),
        { variant: 'error' },
      );
    }
  };

  const handleCreateReportClick = (reportType: EReportTypeKeys) =>
    navigateToCreatePage?.(reportType, selectedLocation?.id);

  const createReportOptions = [
    {
      value: EReportTypeKeys.DAILY_BIOLOGICAL_REPORTS,
      title: 'New Daily Biological, Calibration and Listening Check Report',
      handleClick: (reportType: EReportTypeKeys) =>
        handleCreateReportClick(reportType),
    },
    {
      value: EReportTypeKeys.EQUIPMENT_SPECIFICATIONS_AND_NOISE_LEVELS_REPORTS,
      title: 'New Equipment Specifications and Noise Level Report',
      handleClick: (reportType: EReportTypeKeys) =>
        handleCreateReportClick(reportType),
    },
  ];

  const downloadReportPDF = async (report: ICombinedReport) =>
    handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        FileDownload(file, fileName);
      },
    });

  const previewPdfReport = async (report: ICombinedReport) => {
    setOpenPdfPreview(true);
    setIsPreviewLoading(true);
    return handleDownloadReportDocument(report, {
      onSuccess: async (file, fileName) => {
        setFileName(fileName);
        setFileData(file);
        setIsPreviewLoading(false);
      },
      onReject: () => setOpenPdfPreview(false),
    });
  };

  const cells = useMemo(
    () =>
      getCells({
        onDownload: downloadReportPDF,
        onPreview: previewPdfReport,
        onEdit: (report) => navigateToEditPage?.(report, selectedLocation?.id),
        selectedReportType,
      }),
    [selectedReportType, selectedLocation],
  );

  const theme = useTheme();

  return (
    <Grid>
      <PdfPreviewModal
        file={fileData}
        fileName={fileName}
        isLoading={isPreviewLoading}
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />

      <Grid
        container
        mb={theme.spacing(0.5)}
        gap={2}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={12} sm="auto" mt={theme.spacing(2)}>
          <DashboardSelect
            label="Location"
            labelIcon="location"
            value={selectedLocation?.id || ''}
            options={locationSelectOptions}
            onChange={handleSelectLocation}
            loading={areLocationsLoading}
          />
        </Grid>

        <Grid>
          <Grid container gap={1}>
            <Grid item xs={12} sm="auto">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                endIcon={<FileUploadOutlined fontSize="small" />}
                onClick={() => {
                  setUploadModalOpened(true);
                }}
              >
                Upload a report
              </Button>
            </Grid>
            {navigateToCreatePage && (
              <Grid item xs={12} sm="auto">
                <DropdownButton<ElementType, EReportTypeKeys>
                  size="small"
                  variant="contained"
                  color="secondary"
                  endIcon={<Add fontSize="small" />}
                  options={createReportOptions}
                >
                  New Report
                </DropdownButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container mb={theme.spacing(0.5)}>
        <ToggleTableFilter
          options={reportTypeSelectOptions}
          onChange={setSelectedReportType}
          value={selectedReportType}
        />
      </Grid>
      <BasicTable<any>
        cells={cells}
        tableState={tableState}
        useQueryParams={false}
      />

      {uploadModalOpened && (
        <UploadHistoricalDocumentForm
          locationId={Number(selectedLocation?.id)}
          handleClose={() => setUploadModalOpened(false)}
          handleSubmit={() => {
            tableState.refreshData();
            setUploadModalOpened(false);
          }}
        />
      )}
    </Grid>
  );
}
