import { useCallback, useEffect, useState } from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  IVisit,
  IVisitTime,
  VisitShift,
} from 'api/services/organization/types';
import { api } from 'api';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { useTableState } from 'hooks/useTableState';
import { Participant, UnscheduledParticipant } from 'api/services/auth/types';
import { PageHeader } from 'components/admin-page-layout';
import FileDownload from 'js-file-download';
import { usePulling } from 'hooks/usePulling';
import { VisitShifts, VisitTimes } from './visit-sections';
import { ScheduledParticipants } from './visit-sections/scheduled-participants';
import { UnscheduledParticipants } from './visit-sections/unscheduled-participants';
import { BookForm } from './components';

export function Visit() {
  const { locationId, visitId } = useParams();
  const [visit, setVisit] = useState<IVisit>();
  const [generateLoading, setGenerateLoading] = useState(false);
  const [sendQuestionnaireLoading, setSendQuestionnaireLoading] =
    useState(false);
  const [downloadSchedulePdfLoading, setDownloadSchedulePdfLoading] =
    useState(false);
  const [isPullingVisit, setIsPullingVisit] = useState(false);
  const [, setToggleExcludeLoading] = useState(false);
  const [bookingParticipant, setBookingParticipant] = useState<
    UnscheduledParticipant | Participant | null
  >(null);
  const [bookingError, setBookingError] = useState('');

  const getVisit = async () => {
    if (!visitId || !locationId) {
      return;
    }
    try {
      const visitTimesData = await api.organization.getVisitById(
        locationId,
        visitId,
      );
      setVisit(visitTimesData);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchVisitTimes = useCallback(async (limit: number, offset: number) => {
    if (!locationId || !visitId) {
      return EMPTY_PAGINATED_DATA;
    }
    const visitTimesData = await api.organization.getVisitById(
      locationId,
      visitId,
    );
    return {
      items: visitTimesData.visitTimes,
      total: 0,
      limit,
      offset,
    };
  }, []);

  const fetchVisitShifts = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId || !visitId) {
        return EMPTY_PAGINATED_DATA;
      }
      const visitTimesData = await api.organization.getVisitShifts(
        locationId,
        visitId,
        { limit, offset },
      );
      return visitTimesData;
    },
    [],
  );
  const fetchUnscheduledParticipants = useCallback(
    async (limit: number, offset: number) => {
      if (!locationId || !visitId) {
        return EMPTY_PAGINATED_DATA;
      }
      const visitTimesData = await api.organization.getUnscheduledParticipants(
        locationId,
        visitId,
        { limit, offset },
      );
      return visitTimesData;
    },
    [],
  );

  const visitTimesTableState = useTableState<IVisitTime>({
    fetchDataFunction: fetchVisitTimes,
  });
  const visitLocationsShiftsTableState = useTableState<VisitShift>({
    fetchDataFunction: fetchVisitShifts,
  });
  const unscheduledParticipantsTableState =
    useTableState<UnscheduledParticipant>({
      fetchDataFunction: fetchUnscheduledParticipants,
    });

  const generateSchedule = async () => {
    if (!visitId || !locationId) {
      return;
    }
    try {
      setGenerateLoading(true);
      await api.organization.generateSchedule(locationId, visitId);
      visitTimesTableState.refreshData();
      visitLocationsShiftsTableState.refreshData();
      unscheduledParticipantsTableState.setPage(0);
      unscheduledParticipantsTableState.refreshData();
      showSnackbar(`Schedule successfully generated`, {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not update licenses'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setGenerateLoading(false);
    }
  };

  const toggleParticipantDeferred = async (
    participantId: number,
    isDeferring: boolean,
    params?: { deferringReason: string; deferringReasonNote?: string | null },
  ) => {
    if (!visitId || !locationId || !participantId) {
      return;
    }
    try {
      setToggleExcludeLoading(true);
      const { deferringReason, deferringReasonNote } = params || {};
      if (isDeferring) {
        await api.organization.setParticipantDeferred({
          locationId,
          visitId,
          participantId,
          deferringReason: deferringReason as string,
          deferringReasonNote,
        });
      } else {
        await api.organization.setParticipantNotDeferred(
          locationId,
          visitId,
          participantId,
        );
      }
      visitTimesTableState.refreshData();
      visitLocationsShiftsTableState.refreshData();
      unscheduledParticipantsTableState.refreshData();
      showSnackbar(
        `Successfully set as ${isDeferring ? 'Deferred' : 'Not Deferred'}`,
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not update')),
        {
          variant: 'error',
        },
      );
    } finally {
      setToggleExcludeLoading(false);
    }
  };

  const bookParticipant = async (values: {
    visitTimeId: number | null;
    shiftId: number | null;
    startTime: string;
  }) => {
    if (
      !visitId ||
      !locationId ||
      !bookingParticipant ||
      !values.visitTimeId ||
      !values.shiftId
    ) {
      return;
    }
    try {
      await api.organization.bookParticipant(
        locationId,
        visitId,
        values.visitTimeId,
        values.shiftId,
        bookingParticipant?.id,
        { startTime: values.startTime },
      );
      visitTimesTableState.reloadData();
      visitLocationsShiftsTableState.reloadData();
      unscheduledParticipantsTableState.reloadData();
      showSnackbar('Successfully booked participant', {
        variant: 'success',
      });
      setBookingParticipant(null);
    } catch (e: any) {
      setBookingError(
        e?.response?.data?.error || e?.message || 'Could not book',
      );
    }
  };

  const unscheduleParticipant = async (participantId: number) => {
    if (!visitId || !locationId || !participantId) {
      return;
    }
    try {
      await api.organization.unscheduleParticipant(
        locationId,
        visitId,
        participantId,
      );
      visitTimesTableState.refreshData();
      visitLocationsShiftsTableState.refreshData();
      unscheduledParticipantsTableState.refreshData();
      showSnackbar(`Successfully unscheduled`, {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not unschedule')),
        {
          variant: 'error',
        },
      );
    }
  };

  const sendQuestionnaireInvitations = async () => {
    if (!visitId || !locationId) {
      return;
    }
    setSendQuestionnaireLoading(true);
    try {
      await api.organization.sendQuestionnaireInvitations(locationId, visitId);
      showSnackbar(`Questionnaire is successfully sent`, {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not send Questionnaire'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setSendQuestionnaireLoading(false);
    }
  };

  const sendBookingInvitations = async (
    invitationType: 'phone' | 'email' | 'both',
  ) => {
    if (!visitId || !locationId) {
      return;
    }
    const body = {
      sendSms: invitationType === 'phone' || invitationType === 'both',
      sendEmail: invitationType === 'email' || invitationType === 'both',
    };
    try {
      await api.organization.sendBookingInvitations(locationId, visitId, body);
      showSnackbar(`Booking Invitation is successfully sent`, {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not send Booking Invitation'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const sendIndividualQuestionnaireInvitation = async (
    participantId: number,
    invitationType:
      | 'phone-questionnaire-invitation'
      | 'email-questionnaire-invitation'
      | 'questionnaire-invitation',
  ) => {
    if (!visitId || !locationId) {
      return;
    }
    setSendQuestionnaireLoading(true);
    try {
      await api.organization.sendIndividualQuestionnaireInvitation(
        locationId,
        visitId,
        participantId,
        invitationType,
      );
      showSnackbar(`Questionnaire is successfully sent`, {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not send Questionnaire'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      setSendQuestionnaireLoading(false);
    }
  };

  const downloadSchedulePDF = async () => {
    if (!visitId || !locationId) {
      return;
    }
    try {
      const fileName = `Schedule ${visit?.name}.pdf`;
      const data = await api.organization.getSchedulePDF(locationId, visitId);
      FileDownload(data, fileName);
      setDownloadSchedulePdfLoading(false);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a schedule PDF'),
        ),
        {
          variant: 'error',
        },
      );
      setDownloadSchedulePdfLoading(false);
    }
  };

  const checkIsSchedulePDFGenerated = async () => {
    if (!visitId || !locationId) {
      return;
    }
    try {
      const visitTimesData = await api.organization.getVisitById(
        locationId,
        visitId,
      );
      if (visitTimesData.schedulePDFDocumentStatus === 'Done') {
        setIsPullingVisit(false);
        downloadSchedulePDF();
      }
    } catch (e) {
      console.log(e);
      setIsPullingVisit(false);
      setDownloadSchedulePdfLoading(false);
    }
  };

  usePulling({ isPulling: isPullingVisit, func: checkIsSchedulePDFGenerated });

  const generateSchedulePDF = async () => {
    if (!visitId || !locationId) {
      return;
    }
    setDownloadSchedulePdfLoading(true);
    try {
      await api.organization.generateSchedulePDF(locationId, visitId);
      setIsPullingVisit(true);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not generate schedule PDF'),
        ),
        {
          variant: 'error',
        },
      );
      setDownloadSchedulePdfLoading(false);
      setIsPullingVisit(false);
    }
  };

  useEffect(() => {
    getVisit();
  }, [visitId]);

  return (
    <BasicContainer sx={{ pb: 2 }}>
      <PageHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>{visit?.name || ''} Visit</div>
            <Box
              sx={{
                fontSize: (theme) => theme.spacing(2),
                alignSelf: 'flex-end',
                fontWeight: 500,
              }}
            >
              {visit?.timezone ? `Timezone: ${visit?.timezone}` : null}
            </Box>
          </Box>
        }
      />

      <Grid container justifyContent="space-between" columnSpacing={2} mb={2}>
        <Grid item xs={12} md={7}>
          <VisitTimes
            visit={visit}
            tableState={visitTimesTableState}
            visitLocationsShiftsTableState={visitLocationsShiftsTableState}
            unscheduledParticipantsTableState={
              unscheduledParticipantsTableState
            }
            generateSchedule={generateSchedule}
            generateLoading={generateLoading}
            sendBookingInvitations={sendBookingInvitations}
            timezone={visit?.timezone || 'America/New_York'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <VisitShifts tableState={visitLocationsShiftsTableState} />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" columnSpacing={2}>
        <Grid item xs={12} md={7}>
          <ScheduledParticipants
            tableState={visitTimesTableState}
            toggleDeferred={toggleParticipantDeferred}
            setBookingParticipant={setBookingParticipant}
            unscheduleParticipant={unscheduleParticipant}
            sendQuestionnaireInvitations={sendQuestionnaireInvitations}
            sendQuestionnaireLoading={sendQuestionnaireLoading}
            sendIndividualQuestionnaireInvitation={
              sendIndividualQuestionnaireInvitation
            }
            getSchedulePDF={generateSchedulePDF}
            getSchedulePDFLoading={downloadSchedulePdfLoading}
            visit={visit}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <UnscheduledParticipants
            tableState={unscheduledParticipantsTableState}
            toggleDeferred={toggleParticipantDeferred}
            setBookingParticipant={setBookingParticipant}
          />
        </Grid>
      </Grid>

      {bookingParticipant && locationId && visitId && (
        <BookForm
          title={`Book ${bookingParticipant.given} ${bookingParticipant.family}`}
          handleClose={() => {
            setBookingParticipant(null);
            setBookingError('');
          }}
          locationId={locationId}
          visitId={visitId}
          visitTimes={visitTimesTableState.data}
          handleSubmit={bookParticipant}
          error={bookingError}
          timezone={visit?.timezone || 'America/New_York'}
        />
      )}
    </BasicContainer>
  );
}
