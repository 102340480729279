import { PaginatedData } from 'common/types';
import axios from '../../axios';
import { IState } from './types';
import { PaginatedRequestParams } from '../../types';

export class StateAPI {
  async getStates(params?: PaginatedRequestParams) {
    const { data } = await axios.get('/state', { params });

    return data as PaginatedData<IState>;
  }
}
