import { Chip, useTheme } from '@mui/material';

interface IHeaderChipsProps {
  value: string;
  backgroundColor: string;
}

export function HeaderChips({ value, backgroundColor }: IHeaderChipsProps) {
  const theme = useTheme();
  return (
    <Chip
      label={value}
      sx={{
        backgroundColor,
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'DM Mono',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
        px: theme.spacing(2),
        py: theme.spacing(1),
      }}
    />
  );
}
