import { Upload } from 'api/services/uploads/types';
import {
  StatusCell,
  DateTimeCell,
  LinkCell,
} from 'components/table/components';
import { Cell } from 'components/table/types';
import { UploadType } from './Uploads';

export const getCells = (uploadType: UploadType): Cell<Upload>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'File Name',
    key: 'fileName',
    render: (item: Upload) => {
      let href: string = '';
      if (uploadType === UploadType.Roster) {
        href = `/admin/organizations/${item.organizationId}${
          item.locationId ? `/locations/${item.locationId}` : ''
        }/roster-uploads/${item.id}/records`;
      } else {
        href = `/admin/organizations/${item.organizationId}/test-result-uploads/${item.id}/records`;
      }

      return <LinkCell to={href} label={item.file.name} />;
    },
  },
  {
    name: 'Date',
    key: 'created_at',
    render: (item: Upload) => (
      <DateTimeCell date={item.created_at} format="date_time" />
    ),
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: Upload) => (
      <StatusCell status={item.status} loading={!item.id} />
    ),
  },
  {
    name: 'Reason',
    key: 'reason',
  },
];
