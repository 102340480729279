import { ParticipantWithLastHearingTestResultViewItem } from 'api/services/auth/types';
import {
  DateCell,
  DateTimeCell,
  LastTestDateCell,
  ListCell,
} from 'components/table/components';
import { ClinicReferralCell } from 'components/table/components/ClinicReferralCell';
import { ReportStatusCell } from 'components/table/components/ReportStatusCell';
import { Cell } from 'components/table/types';
import { ILocation } from '../../../api/services/organization/types';

export const getCells = (
  location: ILocation | null,
): Cell<ParticipantWithLastHearingTestResultViewItem>[] => [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'External Id',
    key: 'externalId',
  },
  {
    name: 'First Name',
    key: 'given',
  },
  {
    name: 'Last Name',
    key: 'family',
  },
  {
    name: 'Gender',
    key: 'gender',
  },
  {
    name: 'Date Of Birth',
    key: 'birthDate',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <DateTimeCell date={item.birthDate} format="date" />
    ),
  },
  {
    name: 'Last test date',
    key: 'Participants.lastHearingTestResult.created_at',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => {
      const showClinicReferral =
        item.clinicReferralParticipants.length > 0 &&
        item.clinicReferralParticipants[0].hearingTestResultId === null;
      const hearingTestResultDateTime =
        item.clinicReferralParticipants[0]?.hearingTestResult?.dateTime ?? '';
      const dateTime =
        item.dateTime > hearingTestResultDateTime
          ? item.dateTime
          : hearingTestResultDateTime;

      return showClinicReferral ? (
        <ClinicReferralCell
          date={item.clinicReferralParticipants[0].updated_at}
        />
      ) : (
        <LastTestDateCell
          date={dateTime}
          returnedFromDisabilityLeaveDate={item.returnedFromDisabilityLeaveDate}
          employmentDate={item.employmentDate}
          location={location}
        />
      );
    },
  },
  {
    name: 'Last questionnaire date',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <DateCell date={item.latestQuestionnaireResult?.created_at ?? ''} />
    ),
    sortable: false,
    orderByValue: 'Participants.latestQuestionnaireResult.created_at',
  },
  {
    name: 'Last test status',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <ReportStatusCell status={item.status} subStatuses={item.subStatuses} />
    ),
    sortable: false,
    orderByValue: 'Participants.status',
  },
  {
    name: 'Department',
    render: (item: ParticipantWithLastHearingTestResultViewItem) =>
      item.department,
    sortable: true,
    orderByValue: 'Participants.department',
  },
  {
    name: 'Working shifts',
    key: 'shifts',
    render: (item: ParticipantWithLastHearingTestResultViewItem) => (
      <ListCell items={item.shifts.map((shift) => shift.name)} />
    ),
  },
];
