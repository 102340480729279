import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/common/button';
import { useAuthSelector } from 'store/selectors/auth';
import { logoutUserRequest } from 'store/reducers/auth/actions';
import { APP_VERSION } from 'common/constants/common';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/common/input';
// import { HelpButton } from 'components/help-button';

interface IHeaderProps {
  isEmpty?: boolean;
  logo?: ReactNode;
  onLogoClick?: () => void;
}

export function Header({ isEmpty, logo, onLogoClick }: IHeaderProps) {
  const authState = useAuthSelector();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onLogout = () => {
    dispatch(logoutUserRequest());
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        variant="elevation"
        position="fixed"
        sx={{ backgroundColor: 'white' }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: 'black', cursor: 'pointer' }}
            onClick={() => {
              if (onLogoClick) return onLogoClick();
              return isEmpty ? undefined : navigate('/');
            }}
          >
            {logo || 'STAR'}
            {!isEmpty && (
              <Typography
                variant="body1"
                component="span"
                sx={{ marginLeft: 1, fontSize: 12 }}
              >
                {APP_VERSION}
              </Typography>
            )}
          </Typography>
          {!isEmpty && (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'inline-block' }}>
                <Select
                  sx={{
                    '&': {
                      // display: 'inline-block', TODO: uncomment when translations will be ready
                      display: 'none',
                      width: 'min-content',
                      height: '38px',
                    },
                    '& .MuiSelect-select': {
                      fontSize: '14px',
                      py: '7px',
                      display: 'inline-block',
                    },
                  }}
                  formControlSx={{
                    '& .MuiFormHelperText-root': {
                      display: 'none',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '0',
                    },
                  }}
                  options={[
                    { label: 'EN', value: 'en' },
                    { label: 'FR', value: 'fr' },
                  ]}
                  value={i18n.language}
                  onChange={(e: any) => i18n.changeLanguage(e.target.value)}
                />
              </div>

              {authState.user ? (
                <>
                  {/* TODO: feature in development */}
                  {/* <HelpButton /> */}
                  <Button
                    type="button"
                    variant="transparent"
                    onClick={onLogout}
                    endIcon={<LogoutIcon width="24px" height="24px" />}
                  >
                    {t('common.logOut')}
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="transparent"
                  onClick={() => navigate('/login')}
                  endIcon={<LoginIcon width="24px" height="24px" />}
                >
                  {t('common.login')}
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
