import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Grid, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { cells } from './cells';
import {
  getErrorMessage,
  getErrorMessageComponent,
  showSnackbar,
} from '../../../../utils';
import {
  IHCPSupervisorLocation,
  ILocation,
  ILocationParticipantsHearingTestsSummary,
} from '../../../../api/services/organization/types';
import { useHCPSupervisorDashboardState } from '../../../../hooks/useHCPSupervisorDashboardState';
import { HearingTestResultsSummary } from '../../../../components/hearing-test-results-summary';
import { LocationForm } from '../../../../components/forms/location-form';

export function HCPSupervisorLocationsList() {
  const { selectedOrganization } = useHCPSupervisorDashboardState();
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [editedLocation, setEditedLocation] = useState<ILocation | null>(null);
  const { t, i18n } = useTranslation();
  const fetchLocations = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
    ) => {
      if (!selectedOrganization) return null;
      try {
        setLoading(true);
        const locationsData =
          await api.organization.getHCPSupervisorLocationsList(
            Number(selectedOrganization.id),
            {
              limit,
              offset,
              orderBy,
              orderDirection,
            },
          );
        return locationsData;
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
      return EMPTY_PAGINATED_DATA;
    },
    [selectedOrganization],
  );

  const tableState = useTableState<IHCPSupervisorLocation>({
    fetchDataFunction: fetchLocations,
  });

  const theme = useTheme();

  const [summary, setSummary] =
    useState<ILocationParticipantsHearingTestsSummary | null>(null);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const locationFormSchema = useMemo(
    () =>
      Yup.object().shape({
        amountOfMonthsNewEmployeeShouldBeTested: Yup.number().required(
          t('validation.required'),
        ),
        amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave:
          Yup.number().required(t('validation.required')),
      }),
    [i18n.language],
  );

  useEffect(() => {
    const requestSummary = async () => {
      if (!selectedOrganization) return;
      try {
        setSummaryLoading(true);
        const data =
          await api.organization.getOrganizationParticipantsHearingTestsSummary(
            selectedOrganization.id,
            { showInactive: tableState.filterParams?.showInactive as boolean },
          );
        setSummary(data);
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      } finally {
        setSummaryLoading(false);
      }
    };
    requestSummary();
  }, [selectedOrganization]);

  const navigate = useNavigate();

  const actions = [
    {
      title: t('common.edit'),
      onClick: (location: ILocation) => {
        setEditedLocation(location);
        setFormOpen(true);
      },
    },
    {
      title: 'Add/Remove a site manager',
      onClick: (location: IHCPSupervisorLocation) => {
        navigate(
          `/hcp-supervisor/${location.managingOrganization}/locations/${location.id}/site-managers`,
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid mb={theme.spacing(3)}>
        <HearingTestResultsSummary
          items={[
            {
              name: 'Completed in last 12 months',
              value: summary
                ? `${Math.round(
                    summary.percentageOfParticipantsTestedLastYear,
                  )}%`
                : '',
              backgroundColor: '#ffe86e',
            },
            {
              name: 'of employees with STS',
              value: summary
                ? `${Math.round(summary.percentageOfParticipantsWithSts)}%`
                : '',
              backgroundColor: '#ddf4fd',
            },
            {
              name: 'Tests Needed',
              value: summary?.participantsNotTestedLastYear?.toString() || '',
              backgroundColor: '#e5fede',
            },
            {
              name: 'with OSHA Recordability STS',
              value: summary
                ? `${Math.round(
                    summary.percentageOfParticipantsWithRecordableOSHAShift,
                  )}%`
                : '',
              backgroundColor: '#FDD7D7',
            },
          ]}
          loading={summaryLoading || loading}
        />
      </Grid>
      <BasicTable<IHCPSupervisorLocation>
        cells={cells}
        tableState={tableState}
        actions={actions}
        useQueryParams={false}
      />
      {formOpen && (
        <LocationForm
          location={editedLocation}
          validationSchema={locationFormSchema}
          handleClose={() => setFormOpen(false)}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedLocation(null);
            tableState.reloadData();
          }}
          disabledFields={[
            'name',
            'externalId',
            'line1',
            'line2',
            'country',
            'state',
            'postalCode',
            'city',
          ]}
        />
      )}
    </Grid>
  );
}
