import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useTableState } from 'hooks/useTableState';
import {
  AccountManagerUser,
  User,
  UserInvitationStatuses,
} from 'api/services/auth/types';
import { PageHeader } from 'components/admin-page-layout';
import { useLocation } from 'hooks/useLocation';
import { useTranslation } from 'react-i18next';
import { loginAsUserRequest } from 'store/reducers/auth/actions';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components/modal';
import { AccountManagerForm } from './components/professional-supervisor-form';
import {
  getErrorMessage,
  getErrorMessageComponent,
  handleInvitationCopy,
  isEqualStatuses,
  showSnackbar,
} from '../../../utils';
import { Button } from '../../../components/common/button';
import { getCells } from './cells';

export function AccountManagersList() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [formOpen, setFormOpen] = useState({
    open: false,
    isReactivation: false,
  });
  const [editedAccountManager, setEditedAccountManager] =
    useState<AccountManagerUser | null>(null);
  const { t, i18n } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { location, isLocationLoading } = useLocation();

  const fetchAccountManagers = useCallback(
    async (limit: number, offset: number) =>
      api.user.getAccountManagers({
        limit,
        offset,
      }),
    [],
  );
  const tableState = useTableState<AccountManagerUser>({
    fetchDataFunction: fetchAccountManagers,
  });

  const handleSendInvitation = async (user: User) => {
    try {
      await api.user.sendAccountManagerInvitation(user.id);
      showSnackbar(t('common.invitationSuccessfullySent'), {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, t('common.couldNotSendInvitation')),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const toggleActivationAM = async () => {
    if (!editedAccountManager) {
      return;
    }
    try {
      await api.user.toggleActive(editedAccountManager.id, {
        active: !editedAccountManager.active,
      });
      tableState.reloadData();
      showSnackbar(
        editedAccountManager.active
          ? t('common.AMSuccessfullyDeactivated')
          : t('common.AMSuccessfullyActivated'),
        {
          variant: 'success',
        },
      );
      setConfirmationOpen(false);
      setEditedAccountManager(null);
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not activate/deactivate AM'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const actions = [
    {
      title: t('common.edit'),
      onClick: (accountManager: AccountManagerUser) => {
        setEditedAccountManager(accountManager);
        setFormOpen({ open: true, isReactivation: false });
      },
    },
    {
      title: t('common.copyInvitationLink'),
      onClick: (accountManager: AccountManagerUser) => {
        handleInvitationCopy(accountManager, 'sign-up/account-manager');
      },
      disabled: (accountManager: AccountManagerUser) =>
        isEqualStatuses(
          accountManager.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: t('common.sendInvitation'),
      onClick: (accountManager: AccountManagerUser) => {
        handleSendInvitation(accountManager);
      },
      disabled: (accountManager: AccountManagerUser) =>
        isEqualStatuses(
          accountManager.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: (user: User) =>
        user.active ? t('common.deactivate') : t('common.activate'),
      onClick: (am: User) => {
        setEditedAccountManager(am);
        if (am.active) {
          return setConfirmationOpen(true);
        }
        setFormOpen({ open: true, isReactivation: true });
      },
    },
    {
      title: 'Login',
      onClick: (accountManager: AccountManagerUser) => {
        dispatch(loginAsUserRequest({ userId: accountManager.id }));
      },
    },
  ];

  const cells = useMemo(() => getCells(), [i18n.language]);

  return (
    <BasicContainer>
      {locationId ? (
        <PageHeader
          entityTitle={t('common.accountManagers')}
          entityName={location?.name}
          titleLoading={isLocationLoading}
          buttons={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setEditedAccountManager(null);
                setFormOpen({ open: true, isReactivation: false });
              }}
            >
              {t('common.new')}
            </Button>
          }
        />
      ) : (
        <PageHeader
          title={t('common.accountManagers')}
          buttons={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setEditedAccountManager(null);
                setFormOpen({ open: true, isReactivation: false });
              }}
            >
              {t('common.new')}
            </Button>
          }
        />
      )}
      <BasicTable<AccountManagerUser>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {formOpen.open && (
        <AccountManagerForm
          accountManager={editedAccountManager}
          handleClose={() =>
            setFormOpen({ open: false, isReactivation: false })
          }
          handleSubmit={() => {
            if (formOpen.isReactivation) {
              toggleActivationAM();
            }
            setFormOpen({ open: false, isReactivation: false });
            setEditedAccountManager(null);
            if (editedAccountManager) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}
      {editedAccountManager && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setEditedAccountManager(null);
          }}
          handleSubmit={toggleActivationAM}
          submitButtonTitle={
            editedAccountManager.active ? 'deactivate' : 'activate'
          }
          title={
            <>
              {editedAccountManager.active
                ? t('common.youAreAboutToDeactivateGivenFamily', {
                    given: editedAccountManager.given,
                    family: editedAccountManager.family,
                  })
                : t('common.youAreAboutToActivateGivenFamily', {
                    given: editedAccountManager.given,
                    family: editedAccountManager.family,
                  })}
              <br />
              {t('common.areYouSure')}
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
