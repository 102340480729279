import { useCallback, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { Button } from 'components/common/button';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import {
  HCPSupervisorUser,
  User,
  UserInvitationStatuses,
} from 'api/services/auth/types';
import {
  getErrorMessage,
  getErrorMessageComponent,
  handleInvitationCopy,
  isEqualStatuses,
  showSnackbar,
} from 'utils';
import { ConfirmationModal } from 'components/modal';
import { PageHeader } from 'components/admin-page-layout';
import { useOrganization } from 'hooks/useOrganization';
import { useDispatch } from 'react-redux';
import { cells } from './cells';
import { loginAsUserRequest } from '../../../store/reducers/auth/actions';
import { HCPSupervisorForm } from './components/hcp-supervisor-form';

export function OrganizationHCPSupervisors() {
  const { organizationId } = useParams();
  const [actionedHcpSupervisor, setActionedHcpSupervisor] =
    useState<User | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );
  const dispatch = useDispatch();

  const fetchHCPSupervisors = useCallback(
    async (limit: number, offset: number) => {
      if (!organizationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.organization.getHCPSupervisors(
        organizationId,
        { limit, offset },
      );
      return locationsData;
    },
    [organizationId],
  );

  const tableState = useTableState<User>({
    fetchDataFunction: fetchHCPSupervisors,
  });

  const handleSendInvitation = async (user: HCPSupervisorUser) => {
    try {
      await api.user.sendHCPSupervisorInvitation(user.id);
      showSnackbar('Invitation successfully sent', {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not send invitation'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleUnassignHCPSupervisor = useCallback(async () => {
    try {
      if (!actionedHcpSupervisor) return;
      await api.user.deactivateHCPSupervisorInvitation(
        actionedHcpSupervisor.id,
      );
      showSnackbar('HCP Supervisor successfully unassigned', {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not send unassign the HCP Supervisor'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }, [actionedHcpSupervisor]);

  const actions = [
    {
      title: 'Delete HCP',
      onClick: (item: HCPSupervisorUser) => {
        setConfirmationOpen(true);
        setActionedHcpSupervisor(item);
      },
    },
    {
      title: 'Edit',
      onClick: (hcpSupervisor: HCPSupervisorUser) => {
        setActionedHcpSupervisor(hcpSupervisor);
        setFormOpen(true);
      },
    },
    {
      title: 'Copy Invitation link',
      onClick: (hcpSupervisor: HCPSupervisorUser) => {
        handleInvitationCopy(hcpSupervisor, 'sign-up/hcp-supervisor');
      },
      disabled: (hcpSupervisor: HCPSupervisorUser) =>
        isEqualStatuses(
          hcpSupervisor.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: 'Send Invitation',
      onClick: (hcpSupervisor: HCPSupervisorUser) => {
        handleSendInvitation(hcpSupervisor);
      },
      disabled: (hcpSupervisor: HCPSupervisorUser) =>
        isEqualStatuses(
          hcpSupervisor.invitationStatus,
          UserInvitationStatuses.Active,
        ),
    },
    {
      title: 'Login',
      onClick: (hcpSupervisor: HCPSupervisorUser) => {
        dispatch(loginAsUserRequest({ userId: hcpSupervisor.id }));
      },
    },
  ];

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="HCP Supervisors"
        entityName={organization?.name}
        titleLoading={isOrganizationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setFormOpen(true);
            }}
          >
            New
          </Button>
        }
      />

      <BasicTable<User>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {formOpen && organizationId && (
        <HCPSupervisorForm
          organizationId={Number(organizationId)}
          handleClose={() => setFormOpen(false)}
          hcpSupervisor={actionedHcpSupervisor}
          handleSubmit={() => {
            setFormOpen(false);
            setActionedHcpSupervisor(null);
            if (actionedHcpSupervisor) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}

      {actionedHcpSupervisor && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setActionedHcpSupervisor(null);
          }}
          handleSubmit={() => {
            handleUnassignHCPSupervisor();
            setConfirmationOpen(false);
            setActionedHcpSupervisor(null);
          }}
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete ${actionedHcpSupervisor.given} ${actionedHcpSupervisor.family}`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
