import { ECombinedReportStatus } from 'api/services/organization/types';
import { Chip } from 'components/common/chip';

export const renderReportStatus = (status: ECombinedReportStatus) => {
  const COLORS = {
    [ECombinedReportStatus.Signed]: {
      labelColor: '#276942',
      dotColor: '#276942',
      backgroundColor: '#8FE0B066',
    },
    [ECombinedReportStatus.Error]: {
      labelColor: '#C45558',
      dotColor: '#C45558',
      backgroundColor: '#FDD7D7',
    },
    [ECombinedReportStatus.Processing]: {
      labelColor: '#8F6715',
      dotColor: '#8F6715',
      backgroundColor: '#FFC88C80',
    },
    [ECombinedReportStatus.Draft]: {
      labelColor: '#08161F',
      dotColor: '#08161F',
      backgroundColor: '#CAC2BE4D',
    },
    [ECombinedReportStatus.Historical]: {
      labelColor: '#08161F',
      dotColor: '#08161F',
      backgroundColor: '#CAC2BE4D',
    },
  };

  let label: string = 'Signed';
  if (status === ECombinedReportStatus.Historical) label = 'Historical';
  else if (status === ECombinedReportStatus.Draft) label = 'Needs Signature';
  else if (status === ECombinedReportStatus.Processing) label = 'Processing';
  else if (status === ECombinedReportStatus.Error) label = 'Error';

  return (
    <Chip
      label={label}
      backgroundColor={COLORS[status]?.backgroundColor}
      labelColor={COLORS[status]?.labelColor}
      dotColor={COLORS[status]?.backgroundColor}
    />
  );
};
