import { InputAdornment, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextInput } from './TextInput';

export function SearchInput(props: TextFieldProps) {
  const { InputProps = {}, ...rest } = props;
  return (
    <TextInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        size: 'small',
        ...InputProps,
      }}
      placeholder="Search"
      label=""
      variant="standard"
      sx={{
        '& .MuiInput-input': { fontSize: '13px' },
      }}
      {...rest}
    />
  );
}
