import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { FullHeightContainer } from 'components/container';
import { FormAlert } from 'components/alert';
import { HCPSupervisorUser } from 'api/services/auth/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { useMemo, useState } from 'react';
import { api } from 'api';
import { FormModal } from 'components/modal';
import { Select, TextInput } from 'components/common/input';
import { GENDERS } from 'common/constants/user';
import _ from 'lodash';
import { birthDate } from 'utils/validation/validators';
import { PhoneInput } from 'components/common/input/PhoneInput';
import {
  convertPhoneFormFieldToInternationalFormat,
  convertPhoneFormFieldToTenDigitFormat,
} from 'utils/phoneNumber';

interface HCPSupervisorFormProps {
  organizationId: number;
  hcpSupervisor?: HCPSupervisorUser | null;
  handleClose: () => void;
  handleSubmit: () => void;
}

const OhcsFormSchema = Yup.object().shape({
  given: Yup.string().required('Required'),
  family: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  birthDate,
});

const EMPTY_VALUES = {
  given: '',
  family: '',
  email: '',
  middleName: '',
  phone: '',
  externalId: '',
  birthDate: '',
  gender: 'None',
  active: true,
};

export function HCPSupervisorForm(props: HCPSupervisorFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[]>('');
  const { hcpSupervisor, handleSubmit, handleClose, organizationId } = props;

  const genderDropdownOptions = useMemo(
    () => GENDERS.map((gender) => ({ label: gender, value: gender })) || [],
    [],
  );

  const initialValues = useMemo(
    () =>
      hcpSupervisor
        ? {
            given: hcpSupervisor.given,
            family: hcpSupervisor.family,
            email: hcpSupervisor.email,
            middleName: hcpSupervisor.middleName,
            phone: convertPhoneFormFieldToTenDigitFormat(hcpSupervisor.phone),
            externalId: hcpSupervisor.externalId,
            birthDate: hcpSupervisor.birthDate,
            gender: hcpSupervisor.gender || 'None',
            active: true,
          }
        : { ...EMPTY_VALUES, organizationId },
    [hcpSupervisor, organizationId],
  );

  const onSubmit = async (values: typeof initialValues) => {
    const body = {
      ..._.omitBy(
        values,
        (value) => (_.isString(value) && value.length === 0) || value === null,
      ),
      phone: convertPhoneFormFieldToInternationalFormat(values.phone),
      given: values.given,
      family: values.family,
      email: values.email,
      gender: values.gender === 'None' ? null : values.gender,
    };
    try {
      setLoading(true);
      if (hcpSupervisor)
        await api.user.updateHCPSupervisor(hcpSupervisor.id, body);
      else await api.user.createHCPSupervisor(body);
      handleSubmit();
      showSnackbar(
        `HCP Supervisor successfully ${hcpSupervisor ? 'updated' : 'added'}`,
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setError(getErrorMessage(e, 'Could not save form'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OhcsFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <FormModal
          open
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={
            hcpSupervisor ? 'Edit HCP Supervisor' : 'Create HCP Supervisor'
          }
          isSubmitting={loading}
        >
          <FullHeightContainer maxWidth="sm" disableGutters>
            <Grid
              component="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  handleSubmit();
                }
              }}
              container
              alignItems="flex-start"
              columnSpacing={{ xs: 0, sm: 2 }}
            >
              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    type="text"
                    name="given"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.given}
                    error={touched.given && Boolean(errors.given)}
                    helperText={
                      touched.given && errors.given ? errors.given : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Middle Name"
                    variant="outlined"
                    type="text"
                    name="middleName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={
                      touched.middleName && errors.middleName
                        ? errors.middleName
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    name="family"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.family}
                    error={touched.family && Boolean(errors.family)}
                    helperText={
                      touched.family && errors.family ? errors.family : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="External Id"
                    variant="outlined"
                    type="text"
                    name="externalId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    error={touched.externalId && Boolean(errors.externalId)}
                    helperText={
                      touched.externalId && errors.externalId
                        ? errors.externalId
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={6} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ' '
                    }
                    margin="none"
                  />
                </Grid>

                <Grid item xs={12}>
                  <PhoneInput
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    options={genderDropdownOptions}
                    value={values.gender}
                    label="Gender"
                    displayEmpty
                    onChange={(e) => setFieldValue('gender', e.target.value)}
                    errorMessage={
                      touched?.gender && errors?.gender
                        ? (errors?.gender as string)
                        : ''
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    name="birthDate"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.birthDate}
                    error={touched.birthDate && Boolean(errors.birthDate)}
                    helperText={
                      touched.birthDate && errors.birthDate
                        ? errors.birthDate
                        : ' '
                    }
                    margin="none"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {error ? (
                      <FormAlert severity="error">
                        {getErrorMessageComponent(error)}
                      </FormAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FullHeightContainer>
        </FormModal>
      )}
    </Formik>
  );
}
