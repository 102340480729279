import { Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import { TextInput } from '../../../../../components/common/input';
import { Button } from '../../../../../components/common/button';
import { ISignedDocument } from '../../../../../api/services/organization/types';

interface ISignatureProps {
  disabled?: boolean;
  onSubmit: (signature: string) => Promise<void>;
  signedDocument: ISignedDocument | null;
}

export function Signature(props: ISignatureProps) {
  const { disabled, onSubmit, signedDocument } = props;

  const [inputTriggered, setInputTriggered] = useState(false);

  const submitHandler = useCallback(
    async (values: { signature: string }) => {
      await onSubmit(values.signature);
    },
    [onSubmit],
  );

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: { signature: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      signature: Yup.string()
        .required('Please, add your signature here')
        .max(200, 'limited to 200 characters'),
    }),
    onSubmit: submitHandler,
  });

  if (signedDocument)
    return (
      <Grid>
        <Typography
          sx={{
            position: 'relative',
            display: 'inline-block',
            fontFamily: "'Square Peg', cursive",
            fontSize: '32px',
            fontWeight: 300,
            lineHeight: '120%',
            marginBottom: '16px',
            '&::after': {
              content: '""',
              display: 'block',
              width: '100%',
              height: '1px',
              backgroundColor: 'black',
            },
          }}
        >
          {signedDocument.signature}
        </Typography>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '120%' }}
        >{`Electronically signed by ${signedDocument.signatory?.given || ''} ${
          signedDocument.signatory?.family || ''
        } on ${DateTime.fromISO(signedDocument.signingDateTime).toFormat(
          'MM/dd/yyyy',
        )} at ${DateTime.fromISO(signedDocument.signingDateTime).toFormat(
          'h:mm a',
        )}`}</Typography>
      </Grid>
    );

  return (
    <Grid container>
      {!inputTriggered ? (
        <Button onClick={() => setInputTriggered(true)} disabled={disabled}>
          Signature
        </Button>
      ) : (
        <Grid container gap={1} alignItems="flex-start">
          <TextInput
            sx={{ width: '205px' }}
            size="small"
            type="text"
            {...getFieldProps('signature')}
            error={touched.signature && Boolean(errors.signature)}
            helperText={
              touched.signature && errors.signature ? errors.signature : ' '
            }
            disabled={isSubmitting}
          />
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            sx={{ height: '40px', minWidth: '40px', paddingX: '6px' }}
          >
            <CheckIcon fontSize="small" />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setInputTriggered(false);
              resetForm();
            }}
            disabled={isSubmitting}
            sx={{ height: '40px', minWidth: '40px', paddingX: '6px' }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
