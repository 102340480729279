import { useMemo } from 'react';
import { useIdentity } from 'hooks/useIdentity';
import { BasicContainer } from 'components/container';
import { Grid } from '@mui/material';
import { ITab } from 'components/tabs/dashboard-tabs/types';
import { DashboardTabs } from 'components/tabs';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AccountManagerOrganizations } from './components/account-manager-organizations';

export function AccountManagerDashboard() {
  useIdentity();

  const tabs = useMemo<ITab[]>(
    () => [
      {
        label: 'Organizations',
        component: <AccountManagerOrganizations />,
        icon: <LocationOnIcon fontSize="small" />,
      },
    ],
    [],
  );

  const renderTitle = (tab: ITab) => <Grid container>{tab.label}</Grid>;

  return (
    <BasicContainer>
      <DashboardTabs tabs={tabs} title={renderTitle} />
    </BasicContainer>
  );
}
