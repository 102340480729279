import { EventAvailable, EventBusy } from '@mui/icons-material';
import {
  Grid,
  Typography as TypographyComponent,
  TypographyProps,
  styled,
} from '@mui/material';

const StyledTypography = styled(TypographyComponent)(() => ({
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: 700,
  letterSpacing: '-0.2px',
  lineHeight: '130%',
}));

interface IParticipantsSectionTitleProps extends TypographyProps {
  type: 'scheduled' | 'unscheduled';
}

export function ParticipantsSectionTitle(
  props: IParticipantsSectionTitleProps,
) {
  const { type, children } = props;
  const color = type === 'scheduled' ? '#277750' : '#842D23';

  return (
    <Grid
      container
      justifyContent="center"
      flex={1}
      alignItems="center"
      gap={2}
    >
      {type === 'scheduled' ? (
        <EventAvailable htmlColor={color as string} />
      ) : (
        <EventBusy htmlColor={color as string} />
      )}
      <StyledTypography variant="h6" color={color}>
        {children}
      </StyledTypography>
    </Grid>
  );
}
