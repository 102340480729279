import { DateTime } from 'luxon';
import _ from 'lodash';
import { IFilterConfig } from './types';
import { IFilterValues } from './TableFilter';

export const getFilterFieldNames = (arr: IFilterConfig[]): string[] =>
  arr.reduce<string[]>((result, item) => {
    if (item.type === 'dateRange') {
      result.push(item.dateFrom?.name as string);
      result.push(item.dateTo?.name as string);
    } else {
      result.push(item.name);
    }
    return result;
  }, []);

export const formatValueFromFilterString = (value: string | null) => {
  if (!value) {
    return null;
  }
  if (value.includes('|')) {
    return value.split('|');
  }
  return value;
};

export const formatFilterDateValue = (value: IFilterValues['key']) => {
  if (_.isString(value)) {
    return DateTime.fromISO(value);
  }
  if (value instanceof DateTime) {
    return value;
  }
  return null;
};

export const formatValueToFilterString = (
  value: string | string[] | DateTime,
) => {
  if (value instanceof DateTime) {
    return value.toISO() as string;
  }
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'boolean') {
    return String(value);
  }
  return value.join('|');
};

export const defaultMapOptionMethod = (item: any) => {
  if (typeof item === 'string') {
    return {
      value: item,
      label: item,
    };
  }
  return {
    value: item.id || '',
    label: item.name || '',
  };
};
