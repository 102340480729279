import { Cell } from 'components/table/types';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { Action } from 'components/table';
import { IconActionsCell } from 'components/table/components/IconActionsCell';
import { DateCell } from 'components/table/components';
import {
  ESignedDocumentStatuses,
  ICombinedReport,
} from '../../../../api/services/organization/types';
import { Chip } from '../../../../components/common/chip';

export const getCells = ({
  actions,
}: {
  actions: Action<ICombinedReport>[];
}): Cell<ICombinedReport>[] => [
  {
    name: 'File Name',
    key: 'name',
    render: (list) => list.name,
    width: '375px',
  },
  {
    name: 'Date',
    key: 'created_at',
    render: (item: ICombinedReport) => {
      if (item.historicalDocument?.signingDate) {
        return `${DateTime.fromISO(
          item.historicalDocument?.signingDate,
        ).toFormat('MM/dd/yyyy')}`;
      }
      if (item.signedDocument?.signingDateTime) {
        return `${DateTime.fromISO(
          item.signedDocument.signingDateTime,
        ).toFormat('MM/dd/yyyy')}`;
      }
      return '-';
    },
  },
  {
    name: 'Status',
    key: 'status',
    render: (list: ICombinedReport) => {
      const COLORS = {
        [ESignedDocumentStatuses.Signed]: {
          labelColor: '#276942',
          dotColor: '#276942',
          backgroundColor: '#8FE0B066',
        },
        [ESignedDocumentStatuses.Error]: {
          labelColor: '#C45558',
          dotColor: '#C45558',
          backgroundColor: '#FDD7D7',
        },
        [ESignedDocumentStatuses.Processing]: {
          labelColor: '#8F6715',
          dotColor: '#8F6715',
          backgroundColor: '#FFC88C80',
        },
      };
      let label: string = list.signedDocument?.status || 'Needs Signature';
      if (!_.isEmpty(list.historicalDocument)) {
        label = 'Historical';
      }
      return (
        <Chip
          label={label}
          backgroundColor={COLORS[list.signedDocument?.status]?.backgroundColor}
          labelColor={COLORS[list.signedDocument?.status]?.labelColor}
          dotColor={COLORS[list.signedDocument?.status]?.backgroundColor}
        />
      );
    },
  },
  {
    name: 'Signed By',
    key: 'signatory',
    render: (list: ICombinedReport) => {
      if (!_.isEmpty(list.historicalDocument))
        return list.historicalDocument.signatoryName;
      if (!_.isEmpty(list.signedDocument?.signatory))
        return `${list.signedDocument?.signatory?.given || ''} ${
          list.signedDocument?.signatory?.family || ''
        }`;
      return '-';
    },
  },
  {
    name: 'Signed Date',
    key: 'signingDateTime',
    render: (list: ICombinedReport) => {
      if (list.historicalDocument?.signingDate) {
        return <DateCell date={list.historicalDocument?.signingDate} />;
      }
      if (list.signedDocument?.signingDateTime) {
        return <DateCell date={list.signedDocument.signingDateTime} />;
      }
      return '-';
    },
  },
  {
    name: '',
    render: (list: ICombinedReport) => (
      <IconActionsCell<ICombinedReport> actions={actions} item={list} />
    ),
    align: 'right',
  },
];
