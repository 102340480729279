import { Checkbox } from '@mui/material';
import i18next from 'i18n/config';
import { Organization } from 'api/services/organization/types';
import {
  EllipsisCell,
  LastRosterUploadDateCell,
  LinkCell,
} from 'components/table/components';
import { Cell } from 'components/table/types';
import _ from 'lodash';

export const getCells = (): Cell<Organization>[] => [
  {
    name: i18next.t('common.ID'),
    key: 'id',
    width: '5%',
  },
  {
    name: i18next.t('common.name'),
    key: 'name',
    render: (item: Organization) => <EllipsisCell text={item.name} />,
    maxWidth: '200px',
    width: '25%',
  },
  {
    name: i18next.t('common.externalId'),
    key: 'externalId',
  },
  {
    name: i18next.t('common.lastRosterUpload'),
    key: 'uploads',
    render: (item: Organization) => {
      const uploadsDates = item.uploads
        .filter(
          (upload) =>
            upload.type === 'LOCATION_ROSTER' ||
            upload.type === 'ORGANIZATION_ROSTER',
        )
        .map((upload) => upload.updated_at);
      uploadsDates.sort();
      return <LastRosterUploadDateCell date={_.last(uploadsDates) || ''} />;
    },
  },
  {
    name: i18next.t('common.needToBeTested'),
    key: 'needToBeTested',
    align: 'center',
    render: (item: Organization) =>
      item.participantsCount - item.participantsTestedLastYearCount || '0',
  },
  {
    name: i18next.t('common.locations'),
    key: 'locationsCount',
    render: (item: Organization) => (
      <LinkCell
        to={`/account-manager/organizations/${item.id}/locations`}
        label={item.locationsCount || '0'}
      />
    ),
  },
  {
    name: i18next.t('common.HCP'),
    key: 'HCPSupervisorsCount',
    render: (item: Organization) => (
      <div>{item.HCPSupervisorsCount || '0'}</div>
      // TODO: feature in development
      // <LinkCell
      //   to={`/admin/organizations/${item.id}/hcp-supervisors`}
      //   label={item.HCPSupervisorsCount || '0'}
      // />
    ),
  },
  {
    name: i18next.t('common.Active'),
    key: 'active',
    render: (item: Organization) => (
      <Checkbox size="small" sx={{ padding: 0 }} checked={item.active} />
    ),
  },
];
