import * as Yup from 'yup';

export const VALIDATION_SCHEMA = Yup.object().shape({
  audiometer: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  name: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  booth: Yup.number().required('required'),
  examiner: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  serialNumber: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  uniqueId: Yup.string()
    .required('required')
    .min(1, 'required')
    .max(200, 'limited to 200 characters'),
  annualCalibration: Yup.date()
    .typeError('invalid date')
    .nullable('required')
    .required('required'),
  dateTime: Yup.date()
    .typeError('invalid date')
    .nullable('required')
    .required('required'),
});
