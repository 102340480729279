import { useEffect, useState } from 'react';
import { ILocation, Organization } from '../api/services/organization/types';
import { useAuthSelector } from '../store/selectors/auth';
import { UserRoles } from '../api/services/auth/types';
import { getErrorMessage } from '../utils';
import { api } from '../api';

export const useHCPSupervisorDashboardState = () => {
  const { user } = useAuthSelector();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | string[]>('');
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  useEffect(() => {
    if (!user) return;
    if (user.role !== UserRoles.HCPSupervisor) {
      setError('User is not an HCP supervisor');
    }
    const organizations = user.HCPSupervisor?.organizations || [];
    setSelectedOrganization(organizations[0] || null);
  }, [user]);

  useEffect(() => {
    const asyncRequest = async () => {
      if (!selectedOrganization) return;
      try {
        setLoading(true);
        const locations = await api.organization.getHCPSupervisorLocationsList(
          selectedOrganization.id,
          { limit: 100, offset: 0 },
        );
        setLocations(locations.items);
      } catch (e) {
        setError(getErrorMessage(e, 'Failed to load the locations list'));
      } finally {
        setLoading(false);
      }
    };
    asyncRequest();
  }, [selectedOrganization]);

  return {
    error,
    locations,
    loading,
    selectedOrganization,
    setError,
    setSelectedOrganization,
    setLoading,
  };
};
