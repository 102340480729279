import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Box,
  FormControlLabel,
  Grid,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Button } from 'components/common/button';
import { AsyncSelect } from 'components/common/async-select';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { IOption } from 'common/types/common';
import { Checkbox } from 'components/common/input/Checkbox';
import { IDateRangeField, IFilterConfig } from './types';
import {
  defaultMapOptionMethod,
  formatFilterDateValue,
  formatValueFromFilterString,
  formatValueToFilterString,
  getFilterFieldNames,
} from './utils';
import { FilterDateRangeInput } from './components/FilterDateRangeInput';
import { Select } from '../common/input';
import { AsyncAutocomplete } from '../common/async-autocomplete';

export interface IFilterValues {
  [key: string]: string | string[] | DateTime | null;
}

interface ITabletFilterProps {
  config: IFilterConfig[];
  useQueryParams?: boolean;
  onChange?: (values: { [key: string]: string }) => void;
  withoutContainerPadding?: boolean;
}

export function TableFilter(props: ITabletFilterProps) {
  const [expanded, setExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    config,
    useQueryParams = true,
    onChange,
    withoutContainerPadding,
  } = props;

  const initialValues = useMemo(() => {
    if (useQueryParams) {
      const validNames = getFilterFieldNames(config);
      return validNames.reduce<IFilterValues>((result, item) => {
        result = {
          ...result,
          [item]: formatValueFromFilterString(searchParams.get(item)) || null,
        };
        return result;
      }, {});
    }
    return {};
  }, [config]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: async () => {},
  });

  const handleFilterChange = (values: any) => {
    if (useQueryParams) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      Object.keys(values).forEach((key) => {
        if (_.isArray(values[key]) ? !values[key]?.length : !values[key]) {
          updatedSearchParams.delete(key);
        } else {
          updatedSearchParams.set(key, formatValueToFilterString(values[key]));
        }
      });
      setSearchParams(updatedSearchParams.toString(), {
        replace: true,
      });
    } else {
      const result: { [key: string]: string } = {};
      Object.keys(values).forEach((key) => {
        if (_.isArray(values[key]) ? values[key]?.length : values[key]) {
          result[key] = values[key];
        }
      });
      onChange?.(result);
    }
  };

  useEffect(() => {
    handleFilterChange(formik.values);
  }, [formik.values]);

  const renderFilters = (
    {
      name,
      type,
      label,
      width,
      fetchMethod,
      mapOptionMethod,
      isMultiple,
      dateFrom,
      dateTo,
      options,
      checkboxValues,
    }: IFilterConfig,
    index: number,
  ) => {
    switch (type) {
      case 'asyncSelect':
        return (
          <Box key={name} sx={{ width }}>
            <AsyncSelect
              fullWidth
              multiple={isMultiple}
              value={formik.values[name] as string | string[]}
              label={label || ''}
              fetchMethod={fetchMethod}
              mapOptionMethod={mapOptionMethod || defaultMapOptionMethod}
              onChange={(event: any, newValue: any) => {
                let value = newValue;
                if (isMultiple) {
                  value = newValue.map((item: any) => item.value);
                }
                formik.setFieldValue(name, value.value);
              }}
              withHelperText={false}
              size="small"
            />
          </Box>
        );
      case 'autocomplete':
        return (
          <Box key={name} sx={{ width }}>
            <AsyncAutocomplete
              fullWidth
              withHelperText={false}
              size="small"
              label={label || ''}
              fetchMethod={fetchMethod}
              mapOptionMethod={mapOptionMethod || defaultMapOptionMethod}
              onChange={(event: any, newValue: any) => {
                let value = newValue;
                if (isMultiple) {
                  value = newValue.map((item: any) => item.value);
                }
                formik.setFieldValue(name, value);
              }}
              multiple={isMultiple}
              value={formik.values[name] as string | string[]}
            />
          </Box>
        );
      case 'dateRange': {
        return (
          <Box key={name} sx={{ width }}>
            <FilterDateRangeInput
              label={label || ''}
              dateFromField={{
                ...(dateFrom as IDateRangeField),
                value: formatFilterDateValue(
                  formik.values[dateFrom?.name as string],
                ),
              }}
              dateToField={{
                ...(dateTo as IDateRangeField),
                value: formatFilterDateValue(
                  formik.values[dateTo?.name as string],
                ),
              }}
              onChange={(
                dateFromValue: DateTime | null,
                dateToValue: DateTime | null,
              ) => {
                formik.setFieldValue(dateFrom?.name as string, dateFromValue);
                formik.setFieldValue(dateTo?.name as string, dateToValue);
              }}
            />
          </Box>
        );
      }
      case 'select': {
        return (
          <Box key={name} sx={{ width }}>
            <Select
              formControlSx={{
                '& .MuiFormHelperText-root': {
                  display: 'none',
                },
              }}
              sx={{
                '& .MuiSelect-select': {
                  py: '7px',
                  height: '23px',
                  fontSize: '13px',
                },
              }}
              inputLabelProps={{
                sx: {
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(17px, -9px) scale(0.6)',
                  },
                  transform: formik.values[name]
                    ? 'translate(17px, -9px) scale(0.6)'
                    : 'translate(14px, 10px) scale(0.8)',
                },
              }}
              label={label || ''}
              value={formik.values[name] || ''}
              options={options as IOption[]}
              onChange={(event: any) => {
                formik.setFieldValue(name, event.target.value);
              }}
            />
          </Box>
        );
      }
      case 'checkbox': {
        return (
          <Box key={name} sx={{ width, ml: index === 0 ? '10px' : 0 }}>
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '13px',
                },
              }}
              control={
                <Checkbox
                  name={name}
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      name,
                      checkboxValues
                        ? checkboxValues[
                            String(!!event.currentTarget.checked) as
                              | 'true'
                              | 'false'
                          ]
                        : !!event.currentTarget.checked,
                    );
                  }}
                  checked={
                    checkboxValues
                      ? Object.keys(checkboxValues).find(
                          (key) =>
                            checkboxValues[key as 'true' | 'false'] ===
                            formik.values[name],
                        ) === 'true'
                      : !!formik.values[name]
                  }
                />
              }
              label={label || ''}
            />
          </Box>
        );
      }

      default:
        return null;
    }
  };

  const animationContainerRef = useRef(null);

  const theme = useTheme();
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      item
      flexDirection="row"
      py={withoutContainerPadding ? undefined : theme.spacing(2)}
      alignItems="center"
    >
      <Grid
        item
        sx={{
          py: theme.spacing(1.5),
          mr: theme.spacing(2),
        }}
      >
        <Badge
          overlap="rectangular"
          badgeContent=""
          color="secondary"
          variant="dot"
          invisible={!_.compact(_.values(formik.values)).length}
        >
          <Button
            onClick={() => setExpanded((expanded) => !expanded)}
            startIcon={<FilterListIcon sx={{ fontSize: '13px' }} />}
            sx={{
              fontSize: '13px',
              textTransform: 'none',
              padding: '4px 8px',
              backgroundColor: 'rgba(202, 194, 190, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(202, 194, 190, 0.2)',
              },
            }}
          >
            Filter
          </Button>
        </Badge>
      </Grid>
      <Grid
        item
        ref={animationContainerRef}
        sx={{
          overflowX: 'hidden',
          py: expanded ? theme.spacing(1) : 0,
          height: !expanded && isTabletView ? 0 : undefined,
        }}
      >
        <Slide
          direction="right"
          in={expanded}
          container={animationContainerRef.current}
        >
          <Grid container item wrap="wrap" gap={2} width="auto">
            {config.map(renderFilters)}
          </Grid>
        </Slide>
      </Grid>
    </Grid>
  );
}

export default TableFilter;
