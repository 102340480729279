import { BoolSuccessResponse } from 'api/types';
import {
  LoginUserPayload,
  LoginWithMfaPayload,
} from 'store/reducers/auth/types';
import {
  IAccountManagerBody,
  IProfessionalSupervisorBody,
} from '../user/types';
import axios from '../../axios';
import {
  HCPSupervisorBody,
  LoginResponse,
  User,
  VerificationCodeResponse,
  MfaMethod,
  VerifiedParticipant,
} from './types';

export class AuthAPI {
  async login(loginData: LoginWithMfaPayload | LoginUserPayload) {
    const { data } = await axios.post('/auth/login', loginData);

    return data as LoginResponse;
  }

  async getMfaCode(method: MfaMethod, userId: number) {
    const { data } = await axios.post<BoolSuccessResponse>(
      `/auth/mfa/${method}`,
      { userId },
    );

    return data;
  }

  async verifyParticipant(
    family: string,
    birthDate: string,
    organizationName: string,
  ) {
    const { data } = await axios.post(
      '/auth/generate-employee-token-by-organization',
      {
        family,
        birthDate,
        organizationName,
      },
    );

    return data as VerifiedParticipant;
  }

  async decodeInvitationSignUpToken(token: string) {
    const { data } = await axios.post('/auth/invitation-sign-up/decode', {
      token,
    });

    return data as User;
  }

  async invitationSignUp(
    password: string,
    token: string,
    acceptedTermsOfUse: boolean,
  ) {
    const { data } = await axios.post('/auth/invitation-sign-up', {
      password,
      token,
      acceptedTermsOfUse,
    });

    return data as LoginResponse;
  }

  async professionalSupervisorInvitationSignUp(
    body: IProfessionalSupervisorBody & {
      token: string;
      password: string;
      acceptedTermsOfUse: boolean;
    },
  ) {
    const { data } = await axios.post(
      '/auth/invitation-sign-up/professional-supervisor',
      body,
    );

    return data as LoginResponse;
  }

  async accountManagerInvitationSignUp(
    body: IAccountManagerBody & {
      token: string;
      password: string;
      acceptedTermsOfUse: boolean;
    },
  ) {
    const { data } = await axios.post(
      '/auth/invitation-sign-up/account-manager',
      body,
    );

    return data as LoginResponse;
  }

  async hcpSupervisorInvitationSignUp(
    body: HCPSupervisorBody & {
      token: string;
      password: string;
      acceptedTermsOfUse: boolean;
    },
  ) {
    const { data } = await axios.post(
      '/auth/invitation-sign-up/hcp-supervisor',
      body,
    );

    return data as LoginResponse;
  }

  async generateResetPasswordVerificationCode(email: string) {
    const { data } = await axios.post('/auth/generate-verification-code', {
      email,
    });

    return data as VerificationCodeResponse;
  }

  async resetPassword(
    email: string,
    verificationCode: string,
    password: string,
  ) {
    const { data } = await axios.post('/auth/reset-password', {
      email,
      verificationCode,
      password,
    });

    return data as VerificationCodeResponse;
  }

  async loginAsUser(userId: number) {
    const { data } = await axios.post(`admin/login-as-user`, {
      userId,
    });

    return data as LoginResponse;
  }
}
