import { Grid } from '@mui/material';
import { AnswerType, IQuestion } from 'api/services/questionnaire/types';
import { Option } from './Option';

interface OptionsProps {
  question: IQuestion;
  value?: AnswerType | null;
  setValue: (value: AnswerType) => void;
}

export function Options(props: OptionsProps) {
  const { question, value, setValue } = props;

  const handleChange = (answerValue: AnswerType) => {
    setValue(answerValue);
  };

  return (
    <Grid container gap={2}>
      {question.choices.map((choice, index) => (
        <Option
          choice={choice}
          index={index + 1}
          value={value}
          handleChange={handleChange}
        />
      ))}
    </Grid>
  );
}
