import { Grid, GridProps } from '@mui/material';
import { BasicContainer } from 'components/container';
import { LazyBgGrid } from '../lazy-bg-grid';

interface FormWithBgContainerProps extends GridProps {
  imageUrl: string;
  gradient?: string;
}

export function FormWithBgContainer(props: FormWithBgContainerProps) {
  const { children, imageUrl, gradient } = props;

  return (
    <BasicContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="self-start"
        wrap="nowrap"
        sx={{
          height: {
            xs: 'unset',
            md: 'calc(100vh - 100px)',
          },
          flexDirection: {
            xs: 'column-reverse',
            md: 'row',
          },
        }}
      >
        <Grid
          container
          xs={12}
          md={7}
          sx={{
            height: '100%',
            padding: { xs: '16px 0', md: '0 0 16px' },
            paddingRight: {
              sx: 0,
              md: 4,
            },
            overflowY: 'auto',
          }}
        >
          {children}
        </Grid>

        <LazyBgGrid
          src={imageUrl}
          gradient={gradient}
          md={5}
          sx={{
            width: {
              xs: '100%',
            },
            height: {
              xs: '20vh',
              md: '100%',
            },
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            borderRadius: '8px',
          }}
        />
      </Grid>
    </BasicContainer>
  );
}
