import { t } from 'i18next';
import { IFilterConfig } from '../../../components/table-filter/types';
import { api } from '../../../api';
import { Organization } from '../../../api/services/organization/types';

export const getFilterConfig = (): IFilterConfig[] => [
  {
    name: 'organizations',
    label: t('common.organization'),
    type: 'autocomplete',
    fetchMethod: api.organization.getOrganizations,
    mapOptionMethod: (item: Organization) => ({
      value: item.id,
      label: item.name,
    }),
    width: 320,
    isMultiple: true,
  },
];
