import { Typography } from '@mui/material';

interface IYesNoCellProps {
  value: 'Yes' | 'No';
}

export function YesNoCell(props: IYesNoCellProps) {
  const { value } = props;

  return (
    <Typography
      sx={{
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.25px',
        textTransform: 'uppercase',
        marginRight: '26px',
      }}
      variant="mono"
    >
      {value}
    </Typography>
  );
}
