import { Box, Grid, useTheme } from '@mui/material';
import { useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import { INoteBody } from '../../../../api/services/hearing-test-results/types';
import { Button } from '../../../common/button';
import { TextEditor } from '../../../text-editor';

interface IAudiologistNoteEditor {
  initialState?: EditorState;
  onSave: (note: INoteBody) => Promise<void>;
  onCancel: () => void;
}

export function AudiologistNoteEditor(props: IAudiologistNoteEditor) {
  const { initialState, onSave, onCancel } = props;

  const [editorState, setEditorState] = useState<EditorState>(
    initialState || EditorState.createEmpty(),
  );

  const theme = useTheme();

  return (
    <Box
      sx={{
        py: theme.spacing(2),
        fontFamily: 'PlusJakartaSans',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '0.15px',
      }}
    >
      <TextEditor
        editorState={editorState}
        setEditorState={setEditorState}
        focusOnMount
      />
      <Grid
        container
        gap={theme.spacing(2)}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}
        direction={{ xs: 'column-reverse', sm: 'row' }}
        mt={theme.spacing(2)}
      >
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onSave({
              ...convertToRaw(editorState?.getCurrentContent()),
              entityMap: JSON.stringify(
                convertToRaw(editorState?.getCurrentContent()).entityMap,
              ),
            });
          }}
          disabled={!editorState.getCurrentContent().hasText()}
        >
          Save
        </Button>
      </Grid>
    </Box>
  );
}
