import { Chip } from 'components/common/chip';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { InfoIcon } from 'components/common/Info-icon';
import { Grid } from '@mui/material';
import { ILocation } from '../../../api/services/organization/types';
import { CHIP_STYLES } from '../constants';

interface ILastTestDateCellProps {
  date: string;
  returnedFromDisabilityLeaveDate: string | null;
  employmentDate: string;
  location: ILocation | null;
  onClick?: () => void;
}

export function LastTestDateCell(props: ILastTestDateCellProps) {
  const {
    date,
    onClick,
    employmentDate,
    returnedFromDisabilityLeaveDate,
    location,
  } = props;

  const parsedReturnedFromDisabilityLeaveDate = useMemo(
    () =>
      returnedFromDisabilityLeaveDate
        ? DateTime.fromISO(returnedFromDisabilityLeaveDate)
        : null,
    [returnedFromDisabilityLeaveDate],
  );

  const chipColors = useMemo(() => {
    // Chip should be:
    //  - green, if there are more than 2 months until the next test
    //  - yellow, if there are less than 2 months until the next test
    //  - red, if the test is overdue
    const {
      amountOfMonthsNewEmployeeShouldBeTested,
      amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave,
    } = location || {};

    if (!date) {
      return CHIP_STYLES.disabled;
    }

    const dateTime: DateTime | null = date ? DateTime.fromISO(date) : null;

    if (location && parsedReturnedFromDisabilityLeaveDate?.isValid) {
      if (
        !dateTime?.isValid ||
        parsedReturnedFromDisabilityLeaveDate.diff(dateTime, ['days']).days > 0
      ) {
        const testingDueDate = parsedReturnedFromDisabilityLeaveDate.plus({
          month: amountOfMonthsEmployeeShouldBeTestedAfterDisabilityLeave,
        });
        const differenceInMonthsBetweenTheTestingDueDateAndToday =
          testingDueDate.diff(DateTime.now(), ['months']).months;
        if (differenceInMonthsBetweenTheTestingDueDateAndToday > 2) {
          return CHIP_STYLES.testingIsInMoreThanTwoMonths;
        }
        if (
          differenceInMonthsBetweenTheTestingDueDateAndToday >= 0 &&
          differenceInMonthsBetweenTheTestingDueDateAndToday <= 2
        ) {
          return CHIP_STYLES.testingIsInLessThanTwoMonths;
        }
        return CHIP_STYLES.testingIsOverdue;
      }
    }
    const parsedEmploymentDate = employmentDate
      ? DateTime.fromISO(employmentDate)
      : null;

    if (location && parsedEmploymentDate?.isValid && !dateTime?.isValid) {
      const testingDueDate = parsedEmploymentDate.plus({
        month: amountOfMonthsNewEmployeeShouldBeTested,
      });
      const differenceInMonthsTheTestingDueDateAndToday = testingDueDate.diff(
        DateTime.now(),
        ['months'],
      ).months;
      if (differenceInMonthsTheTestingDueDateAndToday > 2) {
        return CHIP_STYLES.testingIsInMoreThanTwoMonths;
      }
      if (
        differenceInMonthsTheTestingDueDateAndToday >= 0 &&
        differenceInMonthsTheTestingDueDateAndToday <= 2
      ) {
        return CHIP_STYLES.testingIsInLessThanTwoMonths;
      }
      return CHIP_STYLES.testingIsOverdue;
    }

    if (dateTime?.isValid) {
      const diffFromNow = DateTime.now().diff(dateTime, ['months']).months;
      if (diffFromNow < 10) {
        return CHIP_STYLES.testingIsInMoreThanTwoMonths;
      }
      if (diffFromNow < 12) {
        return CHIP_STYLES.testingIsInLessThanTwoMonths;
      }
    }
    return CHIP_STYLES.testingIsOverdue;
  }, [date, employmentDate, returnedFromDisabilityLeaveDate]);

  const formattedDate = useMemo(() => {
    if (!date) {
      return 'N/A';
    }
    const dateTime: DateTime = DateTime.fromISO(date);
    return dateTime.toFormat('MM/dd/yyyy');
  }, [date]);

  const renderInfo = () => {
    const isTestedAfterReturnedFromDisability =
      parsedReturnedFromDisabilityLeaveDate
        ? DateTime.fromISO(date).valueOf() >
          parsedReturnedFromDisabilityLeaveDate?.valueOf()
        : false;

    if (
      returnedFromDisabilityLeaveDate &&
      !isTestedAfterReturnedFromDisability
    ) {
      return (
        <InfoIcon
          content={`Returned from disability on ${parsedReturnedFromDisabilityLeaveDate?.toFormat(
            'MM/dd/yyyy',
          )}`}
        />
      );
    }

    return null;
  };

  return (
    <Grid container alignItems="flex-end" gap={1} wrap="nowrap">
      <Chip
        label={formattedDate}
        backgroundColor={chipColors.background}
        labelColor={chipColors.text}
        isDot
        dotColor={chipColors.dot}
        sx={onClick ? { cursor: 'pointer' } : {}}
        clickable={!!onClick && !!date}
        onClick={(e) => {
          if (!onClick || !date) {
            return;
          }
          e.stopPropagation();
          onClick();
        }}
      />
      {renderInfo()}
    </Grid>
  );
}
