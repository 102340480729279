import {
  IVisit,
  IVisitTime,
  Shift,
  VisitTimeShift,
  VisitTimeSlot,
} from 'api/services/organization/types';
import { DateTimeCell, ListCell } from 'components/table/components';
import { Cell } from 'components/table/types';
import { ColorFilledCell } from 'components/table/components/ColorFilledCell';
import { ShiftNameCell } from 'components/table/components/ShiftNameCell';
import { Action, ContextMenu } from 'components/table';
import { DateTime } from 'luxon';
import { Tooltip } from 'components/common/tooltip';
import { TimeRangeLabel } from '../../components';

interface ExtendedVisitTimeSlot extends VisitTimeSlot {
  shift: Shift;
}

const getSlots = (visitTimeShifts: VisitTimeShift[]) => {
  let res: ExtendedVisitTimeSlot[] = [];
  visitTimeShifts.forEach((visitTimeShift) => {
    const extendedSlot = visitTimeShift.visitTimeSlots.map((slot) => ({
      ...slot,
      shift: visitTimeShift.shift,
    }));
    res = res.concat(extendedSlot);
  });
  return res;
};

export const getCells = (
  actions: Action<VisitTimeSlot>[],
  visit?: IVisit,
): Cell<IVisitTime>[] => [
  {
    name: 'Date',
    key: 'startTime',
    verticalAlign: 'top',
    render: (item: IVisitTime) => (
      <ColorFilledCell
        sx={{
          backgroundColor: 'transparent',
          fontWeight: 700,
          paddingLeft: 0,
          textAlign: 'left',
        }}
      >
        <DateTimeCell
          date={item.startTime}
          tz={visit?.timezone || 'America/New_York'}
          format="date"
        />
      </ColorFilledCell>
    ),
  },
  {
    name: 'Employee',
    key: 'participant',
    verticalAlign: 'top',
    render: (item: IVisitTime) => (
      <ListCell
        items={getSlots(item.visitTimeShifts).map((slot) => (
          <ColorFilledCell
            sx={{
              backgroundColor: 'transparent',
              fontWeight: 500,
              textAlign: 'left',
              paddingLeft: 0,
              margin: '4px 0 5px',
              minWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 200,
              whiteSpace: 'nowrap',
            }}
          >
            <Tooltip
              title={`${slot.participant?.given} ${slot.participant?.family}`}
            >
              <span>{`${slot.participant?.given} ${slot.participant?.family}`}</span>
            </Tooltip>
          </ColorFilledCell>
        ))}
      />
    ),
  },
  {
    name: 'Shift',
    key: 'shift',
    verticalAlign: 'top',
    render: (item: IVisitTime) => (
      <ListCell
        items={getSlots(item.visitTimeShifts).map((slot) => (
          <ShiftNameCell
            name={slot.shift.name || ''}
            shiftId={slot.shift.id}
            sx={{ margin: '4px 0 5px' }}
          />
        ))}
      />
    ),
  },
  {
    name: 'Test time',
    key: 'testTime',
    verticalAlign: 'top',
    width: '150px',
    render: (item: IVisitTime) => (
      <ListCell
        items={item.visitTimeSlots.map((slot) => (
          <ColorFilledCell
            sx={{
              fontFamily: 'DM Mono, monospace',
              backgroundColor: 'transparent',
              fontSize: '14px',
              fontWeight: 500,
              textAlign: 'left',
              paddingLeft: 0,
              lineHeight: '21.8px',
              letterSpacing: '-0.14px',
              margin: '4px 0 4px',
            }}
          >
            <TimeRangeLabel
              start={DateTime.fromISO(slot.dateTime)
                .setZone(visit?.timezone || 'America/New_York')
                .toFormat('h:mm a')}
              end={DateTime.fromISO(slot.dateTime)
                .setZone(visit?.timezone || 'America/New_York')
                .plus({ minutes: item.testLength })
                .toFormat('h:mm a')}
            />
          </ColorFilledCell>
        ))}
      />
    ),
  },
  {
    name: '',
    key: 'actions',
    verticalAlign: 'top',
    width: '40px',
    render: (item: IVisitTime) => (
      <ListCell
        items={getSlots(item.visitTimeShifts).map((slot) => (
          <ContextMenu<VisitTimeSlot>
            actions={actions}
            item={slot}
            containerSxProps={{
              verticalAlign: 'top',
              padding: '0px 0px',
            }}
            buttonProps={{
              sx: {
                backgroundColor: 'rgba(202, 194, 190, 0.40);',
                width: '32px',
                height: '32px',
                borderRadius: '8px',
              },
            }}
          />
        ))}
      />
    ),
  },
];
