import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { DateTime } from 'luxon';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { api } from '../../../api';
import {
  EReportStatuses,
  IEquipmentSpecificationsAndNoiseLevelsReport,
} from '../../../api/services/organization/types';
import { AudiometerInfoItem } from './components';
import { EquipmentSpecificationsAndNoiseLevelsReportFormTable } from './components/equipment-specification-and-noise-levels-report-form-table';
import { Button, SimpleButton } from '../../common/button';

import { SignatureButton } from '../../common/button/SignatureButton';
import { VALIDATION_SCHEMA } from './constants';

interface IEquipmentSpecificationsAndNoiseLevelsReportValues
  extends Omit<
    IEquipmentSpecificationsAndNoiseLevelsReport,
    'id' | 'annualCalibration' | 'created_at' | 'updated_at'
  > {
  annualCalibration: DateTime | null;
  id?: number;
  signature?: string;
}

interface IEquipmentSpecificationAndNoiseLevelsReportFormProps {
  equipmentSpecificationReportId?: number;
  locationId: number;
  allowedSignature?: boolean;
}

export function EquipmentSpecificationAndNoiseLevelsReportForm({
  equipmentSpecificationReportId,
  locationId,
  allowedSignature,
}: IEquipmentSpecificationAndNoiseLevelsReportFormProps) {
  const [loading, setLoading] = useState(!!equipmentSpecificationReportId);
  const [initialValues, setInitialValues] =
    useState<IEquipmentSpecificationsAndNoiseLevelsReportValues>({
      locationId,
      status: EReportStatuses.Draft,
      audiometer: '',
      serialNumber: '',
      annualCalibration: null,
      decibels_1000: 0,
      decibels_2000: 0,
      decibels_4000: 0,
      decibels_500: 0,
      decibels_8000: 0,
      examiners: [
        { name: '', license: '' },
        { name: '', license: '' },
      ],
      level_1000: 0,
      level_2000: 0,
      level_4000: 0,
      level_500: 0,
      level_8000: 0,
      signature: '',
      name: `Equipment Specifications & Noise Levels Report (${DateTime.now().toFormat(
        'MM/dd/yyyy',
      )})`,
    });

  useEffect(() => {
    const getEditedReport = async () => {
      if (!equipmentSpecificationReportId) return;
      setLoading(true);
      try {
        const report =
          await api.organization.getEquipmentSpecificationAndNoiseLevelsReportById(
            equipmentSpecificationReportId,
          );

        setInitialValues({
          ...report,
          annualCalibration: report.annualCalibration
            ? DateTime.fromISO(report.annualCalibration)
            : null,
        });
      } catch (e) {
        console.log(e);
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)));
      } finally {
        setLoading(false);
      }
    };

    getEditedReport();
  }, [equipmentSpecificationReportId]);

  const documentViewingDateTime = useMemo(() => DateTime.now().toISO(), []);

  const createDraft = useCallback(
    async (values: IEquipmentSpecificationsAndNoiseLevelsReportValues) => {
      let result: IEquipmentSpecificationsAndNoiseLevelsReport;
      if (values.id) {
        result =
          await api.organization.updateEquipmentSpecificationAndNoiseLevelsReport(
            values.id,
            {
              ...values,
              annualCalibration: values.annualCalibration?.toISO() || null,
            },
          );
      } else {
        result =
          await api.organization.createEquipmentSpecificationAndNoiseLevelsReport(
            locationId,
            {
              ...values,
              annualCalibration: values.annualCalibration?.toISO() || null,
            },
          );
      }
      setInitialValues({
        ...result,
        annualCalibration: result.annualCalibration
          ? DateTime.fromISO(result.annualCalibration)
          : null,
      });
      return result;
    },
    [locationId],
  );

  const signDocument = useCallback(
    async (values: IEquipmentSpecificationsAndNoiseLevelsReportValues) => {
      try {
        const result = await createDraft(values);
        const signedDocument =
          await api.organization.signEquipmentSpecificationAndNoiseLevelsReport(
            result.id,
            {
              signature: values.signature || '',
              documentViewingDateTime: documentViewingDateTime as string,
            },
          );
        setInitialValues({
          ...result,
          annualCalibration: result.annualCalibration
            ? DateTime.fromISO(result.annualCalibration)
            : null,
          signedDocument,
        });
        showSnackbar(
          'Equipment specification and noise levels report successfully signed',
          {
            variant: 'success',
          },
        );
      } catch (e) {
        showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
          variant: 'error',
        });
      }
    },
    [locationId, documentViewingDateTime],
  );

  const formik = useFormik<IEquipmentSpecificationsAndNoiseLevelsReportValues>({
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      await signDocument(values);
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    getFieldProps,
  } = formik;

  const theme = useTheme();

  const handleDraftSubmit = useCallback(async () => {
    try {
      formik.setSubmitting(true);
      await createDraft(formik.values);
      showSnackbar('Draft successfully saved', {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(getErrorMessageComponent(getErrorMessage(e)), {
        variant: 'error',
      });
    } finally {
      formik.setSubmitting(false);
    }
  }, [formik, createDraft]);

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          handleSubmit();
        }
      }}
      container
      sx={{ width: '100%', margin: 0, pb: theme.spacing(4) }}
      spacing={theme.spacing(2)}
      direction="column"
    >
      <Grid sx={{ maxWidth: '350px', width: '100%', mb: 3 }}>
        <AudiometerInfoItem
          label="Report Name"
          inputProps={{
            ...getFieldProps('name'),
            helperText: touched.name ? errors.name : '',
          }}
        />
      </Grid>
      <Typography
        sx={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          marginBottom: theme.spacing(4),
        }}
      >
        Audiometers & Sound Level
      </Typography>
      <Grid
        container
        direction="column"
        gap={theme.spacing(2.5)}
        sx={{ mb: theme.spacing(4) }}
      >
        <Grid sx={{ maxWidth: '350px', width: '100%' }}>
          <AudiometerInfoItem
            label="Name And Model"
            inputProps={{
              ...getFieldProps('audiometer'),
              helperText: touched.audiometer ? errors.audiometer : '',
            }}
          />
        </Grid>
        <Grid sx={{ maxWidth: '350px', width: '100%' }}>
          <AudiometerInfoItem
            label="Serial Number"
            inputProps={{
              ...getFieldProps('serialNumber'),
              helperText: touched.serialNumber ? errors.serialNumber : '',
            }}
          />
        </Grid>
        <Grid sx={{ maxWidth: '350px', width: '100%' }}>
          <AudiometerInfoItem
            label="Annual Calibration"
            datePickerProps={{
              value: values.annualCalibration,
              onChange: (value) => {
                setFieldValue('annualCalibration', value);
              },
              errorMessage: touched.annualCalibration
                ? errors.annualCalibration
                : '',
            }}
            type="date"
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: theme.spacing(2) }} />
      <Typography
        sx={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          marginBottom: theme.spacing(2),
        }}
      >
        Background Noise Assessment:
      </Typography>
      <EquipmentSpecificationsAndNoiseLevelsReportFormTable
        loading={loading}
        formik={formik}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: theme.spacing(2) }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          Hearing test Examiners
        </Typography>
        {!values.signedDocument && (
          <Button
            endIcon={<AddIcon fontSize="inherit" />}
            onClick={() => {
              setFieldValue('examiners', [
                ...formik.values.examiners,
                { name: '', license: '' },
              ]);
            }}
          >
            Add
          </Button>
        )}
      </Grid>
      <Grid container sx={{ mb: theme.spacing(4) }} gap={theme.spacing(1)}>
        {formik.values.examiners?.map((examiner, index) => (
          <Grid container gap={theme.spacing(2)} alignItems="center">
            <Grid item>
              <AudiometerInfoItem
                label="Name"
                inputProps={{
                  value: examiner.name,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValues = _.cloneDeep(formik.values.examiners);
                    newValues.splice(index, 1, {
                      ...examiner,
                      name: event.target.value,
                    });
                    setFieldValue('examiners', newValues);
                  },
                  helperText:
                    (
                      touched.examiners?.[index] as {
                        name: boolean;
                        license: boolean;
                      }
                    )?.name &&
                    (
                      errors.examiners?.[index] as {
                        name: string;
                        license: string;
                      }
                    )?.name,
                }}
              />
            </Grid>
            <Grid item>
              <AudiometerInfoItem
                label="License"
                inputProps={{
                  value: examiner.license,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValues = _.cloneDeep(formik.values.examiners);
                    newValues.splice(index, 1, {
                      ...examiner,
                      license: event.target.value,
                    });
                    setFieldValue('examiners', newValues);
                  },
                  helperText:
                    (
                      touched.examiners?.[index] as {
                        name: boolean;
                        license: boolean;
                      }
                    )?.license &&
                    (
                      errors.examiners?.[index] as {
                        name: string;
                        license: string;
                      }
                    )?.license,
                }}
              />
            </Grid>
            <Grid item>
              <SimpleButton
                actionType="delete"
                disabled={formik.values.examiners?.length <= 2}
                onClick={() => {
                  const newValues = _.cloneDeep(formik.values.examiners);
                  newValues.splice(index, 1);
                  setFieldValue('examiners', newValues);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid container gap={2} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
        {!values.signedDocument && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading || formik.isSubmitting}
              onClick={handleDraftSubmit}
            >
              Save as Draft
            </Button>
          </Grid>
        )}
        {allowedSignature && (
          <Grid item>
            <SignatureButton
              disabled={loading || formik.isSubmitting}
              buttonTitle="Add signature"
              buttonProps={{ endIcon: <AddIcon fontSize="inherit" /> }}
              onSubmit={async (signature: string) => {
                await setFieldValue('signature', signature);
                handleSubmit();
              }}
              signedDocument={values.signedDocument || null}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
