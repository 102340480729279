import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useParams } from 'react-router-dom';
import { Button } from 'components/common/button';
import { ILocation } from 'api/services/organization/types';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { getErrorMessage, showSnackbar } from 'utils';
import { ConfirmationModal } from 'components/modal';
import { PageHeader } from 'components/admin-page-layout';
import { t } from 'i18next';
import { useOHC } from 'hooks/useOHC';
import { AssignLocationForm } from './components/location-form';
import { getCells } from './cells';
import { TableFilter } from '../../../components/table-filter';
import { getFilterConfig } from './utils';
import { RequestParams } from '../../../api/types';

export function AdminOhcLocationsList() {
  const { ohcId } = useParams();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState<string | string[]>('');
  const [actionedLocation, setActionedLocation] = useState<ILocation | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { OHC, isOHCLoading } = useOHC(ohcId ? Number(ohcId) : null);

  const fetchOhcLocations = useCallback(
    async (
      limit: number,
      offset: number,
      orderBy: string | undefined,
      orderDirection: 'ASC' | 'DESC' | undefined,
      search?: string,
      filterParams?: RequestParams,
    ) => {
      if (!ohcId) {
        return EMPTY_PAGINATED_DATA;
      }
      const locationsData = await api.user.getOhcLocations(ohcId, {
        limit,
        offset,
        orderBy,
        orderDirection,
        filterParams,
        search,
      });
      return locationsData;
    },
    [ohcId],
  );

  const tableState = useTableState<ILocation>({
    fetchDataFunction: fetchOhcLocations,
  });

  const assignLocationsToOhc = async (
    actionedLocationsIds: number[],
    action: 'assign' | 'delete',
  ) => {
    if (!ohcId) {
      return;
    }
    try {
      setFormLoading(true);
      const { ohc } = await api.user.getOhcById(ohcId);
      let locationsIds = ohc?.locations?.map((item) => item.id) || [];

      if (action === 'assign') {
        locationsIds = locationsIds?.concat(actionedLocationsIds);
      } else {
        const indexOfDeletedId = locationsIds?.indexOf(actionedLocationsIds[0]);
        if (indexOfDeletedId > -1) {
          locationsIds.splice(indexOfDeletedId, 1);
        }
        setConfirmationOpen(false);
        setActionedLocation(null);
      }

      await api.user.assignLocationsToOhc(ohcId, {
        locationsIds,
      });
      setOpen(false);
      tableState.refreshData();
      showSnackbar(
        `Location(s) successfully ${
          action === 'assign' ? 'assigned' : 'deleted'
        }`,
        {
          variant: 'success',
        },
      );
    } catch (e: any) {
      setFormError(getErrorMessage(e, 'Could not save form'));
    } finally {
      setFormLoading(false);
    }
  };

  const actions = [
    {
      title: 'Delete',
      onClick: (item: ILocation) => {
        setActionedLocation(item);
        setConfirmationOpen(true);
      },
    },
  ];

  const cells = useMemo(() => {
    if (ohcId) {
      return getCells(ohcId);
    }
    return [];
  }, [ohcId]);

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={t('common.locations')}
        entityName={`${`${OHC?.given} `}${OHC?.family}`}
        titleLoading={isOHCLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(true);
            }}
          >
            New
          </Button>
        }
      />

      <TableFilter
        config={getFilterConfig()}
        useQueryParams={false}
        onChange={(filterParams) => {
          tableState.setFilterParams(filterParams);
        }}
      />

      <BasicTable<ILocation>
        useQueryParams={false}
        cells={cells}
        tableState={tableState}
        actions={actions}
      />

      {open && ohcId && (
        <AssignLocationForm
          ohcId={ohcId}
          onSubmit={assignLocationsToOhc}
          handleClose={() => {
            setOpen(false);
            setFormError('');
            setFormLoading(false);
          }}
          loading={formLoading}
          error={formError}
        />
      )}

      {actionedLocation && (
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={() => {
            setConfirmationOpen(false);
            setActionedLocation(null);
          }}
          handleSubmit={() =>
            assignLocationsToOhc([actionedLocation.id], 'delete')
          }
          submitButtonTitle="Delete"
          title={
            <>
              {`You are about to delete the ${actionedLocation.name} location`}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
