import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

export function CustomLegend(props: any) {
  const { payload } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const renderIcon = useCallback(
    (entry: Payload) => (
      <img
        src={`/images/icons/${
          entry.value === 'Left Ear'
            ? 'left-ear-marker.svg'
            : 'right-ear-marker.svg'
        }`}
        alt="right ear-marker"
        width={10}
        height={10}
        style={
          isMobileView
            ? { marginRight: theme.spacing(0.5) }
            : { marginBottom: theme.spacing(0.5) }
        }
      />
    ),
    [isMobileView],
  );
  return (
    <Grid
      container
      direction={isMobileView ? 'row-reverse' : 'column-reverse'}
      flexWrap="nowrap"
      justifyContent={isMobileView ? 'center' : 'flex-start'}
      mr={isMobileView ? 0 : theme.spacing(8)}
      mt={isMobileView ? theme.spacing(2) : 0}
    >
      {payload
        ?.filter((item: any) => ['Left Ear', 'Right Ear'].includes(item.value))
        .map((entry: any, index: number) => (
          <Grid
            container
            item
            key={entry.value}
            direction={isMobileView ? 'row' : 'column'}
            alignItems="center"
            mb={index !== 0 && !isMobileView ? theme.spacing(3) : 0}
          >
            {renderIcon(entry)}
            <Typography
              variant="mono"
              sx={{ fontSize: '9px', textTransform: 'uppercase' }}
            >
              {entry.value}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
}
