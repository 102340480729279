import { InfoOutlined } from '@mui/icons-material';
import {
  DefaultComponentProps,
  OverridableTypeMap,
} from '@mui/material/OverridableComponent';

import { ReactNode } from 'react';
import { Tooltip } from '../tooltip';

interface InfoIconProps extends DefaultComponentProps<OverridableTypeMap> {
  content: ReactNode;
  size?: 'small' | 'medium' | 'large';
}

export function InfoIcon({ content, size = 'small', ...props }: InfoIconProps) {
  return (
    <Tooltip title={content} enterTouchDelay={0}>
      <InfoOutlined fontSize={size} {...props} />
    </Tooltip>
  );
}
