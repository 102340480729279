import React from 'react';
import { BasicContainer } from 'components/container';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'components/admin-page-layout';
import { DailyBiologicalReportForm } from 'components/forms/daily-biological-report-form';

export function OhcCreateDailyBiologicalReportPage() {
  const { locationId } = useParams();

  return (
    <BasicContainer>
      <PageHeader title="New Daily Biological, Calibration and Listening Check Report" />
      <DailyBiologicalReportForm locationId={Number(locationId)} />
    </BasicContainer>
  );
}
