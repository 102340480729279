import { DependencyList, useEffect } from 'react';
import { useSearchParamHandlers } from './useSearchParamHandlers';

export type UrlParamOpts<ValueT extends string = string> = {
  setNewValue?: (value: ValueT) => unknown;
  paramName: string;
  initialValue?: ValueT;
  validationList?: string[];
};

export const useUrlParam = <ValueT extends string = string>(
  paramData: UrlParamOpts<ValueT>,
  paramSetDeps: DependencyList = [],
) => {
  const { searchParams, setSearchParam } = useSearchParamHandlers();

  useEffect(() => {
    const { paramName, initialValue, validationList, setNewValue } = paramData;

    const currentValueFromUrl = searchParams.get(paramName);

    if (initialValue && initialValue !== currentValueFromUrl)
      setSearchParam(paramName, initialValue);
    else if (currentValueFromUrl) {
      if (
        !validationList ||
        validationList.includes(currentValueFromUrl as ValueT)
      )
        setNewValue?.(currentValueFromUrl as ValueT);
    } else {
      setSearchParam(paramName, null);
    }
  }, paramSetDeps);

  return {
    setSearchParam,
  };
};
