import { PaginatedData } from 'common/types';
import { BoolSuccessResponse, PaginatedRequestParams } from 'api/types';
import axios from '../../axios';
import {
  AssignLocationsBody,
  HCPSupervisorBody,
  HCPSupervisorUser,
  InvitationToken,
  OhcBody,
  Participant,
  SiteManagerBody,
  User,
  MfaMethod,
} from '../auth/types';
import {
  IProfessionalSupervisorLicenseStateBody,
  IProfessionalSupervisorBody,
  GetSiteManagerParams,
  IAccountManagerBody,
} from './types';
import { ILocation, Organization } from '../organization/types';

export class UserAPI {
  async identity() {
    const { data } = await axios.get('/user');

    return data as User;
  }

  async getMfaVerificationCode(method: MfaMethod, identifier: string) {
    const { data } = await axios.post<BoolSuccessResponse>(
      `/user/${method}/verification-code`,
      { [method]: identifier },
    );

    return data;
  }

  async verifyMfaVerificationCode(method: MfaMethod, code: string) {
    const { data } = await axios.post<BoolSuccessResponse>(
      `/user/${method}/verify`,
      { code },
    );

    return data;
  }

  async getInvitationToken(id: number) {
    const { data } = await axios.post(`/user/${id}/sign-up-token`);

    return data as InvitationToken;
  }

  async createSiteManager(body: SiteManagerBody) {
    const { data } = await axios.post('/user/site-manager', body);

    return data as User;
  }

  async updateSiteManager(id: number, body: SiteManagerBody) {
    const { data } = await axios.put(`/user/${id}/site-manager`, body);

    return data as User;
  }

  async getSiteManagers(limit = 10, offset = 0, params?: GetSiteManagerParams) {
    const { data } = await axios.get(`/user/site-manager`, {
      params: { limit, offset, ...params },
    });

    return data as PaginatedData<User>;
  }

  async sendSiteManagerInvitation(id: number) {
    const { data } = await axios.post(`/user/${id}/site-manager/invite`);

    return data as InvitationToken;
  }

  async createOhc(body: OhcBody) {
    const { data } = await axios.post('/user/ohc', body);

    return data as User;
  }

  async updateOhc(id: number, body: OhcBody) {
    const { data } = await axios.put(`/user/${id}/ohc`, body);

    return data as User;
  }

  async getOhcById(id: number | string) {
    const { data } = await axios.get(`/user/${id}/ohc`);

    return data as User;
  }

  async getOhcs(limit = 10, offset = 0) {
    const { data } = await axios.get(
      `/user/ohc?limit=${limit}&offset=${offset}`,
    );

    return data as PaginatedData<User>;
  }

  async getProfessionalSupervisors(
    params: PaginatedRequestParams & { locationId?: number },
  ) {
    const { limit = 10, offset = 0, ...rest } = params;
    const { data } = await axios.get(`/user/professional-supervisor`, {
      params: {
        limit,
        offset,
        ...rest,
      },
    });
    return data as PaginatedData<User>;
  }

  async sendProfessionalSupervisorInvitation(id: number) {
    const { data } = await axios.post(
      `/user/${id}/professional-supervisor/invite`,
    );

    return data as InvitationToken;
  }

  async sendHCPSupervisorInvitation(id: number) {
    const { data } = await axios.post(`/user/${id}/hcp-supervisor/invite`);

    return data as InvitationToken;
  }

  async deactivateHCPSupervisorInvitation(id: number) {
    const { data } = await axios.post(`/user/${id}/hcp-supervisor/deactivate`);

    return data as InvitationToken;
  }

  async getOhcLocations(
    id: number | string,
    {
      limit = 10,
      offset = 0,
      orderBy,
      orderDirection,
      search,
      filterParams = {},
    }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(`/user/${id}/ohc/locations`, {
      params: {
        limit,
        offset,
        search,
        orderBy,
        orderDirection,
        ...filterParams,
      },
    });

    return data as PaginatedData<ILocation>;
  }

  async assignLocationsToOhc(id: number | string, body: AssignLocationsBody) {
    const { data } = await axios.post(`/user/${id}/ohc/assign-locations`, body);

    return data as User;
  }

  async getProfessionalSupervisorById(id: number | string) {
    const { data } = await axios.get(`/user/${id}/professional-supervisor`);
    return data as User;
  }

  async createProfessionalSupervisor(
    body: Pick<IProfessionalSupervisorBody, 'given' | 'middleName' | 'family'>,
  ) {
    const { data } = await axios.post(`/user/professional-supervisor`, body);
    return data as User;
  }

  async updateProfessionalSupervisor(
    id: number,
    body: Partial<IProfessionalSupervisorBody>,
  ) {
    const { data } = await axios.put(
      `/user/${id}/professional-supervisor`,
      body,
    );
    return data as User;
  }

  async updateProfessionalSupervisorLicenses(
    id: number,
    body: { licenseStates: IProfessionalSupervisorLicenseStateBody[] },
  ) {
    const { data } = await axios.post(
      `/user/${id}/professional-supervisor/license-states`,
      body,
    );
    return data as User;
  }

  async getParticipants(limit = 10, offset = 0) {
    const { data } = await axios.get(
      `/participant?limit=${limit}&offset=${offset}`,
    );
    return data as PaginatedData<Participant>;
  }

  async getParticipantById(id: number) {
    const { data } = await axios.get(`/participant/${id}`);
    return data as Participant;
  }

  async getHCPSupervisors(limit = 10, offset = 0) {
    const { data } = await axios.get(
      `/user/hcp-supervisor?limit=${limit}&offset=${offset}`,
    );
    return data as PaginatedData<HCPSupervisorUser>;
  }

  async createHCPSupervisor(body: HCPSupervisorBody) {
    const { data } = await axios.post('/user/hcp-supervisor', body);

    return data as User;
  }

  async updateHCPSupervisor(id: number, body: HCPSupervisorBody) {
    const { data } = await axios.put(`/user/${id}/hcp-supervisor`, body);

    return data as User;
  }

  async getSiteManagerLocations(
    id: number | string,
    { limit = 10, offset = 0, search }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(`/user/${id}/site-manager/locations`, {
      params: {
        limit,
        offset,
        search,
      },
    });

    return data as PaginatedData<ILocation>;
  }

  async getProfessionalSupervisorLocations(
    id: number | string,
    { limit = 10, offset = 0, ...rest }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/user/${id}/professional-supervisor/locations`,
      {
        params: {
          limit,
          offset,
          ...rest,
        },
      },
    );

    return data as PaginatedData<ILocation>;
  }

  toggleActive = async (id: number, body: { active: boolean }) => {
    const { data } = await axios.post(`/user/${id}/toggle-active`, body);

    return data as User;
  };

  async getAccountManagers(params: PaginatedRequestParams) {
    const { limit = 10, offset = 0, ...rest } = params;
    const { data } = await axios.get(`/user/account-manager`, {
      params: {
        limit,
        offset,
        ...rest,
      },
    });
    return data as PaginatedData<User>;
  }

  async getAccountManagerById(id: number | string) {
    const { data } = await axios.get(`/user/${id}/account-manager`);

    return data as User;
  }

  async createAccountManager(
    body: Pick<IAccountManagerBody, 'given' | 'middleName' | 'family'>,
  ) {
    const { data } = await axios.post(`/user/account-manager`, body);
    return data as User;
  }

  async updateAccountManager(id: number, body: Partial<IAccountManagerBody>) {
    const { data } = await axios.put(`/user/${id}/account-manager`, body);
    return data as User;
  }

  async sendAccountManagerInvitation(id: number) {
    const { data } = await axios.post(`/user/${id}/account-manager/invite`);

    return data as InvitationToken;
  }

  async getAccountManagerOrganizations(
    id: number | string,
    { limit = 10, offset = 0, ...rest }: PaginatedRequestParams,
  ) {
    const { data } = await axios.get(
      `/user/${id}/account-manager/organizations`,
      {
        params: {
          limit,
          offset,
          ...rest,
        },
      },
    );

    return data as PaginatedData<Organization>;
  }
}
