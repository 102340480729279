import { useCallback, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import { ConfirmationModal } from 'components/modal';
import { IVisit } from 'api/services/organization/types';
import { Button } from 'components/common/button';
import { PageHeader } from 'components/admin-page-layout';
import { useLocation } from 'hooks/useLocation';
import { cells } from './cells';
import { VisitFormModal } from './components/visit-form';

export function LocationVisitsList() {
  const navigate = useNavigate();
  const { organizationId, locationId } = useParams();
  const [formOpen, setFormOpen] = useState(false);
  const [confirmationState, setConfirmationState] = useState<{
    open: boolean;
    type: 'delete' | 'share' | 'cancel_sharing' | null;
  }>({ open: false, type: null });
  const [editedVisit, setEditedVisit] = useState<IVisit | null>(null);

  const { location, isLocationLoading } = useLocation();

  const fetchVisits = useCallback(async (limit: number, offset: number) => {
    if (!locationId) {
      return EMPTY_PAGINATED_DATA;
    }
    const organizationsData = await api.organization.getLocationVisits(
      locationId,
      { limit, offset },
    );
    return organizationsData;
  }, []);

  const tableState = useTableState<IVisit>({ fetchDataFunction: fetchVisits });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteVisit = async (visit: IVisit) => {
    if (!locationId) {
      return;
    }
    try {
      // feature in development
      // await api.organization.deleteLocationVisit(locationId, shift.id);
      tableState.refreshData();
      showSnackbar('Visit successfully deleted', {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(getErrorMessage(e, 'Could not delete Visit')),
        {
          variant: 'error',
        },
      );
    }
  };

  const shareVisit = async (visit: IVisit) => {
    if (!locationId) {
      return;
    }
    try {
      await api.organization.updateLocationVisit(locationId, visit.id, {
        shared: true,
      });
      tableState.refreshData();
      showSnackbar('Visit successfully shared', {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not share the Visit'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };
  const cancelVisitSharing = async (visit: IVisit) => {
    if (!locationId) {
      return;
    }
    try {
      await api.organization.updateLocationVisit(locationId, visit.id, {
        shared: false,
      });
      tableState.refreshData();
      showSnackbar('Successfully cancelled the sharing of the Visit', {
        variant: 'success',
      });
    } catch (e: any) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not cancel the sharing of the Visit'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  const actions = [
    {
      title: 'Edit',
      onClick: (visit: IVisit) => {
        setEditedVisit(visit);
        setFormOpen(true);
      },
    },
    {
      title: (visit: IVisit) => (visit.shared ? 'Cancel Sharing' : 'Share'),
      onClick: (visit: IVisit) => {
        setEditedVisit(visit);
        setConfirmationState({
          open: true,
          type: visit.shared ? 'cancel_sharing' : 'share',
        });
      },
    },
    {
      title: 'Delete',
      onClick: (visit: IVisit) => {
        setEditedVisit(visit);
        setConfirmationState({ open: true, type: 'delete' });
      },
      disabled: true,
    },
  ];

  const confirmationModalAction = useCallback(
    (visit: IVisit) => async () => {
      if (!confirmationState.type) return;
      if (confirmationState.type === 'delete') {
        await deleteVisit(visit);
      } else if (confirmationState.type === 'share') {
        await shareVisit(visit);
      } else if (confirmationState.type === 'cancel_sharing') {
        await cancelVisitSharing(visit);
      }

      setConfirmationState({ open: false, type: null });
      setEditedVisit(null);
    },
    [confirmationState],
  );

  const confirmationModalSubmitButtonTitle = useMemo(() => {
    if (!confirmationState.type) return '';
    if (confirmationState.type === 'delete') {
      return 'Delete';
    }
    if (confirmationState.type === 'share') {
      return 'Share';
    }
    if (confirmationState.type === 'cancel_sharing') {
      return 'Cancel sharing';
    }
    return '';
  }, [confirmationState]);

  const confirmationModalTitle = useMemo(() => {
    if (!confirmationState.type || !editedVisit) return '';
    if (confirmationState.type === 'delete') {
      return `You are about to delete the ${editedVisit.name} Visit`;
    }
    if (confirmationState.type === 'share') {
      return `You are about to share the ${editedVisit.name} Visit`;
    }
    if (confirmationState.type === 'cancel_sharing') {
      return `You are about to cancel the sharing of the ${editedVisit.name} Visit`;
    }
    return '';
  }, [confirmationState, editedVisit]);

  return (
    <BasicContainer>
      <PageHeader
        entityTitle="Visits"
        entityName={location?.name}
        titleLoading={isLocationLoading}
        buttons={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setEditedVisit(null);
              setFormOpen(true);
            }}
          >
            New
          </Button>
        }
      />

      <BasicTable<IVisit>
        cells={cells(setEditedVisit, setConfirmationState)}
        tableState={tableState}
        actions={actions}
        onRowClicked={(item) => {
          navigate(
            `/admin/organizations/${organizationId}/locations/${locationId}/visits/${item.id}/visit`,
          );
        }}
      />
      {formOpen && locationId && (
        <VisitFormModal
          handleClose={() => {
            setFormOpen(false);
            setEditedVisit(null);
          }}
          visit={editedVisit}
          locationId={locationId}
          handleSubmit={() => {
            setFormOpen(false);
            setEditedVisit(null);
            if (editedVisit) {
              tableState.reloadData();
            } else {
              tableState.refreshData();
            }
          }}
        />
      )}

      {editedVisit && (
        <ConfirmationModal
          open={confirmationState.open}
          handleClose={() => {
            setConfirmationState({ open: false, type: null });
            setEditedVisit(null);
          }}
          handleSubmit={confirmationModalAction(editedVisit)}
          submitButtonTitle={confirmationModalSubmitButtonTitle}
          title={
            <>
              {confirmationModalTitle}
              <br />
              Are you sure?
            </>
          }
        />
      )}
    </BasicContainer>
  );
}
