import { useState } from 'react';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from 'api';
import { FullHeightContainer } from 'components/container';
import { MfaMethod } from 'api/services/auth/types';
import { Button } from 'components/common/button';
import { PhoneInput } from 'components/common/input/PhoneInput';
import { FormAlert } from 'components/alert';
import {
  convertPhoneToInternationalFormat,
  convertPhoneToTenDigitsFormat,
} from 'utils/phoneNumber';
import { getErrorMessageComponent } from 'utils';
import { getPhoneSchema } from 'utils/validation/validators';
import { Loader } from 'components/common/loader';
import { VerificationHeaderText } from '../components/Shared';

const initialValues = {
  phone: '',
};

const ConfirmCodeSchema = Yup.object().shape({
  phone: getPhoneSchema().required('Required'),
});

export type SendPhoneVerificationStepProps = {
  phone?: string;
  onGoToNextStep: (phone: string) => void;
};

export function SendPhoneVerificationStep({
  phone,
  onGoToNextStep,
}: SendPhoneVerificationStepProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    try {
      const phone = convertPhoneToInternationalFormat(values.phone);
      await api.user.getMfaVerificationCode(MfaMethod.PHONE, phone);
      onGoToNextStep(values.phone);
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message);
    }
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        phone: phone ? convertPhoneToTenDigitsFormat(phone) : '',
      }}
      validationSchema={ConfirmCodeSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <FullHeightContainer maxWidth="xs" disableGutters>
          <Grid
            component="form"
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleSubmit();
              }
            }}
            onSubmit={handleSubmit}
            container
            spacing={0.5}
          >
            <VerificationHeaderText />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid item xs={12}>
                  <PhoneInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ' '
                    }
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      {error ? (
                        <FormAlert severity="error">
                          {getErrorMessageComponent(error)}
                        </FormAlert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </FullHeightContainer>
      )}
    </Formik>
  );
}
