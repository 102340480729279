import { Typography, TypographyProps, styled } from '@mui/material';

const StyledTypography = styled(Typography)({
  fontWeight: 700,
});

export function BoldText(props: TypographyProps) {
  const { children, ...otherProps } = props;
  return (
    <StyledTypography component="span" {...otherProps}>
      {children}
    </StyledTypography>
  );
}
