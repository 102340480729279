import { SimpleButton } from 'components/common/button';
import { Grid } from '@mui/material';
import { Action } from '../BasicTable';

interface IIconActionsProps<T> {
  actions: Action<T>[];
  item: T;
}

export function IconActionsCell<T>(props: IIconActionsProps<T>) {
  const { actions, item } = props;

  return (
    <Grid container justifyContent="flex-end" gap={1}>
      {actions.map((action) => {
        const disabled =
          typeof action.disabled === 'boolean'
            ? action.disabled
            : action.disabled?.(item);

        if (!action.actionType || disabled) {
          return null;
        }

        return (
          <SimpleButton
            key={action.actionType}
            actionType={action.actionType}
            onClick={() => action.onClick?.(item)}
            tooltipText={
              typeof action.title === 'string'
                ? action.title
                : action.title?.(item)
            }
          />
        );
      })}
    </Grid>
  );
}
