import { FullHeightContainer } from 'components/container';
import { FormModal } from 'components/modal';
import { IVisitTime } from 'api/services/organization/types';
import { useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { FormAlert } from 'components/alert';
import { useFormik } from 'formik';
import { Select } from 'components/common/input';
import { DateTime } from 'luxon';
import { IOption } from 'common/types/common';

const validationSchema = Yup.object().shape({
  visitTimeId: Yup.number().required('Required'),
  shiftId: Yup.number().required('Required'),
  startTime: Yup.string().required('Required'),
});

interface Values {
  visitTimeId: number | null;
  shiftId: number | null;
  startTime: string;
}

interface BookFormProps {
  title: string;
  locationId: string;
  visitId: string;
  visitTimes: IVisitTime[];
  handleClose: () => void;
  handleSubmit: (values: {
    visitTimeId: number | null;
    shiftId: number | null;
    startTime: string;
  }) => void;
  error: string | string[];
  timezone: string;
}

export function BookForm(props: BookFormProps) {
  const {
    handleSubmit,
    handleClose,
    locationId,
    visitId,
    visitTimes,
    title,
    error,
    timezone,
  } = props;
  const [slotOptions, setSlotOptions] = useState<IOption[]>([]);
  const initialValues: Values = {
    visitTimeId: null,
    shiftId: null,
    startTime: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const visitTimesOptions = visitTimes.map((visitTime) => ({
    label: `${DateTime.fromISO(visitTime.startTime).toFormat(
      'ccc, LLL dd, y',
    )} (${DateTime.fromISO(visitTime.startTime)
      .setZone(timezone)
      .toFormat('h:mm a')} - ${DateTime.fromISO(visitTime.endTime)
      .setZone(timezone)
      .toFormat('h:mm a')})`,
    value: visitTime.id,
  }));

  const shiftOptions = useMemo(() => {
    if (formik.values.visitTimeId) {
      const visitTimeIndex = visitTimes.findIndex(
        (visitTime) => visitTime.id === formik.values.visitTimeId,
      );
      return visitTimes[visitTimeIndex].visitTimeShifts?.map((shift) => ({
        label: shift.shift.name,
        value: shift.id,
      }));
    }
    return [];
  }, [formik.values.visitTimeId]);

  const getSlots = async () => {
    if (!locationId || !visitId || !formik.values.visitTimeId) {
      return;
    }
    try {
      const slots = await api.organization.getVisitTimeSlots(
        locationId,
        visitId,
        formik.values.visitTimeId,
      );
      setSlotOptions(
        slots.map((slot) => ({
          label: DateTime.fromISO(slot.start)
            .setZone(timezone)
            .toFormat('h:mm a'),
          value: slot.start,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!formik.values.visitTimeId && visitTimes.length === 1) {
      formik.setFieldValue('visitTimeId', Number(visitTimes[0]?.id));
    }
  }, [visitTimes]);

  useEffect(() => {
    getSlots();
    if (
      !formik.values.shiftId &&
      !!shiftOptions.length &&
      shiftOptions.length === 1
    ) {
      formik.setFieldValue('shiftId', shiftOptions[0]?.value);
    }
  }, [formik.values.visitTimeId]);

  return (
    <FormModal
      open
      handleClose={handleClose}
      handleSubmit={formik.handleSubmit}
      title={title}
      isSubmitting={formik.isSubmitting}
    >
      <FullHeightContainer maxWidth="xs" disableGutters>
        <Grid
          component="form"
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              formik.handleSubmit();
            }
          }}
          onSubmit={formik.handleSubmit}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <Select
              fullWidth
              label="Visit"
              options={visitTimesOptions}
              displayEmpty
              inputLabelProps={
                formik.values.visitTimeId ? { shrink: true } : {}
              }
              {...(formik.values.visitTimeId ? { notched: true } : {})}
              {...formik.getFieldProps('visitTimeId')}
              onChange={(e) =>
                formik.setFieldValue('visitTimeId', Number(e.target.value))
              }
              errorMessage={
                formik.touched.visitTimeId && formik.errors.visitTimeId
                  ? formik.errors.visitTimeId
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              fullWidth
              label="Shift"
              options={shiftOptions}
              displayEmpty
              inputLabelProps={formik.values.shiftId ? { shrink: true } : {}}
              {...(formik.values.shiftId ? { notched: true } : {})}
              {...formik.getFieldProps('shiftId')}
              onChange={(e) =>
                formik.setFieldValue('shiftId', Number(e.target.value))
              }
              disabled={!formik.values.visitTimeId}
              errorMessage={
                formik.touched.shiftId && formik.errors.shiftId
                  ? formik.errors.shiftId
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              fullWidth
              label="Time"
              options={slotOptions}
              displayEmpty
              {...formik.getFieldProps('startTime')}
              onChange={(e) =>
                formik.setFieldValue('startTime', e.target.value)
              }
              disabled={!formik.values.visitTimeId}
              errorMessage={
                formik.touched.startTime && formik.errors.startTime
                  ? formik.errors.startTime
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {error ? <FormAlert severity="error">{error}</FormAlert> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FullHeightContainer>
    </FormModal>
  );
}
