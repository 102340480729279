export enum QuestionTypes {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  TEXT = 'TEXT',
}

export interface QuestionChoice {
  id: number;
  label: string;
}

export interface IQuestion {
  mandatory: boolean;
  questionId: number;
  questionText: string;
  questionType: QuestionTypes;
  choices: QuestionChoice[];
  answer?: AnswerType;
}

export interface IQuestionnaire {
  id: number;
  name: string;
  resourceType: string;
  description: string;
  questions: IQuestion[];
  created_at: string;
  updated_at: string;
}

export interface Answer {
  questionId: number | null;
  questionType:
    | typeof QuestionTypes.SINGLE_CHOICE
    | typeof QuestionTypes.TEXT
    | null;
  answer: AnswerType | string | null;
}

export interface IQuestionnaireState {
  questionnaire: IQuestionnaire | null;
  answers: Answer[];
}

export interface AnswerType {
  choiceId: number;
  choiceLabel: string;
}

export interface IQuestionnaireResults {
  id: number;
  visitTimeId: number;
  participantId: number;
  questionnaireId: number;
  questionnaire: IQuestionnaire;
  answers: Answer[];
  created_at: string;
  updated_at: string;
}
