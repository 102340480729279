import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'api';
import { BasicContainer } from 'components/container';
import BasicTable from 'components/table/BasicTable';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useTableState } from 'hooks/useTableState';
import { EMPTY_PAGINATED_DATA } from 'common/types';
import { Button, FileUploadButton } from 'components/common/button';
import { Upload } from 'api/services/uploads/types';
import _ from 'lodash';
import { getErrorMessage, getErrorMessageComponent, showSnackbar } from 'utils';
import FileDownload from 'js-file-download';
import { PageHeader } from 'components/admin-page-layout';
import { useLocation as useOrganizationLocation } from 'hooks/useLocation';
import { useOrganization } from 'hooks/useOrganization';
import { getCells } from './cells';
import axios from '../../../api/axios';
import { HearingTestResultsUploadModal } from '../../../components/modal/HearingTestResultsUploadModal';

export enum UploadType {
  Roster = 'roster',
  TestResult = 'test-result',
}

export function Uploads() {
  const { organizationId, locationId } = useParams();
  const location = useLocation();
  const { organization, isOrganizationLoading } = useOrganization(
    organizationId ? Number(organizationId) : null,
  );
  const { location: organizationLocation, isLocationLoading } =
    useOrganizationLocation();
  const [file, setFile] = useState<File | null>(null);

  const uploadType: UploadType = useMemo(() => {
    if (location.pathname.includes('roster-uploads')) {
      return UploadType.Roster;
    }
    return UploadType.TestResult;
  }, [location.pathname]);

  const fetchUploads = useCallback(
    async (limit: number, offset: number) => {
      if (!organizationId) {
        return EMPTY_PAGINATED_DATA;
      }
      const params = {
        limit,
        offset,
        organizationId,
        locationId,
      };
      const uploadsData =
        uploadType === UploadType.Roster
          ? await api.uploads.getRosterUploads(params)
          : await api.uploads.getTestResultUploads(params);
      return uploadsData;
    },
    [organizationId, uploadType],
  );

  const downloadTemplateHandler = useCallback(async () => {
    try {
      const fileName =
        uploadType === UploadType.Roster
          ? 'STAR_Roster_Template.csv'
          : 'STAR_Hearing_Test_Results_Template.csv';
      const { data } = await axios.get(`${window.location.origin}/${fileName}`);
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a template'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }, [uploadType]);

  const downloadFileHandler = useCallback(async (upload: Upload) => {
    try {
      const fileName = upload.file.name;
      const data = await api.uploads.downloadFile(upload.id);
      FileDownload(data, fileName);
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not download a file'),
        ),
        {
          variant: 'error',
        },
      );
    }
  }, []);

  const tableState = useTableState<Upload>({
    fetchDataFunction: fetchUploads,
  });

  const upload = async () => {
    if (!file || !organizationId) {
      return;
    }
    try {
      tableState.setData((prev) => {
        const data = _.cloneDeep(prev) as any;
        data.unshift({ file: { name: file.name }, status: 'Uploading' });
        return data;
      });
      tableState.handlePageChange(0);
      const body = new FormData();
      body.append('organizationId', organizationId);

      if (uploadType === UploadType.Roster) {
        body.append('roster', file);
        if (locationId) {
          body.append('locationId', locationId);
        }
        await api.uploads.uploadRoster(body);
      }

      setFile(null);
      showSnackbar(`File successfully uploaded`, {
        variant: 'success',
      });
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not update licenses'),
        ),
        {
          variant: 'error',
        },
      );
    } finally {
      tableState.refreshData();
    }
  };

  const processUpload = async (uploadId: number) => {
    if (!uploadId) {
      return;
    }
    try {
      await api.uploads.processUpload(uploadId);
      showSnackbar(`Upload process successfully started`, {
        variant: 'success',
      });
      tableState.reloadData();
    } catch (e) {
      showSnackbar(
        getErrorMessageComponent(
          getErrorMessage(e, 'Could not started upload process'),
        ),
        {
          variant: 'error',
        },
      );
    }
  };

  useEffect(() => {
    if (file) {
      upload();
    }
  }, [file]);

  const actions = _.compact([
    {
      title: 'Process Upload',
      onClick: (upload: Upload) => processUpload(upload.id),
      disabled: (upload: Upload) => upload.status !== 'Unattempted',
    },
    uploadType === UploadType.Roster
      ? {
          title: 'Download',
          onClick: (upload: Upload) => downloadFileHandler(upload),
        }
      : null,
  ]);

  const cells = useMemo(() => {
    if (uploadType) {
      return getCells(uploadType);
    }
    return [];
  }, [uploadType]);

  const [uploadModalOpened, setUploadModelOpened] = useState(false);

  const handleUploadModalOpen = useCallback(() => {
    setUploadModelOpened(true);
  }, []);

  const handleUploadModalClose = useCallback(() => {
    setUploadModelOpened(false);
  }, []);

  const handleHearingTestUploadSuccess = useCallback(() => {
    setUploadModelOpened(false);
    tableState.refreshData();
  }, [tableState]);

  return (
    <BasicContainer>
      <PageHeader
        entityTitle={`${
          uploadType === UploadType.Roster
            ? 'Rosters '
            : 'Hearing Test Results '
        }
        Uploads`}
        entityName={`${organization?.name ? `${organization.name}` : ''}
        ${organizationLocation?.name ? ` ${organizationLocation.name}` : ''}`}
        titleLoading={isOrganizationLoading || isLocationLoading}
        buttons={
          <>
            {uploadType === UploadType.Roster ? (
              <Grid item>
                <Link
                  to="https://docs.google.com/spreadsheets/d/1zp4jaNJ8S0zge133eJFm62XKNrK_Cg2E/edit?usp=sharing&ouid=100540254876419919804&rtpof=true&sd=true"
                  target="_blank"
                >
                  <Typography>Columns Definition</Typography>
                </Link>
              </Grid>
            ) : null}
            <Grid item>
              <Button onClick={downloadTemplateHandler}>
                Download Template
              </Button>
            </Grid>
            <Grid item>
              {uploadType === UploadType.Roster ? (
                <FileUploadButton
                  onFilesChosen={(files) => {
                    setFile(files);
                  }}
                  buttonProps={{
                    variant: 'contained',
                    color: 'secondary',
                  }}
                  inputProps={{
                    accept: '.csv',
                  }}
                >
                  Upload new csv
                </FileUploadButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUploadModalOpen}
                >
                  Upload new csv
                </Button>
              )}
            </Grid>
          </>
        }
      />

      <BasicTable<Upload>
        cells={cells}
        tableState={tableState}
        actions={actions}
      />
      {uploadModalOpened && (
        <HearingTestResultsUploadModal
          opened={uploadModalOpened}
          onClose={handleUploadModalClose}
          onSubmit={handleHearingTestUploadSuccess}
          organizationId={Number(organizationId)}
        />
      )}
    </BasicContainer>
  );
}
