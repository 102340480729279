import { User } from 'api/services/auth/types';
import { StatusCell } from 'components/table/components';
import { Cell } from 'components/table/types';

export const cells: Cell<User>[] = [
  {
    name: 'ID',
    key: 'id',
  },
  {
    name: 'External Id',
    key: 'externalId',
  },
  {
    name: 'First Name',
    key: 'given',
  },
  {
    name: 'Last Name',
    key: 'family',
  },
  {
    name: 'Middle Name',
    key: 'middleName',
  },

  {
    name: 'Phone',
    key: 'phone',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Status',
    key: 'status',
    render: (item: User) => <StatusCell status={item.invitationStatus} />,
  },
];
