import { Typography, Dialog, Grid, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { DatePickerProps } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker } from '../common/input';
import { Button } from '../common/button';

interface IDatePickerModalProps {
  opened: boolean;
  onClose: () => void;
  onSelect: (date: DateTime | null) => Promise<void> | void;
  pickerProps?: Partial<DatePickerProps<DateTime>>;
  validationSchema?: Yup.Schema;
  date: DateTime | null;
  title?: string;
}

const DEFAULT_VALIDATION = Yup.object().shape({
  date: Yup.date()
    .typeError('invalid date')
    .nullable('required')
    .required('required'),
});

export function DatePickerModal(props: IDatePickerModalProps) {
  const {
    opened,
    onClose,
    onSelect,
    date = null,
    pickerProps = {},
    title = 'Select a date',
    validationSchema,
  } = props;

  const initialValues = useMemo(
    () => ({
      date,
    }),
    [date],
  );

  const {
    values,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema || DEFAULT_VALIDATION,
    onSubmit: async ({ date }, { resetForm }) => {
      try {
        await onSelect(date);
        onClose();
        resetForm();
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const theme = useTheme();
  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="xs" fullWidth>
      <Grid p={theme.spacing(3)}>
        <Grid mb={theme.spacing(2)}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid container direction="column">
          <Grid item mb={theme.spacing(1)}>
            <DatePicker
              value={values.date}
              onChange={(newDate: DateTime | null) =>
                setFieldValue('date', newDate)
              }
              disableFuture
              errorMessage={touched.date && errors.date ? errors.date : ''}
              {...pickerProps}
            />
          </Grid>

          <Grid item container justifyContent="flex-end" gap={theme.spacing(2)}>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={{
                  width: '100%',
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
