import { ChangeEventHandler, useEffect, useState } from 'react';
import { PaginatedData } from 'common/types';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { RequestParams } from 'api/types';

export interface IRowsSorting {
  orderBy: string;
  orderDirection: 'ASC' | 'DESC';
}

interface IUseTableStateConfig<T> {
  fetchDataFunction: (
    limit: number,
    offset: number,
    orderBy?: string,
    orderDirection?: 'ASC' | 'DESC',
    search?: string,
    filterParams?: any,
  ) => Promise<PaginatedData<T> | null>;
  initialSortingColumn?: string;
  initialSortingDirection?: 'ASC' | 'DESC';
  defaultRowsPerPage?: number;
}

const getFilterParamsFromURL = (params: URLSearchParams) => {
  const filterParamsObj: RequestParams = {};
  params.forEach((value, key) => {
    filterParamsObj[key] = value.includes('|') ? value.split('|') : value;
  });
  return _.omit(filterParamsObj, [
    'page',
    'search',
    'orderBy',
    'orderDirection',
  ]);
};

export const useTableState = <T>(config: IUseTableStateConfig<T>) => {
  const {
    fetchDataFunction,
    initialSortingColumn,
    initialSortingDirection,
    defaultRowsPerPage = 10,
  } = config;

  const [searchParams, setSearchParams] = useSearchParams();

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(
    Number(searchParams.get('page')) > 0
      ? Number(searchParams.get('page')) - 1
      : 0,
  );
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [sorting, setSorting] = useState<IRowsSorting | null>({
    orderBy: searchParams.get('orderBy') || initialSortingColumn || '',
    orderDirection:
      (searchParams.get('orderDirection') as 'ASC' | 'DESC') ||
      initialSortingDirection ||
      '',
  });

  const [search, setSearch] = useState(
    searchParams.get('search')
      ? decodeURIComponent(String(searchParams.get('search')))
      : '',
  );

  const [filterParams, setFilterParams] = useState<RequestParams>(
    getFilterParamsFromURL(searchParams),
  );

  const reloadData = async () => {
    if (!fetchDataFunction) return;
    try {
      setLoading(true);
      const paginatedData = await fetchDataFunction?.(
        rowsPerPage,
        page * rowsPerPage,
        sorting?.orderBy,
        sorting?.orderDirection,
        search,
        filterParams,
      );
      if (!paginatedData) return;
      setTotal(paginatedData.total);
      setData(paginatedData.items);
    } catch (e: any) {
      setError(e?.response?.data?.error || e?.message || e);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async () => {
    // modifications to table can result in less items,
    // so setting page to 0 makes sence. If page is
    // already at 0, just reload the data. We can think
    // about more complex solution later
    if (page === 0) reloadData();
    else handlePageChange(0);
  };

  useEffect(() => {
    reloadData();
  }, [
    page,
    rowsPerPage,
    sorting?.orderBy,
    sorting?.orderDirection,
    filterParams,
    fetchDataFunction,
  ]);

  useEffect(() => {
    if (searchParams.get('orderBy') && searchParams.get('orderDirection')) {
      setSorting({
        orderBy: searchParams.get('orderBy') || '',
        orderDirection: searchParams.get('orderDirection') as 'ASC' | 'DESC',
      });
    }

    setSearch(
      decodeURIComponent(String(searchParams.get('search'))) === 'null'
        ? ''
        : decodeURIComponent(String(searchParams.get('search'))),
    );

    setPage(
      searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
    );

    setFilterParams(getFilterParamsFromURL(searchParams));
  }, [searchParams]);

  function handlePageChange(page: number) {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (page <= 0) {
      updatedSearchParams.delete('page');
    } else {
      updatedSearchParams.set('page', String(page + 1));
    }
    setSearchParams(updatedSearchParams.toString(), {
      replace: true,
    });
  }

  const debounceSearch = _.debounce(
    () => {
      reloadData();
    },
    500,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    debounceSearch();
    if (!search) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.delete('search');
      setSearchParams(updatedSearchParams.toString(), {
        replace: true,
      });
    }
  }, [search]);

  const handleSearchTyping: ChangeEventHandler<HTMLInputElement> = (e) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (page !== 0) {
      updatedSearchParams.delete('page');
    }
    updatedSearchParams.set('search', e.target.value);
    setSearchParams(updatedSearchParams.toString(), {
      replace: true,
    });
  };

  return {
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    handlePageChange,
    total,
    setTotal,
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    reloadData,
    refreshData,
    sorting,
    search,
    handleSearchTyping,
    filterParams,
    setFilterParams,
    setSorting,
  };
};
