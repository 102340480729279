import { User } from 'api/services/auth/types';

export const getUserFullNameString = (
  user: Pick<User, 'given' | 'middleName' | 'family'> | null,
  withMiddleName?: boolean,
) => {
  if (!user) {
    return '';
  }
  return [user.given, withMiddleName && user.middleName, user.family]
    .filter(Boolean)
    .join(' ');
};
