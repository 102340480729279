import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { HearingTestResult } from '../../../api/services/hearing-test-results/types';
import { AudiologistNote } from './AudiologistNote';

interface IAudiologistNotesListProps {
  loading: boolean;
  hearingTestResult: HearingTestResult | null;
}

export function NotesList(props: IAudiologistNotesListProps) {
  const { hearingTestResult, loading } = props;

  const theme = useTheme();

  if (loading) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(243, 238, 236, 0.30)',
          padding: { xs: theme.spacing(1), sm: theme.spacing(4) },
          paddingTop: { xs: 0, sm: 0 },
          marginBottom: theme.spacing(2),
        }}
      >
        <Skeleton variant="rounded" width="100%" height="172px" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(243, 238, 236, 0.30)',
        marginBottom: theme.spacing(4),
      }}
    >
      {!hearingTestResult?.reportMessage ? (
        <Box
          sx={{
            padding: { xs: theme.spacing(2), sm: theme.spacing(4) },
          }}
        >
          <Typography
            variant="mono"
            sx={{
              color: 'rgb(78,78,78)',
            }}
          >
            Participant does not have the note yet
          </Typography>
        </Box>
      ) : (
        <AudiologistNote note={hearingTestResult?.reportMessage} />
      )}
    </Box>
  );
}
